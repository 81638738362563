import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Post } from '../../../builtin-pages/post-list/_model/post.model';
import { CurrentDeviceService } from '../../../core/services/current-device.service';
import { PostStyleType } from '../post/post.component';
import { jsonParse } from '../../pipes/name-translate.pipe';

@Component({
    selector: 'app-common-post-list',
    templateUrl: './post-list.component.html',
    styleUrls: ['./post-list.component.scss'],
})
export class CommonPostListComponent implements OnChanges {
    @Input() contentWrap: boolean;
    @Input() type: PostStyleType = PostStyleType.CARD;
    @Input() posts: Post[] = [];
    @Input() renderOptions: any;
    @Input() data: any;
    @Output() selectPostChange = new EventEmitter<Post>();
    PostStyleType = PostStyleType;
    enabledPublishAuthorOreDateSpace: boolean = true;

    constructor(
        public translateService: TranslateService,
        public currentDeviceService: CurrentDeviceService,
    ) { }

    clickHandler(post: Post) {
        this.selectPostChange.emit(post);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.posts?.length) {
            this.enabledPublishAuthorOreDateSpace = this.posts.some(item => {
                const postRenderOptions = {
                    "enablePublishAuthor": true,
                    "enablePublishDate": true,
                    "enableBanner": true,
                    "enableLike": true,
                    "enableComment": true,
                    "enabledShare": true,
                    ...jsonParse(item?.renderOptions)
                };
                return postRenderOptions?.enablePublishAuthor || postRenderOptions?.enablePublishDate;
            });
        }
    }
}
