<app-header-block [style.opacity]="loadSiteError?'0':'1'"
[logo]="(site?.iconUrl) | uploadUrlPrefix" [siteMenu]="siteMenu" [layout]="layout">
</app-header-block>
<div class="content" style="height: 100%;padding-bottom: 2rem;" [ngClass]="{
    'desktop-aside-menu-existed':layoutRenderOptions?.desktopNavigation && (layoutRenderOptions?.desktopNavigation!==NavigationLayout?.TOP_BAR),
'left-aside-menu':layoutRenderOptions?.desktopNavigation===NavigationLayout?.LEFT_SIDE_BAR,
'right-aside-menu':layoutRenderOptions?.desktopNavigation===NavigationLayout?.RIGHT_SIDE_BAR
}" [style.visibility]="themeSettled?'initial':'hidden'"
    [style.marginTop]="asideMenuHeaderHeight">
    <router-outlet [style.visibility]="themeSettled?'initial':'hidden'">
    </router-outlet>
</div>

<!-- pc固定右侧快捷键 -->
<div [style.visibility]="themeSettled&&!checkCurrentRouterIsActive('/auth')?'initial':'hidden'" *ngIf="site">
    <div class="rightbar" [ngClass]="{'en-language':translationService?.getSelectedLanguage()==='en-US'}">
        <ul
            [style.visibility]="(getSiteFeatureOptions?.shoppingCart?.enable||getSiteFeatureOptions?.userCenter?.enable||showGoTop||getSiteFeatureOptions?.customizedItems?.length)?'initial':'hidden'">
            <li [routerLink]="[ '/shopping-cart' ]" *ngIf="getSiteFeatureOptions?.shoppingCart?.enable">
                <p><i class="iconfont icon-gouwuche"></i></p>
                <p style="word-break: normal;white-space: break-spaces;"
                    title="{{'FLOATING_WINDOW.GO_TO_SHOPCART' | translate}}"
                    [appTranslate]="'FLOATING_WINDOW.GO_TO_SHOPCART'">
                    购物车</p>
            </li>
            <li style="padding-bottom: 1.4rem;" [routerLink]="[ '/user-center' ]"
                *ngIf="getSiteFeatureOptions?.userCenter?.enable">
                <p><i class="iconfont icon-wode"></i></p>
                <p style="padding-top: 0.4rem;word-break: normal;white-space: break-spaces;"
                    [appTranslate]="'FLOATING_WINDOW.USER_CENTER'"
                    title="{{'FLOATING_WINDOW.USER_CENTER' | translate}}">
                    个人中心</p>
            </li>
            <ng-container
                *ngFor="let customerizedItem of getSiteFeatureOptions?.customizedItems;let isLast = last;trackBy:trackByCustomizeItem">
                <li [style.paddingBottom]="!showGoTop && isLast ? '0.5rem' : '1.4rem'"
                    (click)="navigateCustomerPage(customerizedItem)">
                    <p><i [class]="customerizedItem?.iconClassKey | appIconClassKey:false"></i></p>
                    <p style="padding-top: 0.4rem;word-break: normal;white-space: break-spaces;"
                        title="{{customerizedItem | mNameTranslate:'name'}}">
                        {{customerizedItem | mNameTranslate:'name'}}
                    </p>
                </li>
            </ng-container>
            <!-- <li [routerLink]="[ '/customer-service' ]" *ngIf="_config.homeModule.homeService.customerService.visible">
                <p><i class="iconfont icon-lianxikefu" style="font-size: 25px;"></i></p>
                <p style="padding-top: 0.4rem;word-break: normal;white-space: break-spaces;"
                    [appTranslate]="'FLOATING_WINDOW.CONTACT_CUSTOMER_SERVICE'"
                    title="{{'FLOATING_WINDOW.CONTACT_CUSTOMER_SERVICE' | translate}}">
                    联系客服</p>
            </li> -->
            <li (click)="scrollToTop(0)" *ngIf="showGoTop"
                [style.paddingBottom]="!getSiteFeatureOptions?.shoppingCart?.enable&&!getSiteFeatureOptions?.userCenter?.enable&&!getSiteFeatureOptions?.customizedItems?.length&&'0'">
                <p><i class="iconfont icon-up" style="font-size: 25px;"></i></p>
                <p style="padding-top: 0.4rem;word-break: normal;white-space: break-spaces;"
                    [appTranslate]="'FLOATING_WINDOW.BACK_TO_THE_TOP'"
                    title="{{'FLOATING_WINDOW.BACK_TO_THE_TOP' | translate}}">
                    回到顶部</p>
            </li>
        </ul>
    </div>
    <!-- app右侧 -->
    <div class="example-box app-rightbar" cdkDragBoundary=".example-boundary" cdkDrag>
        <ul class="rightbarMenu" [ngClass]="{'app-hide': !hiderightbar}"
            *ngIf="getSiteFeatureOptions?.shoppingCart?.enable||getSiteFeatureOptions?.userCenter?.enable||showGoTop||getSiteFeatureOptions?.customizedItems?.length">
            <li [routerLink]="[ '/shopping-cart' ]" *ngIf="getSiteFeatureOptions?.shoppingCart?.enable">
                <i class="iconfont icon-gouwuche" style="font-size: 30px !important;"
                    title="{{'FLOATING_WINDOW.GO_TO_SHOPCART' | translate}}"></i>
            </li>
            <li style="padding-bottom: 1.4rem;" [routerLink]="[ '/user-center' ]"
                *ngIf="getSiteFeatureOptions?.userCenter?.enable">
                <i class="iconfont icon-wode" title="{{'FLOATING_WINDOW.USER_CENTER' | translate}}"></i>
            </li>
            <li style="padding-bottom: 1.4rem;" (click)="navigateCustomerPage(customerizedItem)"
                *ngFor="let customerizedItem of getSiteFeatureOptions?.customizedItems;trackBy:trackByCustomizeItem">
                <i [class]="customerizedItem?.iconClassKey | appIconClassKey:false"
                    title="{{customerizedItem | mNameTranslate:'name'}}"></i>
            </li>
            <!-- <li [routerLink]="[ '/customer-service' ]" *ngIf="_config.homeModule.homeService.customerService.visible">
                <i class="iconfont icon-lianxikefu" style="font-size: 24px !important;"
                    title="{{'FLOATING_WINDOW.CONTACT_CUSTOMER_SERVICE' | translate}}"></i>
            </li> -->
            <li (click)="scrollToTop(0)" *ngIf="showGoTop">
                <p><i class="iconfont icon-xiangyou" style="font-size: 20px !important;transform: rotate(-90deg);"
                        title="{{'FLOATING_WINDOW.SHRINK_BTN_TEXT' | translate}}"></i>
                </p>
                <p style="white-space: nowrap;" title="{{'FLOATING_WINDOW.BACK_TO_THE_TOP' | translate}}">TOP</p>
            </li>
        </ul>
        <ul style="padding: 3px;" [ngClass]="{'show-apprightbar':!hiderightbar,'no-top-border-radius': hiderightbar}"
            *ngIf="getSiteFeatureOptions?.shoppingCart?.enable||getSiteFeatureOptions?.userCenter?.enable||showGoTop||getSiteFeatureOptions?.customizedItems?.length">
            <li style="padding-bottom: 0;" (click)="toggleRightbar()">
                <p><i class="iconfont" [ngClass]="{'icon-xiangzuoxi': !hiderightbar,'icon-xiangyou':hiderightbar}"
                        [title]="hiderightbar?('FLOATING_WINDOW.EXPAND_BTN_TEXT' | translate):('FLOATING_WINDOW.SHRINK_BTN_TEXT' | translate)"></i>
                </p>
            </li>
        </ul>
    </div>
</div>

<app-footer-block [renderOptions]="footerBlock?.renderOptions" [blockId]="footerBlock?.id" [site]="site"
    [style.visibility]="themeSettled?'initial':'hidden'" [style.opacity]="loadSiteError?'0':'1'"
    [data]="footerBlock?.data" [section]="footerBlock?.section"
    *ngIf="!isMobile&&footerBlock?.enabled">
</app-footer-block>

<!-- 电商新人优惠券 -->
<app-new-coupon #newCoupon *ngIf="supportEcommerceFunc && site"></app-new-coupon>
