<ng-container *ngIf="!hasThumbsSwiprer">
    <div class="swiper-container" [ngClass]="{'b-r':rounded}" id="js-swiper" style="text-align: center">
        <div class="swiper-wrapper">
            <div (click.stop)="delegation(idx)" class="swiper-slide" style="overflow: hidden;max-width: 100%;"
                *ngFor="let item of carouselItems; let idx = index">
                <div class="swiper-img header-background-drop" [ngClass]="{
                    'none-filter-mode':!data?.background?.filterType||(data?.background?.filterType === FilterTypeEnum?.NONE),
                    'blur-mode':data?.background?.filterType === FilterTypeEnum?.BLUR,
                    'darken-mode':data?.background?.filterType === FilterTypeEnum?.DARKEN
                }">
                    <video x-webkit-airplay="allow" name="media" muted loop
                        [attr.index]="idx"
                        *ngIf="urlHelperService?.isVideo((item.img || item.itemUrl))&&videoAutoPlayEnabled()"
                        [ngClass]="{'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                        autoplay="autoplay" preload="auto" playsinline="true" webkit-playsinline="true"
                        mtt-playsinline="true" x5-video-player-type="h5-page"
                        [playSrc]="(item.img || item.itemUrl) | uploadUrlPrefix" playsinline="true"
                        x5-playsinline="true" appBricsVideoAutoPlay>
                        <source type="video/mp4; codecs=&quot;avc1.4D401E, mp4a.40.2&quot;"
                            [src]="(item.img || item.itemUrl) | uploadUrlPrefix" group="banner"
                            appPreventDefaultAndStopPropagation>
                    </video>
                    <img
                        [ngClass]="{'b-r':rounded,'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                        [title]="(item.targetType === 'NONE')?('SHARED.CLICK_TO_SEE_LARGER_PICTURE'|translate):(item.caption ||('SHARED.CLICK_TO_GO'|translate))"
                        [alt]="(item.targetType === 'NONE')?('SHARED.CLICK_TO_SEE_LARGER_PICTURE'|translate):(item.caption ||('SHARED.CLICK_TO_GO'|translate))"
                        [src]="((urlHelperService?.isImage(item.img || item.itemUrl) ? (item.img || item.itemUrl) : getVideoBanner(item))| uploadUrlPrefix) | imgResize: currentDeviceService?.isMobile()?'w_1175':'w_2048'"
                        loading="lazy" onerror="this.onerror=null;this.src='/assets/icon/img-blank.svg';"
                        [attr.index]="idx"
                        *ngIf="!(urlHelperService?.isVideo((item.img || item.itemUrl))&&videoAutoPlayEnabled())"/>
                </div>
                <ng-container *ngIf="item">
                    <div class="carousel-content-container"
                        style="top: 50%;height: auto;min-height: 33%;justify-content: space-around;display: flex;box-sizing: border-box;
                    flex-wrap: nowrap;flex-direction: column;position: absolute;width: min(var(--content-width),calc(100% - 8vw));transform: translateY(-50%);
                    max-width:100%;padding: 1rem 0;max-height: min(500px,100%);
                    align-items: {{getContentAlignClass(item?.renderOptions || contentRenderOptions?.[currentDeviceService.isMobile() ? 'mobile' : 'desktop'])}};">
                        <h1 class="carousel-item-title"
                            [appFontSizeScale]="getElFontSizeScale('main-title',item?.renderOptions || contentRenderOptions?.[currentDeviceService.isMobile() ? 'mobile' : 'desktop'])"
                            style="{{getElStyle('main-title',item?.renderOptions)}}"
                            *ngIf="item | mNameTranslate:'title'">
                            {{item |
                            mNameTranslate:'title'}}</h1>
                        <h3 class="carousel-item-sub-title"
                            [appFontSizeScale]="getElFontSizeScale('sub-title',item?.renderOptions || contentRenderOptions?.[currentDeviceService.isMobile() ? 'mobile' : 'desktop'])"
                            *ngIf="item | mNameTranslate:'desc'"
                            style="height: auto;overflow: auto;min-height: 20%;{{getElStyle('sub-title',item?.renderOptions)}}text-align: left;"
                            [innerText]="item | mNameTranslate:'desc'"></h3>
                        <div *ngIf="item | mNameTranslate:'buttonText'">
                            <button
                                class="btn bg-warning btn-reflush d-inline-flex align-items-center justify-content-center ellipsis"
                                style="{{getElStyle('button',item?.renderOptions)}}" (click.stop)="delegation(idx)"
                                [title]="item |
                                mNameTranslate:'buttonText'">{{item |
                                mNameTranslate:'buttonText'}}</button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="swiper-pagination" [ngStyle]="{ display: pagination ? '' : 'none' }" data-skeleton-remove>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
    </div>
</ng-container>

<div class="big-img" *ngIf="particularsStatus" (click)="close($event)">
    <div class="mask-for-big-img"></div>
    <div class="big-img-header">
        <i class="iconfont icon-cha close-big-img" id="close-big-img" (click)="close($event)"></i>
        <div class="big-img-number"><span class="actived-big-img-index">{{showBigImgNumber}}</span> /
            {{carouselItems.length}}</div>
    </div>
    <Carousel [selectedIndex]="currentBigImgIndex" class="big-img-items" (beforeChange)="beforeChange($event)"
        [dots]="false">
        <CarouselSlide *ngFor="let item of carouselItems; let idx = index">
            <div class="big-img-height">
                <pinch-zoom id="particularsStatus">
                    <img class="mode-contain" [src]="(item.img || item.itemUrl) | uploadUrlPrefix"
                        loading="lazy" onerror="this.onerror=null;this.src='/assets/icon/img-blank.svg';"
                        (click)="$event.stopPropagation()" appPreventDefaultAndStopPropagation
                        *ngIf="!(urlHelperService?.isVideo(currentImageUrl) && videoAutoPlayEnabled())"/>
                    <video name="media" x-webkit-airplay="allow" muted loop
                        [ngClass]="{'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                        autoplay="autoplay" preload="auto" playsinline="true" webkit-playsinline="true" mtt-playsinline="true"
                        x5-video-player-type="h5-page" playsinline="true" x5-playsinline="true" appBricsVideoAutoPlay
                        *ngIf="urlHelperService?.isVideo(currentImageUrl) && videoAutoPlayEnabled()" [playSrc]="currentImageUrl | uploadUrlPrefix">
                        <source type="video/mp4; codecs=&quot;avc1.4D401E, mp4a.40.2&quot;"
                            [src]="currentImageUrl | uploadUrlPrefix" group="banner" appPreventDefaultAndStopPropagation>
                    </video>
                </pinch-zoom>
            </div>
        </CarouselSlide>
    </Carousel>
</div>

<!-- 带有缩略图的PC版轮播图 -->
<ng-container *ngIf="hasThumbsSwiprer">
    <div class="carousel-for-pc-version b-r">
        <!-- 大图区域 -->
        <div class="swiper-img img-detail-of-thumbs-wrapper overflow-hidden b-r"
            (mousemove)="mousemoveHandlerForBigImg($event)"
            [ngClass]="{'noImg ng-img-failed':!carouselItems?.length,'ng-img-failed':!thumbsShowStatus,'w-100 h-100':!pagination}">
            <!-- 大图 -->
            <img class="img-detail-of-thumbs" id="img-detail" appPreventDefaultAndStopPropagation
                [ngClass]="{'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                alt="{{('SHARED.CLICK_TO_SEE_LARGER_PICTURE'|translate)}}"
                [src]="(currentImageUrl | uploadUrlPrefix) | imgResize: 'w_596'" [attr.index]="idx"
                loading="lazy" onerror="this.onerror=null;this.src='/assets/icon/img-blank.svg';"
                (loadImgEvent)="changeThumbsShowStatus($event)"
                *ngIf="!urlHelperService?.isVideo(currentImageUrl)&&videoAutoPlayEnabled()" />
            <video name="media" x-webkit-airplay="allow" muted loop
                [ngClass]="{'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                autoplay="autoplay" preload="auto" playsinline="true" webkit-playsinline="true" mtt-playsinline="true"
                x5-video-player-type="h5-page" playsinline="true" x5-playsinline="true" appBricsVideoAutoPlay
                *ngIf="urlHelperService?.isVideo(currentImageUrl)&&videoAutoPlayEnabled()" [playSrc]="currentImageUrl | uploadUrlPrefix">
                <source type="video/mp4; codecs=&quot;avc1.4D401E, mp4a.40.2&quot;"
                    [src]="currentImageUrl | uploadUrlPrefix" group="banner" appPreventDefaultAndStopPropagation>
            </video>
            <!-- 放大镜区域 -->
            <div class="img-imagezoom-area" id="img-imagezoom-area"
                *ngIf="thumbsShowStatus&&!urlHelperService?.isVideo(currentImageUrl)&&videoAutoPlayEnabled()"
                [ngStyle]="{top: imagezoomAreaNativeEleY+'px',left:imagezoomAreaNativeEleX+'px'}"
                (click.stop)="delegation(undefined,currentSelectedThumbsImageIndex)">
            </div>
            <i class="iconfont icon-sousuo1" [ngClass]="{'b-r':rounded}"
                *ngIf="thumbsShowStatus&&!urlHelperService?.isVideo(currentImageUrl)&&videoAutoPlayEnabled()"></i>
        </div>
        <!-- 放大图区域 -->
        <div class="image-zoom-wrapper"
            *ngIf="thumbsShowStatus&&!urlHelperService?.isVideo(currentImageUrl)&&videoAutoPlayEnabled()"
            [ngClass]="{'b-r':rounded,'w-100 h-100':!pagination}">
            <img src="{{ (currentImageUrl | uploadUrlPrefix) | imgResize: 'w_1200'}}"
                alt="{{('SHARED.ENLARGE_IMAGE'|translate)}}" class="image-zoom"
                [ngStyle]="{top: bigImagezoomAreaNativeEleY+'px',left:bigImagezoomAreaNativeEleX+'px'}">
        </div>
        <div id="thumbs-for-pc-version" *ngIf="carouselItems?.length&&pagination">
            <div class="swiper-wrapper">
                <p class="swiper-slide thumbs-slid-wrapper" *ngFor="let item of carouselItems; let idx = index"
                    [ngClass]="{active:(item.img || item.itemUrl)===currentImageUrl}">
                    <img [attr.index]="idx" appPreventDefaultAndStopPropagation
                        [ngClass]="{'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                        [src]="((item.img || item.itemUrl) | uploadUrlPrefix) | imgResize: 'w_150'"
                        loading="lazy" onerror="this.onerror=null;this.src='/assets/icon/img-blank.svg';"
                        *ngIf="!urlHelperService?.isVideo((item.img || item.itemUrl))&&videoAutoPlayEnabled()"
                        (mouseover)="selectedAndShowThisThumbsImage((item.img || item.itemUrl),idx)" />
                    <video name="media" x-webkit-airplay="allow" muted loop
                        [ngClass]="{'mode-cover':imageMode==='cover','mode-contain':imageMode!=='cover'}"
                        autoplay="autoplay" preload="auto" playsinline="true" webkit-playsinline="true" mtt-playsinline="true"
                        x5-video-player-type="h5-page" playsinline="true" x5-playsinline="true" appBricsVideoAutoPlay
                        *ngIf="urlHelperService?.isVideo(item.img || item.itemUrl)" [playSrc]="(item.img || item.itemUrl) | uploadUrlPrefix">
                        <source type="video/mp4; codecs=&quot;avc1.4D401E, mp4a.40.2&quot;"
                            [src]="currentImageUrl | uploadUrlPrefix" group="banner" appPreventDefaultAndStopPropagation>
                    </video>
                </p>
            </div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
    </div>
</ng-container>