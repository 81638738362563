export const userTranslate = {
    INFOMATION: {
        AVATAR: {
            TITLE: 'アイコン'
        },
        NICK_NAME: {
            TITLE: 'ニックネーム',
            PLACEHOLDER: 'ニックネームを入力してください...'
        },
        GENDER: {
            TITLE: '性别',
            MALE: '男',
            FEMALE: '女',
            OTHER: 'その他',
            UNKNOWN: '不明'
        },
        NAME: {
            TITLE: '名前',
            PLACEHOLDER: '名前を入力してください...'
        },
        MOBILE_PHONE: {
            TITLE: '連絡先',
            PLACEHOLDER: '連絡先を入力してください...'
        },
        TYPE: {
            TITLE: 'タブ',
            INDIVIDUAL: '個人',
            ORGANIZATION: '組織'
        },
        ENTITY_SCALE: {
            TITLE: '主体規模',
            OPTIONS: ['有限責任会社', '株式会社シーアールイー', '個人独資企業', 'パートナー企業', 'フルネーム所有製企業', '集団所有製企業', 'その他']
        },
        ANNUAL_REVENUE: {
            TITLE: '年間営業収入',
            PLACEHOLDER: '年間営業収益を入力してください...'
        },
        EMPLOYEE_NUMBER: {
            TITLE: '労働者数',
            PLACEHOLDER: '労働者数を入力してください...'
        },
        SAVE_BTN_TEXT: '資料を更新する',
        SAVE_BTN_SUCCESS_TEXT: '資料の更新に成功しました！',
        SAVE_BTN_ERROR_TEXT: '資料の更新に失敗しました！',
        UPDATE_AVATAR_ERROR: 'アバターのアップロードに失敗しました、',
    },
    ACCOUNT_SECURITY: {
        SET_PASSWORD: {
            TITLE: 'パスワードの設定',
            ENABLE: '設定済み',
            NOT_ENABLE: '設定されていません',
            SET_BTN_TEXT: '設定＃セッテイ＃',
            CHANGE_BTN_TEXT: '変更',
        },
        BIND_MOBILE_PHONE: {
            TITLE: '携帯電話バインド',
            ENABLE: 'バインド済み',
            NOT_ENABLE: 'バインドされていません',
            SET_BTN_TEXT: 'バインド',
            CHANGE_BTN_TEXT: '変更',
        },
        BIND_WECHAT: {
            TITLE: 'WeChat バインド',
            ENABLE: 'バインド済み',
            NOT_ENABLE: 'バインドされていません',
            SET_BTN_TEXT: 'バインド',
            CHANGE_BTN_TEXT: '変更',
            CHANGE_TITLE: '本当にウィチャットを解除しますか？'
        },
        BIND_PHONE_PAGE: {
            TITLE: '为了您的账户安全，需要{{action}}你的手机号',
            BIND: 'バインド',
            VERIFY: '認証',
            CHANGE_BIND: 'ひもをかえる',
            SUCCESS_MESSAGE: '{{action}}携帯番号:{{phoneNumber}}成功',
            ERROR_MESSAGE: '携帯番号の更新に失敗しました！',
            MOBILE_PHONE: {
                TITLE: '携帯番号',
                PLACEHOLDER: '携帯番号を入力してください...'
            },
            OLD_MOBILE_PHONE: {
                TITLE: '元の携帯番号',
                PLACEHOLDER: '元の携帯番号を入力してください...'
            },
            VERTIFY_CODE: {
                TITLE: '認証コード',
                PLACEHOLDER: '認証コードを入力してください...',
                GET_BTN_TEXT: '認証コードの取得',
                SEND_TEXT: '送信',
                PHONE_CODE_ERROR_MESSAGE: '認証コードの取得に失敗しました！',
                IMAGE_CODE_ERROR_MESSAGE: '認証コードの取得に失敗しました！',
            },
            NEW_MOBILE_PHONE: {
                TITLE: '新しい携帯番号',
                PLACEHOLDER: '新しい携帯番号を入力してください...'
            },
            CHANGE_MOBILE_PHONE_BTN_TEXT: '携帯番号の変更',
            BIND_MOBILE_PHONE_BTN_TEXT: '携帯番号をバインドする',
        },
        CHANGE_PASSWORD_PAGE: {
            TITLE: 'アカウントのセキュリティのために、認証方式を選択して認証を完了します。',
            PHONE_VERTIFY: {
                TITLE: '携帯電話の認証',
                MOBILE_PHONE: {
                    TITLE: '携帯番号',
                    PLACEHOLDER: '現在バインドされている携帯番号を入力してください...'
                },
                PASSWORD: {
                    TITLE: '新しいパスワード',
                    PLACEHOLDER: '新しいパスワードを入力してください...'
                },
                VERTIFY_CODE: {
                    TITLE: '認証コード',
                    PLACEHOLDER: '認証コードを入力してください...',
                    GET_BTN_TEXT: '認証コードの取得',
                    SEND_TEXT: '送信',
                    PHONE_CODE_ERROR_MESSAGE: '認証コードの取得に失敗しました！',
                    IMAGE_CODE_ERROR_MESSAGE: '認証コードの取得に失敗しました！',
                },
                NOT_BIND_PHONE_TEXT: '携帯電話をバインドしていないので、携帯電話で認証できません。先に',
                BIND_PHONE_LINK_TEXT: '携帯電話をバインドする'
            },
            PASSWORD_VERTIFY: {
                TITLE: 'パスワード認証',
                OLD_PASSWORD: {
                    TITLE: '旧パスワード',
                    PLACEHOLDER: '旧パスワードを入力してください...'
                },
                NEW_PASSWORD: {
                    TITLE: '新しいパスワード',
                    PLACEHOLDER: '新しいパスワードを入力してください...'
                },
            },
            SET_PASSWORD_BTN_TEXT: 'パスワードの設定',
            CHANGE_PASSWORD_BTN_TEXT: 'パスワードの変更',
            SUCCESS_MESSAGE: 'パスワードの変更に成功しました！',
            ERROR_MESSAGE: '修正に失敗しました！',
            GET_VERTIFY_CODE_ERROR_MESSAGE: '認証コードの取得に失敗しました！',
            SEND_VERTIFY_CODE_ERROR_MESSAGE: '認証コードの送信に失敗しました！',
        },
        SET_LOGIN_NAME: {
            TITLE: 'ログイン名を設定する',
            ENABLE: 'が設定されています',
            NOT_ENABLE: '設定されていません',
            SET_BTN_TEXT: '設定する',
            CURRENT_NAME_TIPS: '現在のログイン名:',
            INIT_NAME_TIPS: '初期ログイン名:',
            OLD_LOGIN_NAME_TIPS: '旧ログイン名:',
            NEW_LOGIN_NAME_TIPS: '新しいログイン名:',
            CHANGE_LOGIN_NAME_BTN_TEXT: 'ログイン名を変更する',
            NEW_LOGIN_NAME_PLACEHOLDER: '変更後に二次的に変更することはできません',
            LOGIN_CUSTOMIZED_TIP: 'ログインは既に設定されており、再度変更することはできません',
            CHANGE_LOGIN_NAME_ERROR_TIPS: 'ログインの設定に失敗しました:',
            LOGIN_NAME_INVALID_ERROR_MESSAGE: 'ログイン名の形式が正しくありません。現在、大文字と小文字、数字、指定された特殊記号（-、+、_）のみがサポートされています！ご確認ください。',
        }
    },
    REFERRAL: {
        REFERREEID_LABEL: '推薦者名: ',
        REFERRAL_DATE_LABEL: '推奨時間です: ',
        REFERRAL_SHARE_URL_DESC: '登録すると特典があります: ',
        REFERRAL_SHARE_CODE_DESC: '私の紹介コードを記入すると、さらに特典があります: ',
    },
    AFFILIATE: {
        TITLE: 'アライアンスへの参加申し込み',
        SUCCESS_TEXT: 'おめでとうございます、あなたは無事にリーグに参加しました！',
        EMAIL_LABEL: '連絡先メールアドレス',
        EMAIL_PLACEHOLDER: '通知・連絡先受信用',
        EMAIL_ERROR_REQUIRED: '連絡先メールアドレスをご記入ください。',
        EMAIL_ERROR_INVALID: '連絡先のメールフォーマットが正しくありません!',
        PHONE_LABEL: '連絡先電話番号',
        PHONE_PLACEHOLDER: '普段お使いの電話番号を入力してください',
        PHONE_ERROR_REQUIRED: '連絡先の電話番号を入力してください',
        COPY_CODE_TEXT: 'コピー専用コード:',
        PHONE_ERROR_INVALID: '連絡先番号のフォーマットが間違っています!',
        FIRST_NAME_TEXT: '名前',
        FIRST_NAME_ERROR_REQUIRED: '名前を記入してください',
        FIRST_NAME_PLACEHOLDER: '名前を入力してください',
        LAST_NAME_TEXT: '名字',
        LAST_NAME_PLACEHOLDER: '姓を入力してください',
        LAST_NAME_ERROR_REQUIRED: '姓を入力してください',
        BIRTHDAY_TEXT: '誕生日',
        BIRTHDAY_PLACEHOLDER: '誕生日を入力してください',
        BIRTHDAY_ERROR_REQUIRED: '誕生日を入力してください!',
        PAYPAL_EMAIL_PLACEHOLDER: 'ペイパルのEメール引き出し口座を入力',
        PAYPAL_EMAIL_ERROR_REQUIRED: 'Paypal Emailを記入してください',
        PAYPAL_EMAIL_ERROR_INVALID: 'Paypal Emailのフォーマットにエラーがあります!',
        FULL_ADDRESS_TEXT: '住所詳細',
        FULL_ADDRESS_PLACEHOLDER: '例：ストリートアドレス',
        FULL_ADDRESS_ERROR_REQUIRED: '完全な住所を記入してください!',
        COUNTRY_TEXT: '国/地域',
        COUNTRY_ERROR_REQUIRED: '国/地域を記入してください',
        STATE_TEXT: '都道府県名',
        STATE_PLACEHOLDER: '県名／州名を入力してください',
        STATE_ERROR_REQUIRED: '都道府県を入力してください',
        SELECT_TEXT: '選択してください',
        CITY_TEXT: '都市名',
        CITY_PLACEHOLDER: '都市名を入力してください',
        CITY_ERROR_REQUIRED: '都市名を記入してください',
        ZIP_CODE_TEXT: '郵便番号',
        ZIP_CODE_PLACEHOLDER: '郵便番号を入力してください',
        ZIP_CODE_ERROR_REQUIRED: '郵便番号をご記入ください',
        APPLICATION_STATEMENT_TEXT: '自己PRと志望動機',
        APPLICATION_STATEMENT_PLACEHOLDER: '個人的な状況を説明し、応募の理由を述べてください。',
        REMARKS_TEXT: '備考',
        REMARKS_PLACEHOLDER: 'その他の注意事項があれば、ここで再度明記してください。',
        FORM_ERROR_MSG: '現在の情報は不完全です!',
    },
    COMMISSION: {
        COMMISSION_SETTLEMENT: 'コミッション決済',
        SETTLEMENT_AMOUNT_LABEL: '決済される金額:',
        SETTLEMENT_BTN: '決済',
        COMMISSION_RECORDS: 'コミッションレコード',
        SETTLEMENT_RECORDS: '決済記録',
        COMMISSION_SETTLEMENT_CONFIRM_TITLE: '決済情報の確認',
        RECEIPT_INFO: 'コレクション情報',
        ENABLE_SETTLEMENT_TEXT: 'セトルアブル',
        SEE_ALL_SETTLEMENTS: '決済記録を見る',
        STATUS: 'ステータス',
        AFFILIATE: 'アフィリエイト・ネットワーク',
        REFERRAL: 'リファラルネットワーク',
        REWARD_DETAIL: '報酬の詳細',
        REWARD_STATUS: '決済状況',
        PARTNER_TYPE: '報酬の提供元',
        PARTNER_CODE: 'マーケティングコード',
        REWARD: 'マーケティングリワード',
        SETTLEMENT_DATE: '決済日',
        PAYMENT_DATE: '支払日',
        PAYMENT_METHOD: '支払方法',
        PARTNER_NETWORD: 'マーケティングネットワーク',
        CUSTOMER_ID: '顧客ID',
        REWARD_TYPE: '賞の種類',
        ORDER_TYPE: '注文タイプ',
        ORDER_ID: '注文ID',
        ORDER_TOTAL_AMOUNT: '注文の合計金額',
        REWARD_RULE: '報酬ルール',
        ORDER_DATE: '注文日',
        CONFIRMED_DATE: '確認日',
        PAYMENT_DETAIL: '支払いの詳細',
        PAYMENT_REFERRENCE: '支払先',
        PAYMENT_REMARK: '支払備考',
        ORDER_COMMISSION: 'オーダコミッション',
        TIER_UPGRADE: 'レベルリワード',
        RECEIPT_ACCOUNT: '領収書勘定',
        RECEIPT_TYPE: '領収書タイプ',
        CASH_AMOUNT: '手数料収入',
        PERIOD_START_DATE: '決済の開始日',
        PERIOD_END_DATE: '決済の終了日',
        REWARD_SOURCE_TYPE: 'アワードソース',
    },
    MARKING: {
        NETWORK: 'マーケティングネットワーク',
        NETWORK_ERROR_REQUIRED: '参加したいマーケティングネットワークを選択してください',
    },
    AFFILIATE_PROFILE: {
        MENU_TITLE: 'インフォメーション',
        TITLE: 'アフィリエイトプロフィールを更新する',
        NETWORK_INFO_LABEL: 'ネットワーク情報',
        RECEIPT_INFO_LABEL: '領収書情報',
        BASIC_INFO_LABEL: '基本情報',
        ADDRESS_INFO_LABEL: 'アドレス情報',
    }
}
