import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

// 用法：<video appBricsVideoAutoPlay [playSrc]="videoUrl"></video>

@Directive({
    selector: '[appBricsVideoAutoPlay]'
})
export class BricsVideoAutoPlayDirective implements OnChanges {
    @Input() playSrc: string;
    @Input() disabledLazyLoad: boolean = false;

    private observer: IntersectionObserver;

    constructor(private el: ElementRef) {
        if ('IntersectionObserver' in window) {
            this.setupIntersectionObserver();
        } else {
            this.loadVideo();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['playSrc'] && !changes['playSrc'].firstChange && this.isInViewport()) {
            this.loadVideo();
        }
    }

    private isInViewport() {
        const rect = this.el.nativeElement.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    private loadVideo() {
        const video = this.el.nativeElement as HTMLVideoElement;
        // console.log('video.src !== this.playSrc', video.src, this.playSrc);
        if (video.src !== this.playSrc) {
            video.src = this.playSrc;
            // console.log('开始监听');
            video.removeEventListener('loadeddata', this.handleLoadedData?.bind(this));
            video.addEventListener('loadeddata', this.handleLoadedData?.bind(this));
            // load() 方法是同步的，它会立即开始加载媒体资源，但实际的加载过程是异步的。
            // 这意味着，当你调用 load() 方法时，它会立即返回，而不会等待媒体资源加载完成。
            video.load();
        } else {
            try {
                video.muted = true;
                video?.play()?.catch((error) => {
                    console.log('Failed to auto play video:', this.playSrc, error);
                });
            } catch (error) {

            }
        }
    }

    handleLoadedData() {
        const video = this.el.nativeElement as HTMLVideoElement;
        // console.log('play:', video.paused, video);
        if (video.paused) {
            try {
                video.muted = true;
                video?.play()?.catch((error) => {
                    console.log('Failed to auto play video:', this.playSrc, error);
                });
            } catch (error) {

            }
        } else {
            // console.log('Video is playing');
        }
    };

    private setupIntersectionObserver() {
        const video = this.el.nativeElement as HTMLVideoElement;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        this.observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.loadVideo();
                    observer.unobserve(entry.target);
                } else {
                    // 离开可视范围
                    if (entry.intersectionRatio === 0) {
                        video.pause();
                    }
                }
            });
        }, options);

        this.observer.observe(this.el.nativeElement);
    }
}