<div class="post-list-container w-100" style="box-sizing: border-box;">
    <div class="white-nowrap post-card-list post-list-content swiper-part" [ngClass]="{
        'content-wrap-mode':contentWrap,
        'post-card-list':type==='CARD'||!type,
        'd-flex flex-wrap':(contentWrap) ? true : (((type==='LIST')&&currentDeviceService.isDesktop()) ? false : true)
    }">
        <div class="overflow-hidden post-list-item"
            [style.borderBottom]="!isLast && (type==='NEWS')?'1px dashed #ccc':''" [ngClass]="{
            'post-not-image-list-item':type==='LIST',
            'w-100 mb-0 post-new-item':type==='NEWS',
            'b-r':type!=='NEWS',
            'text-start':!currentDeviceService.isDesktop(),
            'is-even-item':even}" *ngFor="let post of posts;let isLast = last;let even = even;"
            (click)="clickHandler(post)">
            <app-post [type]="type" [data]="data" [post]="post" [renderOptions]="renderOptions"
                [hiddenAuthorOrDateOfSpace]="enabledPublishAuthorOreDateSpace"></app-post>
        </div>
    </div>
</div>