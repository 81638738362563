import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable } from 'rxjs';
import { Account } from '../user/account.model';
import { ICustomerDTO } from '../model/customer-dto.model';
import { CustomerService } from '../services/customer.service';
import { tap } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { SiteContextService } from '../../shared/services/site-context.service';

export interface IUserInfo {
    customer: ICustomerDTO;
    account: Account;
    hasRequestedAccount: boolean;
    hasRequestedCustomer: boolean;
    requestedAccountFailed?: boolean;
    requestedCustomerFailed?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AccountService implements OnDestroy {
    private _userInfo: IUserInfo = { customer: {}, account: {}, hasRequestedAccount: false, hasRequestedCustomer: false };
    private userInfoState$: BehaviorSubject<IUserInfo> = new BehaviorSubject(this._userInfo);
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private customerService: CustomerService,
    ) { }

    query(req?: any): Observable<HttpResponse<Account[]>> {
        return this.http.get<Account[]>(API_SERVICE_PREFIX + '/user/api/account',
            {
                params: req,
                observe: 'response',
                headers: new HttpHeaders({
                    'brics-api-error-notify': 'false',
                    'brics-api-success-notify': 'false'
                })
            });
    }

    // 获取account信息
    get(headerOptions?: HttpHeaders): Observable<HttpResponse<Account>> {
        if (this.siteContextService.token()) {
            return this.http.get<Account>(API_SERVICE_PREFIX + '/user/api/account',
                { observe: 'response', headers: headerOptions }).pipe(tap(this.updateAccount.bind(this)));
        } else {
            return new BehaviorSubject(null);
        }
    }

    // 更新account信息
    save(account: any): Observable<HttpResponse<any>> {
        if (account && account.id) {
            return this.http.put(API_SERVICE_PREFIX + '/user/api/account', account,
                { observe: 'response' }).pipe(tap(this.updateAccount.bind(this)));
        } else {
            return this.http.post(API_SERVICE_PREFIX + '/user/api/account', account,
                { observe: 'response' }).pipe(tap(this.updateAccount.bind(this)));
        }
    }

    updateAccount(accountRes: HttpResponse<Account>) {
        // 对返回数据判断，如果不同则更新观察对象
        if (JSON.stringify(accountRes.body) !== JSON.stringify(this._userInfo.account)) {
            this._userInfo.account = accountRes.body;
            this._userInfo.hasRequestedAccount = true;
            this._userInfo.requestedAccountFailed = false;
            this.userInfoState$.next(this._userInfo);
        }
    }

    // 获取Customer信息
    getCurrentCustomer(headerOptions?: HttpHeaders): Observable<HttpResponse<ICustomerDTO>> {
        return this.customerService.getCurrent(headerOptions).pipe(tap(this.updateCustomer.bind(this)));
    }

    // 更新Customer信息
    saveCustomer(customer: ICustomerDTO): Observable<HttpResponse<ICustomerDTO>> {
        if (customer && customer.id) {
            return this.customerService.update(customer).pipe(tap(this.updateCustomer.bind(this)));
        } else {
            return this.customerService.create(customer).pipe(tap(this.updateCustomer.bind(this)));
        }
    }

    updateCustomer(customerRes: HttpResponse<ICustomerDTO>) {
        // 对返回数据判断，如果不同则更新观察对象
        if (JSON.stringify(customerRes.body) !== JSON.stringify(this._userInfo.customer)) {
            this._userInfo.customer = customerRes.body;
            this._userInfo.hasRequestedCustomer = true;
            this._userInfo.requestedCustomerFailed = false;
            this.saveInfoForSomePage(customerRes.body);
            this.userInfoState$.next(this._userInfo);
        }
    }

    saveInfoForSomePage(customer: ICustomerDTO) {
        if (customer) {
            this.siteContextService.setCustomer(customer);
        }
    }

    //   获取Customer和Account信息
    getUserInfo(forceRequest?: boolean, errorToast?: boolean, successToast?: boolean): Observable<IUserInfo> {
        // 如果已经有则直接返回
        if (!this.isEmptyObj(this._userInfo.customer) && !this.isEmptyObj(this._userInfo.account) && !forceRequest) {
            return this.userInfoState$;
        }
        // 如果没有则请求Api
        if (this.siteContextService.token()) {
            const headerOptions = new HttpHeaders({
                'brics-api-error-notify': errorToast ? 'true' : 'false',
                'brics-api-success-notify': successToast ? 'true' : 'false'
            });
            firstValueFrom(this.get(headerOptions)).then(result => { }).catch(err => {
                this._userInfo.requestedAccountFailed = true;
                this._userInfo.account = {};
            });
            firstValueFrom(this.getCurrentCustomer(headerOptions)).then(result => { }).catch(err => {
                this._userInfo.requestedCustomerFailed = true;
                this._userInfo.customer = {};
            });
        } else {
            this.clearUserInfo();
            this._userInfo.requestedAccountFailed = false;
            this._userInfo.requestedCustomerFailed = false;
        }
        // 只要发送请求出去直接返回观察对象就行了，如果返回了结果，我们之前的update会自动更新观察对象数据
        return this.userInfoState$;
    }

    clearUserInfo() {
        this._userInfo = {
            customer: {},
            account: {},
            hasRequestedAccount: false,
            hasRequestedCustomer: false,
            requestedAccountFailed: false,
            requestedCustomerFailed: false
        };
        this.userInfoState$.next(this._userInfo);
    }

    isEmptyObj(obj: Object): boolean {
        if (typeof obj === 'object') {
            return obj === null || Object.getOwnPropertyNames(obj).length < 1;
        } else {
            return false;
        }
    }

    ngOnDestroy() {
        this.userInfoState$.complete();
    }
}
