export const affiliateTranslate = {
    GENERAL: {
        CASH: '现金奖励',
        AFFILIATE_CODE: '联盟代码',
        AFFILIATE_DISCOUNT: '联盟优惠',
        REFERRAL_DISCOUNT: '推荐优惠',
        COUPONS_IS_NOT_AVAILABLE: '不可叠加优惠券',
        PENDING_STATUS: '待支付',
        CONFIRMED_STATUS: '已入账',
        SETTLED_STATUS: '已结算',
        COMPLETED_STATUS: '已完成',
        CANCELLED_STATUS: '已取消',
        CASH_TEXT: '现金',
        COUPON: '优惠券',
        CONSUMING_CARD: '消费卡',
        GIFT_CARD: '礼品卡',
        POINTS: '积分',
        PRODUCT: '商品',
        APPROVED: '已批准',
        PENDING: '待审核',
        REJECTED: '已拒绝',
        CANCELLED: '已取消',
        SETTLED: '已结算',

    },
    SHARE_MODAL: {
        MARKET_REWARD: '推广赚',
        INVITE_FRIEND_START_TIP: '邀请好友成功购买本{{productName}}，每单最高得',
        COMMISSION_START_TIP: '推广收益及提现请到',
        OPERATION: '操作',
        COPY_BUTTON: '复制推广链接',
        REWARD: '推广赚',
    }
}