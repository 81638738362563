<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation" *ngIf="series.length"
    [renderOptions]="renderOptions" [enabledMoreBtn]="showSeriesMore" [moreBtnText]="'GENERAL.SEE_MORE' | translate"
    (moreBtnClick)="navigateMorePage('/series-page')" [section]="section">
    <div class="subject-bg swiper-part">
        <div class="subject-part" *ngFor="let series_ of series; let idx = index">
            <div class="card-box b-r" routerLink="/series-product-page" style="padding-top: 1rem;"
                [queryParams]="{ id: series_.id, title: series_ | mNameTranslate }">
                <p class="card-title ellipsis trans-text" style="padding: 0 .67rem;margin-bottom: 4px;">{{ series_ |
                    mNameTranslate
                    }}</p>
                <p class="card-content">{{ series_.shortDesc }}</p>
                <p class="subject-img">
                    <img [decode]="true" offset="100"
                        lazyLoad="{{ (series_.imgUrl | uploadUrlPrefix) | imgResize: 'h_196'}}" alt="" class="card-img"
                        appLoadingAnimationAndErrorBackground />
                </p>
            </div>
        </div>
    </div>
</app-block-container>