import { afterNextRender, AfterViewInit, ChangeDetectorRef, Component, HostListener, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SiteContextService } from '../../services/site-context.service';
import { StateStorageService } from '../../../core/auth/state-storage.service';
import { AccountService } from '../../../core/auth/account.service';
import { Principal } from '../../../core/auth/principal.service';
import { ToastService } from '../../services/toast.service';
import { OrderNumHelperService } from '../../../core/services/order-num-helper.service';
import { DOCUMENT } from '@angular/common';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent {
    @Input() greetingText = this.translateService.instant('GENERAL.HELLO') || '你好';
    @Input() username = '';
    @Input() userAvatarUrl = '';
    @Input() containerClass = '';
    @Input() enableMoreIcon = true;
    @Input() enableWrap = false;
    @Input() avatarImgSize = '';
    showDropdownMenu  = false;

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        public translateService: TranslateService,
        private siteContextService: SiteContextService,
        private stateStorageService: StateStorageService,
        private toast: ToastService,
        private accountService: AccountService,
        private principal: Principal,
        private orderNumHelperService: OrderNumHelperService,
        public cdRef : ChangeDetectorRef,
    ) { 
        afterNextRender(()=>{
            this.setAccountMenuPosition();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        this.setAccountMenuPosition();
    }

    /**
     * This method set dropdown menu's position, either expand upwards or downwards depends on current container status.
     * This method should be called in ngAfterViewInit() and on window resize event.
     * This method only sets the menu's position, it does not show or hide the dropdown menu.
     */
    private setAccountMenuPosition() {
        const profileWrapper = this.document.querySelector('.user-profile-wrapper');
        if (!profileWrapper) {
            return; // This may be the case when at mobile view, no need to set position
        }
        const avatarButton = profileWrapper.querySelector('.avatar-button');
        const dropdownMenu = this.document.querySelector('.account-menu') as HTMLElement;

        const buttonRect = avatarButton.getBoundingClientRect();
        const spaceBelow = this.document.defaultView.innerHeight - buttonRect.bottom;
        const spaceAbove = buttonRect.top;

        if (spaceBelow < dropdownMenu.offsetHeight && spaceAbove > dropdownMenu.offsetHeight) {
            // Not enough space below and enough space above
            // Set style top to auto, bottom to 100%, this is the position above the button
            dropdownMenu.style.top = 'auto';
            dropdownMenu.style.bottom = '100%';
        } else {
            // Default positioning below the button
            dropdownMenu.style.top = '100%';
            dropdownMenu.style.bottom = 'auto';
        }
        this.cdRef.markForCheck();
        this.cdRef.detectChanges();
    }

    switchDropdownMenu(show: boolean) {
        this.showDropdownMenu =  show;
        this.cdRef.markForCheck();
    }

    consoleLog(msg: string) {
        console.log(msg);
    }

    signOut() {
        this.toast.hide();
        this.toast.success(this.translateService.instant('GENERAL.SIGN_OUT_SUCCESSFULLY') || '退出登录成功', 1000);
        this.accountService.clearUserInfo();
        firstValueFrom(this.principal.logout()).then(() => { });
        this.siteContextService.loginOut();
        this.stateStorageService.clearUrl();
        this.orderNumHelperService.clear();
        this.router.navigate(['home'], {
            replaceUrl: true
        });
  }
}
