<div class="d-flex justify-content-center align-content-center" id="navigation-block-container"
    [style.backgroundColor]="_navigationBackgroundColor">
    <div class='position-relative navigation-container'
        [ngClass]="{'py-1':isMobile,'full-width-mode':(_renderOptions || _data)?.fullWidth}"
        style="box-sizing: border-box;" [style.padding-top]="contentExpansionHeight + '!important'"
        [style.padding-bottom]="contentExpansionHeight + '!important'" [style.padding-left]="menuLeftPadding"
        [style.position]="(_renderOptions || _data)?.navigation?.position"
        [style.backgroundColor]="(_renderOptions || _data)?.navigation?.['background-color']"
        data-skeleton-bgcolor="#eeeeee">
        <div style="flex: 1;overflow: hidden;" #horizationMenuContaniner>
            <div class="d-flex align-items-center" #horizationMenuContent
                [ngClass]="{'justify-content-end':(_renderOptions || _data)?.navigation?.menuHorizontalDirection === 'right'}">
                <div class="logo" [title]="'GENERAL.GO_TO_HOME_BTN_TEXT' | translate" style="cursor: pointer;"
                    [routerLink]="['/']" [ngStyle]="{height:'calc(35px + ' + contentExpansionHeight + ')',
                    'min-width': 'calc(35px + ' + contentExpansionHeight + ')'}"
                    [ngClass]="{'position-static':logoPositionStatic}"
                    *ngIf="((_renderOptions || _data)?.logoVisible !== false) && logo" #loginContainerRef>
                    <p><img [src]="logo" data-skeleton-bgcolor="white" appPureImgErrorIdentification
                            [setErrorImg]="true" loadFailedImage="/assets/icon/img-blank.svg"
                            (loadImgEvent)="calcMenuWidthAndHiddenOutRangeItems()" />
                    </p>
                </div>
                <ng-container *ngFor="let menu of _showSiteMenu?.items">
                    <div class="menu-item-container"  *ngIf="menu?.enabled && isMobile"
                        (click)="navigateMenuPage(menu)" [ngClass]="{active: menu?.id === currentMenuItem?.id}">
                        <div>
                            <i class="{{(menu?.iconClassKey | appIconClassKey: menu?.id === currentMenuItem?.id)}}"></i>
                        </div>
                        <p data-skeleton-bgcolor="white" style="text-transform: capitalize;"
                            title="{{menu | mTitleTranslate}}">
                            {{menu | mTitleTranslate}}</p>
                    </div>
                    <span *ngIf="menu?.enabled && !isMobile" [attr.data-menu-id]="menu?.id" #horizationMenuItem>
                        <app-menu [menu]="menu" [menuHoverStyle]="(_renderOptions || _data)?.navigation?.menuHoverStyle"
                            [contentExpansionHeightSize]="(_renderOptions || _data)?.navigation?.contentExpansionHeightSize">
                        </app-menu>
                    </span>
                </ng-container>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <!-- 搜索按钮-输入框模式 -->
            <div *ngIf="((_renderOptions || _data)?.searchBtnType === 'LIKE_INPUT_BTN')||(!(_renderOptions || _data)?.searchBtnType)"
                class="search b-labr" routerLink="/search-page">
                <p class="search-content"><i class="iconfont icon-sousuo"></i>
                    <span [appTranslate]="'GENERAL.SEARCH'" style="white-space: nowrap;">搜索</span>
                </p>
            </div>
            <div class="d-flex control-btns-container"
                [ngClass]="{'align-items-end':((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE'),
            'align-items-center':!(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE'))}">
                <!-- 搜索按钮-图标模式 -->
                <div class="align-items-center d-flex flex-column flex-wrap justify-content-between menu-item-btn px-2"
                    *ngIf="(_renderOptions || _data)?.searchBtnType === 'ICON_BTN'" [routerLink]="[ '/search-page' ]">
                    <i class="bi bi-search shopping-cart-menu-btn" [style.fontSize]="(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')) ? '1.3rem' : (
                        (_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'SMALL')
                         ? '1.1rem' : ''"
                        style="{{(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG') || ((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')) ? 'position: relative;top: -1px;' : ''}}"></i>
                    <div class="w-100 d-flex justify-content-center align-items-end" style="white-space: nowrap;"
                        [appTranslate]="'GENERAL.SEARCH'"
                        *ngIf="((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG') || ((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')">
                        搜索</div>
                </div>
                <!-- 登录注册按钮 and 账户信息 -->
                <div class="align-items-center d-flex flex-wrap justify-content-between menu-item-btn account-login-btn"
                    style="position: relative;cursor: pointer;height:100%;"
                    [ngClass]="{'flex-column':!((_renderOptions || _data)?.accountLoginBtnType !== 'ONLY_LOGIN_ICON' && !isLogin)}"
                    *ngIf="(_renderOptions || _data)?.accountLoginBtnType !== 'NONE'">
                    <app-user-profile-menu *ngIf="isLogin;else loginButtonRef;" [username]="userName"
                        [avatarImgSize]="'1.5rem'"
                        [enableWrap]="((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')"
                        [userAvatarUrl]="userAvatarUrl">
                    </app-user-profile-menu>
                </div>
                <!-- 购物车按钮 -->
                <div class="align-items-center d-flex flex-column flex-wrap justify-content-between menu-item-btn px-2 shopping-cart-btn"
                    *ngIf="(_renderOptions || _data)?.shoppingCartBtnVisible" [routerLink]="[ '/shopping-cart' ]">
                    <i class="bi bi-cart shopping-cart-menu-btn"
                        style="{{(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG') || ((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')) ? 'position: relative;top: 3px;' : 'margin-top: 3px;'}}"
                        [style.fontSize]="(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')) ? '1.5rem' : (
                            (_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'SMALL')
                             ? '1.3rem' : ''"></i>
                    <div class="w-100 d-flex justify-content-center align-items-end"
                        [appTranslate]="'FLOATING_WINDOW.GO_TO_SHOPCART'" style="white-space: nowrap;"
                        *ngIf="((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG') || ((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')">
                        购物车</div>
                </div>
                <!-- 翻译按钮 -->
                <div class="menu-item-btn text-center" style="display: flex;flex-wrap: wrap;justify-content: center;"
                    *ngIf="_renderOptions?.languageTranslationBtnVisible">
                    <ng-container *ngTemplateOutlet="languageSwitchRef"></ng-container>
                    <div class="w-100 d-flex justify-content-center align-items-end"
                        style="padding-top: 0.25rem;white-space: nowrap;"
                        [appTranslate]="selectedLang() | findLanguageFromKey"
                        (click)="$event.stopPropagation(); languageNgbDropdownRef.open();"
                        *ngIf="((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG') || ((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')">
                        翻译</div>
                </div>
            </div>
            <ng-template #loginButtonRef>
                <!-- 如果是按钮 -->
                <ng-container *ngIf="(_renderOptions || _data)?.accountLoginBtnType !== 'ONLY_LOGIN_ICON'">
                    <div Button [inline]="true" [size]="'small'" style="padding: 0 15px;cursor: pointer;"
                        routerLink="/auth/default-login" title="{{'GENERAL.LOGIN' | translate}}"
                        [appTranslate]="'GENERAL.LOGIN'">登录</div>
                    <div Button [inline]="true" [size]="'small'"
                        style="padding: 0 15px;margin-left: 10px; cursor: pointer;" routerLink="/auth/default-register"
                        title="{{'GENERAL.REGISTER' | translate}}" [appTranslate]="'GENERAL.REGISTER'">
                        注册</div>
                </ng-container>
                <!-- 如果登陆注册是仅登录图标 -->
                <div class="d-flex flex-wrap justify-content-center px-0 menu-item-btn" routerLink="/auth/default-login"
                    *ngIf="(_renderOptions || _data)?.accountLoginBtnType === 'ONLY_LOGIN_ICON'">
                    <i class="iconfont icon-wode shopping-cart-menu-btn" [style.fontSize]="(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')) ? '1.4rem' : (
                                (_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'SMALL')
                                 ? '1.2rem' : ''"></i>
                    <span class="d-flex justify-content-center align-items-end menu-item-btn w-100"
                        style="padding-top: 0.25rem;white-space: nowrap;" [appTranslate]="'GENERAL.LOGIN'"
                        title="{{'GENERAL.LOGIN' | translate}}"
                        *ngIf="((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG') || ((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')">
                        登录</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<ng-template #languageSwitchRef>
    <div class="language-switch"
        style="height: 1.4rem;display: flex!important;justify-content: center;align-items: center;" ngbDropdown
        #languageNgbDropdown="ngbDropdown" container="body">
        <a class="menu-link p-0" style="display: flex;align-items: center;" ngbDropdownToggle>
            <span class="menu-title position-relative" style="display: inline-flex;">
                <span class="fs-8 rounded d-inline-flex flex-row-reverse" style="cursor: pointer;">
                    <i class="bi bi-globe language-translation-selector" style="display: flex;align-items: center;"
                        [style.fontSize]="(((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'BIG')||((_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'LARGE')) ? '1.3rem' : (
                        (_renderOptions || _data)?.navigation?.contentExpansionHeightSize === 'SMALL')
                         ? '1.1rem' : ''"></i>
                </span>
            </span>
        </a>
        <div class="menu-sub menu-sub-dropdown w-150px py-1 translate-option-item border-0" ngbDropdownMenu>
            <div class="menu-item px-0" *ngFor="let country of countries" ngbDropdownItem>
                <a (click)="setLang(country.langKey)" class="menu-link d-flex px-3"
                    [ngClass]="{ active: country.langKey === selectedLang() }">
                    {{country.langKey | findLanguageFromKey}}
                </a>
            </div>
        </div>
    </div>
</ng-template>
