import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { SiteSecuritySetting } from '../model/site-security-settings.model';

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
type EntityResponseType = HttpResponse<SiteSecuritySetting>;
type EntityArrayResponseType = HttpResponse<SiteSecuritySetting[]>;
@Injectable({ providedIn: 'root' })
export class SiteSecuritySettingService {
    private resourceUrl = API_SERVICE_PREFIX + '/user/api/site-security-settings';

    constructor(
        private http: HttpClient,
    ) { }

    create(siteSecuritySetting: SiteSecuritySetting): Observable<EntityResponseType> {
        return this.http
            .post<SiteSecuritySetting>(this.resourceUrl, siteSecuritySetting, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(siteSecuritySetting: SiteSecuritySetting): Observable<EntityResponseType> {
        return this.http
            .put<SiteSecuritySetting>(`${this.resourceUrl}/${siteSecuritySetting.id}`, siteSecuritySetting,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseType) => res));
    }

    patch(siteSecuritySetting: SiteSecuritySetting): Observable<EntityResponseType> {
        return this.http
            .patch<SiteSecuritySetting>(`${this.resourceUrl}/${siteSecuritySetting.id}`, siteSecuritySetting,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http
            .get<SiteSecuritySetting>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityResponseType) => res));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        return this.http
            .get<SiteSecuritySetting[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    count(req?: any): Observable<number> {
        return this.http
            .get<number>(this.resourceUrl + '/count', { params: req, headers: headerOptions });
    }
}
