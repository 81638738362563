import { Component, OnInit, Input } from '@angular/core';
import { SessionStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { SalesOrderService } from '../../../../builtin-pages/user-center/pages/all-order-page/_service/sales-order.service';
import { CustomerCouponService } from '../../../../builtin-pages/user-center/pages/coupon/_service/customer-coupon.service';
import { AuthServerProvider } from '../../../../core/auth/auth-jwt.service';
import { SiteContextService } from '../../../../shared/services/site-context.service';
import { ShopContextService } from '../../service/shop-context.service';
import { CouponStatus } from '../../utils/couponStatus';

@Component({
    selector: 'app-new-coupon',
    templateUrl: './new-coupon.component.html',
    styleUrls: ['./new-coupon.component.scss']
})
export class NewCouponComponent {
    animation = false;
    maskType = false;
    hide = true;
    @Input() shopId = '';
    constructor(
        private customerCouponService: CustomerCouponService,
        private salesOrderService: SalesOrderService,
        private siteContextService: SiteContextService,
        private $sessonStorage: SessionStorageService,
        private authServerProvider: AuthServerProvider,
        private shopContextService: ShopContextService,
    ) { }
    couponData = [];
    coupon = [];
    tabindex = null;
    CouponsOrNot = null;
    customerId = this.siteContextService.customer()?.id;
    couponClose(e) {
        e.stopPropagation();
        CouponStatus.isCouponMaskCanceld = true;
        this.hide = true;
        this.$sessonStorage.store('index-new-coupon-close', true);
    }
    // 判断是否有新人优惠券
    isHaveCoupons(storeId: string) {
        if (this.authServerProvider.getToken()) {
            const siteId = this.siteContextService.siteId;
            this.customerCouponService.customerRegisterNotReceivedCouponQuery({
                'siteId.equals': siteId
            })
                .subscribe(resp => {
                    if (resp?.body['length']) {
                        this.hide = false;
                        for (let i = 0; i < resp.body['length']; i++) {
                            const res = resp.body;
                            this.coupon.push(res[i].id);
                        }
                    }
                });
        }
    }

    /* 获取当前用户的订单大于1就不是新用户 */
    getCurrentUserOrderInfo(shopId: string) {
        if (this.authServerProvider.getToken()) {
            this.customerId = this.siteContextService.customer()?.id;
            if (!this.customerId) {
                return;
            }
            this.salesOrderService.count({
                'customerId.equals': this.customerId,
                'cancelled.equals': false,
                'paid.equals': true
            }).toPromise().then(res => {
                if (!res?.body) {
                    this.isHaveCoupons(shopId);
                }
            });
        }
    }

    initialize() {
        // 如果已经点击了关闭就不要一直弹了。
        if (!this.$sessonStorage.retrieve('index-new-coupon-close')) {
            if (this.shopId) {
                this.getCurrentUserOrderInfo(this.shopId);
            } else {
                this.shopId = this.shopContextService.shopId;
                this.getCurrentUserOrderInfo(this.shopId);
            }
        }
    }

}
