<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [disabledOverflowHidden]="true" [renderOptions]="renderOptions" [enabledMoreBtn]="false" [section]="section"
    *ngIf="data?.items?.length">
    <div class="feature-list">
        <div class="feature-list-item-wrapper b-r"
            *ngFor="let featureListItem of data?.items;let odd = odd;let even = even;" [ngClass]="{
                'is-odd-card':odd,
                'is-even-card':even,
                'big-spacing-card':(data?.cardSpacing === CardSpacingType.BIG)&&!((toNumber(data?.columns) === 4)&&!renderOptions?.fullWidth),
                'three-D-shadow-card':(data?.cardStyle === CardStyle['3D']),
                'vertical-feature-item-for-mobile':(data?.imageSize === ImageSizeType.SQUARE)&&currentDeviceService?.isMobile(),
                'feature-item-for-two-columns':(toNumber(data?.columns) === 2),
                'feature-item-for-three-columns':(toNumber(data?.columns) === 3),
                'border p-4':(data?.cardStyle === CardStyle?.BORDER)
            }">
            <div class="feature-list-item" [ngClass]="{
                'flat-card':(data?.cardStyle === CardStyle['FLAT']) || !data?.cardStyle,
                'd-flex':(toNumber(data?.columns) === 2)
            }">
                <div class="position-relative feature-image-wraper" [ngClass]="{
                    'square-feature-image-wrapper':data?.imageSize === ImageSizeType.SQUARE
                }">
                    <img class="feature-list-item-image"
                        [ngClass]="{'b-r overflow-hidden':data?.cardStyle === CardStyle['NONE']}" [decode]="true"
                        offset="100" appLoadingAnimationAndErrorBackground
                        [lazyLoad]="(featureListItem?.mediaValue | uploadUrlPrefix) | imgResize: 'w_750'"
                        appPureImgErrorIdentification [setErrorImg]="true"
                        loadFailedImage='/assets/icon/img-blank.svg' />
                </div>
                <div class="py-3" [ngClass]="{
                    'px-3':(data?.cardStyle !== CardStyle['NONE'])||((toNumber(data?.columns) === 2)),
                    'w-100':!((toNumber(data?.columns) === 2)),
                    'pt-4':(data?.cardStyle === CardStyle?.BORDER)
                }" style="box-sizing: border-box;" [style.flex]="(toNumber(data?.columns) === 2) ? '1' : ''">
                    <div class="ql-container ql-snow w-100" style="border: none;"
                        *ngIf="data?.contentStyleType !== ContentStyleType.DEFAULT">
                        <div class="ql-editor b-r feature-list-item-content p-0"
                            [innerHTML]="(featureListItem | mNameTranslate:'content') | uploadUrlPrefix:'true'">
                            -
                        </div>
                    </div>
                    <div *ngIf="data?.contentStyleType === ContentStyleType.DEFAULT">
                        <h4 class="feature-list-item-title" [ngClass]="{
                            'fw-normal':(data?.cardStyle === CardStyle?.BORDER)
                        }" [style]="getElStyle(featureListItem,'titleRenderOptions')"
                            [appFontSizeScale]="getElFontSizeScale(featureListItem,'titleRenderOptions')">
                            {{featureListItem | mNameTranslate:'title'}}
                        </h4>
                        <div class="feature-list-item-desc" [style]="getElStyle(featureListItem,'descRenderOptions')"
                            [appFontSizeScale]="getElFontSizeScale(featureListItem,'descRenderOptions')">
                            {{featureListItem | mNameTranslate:'desc'}}
                        </div>
                    </div>
                    <div class="d-flex align-items-center pt-3" [ngClass]="{
                        'justify-content-center':data?.cardStyle !== CardStyle['NONE']
                    }" *ngIf="data?.enabledActionButton">
                        <button class="btn bg-warning btn-reflush ellipsis" style="cursor: pointer;"
                            [ngClass]="{'w-100':data?.cardStyle  === CardStyle?.NONE}"
                            (click)="navigateToSomePage(featureListItem)" type="submit" [title]="featureListItem |
                            mNameTranslate:'button'">{{featureListItem |
                            mNameTranslate:'button'}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-block-container>