import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Dayjs } from 'dayjs/esm';
import { Observable } from 'rxjs';
import { PaymentMethod } from '../../../../../ecommerce/shared/model/payment-method.enum';
import { API_SERVICE_PREFIX } from '../../../../../app.constants';

// 每个 CashSettlement 都会包含一个 orders 集合，里面对应 OrderConversion 里面的 n 条记录
export enum PartnerType {
    AFFILIATE = 'AFFILIATE',
    REFERRAL = 'REFERRAL',
}

export enum RewardStatus {
    PAID = 'PAID',
    UNPAID = 'UNPAID',
}

// 每个 CashSettlement 都会包含一个 orders 集合，里面对应 OrderConversion 里面的 n 条记录
export class CashSettlement {
    constructor(value?: CashSettlement) {
        if (value) {
            Object.keys(value)?.forEach(key => this[key] = value[key]);
        }
    }
    id?: number;
    partnerType?: PartnerType;
    // network name
    partnerNetwork?: string;
    partnerCode?: string;
    // Referrer's or affiliate's customer ID, optional for affiliates
    customerId?: string;
    siteId?: string;
    shopId?: string;
    /**
     * settlement amount
     */
    amount?: number;
    /**
     * Currency code
    */
    currency: string;
    /**
    * Status: PENDING, SETTLED, CANCELLED
    */
    status?: RewardStatus;
    /**
     * Settlement date
     */
    settlementDate?: Dayjs;
    /**
     * Settlement period start date
     */
    periodStartDate?: Dayjs;
    /**
     * Settlement period end date
     */
    periodEndDate?: Dayjs;
    /**
     * Payment date
     */
    paymentDate?: Dayjs;
    /**
     * Payment method
     */
    paymentMethod?: PaymentMethod;
    // Payment method detail such as bank account number, paypal email etc.
    paymentMethodDetails?: string;
    /**
     * Payment reference such as transaction ID
     */
    paymentReference?: string;
    remarks?: string;
    createdBy?: string;
    tlementDate?: Dayjs;
}

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
@Injectable({ providedIn: 'root' })
export class CashSettlementService {
    public resourceUrl = API_SERVICE_PREFIX + '/payment/api/cash-settlements';
    constructor(
        private http: HttpClient
    ) { }

    create(cashSettlement: CashSettlement): Observable<HttpResponse<CashSettlement>> {
        return this.http.post<CashSettlement>(this.resourceUrl, cashSettlement, { observe: 'response' });
    }

    query(req?: any): Observable<HttpResponse<CashSettlement[]>> {
        return this.http.get<CashSettlement[]>(this.resourceUrl, {
            params: req,
            headers: headerOptions,
            observe: 'response'
        });
    }

    find(id: number): Observable<HttpResponse<CashSettlement>> {
        return this.http.get<CashSettlement>(this.resourceUrl + '/' + id, {
            headers: headerOptions,
            observe: 'response'
        });
    }
}
