export const sitesTranslate = {
    ERROR: {
        CREATE_SITE_ERROR: 'サイトアプリケーションの作成中にエラーが発生しました：',
        COMPANY_INFO_ERROR: '会社情報の取得中にエラーが発生しました：',
        GET_SITE_ERROR: 'サイトアプリケーションの取得中にエラーが発生しました：',
        UPDATE_SITE_ERROR: 'サイトアプリケーションの更新エラー：',
        NOT_FOUND_SITE: '現在のページに対応するサイトが見つかりません。確認して再入力してください',
        GET_APP_ERROR: 'アプリの取得中にエラーが発生しました：',
        LOAD_PAGE_EMPTY: 'このページはまだ改修中です、後でもう一度お試しください',
        LOAD_SITE_ERROR: 'このサイトは現在利用できません。システム管理者に連絡するか、後でもう一度お試しください',
    },
    GENERAL: {
        CURRENT_APPLICATION: '現在のアプリケーション',
        NOTYETOPEN_CANNOTBEUSED: 'まだ開いていないので使えません！'
    }
}