export const menuTranslate = {
    HOME: '首页',
    ASIDE_NAV_MENUS: {
        APPS: {
            TITLE: '应用'
        }
    },
    USER_PROFILE_MENUS: {
        INFOMATION: {
            TITLE: '个人资料'
        },
        ACCOUNT_SECURITY: {
            TITLE: '账号安全',
            CHANGE_PASSWORD: {
                TITLE: '设置密码'
            },
            BIND_PHONE: {
                TITLE: '绑定手机'
            },
        },
    },
    ASIDE_FOOTER_MENUS: {
        ACCOUNT_MANAGEMENT: {
            TITLE: '账号管理',
            ADMIN_USER: {
                TITLE: '管理员账号'
            },
            OTHER_USER: {
                TITLE: '其他账号'
            },
        },
        ORGANIZATIONAL_STRUCTURE: {
            TITLE: '组织架构',
            EMPLOYEES_POSTS: {
                TITLE: '人员与职务',
                EMPLOYEES: {
                    TITLE: '人员管理'
                },
                POSTS: {
                    TITLE: '职务管理'
                }
            },
            ORGANIZATIONS_DEPARTMENTS: {
                TITLE: '组织与部门',
                DEPARTMENTS: {
                    TITLE: '部门管理'
                },
                ORGANIZATIONS: {
                    TITLE: '组织管理'
                }
            }
        },
        SYSTEM_SETTING: {
            TITLE: '系统设置',
            WECHAT_MP_CONFIG: {
                TITLE: '微信公众号配置'
            },
            WECHAT_OPEN_CONFIG: {
                TITLE: '微信开放平台配置'
            }
        },
    },
}