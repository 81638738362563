import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { SiteGraph } from '../model/site-graph.model';

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({
    providedIn: 'root',
})
export class SiteGraphService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/site-graph';

    constructor(
        private http: HttpClient,
    ) { }

    getSiteGraph(req?: any): Observable<HttpResponse<SiteGraph>> {
        return this.http
            .get<SiteGraph>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: HttpResponse<SiteGraph>) => res));
    }
}
