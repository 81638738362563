import { Dayjs } from 'dayjs/esm';
import { LayoutBlock } from './layout-block.model';

export class Layout {
    constructor(layouts?: Layout) {
        if (layouts) {
            for (const key in layouts) {
                if (key) {
                    this[key] = layouts[key];
                }
            }
        }
    }
    id?: string;
    siteId: string;
    name: string;
    nameTranslation?: string;
    // 排序数
    seqNum: number;
    // 是否是内置的页面
    builtIn: boolean;
    renderOptions?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    blocks?: LayoutBlock[];
    new?: boolean;
}

export const defaultSiteLayout = {
    "name": "DEFAULT",
    "nameTranslation": null,
    "seqNum": 0,
    "builtIn": false,
    "renderOptions": JSON.stringify({
        theme: "simple-white"
    }),
    siteId: null,
    blocks: [{
        "section": "HEADER",
        "blockType": "HEADER",
        "enabled": true,
        "title": "站点导航",
        "titleTranslation": "{\"zh-TW\":\"站點導航\",\"en-US\":\"Navigation\",\"ja\":\"ナビゲーション\",\"zh-CN\":\"站点导航\"}",
        "data": "null",
        "seqNum": 0,
        "builtIn": false,
        "renderOptions": "{\"logoVisible\":true,\"searchVisible\":true,\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"},\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"0\",\"padding-bottom\":\"0\"},\"navigation\": {\"position\":{ \"top\": \"0\"}, \"padding-top\":\"1rem\",\"padding-bottom\":\"1rem\", \"background-color\": \"\"}}",
    }, {
        "section": "FOOTER",
        "blockType": "FOOTER",
        "enabled": true,
        "title": "页尾",
        "titleTranslation": "{\"zh-TW\":\"頁尾\",\"en-US\":\"Footer\",\"ja\":\"フッター\",\"zh-CN\":\"页尾\"}",
        "data": "null",
        "seqNum": 0,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"none\",\"text-align\":\"left\",\"padding-top\":\"0\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1.5rem\"}}",
    }]
}
