import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../../../../app.constants';
import { ISalesOrder } from '../_model/sales-order.model';
import { map } from 'rxjs/operators';
import { ShopContextService } from '../../../../../ecommerce/shared/service/shop-context.service';

type EntityResponseType = HttpResponse<ISalesOrder>;
type EntityArrayResponseType = HttpResponse<ISalesOrder[]>;

@Injectable({ providedIn: 'root' })
export class SalesOrderService {
    private resourceUrl = API_SERVICE_PREFIX + '/oms/api/sales-orders';

    getShopResourceUrl() {
        return `${API_SERVICE_PREFIX}/oms/api/shop/${this.shopContextService.shopId}/sales-orders`;
    }

    constructor(
        private http: HttpClient,
        private shopContextService: ShopContextService,
    ) { }

    querySalesOrder(query?: any): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.getShopResourceUrl()}`, { params: query, observe: 'response' });
    }

    findSalesOrder(salesOrderId?: number): Observable<HttpResponse<any>> {
        return this.http.get<ISalesOrder[]>(`${this.getShopResourceUrl()}?id.equals=${salesOrderId}`, { observe: 'response' })
            .pipe(map((salesOrdersRes): EntityResponseType => {
                const salesOrder = salesOrdersRes.body?.length ? salesOrdersRes.body[0] : null;
                return {
                    ...salesOrdersRes,
                    body: salesOrder
                } as EntityResponseType;
            }));
    }

    cancelSalesOrder(id: number): Observable<EntityResponseType> {
        return this.http
            .put<ISalesOrder>(`${this.getShopResourceUrl()}/cancel?id=${id}`, '', { observe: 'response' });
    }

    create(salesOrder: ISalesOrder): Observable<EntityResponseType> {
        return this.http
            .post<ISalesOrder>(this.getShopResourceUrl(), salesOrder, { observe: 'response' });
    }

    update(salesOrder: ISalesOrder): Observable<EntityResponseType> {
        return this.http
            .put<ISalesOrder>(this.getShopResourceUrl(), salesOrder, { observe: 'response' });
    }

    updateSalesOrderItems(SalesOrderItem: ISalesOrder): Observable<EntityResponseType> {
        return this.http
            .put<ISalesOrder>(`/oms/api/sales-order-items`, SalesOrderItem, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http
            .get<ISalesOrder[]>(`${this.getShopResourceUrl()}?id.equals=${id}`, { observe: 'response' })
            .pipe(map((salesOrdersRes): EntityResponseType => {
                const salesOrder = salesOrdersRes.body?.length ? salesOrdersRes.body[0] : null;
                return {
                    ...salesOrdersRes,
                    body: salesOrder
                } as EntityResponseType;
            }));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const headers = new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        });
        return this.http
            .get<ISalesOrder[]>(this.getShopResourceUrl(), { params: req, observe: 'response', headers });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.getShopResourceUrl()}/${id}`, { observe: 'response' });
    }

    count(req?: any): Observable<HttpResponse<number>> {
        const headers = new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        });
        return this.http.get<number>(`${this.resourceUrl}/count`, { params: req, observe: 'response', headers });
    }
}
