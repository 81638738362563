import { accountManagementTranslate } from './_modules/account-management.translate';
import { affiliateTranslate } from './_modules/affiliate.translate';
import { authTranslate } from './_modules/auth.translate';
import { contentManagementTranslate } from './_modules/content-management.translate';
import { invoiceCenterTranslate } from './_modules/invoice-center.translate';
import { menuTranslate } from './_modules/menu.translate';
import { orderTranslate } from './_modules/order.translate';
import { sitesTranslate } from './_modules/sites.translate';
import { systemSettingTranslate } from './_modules/system-setting.translate';
import { userTranslate } from './_modules/user.translate';

// Japan
export const locale = {
    lang: 'ja',
    data: {
        AUTH: authTranslate,
        MENU: menuTranslate,
        ACCOUNT_MANAGEMENT: accountManagementTranslate,
        SYSTEM_SETTING: systemSettingTranslate,
        SITES: sitesTranslate,
        ORDER: orderTranslate,
        CONTENT_MANAGEMENT: contentManagementTranslate,
        AFFILIATE: affiliateTranslate,
        INVOICE_CENTER: invoiceCenterTranslate,
        GENERAL: {
            OWNER: '所有者',
            UNPAID: '未払い',
            PAID: '有償',
            EXPIRED: '期限が切れる',
            INVOICE_CENTER: 'インボイス',
            SERIES_PAGE: 'シリーズ商品',
            CALLING_CODE_SELECTOR_TITLE: '国/地域コードを選択する',
            CALLING_CODE_SELECTOR_PLACEHOLDER: '検索する国/地域',
            REFERRAL_HISTORY_TEXT: '推奨の記録',
            RULE_TEXT: '活動ルール',
            REFERRAL_ACTIVITY_TITLE_TEXT: '友達を招待して特典をゲット',
            SHARE_TO_NEW_PEOPLE_TEXT: 'リファーラルURLをコピーする',
            COPY_YOUR_REFERRAL_CODE_TEXT: '紹介コード：{{myReferralCode}}',
            YOUR_REFERRAL_PERSON_TEXT: '私の紹介者です：{{referrerId}}',
            SAVE: '保存',
            LOAD_ORDER_INFO_TIPS: '注文データの計算中です、しばらくお待ちください...',
            LOAD_INFO_TIPS: 'データをロードしています……',
            TAX_INCLUDED: '税込',
            CANT_CREATE_ORDER_FOR_ERROR_MESSAGE: '現在の注文が存在しないか、データが無効です。再送信してください',
            GROUP_BUYING_CREATER_DISCOUNT: 'グループリーダー割引',
            GET_USER_INFO_FAILED: 'ID情報を取得できませんでした。しばらくしてからもう一度お試しください。',
            PICK_UP_PHONE_INVALID_TIPS: '荷受人の電話番号が正しい形式ではありません',
            PICK_UP_NAME_EMPTY_TIPS: '荷受人の名前を空にすることはできません',
            PICK_UP_TIME_TIPS: 'ピックアップ時間を選択してください',
            ORDINARY_EXPRESS: '普通配送便',
            GROUP_BUYING_FREE_SHIPPING: '共同購入送料無料',
            FREE_SHIPPING: '送料無料',
            PHONE: '携帯番号',
            HOW_TO_GET_THE_CARD_PASSWORD: 'カードの入手方法',
            HOW_TO_GET_THE_CARD_PASSWORD_DESC: 'お支払いが完了したら、この方法でカードのパスワードを取得する必要があります',
            GIFT_CARD_INFORMATION: 'ギフトカード情報',
            ALL_BOUND_TO_CURRENT_ACCOUNT: 'すべてが現在のアカウントにバインドされています',
            LOGISTICS_COMPANY_NUMBER_INVALID_ERROR: '追跡番号の形式が正しくありません',
            LOGISTICS_COMPANY_NAME_EMPTY_ERROR: '物流会社名を空にすることはできません',
            FILL_IN_THE_LOGISTICS_COMPANY: '物流会社に記入する',
            EVALUATE_SUCCESS: '成功の評価',
            EVALUATE_INVALID_ERROR: '少なくとも5つの単語にコメントしてください！ ',
            QUERY_BTN_TEXT: 'お問い合わせ',
            ADD_BTN_TEXT: '追加',
            EDIT_BTN_TEXT: '編集',
            FILL_IN_BTN_TEXT: '埋める',
            DELETE_BTN_TEXT: '削除',
            SAVE_BTN_TEXT: '保存',
            CANCEL_BTN_TEXT: 'キャンセル',
            CHANGE_TEXT: '変更',
            LOGIN: 'ログイン',
            REGISTER: '登録',
            REGISTER_BTN_TEXT: 'アカウントを作成',
            SEARCH: '検索',
            PRODUCT_DEFAULT_NAME: '商品',
            INDEX: 'ホーム',
            CUSTOMERIZE: 'カスタマイズ',
            CATEGORY: '分類',
            FEATURE: '特徴',
            SHOPPING_CART: 'カート',
            ABOUT: '約',
            USER_CENTER: 'アカウントセンター',
            CUSTOMIZE: 'カスタマイズ',
            LOAD: '読み込みます',
            LOADING: '読み込み中',
            SIGN_OUT_SUCCESSFULLY: 'ログインを終了しました',
            WECHAT_BINDING_IS_SUCCESSFUL: 'WeChatバインディングに成功!',
            WECHAT_BINDING_FAILED: 'WeChat バインドに失敗しました',
            HOT_SALE: 'ホット販売',
            LABEL: 'ラベル',
            NEW: '最新の',
            PRE_SALE: 'プレセール',
            SERIES: 'シリーズ',
            FILL_OUT: '記入してください',
            SEE_MORE: 'もっと見る',
            CURRENTLY_NO_RECORDS: '申し訳ありませんが、現在記録はありません',
            FAILED: '失敗',
            RELOAD_BTN_TEXT: '再試行',
            NONAME: '匿名',
            NONE: 'なし',
            SETTING: '設定',
            LOGIN_REGISTER: 'ログイン/登録',
            ALL: 'すべて',
            LOADED_ALL: 'すべてがロードされました',
            LOAD_FAILED: '読み込みに失敗しました',
            LOAD_POST_FAILED: '記事が見つからないか、削除されました',
            LOAD_PRODUCT_FAILED: '{{productName}}が見つからないか、棚から降りた',
            LOAD_FORM_FAILED: 'フォームが見つからないか、削除されました',
            LOAD_LABEL_PRODUCT_FAILED: '関連する{{productName}}が見つからない、または棚から削除されました。',
            RE_APPLY_BTN_TEXT: '再度適用します',
            REVIEW_APPLIED_CONTENT_BTN_TEXT: '記入してください',
            GO_TO_HOME_BTN_TEXT: 'トップページへ',
            SUBMIT: '送信',
            SOME_ERROR_OCCUR: '例外が発生しました',
            TIPS: 'ヒント',
            SUBMITTED_SUCCESSFULLY: 'コミットは成功しました',
            SUBMITTED_FAILED: '送信に失敗しました',
            RETURNING_TO_HOME_PAGE_TIPS: 'ホーム ページに戻っています',
            RETURNING_TO_HOME_PAGE_SECONDS_TIPS: '{{secondNumber}}秒后，自动为您返回首页',
            PAGE_NOT_FOUND: 'ページが見つかりませんでした',
            SELECT_ALL_BTN_TEXT: '完全選択',
            INFO: '情報',
            UNIT_PRICE: '単価',
            QUANTITY: '数量',
            PRICE: '金額',
            OPERATION: '操作する',
            MODIFY_AMOUNT: '金額を変更します',
            OFF_SHELF: '完売',
            GO_TO_SETTLEMENT: '決済に行く',
            TOTAL: '合計',
            SELECTED: '選択されています',
            PIECES: '個',
            OUT_OF_STOCK: '在庫なし',
            ONLY: '残りは1つだけ',
            UPDATE_FAILED: '読み込みに失敗しました',
            CALC_DELIVERY_FAILED: '计算配送费调用失败,请检查网络设置后重试',
            SUCCESS: '成功',
            DELETING_DATA_IN_PROGRESS: '削除中',
            LET_ME_THINK_AGAIN: 'もう一度考えてみよう',
            YOU_DIDNT_SELECTED_SOMETHING: '選択の余地はない!',
            NO_PURCHASABLE_ITEMS_TO_CHOOSE_TIPS: '選択する購入不可能な商品!',
            PLEASE_SELECTED: '選択してください',
            WHETHER_TO_DELETE: '削除するかどうか',
            INCORRECT_FORMAT_TIPS: '形式が正しくありません',
            PRICE_OF_PRODUCT: '価格',
            ORIGINAL_PRICE_OF_PRODUCT: 'オリジナル',
            SALE_QUANTITY: '販売済み',
            PRODUCT_TOTAL_COMMENTS: '累计评论',
            COUPON: 'クーポン',
            THRESHOLD_FREE_COUPONS: 'クーポンはありません',
            INVITE_NEW_PARTICIPANTS: '新しい代表団を招待する',
            INVITE_NEW_PARTICIPANTS_TIPS: '新しいユーザーの参加、過密出荷、払い戻しに不満',
            ENTER_THE_PURCHASE_AMOUNT_TIPS: '購入金額を入力してください',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS: 'ステップのパッチワーク',
            SPECIAL_REQUIREMENT_PARAMS: '特別な要件',
            FAVORITES: 'お気に入り',
            COLLECT: 'お気に入りに追加',
            COLLECTED: '追加済み',
            SHARE: '共有',
            COPY_LINK: 'リンクをコピーします',
            WECHAT_SCAN: 'WeChatは一掃した',
            RELATED_ARTICLES: '関連記事',
            WANT_CREATE_GROUP_BUYING: '作成',
            FULL_PAYMENT: '全額を支払い',
            EXPECTED: '予想される',
            INDIVIDUAL_PURCHASE: '別途購入',
            ADD_TO_SHOPPINGCART: 'カートに入れる',
            ADD_TO_SHOPPINGCART_BTN_TEXT: 'カートに入れる',
            PRE_SALE_PRICE: '事前販売価格',
            PARAMETER: '引数',
            COMMENTS: '評価',
            SEE_ALL_PRODUCT_COMMENTS: 'すべてのレビューを参照してください',
            RECEIVED: 'ピックアップされました',
            RECEIVE: 'ピックアップ',
            START_TIME: '開始時刻',
            END_TIME: '終了時刻',
            PRODUCT_PROPERTIES: '商品プロパティ',
            PURCHASE_QUANTITY: '購入数量',
            BUY_NOW: '今すぐ購入',
            DISCOUNT_OF_COUPON: '满{{minAmountToUse}}减{{offValue}}',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER: '各注文制限{max quantity of order}件',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER_TIPS: '1 回限り購入数量を超えました',
            PRODUCT_IS_SOLD_OUT: '{{productName}}は完売しました',
            SOLD_OUT: '完売',
            INVENTORY: '在庫',
            GROUP_BUYING_FOR_PEOPLE_NUMBER: '{{price}}/{{peopleNumber}}人团',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS_TEXT: '{{requiredMembers}}人拼团',
            GET_THE_COUPON: 'バウチャーを受け取る',
            JOIN_THE_GOURP_BUYING_DESC: 'これらの人々は、単一の成功を綴り、グループに参加することができます',
            SOMEONE_GROUP: '連隊の',
            ONLY_FOR_GROUP_BUYING: '悪いだけ',
            PERSON_LEFT_IN_THE_GROUP: '人が一つになる',
            TO_INVITE: '招待してください',
            GO_TO_THE_GROUP: 'グループを組む',
            NO_DETAILS_FOR_PRODUCT: 'この商品についての詳細はありません',
            SOME_DAYS_AFTER_PAYMENT: '支払の後の{{presaleDeliveryDaysAfterPayment}}日以内に',
            SHIP: '出荷',
            COUPON_VALIDITY_PERIOD_TIPS: '有効期限は、請求日から{{validityDays}}日間です',
            PRESALE_DELIVERY_FIXED_DATE: '{{value}}前',
            DESCRIPTION: '説明',
            PRODUCT_QUANTITY_EXCEEDS_AVAILABLE_STOCK: '数量が使用可能な在庫数を超えています',
            ADD_TO_CART_SUCCESSFUL: 'カートに追加されました',
            ADD_FAILED: '追加に失敗しました',
            LIMIT_MIN_NUMBER_OF_PRODUCT_TIPS: '{{productName}}の数量は、少なくとも1個でなければなりません',
            ANONYMOUS_USER: '匿名ユーザー',
            LIMIT_MAX_STOCK_NUMBER_OF_PRODUCT_TIPS: '{{productName}}の現在の在庫数より多くすることはできません！',
            NETWORK_CONNECTION_FAILED: 'ネットワーク接続に失敗しました。',
            BUY_PRODUCT_ERROR_TIPS: 'データ検索に失敗した、{{productName}}は、販売中止の可能性あり',
            SHARE_INFO_FOR_PRESALE_TIPS: 'いい商品に出会えた、{{productName}}は先行販売です、ぜひ、見に来てください!',
            SHARE_INFO_FOR_GROUP_BUYING_TIPS: 'いい商品に出会えた、{{productName}}はグループ購入キャンペーン中です、ぜひ、見に来てください!',
            SHARE_INFO_FOR_PRODUCT_TIPS: 'いい商品に出会えた、{{productName}}、ぜひ、見に来てください!',
            UNFAVORITE_SUCCESSFUL_TIPS: 'お気に入りリストから削除しました',
            UNFAVORITE_FAILED_TIPS: 'お気に入りのキャンセルに失敗しました',
            FAVORITE_SUCCESSFUL_TIPS: 'お気に入りリストに追加しました',
            FAVORITE_FAILED_TIPS: 'お気に入りに失敗しました',
            LOGIN_CHECKING: 'ログイン検出',
            RECEIVING: 'ピックアップ中',
            RECEIVED_SUCCESSFULLY: 'ピックアップは成功しました',
            RECEIVED_FAILED: 'ピックアップに失敗した場合は、カスタマーサービスにお問い合わせください!',
            FAILED_TO_LOAD: '読み込みに失敗しました',
            VIEW_ORDER_DETAILS: '注文の詳細を表示します',
            GO_TO_ORDER_LIST: '注文リストに移動します',
            GO_TO_HOME_TIPS: 'トップページに移動します',
            GROUP_BUYING_CREATER: '団長',
            PARTICIPATE: 'フラットシート',
            PEOPLE: '人々',
            PLEASE_WAIT: 'しばらくお待ちください',
            ENTER_SEARCH_CONTENT: '検索内容を入力してください',
            SEARCH_HISTORY: '歴史を検索します',
            NOT_SEARCHED_RELATED_PRODUCTS: '申し訳ありませんが、関連するコンテンツは検索されません。',
            INPUT_INVALID_ERROR: '入力に誤りがないか確認してください',
            SEARCH_FAILED: '検索に失敗しました',
            SURE: 'OK',
            DELETE_SEARCH_WORD_FAILED: 'クリアに失敗しました',
            CONFIRM_BEFORE_CLEARING_SEARCH_HISTORY: 'すべての検索履歴を消去しますか?',
            NO_SERIES: 'シリーズなし',
            FAILED_TO_LOAD_SERIES_DATA: '系列データの読み込みに失敗しました',
            NO_GIFT_CARD_CONTENT: 'ギフト カードの内容がありません',
            SERIES_CARD: 'テーマカード',
            NOT_FOUND_PRODUCTS: '{{productName}}が見つかりません',
            NO_DATA: 'まだデータなし',
            NO_MORE_DATA: 'データはありません',
            ARTICLE_LIST: '記事リスト',
            LIKE: 'いいね',
            COMMENT_ARTICLE: 'コメント',
            ARTICLE_COMMENTS: 'すべてのコメント',
            LATEST: '最新',
            MOST_POPULAR: '最も暑い',
            AUTHOER: '著者',
            CANCEL_REPLY: '返信をキャンセルします',
            REPLY: '返信します',
            UNLIKE: 'いいねリストに削除しました',
            LINK_COPIED_SUCCESSFULLY: 'リンクのコピーに成功しました',
            THE_CURRENT_BROWSER_DOES_NOT_SUPPORT_COPYING: '現在のブラウザはコピーをサポートしていませんので、手動でコピーしてください!',
            ARTICLE_DETAILS_TITLE: '記事の詳細',
            ONLY_TIME_LEFT_UNTIL_THE_EVENT_STARTS: 'イベント開始時間',
            ONLY_TIME_LEFT_UNTIL_THE_END_OF_THE_EVENT: 'イベント終了時間',
            MALE: '男性',
            FEMALE: '女性',
            OTHER: 'その他',
            UNKNOWN: '不明です',
            THOUSAND: '千',
            TEN_THOUSAND: '万',
            BILLION: '億',
            BACK: '戻る',
            HELLO: 'こんにちは',
            PERSONAL: '個人',
            ORGANIZE: '組織',
            PLEASE_SELECT_GENDER: '性別を選択してください',
            PLEASE_CHOOSE_THE_TYPE: 'タイプを選択してください',
            AVATAR_UPLOAD_FAILED: 'アバターのアップロードに失敗しました',
            TRY_AGAIN_RECIVE: '再度お受け取りください',
            SUCCESSFULLY_DELETED: '削除に成功しました',
            FAILED_TO_DELETE: '削除に失敗しました',
            SET_AS_THE_DEFAULT_ADDRESS: 'デフォルトアドレスに設定',
            SHIPPING_ADDRESS: '詳細な配送先住所',
            SHIPPING_ADDRESS_PLACEHOLDER: '入庫先住所を入力してください',
            POSTAL_CODE: '郵便番号',
            POSTAL_CODE_PLACEHOLDER: '郵便番号を入力してください',
            DEFAULT_ADDRESS: '既定のアドレス',
            NO_ADDRESS_YET: '現時点ではアドレスがありません',
            SET_SUCCESSFULLY: '設定は成功しました',
            COMPLETE_ADDRESS_INFORMATION: '住所情報を絞り上げたい',
            EMAIL_ADDRESS_TITLE: '請求書を受け取る電子メール アドレスを入力します',
            EMAIL_ADDRESS: '電子メール',
            EMAIL_ADDRESS_PLACEHOLDER: 'メールアドレスを入力してください',
            INVOICE_HEADER_TYPE_TITLE: 'ヘッドアップタイプ',
            INVOICE_HEADER_TYPE_PLACEHOLDER: 'ヘッドアップの種類を選択します',
            COMPANY: '会社',
            INVOICE_HEADER_PLACEHOLDER: '請求書ヘッダーを入力してください',
            INVOICE_HEADER_CONTENT: '*請求書のヘッダー',
            INVOICE_HEADER_TITLE: '請求書のヘッダー',
            NSRSBH_PLACEHOLDER: '納税者識別番号を入力してください',
            NSRSBH_CONTENT: '*納税者識別番号',
            NSRSBH_TITLE: '納税者識別番号',
            PHONE_PLACEHOLDER: '携帯番号を入力してください',
            PHONE_CONTENT: '*携帯番号',
            PHONE_TITLE: '携帯番号',
            INPUT_MORE_INFORMATION: '詳細を入力します',
            BANK: '口座開設銀行',
            BANK_PLACEHOLDER: '口座開設明細を入力してください',
            NICKNAME_TITLE: '個人名',
            NICKNAME_CONTENT: '*個人名',
            NICKNAME_PLACEHOLDER: '名前を入力してください',
            FULL_DATE_FROMART_TEXT: '{{year}}年{{month}}月{{day}}日 {{hour}}:{{minute}}:{{second}}',
            SELECT_INVOICE_INFORMATION: '請求情報を選択します',
            FAILED_TO_APPLY_FOR_INVOICING: '請求の申請に失敗しました。管理者に連絡してください',
            FAILED_TO_APPLY_FOR_INVOICING_TIPS: '請求の申請に失敗しました',
            INVOICING_WAS_SUCCESSFUL: '請求申請が成功しました！',
            FAILED_TO_CREATE_INVOICE_HEADER: '請求書ヘッダーの作成に失敗しました',
            REFUNDING: '払い戻し',
            PENDING_RETURN: '返品待ち',
            RETURNED_PENDING_REFUND: '返品され、返金待ち',
            REVIEW_FAILED: '返金要求が拒否された',
            IN_REVIEW: '返金請求受付中',
            REFUND_COMPLETE: '返金完了',
            REFUND: '返金',
            REFUND_APPLICATION_APPROVED: '払い戻し申請が承認されました',
            REFUND_INSTRUCTIONS: '返金通知',
            REFUND_BUTTON_TEXT: '読んだ（返金に行く）',
        },
        SWEET_ALERT: {
            DEFAULT_TITLE: 'これを実行してもよろしいですか？',
            DEFAULT_CONFIRM_BUTTON_TEXT: '確認',
            DEFAULT_CANCEL_BUTTON_TEXT: 'キャンセル',
        },
        PAGINATION: {
            TITLE: '{{total}}の{{page}}-{{lastIndex}}を表示しています.',
            TEXT: 'すべてのページに表示',
            Unit: 'ストリップ'
        },
        USER: {
            ...userTranslate,
            LANGUAGE: '言語',
            LOGOUT: '終了する',
        },
        FOOTER: {
            TEXT: '懐徳株式会社',
            TERMS_OF_USE: '利用規約',
            TERMS_OF_REGISTRATION: '登録条件',
            TERMS_OF_PRIVACY_POLICY: 'プライバシー条件',
            TERMS_OF_PURCHASE: '購入条件',
            CUSTOMER_SERVICE: '顧客サービス',
            CONTACT_CUSTOMER_SERVICE: 'お問い合わせ',
            FEEDBACK_BTN_TEXT: 'フィードバック',
            AFTER_SALES_SERVICE: 'アフターサービス',
            MERCHANT_SERVICES: 'マーチャント・サービス',
            AFFILIATE_SERVICES: 'アフィリエイト・ネットワーク',
            AFFILIATE_BTN_TEXT: '参加申し込み',
            MERCHANT_PORTAL: 'マーチャントポータル',
            FOLLOW_US_FOR_MORE_INFORMATION: '詳細については、こちらをご覧ください',
            TECHNICAL_SUPPORT: '開発支援:',
        },
        API_ALERT_MESSAGE: {
            ERROR: {
                OFF_LINE: 'リクエスト中にエラーが発生しました。インターネット接続を確認してください...',
                UNAUTHORIZED: 'まだログインしていないか、ログインステータスの有効期限が切れています。続行する前にログインしてください...',
                FAILEDTOLAZILYINITIALIZE: '現在のリクエストサービスが正常に開始されていません。処理については、カスタマーサービスまたは管理者にお問い合わせください',
                UNKONWN: '不明なエラーです。ネットワークを確認して再試行してください。引き続き表示される場合は、管理者に連絡してください。',
                ERROR_MESSAGE: 'リクエストのエラー、エラーメッセージ：',
                DEFAULT: '現在のネットワークを確認してください。そうでない場合、現在のインターフェースに不明なエラーがあります...',
                COUPONNOTFEASIBLEFORGIFTCARD: 'クーポンでギフトカードを差し引くことはできません~',
                INVALIDCARDTEMPLATEID: '注文にはギフトカードアイテムが含まれており、ギフトカードで支払うことはできません~',
                OTPEXPIRED: 'WeChat認証の有効期限が切れています。再認証してください~',
                SENDVERIFICATIONCODEERROR: '送信回数が多すぎる場合は、後でもう一度試すか、番号を変更してください',
                NOCUSTOMERRECORD: '現在のアカウント情報が不完全です。別の携帯番号を試すか、管理者に連絡してください',
                NOTFOUND: 'データが存在しません。この問題が続く場合は、管理者に連絡してください',
                METHODNOTALLOWED: 'リクエストにエラーがありました。この問題が続く場合は、管理者に連絡してください',
                INTERNALSERVERERROR: 'サーバーが異常です。この問題が続く場合は、管理者に連絡してください',
                GATEWAYTIMEOUT: 'サーバーに接続できません。ネットワークを確認して、再試行してください',
                ERRNETWORKCHANGED: 'サーバーに接続できません。ネットワークを確認して、再試行してください',
                METHODARGUMENTNOTVALID: '送信されたデータが正しくありません。更新してもう一度お試しください。引き続き表示される場合は、アプリに再度アクセスしてください',
                BADGATEWAY: 'サーバーが異常です。後でアプリケーションにアクセスしてください。引き続き表示される場合は、管理者に連絡してください。',
                USERCOULDNOTBEFOUND: 'ユーザーが存在しません。もう一度ログインするか、後で再試行してください',
                COULDNOTEXTRACTRESULTSET: 'サービス関連のプロセスが異常です。後でアプリケーションにアクセスしてください。引き続き表示される場合は、管理者に連絡してください。',
                NOT_LOADED_SUCCESSFULLY_PLEASE_RE_ENTER: '正常に読み込まれませんでした。再入力してください。',
                CMS: {
                    FORMNOTFOUND: '回答用紙に対応するフォームが見つかりませんでした',
                    MISSINGREQUIREDRESPONSE: '一部の必須の質問は回答を送信しませんでした',
                    INVALIDROWSFORMAT: '行列の質問の行は、JSON 配列の文字列である必要があります',
                    RESPONSENOTMATCHWITHROWCOUNT: 'マトリックス質問の回答行数が質問の行数と一致しません',
                    MISSINGREQUIREDRESPONSEOFROW: 'マトリックス質問の必須行に回答がありません',
                    MULTIPLERESPONSENOTALLOWED: 'マトリックス以外の質問では、複数の回答は許可されません',
                    MISSINGRESPONSEVALUE: '回答に回答がありません',
                    EMPTYRESPONSEVALUE: '答えの中の答えは空の文字列です',
                    INVALIDDATETIMEFORMAT: 'タイトルの日付/時刻の形式が正しくありません',
                    INVALIDINTEGERVALUE: '不適切な整数値です',
                    INVALIDFLOATVALUE: '誤った小数値',
                    DECIMALPLACENUMBEREXCEEDS: '小数点以下の桁数が定義を超えています',
                    FILENUMBEREXCEEDS: 'ファイルの数が制限を超えました',
                    INVALIDOPTIONSFORMAT: 'オプション形式が正しくないため、JSON 形式の FormAttachment 配列である必要があります',
                    INVALIDVALUE: 'ルールに準拠していない答え',
                    CANNOTFINDVALIDATOR: 'バリデーターの回答が見つかりませんでした',
                    EMAILALREADYEXISTS: '購読に成功しました',
                },
                USER: {
                    PASSWORDFORMATINVALID: 'パスワード形式が複雑さの要件を満たしていません',
                    LOGINALREADYEXISTS: 'ログインは既に他のユーザーによって占有されています',
                    NOUSERCREDENTIAL: 'ユーザー レコードを取得できない場合は、正しいアカウントでログインします',
                    ALREADYBOUNDMOBILE: 'ユーザーが携帯番号をバインドしました',
                    USERNOTBOUNDMOBILE: 'ユーザーが携帯番号をバインドしていません',
                    MOBILENUMBERMISMATCH: '携帯番号がバインドされた携帯番号と一致しません',
                    MOBILEBOUNDBYOTHERUSER: '携帯番号は別のユーザーによってバインドされています',
                    INCORRECTPASSWORD: 'パスワードが正しくありません',
                    MOBILENOTREGISTERED: 'この携帯番号は登録されていません',
                    PARAMETERNULL: '引数を空にすることはできません',
                    WECHATNOTREGISTERED: 'この WeChat は登録されていません',
                    USERNOTBOUNDWECHAT: 'ユーザーはマイクロシグナルをバインドしていません',
                    WECHATBOUNDBYOTHERUSER: 'マイクロシグナルは別のユーザーによってバインドされています',
                    MOBILEALREADYREGISTERED: 'この携帯番号は既に登録されていますので、WeChat のバインド操作を行います',
                    REGISTERREQUIRED: 'ログインに失敗しましたので、登録後にログインしてください',
                    WECHATALREADYREGISTERED: 'このWeChatは登録されていますので、ログインするか、新しいマイクロシグナルをバインドしてください',
                    NOUSERBYWECHAT: 'WeChat によると、ユーザー レコードを取得できない場合は、開発支援にお問い合わせください',
                    CODENULL: 'code を空にすることはできません',
                    WRONGPASSWORD: 'パスワードエラー、入力したパスワードを確認してください',
                    FAILEDTOOMANYTIMES: 'ログインしようとした回数が多すぎます。ログイン方法を変更するか、{{value}}分以内に再試行してください',
                    FAILEDTOOMANYTIMES_NO_DETAIL: 'ログインしようとした回数が多すぎます。ログイン方法を変更するか、後でもう一度試してください',
                    LOGINFAILED: 'ログインに失敗しました。ユーザー名またはパスワードが正しいかどうかを確認するか、ログイン方法を変更してください。{{loginNumber}}に何度もログインしようとしていますが、{{maxNumber}}を超えると{{timeNumber}}分間ログインできなくなります',
                    LOGINFAILED_NO_DETAIL: 'ログインに失敗しました。ユーザー名またはパスワードが正しいかどうかを確認するか、ログイン方法を変更してください。頻繁にログインすると、一定期間ログインできなくなります',
                    CODEINVALID: 'Captchaが機能しなくなった',
                    PASSWORDNOTENABLED: 'パスワードログインが有効になっていませんので、他のログイン方法に変更してください。',
                    USER_DOES_NOT_EXIST: 'ユーザーが存在しない',
                }
            },
            SUCCESS: {
                TITLE: '成功!',
            }
        },
        LANGUAGE_SWITCH: {
            CHINESE: '簡体字中国語',
            ENGLISH: '英語',
            JAPANESE: '日本語',
            TRADITIONAL_CHINESE: '繁体字中国語'
        },
        INDEX: {
            USER_CENTER: 'アカウントセンター',
            MY_ORDERS: '注文履歴',
            MY_GIFT_CARDS: 'ギフトカード',
            PERSONAL_INFORMATION: 'プロファイル',
            ACCOUNT_SECURITY: 'アカウントセキュリティ',
            SIGN_OUT_BTN_TEXT: 'ログアウト',
            FAILED_TO_GET_TODAY_GROUP_PRODUCT: '本日のグループ購入商品の取得に失敗しました',
            HEADER: '頭',
            CAROUSEL: 'カルーセル図',
            DIY_CONTENTS: 'カスタマイズ',
            ANNOUNCEMENT: 'お知らせ',
            ANNOUNCEMENT_INFO: 'お知らせ情報',
            NEWS: 'ニュース',
            GROUP_BUYING_PRODUCS: '本日のグループ購入商品',
        },
        FLOATING_WINDOW: {
            GO_TO_SHOPCART: 'カート',
            USER_CENTER: 'アカウントセンター',
            CONTACT_CUSTOMER_SERVICE: 'お問い合わせ',
            BACK_TO_THE_TOP: 'トップへ戻る',
            SHRINK_BTN_TEXT: '縮小',
            EXPAND_BTN_TEXT: '展開します',
        },
        HOME: {
            AMOUNTS_PAYABLE: '支払額',
            SELECT_SELF_PICK_UP_ADDRESS: '受取先住所選択',
            SELF_PICK_UP_ADDRESS: '集荷場',
            SELLER_SENT_WRONG_PRODUCT: '売り手が間違ったアイテムを送信しました',
            PRODUCT_STYLE_ERROR: 'バージョン/バッチ/色などが{{productName}}の説明と一致しません',
            DAMAGED_PRODUCT_PACKAGING: '包装或{{productName}}破损',
            OTHER_REASONS: 'その他の理由',
            REASON_FOR_RETURN: '返却理由',
            REFUND_TYPE: '払い戻しタイプ',
            FILL_IN_THE_LOGISTICS_ORDER_NUMBER: '配送追跡番号をご記入ください',
            EVALUATE_THE_ORDER: '评价晒单',
            PRODUCT_NAME: '商品名',
            PLEASE_SELECT_IMAGE_SOURCE: '画像ソースを選択してください',
            PHONE_ALBUM: '電話アルバム',
            DELETE_INVOICE_TIPS: 'この請求情報を削除しますか?',
            INVOICE_CREATE_BTN_TEXT: '新しい請求情報',
            NO_INVOICE_INFORMATION_YET: '現時点では、請求書情報はありません',
            TAX_ID: '税番号',
            INVOICE_SETTING: '請求設定',
            ADDRESS_PLACEHOLDER: '住所を入力してください',
            BANK_ACCOUNT_PLACEHOLDER: '銀行口座番号を入力してください',
            BANK_ACCOUNT: 'アカウント番号',
            INVOICING_INFORMATION: '請求情報',
            VAT_ELECTRONIC_GENERAL_INVOICE: '売上税電子自由書式の請求書',
            VAT_SPECIAL_ELECTRONIC_INVOICE: 'VAT 固有の電子請求書',
            INVOICE_DOCUMENT: '請求書ファイル',
            INVOICE_FILE_TYPE: '請求書の種類',
            INVOICE_HEADER_NAME: 'ヘッダー名',
            CREATED_SUCCESSFULLY: '正常に作成されました',
            VAT_ORDINARY_INVOICE: 'VAT通常の請求書',
            VAT_SPECIAL_INVOICE: 'VAT特別請求書',
            INVOICE_NUMBER: '請求書番号',
            INVOICE_TYPE: '請求タイプ',
            ELECTRONIC_INVOICE: '電子請求書',
            MANUAL_INVOICE: '手動請求書',
            INVOICE_AMOUNT: '請求額',
            INVOICING_TIME: '請求時間',
            APPLICATION_TIME: '塗布時間',
            FAILED_TO_CREATE: '作成に失敗しました',
            EMAIL_INVALID_ERROR: 'メールアドレスが入力されていないか、フォーマットが正しく入力されていません',
            PHONE_INVALID_ERROR: '正しい携帯番号を入力してください',
            ADDRESS_INVALID_ERROR: '入庫住所は特別な記号をサポートしていません',
            NSRSBH_INVALID_ERROR: '正しい納税者番号を入力してください',
            NAME_CANNOT_BE_EMPTY: '名前を空にすることはできません',
            INVOICE_HEADER_CANNOT_BE_EMPTY: '請求書ヘッダーを空にすることはできません',
            FAILED_LOADED_ACCOUNT_INFORMATION_PLEASE_RETRY_AGAIN: 'アカウント情報の取得に失敗しました',
            TO_BE_PAID: '支払待ち',
            TO_BE_JION: '進行中のグループ',
            PENDING_RECEIPT_OF_GOODS: '納品待ち',
            COMPLETED: '已完成',
            ALL_ORDERS: 'すべての注文',
            MY_SERVICES: 'サービス',
            ADDRESS_MANAGEMENT: 'アドレス管理',
            MY_FAVORITES: 'お気に入り',
            MY_COUPONS: 'クーポン',
            MY_INVOICE: '請求書ヘッダー',
            ACCOUNT_SECURITY: 'アカウントセキュリティ',
            INVITATION_WITH_PRIZE: 'ご招待券のご案内',
            FEEDBACK: 'フィードバック',
            SUCCESSFUL_EXIT: '終了は成功しました',
            FAILED_TO_GET_PERSONAL_INFORMATION: '個人情報の取得に失敗しました!',
            SPARE_CHANGE_DETAILS: '変更の内訳',
            MY_SPARE_CHANGE: '私の変更',
            MY_WALLET: '私の財布',
            BALANCE_DETAILS: '残高の詳細',
            CHOOSE: 'を選択します',
            NO_PICK_UP_LOCATIONS_AVAILABLE: '現時点では、オプションのセルフフィードはありません',
            SUNDAY: '日曜日',
            MONDAY: '周一',
            TUESDAY: '火曜日',
            WEDNESDAY: '水曜日',
            THURSDAY: '木曜日',
            FRIDAY: '金曜日',
            SATURDAY: '土曜日',
            PICK_UP_TIPS: 'できるだけ早くピックアップポイントでお届けください',
            TODAY: '今日',
            TOMORROW: '明日',
            THE_DAY_AFTER_TOMORROW: '後日',
            ALL_DAY: '終日',
            MORNING: '朝',
            AFTERNOON: '午後',
            NIGHT: '夜',
            FEEDBACK_CONTENT: 'フィードバックの内容',
            FEEDBACK_CONTENT_PLACEHOLDER: '反馈内容不少于五个字',
            FEEDBACK_CONTACT: '携帯番号またはメールアドレスをご記入ください',
            CONTACT_DETAILS: '連絡先',
            FEEDBACK_POSTING: 'コミット中',
            FEEDBACK_FAILED: '連絡先とフィードバックを補足してください',
            NO_CONSUMPTION_RECORD: '消費記録はありません',
            CONSUMPTION_RECORD: '消費レコード',
            CONSUMPTION_RECORD_DETAILS: '消費レコードの詳細',
            DESCRIBE: '描述',
            ORDER_ID: '注文 id',
            TRANSACTION_NUMBER: '取引フロー番号',
            TRANSACTION_TIME: '取引時間',
            TOTAL_GIFT_CARD_BALANCE: 'ギフト カードの合計残高',
            BUY_A_NEW_CARD: '新しいカードを購入します',
            BIND_NEW_CARD: '新しいカードをバインドする',
            USED: 'が使用できます',
            NOT_USED: '使用できません',
            CHOOSE_IMG_FILE_TO_UPDATE_AVATAR: 'アバターを交換するには、画像ファイルを選択する必要があります!',
            CHANGE: '交換',
            NO_ACCOUNT_SECURITY_SETTINGS_INFORMATION: 'アカウントのセキュリティ設定情報はありません',
            LOGIN_SETUP: 'ログイン設定',
            CURRENT_LOGIN: '現在のログイン名:',
            HAS_BEEN_SET: '設定されています',
            NOT_SET: '設定されていません',
            SET_LOGIN: 'ログイン名を設定する',
            PASSWORD_SETUP: 'パスワード設定',
            SET_PASSWORD: 'パスワードを設定する',
            CHANGE_PASSWORD: 'パスワードを変更する',
            SET_PHONE_BINDING: '携帯電話バインド',
            BIND_THE_PHONE: '携帯をバインドする',
            CHANGE_PHONE: '携帯を交換する',
            UNBOUND: 'バインドされていません',
            BOUND: 'バインドされています',
            SET_WECHAT_BINDING: 'WeChat バインド',
            UNBOUND_WECHAT_ACCOUNT: 'バインドされていない　WeChat',
            UNBIND_WECHAT: 'WeChat をアンパックする',
            BIND_WECHAT: 'WeChat　をバインドする',
            UNBOUND_AND_NOT_SET_PASSWORD: '現在のアカウントに携帯番号が関連付けられていない場合、パスワードを設定できません',
            GO_TO_BIND: 'バインドに移動します',
            UNBIND_WECHAT_TIPS: 'バインドを解除すると、WeChatを使用してこのWebサイトにログインできなくなります。バインドを解除してもよろしいですか？',
            CONFIRM_UNBIND: 'アンパックを確認する',
            UNBIND_WECHAT_SUCCESSFULLY: '現在のアカウントバインドのWeChatの成功をアンパック!',
            WECHAT_SCAN_CODE_IS_NOT_ENABLED: 'スキャン コード バインドがオンになっていない場合は、WeChat 内でこのページを開いてバインドてください',
            WECHAT_AUTHORIZATION_IS_NOT_ENABLED: '承認バインディングが開いていない場合は、ブラウザー内でこのページを開いてバインドしてください',
            THIS_FEATURE_IS_NOT_YET_AVAILABLE: 'この機能は、まだ開いていません',
            LOGIN_NAME_EMPTY_ERROR: 'ログイン名が入力されていませんので、ご確認ください',
            WRONG_FIELD_FORMAT: '{{name}}格式有误',
            CAPTCHA_VERIFY_CODE_PLACEHOLDER: '画像番号を入力する',
            PLEASE_ENTER_A_NEW_PASSWORD: '新しいパスワードを入力してください',
            SUCCESSFULLY_MODIFIED: '変更は成功しました',
            PHONE_VERIFICATION: '電話の認証',
            PWD_VERIFICATION: 'パスワードの認証',
            WAYS_OF_IDENTIFYING: '認証方法',
            INPUT_OLD_PHONE_PLACEHOLDER: '元の携帯番号を入力してください',
            OLD_PHONE: '元の携帯番号',
            OLD_PHONE_VERIFICATION_CODE: '元の電話の認証コード',
            NEW_PHONE__PLACEHOLDER: '新しい携帯番号を入力してください',
            NEW_PHONE_VERIFICATION_CODE: '新しい電話認証コード',
            NEW_PHONE: '新しい携帯番号',
            MODIFY_PHONE_NUMBER: '携帯番号を変更する',
            CAPTCHA_LABEL: '画像認証コード',
            NEW_PHONE_CAPTCHA_ERROR: '新しい携帯番号の画像認証コードが正しく入力されていません',
            OLD_PHONE_CAPTCHA_ERROR: '元の携帯番号の画像認証コードが正しく入力されていません',
            CURRENT_AVATAR: 'アバター',
            USER_NICKNAME_PLACEHOLDER: 'ニックネームを入力してください',
            USER_NICKNAME: 'ニックネーム',
            GENDER: '性別',
            TYPE: '種類',
            CONFIRM_THE_CHANGES: '変更を確認する',
            CONTACT_DETAILS_PLACEHOLDER: '連絡先を入力してください',
            MOBILE_PHONE: '携帯番号',
            MOBILE_PHONE_PLACEHOLDER: '携帯番号を入力してください',
            NAME: '名前',
            NAME_PLACEHOLDER: '名前を入力してください',
            ORGANIZATION_NAME_PLACEHOLDER: '请输入组织名称',
            ORGANIZATION_NAME: '組織',
            ORGANIZATION_NAME_TITLE: '組織名',
            FAIL_TO_EDIT: '変更に失敗しました',
            LOGISTICS_STATUS: 'ロジスティクスの状態',
            LOGISTICS_COMPANY: '物流会社',
            LOGISTIC_NUMBER: '物流单号',
            FILL_IN_THE_REFUND_LOGISTICS_INFORMATION: '填写退款物流信息',
            COPY_THE_TRACKING_NUMBER: 'コピー',
            LOGISTICS_INFORMATION: '物流情報',
            LOGISTICS_INFORMATION_TIPS: '物流トラック情報はありませんので、後でもう一度お試しください!',
            TRACK_NUMBER_COPIED: '単一番号がコピーされました',
            NO_DISCOUNT_COUPONS_AVAILABLE: '割引は受け付け付けっていません',
            GET_YOUR_COUPON_NOW: '今すぐお受け取りください',
            GET_YOUR_COUPON_TEXT: '领券当日开始{{validityDays}}天有效',
            GET_COUPON: 'クーポンを受け取る',
            TO_BUY: '購入してください',
            NO_FAVORITES_YET: 'お気に入りには何もありません',
            DELETE_PRODUCT_NAME_TIPS: 'お気に入りから{{productName}}を削除してもよろしいですか？',
            MOBILE_PLACEHOLDER: '携帯電話または市外局番付きの固定電話',
            YOUR_AREA: '地域',
            NAME_EMPTY_ERROR: '名前を空にしたり、スペース全体にすることはできません',
            NAME_INVALID_ERROR: '荷受人名は特別な記号をサポートしていません',
            PHONE_EMPTY_ERROR: '連絡先の詳細が入力されていないか、入力ミスがあります',
            ADDRESS_EMPTY_ERROR: '配送先住所を空にしたり、スペース全体を使用したりすることはできません',
            PROVINCE_NAME_EMPTY_ERROR: '地域を選択してください',
            CITY_NAME_EMPTY_ERROR: '地域を完成してください',
            ADDRESS_LENGTH_ERROR: '入庫住所は最大 33 語です',
            INVOICING_RESULT: '开票结果',
            CREATE_ADDRESS: '新しいアドレス',
            UPDATE_ADDRESS: 'アドレスを更新する',
            CHANGE_ADDRESS: 'アドレスを変更する',
            DELETE_ADDRESS_TIPS: 'このアドレスを削除しますか?',
            ORDER_CREATE: '注文の送信',
            ORDER_COMPLETED: '注文完了',
            INVOICING_IS_COMPLETE: '請求が完了しました',
            TOTAL_AMOUNT: '合計',
            FAILED_TO_LOAD_ORDER_INFORMATION: '注文情報の読み込みに失敗しました',
            ORDER_ID_TEXT: '注文番号',
            CREATION_TIME: '作成時間',
            UPDATE_TIME: '更新時刻',
            PAYMENT_METHOD: '支払い方法',
            EMAIL_BINDING: 'メールバインド',
            BIND_THE_EMAIL: 'メールをバインドする',
            CHANGE_EMAIL: 'メールを変更する',
            OLD_EMAIL_VERIFICATION_CODE: '認証コード',
            NEW_EMAIL_VERIFICATION_CODE: '認証コード',
            EMAIL_VERIFICATION: 'メール認証'
        },
        PAYMENT_METHOD: {
            WECHAT: 'WeChat',
            ALIPAY: 'Alipay',
            CASH: '現金',
            GIFT_CARD: 'ギフトカード',
            BANK_TRANSFER: '銀行振込',
            OTHER: 'その他',
            PAYPAY: 'PAYPAY',
            CONSUMING_CARD: '消費者カード',
            COUPON: 'クーポン',
            POINTS: 'ポイント',
            PRODUCT: '商品',
        },
        CATEGORY: {
            NO_PRODUCT: '現在、商品はございません',
            NO_RELATED: 'データが見つかりませんでした',
            CONTENT: 'コンテンツ',
            NO_CATEGORY: '現在、分類はございません',
            SHRINK_BTN_TEXT: '縮小',
            EXPAND_BTN_TEXT: '展開する',
        },
        FORM: {
            SELECT_THE_FORM_RESPONSE_YOU_WANT_TO_COPY: 'コピーするコミット レコードを選択する',
            UPDATE_TIME: '更新時刻',
            COPY_BTN_TEXT: 'コピーする',
            COPY_AND_EDIT: '内容をコピーする',
            REFILL: '再入力する',
            INPUT_VALIDATED_FAILED_TIPS_END: 'ビットには数字、英字のみが含まれます。',
            NOT_FOUND_CURRENT_FORM_TIPS: 'フォームが存在しませんので、もう一度入力するか、別のものを見てください',
            THE_FORM_RESPONSE_TO_BE_COPIED_IS_NOT_SELECTED_AND_CANNOT_BE_COPIED: 'コピーするフォーム レコードが選択されていないため、コピーできません。',
            FOUND_SOME_FORM_RESPONSE_THAT_I_FILLED_OUT_EARLIER_DID_I_COPY_THE_CONTENTS_AND_EDIT_THEM_FOR_SUBMISSION: '以前に入力したフォーム レコードの一部を検出し、コンテンツをコピーして送信を編集しますか。',
            FILL_FORM_BEFORE_PURCHASE: 'ご購入前にフォームにご記入ください',
            FILL_FORM_AFTER_PAID: '支払い後、フォームに入力できます',
            PLEASE_FILL_OUT_THE_FORM_FIRST: 'まずフォームにご記入ください',
            NEED_APPLY_FORM_TIPS: '少なくともまだ記入{{needApplyFormNumber}}個表',
            WHETHER_TO_BIND_THE_FORM_RESPONSE: '入力済みのフォーム レコードを現在の注文にバインドするかどうか',
            CONFIRM_AND_BIND: '確認し、バインドする',
            APPLY_FORM__AND_BIND_ORDER_TIPS: 'フォームに入力し、注文を正常にバインド!',
            GO_TO_FILL_OUT: '埋めに行く',
            FILLED_IN: '記入',
        },
        SHOPPING_CART: {
            FAVORITE_IT: 'お気に入りに追加',
            GO_SHOPPING: '買い物に行く',
            SHOPPING_CART_IS_EMPTY_TIPS: 'カートは空です。他の何かを見に行きましょう',
        },
        PRODUCT_PAGE: {
            GO_SHOPPING_CART: 'カート',
            CURRENT_GROUP_CANNOT_JOIN_PLEASE_TRY_ANOTHER_ONE: '現在のスプライスは参加できませんので、別のグループを試してみてください',
            PRODUCT_ERROR_SEE_OTHER_ONE: '申し訳ございませんが、この商品は販売できませんので、他の商品を見てみてください!',
            CREATE_GROUP_BUYING_PRICE_ERROR: '団長の値段が間違っていて、連隊を開けられなかった!',
            BUY_AT_LEAST_ONE_TIPS: '少なくとも1つを購入する',
        },
        GROUP_BUYING_PAGE: {
            TITLE: 'グループの詳細',
            SUCCESSFULLY_JOINED_TOGETHER: '他の人は成功するために一緒に働いた',
            MY_GROUP_BUYING: '私のパッチワーク',
            GROUP_BUYING_INFORMATION_TITLE: 'スクラブは、次のことを知っておく必要があります。',
            GROUP_BUYING_INFORMATION_TIPS: '高麗人参は、自動払い戻しの数未満です',
            GROUP_BUYING_REMAINING_TIME: '残りの時間',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_TIPS: '男は、私の連隊に参加するために来てください!',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_TIPS: '人は、成功するためにグループを組み立てることができます!',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_LEFT_LABEL: 'まだ悪い',
            JOIN_NOW: '今すぐ参加してください',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_FRIENDS_TIPS: 'スプライスメンバーは、参加するために友人を招待してください!',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_NEW_PEOPLE_TIPS: '暂无拼团成员，快去邀请新人参与吧！',
            INVITE_FRIENDS_TO_JOIN_THE_GROUP: '邀请好友参团',
            INVITE_NEW_GINSENG_GROUP: '邀请新人参团',
            FAILED_TO_GET_GROUP_INFORMATION_TIPS: '获取拼团信息失败，请检查网络并刷新再试吧',
            GROUP_HAS_BEEN_CANCELLED: '現在のスプライスはキャンセルされました',
            HURRY_UP_TO_INVITE: '急いで招待',
            COME_AND_JOIN_MY_GROUP: '私のグループに参加するために急いでください',
            GROUP_BUYING_STATUS_IS_UNKNOWN: 'スプライスの状態は不明です',
            GROUP_BUYING_HAS_STOPPED: 'スクランブルが停止しました',
            GROUP_BUYING_HAS_BEEN_CANCELLED: 'スプライスはキャンセルされました',
            THE_GROUP_BUYING_IS_COMPLETE: 'パッチワークが完了しました',
            THE_GROUP_BUYING_HAS_TIMED_OUT: 'スプライスがタイムアウトしました',
            THE_STATE_OF_THE_GROUP_BUYING_IS_ABNORMAL: 'スプライス状態が異常です',
            NEED_SOME_PERSON_TO_SUCCESSFUL_FOR_GROUP_BUYING: '返差{memberGap}}人 綴団成功',
        },
        CUSTOMER_SERVICE: {
            SERVICE_HOTLINE: 'サービスホットライン',
            OFFICIAL_ADDRESS: '公式アドレス',
            COMPANY_ADDRESS: '会社の住所',
            OFFICIAL_WECHAT: '公式WeChat',
        },
        DOCUMENT: {
            NO_TERMS_AND_CONDITIONS: '用語情報はありません',
            LOGIN_TERM: 'ログイン条件',
            REGISTER_TERM: '登録条件',
            PURCHASE_TERM: '購入条件',
            RETURN_TERM: '払い戻し条件'
        },
        GIFT_CARD: {
            EXPIRATION_DAYS_AFTER_ACTIVATION: 'ライセンス認証後の有効期限 (日)',
            CAMPAIGN_CODE: '活動番号',
            CHANNEL_CODE: 'チャネル番号',
            CARD_MEDIA_TYPE: 'カードの種類',
            CURRENCY: 'コインベルタイプ',
            ACTIVATION_EXPIRATION_DATE: 'アクティブ化期限',
            CNY: '人民元',
            ELECTRONIC: '電子カード',
            ORDER_TYPE: '应用订单',
            REMARK: '备注',
            SERVICE_ORDER: 'サービス注文',
            SALES_ORDER: '販売注文',
            GIFT_CARD_TIPS: '选填，请先和商家协商一致'
        },
        FORM_CONTROL: {
            DOCUMENTS_MUST_BE_UPLOADED: 'アップロードする必要があります!',
            PENDING_UPLOAD: 'アップロード予定',
            CANCEL_UPLOAD: 'アップロードをキャンセルする',
            UPLOAD: 'アップロード',
            DOWNLOAD: 'ダウンロード',
            DRAG_OR_CLICK_TO_UPLOAD: 'ドラッグ/クリックしてアップロード',
            FILES: '个',
            APPLICATION: 'アプリ',
            IMAGE: '写真',
            VIDEO: 'ビデオ',
            AUDIO: 'オーディオ',
            COMPRESS: 'パッケージを圧縮する',
            FILES_UPLOAD: '添付ファイルのアップロード',
            FILE_SIZE_NUMBER_MB_CANNOT_BE_UPLOADED: '{{fileSize}}mb のサイズのファイルはアップロードできません',
            PLEASE_UPLOAD_THE_TYPE_FORMAT_FILE_WITHIN_FILESIZE_MB_IN_SIZE: 'ファイルサイズが{{fileSize}}MB以下の{{fileType}}ファイルをアップロードしてください。',
            FILE_TYPE: '形式',
            CONTROL_CANNOT_BE_EMPTY: '空にすることはできません!',
            TEXTAREA: '複数行のテキスト',
            PLEASE_CHOOSE: '選択してください',
            SELECT: 'ドロップダウン ボックス',
            RADIO_GRID: 'マトリックスの単一選択',
            RADIO: '単一のマーキー',
            LINEAR_SCALE_GRID: 'マトリックスゲージ',
            LINEAR_SCALE: '線形ゲージ',
            PLEASE_TYPE_IN: '入力してください',
            PLEASE_ENTER_CONTENT: '内容を入力してください',
            ADDRESS: 'アドレス',
            INPUT: '1 行のテキスト',
            LENGTH_CANNOT_BE_LESS_THAN: '長さは小さくすることはできません',
            LENGTH_CANNOT_BE_GREATER_THAN: '長さは大きくすることはできません',
            CANNOT_BE_LESS_THAN: 'より小さくすることはできません',
            CANNOT_BE_GREATER_THAN: 'より大きくすることはできません',
            INVALID_ERROR: '形式が正しくありません。',
            CHECKBOX_GRID: '行列の複数選択',
            TITLE: 'タイトル',
            SUBTITLE: 'サブタイトル',
            TEXT: '本文',
            RICHTEXT: 'リッチ テキスト',
            CHECKBOX: '複数のマーキー',
            SHOULD_CHOOSE: 'を選択する必要があります',
            OPTIONS: 'オプション',
            PREV_STEP: '前の手順',
            NEXT_STEP: '下一步',
            DATETIME: '時間',
            DATE: '日付',
            DATE_NOT_SELECTED: '日付が選択されていません!',
            THE_TIME_IS_NOT_ENTERED_COMPLETELY: '時間は完全に入力されていません!',
            PLEASE_CHECK: '、チェックしてください!',
        },
        EDITOR: {
            TITLE: 'タイトル',
            SUB_TITLE: 'サブタイトル',
            BODY: '本文',
            DEFAULT: 'デフォルト',
            EXPORT_PDF: 'PDFをエクスポート',
            PREVIEW_PDF: 'PDFのプレビュー',
            PRINT: '印刷',
            MARGIN_LEFT: '左マージン',
            MARGIN_RIGHT: '右マージン',
            LINE_HEIGHT: '行の高さ',
            MARGIN_TOP: 'トップマージン',
        },
        FILTER_PARAMS_SELECTOR: {
            SORT: 'ソート',
            FILTER: 'フィルタリング',
            CATEGORY: 'カテゴリー',
            PUT_AWAY: 'しまう',
            EXPEND_MORE: 'もっと見る',
            KEYWORD: 'キーワード',
            PRICE: '価格',
            CANCEL: 'キャンセル',
            CONFIRM: '認識する',
            KEYWORD_PLACEHOLDER: 'キーワードを入力してください',
        },
        SHARED: {
            DISCOUNTED_COUPON_PRICE: 'クーポンの金額',
            NOT_SUPPORT_ADDRESS: 'このエリアでの購入は、現時点ではサポートされていません！',
            NOT_SUPPORT_ADDRESS_TIP: 'この地域での購入はできません、他のアドレスを選択してください！',
            COMMENT_PLACEHOLDER: 'コメントを入力してください',
            DELIVERY_ADRESS_EMPTY: '最初に住所を選択してください',
            DELIVERY_INFORMATION_EMPTY: '配信情報が取得されていません。更新してもう一度お試しください。',
            DISCOUNTED_PRICE: '割引',
            UNAVAILABLE_FOR_COUPON: '利用できません',
            EDIT_PICKER_INFO: 'ピッカー情報を編集する',
            PICKER_INFO_PLACEHOLDER: '荷受人情報を記入してください',
            EDIT_PICKER_INFO_PLACEHOLDER: '荷受人の名前を入力してください',
            EDIT_PICKER_PHONE_PLACEHOLDER: '荷受人の携帯番号を入力してください',
            NO_COUPONS_AVAILABLE: '利用可能なクーポンはありません',
            SELF_PICKUP_TIME: 'お迎えの時間',
            TOGGLE: 'トグル',
            CHANGE_ADDRESS: '住所を変更する',
            SELF_PICKUP_PERPON: '荷受人',
            THE_ORDER_HAS_BEEN_REVIEWED_AND_DOES_NOT_SUPPORT_REFUNDS: '注文は確認済みで、払い戻しはサポートされていません',
            REFUND_ORDER_DETAILS: '払い戻し注文の詳細',
            PAYMENT_METHOD: '支払方法',
            FILL_FROM_AFTER_PAID_TIPS: 'お支払い後、フォームにご記入ください',
            REFUND_NUMBER: '返金番号',
            I_HAVE_SENT_IT_CLICK_TO_FILL_IN_THE_LOGISTICS_ORDER_NUMBER: 'リターン情報を記入する',
            ONLINE_PAY_REFUND_AMOUNT: 'WeChat/Alipayの払い戻し金額',
            GIFT_CARD_REFUND_AMOUNT: 'ギフトカードの払い戻し額',
            WAITING_FOR_SELLER_TO_RECEIVE: '戻ってきて、売り手が受け取るのを待っている',
            PASSED_THE_REVIEW_TIPS: '販売者は返品申請に同意しました。できるだけ早く返品してください。',
            ORDER_TYPE: '注文タイプ',
            THE_AMOUNT_PAID_FOR_THE_ORDER: '注文に対して支払われた金額',
            EXPRESS_LOGISTICS: '配送サービス',
            SHIPPING_FEES: '送料',
            NO_DELIVERY_REQUIRED: '配達は必要ありません',
            DELIVERY_METHOD: '配送方法',
            APPLICATION_STATUS: 'アプリケーションの状態',
            OTHER_REASONS_INVALID_ERROR: 'その他の理由は正しくありません',
            OTHER_REASONS_EMPTY_ERROR: 'その他の理由が満たされていない',
            PHONE_INVALID_ERROR: '電話番号の形式が正しくありません',
            REQUEST_A_REFUND_PLACEHOLDER: '注：自分で受け取らなかった配送注文は返金されません！',
            PLEASE_FILL_IN_OTHER_REASONS: '他の理由を記入してください',
            ANONYMOUS_COMMENTS: '匿名のコメント',
            PLEASE_ENTER_REVIEW_CONTENT: 'レビュー内容を入力してください',
            BIND_GIFT_CARD: 'ギフト カードをバインドする',
            TO_BE_PICKED_UP: 'ピックアップされる',
            GIFT_CARD_SCRET: 'カミ',
            GROUP_TO_BE_FORMED: '形成されるグループ',
            PAID_TO_BE_PICKED: '支払われた、選ばれる',
            PAID_PENDING: '有料、保留中',
            ORDER_NOT_PAID: '注文は未払い',
            PLEASE_ENTER_GIFT_CARD_SCRET: 'カミを入力してください',
            COMMENTED: 'コメント',
            BIND: 'バインドする',
            INPUT_GIFT_CARD_SCRET_TIPS: 'ギフトカードのパスワードを入力してください(電子カードは注文の詳細で確認できます)',
            GIFT_CARD_SCRET_EMPTY_ERROR: 'カミを空にすることはできません',
            BINDING_SUCCEEDED: 'バインドは成功しました',
            GIFT_CARD_SCRET_INVALID_ERROR: '正しいパスワードを入力してください',
            MAX_QUANTITY_FOR_ONCE_TIPS: '一度に買えるのはせいぜい{value}個しか買えない!',
            MAX_QUANTITY_FOR_ONCE_BY_STOCK_TIPS: '購入できるのはせいぜい{{value}}個だけです！',
            NEWCOMER_GROUP: '新人がグループ化',
            BROWSER_UNSPPORT_VIDEO_PLAY: 'お使いのブラウザは、ビデオの再生をサポートするには余りにも古いです, ああ、ブラウザをアップグレードしてください!',
            SOLD_DESC: '{{value}}売り',
            THERE_IS_NO_INFORMATION: '情報はありません',
            COMMENT: 'コメントを残す',
            PURCHASE_NOTICE: '購入について知っておくべきこと',
            COPY_SUCCESSFULLY: 'コピーに成功しました!',
            BIND_FAILED: 'バインドに失敗しました',
            USE_THIS_GIFT_CARD_TIPS: 'このギフトカードを現在のアカウントにバインドしますか?',
            USE_THIS_GIFT_CARD: '現在のアカウントにバインドする',
            VIEW_THIS_GIFT_CARD: 'キャミィを見る',
            VIEW_GIFT_CARDS_IN_ORDER: '注文を表示-ギフトカード',
            LOAD_GIFT_CARDS_ERROR: '注文関連のギフトカードの取得に失敗しました',
            CARD_NUMBER: 'カード番号',
            PAR_VALUE: '額面',
            EXPIRATION_DATE: '有効期間',
            CLICK_TO_SEE_LARGER_PICTURE: 'クリックすると拡大表示されます',
            ENLARGE_IMAGE: '拡大図',
            CLICK_TO_GO: '移動する',
            COUPONS: 'クーポン',
            DO_NOT_USE: '使用されません',
            FAILED_TO_INITIALIZE_QR_CODE_FUNCTION: 'QR コード機能の初期化に失敗しましたが、ネットワークが正常であるか、広告ブロック プラグインによって誤ってブロックされているかどうかを確認します。',
            DRAW_AND_SHARE: '共有図を非常に速く描画しますので、しばらくお待ちください',
            BROWSER_NOT_SUPPORT_CANVAS: '現在のブラウザは QR コードの描画をサポートしていません',
            LOAD_LOGO_IMG_FAILED: 'ロゴ画像またはlogo画像を読み込めませんでしたので、後でもう一度お試しください',
            HOW_TO_SHARE_ON_WECHAT: '(1) 画像を保存するか、友達を送信するために長押しする',
            SAVE_PICTURES: '(1) 画像を保存する',
            SCAN_THE_CODE_TO_VIEW: '(2) スキャンして表示する',
            GROUP_BUYING: 'スクランブル',
            GROUP_BUYING_DESC: '人のパッチワーク • 過密出荷 • 払い戻しに不満を持つ人',
            LOAD_QRCODE_FAILED: '{{productName}}QRコードの読み込みに失敗しました。しばらくしてからもう一度お試しください。',
            LOAD_IMG_FAILED: '{{productName}}画像の読み込みに失敗しました。しばらくしてからもう一度お試しください。',
            SHIP_BEFORE_DATE: '{{value}}より前に発送',
            SHIPS_DAYS_AFTER_PAYMENT: "お支払い後{{value}}日以内に発送",
            MORE_PRODUCT: 'より多くの商品',
            DEFAULT: 'デフォルト',
            LATEST: '最新の',
            SALES: '販売量',
            PRICE: '価格',
            MORE: 'もっと',
            NOT_FOUND_TIPS: '関連する{{value}}が見つかりません。最初に他の人を見てみましょう',
            HOT_SALE: 'ホット販売',
            CANCEL_ORDER_TIPS: '注文をキャンセルしますか?',
            CANCELLED: 'キャンセルされました',
            IN_PROGRESS_GROUPINGS_DO_NOT_ALLOW_REFUNDS: '進行中のスプライスは払い戻しを許可していません!',
            OUT_OF_STOCK: 'これ以上追加できない',
            OFF_STOCK: '在庫切れ',
            ORDER_REVIEWED: '注文は承認され、払い戻しはサポートされていません',
            INSUFFICIENT_AMOUNT_ACTUALLY_PAID: '実際の支払額は 0 で、請求できません',
            ACTUAL_PAYMENT_AS_GIFT_CARD: '支払い方法はギフトカードで支払われ、請求できません',
            INVOICE_SUBMITTED_SUCCESSFULLY: '現在の注文は、請求を提出し、レビュー中であり、辛抱強くああ待っています...',
            REFUND_REQUESTED: '払い戻しがリクエストされました',
            ORDER_ID: '注文番号',
            ORDER_CANCELLED: '注文がキャンセルされました',
            REMAINING_PAYMENT_TIME: '残りの支払時間',
            SECONDS: '秒',
            MINUTE: '分',
            REFUNDS: '返品払い戻し',
            REQUEST_A_REFUND: '払い戻しをリクエストする',
            REFUND_SUCCESSFULLY: '払い戻しは成功しました',
            REFUND_UNDER_REVIEW: '退款审核中',
            REFUND_FAILED: '払い戻しが失敗しました',
            REFUND_APPROVED: '払い戻しの確認に合格しました',
            REFUND_PENDING_REVIEW: '払い戻しは保留中です',
            GO_TO_COMMENT: '評価',
            EVALUATED: '評価済み',
            GO_TO_EVALUATE: '評価する',
            PRESALE_ORDER: '事前販売注文',
            GROUP_BUYING_ORDER: 'スクランブル注文',
            ESTIMATED_DELIVERY_TIME: '推定配達時間',
            DELIVERY_TIME: '納期',
            NORMAL_ORDER: '正規順序',
            SELF_PICKUP: '店舗受け取り',
            NUMBER_PIECES_IN_TOTAL: '計 {{totalQuantity}} 枚',
            THE_TOTAL_AMOUNT_OF_ORDERS: '合計金額',
            SHIPPING_VALUE: '含运费{{value}}',
            AMOUNT_PAID: '支払済金額',
            AMOUNT_TO_BE_PAID: '待支付金额',
            REFUND_AMOUNT: '払い戻し金額',
            ORDER_TIME: '注文時間',
            INVOICED: '請求済',
            INVOICING_REVIEW: '請求レビュー',
            APPLY_FOR_MAKING_AN_INVOICE: '請求をリクエストする',
            VIEW_LOGISTICS: '物流を見る',
            BUY_AGAIN: '買戻し',
            HOURS: '時間',
            TO_PAY: '支払う',
            SITEWIDE: '全体のフィールド',
            BE_USABLE: '使用できます',
            UNAVAILABLE: '使用できません',
            SUBMITTED: '提出された',
            REFUND_FAILED_ERROR: '払い戻しに失敗しました-管理者に連絡してください',
            ORDER_DETAILS: '注文詳細',
            PASSED_THE_REVIEW: '試験に合格',
            NOT_ADDRESS_DATA_TIPS: '現在、住所情報はありません。クリックして住所を作成してください',
            NOT_INVOICE_HEADER_DATA_TIPS: '利用可能な請求書ヘッダーがないため、作成に進む',
            TO_CREATE_TIPS: '作成',
            ORDER: '注文',
            STORE_WIDE_COUPONS: '全店クーポン',
            DESIGNATED_PRODUCT_COUPONS: '{{value}}クーポンを指定する',
            YEAR_MONTH_DAY_FROMART_TEXT: '{{year}} {{month}},{{day}} ',
            YEAR_MONTH_DAY_PIPE_FROMART_TEXT: 'yyyy-MM-dd',
            ADDRESS_NOT_SELECTED_TIPS: '配送先住所を選択してください',
            FREE_SHIPPING_ON_SITE: '全品送料無料',
            FREE_SHIPPING_OVER_NUMBER_KG: '重量が{{値}}KGを超える場合は送料無料',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT: '金額が{{value}}KGを超えると送料無料',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT_AND_WEIGHT: '重量が{{value}}KGを超え、金額が{{mount}}KGを超える場合は送料無料'
        }
    },
};
