import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { API_SERVICE_PREFIX } from '../../../app/app.constants';
import { CurrentDeviceService } from '../../../app/core/services/current-device.service';
import { UrlHelperService } from '../../../app/core/services/url-helper.service';
import { OrderPayService } from '../../../app/ecommerce/builtin-pages/order-pay/order-pay.service';
import { WebviewAdaptorForMiniprogramService } from '../../../app/shared/webview-adaptor-for-miniprogram.service';
import { Site } from '../../../app/shared/model/site.model';
import { SiteContextService } from '../../../app/shared/services/site-context.service';
import { isPlatformBrowser } from '@angular/common';

declare const window: any;
declare const wx: any;
declare const sptcc: any;

@Injectable({
    providedIn: 'root',
})
export class SocialShare {
    constructor(
        private currentDeviceService: CurrentDeviceService,
        private orderPayService: OrderPayService,
        private webviewAdaptorForMiniprogramService: WebviewAdaptorForMiniprogramService,
        private urlHelperService: UrlHelperService,
        private siteContextService: SiteContextService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) { }

    uploadFileUrlPrefix(url: string) {
        // 如果是阿里云路径
        if (this.urlHelperService.isOssUrl(url)) {
            return url;
            //  如果是相对路径
        } else if (!this.urlHelperService.isAbsoluteUrl(url) && url?.includes('/api/') && !url?.startsWith(API_SERVICE_PREFIX)) {
            if (url?.startsWith('/')) {
                return `${API_SERVICE_PREFIX}${url}`?.replace(/\/\//g, '/');
            }
            return `${API_SERVICE_PREFIX}/${url}`?.replace(/\/\//g, '/');
        }
        return url;
    }

    globalConfig(site: Site) {
        if (site) {
            site.screenShotUrl = this.uploadFileUrlPrefix(site.screenShotUrl);
            site.iconUrl = this.uploadFileUrlPrefix(site.iconUrl);
            // 小程序主页分享
            this.webviewAdaptorForMiniprogramService.postShareMessage(site);
            if (this.currentDeviceService.isWX()) {
                this.configWxShare(site);
            } else {
                this.configSptccAppShare(site);
            }
        }
    }

    productShareConfig(wxShare: Site, shareLink?: string) {
        wxShare.screenShotUrl = this.uploadFileUrlPrefix(wxShare.screenShotUrl);
        wxShare.iconUrl = this.uploadFileUrlPrefix(wxShare.iconUrl);
        if (this.currentDeviceService.isWX()) {
            shareLink ? this.configWxShare(wxShare, shareLink) : this.configWxShare(wxShare);
        } else {
            this.configSptccAppShare(wxShare);
        }
    }

    configSptccAppShare(wxShare: Site) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        wxShare.screenShotUrl = this.uploadFileUrlPrefix(wxShare.screenShotUrl);
        wxShare.iconUrl = this.uploadFileUrlPrefix(wxShare.iconUrl);
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
        if (isAndroid) {
            try {
                if (!!sptcc && sptcc?.share) {
                    sptcc.share(1, wxShare.name, wxShare.desc, wxShare.screenShotUrl || wxShare.iconUrl);
                }
            } catch (err) {
                console.log('Error on SPTCC sharing on Android', err);
            }
        } else {
            try {
                window.webkit.messageHandlers.Share.postMessage({
                    id: '1',
                    title: wxShare.name,
                    content: wxShare.desc,
                    url: wxShare.screenShotUrl || wxShare.iconUrl
                });
            } catch (err) {
            }
        }
    }

    configWxShare(wxShare: Site, shareLink?: string) {
        wxShare.screenShotUrl = this.uploadFileUrlPrefix(wxShare.screenShotUrl);
        wxShare.iconUrl = this.uploadFileUrlPrefix(wxShare.iconUrl);
        const siteId = this.siteContextService.siteId;
        this.orderPayService.getJsSignature({
            url: location.origin + location.pathname,
            'siteId.equals': siteId
        }).subscribe(
            (res) => {
                wx.config({
                    debug: false,                      // 开启调试模式,
                    appId: res.body.appId,               // 必填，企业号的唯一标识，此处填写企业号corpid
                    timestamp: res.body.timestamp,       // 必填，生成签名的时间戳
                    nonceStr: res.body.nonceStr,         // 必填，生成签名的随机串
                    signature: res.body.signature,         // 必填，签名，见附录1
                    jsApiList: ['chooseWXPay', 'onMenuShareAppMessage',
                        'onMenuShareTimeline', 'updateAppMessageShareData', 'updateTimelineShareData'],  // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });
                wx.ready(
                    () => {
                        // 商品分享
                        this.webviewAdaptorForMiniprogramService.postShareMessage(wxShare, shareLink);
                        // 设置分享给朋友：
                        if (wx.onMenuShareAppMessage) {
                            wx.onMenuShareAppMessage({
                                title: wxShare.name,
                                desc: wxShare.desc,
                                link: shareLink || location.origin,
                                imgUrl: wxShare.screenShotUrl || wxShare.iconUrl,
                                type: 'link'
                            });
                        } else {
                            wx.updateAppMessageShareData({
                                title: wxShare.name,
                                desc: wxShare.desc,
                                link: shareLink || location.origin,
                                imgUrl: wxShare.screenShotUrl || wxShare.iconUrl,
                            });
                        }
                        // 设置分享到朋友圈：
                        const timelineShareData = {
                            title: wxShare.name,
                            link: shareLink || location.origin,
                            imgUrl: wxShare.screenShotUrl || wxShare.iconUrl,
                        };
                        if (wx.onMenuShareTimeline) {
                            wx.onMenuShareTimeline(timelineShareData);
                        } else {
                            wx.updateTimelineShareData(timelineShareData);
                        }
                    });
                wx.error(
                    () => {
                        console.log('微信JS-SDK调用wx.config()失败!!!。');
                    }
                );
            }
        );
    }
}
