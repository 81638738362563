import { afterNextRender, Directive, ElementRef, Inject, Input, PLATFORM_ID } from '@angular/core';

// 用法：
// <div appBricsAnimateOnIntersection
//      bricsAnimationName="fade-in" 
//      bricsAnimationDuration="2s"
//      bricsAnimationDelay="0.5s"
//      animateOnIntersectionRootMargin="50px"
//      animateOnIntersectionThreshold="0.75">
//   ...
// </div>

@Directive({
    selector: '[appBricsAnimateOnIntersection]'
})
export class AnimateOnIntersectionDirective {
    @Input() bricsAnimationName: string = 'fade-in';
    @Input() bricsAnimationDuration: string = '1s';
    @Input() bricsAnimationDelay: string = '1s';
    // 用于定义根元素的边距的。根元素是指与目标元素相交的元素。
    // 例如，如果您想要在目标元素进入视口之前提前触发动画，则可以使用 rootMargin 变量来定义一个较大的边距，以便在目标元素进入视口之前就触发动画。
    @Input() animateOnIntersectionRootMargin: string;
    // 用于定义目标元素与根元素相交的比例的。
    // 例如，如果您将 threshold 变量设置为 0.5，则当目标元素的一半进入视口时，将触发动画。
    @Input() animateOnIntersectionThreshold: number;
    @Input() emitOnce: boolean;

    private rootMargin: string;
    private threshold: number;
    private settedAnimationEnterClass: boolean;
    private settedAnimationExitClass: boolean;
    private isAnimating: boolean;

    constructor(private el: ElementRef,) {
        afterNextRender(() => {
            if (this.bricsAnimationName) {
                this.initListen();
            }
        });
    }

    initListen() {
        this.rootMargin = this.animateOnIntersectionRootMargin || '0px';
        this.threshold = this.animateOnIntersectionThreshold || 0;

        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    this.animationHandler(entry.intersectionRatio > this.threshold);
                });
            }, {
                rootMargin: this.rootMargin,
                threshold: this.threshold
            });

            observer.observe(this.el.nativeElement);
        } else {
            (window as Window).addEventListener('scroll', () => {
                if (!this.emitOnce ? true : (this.emitOnce && !this.settedAnimationExitClass)) {
                    this.animationHandler(this.isInViewport(this.threshold));
                }
            });
        }
    }

    isInViewport(threshold: number) {
        const rect = this.el.nativeElement.getBoundingClientRect();
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth);
        const rootMargin = parseInt(this.rootMargin.replace('px', '')); // convert to number
        return (
            rect.top >= -rootMargin &&
            rect.left >= -rootMargin &&
            rect.bottom <= (windowHeight + rootMargin) &&
            rect.right <= (windowWidth + rootMargin) &&
            (rect.top + rect.height * threshold) <= windowHeight &&
            (rect.left + rect.width * threshold) <= windowWidth
        );
    }

    animationHandler(flag: boolean) {
    }

    addEnterClass() {
        this.isAnimating = true;
        // 添加类并开始动画
        const el = this.el.nativeElement;
        el.classList.add('brics-saas-animation');
        el.style.animation = `${this.bricsAnimationName || 'fade-in'} ${this.bricsAnimationDuration || 0.6}s cubic-bezier(0.39, 0.575, 0.565, 1) ${this.bricsAnimationDelay || 0}s both`;
        this.settedAnimationEnterClass = true;
        // 在动画结束时清除标志
        setTimeout(() => {
            this.isAnimating = false;
        }, (Number(this.bricsAnimationDuration) + Number(this.bricsAnimationDelay)) * 1000);
    }

    addExitClass() {
        if (this.settedAnimationEnterClass) {
            this.isAnimating = true;
            const el = this.el.nativeElement;
            el.classList.remove('brics-saas-animation');
            el.style.animation = '';
            // 在动画结束时清除标志
            setTimeout(() => {
                this.isAnimating = false;
            }, (Number(this.bricsAnimationDuration) + Number(this.bricsAnimationDelay)) * 1000);
        }
    }
}