import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuConfig, SiteMenus } from '../../core/menu-config';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {
    matchedMenu$: BehaviorSubject<MenuConfig[]>;
    _needPushMenus = [];
    _parsedMenusByRouteAndMenuConfig = [];
    constructor(
        private router: Router,
    ) {
        this.init();
    }
    init() {
        this.router.events.pipe(filter(event => event && (event instanceof NavigationEnd))).subscribe(event => {
            const matchedMenu: MenuConfig[] = [];
            if (event && (event instanceof NavigationEnd)) {
                SiteMenus.forEach(AsideFooterMenu => {
                    let routerUrl = event?.url;
                    if (event?.url !== event?.urlAfterRedirects) {
                        routerUrl = event?.urlAfterRedirects;
                    }
                    if (this.validRoutePathByRegexOrStarStr(AsideFooterMenu?.regExp || AsideFooterMenu?.page, routerUrl)) {
                        matchedMenu.push(AsideFooterMenu);
                        if (AsideFooterMenu?.submenu?.length) {
                            AsideFooterMenu?.submenu?.forEach(asideMenuSonSubmenu => {
                                if (this.validRoutePathByRegexOrStarStr(asideMenuSonSubmenu?.regExp || asideMenuSonSubmenu?.page, routerUrl)) {
                                    matchedMenu.push(asideMenuSonSubmenu);
                                    if (asideMenuSonSubmenu?.submenu?.length) {
                                        asideMenuSonSubmenu?.submenu?.forEach(asideMenuSliblingSubmenu => {
                                            if (this.validRoutePathByRegexOrStarStr(asideMenuSliblingSubmenu?.regExp || asideMenuSliblingSubmenu?.page, routerUrl)) {
                                                matchedMenu.push(asideMenuSliblingSubmenu);
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    }
                });
                this._parsedMenusByRouteAndMenuConfig = matchedMenu;
                if (this.matchedMenu$) {
                    this.matchedMenu$.next([...matchedMenu, ...this._needPushMenus]);
                } else {
                    this.matchedMenu$ = new BehaviorSubject([...matchedMenu, ...this._needPushMenus]);
                }
            }
        });
    }

    clearPushedMenus() {
        this._needPushMenus = [];
        this.matchedMenu$.next(this._parsedMenusByRouteAndMenuConfig || []);
    }

    addMenuItems(menuItems: MenuConfig[]) {
        this._needPushMenus = menuItems || [];
        if (this.matchedMenu$) {
            this.matchedMenu$.next([...(this._parsedMenusByRouteAndMenuConfig || []), ...this._needPushMenus]);
        }
    }

    validRoutePathByRegexOrStarStr(regexOrStarStr: string | RegExp, routerUrl: string) {
        if (regexOrStarStr && regexOrStarStr instanceof RegExp) {
            return regexOrStarStr.test(routerUrl);
        }
        return routerUrl?.startsWith(regexOrStarStr as string)
    }

    generateRegexRedirectTo(regexParam: RegExp) {
        try {
            const matchResult = regexParam.exec(this.router.url);
            return regexParam.toString().replace(/\(\.\*\?\)/, matchResult[matchResult?.length - 1]).replace(/\\\//g, '/').replace(/\/\//g, '/');
        } catch (error) {
            return '';
        }
    }

    getValue() {
        return this.matchedMenu$;
    }

    getValueSync() {
        return this.matchedMenu$.getValue();
    }
}
