import { Pipe, PipeTransform } from '@angular/core';
import { UrlHelperService } from '../../core/services/url-helper.service';

@Pipe({
    name: 'imgResize'
})
export class ImgResizePipe implements PipeTransform {
    constructor(private urlHelperService: UrlHelperService) {
    }
    transform(imgUrl: string, resizeParam: string) {
        // 如果是svg和webp不支持裁剪的格式直接返回
        if (/\.(svg)$/.test(imgUrl) || /\.(webp)$/.test(imgUrl) || !imgUrl) {
            return imgUrl;
        }
        if (this.urlHelperService.isAbsoluteUrl(imgUrl)) {
            return `${imgUrl}?x-oss-process=image/resize,${resizeParam}/format,jpg/interlace,1`;
        }
        return `${imgUrl}?imageProcess=image/resize,${resizeParam}`;
    }
}
