<div class='page-header-container nav-box-shadow nav-footer-part navigation-border-bottom-container' [ngClass]="{
        'd-block tabbar-page':fixedInBottom,
        'position-fixed':!isMobile && (layoutRenderOptions?.desktopNavigationPosition === NavigationPosition.FIXED),
        'header-shadow-container':headerShadowContainer
    }" data-skeleton-bgcolor="transparent" id="siteMenuHeaderContainer">
    <ng-container *ngFor="let layoutBlock of _layout?.blocks;">
        <!-- 广告横幅 -->
        <ng-container *ngIf="(layoutBlock?.section === 'HEADER') && layoutBlock?.enabled">
            <app-banner-block
                *ngIf="(layoutBlock.blockType === 'BANNER') && bannerEnableForCurrentPage(layoutBlock)"
                [data]="layoutBlock?.data" (closeBannerEvent)="closeBanner()"
                [renderOptions]="layoutBlock?.renderOptions">
            </app-banner-block>
            <div [ngStyle]="!isMobile ? layoutBlock.renderOptions?.container : {}"
                *ngIf="(layoutBlock.blockType === 'HEADER')" class="position-relative">
                <div class="overflow-hidden">
                    <app-site-branding
                        *ngIf="((layoutBlock?.data || layoutBlock?.renderOptions)?.headerType !== 'NONE') && (layoutBlock?.data || layoutBlock?.renderOptions)?.headerType && headerBackgroundEnableForCurrentPage(layoutBlock)"
                        [ngClass]="{'pc-version':isTopOrBottomMenuForCurrentDevice()}" [isMobile]="isMobile"
                        [block]="layoutBlock" [data]="layoutBlock?.data" [renderOptions]="layoutBlock?.renderOptions"
                        [navigationHeight]="navigationRef?.clientHeight">
                    </app-site-branding>
                </div>
                <div class="navigation-menu w-100" [ngClass]="{
                    'aside-menu-header-for-mobile':isMobile && isAsideMenuForMobile(),
                    'position-relative':isMobile ? false : ((((layoutBlock?.data || layoutBlock?.renderOptions)?.headerType === 'NONE')||!(layoutBlock?.data || layoutBlock?.renderOptions)?.headerType)||!headerBackgroundEnableForCurrentPage(layoutBlock)),
                    'position-top': ((layoutBlock?.data || layoutBlock?.renderOptions)?.headerType && ((layoutBlock?.data || layoutBlock?.renderOptions)?.headerType !== 'NONE') && ((layoutBlock?.data || layoutBlock?.renderOptions)?.navigation?.position?.bottom !== 0)) && (isMobile ? isAsideMenuForMobile(): true),
                    'position-bottom':((layoutBlock?.data || layoutBlock?.renderOptions)?.headerType && ((layoutBlock?.data || layoutBlock?.renderOptions)?.headerType !== 'NONE') && ((layoutBlock?.data || layoutBlock?.renderOptions)?.navigation?.position?.bottom === 0))
                }" [style.backgroundColor]="_navigationBackgroundColor" #navigationRef>
                    <app-navigation-block *ngIf="isTopOrBottomMenuForCurrentDevice()"
                        [navigationLayoutRenderOptions]="layoutRenderOptions" [logo]="logo"
                        [siteMenu]="siteMenu" [renderOptions]="layoutBlock.renderOptions" [userAvatarUrl]="userAvatarUrl"
                        [userName]="userName" [data]="layoutBlock.data" [isLogin]="isLogin">
                    </app-navigation-block>
                    <ng-container
                        *ngIf="(layoutRenderOptions?.mobileNavigation===NavigationLayout?.LEFT_SIDE_BAR) || (layoutRenderOptions?.mobileNavigation===NavigationLayout?.RIGHT_SIDE_BAR)">
                        <ng-container *ngTemplateOutlet="mobileAsideHeader"></ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<app-side-bar [class]="isMobile ? 'left-fade-in' : 'right-fade-in'" [isMobile]="isMobile"
    *ngIf="isMobile ? sideBarMenuExpend : !isTopOrBottomMenuForCurrentDevice()" [maskEnable]="isMobile"
    [isRight]="checkIsRightAsideForCurrentDevice()" [siteLogo]="logo" [menu]="siteMenu" [renderOptions]="getHeaderBlock()?.renderOptions"
    [accountLoginBtnType]="renderOptions?.accountLoginBtnType" (closeAside)="sideBarMenuExpend = false;"
    [userAvatarUrl]="userAvatarUrl" [userName]="userName" [isLogin]="isLogin">
</app-side-bar>

<ng-template #mobileAsideHeader>
    <div id="asideMenuHeader" [ngClass]="{'navigation-shadow':navigationShadow}">
        <div class="d-flex justify-content-between align-items-center px-3" *ngIf="isMobile"
            [ngClass]="{'flex-row-reverse':checkIsRightAsideForCurrentDevice(),'navigation-shadow-container':navigationShadowContainer}"
            style="box-sizing: border-box;padding-top:{{getContentExpansionHeight() + '!important'}};padding-bottom:{{getContentExpansionHeight() + '!important'}};"
            [style.position]="(data || renderOptions)?.navigation?.position"
            [style.backgroundColor]="(data || renderOptions)?.navigation?.['background-color']">
            <div class="d-flex align-items-center mobile-aside-header-left-content"
                [ngClass]="{'flex-row-reverse':checkIsRightAsideForCurrentDevice()}">
                <i class="bi bi-list mr-2" (click)="sideBarMenuExpend = !sideBarMenuExpend;"
                    style="font-size: 2rem;"></i>
                <ng-container *ngIf="renderOptions?.languageTranslationBtnVisible">
                    <ng-container *ngTemplateOutlet="languageSwitchRef"></ng-container>
                </ng-container>
                <div class="split-line" *ngIf="checkIsRightAsideForCurrentDevice()">
                </div>
                <div *ngIf="checkIsRightAsideForCurrentDevice()"
                    class="d-flex justify-content-between align-items-center mobile-aside-header-right-content">
                    <div class="d-flex justify-content-center flex-wrap search-btn"
                        *ngIf="renderOptions?.searchVisible !== false" routerLink="/search-page">
                        <i class="bi bi-search"></i>
                        <div class="w-100 text-center" style="white-space: nowrap;" [appTranslate]="'GENERAL.SEARCH'">搜索
                        </div>
                    </div>
                    <div class="d-flex justify-content-center flex-wrap shopping-cart-btn"
                        *ngIf="renderOptions?.shoppingCartBtnVisible" routerLink="/shopping-cart">
                        <i class="bi bi-cart2"></i>
                        <div class="w-100 text-center" style="white-space: nowrap;"
                            [appTranslate]="'FLOATING_WINDOW.GO_TO_SHOPCART'">购物车</div>
                    </div>
                </div>
            </div>
            <div class="mobile-aside-header-center-content" [title]="'GENERAL.GO_TO_HOME_BTN_TEXT' | translate"
                style="cursor: pointer;" [routerLink]="['/']" [ngStyle]="{maxHeight:'calc(35px + ' + getContentExpansionHeight() + ')',
            'min-width': 'calc(35px + ' + getContentExpansionHeight() + ')'}"
                style="flex:1;object-fit: contain;padding:0 1rem;">
                <img [src]="logo" alt="" class="site-logo" style="max-height:100%; max-width: 100%;" 
                    appPureImgErrorIdentification  [setErrorImg]="true" loadFailedImage="/assets/icon/img-blank.svg"
                    [style.visibility]="((data || renderOptions)?.logoVisible !== false) ? 'visible' : 'hidden'">
            </div>
            <div *ngIf="checkIsLeftAsideForCurrentDevice()" style="min-width: 30px;"
                class="d-flex justify-content-between align-items-center mobile-aside-header-right-content">
                <div class="d-flex justify-content-center flex-wrap search-btn" routerLink="/search-page"
                    *ngIf="renderOptions?.searchBtnType === 'ICON_BTN'">
                    <i class="bi bi-search"
                        [style.fontSize]="((data || renderOptions)?.navigation?.contentExpansionHeightSize === 'SMALL')&&'1.2rem'"></i>
                    <div class="w-100 text-center" [appTranslate]="'GENERAL.SEARCH'"
                        style="font-size: 12px;white-space: nowrap;"
                        *ngIf="(data || renderOptions)?.navigation?.contentExpansionHeightSize !== 'SMALL'">搜索</div>
                </div>
                <div *ngIf="(renderOptions?.searchBtnType === 'LIKE_INPUT_BTN')||(!renderOptions?.searchBtnType)"
                    class="search b-labr" routerLink="/search-page">
                    <p class="search-content"><i class="iconfont icon-sousuo"></i>
                        <span [appTranslate]="'GENERAL.SEARCH'" style="white-space: nowrap;">搜索</span>
                    </p>
                </div>
                <div class="d-flex justify-content-center flex-wrap shopping-cart-btn"
                    *ngIf="renderOptions?.shoppingCartBtnVisible" routerLink="/shopping-cart">
                    <i class="bi bi-cart2"
                        [style.fontSize]="((data || renderOptions)?.navigation?.contentExpansionHeightSize === 'SMALL')&&'1.4rem'"
                        [style.marginLeft]="((data || renderOptions)?.navigation?.contentExpansionHeightSize === 'SMALL')&&'1rem'"></i>
                    <div class="w-100 text-center" [appTranslate]="'FLOATING_WINDOW.GO_TO_SHOPCART'"
                        style="font-size: 12px;white-space: nowrap;"
                        *ngIf="(data || renderOptions)?.navigation?.contentExpansionHeightSize !== 'SMALL'">购物车</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #languageSwitchRef>
    <div class="language-switch d-inline-block" ngbDropdown container="body">
        <a class="menu-link" ngbDropdownToggle>
            <span class="menu-title position-relative" style="display: inline-flex; height: 22px;">
                &nbsp;
                <span class="fs-8 rounded d-inline-flex flex-row-reverse align-items-center" style="cursor: pointer;">
                    <img class="rounded-1 mr-2" style="height: 22px;" [src]="'assets/icon/flags/globe.svg'" />
                </span>
            </span>
        </a>
        <div class="menu-sub menu-sub-dropdown w-175px py-3 translate-option-item border-0" ngbDropdownMenu>
            <div class="menu-item px-3" *ngFor="let country of countries" ngbDropdownItem>
                <a (click)="setLang(country.langKey)" class="menu-link d-flex px-3"
                    [ngClass]="{ active: country.langKey === selectedLang() }">
                    {{country.langKey | findLanguageFromKey}}
                </a>
            </div>
        </div>
    </div>
</ng-template>