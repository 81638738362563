import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Post } from '../../../builtin-pages/post-list/_model/post.model';
import { CurrentDeviceService } from '../../../core/services/current-device.service';
import { NewLayoutType } from '../../model/page-block.model';
import { jsonParse } from '../../pipes/name-translate.pipe';
import dayjs from 'dayjs/esm';

export enum PostStyleType {
    CARD = 'CARD',
    LIST = 'LIST',
    NEWS = 'NEWS',
}

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnChanges {
    @Input() type: PostStyleType = PostStyleType.CARD;
    @Input() post: Post;
    @Input() renderOptions: any;
    @Input() data: any;
    @Input() hiddenAuthorOrDateOfSpace: boolean;
    PostStyleType = PostStyleType;
    NewLayoutType = NewLayoutType;
    postRenderOptions: any = {};

    constructor(
        public translateService: TranslateService,
        public currentDeviceService: CurrentDeviceService,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.postRenderOptions = {
            "enablePublishAuthor": true,
            "enablePublishDate": true,
            "enableBanner": true,
            "enableLike": true,
            "enableComment": true,
            "enabledShare": true,
            ...this.renderOptions,
            ...jsonParse(this.post?.renderOptions)
        };
    }

    getPublishDate(date, formart: string) {
        // YYYY-MM-DDTHH
        return dayjs(date)?.format(formart);
    }
}
