export const invoiceCenterTranslate = {
    GENERAL: {
        ENABLE_INVOICE_ORDERS: '可開票訂單',
        INVOICED_ORDERS: '開票記錄',
        NOT_INVOICE_ORDERS: '待開票訂單',
        INVOICE_HEADERS: '抬頭管理',
    },
    SHARED: {
        NOT_INVOICE: '待開票',
        TO_DETAIL: '查看詳情',
        TO_APPLY: '申請開票',
        PENDING_TIPS: '已申請，待稽核',
        PENDING: '待稽核',
        DISABLED_INVOICE_TIPS: '該訂單不支持開票',
    }
}