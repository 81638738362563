export const userTranslate = {
    INFOMATION: {
        AVATAR: {
            TITLE: '头像'
        },
        NICK_NAME: {
            TITLE: '昵稱',
            PLACEHOLDER: '請輸入昵稱…'
        },
        GENDER: {
            TITLE: '性别',
            MALE: '男',
            FEMALE: '女',
            OTHER: '其他',
            UNKNOWN: '未知'
        },
        NAME: {
            TITLE: '姓名',
            PLACEHOLDER: '請輸入姓名…'
        },
        MOBILE_PHONE: {
            TITLE: '聯繫方式',
            PLACEHOLDER: '请輸入聯繫方式...'
        },
        TYPE: {
            TITLE: '類型',
            INDIVIDUAL: '個人',
            ORGANIZATION: '組織'
        },
        ENTITY_SCALE: {
            TITLE: '主體規模',
            OPTIONS: ['有限責任公司', '股份有限公司', '個人獨資企業', '合伙企业', '全名所有制企業', '集体所有制企业', '其他']
        },
        ANNUAL_REVENUE: {
            TITLE: '年營業收入',
            PLACEHOLDER: '請輸入年營業收入...'
        },
        EMPLOYEE_NUMBER: {
            TITLE: '用工人数',
            PLACEHOLDER: '請輸入用工人數…'
        },
        SAVE_BTN_TEXT: '更新資料',
        SAVE_BTN_SUCCESS_TEXT: '資料更新成功！',
        SAVE_BTN_ERROR_TEXT: '資料更新失败！',
        UPDATE_AVATAR_ERROR: '上傳頭像失敗，',
    },
    ACCOUNT_SECURITY: {
        SET_PASSWORD: {
            TITLE: '設置密碼',
            ENABLE: '已設定',
            NOT_ENABLE: '未設定',
            SET_BTN_TEXT: '设置',
            CHANGE_BTN_TEXT: '更改',
        },
        BIND_MOBILE_PHONE: {
            TITLE: '綁定手機',
            ENABLE: '已綁定',
            NOT_ENABLE: '未綁定',
            SET_BTN_TEXT: '綁定',
            CHANGE_BTN_TEXT: '更改',
        },
        BIND_WECHAT: {
            TITLE: '綁定微信',
            ENABLE: '已綁定',
            NOT_ENABLE: '未綁定',
            SET_BTN_TEXT: '綁定',
            CHANGE_BTN_TEXT: '更改',
            CHANGE_TITLE: '你確定要解綁微信嗎？'
        },
        BIND_PHONE_PAGE: {
            TITLE: '為了您的帳戶安全，需要{{action}}您的手機號',
            BIND: '綁定',
            VERIFY: '驗證',
            CHANGE_BIND: '换绑',
            SUCCESS_MESSAGE: '{{action}}手機號：{{phoneNumber}} 成功',
            ERROR_MESSAGE: '手機號更新失敗！',
            MOBILE_PHONE: {
                TITLE: '手機號',
                PLACEHOLDER: '請輸入手機號…'
            },
            OLD_MOBILE_PHONE: {
                TITLE: '原手機號',
                PLACEHOLDER: '請輸入原手機號...'
            },
            VERTIFY_CODE: {
                TITLE: '驗證碼',
                PLACEHOLDER: '請輸入驗證碼...',
                GET_BTN_TEXT: '獲取驗證碼',
                SEND_TEXT: '發送',
                PHONE_CODE_ERROR_MESSAGE: '驗證碼獲取失敗！',
                IMAGE_CODE_ERROR_MESSAGE: '驗證碼獲取失敗！',
            },
            NEW_MOBILE_PHONE: {
                TITLE: '新手機號',
                PLACEHOLDER: '請輸入新手機號…'
            },
            CHANGE_MOBILE_PHONE_BTN_TEXT: '修改手機號',
            BIND_MOBILE_PHONE_BTN_TEXT: '綁定手機號',
        },
        CHANGE_PASSWORD_PAGE: {
            TITLE: '為了您的帳戶安全，請選擇驗證管道完成身份驗證：',
            PHONE_VERTIFY: {
                TITLE: '手機驗證',
                MOBILE_PHONE: {
                    TITLE: '手機號',
                    PLACEHOLDER: '請輸入當前綁定的手機號...'
                },
                PASSWORD: {
                    TITLE: '新密碼',
                    PLACEHOLDER: '請輸入新密碼…'
                },
                VERTIFY_CODE: {
                    TITLE: '驗證碼',
                    PLACEHOLDER: '請輸入驗證碼...',
                    GET_BTN_TEXT: '獲取驗證碼',
                    SEND_TEXT: '發送',
                    PHONE_CODE_ERROR_MESSAGE: '驗證碼獲取失敗！',
                    IMAGE_CODE_ERROR_MESSAGE: '驗證碼獲取失敗！',
                },
                NOT_BIND_PHONE_TEXT: '暫未綁定手機，無法通過手機進行驗證，請先',
                BIND_PHONE_LINK_TEXT: '綁定手機'
            },
            PASSWORD_VERTIFY: {
                TITLE: '密碼驗證',
                OLD_PASSWORD: {
                    TITLE: '原密码',
                    PLACEHOLDER: '請輸入原密碼…'
                },
                NEW_PASSWORD: {
                    TITLE: '新密碼',
                    PLACEHOLDER: '請輸入新密碼…'
                },
            },
            SET_PASSWORD_BTN_TEXT: '設置密碼',
            CHANGE_PASSWORD_BTN_TEXT: '修改密碼',
            SUCCESS_MESSAGE: '修改密碼成功！',
            ERROR_MESSAGE: '修改失敗！',
            GET_VERTIFY_CODE_ERROR_MESSAGE: '驗證碼獲取失敗！',
            SEND_VERTIFY_CODE_ERROR_MESSAGE: '驗證碼獲取失敗！',
        },
        SET_LOGIN_NAME: {
            TITLE: '設置登錄名',
            ENABLE: '已設置',
            NOT_ENABLE: '未設置',
            SET_BTN_TEXT: '設置',
            CURRENT_NAME_TIPS: '目前登入名稱：',
            INIT_NAME_TIPS: '初始登入名稱：',
            OLD_LOGIN_NAME_TIPS: '原登入名稱：',
            NEW_LOGIN_NAME_TIPS: '新登入名稱：',
            CHANGE_LOGIN_NAME_BTN_TEXT: '修改登錄名',
            NEW_LOGIN_NAME_PLACEHOLDER: '修改後不能二次修改',
            LOGIN_CUSTOMIZED_TIP: '登錄名已經設置了，不能再次修改',
            CHANGE_LOGIN_NAME_ERROR_TIPS: '設定登入名失敗：',
            LOGIN_NAME_INVALID_ERROR_MESSAGE: '登錄名格式不正確，請檢查，目前僅支援大小寫字母、數位和 指定特殊符號（- 、+ 、 _）！',
        }
    },
    REFERRAL: {
        REFERREEID_LABEL: '被推薦人：',
        REFERRAL_DATE_LABEL: '推薦時間：',
        REFERRAL_SHARE_URL_DESC: '註冊有獎，快來看看吧：',
        REFERRAL_SHARE_CODE_DESC: '填寫我的推薦碼有更多福利哦：',
    },
    AFFILIATE: {
        TITLE: '申請加入聯盟',
        SUCCESS_TEXT: '恭喜您，已經成功加入聯盟！',
        EMAIL_LABEL: '聯繫郵箱',
        EMAIL_PLACEHOLDER: '用於接收通知與聯繫',
        EMAIL_ERROR_REQUIRED: '請填寫聯繫郵箱',
        EMAIL_ERROR_INVALID: '聯繫郵箱 格式有誤！',
        PHONE_LABEL: '聯繫電話',
        PHONE_PLACEHOLDER: '請輸入您的常用電話號',
        PHONE_ERROR_REQUIRED: '請填寫聯繫電話',
        COPY_CODE_TEXT: '複製專屬代碼：',
        PHONE_ERROR_INVALID: '聯繫電話 格式有誤！',
        FIRST_NAME_TEXT: '名字',
        FIRST_NAME_ERROR_REQUIRED: '請填寫名字',
        FIRST_NAME_PLACEHOLDER: '請輸入您的名字',
        LAST_NAME_TEXT: '姓氏',
        LAST_NAME_PLACEHOLDER: '請輸入姓氏',
        LAST_NAME_ERROR_REQUIRED: '請輸入您的姓氏',
        BIRTHDAY_TEXT: '生日',
        BIRTHDAY_PLACEHOLDER: '輸入生日',
        BIRTHDAY_ERROR_REQUIRED: '請填寫您的生日！',
        PAYPAL_EMAIL_PLACEHOLDER: '輸入您的 PayPal Email 提現帳號',
        PAYPAL_EMAIL_ERROR_REQUIRED: '請填寫 PayPal Email',
        PAYPAL_EMAIL_ERROR_INVALID: 'PayPal Email 格式有誤！',
        FULL_ADDRESS_TEXT: '詳細位址',
        FULL_ADDRESS_PLACEHOLDER: '如：街道位址',
        FULL_ADDRESS_ERROR_REQUIRED: '請填寫您的詳細位址！',
        COUNTRY_TEXT: '國家/地區',
        COUNTRY_ERROR_REQUIRED: '請填寫國家/地區',
        STATE_TEXT: '省/州',
        STATE_PLACEHOLDER: '輸入 省/州名',
        STATE_ERROR_REQUIRED: '請輸入 省/州',
        SELECT_TEXT: '請選擇',
        CITY_TEXT: '市名',
        CITY_PLACEHOLDER: '輸入 市名',
        CITY_ERROR_REQUIRED: '請填寫市名',
        ZIP_CODE_TEXT: '郵遞區號',
        ZIP_CODE_PLACEHOLDER: '輸入郵遞區號',
        ZIP_CODE_ERROR_REQUIRED: '請填寫郵遞區號',
        APPLICATION_STATEMENT_TEXT: '個人介紹及申請理由',
        APPLICATION_STATEMENT_PLACEHOLDER: '請介紹個人情況及說明申請理由',
        REMARKS_TEXT: '額外備註',
        REMARKS_PLACEHOLDER: '如果有額外備註請再此說明',
        FORM_ERROR_MSG: '當前資訊填寫不完整！',
    },
    COMMISSION: {
        COMMISSION_SETTLEMENT: '傭金結算',
        SETTLEMENT_AMOUNT_LABEL: '待結算金額：',
        SETTLEMENT_BTN: '結算',
        COMMISSION_RECORDS: '傭金記錄',
        SETTLEMENT_RECORDS: '結算記錄',
        COMMISSION_SETTLEMENT_CONFIRM_TITLE: '結算信息確認',
        RECEIPT_INFO: '收款資訊',
        ENABLE_SETTLEMENT_TEXT: '可結算',
        SEE_ALL_SETTLEMENTS: '查看結算記錄',
        STATUS: '狀態',
        AFFILIATE: '聯盟網絡',
        REFERRAL: '推薦網絡',
        REWARD_DETAIL: '獎勵明細',
        REWARD_STATUS: '結算狀態',
        PARTNER_TYPE: '獎勵來源',
        PARTNER_CODE: '行銷程式碼',
        REWARD: '行銷獎勵',
        SETTLEMENT_DATE: '結算日期',
        PAYMENT_DATE: '付款日期',
        PAYMENT_METHOD: '付款方式',
        PARTNER_NETWORD: '行銷網路',
        CUSTOMER_ID: '客戶ID',
        REWARD_TYPE: '獎勵類型',
        ORDER_TYPE: '訂單類型',
        ORDER_ID: '訂單ID',
        ORDER_TOTAL_AMOUNT: '訂單總金額',
        REWARD_RULE: '獎勵規則',
        ORDER_DATE: '下單日期',
        CONFIRMED_DATE: '確認日期',
        PAYMENT_DETAIL: '付款詳情',
        PAYMENT_REFERRENCE: '付款來源',
        PAYMENT_REMARK: '付款備註',
        ORDER_COMMISSION: '訂單轉換',
        TIER_UPGRADE: '等級獎勵',
        RECEIPT_ACCOUNT: '收款帳號',
        RECEIPT_TYPE: '收款管道',
        CASH_AMOUNT: '傭金收入',
        PERIOD_START_DATE: '結算開始日期',
        PERIOD_END_DATE: '結算结束日期',
        REWARD_SOURCE_TYPE: '獎勵來源',
    },
    MARKING: {
        NETWORK: '營銷網路',
        NETWORK_ERROR_REQUIRED: '請選擇一個想加入的營銷網路',
    },
    AFFILIATE_PROFILE: {
        MENU_TITLE: '資料',
        TITLE: '修改聯盟資料',
        NETWORK_INFO_LABEL: '網路資訊',
        RECEIPT_INFO_LABEL: '收款資訊',
        BASIC_INFO_LABEL: '基本資訊',
        ADDRESS_INFO_LABEL: '地址資訊',
    }
};
