export enum AuthenticationMethodEnum {
    'LOGIN_NAME' = 'LOGIN_NAME',
    'EMAIL' = 'EMAIL',
    'MOBILE' = 'MOBILE',
    'WECHAT_MP' = 'WECHAT_MP',
    'WECHAT_OPEN_PLATFORM' = 'WECHAT_OPEN_PLATFORM',
}

export class AuthenticationMethods {
    id?: number;
    authenticationMethod?: AuthenticationMethodEnum;
    enableLogin?: boolean;
    enableRegister?: boolean;
    configId?: string;
    options?: string;
    // 其实是SiteSecuritySetting对象
    site?: string;
}

export class SiteSecuritySetting {
    constructor(siteSecuritySetting?: SiteSecuritySetting) {
        if (siteSecuritySetting) {
            for (const key in siteSecuritySetting) {
                if (key) {
                    this[key] = siteSecuritySetting[key];
                }
            }
        }
    }
    id?: string;
    passwordEnabled?: boolean;
    passwordMinimumLength?: number;
    passwordRequireNumbers?: boolean;
    passwordRequireSymbols?: boolean;
    passwordRequireUppercase?: boolean;
    passwordRequireLowercase?: boolean;
    loginFailureMaxCount?: number;
    loginFailureResetMinutes?: number;
    defaultAuthenticationMethod?: AuthenticationMethodEnum;
    authenticationMethods?: AuthenticationMethods[];
}

export const defaultSiteSecuritySetting = {
    passwordEnabled: true,
    passwordMinimumLength: 8,
    passwordRequireNumbers: true,
    passwordRequireSymbols: true,
    passwordRequireUppercase: false,
    passwordRequireLowercase: true,
    loginFailureMaxCount: 3,
    loginFailureResetMinutes: 5,
    defaultAuthenticationMethod: AuthenticationMethodEnum.MOBILE,
    authenticationMethods: [{
        authenticationMethod: AuthenticationMethodEnum.MOBILE,
        enableLogin: false,
        enableRegister: false,
    }, {
        authenticationMethod: AuthenticationMethodEnum.WECHAT_MP,
        enableLogin: false,
        enableRegister: false,
        configId: undefined,
    }, {
        authenticationMethod: AuthenticationMethodEnum.WECHAT_OPEN_PLATFORM,
        enableLogin: false,
        enableRegister: false,
        configId: undefined,
    }, {
        authenticationMethod: AuthenticationMethodEnum.EMAIL,
        enableLogin: false,
        enableRegister: false,
    }, {
        authenticationMethod: AuthenticationMethodEnum.LOGIN_NAME,
        enableLogin: false,
        enableRegister: false,
    }],
}