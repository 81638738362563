export const orderTranslate = {
    ERROR: {
        BINDING_FORM_FAILED: '繫結表單失敗：',
        FAILED_TO_SUBMIT_ORDER: '提交訂單失敗：',
    },
    GENERAL: {
        DISCOUNT_TIP: '聯盟折扣優惠、優惠券抵扣優惠不適用於運費',
        SUBMIT_ORDER: '提交訂單',
        ORDER_ID: '訂單編號',
        TOTAL_AMOUNT_OF_ORDERS: '訂單總金額',
        AMOUNT_PAID: '已支付金額',
        AMOUNT_TO_BE_PAID: '待付金額',
        FOLLOW_THE_PAYMENT_INSTRUCTIONS_TO_PAID: '您已選擇線下支付，請按照支付說明進行付款：',
        CONTACT_THE_STORE_TO_PAID: '您已選擇線下支付，請聯繫店家進行付款。',
        PAYMENT_BY_OTHER_METHODS: '其他方式支付',
        OFFLINE_PAYMENTS: '線下支付',
        STRIPE_PAYMENTS: 'Stripe 支付',
        PAY_FOR_THE_ORDER: '訂單支付',
        CONFIRM_PAYMENT: '確認支付',
        CONFIRM_THE_PAYMENT_INFORMATION: '請確認以下支付資訊',
        PAYMENT_AMOUNT: '支付金額',
        WECHAT_PAY: '微信支付',
        PAY_BY_ALIPAY: '支付寶',
        PAY_BY_PAYPAY: 'PayPay',
        PAYMENT_METHOD: '支付方式',
        WECHAT_TO_SCAN_THE_CODE_TO_PAY: '請使用微信掃一掃進行掃碼支付',
        PAYMENT_RESULT_CONFIRMATION: '支付結果確認',
        PLEASE_MAKE_PAYMENT_ON_THE_NEWLY_OPENED_PAGE: '請在新打開的頁面進行支付',
        AFTER_PAYMENT_CONFIRMATION: '支付完成後，根據您的情況點擊下面按鈕。',
        PAYMENT_COMPLETED: '支付完成',
        ORDER_PAYMENT_COMPLETED: '訂單支付完成',
        PAYMENT_COMPLETED_TIMEOUT_TIP: '{{value}} 秒後自動跳轉到訂單清單頁。',
        PAYMENT_NOT_COMPLETED: '支付未完成',
        SELECT_PAYMENT_METHOD: '選擇支付方式',
        GIFT_CARD_PAYMENT: '禮品卡支付',
        CURRENTLY_UNAVAILABLE_BALANCE: '暫無可使用的餘額',
        RECHARGE: '充值',
        CANNOT_BE_PAID_WITH_A_GIFT_CARD: '訂單中包含禮品卡商品，無法使用禮品卡支付哦',
        BALANCE: '餘額',
        USE: '使用',
        DEDUCT: '抵扣',
        PAY: '支付',
        PAY_AMOUNT: '支付金額',
        NO_PAYMENT_METHOD_AVAILABLE: '沒有可使用的支付方式',
        NO_PAYMENT_METHOD_AVAILABLE_TIPS: '無可用支付方式，請刷新再試或者聯繫管理員！',
        BUY_AND_PAY: '購買 支付',
        PAYMENT_ORDER_FAILED: '支付下單失敗',
        PAYMENT_TIMED_OUT: '支付超時，請重新進行支付',
        PAYMENT_CANCELLED: '支付已取消',
        Available_Balance: '可用餘額',
        Unavailable_Balance: '不可用餘額',
        Gift_Card_Istructions: '注：僅能使用當前訂單同種貨幣的禮品卡餘額',
    }
};
