export enum GradientMode {
    // 线性渐变 
    "LINEAR-GRADIENT" = "LINEAR-GRADIENT",
    // 径向渐变
    "RADIAL-GRADIENT" = "RADIAL-GRADIENT",
}

export class GradientColor {
    mode?: GradientMode;
    deg?: number;
    data?: {
        color: string;
        position: string;
    }[];
}

export const defaultGradientColors = [
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(255, 110, 127)',
            position: '0%'
        }, {
            color: 'rgb(191, 233, 255)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(230, 92, 0)',
            position: '0%'
        }, {
            color: 'rgb(249, 212, 35)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(236, 0, 140)',
            position: '0%'
        }, {
            color: 'rgb(252, 103, 103)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(0, 70, 127)',
            position: '0%'
        }, {
            color: 'rgb(165, 204, 130)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(7, 101, 133)',
            position: '0%'
        }, {
            color: 'rgb(255, 255, 255)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(151, 150, 240)',
            position: '0%'
        }, {
            color: 'rgb(251, 199, 212)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: 'rgb(189, 195, 199)',
            position: '0%'
        }, {
            color: 'rgb(44, 62, 80)',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: '#6c6dfb',
            position: '0%'
        }, {
            color: '#4e4dff',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#533457',
            position: '0%'
        }, {
            color: '#555874',
            position: '25%'
        }, {
            color: '#4d7c93',
            position: '50%'
        }, {
            color: '#33a1b3',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#351b90',
            position: '0%'
        }, {
            color: '#4559a9',
            position: '25%'
        }, {
            color: '#4692c2',
            position: '50%'
        }, {
            color: '#28cddb',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#351b90',
            position: '0%'
        }, {
            color: '#4559a9',
            position: '25%'
        }, {
            color: '#4692c2',
            position: '50%'
        }, {
            color: '#28cddb',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#dd5938',
            position: '0%'
        }, {
            color: '#c47c68',
            position: '25%'
        }, {
            color: '#9d9999',
            position: '50%'
        }, {
            color: '#47b3cc',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#c72970',
            position: '0%'
        }, {
            color: '#d35a7b',
            position: '25%'
        }, {
            color: '#de8186',
            position: '50%'
        }, {
            color: '#e8a491',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#189382',
            position: '0%'
        }, {
            color: '#75b2a1',
            position: '25%'
        }, {
            color: '#b8d0c2',
            position: '50%'
        }, {
            color: '#f9eee3',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#5100f7',
            position: '0%'
        }, {
            color: '#7858d9',
            position: '25%'
        }, {
            color: '#858bb9',
            position: '50%'
        }, {
            color: '#7fba97',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#240af0',
            position: '0%'
        }, {
            color: '#9f4ec2',
            position: '25%'
        }, {
            color: '#d18691',
            position: '50%'
        }, {
            color: '#f4bf53',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 25,
        data: [{
            color: '#240af0',
            position: '0%'
        }, {
            color: '#9f4ec2',
            position: '25%'
        }, {
            color: '#d18691',
            position: '50%'
        }, {
            color: '#f4bf53',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 160,
        data: [{
            color: '#0093E9',
            position: '0%'
        }, {
            color: '#80D0C7',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 90,
        data: [{
            color: '#00DBDE',
            position: '0%'
        }, {
            color: '#FC00FF',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 0,
        data: [{
            color: '#D9AFD9',
            position: '0%'
        }, {
            color: '#97D9E1',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 43,
        data: [{
            color: '#4158D0',
            position: '0%'
        }, {
            color: '#C850C0',
            position: '46%'
        }, {
            color: '#FFCC70',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 19,
        data: [{
            color: '#21D4FD',
            position: '0%'
        }, {
            color: '#B721FF',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 45,
        data: [{
            color: '#FBDA61',
            position: '0%'
        }, {
            color: '#FF5ACD',
            position: '100%'
        }]
    },
    {
        mode: GradientMode["LINEAR-GRADIENT"],
        deg: 45,
        data: [{
            color: '#30cfd0',
            position: '0%'
        }, {
            color: '#330867',
            position: '100%'
        }]
    }
];

export function tranformGradientColorStyle(value: any) {
    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        try {
            value = JSON.parse(value);
        } catch (error) {
            return null;
        }
    }
    // linear-gradient(90deg, rgb(189, 195, 199) 0%, rgb(44, 62, 80) 100%)
    return `${value?.mode?.toLowerCase()}(${value?.deg}deg, ${value?.data?.map(item => item?.color + ' ' + item?.position)?.join(', ')})`;
}

export function convertToHex(color) {
    // 检查输入是否是有效的 RGB 或 RGBA 格式
    const rgbRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*(\d+(?:\.\d+)?))?\)$/;
    const match = color.match(rgbRegex);

    if (match) {
        // 提取 RGB 或 RGBA 值
        const red = parseInt(match[1]);
        const green = parseInt(match[2]);
        const blue = parseInt(match[3]);
        const alpha = match[4] ? parseFloat(match[4]) : 1;

        // 检查是否是倍速的 RGB 或 RGBA 数据
        if (red > 255 || green > 255 || blue > 255 || alpha > 1) {
            return color; // 返回原始输入
        }

        // 将 RGB 或 RGBA 转换为十六进制格式
        const hexRed = red.toString(16).padStart(2, '0');
        const hexGreen = green.toString(16).padStart(2, '0');
        const hexBlue = blue.toString(16).padStart(2, '0');
        const hexAlpha = Math.round(alpha * 255).toString(16).padStart(2, '0');

        if (alpha === 1) {
            return `#${hexRed}${hexGreen}${hexBlue}`;
        } else {
            return `#${hexRed}${hexGreen}${hexBlue}${hexAlpha}`;
        }
    } else {
        return color; // 返回原始输入
    }
}
