import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs/esm';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { ToastService } from '../../shared/services/toast.service';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { UserRouteAccessService } from '../auth/user-route-access-service';
import { IAccount } from '../user/account.model';

@Injectable({ providedIn: 'root' })
export class UserAccountService implements OnDestroy {
    public userAccountNeedToUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        private http: HttpClient,
        private authServerProvider: AuthServerProvider,
        private router: Router,
        private toast: ToastService,
        private userRouteAccessService: UserRouteAccessService,
        private translateService: TranslateService,
    ) { }
    updateUserAccount(req: IAccount): Observable<IAccount> {
        const headerOptions = new HttpHeaders({
            'brics-api-success-notify': 'false'
        });
        return this.http.put<IAccount>(API_SERVICE_PREFIX + '/user/api/account', req, { headers: headerOptions })
            .pipe(map((res: IAccount) => this.convertDateFromServer(res)))
            .pipe(tap(() => this.forceUpdateUserAccount(true)));
    }

    getUserAccount(): Observable<IAccount> {
        const headerOptions = new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        });
        if (this.authServerProvider.getToken()) {
            return this.http.get<IAccount>(API_SERVICE_PREFIX + '/user/api/account', { headers: headerOptions })
                .pipe(map((res: IAccount) => this.convertDateFromServer(res)))
                .pipe(tap(() => this.forceUpdateUserAccount(false)));
        } else {
            return new BehaviorSubject({});
        }
    }
    queryUserWxOpenId(userId: number): Observable<string> {
        const headerOptions = new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        });
        return this.http.get(API_SERVICE_PREFIX + `/user/api/wx/openId/${userId}`, { headers: headerOptions, responseType: 'text' });
    }
    forceUpdateUserAccount(needUpdate: boolean) {
        this.userAccountNeedToUpdate$.next(needUpdate);
    }
    protected convertDateFromServer(res: IAccount): IAccount {
        if (res) {
            res.createdDate = res.createdDate ? dayjs(res.createdDate) : undefined;
            res.lastModifiedDate = res.lastModifiedDate ? dayjs(res.lastModifiedDate) : undefined;
        }
        return res;
    }
    ngOnDestroy() {
        this.userAccountNeedToUpdate$.complete();
    }

    userLogin(router: Router, showToast = true) {
        if (showToast) {
            try {
                this.toast.load(this.translateService.instant('GENERAL.LOGIN_CHECKING') + '...', 0);
            } catch (error) {
            }
        }
        const isLogin = this.userRouteAccessService.checkLogin(['ROLE_USER'], this.router.url);
        if (showToast) {
            this.toast.hide();
        }
        return isLogin;
    }
}
