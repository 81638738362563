export const accountManagementTranslate = {
    USER: {
        LIST_PAGE: {
            CUSTOMER_USER: '客戶帳號',
            TEACHER_USER: '教師帳號',
            ADD_BTN_TEXT: '添加管理員帳號',
            QUERY_FORM: {
                LOGIN: {
                    TITLE: '帳號',
                    PLACEHOLDER: '請輸入帳號進行査詢…',
                },
                FIRST_NAME: {
                    TITLE: '用户名',
                    PLACEHOLDER: '请输入用户名进行查询...',
                },
                MOBILE_PHONE: {
                    TITLE: '手機號',
                    PLACEHOLDER: '请输入手机号进行查询...',
                },
                EMAIL: {
                    TITLE: '郵箱',
                    PLACEHOLDER: '请输入邮箱进行查询...',
                },
            },
            TABLE: {
                TH: {
                    LOGIN: '登入帳號',
                    FIRST_NAME: '用户名',
                    CONTACT: '聯繫方式',
                    ACTIVATED: '是否啟動',
                    PASSWORD_LOGIN_ENABLED: '密码登录',
                    MOBILE_PHONE_LOGIN_ENABLED: '手機號登入',
                    WECHAT_LOGIN_ENABLED: '微信登入',
                },
                TD: {
                    ACTIVATED_TITLE: '點擊更改帳號啟動狀態',
                    ACTIVATED: '已啟動',
                    NOT_ACTIVATED: '未激活',
                    RESET_BTN_TEXT: '重置密码'
                }
            },
            CHANGE_ACTIVE: {
                TITLE: '你确定要更改账号 "{{login}}" 的激活状态吗？'
            },
            DELETE_USER: {
                TITLE: '你确定要删除账号 "{{login}}" 吗？'
            }
        },
        UPDATE_PAGE: {
            TITLE: '{{action}}账号',
            LOGIN: {
                TITLE: '登入帳號',
                PLACEHOLDER: '请输入登录账号...',
                REQUIRED_ERROR_MESSAGE: '帳號不能為空！'
            },
            FIRST_NAME: {
                TITLE: '用户名',
                PLACEHOLDER: '請輸入用戶名…',
            },
            PASSWORD: {
                TITLE: '密碼',
                PLACEHOLDER: '請輸入密碼…',
                REQUIRED_ERROR_MESSAGE: '密码不能为空！'
            },
            REPEAT_PASSWORD: {
                TITLE: '確認密碼',
                PLACEHOLDER: '请输入确认密码...',
                REQUIRED_ERROR_MESSAGE: '确认密码不能为空！',
                REPEAT_ERROR_MESSAGE: '确认密码必须与密码保持一致！',
            },
            MOBILE_PHONE: {
                TITLE: '手機號',
                PLACEHOLDER: '请输入手机号...',
            },
            EMAIL: {
                TITLE: '郵箱',
                PLACEHOLDER: '請輸入郵箱…',
                EMAIL_ERROR_MESSAGE: '郵箱格式不正確！'
            },
            ACTIVATED: {
                TITLE: '是否啟動'
            },
        },
        RESET_PASSWORD_PAGE: {
            TITLE: '您正在为账号 "{{login}}" 重置登录密码',
            PASSWORD: {
                TITLE: '密碼',
                PLACEHOLDER: '請輸入密碼…',
                REQUIRED_ERROR_MESSAGE: '密码不能为空！',
            },
            REPEAT_PASSWORD: {
                TITLE: '確認密碼',
                PLACEHOLDER: '请输入确认密码...',
                REQUIRED_ERROR_MESSAGE: '确认密码不能为空！',
                REPEAT_ERROR_MESSAGE: '确认密码必须与密码保持一致！',
            }
        }
    },
};