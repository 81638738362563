export const contentManagementTranslate = {
    POST: {
        LIKED_CANCELLED: 'Liked cancelled!',
        CANNOT_REPEAT_LIKES: 'Can not repeat likes!',
    },
    FORMS: {
        GET_FORM_FAILED: 'Failed to get form:',
        DISABLED_SUBMIT_FOR_NO_DATA: 'No data is currently filled in and cannot be submitted!',
        GET_FORM_DATE_ERROR: 'Failed to get form data:',
    },
    ERROR: {
    },
    GENERAL: {
    }
};
