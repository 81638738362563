import { Injectable, TemplateRef } from '@angular/core';
import { ModalService, ModalServiceComponent } from 'ng-zorro-antd-mobile';

@Injectable({
    providedIn: 'root'
})
export class ModalAlertService {
    // 虽然没用到也要依赖注入一下，不然报错
    constructor(
        private _modal: ModalService
    ) { }

    open(title?: string | TemplateRef<any>, content?: string | TemplateRef<any>, options?: {
        successBtnTitle?: string,
        cancelBtnTitle?: string,
    }): Promise<string> {
        const resultPromiser: Promise<string> = new Promise((resolve, rejected) => {
            this._modal.alert(title, content || '是否确定该操作?', [
                {
                    text: options.cancelBtnTitle || '取消',
                    onPress: () => rejected('cancel'),
                    style: {
                        'background-color': '#ebedf2',
                        'color': '#303030!important'
                    }
                },
                {
                    text: options.successBtnTitle || '确认',
                    onPress: () => resolve('confirmed'),
                    style: {
                        background: 'var(--btn-bg-color)',
                        color: 'var(--color-ff)'
                    }
                }
            ]);
        });
        return resultPromiser;
    }
    operation(content?: { text: string, style?: object }[], options?: {
        maskClosable?: boolean,
        platform?: 'android' | 'ios'
    }): any {
        const resultPromiser = new Promise((resolve, rejected) => {
            this._modal.operation(content.map(item => {
                return { text: item.text, onPress: () => rejected(item.text), style: item.style };
            }), options?.platform || 'ios');
        });
        return resultPromiser;
    }

}
