import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu, MenuItem } from '../model/menu.model';
import { API_SERVICE_PREFIX } from '../../app.constants';

type EntityResponseType = HttpResponse<Menu>;
type EntityArrayResponseType = HttpResponse<Menu[]>;
type EntityResponseMenuItemType = HttpResponse<MenuItem>;
type EntityArrayResponseMenuItemType = HttpResponse<MenuItem[]>;
const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({ providedIn: 'root' })
export class SiteMenusService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/menus';
    private resourceUrlForMenuItem = API_SERVICE_PREFIX + '/cms/api/menu-items';

    constructor(
        private http: HttpClient,) { }

    create(menu: Menu): Observable<EntityResponseType> {
        return this.http
            .post<Menu>(this.resourceUrl, menu, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(menu: Menu, successNotify = true, errorNotify = true): Observable<EntityResponseType> {
        return this.http
            .put<Menu>(`${this.resourceUrl}/${menu.id}`, menu,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: string, headers = {}): Observable<EntityResponseType> {
        return this.http
            .get<Menu>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headers })
            .pipe(map((res: EntityResponseType) => res));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        return this.http
            .get<Menu[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    // MenuItems

    createMenuItem(menuItem: MenuItem): Observable<EntityResponseMenuItemType> {
        return this.http
            .post<MenuItem>(this.resourceUrlForMenuItem, menuItem, { observe: 'response' })
            .pipe(map((res: EntityResponseMenuItemType) => res));
    }

    updateMenuItem(menuItem: MenuItem, successNotify = true, errorNotify = true): Observable<EntityResponseMenuItemType> {
        return this.http
            .put<MenuItem>(`${this.resourceUrlForMenuItem}/${menuItem.id}`, menuItem,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseMenuItemType) => res));
    }

    findMenuItem(id: string, headers = {}): Observable<EntityResponseMenuItemType> {
        return this.http
            .get<Menu>(`${this.resourceUrlForMenuItem}/${id}`, { observe: 'response', headers: headers })
            .pipe(map((res: EntityResponseMenuItemType) => res));
    }

    queryMenuItems(req?: any): Observable<EntityArrayResponseMenuItemType> {
        return this.http
            .get<Menu[]>(this.resourceUrlForMenuItem, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseMenuItemType) => res));
    }

    deleteMenuItem(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrlForMenuItem}/${id}`, { observe: 'response' });
    }
}
