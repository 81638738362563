<div class="post-item-container" [ngClass]="{
        'w-100':(type==='NEWS') || (type==='LIST'),
        'b-r':type!=='NEWS'}">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="PostStyleType.LIST">
            <div class="list-item b-r" title="{{post| mNameTranslate:'title'}}">
                <ng-container *ngIf="currentDeviceService.isDesktop()">
                    <div class="list-item-content">
                        <div class="list-item-left-content"
                            [ngClass]="{'flex-grow-1':(postRenderOptions?.enablePublishAuthor !== false)&&(postRenderOptions?.enablePublishDate !== false)}">
                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="list-item-header mb-0 text-overflow-ellipsis">
                                    {{post| mNameTranslate:'title'}}
                                </div>
                                <div class="list-item-footer mt-0 pt-0" style="margin-left: 1rem;"
                                    *ngIf="(postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishDate !== false)">
                                    <span class="text-overflow-ellipsis author-name"
                                        *ngIf="postRenderOptions?.enablePublishAuthor !== false">{{post?.authorName}}</span>
                                    <span class="date-text"
                                        *ngIf="postRenderOptions?.enablePublishDate !== false">{{post?.createdDate |
                                        date:
                                        ('SHARED.YEAR_MONTH_DAY_PIPE_FROMART_TEXT'|translate)}}</span>
                                </div>
                            </div>
                            <ng-container *ngIf="(post| mNameTranslate:'summary')">
                                <div class="ql-container ql-snow" style="border: none;">
                                    <div class="ql-editor list-item-desc" style="padding: 0;"
                                        [innerHTML]="(post| mNameTranslate:'summary') | uploadUrlPrefix:'true'">
                                    </div>
                                </div>
                            </ng-container>
                            <div class="list-item-desc" *ngIf="!(post| mNameTranslate:'summary')">
                                {{((post| mNameTranslate:'content') || '无') |
                                textExtraction:(currentDeviceService.isDesktop()?'256':'128')}}
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!currentDeviceService.isDesktop()">
                    <div class="d-flex align-items-center justify-content-between pb-2">
                        <div class="list-item-header mb-0 text-overflow-ellipsis">
                            {{post| mNameTranslate:'title'}}
                        </div>
                        <div class="list-item-footer mt-0 pt-0" style="margin-left: 1rem;"
                            *ngIf="(postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishDate !== false)">
                            <span class="text-overflow-ellipsis author-name"
                                *ngIf="postRenderOptions?.enablePublishAuthor !== false">{{post?.authorName}}</span>
                            <span class="date-text"
                                *ngIf="postRenderOptions?.enablePublishDate !== false">{{post?.createdDate | date:
                                ('SHARED.YEAR_MONTH_DAY_PIPE_FROMART_TEXT'|translate)}}</span>
                        </div>
                    </div>
                    <div class="list-item-content">
                        <ng-container *ngIf="(post| mNameTranslate:'summary')">
                            <div class="ql-container ql-snow" style="border: none;">
                                <div class="ql-editor list-item-desc" style="padding: 0;"
                                    [innerHTML]="(post| mNameTranslate:'summary') | uploadUrlPrefix:'true'">
                                </div>
                            </div>
                        </ng-container>
                        <div class="list-item-desc" *ngIf="!(post| mNameTranslate:'summary')">{{((post|
                            mNameTranslate:'content')
                            || '无') |
                            textExtraction:(currentDeviceService.isDesktop()?'256':'128')}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="PostStyleType.NEWS">
            <div class="list-item-for-news m-0 w-100 pt-2 pb-3" title="{{post| mNameTranslate:'title'}}">
                <div class="d-flex align-items-center justify-content-between position-relative" [ngClass]="{
                        'flex-wrap':(currentDeviceService?.isMobile() && ((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE))),
                    'flex-row-reverse':data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE,
                    'px-2':!currentDeviceService?.isMobile() && ((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE))
                    }">
                    <ng-container *ngIf="data?.newLayoutType === NewLayoutType.SIMPLE">
                        <div
                            style="font-size: 1rem;flex: 1;{{!((postRenderOptions?.enablePublishAuthor === false)||(postRenderOptions?.enablePublishDate === false))?'max-width: 568px;':''}}">
                            <div class="list-item-header mb-0 text-overflow-ellipsis">
                                {{post| mNameTranslate:'title'}}
                            </div>
                        </div>
                        <div class="list-item-footer mt-0 pt-0" style="margin-left: 1rem;"
                            *ngIf="(postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishDate !== false)">
                            <span class="text-overflow-ellipsis author-name"
                                *ngIf="postRenderOptions?.enablePublishAuthor !== false">{{post?.authorName}}</span>
                            <span class="px-2" style="color: var(--bs-border-color);"
                                *ngIf="postRenderOptions?.enablePublishAuthor !== false">|</span>
                            <span class="date-text"
                                *ngIf="postRenderOptions?.enablePublishDate !== false">{{post?.createdDate |
                                date:
                                ('SHARED.YEAR_MONTH_DAY_PIPE_FROMART_TEXT'|translate)}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data?.newLayoutType !== NewLayoutType.SIMPLE">
                        <div class="b-r overflow-hidden news-image position-relative" [ngClass]="{
                                'pb-2':(currentDeviceService?.isMobile() && ((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE))),
                            'aspect-ratio-image':!((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE))
                            }">
                            <img [decode]="true" offset="100" appLoadingAnimationAndErrorBackground
                                lazyLoad="{{post.featuredImage | uploadUrlPrefix | imgResize: 'h_420'}}"
                                class="article-img" appPureImgErrorIdentification [setErrorImg]="false" />
                        </div>
                        <div style="{{(!data?.newLayoutType||(data?.newLayoutType === NewLayoutType.LEFT_IMAGE_RIGHT_INFO)||((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)) && currentDeviceService?.isDesktop()) ? 'flex:1;' : ''}}"
                            class="d-flex align-items-center jusitify-content-bettween overflow-hidden">
                            <ng-container
                                *ngIf="data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE">
                                <ng-container *ngTemplateOutlet="verticalDateRef"></ng-container>
                            </ng-container>
                            <div class="d-inline-flex flex-column w-100 overflow-hidden" [ngClass]="{
                                'justify-content-between':postRenderOptions?.enableSumary === false,
                                'ml-3':!(currentDeviceService?.isMobile() && ((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)))
                            }" style="height: min(21vw,88px);">
                                <div [ngClass]="{'d-flex':postRenderOptions?.enableSumary !== false}">
                                    <div class="list-item-header mb-0 text-overflow-ellipsis item-tit"
                                        [ngClass]="{'px-0':(currentDeviceService?.isMobile() && ((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)))}"
                                        style="font-size: 1rem;flex: 1;{{!((postRenderOptions?.enablePublishAuthor === false)||(postRenderOptions?.enablePublishDate === false))?'max-width: 568px;':''}}">
                                        {{post| mNameTranslate:'title'}}
                                    </div>
                                    <ng-container *ngIf="(postRenderOptions?.enableSumary !== false)">
                                        <ng-container
                                            *ngTemplateOutlet="authorAndDateRef;context:{alignCenter:true}"></ng-container>
                                    </ng-container>
                                </div>
                                <ng-container
                                    *ngIf="(postRenderOptions?.enableSumary === false) && (!data?.newLayoutType ||(data?.newLayoutType === NewLayoutType.LEFT_IMAGE_RIGHT_INFO))">
                                    <ng-container *ngTemplateOutlet="authorAndDateRef;"></ng-container>
                                </ng-container>
                                <ng-container *ngIf="postRenderOptions?.enableSumary !== false">
                                    <ng-container
                                        *ngTemplateOutlet="sumaryRef;context:{noPaddingX:(currentDeviceService?.isMobile() && ((data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE)||(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)))}"></ng-container>
                                </ng-container>

                                <ng-template #sumaryRef let-noPaddingX="noPaddingX">
                                    <ng-container *ngIf="(post| mNameTranslate:'summary')">
                                        <div class="ql-container ql-snow" style="border: none;">
                                            <div class="ql-editor list-item-desc pr-0" [ngClass]="{'px-0':noPaddingX}"
                                                [innerHTML]="(post| mNameTranslate:'summary') | uploadUrlPrefix:'true'">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="list-item-desc pr-0" [ngClass]="{'px-0':noPaddingX}"
                                        *ngIf="!(post| mNameTranslate:'summary')">
                                        {{((post| mNameTranslate:'content') || '无') |
                                        textExtraction:(currentDeviceService.isDesktop()?'256':'128')}}
                                    </div>
                                </ng-template>
                                <ng-template #authorAndDateRef let-alignCenter="alignCenter">
                                    <div *ngIf="(postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishDate !== false) || hiddenAuthorOrDateOfSpace"
                                        class="justify-content-between author-and-date-info-wrapper" [ngClass]="{
                                        'w-100':!alignCenter,
                                        'pb-2':currentDeviceService?.isDesktop()&&!alignCenter,
                                        'pb-0':!currentDeviceService?.isDesktop()&&!alignCenter,
                                    'align-items-center d-flex p-0':alignCenter
                                    }" [style.opacity]="(!((postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishDate !== false)) && 
                                    hiddenAuthorOrDateOfSpace) ?'0':'1'"
                                        [style.paddingTop]="!(postRenderOptions?.enableTitleForDetail !== false) ? '10px' : ''">
                                        <div *ngIf="postRenderOptions?.enablePublishAuthor !== false"
                                            class="item-master pc-version">
                                            <img src="{{post.customer?.imageUrl | uploadUrlPrefix | imgResize: 'h_420'}}"
                                                alt="" class="item-logo" *ngIf="post.customer?.imageUrl">
                                            <img src="/assets/icon/c_logo.png" class="item-logo pc-version"
                                                *ngIf="!post.customer?.imageUrl" appPureImgErrorIdentification
                                                [setErrorImg]="true">
                                            <span class="author-name">{{post?.authorName || '-'}}</span>
                                        </div>
                                        <div class="date-text"
                                            [style.opacity]="(hiddenAuthorOrDateOfSpace && !((postRenderOptions?.enablePublishDate !== false) && (data?.newLayoutType !== NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE) && (data?.newLayoutType !== NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)))?'0':'1'"
                                            *ngIf="(postRenderOptions?.enablePublishDate !== false) && (data?.newLayoutType !== NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE) && (data?.newLayoutType !== NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE) || hiddenAuthorOrDateOfSpace">
                                            {{(post?.publishDate || post?.createdDate) |
                                            date: ('SHARED.YEAR_MONTH_DAY_PIPE_FROMART_TEXT'|translate)}}</div>
                                    </div>
                                </ng-template>
                            </div>
                            <ng-container
                                *ngIf="data?.newLayoutType === NewLayoutType.LEFT_IMAGE_CENTER_INFO_RIGHT_DATE">
                                <ng-container *ngTemplateOutlet="verticalDateRef"></ng-container>
                            </ng-container>
                            <ng-template #verticalDateRef>
                                <div class="d-flex align-items-center news-date-container position-relative"
                                    style="{{(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)?'padding-right: 3rem;':'padding-left: 3rem;'}}"
                                    *ngIf="postRenderOptions?.enablePublishDate !== false">
                                    <div class="position-absolute px-4 py-2 h-100"
                                        style="{{(data?.newLayoutType === NewLayoutType.LEFT_DATE_CENTER_INFO_RIGHT_IMAGE)?'right: -2rem;transform: translateX(-50%);':'transform: translateX(-100%);'}}box-sizing: border-box;">
                                        <div class="split-line h-100"
                                            style="width:2px;background-color: rgb(235,235,235);">
                                        </div>
                                    </div>
                                    <span class="news-date-content"><b
                                            class="news-date-day-text">{{getPublishDate((post?.publishDate ||
                                            post?.createdDate),'DD')}}</b>{{getPublishDate((post?.publishDate ||
                                        post?.createdDate),'YYYY-MM')}}</span>
                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="item-card" title="{{post| mNameTranslate:'title'}}">
                <div class="position-relative article-img-box overflow-hidden">
                    <img [decode]="true" offset="100" appLoadingAnimationAndErrorBackground
                        lazyLoad="{{post.featuredImage | uploadUrlPrefix | imgResize: 'h_420'}}" class="article-img"
                        appPureImgErrorIdentification [setErrorImg]="false" />
                    <i class="position-absolute iconfont icon-tupian" [ngClass]="{'article-img': true}"
                        *ngIf="!post.featuredImage"></i>
                </div>
                <h4 class="item-tit" *ngIf="postRenderOptions?.enableTitleForDetail !== false">
                    {{post| mNameTranslate:'title'}}</h4>
                <div *ngIf="hiddenAuthorOrDateOfSpace || (postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishAuthor !== false)"
                    class="justify-content-between w-100 author-and-date-info-wrapper" [style.opacity]="(hiddenAuthorOrDateOfSpace && !((postRenderOptions?.enablePublishAuthor !== false)||(postRenderOptions?.enablePublishAuthor !== false)))
                    ?'0':'1'" [style.paddingTop]="!(postRenderOptions?.enableTitleForDetail !== false) ? '10px' : ''">
                    <div *ngIf="postRenderOptions?.enablePublishAuthor !== false" class="item-master">
                        <img src="{{post.customer?.imageUrl | uploadUrlPrefix | imgResize: 'h_420'}}" alt=""
                            class="item-logo" *ngIf="post.customer?.imageUrl">
                        <img src="/assets/icon/c_logo.png" class="item-logo" *ngIf="!post.customer?.imageUrl"
                            appPureImgErrorIdentification [setErrorImg]="true">
                        <span class="author-name">{{post?.authorName || '-'}}</span>
                    </div>
                    <div class="date-text"
                        *ngIf="(postRenderOptions?.enablePublishAuthor !== false) || hiddenAuthorOrDateOfSpace"
                        [style.opacity]="(hiddenAuthorOrDateOfSpace && !((postRenderOptions?.enablePublishAuthor !== false) || hiddenAuthorOrDateOfSpace))?'0':'1'">
                        {{(post?.publishDate || post?.createdDate) |
                        date: ('SHARED.YEAR_MONTH_DAY_PIPE_FROMART_TEXT'|translate)}}</div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>