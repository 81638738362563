import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteContextService } from '../../shared/services/site-context.service';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { Principal } from './principal.service';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider {
    [x: string]: any;
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        private principal: Principal,

    ) { }

    getToken() {
        return this.siteContextService.token();
    }

    login(credentials): Observable<any> {
        const data = {
            username: credentials.username,
            password: credentials.password,
            rememberMe: credentials.rememberMe
        };
        return this.http.post(
            API_SERVICE_PREFIX + `/user/api/user/login`, credentials.loginInfo, { observe: 'response' })
            // API_SERVICE_PREFIX + '/user/api/authenticate', credentials, { observe: 'response' })  //备用接口
            .pipe(map(response => this.authenticateSuccess(response, credentials.rememberMe)));
    }

    verifyToken(jwt) {
        this.storeAuthenticationToken(jwt, true);
        // if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        //         const jwt = bearerToken.slice(7, bearerToken.length);
        //         console.log('保存token',jwt)

        //         return jwt;
        //     }
    }

    authenticateSuccess(resp, rememberMe) {
        return this.principal.authenticateSuccess(resp, rememberMe);
    }

    userInfo() {
        return this.principal.userInfo();
    }

    loginWithToken(jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }
    }

    logout(): Observable<any> {
        return this.principal.logout();
    }

    private storeAuthenticationToken(jwt, rememberMe) {
        this.siteContextService.setToken(jwt, rememberMe);
        this.userInfo();
    }
}
