import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { CountryCodeEnum } from '../model/config.model';

export class OwnerInfo {
    "id"?: string;
    "name"?: string;
    "fullName"?: string;
    "imageUrl"?: string;
    "new"?: boolean;
    defaultCountry?: CountryCodeEnum;
    "defaultLanguage"?: string;
}

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({
    providedIn: 'root'
})
export class OwnerInfoService {
    private resourceUrl = API_SERVICE_PREFIX + '/base/api/owner-info';
    constructor(
        private http: HttpClient,
    ) { }

    get(): Observable<OwnerInfo> {
        return this.http.get<OwnerInfo>(this.resourceUrl, { headers: headerOptions });
    }
    post(req: OwnerInfo): Observable<OwnerInfo> {
        return this.http.post<OwnerInfo>(this.resourceUrl, req);
    }
    update(req: OwnerInfo): Observable<OwnerInfo> {
        return this.http.put<OwnerInfo>(this.resourceUrl, req);
    }
}
