import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../i18n';

export function getCurrentControlTitle(control: any, currentLangKey: string, defaultLanguageKey: string = null): string {
    let langKey = currentLangKey?.toLowerCase();
    let titleTranslation = objectKeyToLowerCase(control?.titleTranslation);
    if (Array.isArray(titleTranslation)) {
        const findNameTranslation = titleTranslation?.find(nameTranslationItem => nameTranslationItem?.[langKey]);
        return findNameTranslation?.[langKey] || findNameTranslation?.[defaultLanguageKey] || control?.name;
    }
    if (titleTranslation && (typeof titleTranslation === 'object') && Object.keys(titleTranslation)?.includes(langKey)) {
        return titleTranslation[langKey] || titleTranslation[defaultLanguageKey] || control?.title;
    } else {
        if (titleTranslation && (typeof titleTranslation === 'object')) {
            if (['zh-hk', 'zh-tw']?.includes(langKey)) {
                langKey = (langKey === 'zh-tw') ? 'zh-hk' : 'zh-tw';
                return titleTranslation[langKey] || titleTranslation[defaultLanguageKey] || control?.title;
            }
        }
    }
    return jsonParse(control?.titleTranslation)?.[currentLangKey] || jsonParse(control?.titleTranslation)?.[defaultLanguageKey] || control?.title;
}

export function objectKeyToLowerCase(value: string | Object) {
    if (value) {
        let strTranslation = {};
        let tempValue = value;
        if (typeof value === 'string') {
            tempValue = jsonParse(value);
        }
        Object.keys(tempValue)?.map(key => {
            strTranslation[key.toLowerCase()] = tempValue[key];
        });
        return strTranslation;
    }
    return value;
}

export function jsonParse(str: string) {
    try {
        if (str && (typeof str === 'string')) {
            return JSON.parse(str);
        }
        return str;
    } catch (error) {
        return str;
    }
}

@Pipe({
    name: 'mTitleTranslate',
    pure: false,
})
export class TitleTranslatePipe implements PipeTransform {

    constructor(
        private translationService: TranslationService,
    ) { }

    transform(value: any): any {
        return getCurrentControlTitle(value, this.translationService.getSelectedLanguage());
    }
}
