import {
    Component,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslationService } from '../../i18n';
import { ParseTargetEntityConfigService } from '../core/services/parse-target-entity-config.service';
import { Menu } from '../shared/model/menu.model';
import { Page } from '../shared/model/page.model';
import { SiteContextService } from '../shared/services/site-context.service';
import { SiteMenusService } from '../shared/services/site-menus.service';
import { findPageMenuById } from '../core/utilities/page-route-util';


@Component({
    selector: 'app-custom-page-renderer',
    templateUrl: './custom-page-renderer.component.html',
    styleUrls: ['./custom-page-renderer.component.scss'],
})
export class CustomPageRendererComponent implements OnInit {
    isParsedPage = false;
    siteMenus: Menu;
    pageId: string;
    initializationPage: Page;
    constructor(
        public translationService: TranslationService,
        public siteContextService: SiteContextService,
        public cdr: ChangeDetectorRef,
        public route: ActivatedRoute,
        public router: Router,
        public siteMenusService: SiteMenusService,
        public $localStorage: LocalStorageService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
    ) { }
    ngOnInit() {
        this.pageId = undefined;
        this.isParsedPage = false;
        this.initializationPage = null;
        this.route.data.subscribe(data => {
            this.initializationPage = data['currentPage'] || {};
            this.pageId = data['currentPage']?.id;
            if (!this.initializationPage?.id) {
                this.initPageIdBySiteMenus();
            }
        });
    }

    initPageIdBySiteMenus() {
        const menusRes = this.siteContextService.defaultSiteMenu;
        if (menusRes) {
            this.siteMenus = menusRes;
            this.route.paramMap.subscribe(params => {
                if (params.get('path')) {
                    const activeMenuItem = this.findActiveMenu(params.get('path'));
                    if (activeMenuItem) {
                        this.pageId = activeMenuItem.linkedPageId;
                    } else {
                        const pageId = params.get('path');
                        if (pageId) {
                            this.parseTargetEntityConfigService.navigateByPageId(pageId);
                        }
                    }
                    this.cdr.detectChanges();
                    this.cdr.markForCheck();
                    return;
                }
            });
        }
    }

    findActiveMenu(path: string) {
        return findPageMenuById(this.siteMenus.items, path);
    }
}