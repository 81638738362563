import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../core/services/parse-target-entity-config.service';
import { UrlHelperService } from '../../core/services/url-helper.service';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { tranformGradientColorStyle } from '../../core/model/color-config.model';

@Component({
    selector: 'app-banner-block',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerBlockComponent {
    @Input() key: string = 'default_banner_block';
    @Input() data: any = {};
    @Input() set renderOptions(value: any) {
        if (value) {
            Object.keys(value?.container)?.forEach(key => {
                if (key?.includes('padding')) {
                    value.container[key?.replace('padding', 'margin')] = value.container[key];
                    value.container[key] = '0';
                }
            });
            const tempValue = value?.container?.background || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._renderOptions = {
                ...value,
                container: {
                    ...value?.container,
                    'background-color': backgroundColor
                }
            };
        }
    }
    _renderOptions: any = {};
    @Output() closeBannerEvent = new EventEmitter();
    isShow = true;
    constructor(
        public currentDeviceService: CurrentDeviceService,
        public urlHelperService: UrlHelperService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
    ) { }
    closeBanner() {
        this.isShow = !this.isShow;
        if (!this.isShow) {
            this.closeBannerEvent.emit(false);
        }
    }
    navigateToSomePage(navigation: any) {
        this.parseTargetEntityConfigService.navigateLinkedPage(navigation);
    }

    getElStyle(propertyPath: string, isBackgroundColor = false) {
        let options = this._renderOptions?.[propertyPath];
        let colorData = ((options?.color !== "CUSTOMIZE") && options?.color) ? options?.color : options?.customizeColorValue;
        try {
            colorData = JSON.parse(colorData);
        } catch (error) {
            colorData = colorData?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(colorData);
        return `letter-spacing: ${options?.['letter-spacing'] || 0}px;line-height: ${options?.['line-height'] || 1.5};
        ${gradientColor ? `background-image: ${colorData}!important;${isBackgroundColor ? '' : `color: ${jsonParse(colorData)?.data?.[0]?.color};background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;`}`
                : `color:${colorData}!important;`}`;
    }

    getElFontSizeScale(propertyPath: string) {
        let options = this._renderOptions?.[propertyPath];
        return options?.['font-size'];
    }

    getElementBackground(value: string) {
        let colorData = value;
        try {
            colorData = JSON.parse(value);
        } catch (error) {
            colorData = value?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(value);
        return `${gradientColor}!important`;
    }

    getContainerStyle() {
        const result = {
            ...this._renderOptions?.container,
            'background-image': !this.data?.enabledBackgroundImage && this._renderOptions?.container && this.getElementBackground(this._renderOptions?.container['background-color']),
            'background-color': !this.data?.enabledBackgroundImage && this._renderOptions?.container && this._renderOptions?.container['background-color']
        };
        if (result['background-image']) {
            delete result?.background;
            delete result['background-color'];
        }
        return Object.keys(result)?.map(key => `${key}: ${result[key]}`)?.join(';');
    }
}
