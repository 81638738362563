import { IMobileAppConfig, MobileAppConfig } from "./mobile-config.model";

export enum CountryCodeEnum {
    CN = 'CN',
    JP = 'JP'
}
export enum CurrencyCodeEnum {
    USD = 'USD',
    CNY = 'CNY',
    HKD = 'HKD',
    SGD = 'SGD',
    JPY = 'JPY'
}

export interface IShopSetting {
    id?: number;
    shopId?: number;
    mobileAppConfig?: IMobileAppConfig;
    defaultCountryOfSale?: CountryCodeEnum;
    defaultCurrencyCode?: CurrencyCodeEnum;
    supportedCountriesOfSale?: string;
    hideSoldOutProduct?: boolean;
    termsConditionsContent?: string;
    termsConditionsTitle?: string;
}

export class ShopSetting implements IShopSetting {
    id?: number;
    shopId?: number;
    mobileAppConfig?: IMobileAppConfig;
    defaultCountryOfSale?: CountryCodeEnum = CountryCodeEnum.CN;
    defaultCurrencyCode?: CurrencyCodeEnum = CurrencyCodeEnum.CNY;
    supportedCountriesOfSale?: string = JSON.stringify([CountryCodeEnum.CN]);
    hideSoldOutProduct?: boolean = false;
    termsConditionsContent?: string = '';
    termsConditionsTitle?: string = '';
    constructor(shopSetting?: IShopSetting) {
        if (!shopSetting) {
            shopSetting = {
                mobileAppConfig: new MobileAppConfig()
            };
        }
        if (shopSetting) {
            for (const key in shopSetting) {
                if ((key && shopSetting[key]) || typeof (shopSetting[key]) === 'boolean') {
                    this[key] = shopSetting[key];
                }
            }
        }
    }
}
