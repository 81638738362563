// Localization is based on '@ngx-translate/core';
// Please be familiar with official documentations first => https://github.com/ngx-translate/core

import { Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { defaultSite, Site } from '../app/shared/model/site.model';
import { SiteContextService } from '../app/shared/services/site-context.service';
import { isPlatformBrowser } from '@angular/common';

export interface Locale {
    lang: string;
    data: any;
}

export const LOCALIZATION_LOCAL_STORAGE_KEY = 'bricsSiteLangKey';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    private langIds: any = [];
    private siteLanguages = {
        defaultLanguage: 'zh-CN',
        supportedLanguages: []
    };

    constructor(
        private translate: TranslateService,
        private siteContextService: SiteContextService,
    ) {
        this.initSiteLanguages(this.siteContextService.site);
    }

    instant(key: string | string[], interpolateParams?: Object, defaultStr?: string) {
        if (key) {
            const result = this.translate.instant(key, interpolateParams);
            return (result === key) ? defaultStr : result;
        }
        return defaultStr;
    }

    private parseJsonStrArr(value: string) {
        if (value) {
            try {
                let result = JSON.parse(value);
                return Array.isArray(result) ? result : [];
            } catch (error) {
                return [];
            }
        }
        return [];
    }

    private initSiteLanguages(site: Site) {
        let defaultLang = defaultSite?.defaultLanguage;
        let supportedLanguages = this.parseJsonStrArr(defaultSite?.supportedLanguages) || [];
        // 获取cms site里的语言配置
        if (site?.id) {
            // 如果只存在一个site的话，取其默认语言和支持语言列表
            let defaultLang = site?.defaultLanguage ? site?.defaultLanguage : defaultSite?.defaultLanguage;
            const supportedLanguagesRes = site?.supportedLanguages;
            supportedLanguages = supportedLanguagesRes?.length ? (this.parseJsonStrArr(supportedLanguagesRes)) : [defaultLang] || [];
        }
        this.setLang(defaultLang, supportedLanguages);
    }

    getSiteLanguages() {
        return this.siteLanguages;
    }

    loadTranslations(...args: Locale[]): void {
        // 过滤未启用的语言
        const enableLanguages = args?.filter(arg => {
            let findLang = this.siteLanguages.supportedLanguages?.find(langKey => {
                return langKey?.toLowerCase() === arg.lang?.toLowerCase();
            });
            return !!findLang;
        });
        const locales = enableLanguages;
        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this.translate.setTranslation(locale.lang, locale.data, true);
            this.langIds.push(locale.lang);
        });
        this.translate.addLangs(this.langIds);
        this.translate.use(this.getSelectedLanguage());
    }

    private setLang(defaultLang: string, supportedLanguages: string[]) {
        this.siteLanguages.defaultLanguage = defaultLang;
        this.siteLanguages.supportedLanguages = supportedLanguages;
        this.translate.setDefaultLang(defaultLang);
        try {
            // 检查本地缓存是否有语言值，若有， 则比对是否属于站点支持的语言， 是则选择
            const localizationLanguageKey = localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY);
            if (localizationLanguageKey) {
                if (supportedLanguages?.includes(localizationLanguageKey)) {
                    this.setLanguage(localizationLanguageKey);
                    return;
                }
            }
        } catch (error) {

        }
        // 根据环境信息（浏览器相关属性）采用客户当前语言， 比对是否属于站点支持的语言， 是则选择
        if (isPlatformBrowser(PLATFORM_ID)) {
            const browserLanguage = this.getBrowserLanguage();
            if (supportedLanguages?.includes(browserLanguage)) {
                this.setLanguage(browserLanguage);
                return;
            }
        }
        // 采用站点默认语言
        this.setLanguage(defaultLang);
        return;
    }

    private getBrowserLanguage() {
        switch (navigator.language?.toLowerCase()) {
            case 'en':
                return 'en-US';
            case 'zh-Hant':
                return 'zh-TW';
            case 'zh-Hans':
                return 'zh-TW';
            case 'zh-HK':
                return 'zh-TW';
            default:
                break;
        }
        // safira浏览器部分版本返回的是全小写，需要转换一下
        return navigator.language?.split('-')?.map((str, index) => {
            if (index === 1) {
                return str?.toUpperCase();
            } else {
                return str;
            }
        })?.join('-');
    }

    setLanguage(langKey: string) {
        if (langKey) {
            this.translate.use(langKey);
            // if (this.translate.getLangs()?.includes(langKey)) {
            // } else {
            //     import(`./vocabs/${langKey}`).then((module) => {
            //         // do something with the translations
            //         this.loadTranslations(module?.locale);
            //     });
            // }
            try {
                localStorage.setItem(LOCALIZATION_LOCAL_STORAGE_KEY, langKey);
            } catch (error) {

            }
        }
    }

    /**
     * Returns selected language
     */
    getSelectedLanguage(): any {
        try {
            return localStorage.getItem(LOCALIZATION_LOCAL_STORAGE_KEY) || this.translate.currentLang || this.translate.defaultLang;
        } catch (error) {
            return this.translate.currentLang || this.translate.defaultLang;
        }
    }
}
