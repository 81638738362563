export const systemSettingTranslate = {
    WECHAT_OPEN_CONFIG: {
        APP_ID: {
            PLACEHOLDER: 'AppIdを入力してください...',
            REQUIRED_ERROR_MESSAGE: 'AppIdは空にできません！',
        },
        APP_SECRET: {
            PLACEHOLDER: 'AppSecretを入力してください...',
            REQUIRED_ERROR_MESSAGE: 'AppSecretは空にできません！',
        },
        SUCCESS_MESSAGE: '保存に成功しました！',
        ERROR_MESSAGE: '保存に失敗しました！',
    },
    WECHAT_MP_CONFIG: {
        APP_ID: {
            PLACEHOLDER: 'AppIdを入力してください...',
            REQUIRED_ERROR_MESSAGE: 'AppIdは空にできません！',
        },
        APP_SECRET: {
            PLACEHOLDER: 'AppSecretを入力してください...',
            REQUIRED_ERROR_MESSAGE: 'AppSecretは空にできません！',
        },
        SUCCESS_MESSAGE: '保存に成功しました！',
        ERROR_MESSAGE: '保存に失敗しました！',
    }
}