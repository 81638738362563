import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { CurrentDeviceService } from './current-device.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { ShopContextService } from '../../ecommerce/shared/service/shop-context.service';

const headers = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({ providedIn: 'root' })
export class HomeAnnouncementsService {
    constructor(
        private http: HttpClient,
        public currentDeviceService: CurrentDeviceService,
        private siteContextService: SiteContextService,
        private shopContextService: ShopContextService,
    ) { }

    query(shopId: string, query?: any): Observable<HttpResponse<[]>> {
        return this.http.get<any>(API_SERVICE_PREFIX + `/store/api/shop/${shopId}/home-announcements`, {
            params: query,
            observe: 'response',
            headers
        });
    }
}
