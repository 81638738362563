<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="renderOptions" [enabledMoreBtn]="false" [section]="section">
    <div class="portfolio-container">
        <div class="d-flex flex-wrap portfolio-content"
            [style.marginLeft]="(renderOptions?.fullWidth ? '' : '-') + renderOptions?.items?.container?.['border-width'] + 'px'"
            [style.marginRight]="(renderOptions?.fullWidth ? '' : '-') + renderOptions?.items?.container?.['border-width'] + 'px'"
            [style.marginTop]="renderOptions?.items?.container?.['border-width'] + 'px'"
            [style.marginBottom]="renderOptions?.items?.container?.['border-width'] + 'px'">
            <div class="position-relative portfolio-item" [ngClass]="{'three-equals-part':isTreeEqualsPart()}"
                [style.width]="calcItemWidth(portfolio)"
                *ngFor="let portfolio of getCurrentDeviceData(!currentDeviceService?.isDesktop());"
                (click)="navigateLink(portfolio)">
                <div class="portfolio-background"
                    [ngClass]="{'b-r overflow-hidden':renderOptions?.borderType!=='SQUARE'}" [ngStyle]="{left:renderOptions?.items?.container?.['border-width']+'px',
                right:renderOptions?.items?.container?.['border-width']+'px',top:renderOptions?.items?.container?.['border-width']+'px',
                bottom:renderOptions?.items?.container?.['border-width']+'px'}">
                    <img class="portfolio-background-img" [decode]="true" offset="100"
                        appLoadingAnimationAndErrorBackground
                        [lazyLoad]="(portfolio?.imgUrl | uploadUrlPrefix) | imgResize: getImageResizeParams(portfolio)"
                        alt="">
                </div>
                <span class="portfolio-item-title" [style]="getElStyle(portfolio)"
                    [appFontSizeScale]="getElFontSizeScale(portfolio)">{{
                    {title:portfolio?.title || portfolio?.caption,titleTranslation: portfolio?.titleTranslation } |
                    mNameTranslate:'title'
                    }}</span>
            </div>
        </div>
    </div>
</app-block-container>