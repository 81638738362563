export const sitesTranslate = {
    ERROR: {
        CREATE_SITE_ERROR: 'Create Site Error：',
        COMPANY_INFO_ERROR: 'Company Info Error:',
        GET_SITE_ERROR: 'Get Sites Error:',
        UPDATE_SITE_ERROR: 'Update Site Error:',
        NOT_FOUND_SITE: 'Not Found Site',
        GET_APP_ERROR: 'Get Apps Error:',
        LOAD_PAGE_EMPTY: 'This page is still under renovation, please try again later',
        LOAD_SITE_ERROR: 'The site is not available at the moment, please contact the system administrator or try again later',
    },
    GENERAL: {
        CURRENT_APPLICATION: 'Current Application',
        NOTYETOPEN_CANNOTBEUSED: 'Not yet open, Can not be used!'
    }
}