<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation" [enabledTitle]="false"
    [renderOptions]="_renderOptions" [enabledMoreBtn]="false" [section]="section" [attachStyleToInnerContent]="true">
    <div class="graphic-portfolio-container">
        <div class="d-flex flex-wrap graphic-portfolio-content" [ngClass]="{
                'padding-x-for-mobile':_renderOptions?.fullWidth,
            'p-0':currentDeviceService?.isMobile()
        }">
            <div class="w-100" [ngClass]="{
                'd-flex justify-content-between':data?.layoutType !== 'MEDIA_BOTTOM',
                'flex-wrap':contentWrapperableForMobile()
            }">
                <ng-container *ngIf="data?.layoutType !== 'MEDIA_BOTTOM'">
                    <div class="left-content w-50 d-flex align-items-center" [ngClass]="{
                        'flex-wrap':data?.layoutType !== 'MEDIA_RIGHT',
                        'p-5':!currentDeviceService?.isMobile() && (data?.layoutType === 'MEDIA_RIGHT') && (data?.mediaSize !== 'SMALL'),
                        'p-3':currentDeviceService?.isMobile() && (data?.layoutType === 'MEDIA_RIGHT'),
                        'w-100':contentWrapperableForMobile()
                    }"
                        [style.paddingLeft]="_renderOptions?.fullWidth && (
                        (data?.layoutType !== 'MEDIA_RIGHT') ? (!currentDeviceService?.isMobile() ? '3rem' : '1rem') : '')">
                        <ng-container *ngIf="data?.layoutType === 'MEDIA_RIGHT'">
                            <ng-container *ngTemplateOutlet="mainContentRef;"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="data?.layoutType !== 'MEDIA_RIGHT'">
                            <ng-container
                                *ngTemplateOutlet="mediaContentRef;context:{layoutType:'MEDIA_LEFT'}"></ng-container>
                        </ng-container>
                    </div>
                    <div class="align-items-center d-flex right-content w-50" [ngClass]="{
                        'flex-wrap':data?.layoutType === 'MEDIA_RIGHT',
                        'p-5':!currentDeviceService?.isMobile() && (data?.layoutType !== 'MEDIA_RIGHT') && (data?.mediaSize !== 'SMALL'),
                        'p-3':currentDeviceService?.isMobile() && (data?.layoutType !== 'MEDIA_RIGHT'),
                        'w-100':contentWrapperableForMobile()
                    }"
                        [style.paddingRight]="_renderOptions?.fullWidth && (
                        (data?.layoutType === 'MEDIA_RIGHT') ? (!currentDeviceService?.isMobile() ? '3rem' : '1rem') : '')">
                        <ng-container *ngIf="data?.layoutType === 'MEDIA_RIGHT'">
                            <ng-container
                                *ngTemplateOutlet="mediaContentRef;context:{layoutType:'MEDIA_RIGHT'}"></ng-container>
                        </ng-container>
                        <ng-container *ngIf="data?.layoutType !== 'MEDIA_RIGHT'">
                            <ng-container *ngTemplateOutlet="mainContentRef;"></ng-container>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="data?.layoutType === 'MEDIA_BOTTOM'">
                    <div class="top-content pb-3">
                        <ng-container *ngTemplateOutlet="mainContentRef;"></ng-container>
                    </div>
                    <div class="bottom-content">
                        <ng-container *ngTemplateOutlet="mediaContentRef;"></ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</app-block-container>

<ng-template #mediaContentRef let-layoutType="layoutType">
    <div class="w-100 d-flex justify-content-center" [ngClass]="!currentDeviceService?.isMobile() && (data?.layoutType === layoutType) && {
        'justify-content-center':!data?.mediaHorizontal || (data?.mediaHorizontal === 'center'),
        'justify-content-end':(data?.mediaHorizontal === 'right'),
        'justify-content-start':(data?.mediaHorizontal === 'left'),
        'align-items-center':!data?.mediaVertical || (data?.mediaVertical === 'middle'),
        'align-items-start':(data?.mediaVertical === 'top'),
        'align-items-end':(data?.mediaVertical === 'bottom')
    }">
        <ng-container [ngSwitch]="data?.mediaType">
            <ng-container *ngSwitchCase="'INSERT_OTHER_PLATFORM_CODE'">
                <div *ngIf="data?.data"
                    class="b-r overflow-hidden graphic-image graphic-video d-flex justify-content-center" [ngClass]="{
                    'small-size':data?.mediaSize === 'SMALL',
                    'max-width-auto':_renderOptions?.fullWidth,
                    'w-100':currentDeviceService?.isMobile() ? (data?.mediaSize !== 'SMALL') : (data?.layoutType !== 'MEDIA_BOTTOM')
                }" [innerHTML]="getSanitizerHtml(data?.data)"></div>
                <img *ngIf="!data?.data" src="/assets/icon/img-blank.svg" class="graphic-image" [ngClass]="{
                    'b-r overflow-hidden':data?.mediaSize !== 'SMALL',
                    'small-size':data?.mediaSize === 'SMALL',
                    'max-width-auto':_renderOptions?.fullWidth
                }" alt="">
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngIf="urlHelperService?.isImage(data?.data)">
                    <img [decode]="true" offset="100" appLoadingAnimationAndErrorBackground
                        appPureImgErrorIdentification [setErrorImg]="true"
                        loadFailedImage='/assets/icon/img-blank.svg'
                        [lazyLoad]="(data?.data | uploadUrlPrefix) | imgResize: 'h_960'"
                        [alt]="data|mNameTranslate:'title'" class="graphic-image" [ngClass]="{
                            'b-r overflow-hidden':data?.mediaSize !== 'SMALL',
                            'small-size':data?.mediaSize === 'SMALL',
                            'max-width-auto':_renderOptions?.fullWidth
                        }">
                </ng-container>
                <ng-container *ngIf="!urlHelperService?.isImage(data?.data)">
                    <video [src]="data?.data" [alt]="data|mNameTranslate:'title'" class="graphic-image" [ngClass]="{
                            'b-r overflow-hidden':data?.mediaSize !== 'SMALL',
                            'small-size':data?.mediaSize === 'SMALL',
                            'max-width-auto':_renderOptions?.fullWidth
                        }"></video>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #mainContentRef>
    <div class="w-100">
        <div class="main-content-title title"
            [ngClass]="{'text-center':currentDeviceService?.isMobile() || data?.layoutType === 'MEDIA_BOTTOM'}"
            [style]="getElStyle('item-title')" [appFontSizeScale]="getElFontSizeScale('item-title')">
            {{data|mNameTranslate:'title'}}</div>
        <div class="main-content-desc sub-title" [ngClass]="{
            'py-4':!currentDeviceService?.isMobile(),
            'py-2':currentDeviceService?.isMobile(),
            'on-top-content':data?.layoutType === 'MEDIA_BOTTOM',
            'text-center':currentDeviceService?.isMobile() || data?.layoutType === 'MEDIA_BOTTOM'
        }" [style]="getElStyle('item-desc')" [appFontSizeScale]="getElFontSizeScale('item-desc')">
            {{data|mNameTranslate:'desc'}}</div>
        <div class="d-flex align-items-center" [ngClass]="{
                'justify-content-center':currentDeviceService?.isMobile() || data?.layoutType === 'MEDIA_BOTTOM',
            'pt-3':data?.layoutType !== 'MEDIA_BOTTOM',
            'pb-3':data?.layoutType === 'MEDIA_BOTTOM'
        }" *ngIf="data?.enabledActionButton">
            <button class="btn bg-warning btn-reflush ellipsis" [title]="data |
            mNameTranslate:'button'" style="cursor: pointer;" (click)="navigateToSomePage(data)" type="submit">{{data |
                mNameTranslate:'button'}}</button>
        </div>
    </div>
</ng-template>