import { Pipe, PipeTransform } from '@angular/core';

export class IconClassKey {
    keyName?: string;
    title?: string;
    titleTranslation?: object;
    className?: string;
    activeClassName?: string;
}

export const iconClassKeys = [{
    title: '推荐',
    titleTranslation: {
        'zh-TW': '推薦',
        'en-US': 'Recommend',
        'ja': 'お勧め',
        'zh-CN': '推荐'
    },
    items: [{
        keyName: 'Home',
        title: '主页',
        titleTranslation: { 'en-US': 'Index', ja: '家', 'zh-TW': '主頁', 'zh-CN': '主页' },
        className: 'bi bi-house-door',
        activeClassName: 'bi bi-house-door-fill',
    }, {
        keyName: 'Category',
        title: '分类',
        titleTranslation: { 'en-US': 'Category', ja: 'カテゴリー', 'zh-TW': '類別', 'zh-CN': '分类' },
        className: 'bi bi-grid',
        activeClassName: 'bi bi-grid-fill',
    }, {
        keyName: 'ShoppingCart',
        title: '购物车',
        titleTranslation: { 'en-US': 'ShoppingCart', ja: 'ショッピングカート', 'zh-TW': '購物車', 'zh-CN': '购物车' },
        className: 'bi bi-cart',
        activeClassName: 'bi bi-cart-fill',
    }, {
        keyName: 'User',
        title: '我的',
        titleTranslation: { 'en-US': 'Home', ja: '私の', 'zh-TW': '我的', 'zh-CN': '我的' },
        className: 'bi bi-person',
        activeClassName: 'bi bi-person-fill',
    }, {
        keyName: 'Feature',
        title: '特色',
        titleTranslation: { 'en-US': 'Feature', ja: '特徴', 'zh-TW': '特色', 'zh-CN': '特色' },
        className: 'bi bi-x-diamond',
        activeClassName: 'bi bi-x-diamond-fill',
    }, {
        keyName: 'News',
        title: '新闻',
        titleTranslation: { 'en-US': 'News', ja: 'ニュース', 'zh-TW': '新聞', 'zh-CN': '新闻' },
        className: 'bi bi-postcard',
        activeClassName: 'bi bi-postcard-fill',
    }, {
        keyName: 'Chat',
        title: '聊天',
        titleTranslation: { 'en-US': 'Chat', ja: 'とチャットする', 'zh-TW': '聊天', 'zh-CN': '聊天' },
        className: 'bi bi-chat-dots',
        activeClassName: 'bi bi-chat-dots-fill',
    }, {
        keyName: 'News',
        title: '资讯',
        titleTranslation: { 'en-US': 'Articles', ja: '記事一覧', 'zh-TW': '資訊', 'zh-CN': '资讯' },
        className: 'bi bi-card-list',
        activeClassName: 'bi bi-card-list-fill',
    }, {
        keyName: 'Message',
        title: '私信',
        titleTranslation: { 'en-US': 'Message', ja: '私信', 'zh-TW': '私信', 'zh-CN': '私信' },
        className: 'bi bi-bell',
        activeClassName: 'bi bi-bell-fill',
    }, {
        keyName: 'Notification',
        title: '通知',
        titleTranslation: { 'en-US': 'Notification', ja: '通知', 'zh-TW': '通知', 'zh-CN': '通知' },
        className: 'bi bi-megaphone',
        activeClassName: 'bi bi-megaphone-fill',
    }, {
        keyName: 'Record',
        title: '记录',
        titleTranslation: { 'en-US': 'Record', ja: '記録', 'zh-TW': '記錄', 'zh-CN': '记录' },
        className: 'bi bi-cloud-arrow-down',
        activeClassName: 'bi bi-cloud-arrow-down-fill',
    }, {
        keyName: 'Share',
        title: '分享',
        titleTranslation: { 'en-US': 'Record', ja: '記録', 'zh-TW': '記錄', 'zh-CN': '分享' },
        className: 'bi bi-share',
        activeClassName: 'bi bi-share-fill',
    }, {
        keyName: 'Setting',
        title: '设置',
        titleTranslation: { 'en-US': 'Setting', ja: '設定', 'zh-TW': '環境', 'zh-CN': '设置' },
        className: 'bi bi-gear',
        activeClassName: 'bi bi-gear-fill',
    }, {
        keyName: 'Gift',
        title: '礼物',
        titleTranslation: { 'en-US': 'Gift', ja: '贈り物', 'zh-TW': '禮物', 'zh-CN': '礼物' },
        className: 'bi bi-gift',
        activeClassName: 'bi bi-gift-fill',
    }, {
        keyName: 'Map',
        title: '地图',
        titleTranslation: { 'en-US': 'Map', ja: '地図', 'zh-TW': '地圖', 'zh-CN': '地图' },
        className: 'bi bi-geo-alt',
        activeClassName: 'bi bi-geo-alt-fill',
    }, {
        keyName: 'Favorite',
        title: '收藏',
        titleTranslation: { 'en-US': 'Favorite', ja: 'お気に入り', 'zh-TW': '收藏', 'zh-CN': '收藏' },
        className: 'bi bi-heart',
        activeClassName: 'bi bi-heart-fill',
    }, {
        keyName: 'Info',
        title: '信息',
        titleTranslation: { 'en-US': 'Info', ja: '情報', 'zh-TW': '信息', 'zh-CN': '信息' },
        className: 'bi bi-info-circle',
        activeClassName: 'bi bi-info-circle-fill',
    }, {
        keyName: 'Customized',
        title: '定制',
        titleTranslation: { 'en-US': 'Customized', ja: 'カスタムメイド', 'zh-TW': '定制', 'zh-CN': '定制' },
        className: 'bi bi-palette',
        activeClassName: 'bi bi-palette-fill',
    }]
}, {
    title: '基础',
    titleTranslation: {
        'zh-TW': '常規',
        'en-US': 'Basic',
        'ja': '基本型',
        'zh-CN': '基础'
    },
    items: [{
        keyName: 'Coupon',
        title: '优惠券',
        titleTranslation: { 'en-US': 'Coupon', ja: 'クーポン', 'zh-TW': '優惠券', 'zh-CN': '优惠券' },
        className: 'bi bi-ticket-perforated',
        activeClassName: 'bi bi-ticket-perforated-fill',
    }, {
        keyName: 'Activity',
        title: '活动',
        titleTranslation: { 'en-US': 'Activity', ja: 'アクティビティ', 'zh-TW': '活動', 'zh-CN': '活动' },
        className: 'bi bi-activity',
        activeClassName: 'bi bi-activity-fill',
    }, {
        keyName: 'Archive',
        title: '档案',
        titleTranslation: { 'en-US': 'Archive', ja: 'ファイル', 'zh-TW': '檔案', 'zh-CN': '档案' },
        className: 'bi bi-archive',
        activeClassName: 'bi bi-archive-fill',
    }, {
        keyName: 'Award',
        title: '荣誉',
        titleTranslation: { 'en-US': 'Archive', ja: '名誉', 'zh-TW': '榮譽', 'zh-CN': '荣誉' },
        className: 'bi bi-award',
        activeClassName: 'bi bi-award-fill',
    }, {
        keyName: 'Bag',
        title: '包',
        titleTranslation: { 'en-US': 'Bag', ja: 'バッグ', 'zh-TW': '包', 'zh-CN': '包' },
        className: 'bi bi-bag',
        activeClassName: 'bi bi-award-fill',
    }, {
        keyName: 'Book',
        title: '书籍',
        titleTranslation: { 'en-US': 'Book', ja: '本', 'zh-TW': '書籍', 'zh-CN': '书籍' },
        className: 'bi bi-book',
        activeClassName: 'bi bi-book-fill',
    }, {
        keyName: 'BookMarks',
        title: '标签',
        titleTranslation: { 'en-US': 'BookMarks', ja: 'ブックマーク', 'zh-TW': '書籤', 'zh-CN': '标签' },
        className: 'bi bi-bookmarks',
        activeClassName: 'bi bi-bookmarks-fill',
    }, {
        keyName: 'CarFront',
        title: '直通车',
        titleTranslation: { 'en-US': 'CarFront', ja: '電車で', 'zh-TW': '直通車', 'zh-CN': '直通车' },
        className: 'bi bi-car-front',
        activeClassName: 'bi bi-car-front-fill',
    }, {
        keyName: 'Search',
        title: '搜索',
        titleTranslation: { 'en-US': 'Search', ja: '探す', 'zh-TW': '搜索', 'zh-CN': '搜索' },
        className: 'bi bi-search',
        activeClassName: 'bi bi-search',
    }, {
        keyName: 'Camera',
        title: '相机',
        titleTranslation: { 'en-US': 'Camera', ja: 'カメラ', 'zh-TW': '相機', 'zh-CN': '相机' },
        className: 'bi bi-camera',
        activeClassName: 'bi bi-camera-fill',
    }, {
        keyName: 'CameraVideo',
        title: '视频',
        titleTranslation: { 'en-US': 'Video', ja: 'ビデオ', 'zh-TW': '視頻', 'zh-CN': '视频' },
        className: 'bi bi-camera-video',
        activeClassName: 'bi bi-camera-video-fill',
    }, {
        keyName: 'Cassette',
        title: '磁带',
        titleTranslation: { 'en-US': 'Cassette', ja: 'カセット', 'zh-TW': '卡帶', 'zh-CN': '磁带' },
        className: 'bi bi-cassette',
        activeClassName: 'bi bi-cassette-fill',
    }, {
        keyName: 'Statistics',
        title: '统计',
        titleTranslation: { 'en-US': 'Statistics', ja: '統計学', 'zh-TW': '統計', 'zh-CN': '统计' },
        className: 'bi bi-clipboard-data',
        activeClassName: 'bi bi-clipboard-data-fill',
    }, {
        keyName: 'Clock',
        title: '历史',
        titleTranslation: { 'en-US': 'Clock', ja: '歴史', 'zh-TW': '歷史', 'zh-CN': '历史' },
        className: 'bi bi-clock',
        activeClassName: 'bi bi-clock-fill',
    }, {
        keyName: 'Movies',
        title: '电影',
        titleTranslation: { 'en-US': 'Movies', ja: '映画', 'zh-TW': '電影', 'zh-CN': '电影' },
        className: 'bi bi-collection-play',
        activeClassName: 'bi bi-collection-play-fill',
    }, {
        keyName: 'Guide',
        title: '指南',
        titleTranslation: { 'en-US': 'Guide', ja: 'ガイド', 'zh-TW': '指南', 'zh-CN': '指南' },
        className: 'bi bi-compass',
        activeClassName: 'bi bi-compass-fill',
    }, {
        keyName: 'Game',
        title: '游戏',
        titleTranslation: { 'en-US': 'Game', ja: 'ゲーム', 'zh-TW': '遊戲', 'zh-CN': '游戏' },
        className: 'bi bi-controller',
        activeClassName: 'bi bi-controller-fill',
    }, {
        keyName: 'Profile',
        title: '资料',
        titleTranslation: { 'en-US': 'Profile', ja: '素材', 'zh-TW': '資料', 'zh-CN': '资料' },
        className: 'bi bi-credit-card-2-front',
        activeClassName: 'bi bi-credit-card-2-front-fill',
    }, {
        keyName: 'BankCard',
        title: '银行卡',
        titleTranslation: { 'en-US': 'Bank card', ja: 'バンクカード', 'zh-TW': '銀行卡', 'zh-CN': '银行卡' },
        className: 'bi bi-credit-card-2-back',
        activeClassName: 'bi bi-credit-card-2-back-fill',
    }, {
        keyName: 'Entertainment',
        title: '娱乐',
        titleTranslation: { 'en-US': 'Entertainment', ja: 'エンターテイメント', 'zh-TW': '娛樂', 'zh-CN': '娱乐' },
        className: 'bi bi-cup',
        activeClassName: 'bi bi-cup-fill',
    }, {
        keyName: 'Organize',
        title: '组织',
        titleTranslation: { 'en-US': 'Organize', ja: '整理', 'zh-TW': '組織', 'zh-CN': '组织' },
        className: 'bi bi-diagram-3',
        activeClassName: 'bi bi-diagram-3-fill',
    }, {
        keyName: 'Disc',
        title: '影片',
        titleTranslation: { 'en-US': 'Disc', ja: 'ディスク', 'zh-TW': '光盤', 'zh-CN': '影片' },
        className: 'bi bi-disc',
        activeClassName: 'bi bi-disc-fill',
    }, {
        keyName: 'Email',
        title: '邮件',
        titleTranslation: { 'en-US': 'Email', ja: 'Eメール', 'zh-TW': '郵件', 'zh-CN': '邮件' },
        className: 'bi bi-envelope',
        activeClassName: 'bi bi-envelope-fill',
    }, {
        keyName: 'Document',
        title: '文档',
        titleTranslation: { 'en-US': 'Document', ja: 'ファイル', 'zh-TW': '文档', 'zh-CN': '文档' },
        className: 'bi bi-file-earmark',
        activeClassName: 'bi bi-file-earmark-fill',
    }]
}];

export function getIconClassName(value: string, active?: boolean): any {
    let iconClassGroups = [];
    iconClassKeys?.forEach(iconClassGroup => iconClassGroups.push(...iconClassGroup?.items));
    const iconMatched = iconClassGroups.find(iconClassItem => iconClassItem.keyName === value);
    return active ? (iconMatched?.activeClassName || iconMatched?.className || (iconMatched?.keyName + '_active')) : (iconMatched?.className || iconMatched?.keyName);
}

@Pipe({
    name: 'appIconClassKey'
})
export class IconClassKeyPipe implements PipeTransform {
    transform(value: any, active?: any): any {
        return getIconClassName(value, active);
    }
}
