import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPost } from '../_model/post.model';
import { ILike } from '../_model/like.model';
import { IComment } from '../_model/comment.model';
import { API_SERVICE_PREFIX } from '../../../app.constants';

type EntityResponseType = HttpResponse<IPost>;
type EntityArrayResponseType = HttpResponse<IPost[]>;

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
@Injectable({ providedIn: 'root' })
export class PostService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/posts';

    constructor(private http: HttpClient) { }

    create(post: IPost): Observable<EntityResponseType> {
        return this.http.post<IPost>(this.resourceUrl, post, { observe: 'response' });
    }

    update(post: IPost): Observable<EntityResponseType> {
        return this.http.put<IPost>(`${this.resourceUrl}/${post.id}`, post, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<IPost>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headerOptions });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        return this.http.get<IPost[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    like(like: ILike): Observable<HttpResponse<ILike>> {
        return this.http.post<ILike>(API_SERVICE_PREFIX + '/customer/api/me/likes', like, { observe: 'response', headers: headerOptions });
    }
    unLike(id: number): Observable<HttpResponse<ILike>> {
        return this.http.delete<ILike>(API_SERVICE_PREFIX + '/customer/api/me/likes/' + id, { observe: 'response', headers: headerOptions });
    }
    getLikeCount(id: number, type?: string): Observable<any> {
        return this.http.get<any>(API_SERVICE_PREFIX + '/customer/api/likes/count', {
            params: {
                'subjectId.equals': String(id),
                'subjectType.equals': 'BRICS_CMS_POSTS'
            },
            observe: 'body', headers: headerOptions
        });
    }
    getLikeStatus(id: number, type?: string): Observable<any> {
        return this.http.get<any>(API_SERVICE_PREFIX + '/customer/api/me/likes', {
            params: {
                'subjectId.equals': String(id),
                'subjectType.equals': 'BRICS_CMS_POSTS'
            },
            observe: 'body', headers: headerOptions
        });
    }
    comment(comment: IComment): Observable<HttpResponse<IComment>> {
        return this.http.post<IComment>(API_SERVICE_PREFIX + '/customer/api/comments', comment, {
            observe: 'response', headers: headerOptions
        });
    }
    getCommentCount(arcticleId: number, commentId?: number): Observable<any> {
        const params: any = {
            'rootId.equals': arcticleId,
        };
        if (commentId) {
            params['objectId.equals'] = commentId;
        }
        return this.http.get<any>(API_SERVICE_PREFIX + '/customer/api/comments/count', {
            params: params,
            observe: 'body', headers: headerOptions
        });
    }
    getComments(req: any): Observable<HttpResponse<IComment[]>> {
        return this.http.get<IComment[]>(API_SERVICE_PREFIX + '/customer/api/comments', {
            params: req, observe: 'response', headers: headerOptions
        });
    }
    getTags(req?: any): Observable<HttpResponse<any[]>> {
        return this.http.get<any[]>(API_SERVICE_PREFIX + '/cms/api/tags', {
            params: req, observe: 'response', headers: headerOptions
        });
    }

    search(req?: any): Observable<EntityArrayResponseType> {
        return this.http.get<IPost[]>(this.resourceUrl + '/search', { params: req, observe: 'response', headers: headerOptions });
    }
}
