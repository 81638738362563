import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../i18n';
import { jsonParse, objectKeyToLowerCase } from './name-translate.pipe';

export function getCurrentDescription(control: any, currentLangKey: string, defaultLanguageKey: string = null): string {
    let langKey = currentLangKey?.toLowerCase();
    let descriptionTranslation = objectKeyToLowerCase(control?.descTranslation);
    if (Array.isArray(descriptionTranslation)) {
        const findNameTranslation = descriptionTranslation?.find(descriptionTranslation => descriptionTranslation?.[langKey]);
        return findNameTranslation?.[langKey] || findNameTranslation?.[defaultLanguageKey] || control?.desc;
    }
    if (descriptionTranslation && (typeof descriptionTranslation === 'object') && Object.keys(descriptionTranslation)?.includes(langKey)) {
        return descriptionTranslation[langKey] || descriptionTranslation?.[defaultLanguageKey] || control?.desc;
    } else {
        if (descriptionTranslation && (typeof descriptionTranslation === 'object')) {
            if (['zh-hk', 'zh-tw']?.includes(langKey)) {
                langKey = (langKey === 'zh-tw') ? 'zh-hk' : 'zh-tw';
                return descriptionTranslation[langKey] || descriptionTranslation[defaultLanguageKey] || control?.desc;
            }
        }
    }
    return jsonParse(control?.descTranslation)?.[currentLangKey] || jsonParse(control?.descTranslation)?.[defaultLanguageKey] || control?.desc;
}

@Pipe({
    name: 'mDescriptionTranslate',
    pure: false,
})
export class DescriptionTranslatePipe implements PipeTransform {

    constructor(
        private translationService: TranslationService,
    ) { }

    transform(value: any): any {
        return getCurrentDescription(value, this.translationService.getSelectedLanguage());
    }
}
