import { NgModule } from '@angular/core';
import { StopPropagationDirective } from '../core/directives/stop-propagetion.directive';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyHelperPipe } from './pipes/currency-helper.pipe';
import { DescriptionTranslatePipe } from './pipes/description-translate.pipe';
import { FindLanguageFromKeyPipe } from './pipes/find-language-from-key.pipe';
import { GiftCardAttributePipe } from './pipes/gift-card-attribute.pipe';
import { GroupBuyingGroupStatusPipe } from './pipes/group-buying-group-status.pipe';
import { IconClassKeyPipe } from './pipes/icon-class-key.pipe';
import { ImgResizePipe } from './pipes/img-resize.pipe';
import { MobilePhoneMaskPipe } from './pipes/mobile-phone-mask.pipe';
import { NameTranslatePipe } from './pipes/name-translate.pipe';
import { NumberTransformPipe } from './pipes/number-transform.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { SelectedLanguageHelperPipe } from './pipes/selected-language-helper.pipe';
import { TermTypeReformPipe } from './pipes/shop-user-term.pipe';
import { TextExtractionPipe } from './pipes/text-extraction.pipe';
import { TitleTranslatePipe } from './pipes/title-translate.pipe';
import { UploadUrlPrefixPipe } from './pipes/upload-url-prefix.pipe';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../core/directives/directives.module';
import { AnonymizeSecurityInformationPipe } from './pipes/anonymize-security-information.pipe';
import { PaymentTypePipe } from './pipes/payment-type.pipe';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatIconModule,
        FormsModule,
        TranslateModule,
        RouterModule,
        DirectivesModule,
    ],
    declarations: [
        ImgResizePipe,
        MobilePhoneMaskPipe,
        GiftCardAttributePipe,
        CurrencyHelperPipe,
        GroupBuyingGroupStatusPipe,
        StopPropagationDirective,
        TermTypeReformPipe,
        NumberTransformPipe,
        RelativeTimePipe,
        TextExtractionPipe,
        UploadUrlPrefixPipe,
        SelectedLanguageHelperPipe,
        IconClassKeyPipe,
        TitleTranslatePipe,
        NameTranslatePipe,
        DescriptionTranslatePipe,
        FindLanguageFromKeyPipe,
        AnonymizeSecurityInformationPipe,
        PaymentTypePipe,
    ],
    providers: [
        CurrencyPipe
    ],
    exports: [
        MatAutocompleteModule,
        TermTypeReformPipe,
        MatIconModule,
        FormsModule,
        ImgResizePipe,
        MobilePhoneMaskPipe,
        GiftCardAttributePipe,
        CurrencyHelperPipe,
        GroupBuyingGroupStatusPipe,
        StopPropagationDirective,
        NumberTransformPipe,
        RelativeTimePipe,
        TextExtractionPipe,
        TranslateModule,
        UploadUrlPrefixPipe,
        SelectedLanguageHelperPipe,
        IconClassKeyPipe,
        TitleTranslatePipe,
        NameTranslatePipe,
        DescriptionTranslatePipe,
        FindLanguageFromKeyPipe,
        RouterModule,
        DirectivesModule,
        AnonymizeSecurityInformationPipe,
        PaymentTypePipe,
    ]
})
export class SharedModule { }
