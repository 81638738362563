import { LinkType, MenuItem } from "../../shared/model/menu.model";
import { jsonParse } from "../../shared/pipes/name-translate.pipe";
import { TargetEntityTypeEnum, TargetTypeEnum } from "../model/mobile-config.model";

export function findPageMenuById(siteMenuItems: MenuItem[], path: string) {
    const allMenuItems = [];
    siteMenuItems?.forEach(menuItem => {
        allMenuItems.push(menuItem);
        if (menuItem?.children?.length) {
            menuItem?.children?.forEach(menuChildItem => {
                allMenuItems.push(menuChildItem);
                if (menuChildItem?.children?.length) {
                    menuChildItem?.children?.forEach(menuSonItem => {
                        allMenuItems.push(menuSonItem);
                    });
                }
            });
        }
    });
    return allMenuItems?.find(menuItem => {
        return (jsonParse(menuItem?.linkedPageParams)?.path || menuItem?.linkedPageId || jsonParse(menuItem?.titleTranslation)?.en || menuItem?.title) === path;
    });
}

export function findPageMenu(siteMenuItems: MenuItem[], routerUrl: string) {
    /**
     * Recursively add all menu items and children into a list of menu items.
     * 
     * @param siteMenuItems the menu items with children.
     * @param allMenuItems the list of all menu items without children.
     * @returns the list of all menu items without children.
     */
    function convertMenuItemTreeToList(siteMenuItems: MenuItem[], allMenuItems: {path: string, item: MenuItem}[] = []) {
        siteMenuItems?.forEach(menuItem => {
            const menuNativePath = getMenuNativePath(menuItem);
            allMenuItems.push({
                path: menuNativePath?.startsWith('/') ? menuNativePath : ('/' + menuNativePath),
                item: menuItem
            });
            if (menuItem?.children?.length) {
                    convertMenuItemTreeToList(menuItem?.children, allMenuItems);
            }
        });
        return allMenuItems;
    }

    const allMenuItems = convertMenuItemTreeToList(siteMenuItems);
    let routerPath = routerUrl?.split('?')?.[0];
    // resolve the root path
    if (['', '/', '/home']?.includes(routerPath)) {
        return allMenuItems?.find(menuItem => ['', '/', '/home'].includes(menuItem?.path))?.item;
    } else {
        return allMenuItems?.find(menuItem => routerPath === menuItem?.path)?.item;
    }
}

export function getMenuNativePath(item: MenuItem) {
    if (item) {
        try {
            if (item.linkType === LinkType.URL) {
                return `/page/${window.btoa(item.linkedUrl)}/url`;
            }
        } catch (error) {

        }
        if (item.linkType === LinkType.PAGE) {
            const pagePath = jsonParse(item?.linkedPageParams)?.path || item?.linkedPageId;
            const linkedPageParamPath = jsonParse(item.linkedPageParams)?.path;
            return getSpecialPagePath(item, pagePath) || linkedPageParamPath || `/page/${pagePath}`;
        }
        if (!item.linkType) {
            return undefined;
        }
    }
    return '';
}

export function getSpecialPagePath(item: MenuItem, pagePath: string) {
    let linkParams = jsonParse(item?.linkedPageParams) || {};
    if (linkParams.targetType === TargetTypeEnum.ENTITY) {
        if ([TargetEntityTypeEnum.POST_LIST, TargetEntityTypeEnum.POST_CATEGORY].includes(linkParams.targetEntityType)) {
            return `/page/${pagePath}/post/list`;
        }
        if (linkParams.targetEntityType === TargetEntityTypeEnum.POST) {
            return `/page/${pagePath}/post/${linkParams.targetEntityId}`;
        }
        if (linkParams.targetEntityType === TargetEntityTypeEnum.PRODUCT_CATEGORY) {
            return `/page/${pagePath}/product-category`;
        }
        if (linkParams.targetEntityType === TargetEntityTypeEnum.PRODUCT) {
            return `/page/${pagePath}/product`;
        }
        if (linkParams.targetEntityType === TargetEntityTypeEnum.FORM) {
            return `/page/${pagePath}/forms/${linkParams.targetEntityId}`;
        }
    }
    return '';
}