import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { CurrentDeviceService } from './current-device.service';
import { AuthService } from '../auth/auth.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { WechatLogin } from '../auth/login.model';
import { SiteSecuritySetting, AuthenticationMethodEnum } from '../../shared/model/site-security-settings.model';
import { GetCurrentUrlAddress } from '../../../assets/js/tools/getUrl';
declare const wx: any;

@Injectable({
    providedIn: 'root',
})
export class AutoLoginForWechatService  {
    isAutoLogined: boolean;
    constructor(
        private currentDeviceService: CurrentDeviceService,
        private authService: AuthService,
        protected siteContextService: SiteContextService,
        private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        const url: string = state.url;
        return this.canWechatAutoLogin(url).pipe(map(res => {
            return res;
        }));
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canWechatAutoLogin(url: string): Observable<boolean> {
        if (!this.currentDeviceService.isWX() || window.location.href?.match(/state=auto-login-in-wechat/) || this.isAutoLogined) {
            return of(true);
        } else {
            const enbaledAutoLogin$ = this.siteContextService.loadSiteSecuritySetting().pipe(
                // 请求到结果立即结束
                take(1),
                map((config: SiteSecuritySetting) => {
                    const hasJwtToken = !!this.siteContextService.token();
                    const wechatJsAuthEnabled = config?.authenticationMethods?.find(authenticationMethod => authenticationMethod?.authenticationMethod === AuthenticationMethodEnum.WECHAT_MP);
                    // 如果微信登录未开启或者已存在token的话
                    if (!wechatJsAuthEnabled || hasJwtToken) {
                        return true;
                    }
                    return false;
                }));
            return combineLatest(enbaledAutoLogin$, this.authorizationOfWechat(url)).pipe(map(rs => {
                if (rs?.length === 2) {
                    // 如果微信授权登录未开启或者token存在则直接放行
                    if (rs[0]) {
                        return true;
                    } else {
                        if (rs[1]) {
                            window.location.href = rs[1] as string;
                            return false;
                        }
                        return true;
                    }
                }
                return true;
            }),
                // 如果没配置微信信息api可能报错，故对错误进行处理，如果报错的话，直接返回true放行
                catchError(() => of(true))
            );
        }
    }
    authorizationOfWechat(url: string, isNeedUserAuthorization = false) {
        const agentUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';
        const isDevEnv = location.host.includes('localhost');
        const targetOriginalUrl = document?.baseURI;
        const redirect_uri = GetCurrentUrlAddress.urlEncode(targetOriginalUrl, url);
        const response_type = 'code';
        // 是否需要显示授权
        const scope = isNeedUserAuthorization ? 'snsapi_userinfo' : 'snsapi_base';
        const state = 'auto-login-in-wechat';
        return this.authService.getWxMpId().pipe(map((res: any) => {
            if (res && res.body && res.body.appId) {
                const appid = res.body['appId'];
                const wxAPI = `${agentUrl}?appid=${appid}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&state=${state}#wechat_redirect`;
                return wxAPI;
            } else {
                return false;
            }
        }));
    }

    checkCodeInRouterPathAndLogin() {
        const codeUrl = this.router.url;
        const regex = /code=(\S*)\&/;
        if (codeUrl.match(regex)) {
            const htmlUrlParam = new URLSearchParams(location.search);
            const code = htmlUrlParam.get('code');
            const state = htmlUrlParam.get('state'); // 根据codeUrl解析出state
            if (state?.includes('auto-login-in-wechat')) {
                this.authService.loginByWxMp(new WechatLogin(code, true, true)).subscribe(res => {
                    this.isAutoLogined = true;
                    if (res?.id_token) {
                        // 授权登录成功，返回原页面
                        // this.backSourcePageAfterAuthorization();
                    } else {
                        console.warn('微信内自动授权登录发生异常', res);
                    }
                },
                    err => {
                        this.isAutoLogined = true;
                        console.warn('微信内自动授权登录失败', err);
                    });
            }
        }
    }
}
