import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

// import { LoginModalService } from '../login/login-modal.service';
import { StateStorageService } from './state-storage.service';
import { Principal } from './principal.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { CurrentDeviceService } from '../services/current-device.service';
@Injectable({ providedIn: 'root' })
export class UserRouteAccessService  {
    constructor(
        private router: Router,
        private siteContextService: SiteContextService,
        private stateStorageService: StateStorageService,
        private currentDeviceService: CurrentDeviceService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.stateStorageService.storeUrl(state.url);
        if (!this.siteContextService.token()) {
            this.router.navigate([this.currentDeviceService.isWX() ? '/auth/login-by-wxcode' : '/auth/default-login'], {
                queryParams: {
                    returnUrl: state.url
                }
            });
            return false;
        }
        return true;
    }

    checkLogin(authorities: string[], url: string): boolean {
        this.stateStorageService.storeUrl(url);
        if (!this.siteContextService.token()) {
            this.router.navigate([this.currentDeviceService.isWX() ? '/auth/login-by-wxcode' : '/auth/default-login'], {
                queryParams: {
                    returnUrl: url
                }
            });
            return false;
        } else {
            return true;
        }
    }
}
