import { Dayjs } from 'dayjs/esm';
import { PageBlockTypeEnum } from "./page-block-type.enum";

export enum NewLayoutType {
    SIMPLE = 'SIMPLE',
    LEFT_IMAGE_RIGHT_INFO = 'LEFT_IMAGE_RIGHT_INFO',
    LEFT_DATE_CENTER_INFO_RIGHT_IMAGE = 'LEFT_DATE_CENTER_INFO_RIGHT_IMAGE',
    LEFT_IMAGE_CENTER_INFO_RIGHT_DATE = 'LEFT_IMAGE_CENTER_INFO_RIGHT_DATE',
}

export class PageBlock {
    id?: string;
    pageId?: string;
    // 块属于哪个section，如：content
    section: string;
    /**
     * 类型： Type of the block, e.g. carousel, product-list, product-detail, article-detail etc.
     */
    blockType: PageBlockTypeEnum;
    enabled: boolean;
    title?: string;
    /**
     * The i18n version titles, in JSON format: { "en": "Carousel", "zh-CN": "轮播图" }
     */
    titleTranslation?: string;
    // 排序数
    seqNum: number;
    // 是否是内置的页面块
    builtIn: boolean;
    renderOptions: string;
    // 内容文字/富文本/自定义入口的子项items JSON数据
    data: string;
    dataTranslation?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
}
