import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteContextService } from '../../shared/services/site-context.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private siteContextService: SiteContextService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.siteContextService.token();
        let customizeHeader = {};
        if (token) {
            customizeHeader = {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: 'Bearer ' + token,
                'brics-api-success-notify': request.headers.get('brics-api-success-notify') || 'true',
                'brics-api-error-notify': request.headers.get('brics-api-error-notify') || 'true',
            };
        } else {
            if (isPlatformBrowser(this.platformId)) {
                customizeHeader['Client-InnerWidth'] = window?.innerWidth?.toString();
            };
        }
        if (Object.keys(customizeHeader)?.length) {
            request = request.clone({
                setHeaders: customizeHeader
            });
        }
        return next.handle(request);
    }
}
