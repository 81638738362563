export const authTranslate = {
    LOGIN_BY_WECHAT_QRCODE: {
        TITLE: '微信掃碼登錄',
        RELOAD_BTN_TEXT: '刷新二維碼',
        ERROR_MESSAGE: {
            RELOAD_ERROR: '初始化微信二維碼失敗，請重試！',
            WECHAT_NOT_REGISTER: '當前微信暫未註冊或綁定手機號，您是否需要前往 微信註冊頁面 或 使用帳戶登錄 ？',
            CODE_USED: '當前身份已過期，請重新掃碼登錄......',
            DEFAULT_TEXT: '後台服務未啟動或者未配置微信平台資訊，請聯繫管理員',
            WECHAT_NOT_REGISTER_ERROR: '當前微信號暫未註冊，請問是否用該微信號註冊'
        }
    },
    LOGIN_BY_ACCOUNT_AND_PASSWORD: {
        TITLE: '帳戶密碼登錄'
    },
    PASSWORD: {
        TITLE: '8 60位僅包含數位、字母字元',
        PLACEHOLDER: '請輸入密碼...',
        REQUIRED_ERROR_MESSAGE: '密碼不能為空',
        PATTERN_ERROR_MESSAGE: '密碼格式不正確，請檢查',
        PATTERN_TOOLTIPS: {
            LENGTH_VALIDATE_MSG: '長度為{{passwordMinimumLength}}~60個字元',
            REQUIRE_START_TEXT: '至少包含{{value}}',
            NUMBER_TEXT: '數位',
            LOWER_CASE_TEXT: '小寫字母',
            SYMBOLS_TEXT: '特殊符號',
            UPPER_CASE_TEXT: '大寫字母',
            REQUIRE_ALL_RULES_TEXT: '必須包含大寫字母/小寫字母/數位以及特殊符號',
            SUPPORTED_CHARACTERS_TEXT: '僅支持輸入大寫字母、小寫字母、數位以及特殊符號',
        }
    },
    VERIFY_CODE: {
        TITLE: '驗證碼',
        PLACEHOLDER: '請輸入驗證碼...',
        SEND_BTN_TEXT: '發送',
        SEND_BTN_FULL_TEXT: '送出驗證碼',
        GET_CODE_ERROR_MESSAGE: '獲取圖片驗證碼失敗',
        REQUIRED_ERROR_MESSAGE: '驗證碼不能為空',
        PATTERN_ERROR_MESSAGE: '驗證碼格式不正確',
        SEND_ERROR: '送出驗證碼失敗：',
        SENDING_TEXT_MESSAGES_TOO_FREQUENTLY_PLEASE_TRY_AGAIN_LATER: '發送簡訊過於頻繁，請稍後再試吧',
    },
    MOBILE_PHONE: {
        TITLE: '手機號',
        PLACEHOLDER: '請輸入手機號...',
        REQUIRED_ERROR_MESSAGE: '手機號不能為空！',
        PATTERN_ERROR_MESSAGE: '手機號格式不正確！',
        EXISISTED_ERROR_MESSAGE: '手機號已注册，請更換手機號或者使用該手機號登錄吧！',
        NOT_EXISIST_ERROR_MESSAGE: '手機號未注册，請更換手機號或者先去注册吧！',
    },
    ACCOUNT: {
        TITLE: '帳號',
        PLACEHOLDER: '請輸入帳號...',
        REQUIRED_ERROR_MESSAGE: '帳號不能為空',
        PATTERN_ERROR_MESSAGE: '帳號格式不正確',
    },
    RESET_PASSWORD: {
        TITLE: '重置密碼',
        RESET_BTN_TEXT: '確認重置',
        SUCCESS_MESSAGE: '重置成功',
        ERROR_MESSAGE: '重置失敗：'
    },
    LOGIN_BY_ACCOUNT: {
        TITLE: '賬號登錄',
    },
    LOGIN_BY_MOBILE_AND_VERIFY: {
        TITLE: '驗證碼登錄',
    },
    LOGIN_BY_MOBILE_AND_PASSWORD: {
        TITLE: '密碼登錄',
    },
    LOGIN_BY_WECHAT_MP: {
        TITLE: '微信授權登錄',
        LOGIN_BTN_TEXT: '授權登錄',
        ERROR_MESSAGE: {
            NOT_CONFIG: '未配置微信公眾平台資訊，請聯繫客服或者管理員！',
            CONFIG_ERROR: '微信配置數據有誤，請聯繫客服或者管理員！',
            WECHAT_NOT_REGISTER: '該微信暫未註冊，請使用手機號或者其他微信登錄吧！',
            DEFAULT_TEXT: '微信登錄失敗，請刷新后重新授權登錄'
        }
    },
    LOGIN_BY_WECHAT: {
        TITLE: '微信登錄'
    },
    LOGIN_BY_PHONE: {
        TITLE: '手機號登錄'
    },
    RESPONSE_ERROR_MESSAGE: '登入失敗：',
    REGISTER_ERROR_MESSAGE: '註冊失敗：',
    CHANGE_ERROR_MESSAGE: '變更失敗：',
    AUTO_LOGIN_TEXT: '自動登錄',
    FORGOT_PASSWORD_TEXT: '忘記密碼',
    LOGIN_TEXT: '登錄',
    LOGOUT_TEXT: '退出登錄',
    RELOAD_TEXT: '刷新重試',
    METHOD_ERROR_MESSAGE: '獲取可用登錄方式失敗~',
    OTHER_LOGIN_METHOD_TEXT: '其他登錄方式~',
    USER_NOT_EXIST_EROOR_TEXT: '當前帳號不存在哦~',
    INFOMATION: {
        ERROR_MESSAGE: '帳號信息獲取失敗，請刷新重試',
        WELECOME_TEXT: '歡迎回來~',
        HOME_BTN_TEXT: '個人中心',
    },
    WECHAT_AUTHORIZATION_SUCCESS: '微信授權成功！',
    AUTHORIZATION: '授權',
    QRCODE: '掃碼',
    FAILED_TO_BIND_WECHAT: '綁定微信失敗：',
    FAILED_TO_UNBIND_WECHAT: '解綁微信失敗：',
    FAILED_TO_BIND: '繫結失敗：',
    WECHAT_CODE_VERIFICATION_FAILED_PLEASE_TRY_AGAIN: '微信碼校驗失敗，請重新',
    CURRENT_ACCOUNT_IDENTITY: '當前身份',
    FAILED_TO_GET_LOGIN_REGISTRATION_METHOD: '獲取登錄/註冊方式失敗~',
    FAILED_TO_OBTAIN_PERSONAL_INFORMATION: '獲取個人信息失敗！',
    SIGN_OUT_AND_SIGN_IN_SUCCESSFULLY: '退出登錄成功',
    OTHER_REGISTRATION_OR_LOGIN_METHODS: '其他{{value}}方式',
    REGISTRATION_TEXT: '註冊',
    REGISTER_BTN_TEXT: '創建新帳戶',
    SIGN_UP_NOW: '立即註冊',
    LOG_IN_IMMEDIATELY: '立即登錄',
    ALREADY_HAVE_AN_ACCOUNT: '已有帳號？',
    DONT_HAVE_AN_ACCOUNT_YET: '還沒有帳號？',
    MOBILE_NUMBER_REGISTRATION: {
        TITLE: '手機註冊'
    },
    WECHAT_REGISTRATION: {
        TITLE: '微信註冊'
    },
    CAPTCHA_VERIFY_CODE: '圖片驗證碼',
    CAPTCHA_VERIFY_CODE_PLACEHOLDER: '請輸入圖片驗證碼',
    CAPTCHA_VERIFY_CODE_NULL_ERROR: '圖片驗證碼不能為空！',
    CAPTCHA_VERIFY_CODE_INVALID_ERROR: '圖片驗證碼最少得有4位！',
    MESSAGE_VERIFY_CODE: '手機驗證碼',
    REFERRAL_CODE: '推薦碼',
    REFERRAL_CODE_PLACEHOLDER: '可輸入邀請人的推薦碼',
    SECOND: '秒',
    PHONE_NULL_TIPS: '請先輸入手機號！',
    PHONE_NOT_REGISTER_TIPS: '當前手機號未註冊！',
    PHONE_HAS_REGISTERED_TIPS: '當前手機號已註冊！',
    CAPTCHA_VERIFY_CODE_ERROR_TIPS: '圖片驗證碼輸入錯誤！',
    CAPTCHA_VERIFY_CODE_NULL_TIPS: '請填寫圖片驗證碼',
    AGREEMENT_NOT_CONFIRM_TIPS: '協定未勾選！',
    LOGGING_IN_TIPS: '登錄中',
    SIGN_IN_SUCCESSFULLY: '登錄成功',
    PASSWORD_TEXT: '密碼',
    ACCOUNT_LOGIN: '帳戶登錄',
    LOGIN_BY_OTHER_WECHAT_ACCOUNT: '其他微信登錄',
    ACCOUNT_REGISTER: {
        TITLE: '手機號註冊',
        EXISISTED_ERROR_MESSAGE: '手機號已注册！',
        NOT_EXISIST_ERROR_MESSAGE: '手機號未注册！',

    },
    REGISTERING: '註冊中',
    REGISTRATION_SUCCESS: '註冊成功',
    WECHAT_AUTHORIZED_REGISTRATION: '微信授權註冊',
    BIND_AND_REGISTER: '綁定並註冊',
    WECHAT_SCAN_CODE_REGISTRATION: '微信掃碼註冊',
    BIND_MOBILE_NUMBER: '綁定手機號',
    WECHAT_REGISTERED_AND_AUTO_LOGGING_TIPS: '該微信已註冊，為您自動登錄中......',
    IDENTITY_EXPIRED_AND_REAUTHORIZE_TIPS: '當前身份已過期，請重新授權......',
    AGREE_BY_ACTION_TYPE: '{{action}}即代表同意',
    I_HAVE_READ_AND_AGREE_TIPS: '我已閱讀並同意',
    WECHAT_SCAN_CODE_AUTHORIZATION: '微信掃碼授權',
    WECHAT_AUTHORIZATION: '微信授權',
    WECHA_PLATFORM_NOT_CONFIRGURED_ERROR_TIPS: '後台未配置微信公眾平台資訊，請聯繫管理員！',
    CURRENT_WECHAT_AUTHORIZATION_FAILED_TIPS: '當前微信狀態已過期，是否重新授權',
    REAUTHORIZE: '重新授權',
    GET_CURRENT_WECHAT_ID_FAILED_TIPS: '未獲取到微信身份，請重試！',
    SUCCESSFULLY_BIND_WECHAT: '綁定微信成功！',
    AUTHORIZE_SUCCESSFULLY_AND_AUTO_LOGGING_TIPS: '授權成功，為您登錄中......',
    WECHAT_PLATFORM_SCRET_ERROR_TIPS: '當前後台未配置正確的微信密鑰，無法進行微信授權登錄！',
    WECHAT_NOTREGISTERED_ERROR_TIPS: '目前微信暫未註冊，您是否需要前往 微信註冊頁面 或 刷新重試 ？',
    WECHAT_NOTREGISTERED_OR_NOT_FOUND_PHONE_ERROR_TIPS: '當前微信暫未註冊或綁定手機號，您是否需要前往 微信註冊頁面 或 使用帳戶登錄 ？',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS_FOR_PHONE_LOGIN_ENABLE: '目前微信未授權，您是否需要 微信授權登錄 或 使用帳戶登錄 ？',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS: '目前微信未授權，您是否需要 微信授權登錄 或 刷新重試 ？',
    NEW_PASSWORD: '新密碼',
    PLEASE_READ_AND_CHECKED_THE_TERMS: '請閱讀並勾選條款！',
    PHONE_OR_CAPTCHA_CODE_NULL: '請先輸入手機號或者圖片驗證碼！',
    OLD_PASSWORD: '原密碼',
    OLD_PASSWORD_PLACEHOLDER: '請輸入原密碼',
    RESETING: '重置中',
    RESET_SUCCESSFULLY: '重置成功',
    MESSAGE_CODE_SEND_FAILED: '簡訊驗證碼發送失敗',
    EMAIL_CODE_SEND_FAILED: '郵箱驗證碼發送失敗',
    EMAIL_VERIFY_CODE: '郵箱驗證碼',
    EMAIL_NULL_TIPS: '請先輸入郵箱!',
    EMAIL_NOT_REGISTER_TIPS: '當前郵箱未注册!',
    EMAIL_HAS_REGISTERED_TIPS: '當前郵箱已注册!',
    EMAIL: {
        TITLE: '郵箱',
        PLACEHOLDER: '請輸入郵箱...',
        REQUIRED_ERROR_MESSAGE: '郵箱不能為空！',
        PATTERN_ERROR_MESSAGE: '郵箱格式不正確！',
        EXISISTED_ERROR_MESSAGE: '郵箱已注册，請更換郵箱或者使用該郵箱去登錄吧！',
        NOT_EXISIST_ERROR_MESSAGE: '郵箱未注册，請更換郵箱或者先注册吧！',
    },
    REGISTRATION_BY_PHONE: {
        TITLE: '手機號注册'
    },
    LOGIN_BY_EMAIL: {
        TITLE: '郵箱登錄'
    },
    REGISTRATION_BY_EMAIL: {
        TITLE: '郵箱註冊'
    },
    BIND_EMAIL: '綁定郵箱',
    EMAIL_VERIFY_CODE_PLACEHOLDER: '請輸入郵箱驗證碼',
    SEND_EMAIL_VERIFY_CODE_BTN_TEXT: '發送郵箱驗證碼',
    OLD_EMAIL_CAPTCHA_ERROR: '原郵箱圖片驗證碼輸入錯誤',
    NEW_EMAIL: '新郵箱',
    OLD_EMAIL: '原郵箱',
    LOGIN_BY_NAME: {
        TITLE: '帳戶名登入',
    },
    LOGIN_NAME: {
        TITLE: '帳戶名',
        PLACEHOLDER: '請輸入帳戶名...',
        REQUIRED_ERROR_MESSAGE: '帳戶名不能為空！',
        PATTERN_ERROR_MESSAGE: '帳戶名格式不正確！',
        NOT_EXISIST_ERROR_MESSAGE: '當前帳戶不存在，請檢查帳戶名或者先去注册吧！',
    },
};
