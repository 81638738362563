import { Routes } from '@angular/router';

export const builtinPageRoutes: Routes = [
    {
        path: 'user-center',
        data: { authorities: ['ROLE_USER'] },
        loadChildren: () => import(`./user-center/user-center.module`).then(m => m.UserCenterModule),
    },
    {
        path: 'auth',
        loadChildren: () => import(`./auth/auth.module`).then(m => m.AuthModule),
    },
    {
        path: 'announcements',
        loadChildren: () => import(`./announcement/announcement.module`).then(m => m.AnnouncementModule),
    },
    {
        path: 'document',
        loadChildren: () => import(`./user-term-page/user-term-page.module`).then(m => m.UserTermPageModule),
    },
    {
        path: 'search-page',
        loadChildren: () => import(`./search-page/search-page.module`).then(m => m.SearchPageModule),
    },
    {
        path: 'post',
        loadChildren: () => import(`./post-list/post-list.module`).then(m => m.PostPageModule),
    },
    {
        path: 'forms',
        loadChildren: () => import(`./smart-form-page/smart-form-page.module`).then(m => m.SmartFormPageModule),
    },
    {
        path: 'customer-service',
        loadChildren: () => import(`./customer-service/customer-service.module`).then(m => m.CustomerServiceModule)
    },
    {
        path: '404',
        loadChildren: () => import(`./not-found/not-found.module`).then(m => m.NotFoundModule)
    },
    // {
    //     path: '**',
    //     redirectTo: '404',
    //     pathMatch: 'full'
    // }
];
