import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../../../../app.constants';
import { ICustomerGiftCard } from 'src/app/ecommerce/shared/model/customer-gift-card.model';

type EntityResponseType = HttpResponse<ICustomerGiftCard>;
type EntityArrayResponseType = HttpResponse<ICustomerGiftCard[]>;

@Injectable({ providedIn: 'root' })
export class CustomerGiftCardService {
    private resourceUrl = API_SERVICE_PREFIX + '/payment/api/me/customer-gift-cards';

    constructor(
        private http: HttpClient,
    ) { }

    create(customerGiftCard: ICustomerGiftCard): Observable<EntityResponseType> {
        return this.http
            .post<ICustomerGiftCard>(this.resourceUrl, customerGiftCard, { observe: 'response' });
    }

    bind(secret: string): Observable<EntityResponseType> {
        return this.http
            .post<ICustomerGiftCard>(this.resourceUrl, { secret }, { observe: 'response' });
    }

    update(customerGiftCard: ICustomerGiftCard): Observable<EntityResponseType> {
        return this.http
            .put<ICustomerGiftCard>(this.resourceUrl, customerGiftCard, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http
            .get<ICustomerGiftCard>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any, headerOptions?: HttpHeaders): Observable<EntityArrayResponseType> {
        return this.http
            .get<ICustomerGiftCard[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
