import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { GroupBuyingResultEnum } from '../../builtin-pages/user-center/pages/all-order-page/_model/sales-order.model';
import { SalesOrderService } from '../../builtin-pages/user-center/pages/all-order-page/_service/sales-order.service';
import { ShopContextService } from '../../ecommerce/shared/service/shop-context.service';
import { SiteContextService } from '../../shared/services/site-context.service';

@Injectable({ providedIn: 'root' })
export class OrderNumHelperService {
    $peddingPayOrderNum: BehaviorSubject<number> = new BehaviorSubject(0);
    $inProgressOrderNum: BehaviorSubject<number> = new BehaviorSubject(0);
    $peddingReceiveOrderNum: BehaviorSubject<number> = new BehaviorSubject(0);
    $compledOrderNum: BehaviorSubject<number> = new BehaviorSubject(0);
    countInterval;
    constructor(
        private salesOrderService: SalesOrderService,
        private siteContextService: SiteContextService,
        private shopContextService: ShopContextService,
    ) { }

    clear() {
        this.$peddingPayOrderNum.next(0);
        this.$inProgressOrderNum.next(0);
        this.$peddingReceiveOrderNum.next(0);
        this.$compledOrderNum.next(0);
        clearInterval(this.countInterval);
    }

    getCount() {
        const token = this.siteContextService.token();
        const customerId = this.siteContextService.customer()?.id;
        if (token && customerId) {
            this.getSalesOrderNum({
                'paid.equals': false,
                'cancelled.equals': false,
                // 'customized.equals': false
            }).subscribe(res => this.$peddingPayOrderNum.next(res.body));
            this.getSalesOrder({
                'paid.equals': true,
                'cancelled.equals': false,
                'completed.equals': false,
                // 'customized.equals': false,
                size: 99999
            }).subscribe(res => {
                this.$peddingReceiveOrderNum.next(res.body.filter(
                    item => item.groupBuyingGroupResult !== GroupBuyingResultEnum.IN_PROGRESS
                ).length);
            });
            this.getSalesOrderNum({
                'groupBuyingGroupResult.equals': 'IN_PROGRESS',
                'salesOrderType.equals': 'GROUP_BUYING_ORDER'
            }).subscribe(res => this.$inProgressOrderNum.next(res.body));
            this.getSalesOrderNum({
                'completed.equals': true
            }).subscribe(res => this.$compledOrderNum.next(res.body));
        } else {
            this.clear();
        }
    }

    init() {
        this.getCount();
        this.restartInterval();
    }

    restartInterval() {
        clearInterval(this.countInterval);
        this.countInterval = setInterval(() => {
            this.getCount();
        }, 10000);
    }

    getSalesOrderNum(param) {
        const customerId = this.siteContextService.customer()?.id;
        if (!customerId) {
            return new Subject<any>();
        }
        return this.salesOrderService.count({
            'shopId.equals': this.shopContextService.shopId,
            'customerId.equals': customerId,
            ...param
        });
    }

    getSalesOrder(param) {
        const customerId = this.siteContextService.customer()?.id;
        if (!customerId) {
            return new Subject<any>();
        }
        return this.salesOrderService.query({
            'shopId.equals': this.shopContextService.shopId,
            'customerId.equals': customerId,
            ...param
        });
    }
}
