export const systemSettingTranslate = {
    WECHAT_OPEN_CONFIG: {
        APP_ID: {
            PLACEHOLDER: 'Please enter appid...',
            REQUIRED_ERROR_MESSAGE: 'Appid cannot be empty!',
        },
        APP_SECRET: {
            PLACEHOLDER: 'Please enter appsecret...',
            REQUIRED_ERROR_MESSAGE: 'Appsecret cannot be empty!',
        },
        SUCCESS_MESSAGE: 'Saved successfully!',
        ERROR_MESSAGE: 'Save failed!',
    },
    WECHAT_MP_CONFIG: {
        APP_ID: {
            PLACEHOLDER: 'Please enter appid...',
            REQUIRED_ERROR_MESSAGE: 'Appid cannot be empty!',
        },
        APP_SECRET: {
            PLACEHOLDER: 'Please enter appsecret...',
            REQUIRED_ERROR_MESSAGE: 'Appsecret cannot be empty!',
        },
        SUCCESS_MESSAGE: 'Saved successfully!',
        ERROR_MESSAGE: 'Save failed!',
    }
}