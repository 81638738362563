import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BindEmail, ChangeEmail, EmailCode, EmailLogin, EmailRegister, ForgotPassword, ForgotPasswordByEmail, IBindMobile, IOpenId, IQueryOpenId, IUserChangeMobile, IUserChangePassword, IUserChangePasswordByMobile, IWechatLogin, JwtToken, Login, QueryOpenId, Register, SetLoginNameByEmail, SetLoginNameByMobile, SetLoginNameByPassword, Sms, WechatLogin, WechatRegister } from './login.model';
import { AuthServerProvider } from './auth-jwt.service';
import { Account } from '../user/account.model';

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private http: HttpClient,
        private authServerProvider: AuthServerProvider,
    ) { }
    // 验证码登录
    loginByMessageCode(credentials: any): Observable<any> {
        return this.http
            .post<JwtToken>(API_SERVICE_PREFIX + '/user/api/authenticate/mobile', { phoneNumber: credentials.phoneNumber, verifyCode: credentials.verifyCode, rememberMe: credentials.rememberMe }, { headers: headerOptions })
            .pipe(map(response => this.authServerProvider.authenticateSuccess(response, credentials.rememberMe)));
    }

    // 密码登录
    loginByPassword(credentials: Login): Observable<any> {
        return this.http
            .post<JwtToken>(API_SERVICE_PREFIX + '/user/api/authenticate/mobile-and-password', { phoneNumber: credentials.username, password: credentials.password, rememberMe: credentials.rememberMe }, { headers: headerOptions })
            .pipe(map(response => this.authServerProvider.authenticateSuccess(response, credentials.rememberMe)));
    }

    // 微信公众号授权登录
    loginByWxMp(credentials: WechatLogin): Observable<any> {
        return this.http
            .post<JwtToken>(API_SERVICE_PREFIX + `/user/api/authenticate/wx-mp`, { code: credentials.code, rememberMe: credentials.rememberMe, requireOtpIfNotRegistered: credentials.requireOtpIfNotRegistered }, { headers: headerOptions })
            .pipe(map(response => this.authServerProvider.authenticateSuccess(response, credentials.rememberMe)));
    }

    // 微信扫码登录
    loginByWxOpenPlatform(credentials: WechatLogin): Observable<any> {
        return this.http
            .post<JwtToken>(API_SERVICE_PREFIX + `/user/api/authenticate/wx-open-Platform`, { code: credentials.code, rememberMe: credentials.rememberMe, requireOtpIfNotRegistered: credentials.requireOtpIfNotRegistered }, { headers: headerOptions })
            .pipe(map(response => this.authServerProvider.authenticateSuccess(response, credentials.rememberMe)));
    }

    // 账户密码注册
    registerByPassword(params: Register): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/register/mobile-and-password', {
                phoneNumber: params.phoneNumber,
                verifyCode: params.verifyCode,
                referralCode: params.referralCode,
                password: params.password
            }, { observe: 'response', headers: headerOptions })
            .pipe(map(response => response.body))
            .pipe(map((params: JwtToken) => this.authServerProvider.authenticateSuccess(params, true)));
    }

    // 微信公众号授权注册
    registerByWxMp(params: WechatRegister): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/register/wx-mp', params, { observe: 'response' })
            .pipe(map(response => response.body))
            .pipe(map((params: JwtToken) => this.authServerProvider.authenticateSuccess(params, true)));
    }

    // 微信公众号授权注册(无需手机号)
    registerByWxMpAndNoMobile(params: IWechatLogin): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/register/wx-mp/no-mobile', params, { observe: 'response' })
            .pipe(map(response => response.body))
            .pipe(map((params: JwtToken) => this.authServerProvider.authenticateSuccess(params, true)));
    }

    // 微信扫码注册
    registerByWxOpenPlatform(params: WechatRegister): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/register/wx-open-platform', params, { observe: 'response' })
            .pipe(map(response => response.body))
            .pipe(map((params: JwtToken) => this.authServerProvider.authenticateSuccess(params, true)));
    }

    // 微信扫码注册(无需手机号)
    registerByWxOpenPlatformAndNoMobile(params: IWechatLogin): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/register/wx-open-platform/no-mobile', params, { observe: 'response' })
            .pipe(map(response => response.body))
            .pipe(map((params: JwtToken) => this.authServerProvider.authenticateSuccess(params, true)));
    }

    // 获取图片验证码
    getGraphCode(): Observable<any> {
        return this.http
            .get<any>(API_SERVICE_PREFIX + '/user/api/graph/verify-code', { observe: 'response' });
    }

    // 获取微信开放平台appId配置
    getWxOpenId(): Observable<HttpResponse<{ appId: string }>> {
        return this.http
            .get<{ appId: string }>(`${API_SERVICE_PREFIX}/user/api/wx-open-platform-configs/app-id`, {
                observe: 'response',
                headers: headerOptions
            });
    }

    // 获取微信公众平台appId配置
    getWxMpId(): Observable<HttpResponse<{ appId: string }>> {
        return this.http
            .get<{ appId: string }>(`${API_SERVICE_PREFIX}/user/api/wx-mp-login-configs/app-id`, {
                observe: 'response',
                headers: headerOptions
            });
    }

    // 发送短信验证码
    sendMessageCode(params: Sms): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/verify-codes/mobile', params, { observe: 'response' });
    }

    // 微信解绑
    wxUnbind(): Observable<any> {
        return this.http.put<any>(API_SERVICE_PREFIX + `/user/api/wx/unbind`, {}, { observe: 'response' });
    }

    // 微信授权绑定
    bindByWxMp(code: string): Observable<any> {
        return this.http.post<any>(API_SERVICE_PREFIX + `/user/api/account/wx-mp`, { code }, { observe: 'response', headers: headerOptions });
    }

    // 微信扫码绑定
    bindByWxOpenPlatform(code: string): Observable<any> {
        return this.http.post<any>(API_SERVICE_PREFIX + `/user/api/account/wx-open-platform`, { code }, { observe: 'response', headers: headerOptions });
    }

    changeMobile(userChangePhone: IUserChangeMobile): Observable<HttpResponse<void>> {
        return this.http
            .put<void>(API_SERVICE_PREFIX + '/user/api/account/change-mobile', userChangePhone, { observe: 'response' });
    }

    bindMobile(bindPhoneData: IBindMobile): Observable<HttpResponse<void>> {
        return this.http
            .post<void>(API_SERVICE_PREFIX + '/user/api/account/mobile', bindPhoneData, { observe: 'response' });
    }

    changePassword(userChangePassword: IUserChangePassword): Observable<HttpResponse<IUserChangePassword>> {
        return this.http
            .post<IUserChangePassword>(API_SERVICE_PREFIX + '/user/api/account/change-password', userChangePassword, { observe: 'response' });
    }

    setLoginNameByPassword(setLoginNameByPassword: SetLoginNameByPassword): Observable<HttpResponse<Account>> {
        return this.http
            .post<Account>(API_SERVICE_PREFIX + '/user/api/account/set-login', setLoginNameByPassword, { observe: 'response' });
    }

    changePasswordByMobile(userChangePasswordByMobile: IUserChangePasswordByMobile): Observable<HttpResponse<IUserChangePassword>> {
        return this.http
            .post<IUserChangePassword>(API_SERVICE_PREFIX + '/user/api/account/change-password/by-mobile', userChangePasswordByMobile, { observe: 'response' });
    }

    setLoginNameByMobile(setLoginNameByMobile: SetLoginNameByMobile): Observable<HttpResponse<Account>> {
        return this.http
            .post<Account>(API_SERVICE_PREFIX + '/user/api/account/set-login/by-mobile', setLoginNameByMobile, { observe: 'response' });
    }

    resetPasswordByMobile(userChangePassword: ForgotPassword): Observable<HttpResponse<ForgotPassword>> {
        return this.http
            .post<ForgotPassword>(API_SERVICE_PREFIX + '/user/api/account/reset-password/by-mobile', userChangePassword, { observe: 'response' });
    }

    // 获取手机号是否存在
    verifyPhoneNumberExists(phoneNumber: string): Observable<boolean> {
        return this.http
            .get<any>(API_SERVICE_PREFIX + '/user/api/register/mobile?phoneNumber=' + encodeURIComponent(phoneNumber));
    }

    // 获取邮件是否存在
    verifyEmailExists(email: string): Observable<boolean> {
        return this.http
            .get<any>(API_SERVICE_PREFIX + '/user/api/register/email?email=' + encodeURIComponent(email));
    }

    // 判断当前账号是否绑定微信
    getWxOpenIdByUserId(id: number): Observable<any> {
        return this.http
            .get<string>(API_SERVICE_PREFIX + '/user/api/wx/openId/' + id, { responseType: 'text' } as Object);
    }

    // 获取当前的微信号Id
    getWechatOpenIdByWxMp(code: string): Observable<HttpResponse<IOpenId>> {
        return this.http
            .post<IOpenId>(API_SERVICE_PREFIX + '/user/api/wx/wx-mp/openId', new QueryOpenId(code), { observe: 'response' });
    }

    getWechatOpenIdByWxOpenPlatform(code: string): Observable<HttpResponse<IOpenId>> {
        return this.http
            .post<IOpenId>(API_SERVICE_PREFIX + '/user/api/wx/wx-open-platform/openId',
                new QueryOpenId(code), { observe: 'response' });
    }

    // 发送邮件验证码
    sendEmailCode(params: EmailCode): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/verify-codes/email', params, { observe: 'response' });
    }

    // 邮箱注册
    registerByEmail(params: EmailRegister): Observable<any> {
        return this.http
            .post<any>(API_SERVICE_PREFIX + '/user/api/register/email-and-password', params, { observe: 'response', headers: headerOptions })
            .pipe(map(response => response.body))
            .pipe(map((params: JwtToken) => this.authServerProvider.authenticateSuccess(params, true)));
    }

    // 邮箱登录
    loginByEmail(credentials: EmailLogin): Observable<any> {
        return this.http
            .post<JwtToken>(API_SERVICE_PREFIX + '/user/api/authenticate/email-and-password', { email: credentials.email, password: credentials.password }, { headers: headerOptions })
            .pipe(map(response => this.authServerProvider.authenticateSuccess(response, credentials.rememberMe)));
    }

    resetPasswordByEmail(userChangePassword: ForgotPasswordByEmail): Observable<HttpResponse<ForgotPassword>> {
        return this.http
            .post<ForgotPassword>(API_SERVICE_PREFIX + '/user/api/account/reset-password/by-email', userChangePassword, { observe: 'response' });
    }

    setLoginNameByEmail(setLoginNameByEmail: SetLoginNameByEmail): Observable<HttpResponse<Account>> {
        return this.http
            .post<Account>(API_SERVICE_PREFIX + '/user/api/account/set-login/by-email', setLoginNameByEmail, { observe: 'response' });
    }

    bindEmail(bindEmail: BindEmail): Observable<HttpResponse<void>> {
        return this.http
            .post<void>(API_SERVICE_PREFIX + '/user/api/account/email', bindEmail, { observe: 'response' });
    }

    changeEmail(changeEmail: ChangeEmail): Observable<HttpResponse<void>> {
        return this.http
            .put<void>(API_SERVICE_PREFIX + '/user/api/account/change-email', changeEmail, { observe: 'response' });
    }

    // 更新token，在部分操作时需要更新token，以避免后端部分服务无法获取到最新的账号数据，不会更新过期时间 != 续期token
    getUpdatedToken(): Observable<string> {
        return this.http
            .get<JwtToken>(API_SERVICE_PREFIX + '/user/api/authenticate/update-token', {
                observe: 'body',
                headers: headerOptions
            }).pipe(map(res => res?.id_token));
    }

    loginByAccount(credentials: Login): Observable<any> {
        const headerOptions = new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        });
        return this.http
            .post<JwtToken>(API_SERVICE_PREFIX + '/user/api/authenticate', { username: credentials.username, password: credentials.password, rememberMe: credentials.rememberMe }, { headers: headerOptions })
            .pipe(map(response => this.authServerProvider.authenticateSuccess(response, credentials.rememberMe)));
    }
}
