import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Pipe({ name: 'findLanguageFromKey' })
export class FindLanguageFromKeyPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService,
    ) { }
    private languages = [
        { key: 'zh-CN', name: '简体中文', tips: 'LANGUAGE_SWITCH.CHINESE' },
        { key: 'en-US', name: 'English', tips: 'LANGUAGE_SWITCH.ENGLISH' },
        { key: 'ja', name: '日本語', tips: 'LANGUAGE_SWITCH.JAPANESE' },
        { key: 'zh-HK', name: '繁體中文', tips: 'LANGUAGE_SWITCH.TRADITIONAL_CHINESE' },
        { key: 'zh-TW', name: '繁體中文', tips: 'LANGUAGE_SWITCH.TRADITIONAL_CHINESE' }
    ];

    transform(lang: string, showSelectedLanguageStr?: boolean): string {
        return this.languages.find(language => language.key.toLowerCase() === lang?.toLowerCase())?.[showSelectedLanguageStr ? 'tips' : 'name'];
    }
}
