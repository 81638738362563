export const menuTranslate = {
    HOME: 'ホーム',
    ASIDE_NAV_MENUS: {
        APPS: {
            TITLE: 'アプリ'
        }
    },
    USER_PROFILE_MENUS: {
        INFOMATION: {
            TITLE: '個人情報'
        },
        ACCOUNT_SECURITY: {
            TITLE: 'アカウントセキュリティ',
            CHANGE_PASSWORD: {
                TITLE: 'パスワードの設定'
            },
            BIND_PHONE: {
                TITLE: '携帯電話をバインドする'
            },
        },
    },
    ASIDE_FOOTER_MENUS: {
        ACCOUNT_MANAGEMENT: {
            TITLE: 'アカウント管理',
            ADMIN_USER: {
                TITLE: '管理者アカウント'
            },
            OTHER_USER: {
                TITLE: 'その他のアカウント'
            },
        },
        ORGANIZATIONAL_STRUCTURE: {
            TITLE: '組織スキーマ',
            EMPLOYEES_POSTS: {
                TITLE: '人員と職務',
                EMPLOYEES: {
                    TITLE: 'ユーザ管理'
                },
                POSTS: {
                    TITLE: '職務管理'
                }
            },
            ORGANIZATIONS_DEPARTMENTS: {
                TITLE: '組織と部門',
                DEPARTMENTS: {
                    TITLE: '部門管理'
                },
                ORGANIZATIONS: {
                    TITLE: '組織管理'
                }
            }
        },
        SYSTEM_SETTING: {
            TITLE: 'システム設定',
            WECHAT_MP_CONFIG: {
                TITLE: '微信公衆番号の配置'
            },
            WECHAT_OPEN_CONFIG: {
                TITLE: '微信オープンプラットフォームの構成'
            }
        },
    },
}