export const authTranslate = {
    LOGIN_BY_WECHAT_QRCODE: {
        TITLE: 'WeChat scan code login',
        RELOAD_BTN_TEXT: 'Refresh the QR code',
        ERROR_MESSAGE: {
            RELOAD_ERROR: 'Initialization of WeChat QR code failed, please try again!',
            WECHAT_NOT_REGISTER: 'At present, WeChat has not registered or bound a mobile phone number, do you need to go to the WeChat registration page or log in with an account?',
            CODE_USED: 'The current identity has expired, please scan the code again to log in...',
            DEFAULT_TEXT: 'The background service is not started or the WeChat platform information is not configured, please contact the administrator!',
            WECHAT_NOT_REGISTER_ERROR: 'At present, the WeChat signal is not registered, please ask whether to register with the WeChat signal'
        }
    },
    LOGIN_BY_ACCOUNT_AND_PASSWORD: {
        TITLE: 'Account password login'
    },
    PASSWORD: {
        TITLE: '8 The 60-bit character contains only numbers and alphabetic characters',
        PLACEHOLDER: 'Enter a password...',
        REQUIRED_ERROR_MESSAGE: 'Password cannot be empty!',
        PATTERN_ERROR_MESSAGE: 'Password format is not correct, please check!',
        PATTERN_TOOLTIPS: {
            LENGTH_VALIDATE_MSG: 'Length of {{passwordMinimumLength}}~60 characters',
            REQUIRE_START_TEXT: 'At least {{value}} included',
            NUMBER_TEXT: 'number',
            LOWER_CASE_TEXT: 'lowercase letters',
            SYMBOLS_TEXT: 'special symbols',
            UPPER_CASE_TEXT: 'uppercase letters',
            REQUIRE_ALL_RULES_TEXT: 'Must contain uppercase letters/lowercase letters/numbers and special symbols',
            SUPPORTED_CHARACTERS_TEXT: 'Only uppercase letters, lowercase letters, numbers and special symbols are supported',
        }
    },
    VERIFY_CODE: {
        TITLE: 'Verification code',
        PLACEHOLDER: 'Enter the SMS code...',
        SEND_BTN_TEXT: 'Send',
        SEND_BTN_FULL_TEXT: 'Send',
        GET_CODE_ERROR_MESSAGE: 'Failed to get captcha code!',
        REQUIRED_ERROR_MESSAGE: 'The SMS code cannot be empty',
        PATTERN_ERROR_MESSAGE: 'The captcha is malformed',
        SEND_ERROR: 'Failed to send verification code:',
        SENDING_TEXT_MESSAGES_TOO_FREQUENTLY_PLEASE_TRY_AGAIN_LATER: 'Send SMS code too frequently, please try again later',
    },
    MOBILE_PHONE: {
        TITLE: 'Phone number',
        PLACEHOLDER: 'Enter your phone number...',
        REQUIRED_ERROR_MESSAGE: 'The phone number cannot be empty',
        PATTERN_ERROR_MESSAGE: 'The phone number format is incorrect',
        EXISISTED_ERROR_MESSAGE: 'Phone number has been registered, please change your phone number or login it!',
        NOT_EXISIST_ERROR_MESSAGE: 'Your phone number is not registered, please change it or register it first! ',
    },
    ACCOUNT: {
        TITLE: 'Account',
        PLACEHOLDER: 'Enter an account number...',
        REQUIRED_ERROR_MESSAGE: 'Account number cannot be empty',
        PATTERN_ERROR_MESSAGE: 'The account format is incorrect',
    },
    RESET_PASSWORD: {
        TITLE: 'Reset your password',
        RESET_BTN_TEXT: 'Confirm the reset',
        SUCCESS_MESSAGE: 'Reset successful',
        ERROR_MESSAGE: 'Reset failed:'
    },
    LOGIN_BY_ACCOUNT: {
        TITLE: 'Account login',
    },
    LOGIN_BY_MOBILE_AND_VERIFY: {
        TITLE: 'Verification code login',
    },
    LOGIN_BY_MOBILE_AND_PASSWORD: {
        TITLE: 'Password login',
    },
    LOGIN_BY_WECHAT_MP: {
        TITLE: 'WeChat authorized login',
        LOGIN_BTN_TEXT: 'Authorize logins',
        ERROR_MESSAGE: {
            NOT_CONFIG: 'WeChat public platform information is not configured, please contact customer service or administrator!',
            CONFIG_ERROR: 'WeChat configuration data is wrong, please contact customer service or administrator!',
            WECHAT_NOT_REGISTER: 'The WeChat is not registered yet, please use your mobile phone number or other WeChat to log in!',
            DEFAULT_TEXT: 'WeChat login failed, please refresh and reauthorize login'
        }
    },
    LOGIN_BY_WECHAT: {
        TITLE: 'WeChat login'
    },
    LOGIN_BY_PHONE: {
        TITLE: 'Phone login'
    },
    RESPONSE_ERROR_MESSAGE: 'Login failed:',
    REGISTER_ERROR_MESSAGE: 'Registration failed:',
    CHANGE_ERROR_MESSAGE: 'Modification failed:',
    AUTO_LOGIN_TEXT: 'Sign in automatically',
    FORGOT_PASSWORD_TEXT: 'Forgot password',
    LOGIN_TEXT: 'login',
    LOGOUT_TEXT: 'Sign out',
    RELOAD_TEXT: 'Refresh retry',
    METHOD_ERROR_MESSAGE: 'Failed to get available login methods ~',
    OTHER_LOGIN_METHOD_TEXT: 'Other login methods~',
    USER_NOT_EXIST_EROOR_TEXT: 'The current account does not exist Oh~',
    INFOMATION: {
        ERROR_MESSAGE: 'Failed to obtain account information, please refresh and try again',
        WELECOME_TEXT: 'Welcome back ~',
        HOME_BTN_TEXT: 'Personal Center',
    },
    WECHAT_AUTHORIZATION_SUCCESS: 'WeChat authorization successful!',
    AUTHORIZATION: 'authorization',
    QRCODE: 'Scan the code',
    FAILED_TO_BIND_WECHAT: 'Binding WeChat failed:',
    FAILED_TO_UNBIND_WECHAT: 'Unbind WeChat failed:',
    FAILED_TO_BIND: 'Binding failed:',
    WECHAT_CODE_VERIFICATION_FAILED_PLEASE_TRY_AGAIN: 'WeChat code verification failed, please re-',
    CURRENT_ACCOUNT_IDENTITY: 'Current identity',
    FAILED_TO_GET_LOGIN_REGISTRATION_METHOD: 'Failed to get login/registration method ~',
    FAILED_TO_OBTAIN_PERSONAL_INFORMATION: 'Failed to get personal information!',
    SIGN_OUT_AND_SIGN_IN_SUCCESSFULLY: 'Log out successfully',
    OTHER_REGISTRATION_OR_LOGIN_METHODS: 'Other {{value}} ways',
    REGISTRATION_TEXT: 'Register',
    REGISTER_BTN_TEXT: 'Create new account',
    SIGN_UP_NOW: 'Sign up now',
    LOG_IN_IMMEDIATELY: 'Sign in now',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    DONT_HAVE_AN_ACCOUNT_YET: "Don't have an account yet?",
    MOBILE_NUMBER_REGISTRATION: {
        TITLE: 'Mobile phone registration'
    },
    WECHAT_REGISTRATION: {
        TITLE: 'WeChat registration'
    },
    CAPTCHA_VERIFY_CODE: 'Captcha',
    CAPTCHA_VERIFY_CODE_PLACEHOLDER: 'Enter the captcha code',
    CAPTCHA_VERIFY_CODE_NULL_ERROR: 'The captcha cannot be empty!',
    CAPTCHA_VERIFY_CODE_INVALID_ERROR: 'The captcha code must have at least 4 digits!',
    MESSAGE_VERIFY_CODE: 'SMS code',
    REFERRAL_CODE: 'Referral code',
    REFERRAL_CODE_PLACEHOLDER: 'Enter the referral code',
    SECOND: 'seconds',
    PHONE_NULL_TIPS: 'Enter your phone number first!',
    PHONE_NOT_REGISTER_TIPS: 'The current phone number is not registered!',
    PHONE_HAS_REGISTERED_TIPS: 'The current mobile phone number is registered!',
    CAPTCHA_VERIFY_CODE_ERROR_TIPS: 'The captcha code is incorrect, please fill in again.',
    CAPTCHA_VERIFY_CODE_NULL_TIPS: 'The captcha code cannot be empty, please fill in.',
    AGREEMENT_NOT_CONFIRM_TIPS: 'You need to agreed with the user terms first.',
    LOGGING_IN_TIPS: 'Sign in',
    SIGN_IN_SUCCESSFULLY: 'Login successful',
    PASSWORD_TEXT: 'password',
    ACCOUNT_LOGIN: 'Account login',
    LOGIN_BY_OTHER_WECHAT_ACCOUNT: 'Other WeChat logins',
    ACCOUNT_REGISTER: {
        TITLE: 'Phone registration',
        EXISISTED_ERROR_MESSAGE: 'Phone number has been registered!',
        NOT_EXISIST_ERROR_MESSAGE: 'Phone number is not registered!',

    },
    REGISTERING: 'Registering',
    REGISTRATION_SUCCESS: 'Registration successful',
    WECHAT_AUTHORIZED_REGISTRATION: 'WeChat authorized registration',
    BIND_AND_REGISTER: 'Bind and register',
    WECHAT_SCAN_CODE_REGISTRATION: 'WeChat scan code registration',
    BIND_MOBILE_NUMBER: 'Bind the phone number',
    WECHAT_REGISTERED_AND_AUTO_LOGGING_TIPS: 'The WeChat has been registered for you to automatically log in...',
    IDENTITY_EXPIRED_AND_REAUTHORIZE_TIPS: 'The current identity has expired, please reauthorize...',
    AGREE_BY_ACTION_TYPE: '{{action}} to agree',
    I_HAVE_READ_AND_AGREE_TIPS: 'I have read and agree',
    WECHAT_SCAN_CODE_AUTHORIZATION: 'WeChat scan code authorization',
    WECHAT_AUTHORIZATION: 'WeChat authorization',
    WECHA_PLATFORM_NOT_CONFIRGURED_ERROR_TIPS: 'The background is not configured with WeChat public platform information, please contact the administrator!',
    CURRENT_WECHAT_AUTHORIZATION_FAILED_TIPS: 'The current WeChat status has expired, whether to reauthorize',
    REAUTHORIZE: 'Reauthorize',
    GET_CURRENT_WECHAT_ID_FAILED_TIPS: 'Did not get the WeChat identity, please try again!',
    SUCCESSFULLY_BIND_WECHAT: 'Binding WeChat successfully!',
    AUTHORIZE_SUCCESSFULLY_AND_AUTO_LOGGING_TIPS: 'Authorization successful, for you to log in...',
    WECHAT_PLATFORM_SCRET_ERROR_TIPS: 'The current background is not configured with the correct WeChat key, and weChat authorized login cannot be performed!',
    WECHAT_NOTREGISTERED_ERROR_TIPS: 'WeChat is not currently registered, do you need to go to the WeChat registration page or Refresh to try again?',
    WECHAT_NOTREGISTERED_OR_NOT_FOUND_PHONE_ERROR_TIPS: 'At present, WeChat has not registered or bound a mobile phone number, do you need to go to the WeChat registration page or log in with an account?',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS_FOR_PHONE_LOGIN_ENABLE: 'Currently WeChat is not authorized, do you need WeChat authorized login or login using an account?',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS: 'Currently WeChat is not authorized, do you need WeChat authorized login or refresh to try again?',
    NEW_PASSWORD: 'New password',
    PLEASE_READ_AND_CHECKED_THE_TERMS: 'Please read and tick the terms!',
    PHONE_OR_CAPTCHA_CODE_NULL: 'Enter your phone number or captcha first!',
    OLD_PASSWORD: 'The original password',
    OLD_PASSWORD_PLACEHOLDER: 'Enter your original password',
    RESETING: 'Resetting',
    RESET_SUCCESSFULLY: 'Reset successful',
    MESSAGE_CODE_SEND_FAILED: 'Failed to send verification code',
    EMAIL_CODE_SEND_FAILED: 'Email message failed to be sent',
    EMAIL_VERIFY_CODE: 'Email message',
    EMAIL_NULL_TIPS: 'Please enter your email address!',
    EMAIL_NOT_REGISTER_TIPS: 'The current email address is not registered!',
    EMAIL_HAS_REGISTERED_TIPS: 'Current email address is registered!',
    EMAIL: {
        TITLE: 'Email address',
        PLACEHOLDER: 'Please enter your message...',
        REQUIRED_ERROR_MESSAGE: 'email address cannot be empty',
        PATTERN_ERROR_MESSAGE: 'email address format is not correct',
        EXISISTED_ERROR_MESSAGE: 'The email address has been registered, please change the mailbox or login it!',
        NOT_EXISIST_ERROR_MESSAGE: 'Your email address is not registered, please change your email address or register first!',
    },
    REGISTRATION_BY_PHONE: {
        TITLE: 'Phone registration'
    },
    LOGIN_BY_EMAIL: {
        TITLE: 'Email Login'
    },
    REGISTRATION_BY_EMAIL: {
        TITLE: 'Email registration'
    },
    BIND_EMAIL: 'Bind email',
    EMAIL_VERIFY_CODE_PLACEHOLDER: 'Please enter the email verification code',
    SEND_EMAIL_VERIFY_CODE_BTN_TEXT: 'Send email verification code',
    OLD_EMAIL_CAPTCHA_ERROR: 'The old mailbox image verification code was entered incorrectly',
    NEW_EMAIL: 'New email',
    OLD_EMAIL: 'Old email',
    LOGIN_BY_NAME: {
        TITLE: 'Account login',
    },
    LOGIN_NAME: {
        TITLE: 'Account name',
        PLACEHOLDER: 'Please enter your account name...',
        REQUIRED_ERROR_MESSAGE: 'Account name cannot be empty!',
        PATTERN_ERROR_MESSAGE: 'The account name is not in the correct format!',
        NOT_EXISIST_ERROR_MESSAGE: 'The current account does not exist, please check the account name or register first!',
    },
};
