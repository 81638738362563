import { Injectable } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';

@Injectable({
    providedIn: 'root'
})
export class MessageAlertService {

    constructor(
        private toastService: ToastService
    ) { }

    send(type, message) {
        console.log(type);
        if (type === 'success') {
            this.toastService.hide();
            this.toastService.success(message, 1500);
        }
        if (type === 'error') {
            this.toastService.hide();
            this.toastService.fail(message, 1500);
        }
    }

}
