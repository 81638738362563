import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UrlHelperService {
    imgReg: RegExp = /\.(png|jpg|gif|jpeg|webp|bmp|pcx|tif|tga|exif|fpx|psd|cdr|pcd|svg|dxf|ufo|eps|ai|hdri|raw|wmf|flic|emf|ico|jfif)$/;
    videoReg: RegExp = /\.(wmv|asf|asx|rm|rmvb|mp4|3gp|mov|m4v|avi|dat|mkv|vob)$/;
    pdfReg: RegExp = /\.(pdf)$/;
    svgReg: RegExp = /\.(svg)$/;
    absoluteUrlReg: RegExp = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/;
    svgFormtReg: RegExp = /\.(pdf|css|csv|doc|html|javascript|xml|zip)$/;

    constructor() { }

    getFileFormat(url: string) {
        if (url.lastIndexOf('.') > 0) {
            return url.slice(url.lastIndexOf('.') + 1);
        }
        return '未知';
    }

    isImage(url) {
        return this.imgReg.test(url);
    }

    isVideo(url) {
        return this.videoReg.test(url);
    }

    isPDF(url) {
        return this.pdfReg.test(url);
    }

    isSvg(url) {
        return this.svgReg.test(url);
    }

    isFile(url) {
        return !this.isSvg(url) && !this.isImage(url) && !this.isVideo(url);
    }

    isOssUrl(url: any) {
        return this.absoluteUrlReg.test(url) && url.indexOf('.oss') !== -1;
    }

    isAbsoluteUrl(url: any) {
        return this.absoluteUrlReg.test(url);
    }

    toSvgFormat(url) {
        if (this.svgFormtReg.test(url)) {
            return this.getFileFormat(url);
        }
        return 'file';
    }

}
