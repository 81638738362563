import { Dayjs } from 'dayjs/esm';
import { LinkType } from "./menu.model";

export enum siteAnnouncementStatus {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
}

export enum siteAnnouncementVisibility {
    PUBLIC = 'PUBLIC',
    INTERNAL = 'INTERNAL',
}

export class SiteAnnouncement {
    public id?: number;
    public siteId?: string;
    public title?: string;
    public titleTranslation?: string;
    public content?: string;
    public contentTranslation?: string;
    // What type of link, e.g. PAGE or URL.
    linkType?: LinkType;
    // The id of the page, if linkType is PAGE.
    linkedPageId?: string;
    linkedPageSlug?: string;
    // Params of the linked page if any, in JSON format.
    linkedPageParams?: string;
    // The external url if linkType is URL.
    linkedUrl?: string;
    // Options of the link if any, in JSON format, e.g. if open in a new window etc.
    linkOptions?: string;
    visibility?: siteAnnouncementVisibility = siteAnnouncementVisibility.PUBLIC;
    status?: siteAnnouncementStatus;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    constructor(siteAnnouncement?: SiteAnnouncement
    ) {
        if (siteAnnouncement) {
            for (const key in siteAnnouncement) {
                if (key) {
                    this[key] = siteAnnouncement[key];
                }
            }
        }
    }
}
