import { Component, Input } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
    selector: 'app-forms-block',
    templateUrl: './forms.component.html',
    styleUrls: ['./forms.component.scss']
})
export class FormsBlockComponent {
    @Input() title: string = '';
    @Input() titleRenderOption = {};
    @Input() titleTranslation = '';
    @Input() forms = [];
    @Input() renderOptions: any = {};
    @Input() section: string;
    constructor(
        private toast: ToastService,
    ) { }
}
