export enum CountryCodeEnum {
    CN = 'CN',
    JP = 'JP'
}
export enum CurrencyCodeEnum {
    USD = 'USD',
    CNY = 'CNY',
    HKD = 'HKD',
    SGD = 'SGD',
    JPY = 'JPY'
}
