import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../../../app.constants';
import { IProduct } from '../../../shared/model/product.model';
import { ISeries } from '../../../shared/model/series.model';
import { ShopContextService } from '../../../shared/service/shop-context.service';

@Injectable({ providedIn: 'root' })
export class ProductsService {

    getShopResourceUrl() {
        return `${API_SERVICE_PREFIX}/store/api/shop/${this.shopContextService.shopId}`;
    }

    constructor(
        private http: HttpClient,
        public shopContextService: ShopContextService,
    ) { }

    querySeries(req?: any): Observable<HttpResponse<ISeries[]>> {
        return this.http
            .get<ISeries[]>(`${this.getShopResourceUrl()}/series`, { params: req, observe: 'response' });
    }

    queryProducts(req?: any): Observable<HttpResponse<IProduct[]>> {
        if (this.shopContextService.shopSetting?.hideSoldOutProduct) {
            req = {
                'stock.greaterThanOrEqual': 0,
                ...req
            };
        }
        return this.http
            .get<IProduct[]>(`${this.getShopResourceUrl()}/products`, { params: req, observe: 'response' });
    }
}
