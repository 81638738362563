import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren, afterNextRender } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { TranslationService } from '../../../i18n';
import { Menu, LinkType, MenuItem } from '../../shared/model/menu.model';
import { TargetEntityTypeEnum, TargetTypeEnum } from '../../core/model/mobile-config.model';
import { NavigationLayout } from '../../shared/model/navigation-layout.enum';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { languageConfig, getSelectedLang, setLang } from '../../shared/pipes/selected-language-helper.pipe';
import { PostConfig } from '../../builtin-pages/post-list/_model/post.model';
import { getMenuNativePath, getSpecialPagePath } from '../../core/utilities/page-route-util';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { isPlatformServer } from '@angular/common';

@Component({
    selector: 'app-navigation-block',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
    @ViewChild('horizationMenuContaniner', { static: false }) horizationMenuContaniner: ElementRef;
    @ViewChild('horizationMenuContent', { static: false }) horizationMenuContent: ElementRef;
    @ViewChildren('horizationMenuItem') horizationMenuItems: QueryList<ElementRef>;
    @Input() fixedInBottom = false;
    @Input() logo = '';
    @Input() set siteMenu(value: Menu) {
        this._siteMenu = jsonParse(JSON.stringify(value));
        this._showSiteMenu = jsonParse(JSON.stringify(value));
    };
    @Input() set renderOptions(value: any) {
        if (value) {
            if (value?.navigation?.background) {
                const tempValue = value?.navigation?.background || {};
                const backgroundValue = jsonParse(tempValue?.data);
                const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
                this._navigationBackgroundColor = backgroundColor;
            }
            this._renderOptions = value;
        }
    }
    @Input() set data(value: any) {
        if (value) {
            if (value?.navigation?.background) {
                const tempValue = value?.navigation?.background || {};
                const backgroundValue = jsonParse(tempValue?.data);
                const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
                this._navigationBackgroundColor = backgroundColor;
            }
            this._data = value;
        }
    }
    @Input() isLogin = false;
    @Input() userName = '';
    @Input() userAvatarUrl = '';
    @Input() navigationLayoutRenderOptions: any;
    @ViewChild('loginContainerRef', { static: false }) loginContainerRef: ElementRef;
    @ViewChild('languageNgbDropdown', { static: false }) languageNgbDropdownRef: NgbDropdown;
    _navigationBackgroundColor: string;
    _renderOptions: any = {};
    _data: any = {};
    _siteMenu = new Menu();
    _showSiteMenu = new Menu();
    currentMenuItem: MenuItem;
    countries: {
        langKey: string;
        nameKey: string;
    }[] = [];
    selectedLang = getSelectedLang;
    subs: Subscription[] = [];
    isMobile = false;
    logoPositionStatic = true;
    contentExpansionHeight = '0.5rem';
    menuLeftPadding: string;

    constructor(
        public translationService: TranslationService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private currentDeviceService: CurrentDeviceService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        afterNextRender(() => {
            this.detectCurrentMenuItem();
            this.recalculateClientLayout();
            const sub2 = this.translateService.onLangChange.subscribe(e => {
                setTimeout(() => {
                    this.calcMenuWidthAndHiddenOutRangeItems();
                }, 100);
            });
            let sub3 = null;
            sub3 = fromEvent(window, 'resize').pipe(debounceTime(200))
                .subscribe(() => {
                    this.recalculateClientLayout();
                });
            this.subs = this.subs.concat(sub2, sub3);
            this.routerActiveWatch();
        });
     }

    ngOnInit(): void {
        this.menuLeftPadding = this.calcMenuLeftPadding();
        const langs = this.translationService.getSiteLanguages();
        this.countries = languageConfig?.filter(lang => {
            return langs?.supportedLanguages?.find(languageItem => lang?.langKey?.toLowerCase() === languageItem?.toLowerCase());
        });
    }

    private recalculateClientLayout() {
        this.isMobile = this.currentDeviceService.isMobile();
        this.logoPositionStatic = false;
        this.menuLeftPadding = this.calcMenuLeftPadding();
        this.contentExpansionHeight = this.getContentExpansionHeight();
        this.calcMenuWidthAndHiddenOutRangeItems();
        this.changeDetectorRef.detectChanges();
    }

    private calcMenuLeftPadding() {
        if (isPlatformServer(this.platformId)) {
            return '4rem';
        }

        if (!this.checkIsAsideAndOnDesktop()) {
            return "";
        }

        let loginContainerWidth = this.loginContainerRef?.nativeElement?.clientWidth;
        if (!loginContainerWidth) {
            return '4rem';
        }
        let calcExpression = loginContainerWidth + ((loginContainerWidth < 100) ? ('px + 2rem') : ('px'));
        return "calc(" + calcExpression + ")";
    }

    setLang(langKey: string) {
        setLang(langKey, this.translationService);
    };

    checkIsAsideAndOnDesktop() {
        return !this.isMobile && (
            (this.navigationLayoutRenderOptions?.desktopNavigation === NavigationLayout?.TOP_BAR) || !this.navigationLayoutRenderOptions?.desktopNavigation
        )
    }

    navigateMenuPage(item: MenuItem) {
        let path = this.getMenuNativePath(item);
        if (![undefined, null]?.includes(path)) {
            let queryParams: any = {};
            if (item.linkType === LinkType.URL) {
                if (jsonParse(item?.linkOptions)?.targetType === 'NEW_WINDOW') {
                    window.open(item.linkedUrl);
                    return;
                } else {
                    location.href = item.linkedUrl;
                }
            }
            if (jsonParse(item?.linkedPageParams)?.special) {
                queryParams = this.getSpecialPageQueryParams(jsonParse(item?.linkedPageParams));
            }
            if (jsonParse(item?.linkedPageParams)?.targetEntityType === TargetEntityTypeEnum.POST_CATEGORY) {
                if (!queryParams) {
                    queryParams = {};
                }
                queryParams.categoryId = jsonParse(item?.linkedPageParams)?.targetEntityId || jsonParse(item?.linkedPageParams)?.paramsId;
            }
            this.router.navigate([path], {
                queryParams: {
                    ...queryParams,
                    enableHeaderBackBtn: false,
                }
            });
        } else {
            if (item?.children?.length) {
                this.navigateMenuPage(item?.children?.[0]);
            }
        }
    }

    checkCurrentRouterIsActive(item: MenuItem, routerUrl: string): boolean {
        if (!item) {
            return false;
        }
        if (routerUrl === undefined) {
            if (item?.children?.length) {
                return item?.children?.some(menuItem => {
                    if (this.getMenuNativePath(menuItem)) {
                        let result = this.checkCurrentRouterIsActive(item, this.getMenuNativePath(menuItem));
                        if (result) {
                            return result;
                        }
                    }
                    return menuItem?.children?.some(menuChildItem => this.checkCurrentRouterIsActive(item, this.getMenuNativePath(menuChildItem)));
                });
            }
            return false;
        }
        return (!['', '/', '/home']?.includes(routerUrl)) ? this.router.isActive(routerUrl, false) : ['', '/', '/home']?.includes(this.router.url?.split(/\?/)?.[0]);
    }

    getMenuNativePath = getMenuNativePath;

    getSpecialPagePath = getSpecialPagePath;

    private getContentExpansionHeight() {
        switch ((this._data || this._renderOptions)?.navigation?.contentExpansionHeightSize) {
            case 'SMALL':
                return '0.25rem';
            case 'MIDDLE':
                return '0.5rem';
            case 'BIG':
                return '0.75rem';
            case 'LARGE':
                return '1rem';
            default:
                return '0.5rem';
        }
    }

    checkMenuIsActive(item: MenuItem): boolean {
        let isActive = this.checkCurrentRouterIsActive(item, this.getMenuNativePath(item));
        if (isActive) {
            return isActive;
        }
        if (item?.children?.length) {
            return item?.children?.some(menuItem => {
                let result = this.checkCurrentRouterIsActive(menuItem, this.getMenuNativePath(menuItem));
                if (result) {
                    return result;
                }
                return menuItem?.children?.some(menuChildItem => this.checkCurrentRouterIsActive(menuChildItem, this.getMenuNativePath(menuChildItem)));
            });
        }
        return false;
    }

    getSpecialPageQueryParams(linkParams: any) {
        if (linkParams.targetType === TargetTypeEnum.ENTITY) {
            if ([TargetEntityTypeEnum.POST_LIST, TargetEntityTypeEnum.POST_CATEGORY].includes(linkParams.targetEntityType)) {
                return {
                    ...(linkParams.postConfig || new PostConfig),
                    enableHeaderBackBtn: false,
                };
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.POST) {
                return {
                    enableHeaderBackBtn: false,
                    ...linkParams.postConfig
                };
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.POST_CATEGORY) {
                const params: any = linkParams.postConfig;
                params.categoryId = linkParams.targetEntityId;
                params.enableHeaderBackBtn = false;
                return params;
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.PRODUCT_CATEGORY) {
                return {
                    enableHeaderBackBtn: false,
                    categoryId: linkParams.targetEntityId
                };
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.PRODUCT) {
                return {
                    enableHeaderBackBtn: false,
                    id: linkParams.targetEntityId
                };
            }
        }
    }

    calcMenuWidthAndHiddenOutRangeItems() {
        if (this.horizationMenuContent?.nativeElement && !this.isMobile) {
            // 获取导航栏元素
            let navbar = this.horizationMenuContaniner?.nativeElement;
            let navbarContent = this.horizationMenuContent?.nativeElement;
            // 设置最大宽度
            let maxWidth = navbar?.offsetWidth;
            let currentMenuWidth = navbarContent?.scrollWidth;
            // 判断是否超过最大宽度
            if ((currentMenuWidth - maxWidth) > 3) {
                let tempChildren = [];
                let tempSiteMenu = jsonParse(JSON.stringify(this._siteMenu));
                let moreMenuItem: MenuItem = jsonParse(JSON.stringify(this._showSiteMenu?.items?.find(item => !item?.id && (item?.title === '更多'))));
                // 创建“更多”菜单
                if (!moreMenuItem) {
                    moreMenuItem = new MenuItem();
                    moreMenuItem.enabled = true;
                    moreMenuItem.title = '更多';
                    moreMenuItem.titleTranslation = JSON.stringify({
                        'zh-CN': '更多',
                        'zh-TW': '更多',
                        'en-US': 'More',
                        'ja': 'もっと見る',
                    });
                    this._showSiteMenu.items.push({
                        ...moreMenuItem,
                        children: [{}]
                    });
                }
                setTimeout(() => {
                    // let currentMenuWidth = navbarContent?.scrollWidth;
                    // 获取导航栏菜单项
                    let menuItems = this.horizationMenuItems?.map(item => item?.nativeElement);
                    let menuItemTotalWidth = 0;
                    menuItems.forEach(item => menuItemTotalWidth += item.scrollWidth);
                    if (menuItemTotalWidth <= (maxWidth - 3)) {
                        return;
                    }
                    // 移动超出宽度的菜单项到下拉菜单中
                    for (let i = menuItems.length - 2; i >= 1; i--) {
                        if (menuItemTotalWidth > (maxWidth - 3)) {
                            menuItemTotalWidth = menuItemTotalWidth - menuItems[i].scrollWidth;
                            const menuItemId = menuItems[i].getAttribute('data-menu-id');
                            const menuItemIndex = tempSiteMenu?.items?.findIndex(item => item?.id === menuItemId);
                            tempChildren.push(tempSiteMenu?.items[menuItemIndex]);
                            tempSiteMenu?.items.splice(menuItemIndex, 1);
                        } else {
                            break;
                        }
                    }
                    if (tempChildren?.length) {
                        moreMenuItem.children = tempChildren;
                    }
                    if (!tempSiteMenu.items[tempSiteMenu.items?.length - 1]?.id) {
                        tempSiteMenu.items.pop();
                    }
                    tempSiteMenu.items.push(moreMenuItem);
                    this._showSiteMenu = tempSiteMenu;
                    this.detectCurrentMenuItem();
                }, 200);
            }
        } else {
            this._showSiteMenu = jsonParse(JSON.stringify(this._siteMenu));
            this.detectCurrentMenuItem();
        }
    }

    private routerActiveWatch() {
        // 过滤 取消导航事件，避免loading无法隐藏；增加节流避免来回闪烁
        this.router.events.pipe(filter(event => {
                return (event instanceof NavigationEnd) ||
                    (event instanceof NavigationCancel);
            })).subscribe(() => {
                this.detectCurrentMenuItem();
            });
    }

    private detectCurrentMenuItem() {
        if (this._showSiteMenu?.items?.length) {
            this._showSiteMenu.items.forEach(item => {
                if (this.checkMenuIsActive(item)) {
                    this.currentMenuItem = item;
                }
            });
        }
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.subs?.forEach(sub => sub?.unsubscribe);
    }
}
