export const menuTranslate = {
    HOME: '首頁',
    ASIDE_NAV_MENUS: {
        APPS: {
            TITLE: '應用'
        }
    },
    USER_PROFILE_MENUS: {
        INFOMATION: {
            TITLE: '個人資料'
        },
        ACCOUNT_SECURITY: {
            TITLE: '帳號安全',
            CHANGE_PASSWORD: {
                TITLE: '设置密码'
            },
            BIND_PHONE: {
                TITLE: '綁定手機'
            },
        },
    },
    ASIDE_FOOTER_MENUS: {
        ACCOUNT_MANAGEMENT: {
            TITLE: '账号管理',
            ADMIN_USER: {
                TITLE: '管理员账号'
            },
            OTHER_USER: {
                TITLE: '其他帳號'
            },
        },
        ORGANIZATIONAL_STRUCTURE: {
            TITLE: '组织架构',
            EMPLOYEES_POSTS: {
                TITLE: '人员与职务',
                EMPLOYEES: {
                    TITLE: '人員管理'
                },
                POSTS: {
                    TITLE: '職務管理'
                }
            },
            ORGANIZATIONS_DEPARTMENTS: {
                TITLE: '組織與部門',
                DEPARTMENTS: {
                    TITLE: '部门管理'
                },
                ORGANIZATIONS: {
                    TITLE: '組織管理'
                }
            }
        },
        SYSTEM_SETTING: {
            TITLE: '系统设置',
            WECHAT_MP_CONFIG: {
                TITLE: '微信公众号配置'
            },
            WECHAT_OPEN_CONFIG: {
                TITLE: '微信開放平臺配寘'
            }
        },
    },
};