import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IShoppingCartItem } from '../_model/shopping-cart.model';
import { API_SERVICE_PREFIX } from 'src/app/app.constants';
import { map, mergeMap, take } from 'rxjs/operators';
import { ShopContextService } from '../../../shared/service/shop-context.service';

type ShoppingCartResponseType = HttpResponse<IShoppingCartItem>;
type ShoppingCartArrayResponseType = HttpResponse<IShoppingCartItem[]>;

@Injectable({ providedIn: 'root' })
export class ShoppingCartService {

    public resourceUrl = API_SERVICE_PREFIX + '/store/api/shopping-cart-items';

    getShopResourceUrl() {
        return `${API_SERVICE_PREFIX}/store/api/shop/${this.shopContextService.shopId}/shopping-cart-items`;
    }

    constructor(
        private http: HttpClient,
        private shopContextService: ShopContextService,
    ) { }

    create(shoppingCartItem: IShoppingCartItem): Observable<ShoppingCartResponseType> {
        return this.http.post<IShoppingCartItem>(this.getShopResourceUrl(), shoppingCartItem, { observe: 'response' });
    }

    update(shoppingCartItem: IShoppingCartItem): Observable<ShoppingCartResponseType> {
        return this.http.put<IShoppingCartItem>(this.getShopResourceUrl(), shoppingCartItem, { observe: 'response' });
    }

    find(id: number): Observable<ShoppingCartResponseType> {
        return this.http
            .get<IShoppingCartItem>(`${this.getShopResourceUrl()}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<ShoppingCartArrayResponseType> {
        return this.http
            .get<IShoppingCartItem[]>(this.resourceUrl, { params: req, observe: 'response' });
    }

    customerQuery(customerId: number, req?: any): Observable<ShoppingCartArrayResponseType> {
        return this.http
            .get<IShoppingCartItem[]>(`${API_SERVICE_PREFIX}/store/api/shop/${this.shopContextService.shopId}/customers/${customerId}/shopping-cart-items`, { params: req, observe: 'response' });
    }

    delete(customerId: number, ids: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${customerId}?ids=${ids}`, { observe: 'response' });
    }
}
