<div id="builtinComboPage" [class]="pageContainerClass">
    <ng-container *ngIf="!enableLoading||!isLoading">
        <div id="builtinComboPageHeader" *ngIf="isMobile">
            <ng-container *ngFor="let pageBlock of layout?.blocks;">
                <ng-container
                    *ngIf="(!layoutRenderOptions?.mobileNavigation || (layoutRenderOptions?.mobileNavigation===NavigationLayout?.BOTTOM_TAB_BAR))">
                    <!-- 广告横幅 -->
                    <ng-container
                        *ngIf="(pageBlock.blockType === 'BANNER') && (pageBlock?.section === 'HEADER') && checkBannerEnableForCurrentPage(pageBlock)">
                        <app-banner-block class="app-version" [data]="pageBlock?.data"
                            [renderOptions]="pageBlock?.renderOptions">
                        </app-banner-block>
                    </ng-container>
                    <!-- 如果是头部的话 -->
                    <div *ngIf="pageBlock.blockType === 'HEADER'" class="position-relative"
                        [style.opacity]="loadSiteError?'0':'1'">
                        <div class="overflow-hidden">
                            <app-site-branding
                                *ngIf="(layoutBlock?.renderOptions?.headerType !== 'NONE') && layoutBlock?.renderOptions?.headerType && checkHeaderBackgroundEnableForCurrentPage(layoutBlock)"
                                class="app-version" [isMobile]="isMobile" [block]="pageBlock"
                                [renderOptions]="pageBlock?.renderOptions" [data]="pageBlock?.data"
                                [navigationHeight]="navigationRef?.clientHeight">
                            </app-site-branding>
                        </div>
                        <div class="navigation-menu w-100" [ngClass]="{
                            'position-relative':!checkHeaderBackgroundEnableForCurrentPage(layoutBlock),
                            'pc-version':(layoutRenderOptions?.mobileNavigation===NavigationLayout?.LEFT_SIDE_BAR)||(layoutRenderOptions?.mobileNavigation===NavigationLayout?.RIGHT_SIDE_BAR),
                            'position-absolute':(pageBlock?.renderOptions?.headerType !== 'NONE') && layoutBlock?.renderOptions?.headerType,
                    'position-bottom':(pageBlock?.renderOptions?.headerType !== 'NONE') && (pageBlock?.renderOptions?.navigation?.position?.bottom === 0)
                }" [style.top]="((pageBlock?.renderOptions?.headerType !== 'NONE') && (pageBlock?.renderOptions?.navigation?.position?.bottom !== 0)) ? '0':''"
                            [style.bottom]="((pageBlock?.renderOptions?.headerType !== 'NONE') && (pageBlock?.renderOptions?.navigation?.position?.bottom === 0)) ? '0':''"
                            #navigationRef>
                            <div class="logo-moblie-bg" *ngIf="renderHeaderBlocks && (pageBlock.blockType === 'HEADER')"
                                [ngStyle]="pageBlock?.renderOptions?.navigation"
                                [attr.data-skeleton-bgcolor]="!isMobile?'transparent':'#eeeeee'">
                                <div class="logo-moblie" [title]="'GENERAL.GO_TO_HOME_BTN_TEXT' | translate"
                                    style="cursor: pointer;" [routerLink]="['/']"
                                    *ngIf="pageBlock?.renderOptions?.logoVisible !== false">
                                    <p data-skeleton-bgcolor="white"><img [src]="(site?.iconUrl) | uploadUrlPrefix" />
                                    </p>
                                </div>
                                <div *ngIf="pageBlock?.renderOptions?.searchVisible !== false" class="search b-labr"
                                    routerLink="/search-page">
                                    <p class="search-content"><i class="iconfont icon-sousuo"></i>
                                        {{'GENERAL.ENTER_SEARCH_CONTENT' | translate}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngTemplateOutlet="renderCommonBlockRef;context:{pageBlock:pageBlock}">
                </ng-container>
            </ng-container>
        </div>
        <div id="builtinComboPageContent" class="body-color" data-skeleton-bgcolor="transparent">
            <div id="outer-width" data-skeleton-bgcolor="transparent">
                <div class="page-padding {{contentContainerClass}}">
                    <ng-container *ngFor="let pageBlock of page?.blocks">
                        <!-- 排除头部 -->
                        <ng-container *ngIf="pageBlock.blockType !== 'HEADER'">
                            <ng-container *ngTemplateOutlet="renderCommonBlockRef;context:{pageBlock:pageBlock}">
                            </ng-container>
                            <!-- 如果是其他视图 -->
                            <ng-container *ngTemplateOutlet="builtInCpnRef;context:{blockType:pageBlock?.blockType}">
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <app-nothing *ngIf="!page?.blocks?.length" [nothingText]="nothingText" [nothingImageClass]="'pt-5 pb-3 mt-5'"
            [nothingImage]="'/assets/icon/site-uploading.svg'"></app-nothing>
    </ng-container>
    <app-loading *ngIf="enableLoading && isLoading"></app-loading>
</div>

<ng-template #renderCommonBlockRef let-pageBlock="pageBlock">
    <ng-container
        *ngIf="(pageBlock.blockType === 'BANNER') && (pageBlock?.section === 'CONTENT') && checkBannerEnableForCurrentPage(pageBlock)">
        <app-banner-block [data]="pageBlock?.data" [renderOptions]="pageBlock?.renderOptions">
        </app-banner-block>
    </ng-container>
    <!-- 如果是轮播图的话 -->
    <ng-container *ngIf="pageBlock.blockType === 'SHOP_CAROUSEL'">
        <app-carousel-block [key]="pageBlock?.id" [renderOptions]="pageBlock?.renderOptions"
            [defaultData]="pageBlock?.data?.defaultData" [data]="pageBlock?.data">
        </app-carousel-block>
    </ng-container>
    <ng-container *ngIf="pageBlock.blockType === 'CAROUSEL'">
        <app-carousel-block [key]="pageBlock?.id" [renderOptions]="pageBlock?.renderOptions"
            [defaultData]="pageBlock?.data?.defaultData" [data]="pageBlock?.data">
        </app-carousel-block>
    </ng-container>
    <!-- 如果是公告的话 -->
    <ng-container *ngIf="pageBlock.blockType === 'ANNOUNCEMENT'">
        <div style="width: 100%;">
            <app-home-announcement-block [blockId]="pageBlock?.id" [renderOptions]="pageBlock?.renderOptions"
                [section]="pageBlock?.section" [data]="pageBlock?.data">
            </app-home-announcement-block>
        </div>
    </ng-container>
    <!-- 自定义 -->
    <ng-container *ngIf="pageBlock.blockType === 'ICON_BOXES'">
        <app-icon-boxes-block [diyContents]="pageBlock?.data" [title]="pageBlock?.title" [blockId]="pageBlock?.id"
            *ngIf="pageBlock?.enabled" [titleTranslation]="pageBlock?.titleTranslation"
            [renderOptions]="pageBlock?.renderOptions" [section]="pageBlock?.section">
        </app-icon-boxes-block>
    </ng-container>
    <!-- 如果是标签商品的话 -->
    <div class="labeledBox" *ngIf="pageBlock.blockType ==='LABEL_PRODUCTS'" style="width: 100%;">
        <app-label-products-block [title]="pageBlock?.title" [blockId]="pageBlock?.id" [data]="pageBlock?.data"
            [section]="pageBlock?.section" [titleTranslation]="pageBlock?.titleTranslation"
            [titleRenderOption]="pageBlock?.renderOptions?.title" [renderOptions]="pageBlock?.renderOptions">
        </app-label-products-block>
    </div>
    <!-- 如果是商品列表 -->
    <ng-container [ngSwitch]="pageBlock.blockType">
        <ng-container *ngSwitchCase="'BEST_SELLERS'">
            <!-- 热卖 -->
            <ng-container *ngTemplateOutlet="productListItemRef;context:{
            productsType:'BEST_SELLERS',
            pageBlock:pageBlock,
            queryType:{queryType: queryType.hotProduct}
        }">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'NEW_ARRIVAL'">
            <!-- 最新商品  -->
            <ng-container *ngTemplateOutlet="productListItemRef;context:{
            productsType:'NEW_ARRIVAL',
            pageBlock:pageBlock,
            queryType:{queryType: queryType.newProduct}
        }">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'GROUP_PURCHASING'">
            <!-- 团购商品  -->
            <ng-container *ngTemplateOutlet="productListItemRef;context:{
                productsType:'GROUP_PURCHASING',
                pageBlock:pageBlock,
                queryType:{isGroupBuyingProduct: true}
            }">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'PRESALE_PRODUCTS'">
            <!-- 预售商品  -->
            <ng-container *ngTemplateOutlet="productListItemRef;context:{
                productsType:'PRESALE_PRODUCTS',
            pageBlock:pageBlock,
            queryType:{queryType: queryType.preSaleProduct}
        }">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <!-- 特殊情况 -->
        </ng-container>
    </ng-container>
    <!-- 如果是系列商品 -->
    <div *ngIf="pageBlock.blockType === 'SERIES_PRODUCTS'" style="width: 100%;">
        <app-series-products-block [title]="pageBlock?.title" [blockId]="pageBlock?.id" [section]="pageBlock?.section"
            [titleTranslation]="pageBlock?.titleTranslation" [renderOptions]="pageBlock?.renderOptions">
        </app-series-products-block>
    </div>
    <!-- 如果是文章列表 -->
    <app-posts-block *ngIf="(pageBlock.blockType === 'POST_LIST')" [title]="pageBlock?.title" [data]="pageBlock?.data"
        [titleTranslation]="pageBlock?.titleTranslation" [renderOptions]="pageBlock?.renderOptions" [block]="pageBlock"
        [blockId]="pageBlock?.id" [section]="pageBlock?.section" [titleRenderOption]="pageBlock?.renderOptions?.title"
        [renderOptions]="pageBlock?.renderOptions">
    </app-posts-block>
    <!-- 如果是单个文章 -->
    <app-posts-block *ngIf="(pageBlock.blockType === 'POSTS')" [title]="pageBlock?.title" [data]="pageBlock?.data"
        [block]="pageBlock" [titleRenderOption]="pageBlock?.renderOptions?.title" [blockId]="pageBlock?.id"
        [titleTranslation]="pageBlock?.titleTranslation" [renderOptions]="pageBlock?.renderOptions"
        [section]="pageBlock?.section">
    </app-posts-block>
    <!-- 如果是表单 -->
    <app-forms-block *ngIf="pageBlock.blockType === 'FORMS'" [title]="pageBlock?.title" [forms]="pageBlock?.data"
        [titleRenderOption]="pageBlock?.renderOptions?.title" [titleTranslation]="pageBlock?.titleTranslation"
        [renderOptions]="pageBlock?.renderOptions" [section]="pageBlock?.section">
    </app-forms-block>
    <!-- 文字链接导航 -->
    <ng-container *ngIf="pageBlock.blockType === 'TEXT_LINKS'">
        <app-text-links [data]="pageBlock?.data" [section]="pageBlock?.section" *ngIf="pageBlock?.enabled"
            [blockId]="pageBlock?.id" [renderOptions]="pageBlock?.renderOptions">
        </app-text-links>
    </ng-container>
    <!-- 照片墙 -->
    <ng-container *ngIf="pageBlock.blockType === 'IMAGE_GRID'">
        <app-image-grid-block [data]="pageBlock?.data" [title]="pageBlock?.title" [blockId]="pageBlock?.id"
            *ngIf="pageBlock?.enabled" [titleTranslation]="pageBlock?.titleTranslation" [section]="pageBlock?.section"
            [renderOptions]="pageBlock?.renderOptions" [section]="pageBlock?.section"></app-image-grid-block>
    </ng-container>
    <!-- EMBED -->
    <ng-container *ngIf="pageBlock.blockType === 'EMBED'">
        <app-iframe-block [url]="pageBlock?.data?.url" [blockId]="pageBlock?.id" *ngIf="pageBlock?.enabled"
            [renderOptions]="pageBlock?.renderOptions" [section]="pageBlock?.section"></app-iframe-block>
    </ng-container>
    <!-- 客户评价 -->
    <ng-container *ngIf="pageBlock.blockType === 'PULL_QUOTE'">
        <app-pull-quote-block [data]="pageBlock?.data" [title]="pageBlock?.title" [blockId]="pageBlock?.id"
            *ngIf="pageBlock?.enabled" [titleTranslation]="pageBlock?.titleTranslation" [section]="pageBlock?.section"
            [renderOptions]="pageBlock?.renderOptions"></app-pull-quote-block>
    </ng-container>
    <!-- 特点列表 -->
    <ng-container *ngIf="pageBlock.blockType === 'FEATURE_LIST'">
        <app-feature-list-block [data]="pageBlock?.data" [title]="pageBlock?.title" [blockId]="pageBlock?.id"
            *ngIf="pageBlock?.enabled" [titleTranslation]="pageBlock?.titleTranslation" [section]="pageBlock?.section"
            [renderOptions]="pageBlock?.renderOptions"></app-feature-list-block>
    </ng-container>
    <!-- 邮件订阅 -->
    <ng-container *ngIf="pageBlock.blockType === 'MAIL_SUBSCRIPTION'">
        <app-mail-subscribe-block [data]="pageBlock?.data" [title]="pageBlock?.title" [blockId]="pageBlock?.id"
            *ngIf="pageBlock?.enabled" [titleTranslation]="pageBlock?.titleTranslation" [section]="pageBlock?.section"
            [renderOptions]="pageBlock?.renderOptions"></app-mail-subscribe-block>
    </ng-container>
    <!-- 图文组合 -->
    <ng-container *ngIf="pageBlock.blockType === 'GRAPHIC_PORTFOLIO'">
        <app-graphic-portfolio-block [data]="pageBlock?.data" [title]="pageBlock?.title" [blockId]="pageBlock?.id"
            *ngIf="pageBlock?.enabled" [titleTranslation]="pageBlock?.titleTranslation" [section]="pageBlock?.section"
            [renderOptions]="pageBlock?.renderOptions"></app-graphic-portfolio-block>
    </ng-container>
</ng-template>

<ng-template #productListItemRef let-productsType="productsType" let-pageBlock="pageBlock" let-queryType="queryType">
    <app-products-block [blockId]="pageBlock?.id" [title]="pageBlock?.title" [productsType]="productsType"
        [moreProductsQueryParams]="queryType" [titleRenderOption]="pageBlock?.renderOptions?.title"
        [titleTranslation]="pageBlock?.titleTranslation" [salesVisible]="pageBlock?.renderOptions?.salesVisible"
        [renderOptions]="pageBlock?.renderOptions" [section]="pageBlock?.section">
    </app-products-block>
</ng-template>

<ng-template #builtInCpnRef let-blockType="blockType">
    <div [ngStyle]="blockType?.renderOption?.container">
        <ng-container *ngIf="blockType === 'PAGED_PRODUCT_CATEGORY'">
            <ng-content select="#productCategoriesComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_USER_CENTER'">
            <ng-content select="#userCenterComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_SHOPPING_CART'">
            <ng-content select="#shoppingCartComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_POST_LIST'">
            <ng-content select="#postsPageComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_CATEGORY_PRODUCT'">
            <ng-content select="#productCategoryComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_FORMS'">
            <ng-content select="#formComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_PRODUCTS'">
            <ng-content select="#productComponent"></ng-content>
        </ng-container>
        <ng-container *ngIf="blockType === 'PAGED_POSTS'">
            <ng-content select="#postPageComponent"></ng-content>
        </ng-container>
    </div>
</ng-template>