import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../i18n/translation.service';

export const languageConfig = [
    {
        langKey: 'en-US',
        nameKey: 'LANGUAGE_SWITCH.ENGLISH',
    },
    {
        langKey: 'ja',
        nameKey: 'LANGUAGE_SWITCH.JAPANESE',
    },
    {
        langKey: 'zh-CN',
        nameKey: 'LANGUAGE_SWITCH.CHINESE',
    },
    {
        langKey: 'zh-TW',
        nameKey: 'LANGUAGE_SWITCH.TRADITIONAL_CHINESE',
    },
];
@Pipe({
    name: 'selectedLanguageHelper'
})

export class SelectedLanguageHelperPipe implements PipeTransform {
    constructor(
        private translationService: TranslationService,
    ) {
        // window.addEventListener("storage", (e) => {
        //     if (['language', 'langKey']?.includes(e.key)) {
        //         let _langKey = e.newValue;
        //         setLang(_langKey, translationService);
        //     }
        // });
    }
    transform(langKey?: string): string {
        const selectedLanguage = languageConfig.find(item => item.langKey?.toLowerCase() === (langKey || this.translationService.getSelectedLanguage())?.toLowerCase());
        return selectedLanguage?.nameKey;
    }
}

export const setLang = (langKey: string, translationService: TranslationService) => {
    try {
        localStorage.setItem("bricsSiteLangKey", langKey);
    } catch (error) {

    }
    translationService.setLanguage(langKey);
};

export const getSelectedLang = () => {
    try {
        return localStorage.getItem('bricsSiteLangKey');
    } catch (error) {
        return 'zh-CN';
    }
};
