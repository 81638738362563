import { ISalesOrderItem } from './sales-order-item.model';
import { ISalesOrderDeliveryAddress } from './sales-order-delivery-address.model';
import { IShippingOrder } from './shipping-order.model';
import { Dayjs } from 'dayjs/esm';

export enum PaymentMethod {
    WECHAT = 'WECHAT',
    ALIPAY = 'ALIPAY',
    CASH = 'CASH',
    BANK_TRANSFER = 'BANK_TRANSFER',
    GIFT_CARD = 'GIFT_CARD',
    OTHER = 'OTHER'
}

export enum SalesOrderShipStatus {
    UNSHIPPED = 'UNSHIPPED',
    PART_SHIPPED = 'PART_SHIPPED',
    SHIPPED = 'SHIPPED'
}

export enum GroupBuyingResultEnum {
    CANCELED = 'CANCELED',
    PASSED = 'PASSED',
    IN_PROGRESS = 'IN_PROGRESS',
}

export enum SalesOrderType {
    PRESALE_ORDER = 'PRESALE_ORDER',
    NORMAL_ORDERS = 'NORMAL_ORDERS',
    GROUP_BUYING_ORDER = 'GROUP_BUYING_ORDER',
}

export interface ISalesOrder {
    id?: number;
    totalQuantity?: number;
    /**
     * 订单运费
     */
    deliveryExpense?: number;
    /**
     * 订单商品明细合计（不含运费）
     */
    totalItemAmount?: number;
    /**
     * 订单总金额（含商品明细合计及运费）
     */
    totalAmount?: number;
    /**
     * 订单总金额货币
     */
    totalAmountCurrency?: string;
    couponId?: number;
    couponValue?: number;
    customerRemark?: string;
    paid?: boolean;
    paymentMethod?: PaymentMethod;
    paymentTime?: string;
    shipStatus?: SalesOrderShipStatus;
    shipTime?: string;
    picked?: boolean;
    pickedTime?: string;
    shippingMethodId?: number;
    shippingMethodName?: string;
    logisticServiceProviderId?: number;
    chargedAmount?: number;
    logisticServiceProviderName?: string;
    supportKdniao?: boolean;
    completed?: boolean;
    completedTime?: string;
    cancelled?: boolean;
    cancelledTime?: string;
    invoiced?: boolean;
    approvalRequired?: boolean;
    approved?: boolean;
    approverId?: number;
    approvalName?: string;
    approvalTime?: string;
    approvalRemark?: string;
    referralCode?: string;
    orderChannel?: string;
    trackCode?: string;
    customerId?: number;
    customerName?: string;
    customerAvatar?: string;
    customerUserId?: number;
    siteId?: string;
    customerCouponId?: number;
    groupBuyingRequiredMembers?: number;
    selfPickupTimeSpecified?: boolean;
    selfPickupFromTime?: Dayjs;
    selfPickupToTime?: Dayjs;
    selfPickupAddressId?: number;
    selfPickupContactName?: string;
    selfPickupContactMobile?: string;
    shippingMethod?: string;
    shopId?: string;
    language?: string;
    createdBy?: string;
    createdDate?: string;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    salesOrderItems?: ISalesOrderItem[];
    orderDeliveryAddressDTO?: ISalesOrderDeliveryAddress;
    orderDeliveryAddressId?: number;
    amount?: number;
    isShowProducts?: boolean;
    couponDeduction?: number;
    customized?: boolean;
    processed?: boolean;
    shippingOrders?: IShippingOrder[];
    couponAvailableValue?: any;
    refunded?: boolean;
    fulfillStatus?: any;
    salesOrderType?: SalesOrderType;
    estimateDeliverDate?: string;
    chargedCurrency?: string;
    groupBuyingCampaignId?: number;
    groupBuyingGroupId?: number;
    groupBuyingGroupResult?: GroupBuyingResultEnum;
    affiliateCode?: string;
}

export class SalesOrder implements ISalesOrder {
    constructor(
        public id?: number,
        public totalQuantity?: number,
        public deliveryExpense?: number,
        public totalItemAmount?: number,
        public totalAmount?: number,
        public totalAmountCurrency?: string,
        public couponId?: number,
        public couponValue?: number,
        public customerRemark?: string,
        public customerAvatar?: string,
        public paid?: boolean,
        public paymentMethod?: PaymentMethod,
        public paymentTime?: string,
        public shipStatus?: SalesOrderShipStatus,
        public shipTime?: string,
        public shippingMethodId?: number,
        public shippingMethodName?: string,
        public logisticServiceProviderId?: number,
        public chargedAmount?: number,
        public logisticServiceProviderName?: string,
        public supportKdniao?: boolean,
        public completed?: boolean,
        public completedTime?: string,
        public cancelled?: boolean,
        public cancelledTime?: string,
        public invoiced?: boolean,
        public approvalRequired?: boolean,
        public approved?: boolean,
        public approverId?: number,
        public approvalName?: string,
        public approvalTime?: string,
        public approvalRemark?: string,
        public affiliateCode?: string,
        public referralCode?: string,
        public orderChannel?: string,
        public trackCode?: string,
        public customerId?: number,
        public customerName?: string,
        public customerUserId?: number,
        public siteId?: string,
        public shopId?: string,
        public language?: string,
        public createdBy?: string,
        public createdDate?: string,
        public lastModifiedBy?: string,
        public lastModifiedDate?: string,
        public salesOrderItems?: ISalesOrderItem[],
        public orderDeliveryAddressId?: number,
        public orderDeliveryAddressDTO?: ISalesOrderDeliveryAddress,
        public amount?: number,
        public isShowProducts?: boolean,
        public couponDeduction?: number,
        public customized?: boolean,
        public processed?: boolean,
        public shippingOrders?: IShippingOrder[],
        public couponAvailableValue?: any,
        public refunded?: boolean,
        public fulfillStatus?: any,
        public salesOrderType?: SalesOrderType,
        public estimateDeliverDate?: string,
        public chargedCurrency?: string,
        public customerCouponId?: number,
        public groupBuyingRequiredMembers?: number,
        public selfPickupTimeSpecified?: boolean,
        public selfPickupFromTime?: Dayjs,
        public selfPickupToTime?: Dayjs,
        public selfPickupAddressId?: number,
        public selfPickupContactName?: string,
        public selfPickupContactMobile?: string,
        public shippingMethod?: string,
    ) {
        this.paid = this.paid || false;
        this.completed = this.completed || false;
        this.cancelled = this.cancelled || false;
        this.invoiced = this.invoiced || false;
        this.approvalRequired = this.approvalRequired || false;
        this.approved = this.approved || false;
        this.isShowProducts = this.isShowProducts || false;
        this.totalAmountCurrency = this.totalAmountCurrency || 'CNY';
        this.chargedCurrency = this.chargedCurrency || 'CNY';
    }
}
