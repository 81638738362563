export const affiliateTranslate = {
    GENERAL: {
        CASH: '賞金',
        AFFILIATE_CODE: 'アフィリコード',
        AFFILIATE_DISCOUNT: 'アライアンスのオファー',
        REFERRAL_DISCOUNT: 'おすすめキャンペーン',
        PENDING_STATUS: '支払い待ち',
        CONFIRMED_STATUS: '確認済み',
        SETTLED_STATUS: '支払い決済',
        COMPLETED_STATUS: '完成済み',
        CANCELLED_STATUS: 'キャンセル',
        CASH_TEXT: 'キャッシュ',
        COUPON: 'クーポン',
        CONSUMING_CARD: '消費者カード',
        GIFT_CARD: 'ギフトカード',
        POINTS: 'ポイント',
        PRODUCT: '商品',
        APPROVED: '承認された',
        PENDING: '未定',
        REJECTED: '却下',
        CANCELLED: 'キャンセル',
        SETTLED: 'クリアされた',
    },
    SHARE_MODAL: {
        MARKET_REWARD: 'シェア＆ウィン',
        INVITE_FRIEND_START_TIP: 'この{{productName}}を購入するために友人を招待すると、最大で ',
        COMMISSION_START_TIP: 'プロモーションの収益と出金については、以下へ',
        OPERATION: '操作',
        COPY_BUTTON: 'コピーリンク',
        REWARD: '奨励',
    }
}