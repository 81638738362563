import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textExtraction'
})
export class TextExtractionPipe implements PipeTransform {

    transform(value: string, needLength?: number): string {
        try {
            return this.removeExtraLineBreaks(this.combineExtraSpace(this.escapeHtmlSpecialStr(value.replace(/<[^>]*>|/g, ''))))
                .substr(0, needLength || undefined) + ' ... ';
        } catch (error) {

        }
        return '';
    }
    // 将&nbsp;这种字符串转换为 真实意义的空格之类的字符串
    escapeHtmlSpecialStr(str) {
        return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function (all, t) {
            return { 'lt': '', 'nbsp': ' ', 'amp': '&', 'quot': '"' }[t];
        });
    }
    // 将多个连续空格合并为一个
    combineExtraSpace(str) {
        str = str.replace(/(\s| )+/g, ' ');
        return str;
    }
    // 移除多余换行
    removeExtraLineBreaks(str) {
        str = str.replace(/((\s| )*\r?\n){3,}/g, '\r\n\r\n'); // 限制最多2次换行
        str = str.replace(/^((\s| )*\r?\n)+/g, ''); // 清除开头换行
        str = str.replace(/((\s| )*\r?\n)+$/g, ''); // 清除结尾换行
        return str;
    }
}
