import { Component, Input, OnInit } from '@angular/core';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../core/services/parse-target-entity-config.service';
import { jsonParse, NameTranslatePipe } from '../../shared/pipes/name-translate.pipe';
import { UrlHelperService } from '../../core/services/url-helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { tranformGradientColorStyle } from '../../core/model/color-config.model';

@Component({
    selector: 'app-graphic-portfolio-block',
    templateUrl: './graphic-portfolio.component.html',
    styleUrls: ['./graphic-portfolio.component.scss'],
    providers: [NameTranslatePipe],
})
export class GraphicPortfolioBlockComponent {
    @Input() blockId: string = '';
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() data: any = {};
    @Input() section: string;
    @Input() set renderOptions(value: any) {
        if (value) {
            const tempValue = value?.container?.background || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._renderOptions = {
                ...value,
                container: {
                    ...value?.container,
                    'background-color': backgroundColor
                }
            };
        }
    }
    _renderOptions: any = {};
    _embedVideoContent: any;
    constructor(
        private nameTranslatePipe: NameTranslatePipe,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
        public currentDeviceService: CurrentDeviceService,
        public urlHelperService: UrlHelperService,
        public sanitizer: DomSanitizer,
    ) { }

    navigateToSomePage(linkData: any) {
        this.parseTargetEntityConfigService.navigateLinkedPage(linkData);
    }

    getElStyle(propertyPath: string) {
        let options = this._renderOptions?.[propertyPath];
        let colorData = ((options?.color !== "CUSTOMIZE") && options?.color) ? options?.color : options?.customizeColorValue;
        try {
            colorData = JSON.parse(colorData);
        } catch (error) {
            colorData = colorData?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(colorData);
        return `letter-spacing: ${options?.['letter-spacing'] || 0}px;line-height: ${options?.['line-height'] || 1.5};
        ${gradientColor ? `background-image: ${colorData};color: ${jsonParse(colorData)?.data?.[0]?.color};background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;`
                : `color:${colorData};`}`;
    }

    getElFontSizeScale(propertyPath: string) {
        let options = this._renderOptions?.[propertyPath];
        return options?.['font-size'];
    }

    contentWrapperableForMobile() {
        return this.currentDeviceService?.isMobile() &&
            (
                (this.nameTranslatePipe.transform(this.data, 'title')?.length > 24)
                || (this.nameTranslatePipe.transform(this.data, 'desc')?.length > 128)
                || (this.nameTranslatePipe.transform(this.data, 'button')?.length > 8));
    }

    getSanitizerHtml(content: string) {
        if (content) {
            if (!this._embedVideoContent) {
                this._embedVideoContent = this.sanitizer.bypassSecurityTrustHtml(content);
            }
            return this._embedVideoContent;
        }
    }
}
