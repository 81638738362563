import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    @Input() loadingText = '';
    @Input() loadingWrapperClass = '';
    constructor(
        public translateService: TranslateService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    isInSSRMode() {
        return isPlatformBrowser(this.platformId);
    }
}
