export interface JwtToken {
    id_token: string;
    register?: boolean;
    openId?: string;
}
export interface ILogin {
    username?: string;
    phoneNumber?: string;
    password?: string;
    verifyCode?: number;
    selectCycode?: string;
    invokeType?: string;
    wxCode?: string;
}
export interface IRegister {
    username?: string;
    phoneNumber?: string;
    password?: string;
    confirmPassword?: string;
    verifyCode?: number;
    selectCycode?: string;
    login?: string;
    email?: string;
    scaleOfEntity?: string;
    realName?: string;
    annualRevenue?: string;
    numberOfEmployees?: string;
    nickName?: string;
    authorities?: string[];
    referralCode?: string;
}
export interface ISms {
    phoneNumber?: string;
    graphCode?: string;
    key?: string;
    language?: string;
}
export interface IWechatLogin {
    code?: string;
    requireOtpIfNotRegistered?: boolean;
    otp?: string;
}
export class Sms implements ISms {
    constructor(public phoneNumber: string, public graphCode: string, public key: string, public language?: string) { }
}
export class Login implements ILogin {
    constructor(public username?: string, public password?: string, public rememberMe?: boolean) {
        this.rememberMe = rememberMe ? rememberMe : false;
    }
}
export class WechatLogin implements IWechatLogin {
    constructor(public code?: string, public rememberMe?: boolean, public requireOtpIfNotRegistered?: boolean) {
        this.rememberMe = Boolean(rememberMe);
        this.requireOtpIfNotRegistered = Boolean(requireOtpIfNotRegistered);
    }
}
export class MessageLogin implements ILogin {
    constructor(public phoneNumber?: string, public verifyCode?: number, public rememberMe?: boolean) {
        this.rememberMe = rememberMe ? rememberMe : false;
    }
}

export class ForgotPassword implements IRegister {
    constructor(public phoneNumber?: string, public verifyCode?: number, public password?: string, public login?: string) { }
}

export class Register implements ILogin {
    constructor(
        public phoneNumber?: string,
        public verifyCode?: number,
        public rememberMe?: boolean,
        public password?: string,
        public wxCode?: string,
        public referralCode?: string) {
        this.rememberMe = rememberMe ? rememberMe : false;
    }
}
export class InformationForRegister implements IRegister {
    constructor(
        public scaleOfEntity?: string,
        public annualRevenue?: string,
        public numberOfEmployees?: string,
        public realName?: string) {
    }
}
export class WechatRegister implements IWechatLogin {
    constructor(
        public code?: string,
        public phoneNumber?: string,
        public verifyCode?: number,
        public loginIfRegistered?: boolean,
        public otp?: string,
        public referralCode?: string) {
        this.loginIfRegistered = Boolean(loginIfRegistered);
        this.otp = otp || '';
        this.code = code || '';
    }
}


export interface IUserChangeMobile {
    newMobile?: string;
    // 老手机号
    phoneNumber?: string;
    verifyCode?: number;
    newMobileVerifyCode?: number;
}

export class UserChangeMobile implements IUserChangeMobile {
    constructor(
        public newMobile?: string,
        public phoneNumber?: string,
        public verifyCode?: number,
        public newMobileVerifyCode?: number
    ) { }
}


export interface IUserChangePassword {
    newPassword?: string;
    currentPassword?: string;
}

export class UserChangePassword implements IUserChangePassword {
    constructor(
        public newPassword?: string,
        public currentPassword?: string
    ) { }
}

export class SetLoginNameByPassword {
    constructor(
        public password?: string,
        public login?: string
    ) { }
}

export class SetLoginNameByMobile {
    constructor(public phoneNumber?: string, public verifyCode?: number, public login?: string) { }
}

export class SetLoginNameByEmail {
    constructor(public email?: string, public verifyCode?: number, public login?: string) { }
}

export interface IUserChangePasswordByMobile {
    login?: string;
    password?: string;
    phoneNumber?: string;
    phoneNumbverifyCodeer?: number;
}

export class UserChangePasswordByMobile implements IUserChangePasswordByMobile {
    constructor(
        public login?: string,
        public password?: string,
        public phoneNumber?: string,
        public phoneNumbverifyCodeer?: number,
    ) { }
}

export interface IBindMobile {
    phoneNumber?: string;
    verifyCode?: number;
}

export class BindMobile implements IBindMobile {
    constructor(
        public phoneNumber?: string,
        public verifyCode?: number
    ) { }
}

export interface IQueryOpenId {
    code?: string;
}

export interface IOpenId {
    openId?: string;
}
export class QueryOpenId implements IQueryOpenId {
    constructor(
        public code?: string,
    ) { }
}

export class EmailRegister {
    constructor(
        public email?: string,
        public verifyCode?: number,
        public password?: string,
        public login?: string,
        public referralCode?: string) { }
}

export class EmailCode {
    constructor(
        public email: string,
        public graphCode: string,
        public key: string,
        public language?: string) { }
}
export class EmailLogin {
    constructor(public email?: string, public password?: string, public rememberMe?: boolean) {
        this.rememberMe = rememberMe ? rememberMe : false;
    }
}
export class ForgotPasswordByEmail {
    constructor(public email?: string, public verifyCode?: number, public password?: string, public login?: string) { }
}
export class BindEmail {
    constructor(
        public email?: string,
        public verifyCode?: number
    ) { }
}
export class ChangeEmail {
    constructor(
        public newEmail?: string,
        public email?: string,
        public verifyCode?: number,
        public newEmailVerifyCode?: number
    ) { }
}
