import { Component, Input, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-error-view',
    templateUrl: './error-view.component.html',
    styleUrls: ['./error-view.component.scss']
})
export class ErrorComponent {
    @Input() errorImg = '';
    @Input() loadedError: {
        message: string,
        title: string,
        isTitleLangKey?: boolean,
        templateRef?: TemplateRef<any>
    } = {
            message: this.translateService.instant('GENERAL.LOAD_FAILED') || '请求失败！',
            title: this.translateService.instant('GENERAL.LOAD_FAILED') || '加载失败',
        };
    @Input() errorWrapperClass = '';
    @Input() noMarginTop = false;
    constructor(
        public translateService: TranslateService,
    ) { }

}
