export const invoiceCenterTranslate = {
    GENERAL: {
        ENABLE_INVOICE_ORDERS: '請求済み注文',
        INVOICED_ORDERS: 'インボイスの記録',
        NOT_INVOICE_ORDERS: '請求書を発行する注文',
        INVOICE_HEADERS: 'ヘッダー管理',
    },
    SHARED: {
        NOT_INVOICE: '請求書発行予定',
        TO_DETAIL: '詳細を表示する',
        TO_APPLY: '請求書を請求する',
        PENDING_TIPS: '申請中、審査中',
        PENDING: 'レビュー待ち',
        DISABLED_INVOICE_TIPS: 'この注文は請求書発行に対応していません',
    }
}