import dayjs from 'dayjs/esm';
import duration from 'dayjs/esm/plugin/duration';
import { interval, Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IGroupBuyingCampaign, IVariantPrice } from "../model/group-buying-campaign.model";
import { signal } from '@angular/core';

dayjs.extend(duration);

class GroupBuyingTranform {
    constructor() { }

    // 根据拼团活动初始化拼团信息
    initGroupBuyingCampaigns(groupBuyingCampaigns: IGroupBuyingCampaign[], $destroy?: Observable<boolean>) {
        if (!groupBuyingCampaigns?.length) {
            return {};
        }
        // 筛选掉不能用的数据：活动暂停 或者 不允许未开始时展示且当前活动未开启 或者 已经超过了活动时间的话；并对结果按开始时间进行排序，开始时间最早的最前面
        const groupBuyingCampaignList = groupBuyingCampaigns.filter(groupBuyingCampaignRes => {
            if (groupBuyingCampaignRes.suspended || (!groupBuyingCampaignRes.showAdvanceNotice
                && (groupBuyingCampaignRes.startTime && (dayjs().diff(dayjs(groupBuyingCampaignRes.startTime)) < 0)))
                || (dayjs(groupBuyingCampaignRes.endTime).diff(dayjs()) < 0)) return false;
            return true;
        }).sort((before, after) => dayjs(before.startTime).isBefore(dayjs(after.startTime)) ? -1 : 1);
        // 如果不存在数据
        if (groupBuyingCampaignList && !groupBuyingCampaignList.length) return {};
        // 取结果的第一个数据
        const groupBuyingCampaignRes = groupBuyingCampaignList[0];
        // 活动是否开始了
        const groupBuyingCampaignNotStart = groupBuyingCampaignRes.startTime && (dayjs().diff(dayjs(groupBuyingCampaignRes.startTime)) < 0);
        // 设置倒计时显示文本
        let advanceNoticeText = groupBuyingCampaignNotStart ? 'GENERAL.ONLY_TIME_LEFT_UNTIL_THE_EVENT_STARTS' : 'GENERAL.ONLY_TIME_LEFT_UNTIL_THE_END_OF_THE_EVENT';
        // 设置倒计时
        let countDownSginal = signal(null);
        if (!$destroy) {
            interval(1000).pipe(takeUntil($destroy)).subscribe(() => {
                let diffMilliseconds;
                // 如果显示预告的话
                if (groupBuyingCampaignRes.showAdvanceNotice) {
                    // 如果已经还没到开始时间的话
                    if (groupBuyingCampaignNotStart) {
                        diffMilliseconds = Math.abs(dayjs().diff(dayjs(groupBuyingCampaignRes.startTime)));
                    }
                }
                // 如果已经开始了
                if (!diffMilliseconds) diffMilliseconds = dayjs(groupBuyingCampaignRes.endTime).diff(dayjs());
                countDownSginal.set({
                    // 倒计时小时（总小时，取整数）
                    diffHours: Math.floor((dayjs as any).duration(diffMilliseconds).asHours()),
                    // 倒计时分钟（0-59）
                    diffMinutes: Math.floor((dayjs as any).duration(diffMilliseconds).minutes()),
                    diffSeconds: Math.floor((dayjs as any).duration(diffMilliseconds).seconds()),
                });
            });
        }
        let prices: any[], singleSizePriceForCreator: number;
        // 多规格拼团价
        let variantPrices = null;
        if (groupBuyingCampaignRes.variantPrices && groupBuyingCampaignRes.variantPrices.length) {
            variantPrices = {};
            (groupBuyingCampaignRes.variantPrices as IVariantPrice[]).forEach(variantPrice => {
                variantPrices[variantPrice.variantId] = variantPrice;
            });
        }
        // 单规格拼团价格
        const { price, price2, price3, requiredMembers, requiredMembers2, requiredMembers3 } = groupBuyingCampaignRes;
        // 开团价
        singleSizePriceForCreator = groupBuyingCampaignRes.priceForCreatorEnabled ? groupBuyingCampaignRes.priceForCreator : undefined;
        prices = [{
            price,
            requiredMembers,
            isSelected: true,
        }, {
            price: price2,
            requiredMembers: requiredMembers2,
            isSelected: false,
        }, {
            price: price3,
            requiredMembers: requiredMembers3,
            isSelected: false,
        }].filter(priceItem => priceItem.price && priceItem.requiredMembers);
        let currentSelectedGroupBuyingGroupLevelIndex: number;
        if (prices.filter(item => item.requiredMembers).length) currentSelectedGroupBuyingGroupLevelIndex = 0;
        // 设置的拼团活动信息
        return {
            id: groupBuyingCampaignRes.id,
            // 活动名称
            name: groupBuyingCampaignRes.name,
            // 距离多少时间
            countDownSignal: countDownSginal,
            // 需要多少人参团
            requiredMembers: groupBuyingCampaignRes.requiredMembers,
            // 是否公开邀团（用于显示参团列表）
            showPublicInvitation: groupBuyingCampaignRes.showPublicInvitation,
            // 单规格价格（多规格由于要与规格数据对应，故放在多规格数据里）
            prices,
            singleSizePriceForCreator,
            // 倒计时提示内容
            advanceNoticeText,
            // 多规格商品价格
            variantPrices,
            // 当前选中的阶梯拼团坐标
            currentSelectedGroupBuyingGroupLevelIndex,
            // 一单限购
            maxQuantityOfOrder: groupBuyingCampaignRes.maxQuantityOfOrder,
            isStarted: !groupBuyingCampaignNotStart,
            allowOtherCampaign: groupBuyingCampaignRes.allowOtherCampaign,
            allowOnlyNewCustomer: groupBuyingCampaignRes.allowOnlyNewCustomer,
            // 是否包邮
            freeDelivery: groupBuyingCampaignRes.freeDelivery,
        };
    }

    // 获取当前活动参团价格
    getJoinerPrice(groupBuyingCampaignInfo: any, groupLevelIndex?: number, variantId?: number): number {
        let _groupLevelIndex = Number.isInteger(groupLevelIndex) ? groupLevelIndex : 0;
        let priceForJoiner = 0;
        try {
            // 如果是多规格
            if (groupBuyingCampaignInfo?.variantPrices && Number.isInteger(variantId) && Object.keys(groupBuyingCampaignInfo?.variantPrices)?.length) {
                // 当前对应的多规格变体价格数据
                const varinatPrices = groupBuyingCampaignInfo?.variantPrices[variantId];
                let _groupLevelKeys = Number.isInteger(_groupLevelIndex) ? `price${(_groupLevelIndex == 0) ? '' : (_groupLevelIndex + 1)}` : 'price';
                priceForJoiner = varinatPrices[_groupLevelKeys];
            } else {
                // 如果是单规格价格
                priceForJoiner = groupBuyingCampaignInfo?.prices[_groupLevelIndex]?.price;
            }
        } catch (error) {
            priceForJoiner = 0;
        }
        return priceForJoiner;
    }
    // 获取当前团购活动的开团价格
    getCreatorPrice(groupBuyingCampaignInfo: any, variantId?: number, groupLevelIndex?: number): number {
        let _groupLevelIndex = Number.isInteger(groupLevelIndex) ? groupLevelIndex : 0;
        let priceForCreator = 0;
        try {
            // 如果是多规格
            if (groupBuyingCampaignInfo?.variantPrices && Number.isInteger(variantId) && Object.keys(groupBuyingCampaignInfo?.variantPrices)?.length) {
                // 当前对应的多规格变体价格数据
                const varinatPrices = groupBuyingCampaignInfo?.variantPrices[variantId];
                let _groupLevelKeys = Number.isInteger(_groupLevelIndex) ? `price${(_groupLevelIndex == 0) ? '' : (_groupLevelIndex + 1)}` : 'price';
                // 如果有开团价格，则团长价格，没有则是当前参团等级对应的参团价
                priceForCreator = varinatPrices?.priceForCreator || varinatPrices[_groupLevelKeys];
            } else {
                // 如果是单规格价格
                priceForCreator = groupBuyingCampaignInfo?.singleSizePriceForCreator || groupBuyingCampaignInfo?.prices[_groupLevelIndex]?.price;
            }
        } catch (error) {
            priceForCreator = 0;
        }
        return priceForCreator;
    }
}
export default new GroupBuyingTranform();
