<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="renderOptions" [enabledMoreBtn]="showPostsMore" [moreBtnText]="'GENERAL.SEE_MORE' | translate"
    (moreBtnClick)="navigateMorePage(isPosts ? ['/post/list'] : ['/post',posts[0]?.id],getQueryParams())"
    [section]="section">
    <ul class="header-nav swiper-part p-0 pb-2" *ngIf="block?.data?.tags?.length > 0">
        <li class="label-part b-labr" (click)="labelChecked(idx)" *ngFor="let tag of block?.data?.tags;let idx = index"
            [class.label-part-select]="idx === selectedTagIndex">
            {{ getTagNameById(tag)?.name || '-' }}
        </li>
    </ul>
    <div [ngClass]="{'b-r bg-white overflow-hidden px-3 pt-2':renderOptions?.type==='NEWS'}">
        <!-- 如果是文章列表 -->
        <app-common-post-list [data]="data"
            [type]="(renderOptions?.type==='LIST') ? PostStyleType.LIST : ((renderOptions?.type==='NEWS') ? PostStyleType.NEWS : PostStyleType.CARD)"
            [posts]="posts" [renderOptions]="renderOptions" *ngIf="!loadFailed&&!loadingPosts && isPosts"
            (selectPostChange)="navigateMorePage(['/post',$event.id],getQueryParams())"></app-common-post-list>
        <!-- 如果是单个文章 -->
        <ng-container *ngIf="!loadFailed&&!loadingPosts">
            <div class="post-list-container single-post-container" *ngIf="!isPosts" style="width: 100%;"
                [routerLink]="['/post',posts[0]?.id]" [queryParams]="getQueryParams()"
                title="{{posts[0] | mNameTranslate:'title'}}">
                <div class="swiper-part post-list-content single-post-content" *ngIf="posts[0] as post;">
                    <div class="b-r bg-white post-list-item w-100" style="box-sizing: border-box;" *ngIf="post">
                        <div class="d-flex item-card single-post-inner-content align-items-center b-r overflow-hidden"
                            title="{{post | mNameTranslate:'title'}}">
                            <div class="article-img-box d-inline-block position-relative" style="object-fit: contain;"
                                [ngClass]="{
                                    'px-3 pt-3 pb-3':renderOptions?.enablePostImagePadding !== false,
                                    'pb-3':(renderOptions?.enablePostImagePadding !== false)&&(currentDeviceService?.isNotMobile() || (block?.data?.contentType === 'CUSTOMIZE'))
                                }" [ngStyle]="{
                                    height:(renderOptions?.enablePostImagePadding === false) ? 'calc(210px + 2rem)' : ''
                                }">
                                <img [decode]="true" offset="100" appLoadingAnimationAndErrorBackground
                                    lazyLoad="{{post.featuredImage | uploadUrlPrefix | imgResize: 'h_420'}}"
                                    class="article-img" [ngClass]="{
                                        'b-r overflow-hidden':renderOptions?.enablePostImagePadding !== false,
                                        'w-100 h-100':renderOptions?.enablePostImagePadding === false
                                    }" appPureImgErrorIdentification [setErrorImg]="false" />
                                <i class="iconfont icon-tupian"
                                    style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                                    *ngIf="!post.featuredImage"></i>
                            </div>
                            <div class="position-relative single-post-inner-article-content w-100 px-3 pb-3" [ngClass]="{
                                'pt-3':(renderOptions?.enablePostImagePadding !== false)&&currentDeviceService?.isNotMobile()
                            }">
                                <div class="p-2 article-info-content px-4 pc-version" [ngClass]="{
                                    'no-show-title':!(renderOptions?.enableTitleForDetail !== false)||(block?.data?.contentType === 'CUSTOMIZE')
                                }">
                                    <ng-container *ngIf="block?.data?.contentType === 'CUSTOMIZE'">
                                        <div class="ql-container ql-snow item-desc pc-version word-less-text"
                                            style="border: none;white-space: normal;"
                                            [ngStyle]="{'-webkit-line-clamp': 7}">
                                            <div class="ql-editor" style="padding: 0;"
                                                [innerHTML]="(block?.data | mNameTranslate:'content') | uploadUrlPrefix:'true'">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="block?.data?.contentType !== 'CUSTOMIZE'">
                                        <h4 class="w-100 font-weight-bold item-tit"
                                            *ngIf="renderOptions?.enableTitleForDetail !== false">
                                            {{post | mNameTranslate:'title'}}</h4>
                                        <ng-container *ngIf="post | mNameTranslate:'summary'">
                                            <div class="ql-container ql-snow item-desc pb-2 pc-version word-less-text"
                                                style="border: none;white-space: normal;"
                                                [ngStyle]="{'-webkit-line-clamp': ((getPostRenderOptions(renderOptions)?.enablePublishAuthor !== false)||(getPostRenderOptions(renderOptions)?.enablePublishDate !== false)) ? 5 : 7}">
                                                <div class="ql-editor" style="padding: 0;"
                                                    [innerHTML]="(post | mNameTranslate:'summary') | uploadUrlPrefix:'true'">
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="item-desc pb-2" *ngIf="!(post | mNameTranslate:'summary')"
                                            [ngStyle]="{
                                                '-webkit-line-clamp': ((getPostRenderOptions(renderOptions)?.enablePublishAuthor !== false)||(getPostRenderOptions(renderOptions)?.enablePublishDate !== false)) ? 5 : 7,
                                            'word-less-text':(post | mNameTranslate:'content')?.length <= 106
                                            }">
                                            {{((post | mNameTranslate:'content') || ('GENERAL.NONE' | translate)) |
                                            textExtraction:(currentDeviceService.isDesktop()?'256':'128')}}</div>
                                    </ng-container>
                                </div>
                                <h4 class="item-tit mobile-title py-3 pb-2"
                                    *ngIf="(block?.data?.contentType !== 'CUSTOMIZE') && (renderOptions?.enableTitleForDetail !== false)">
                                    {{post | mNameTranslate:'title'}}
                                </h4>
                                <div class="app-version" *ngIf="(block?.data?.contentType === 'CUSTOMIZE')">
                                    <div class="ql-container ql-snow item-desc word-less-text"
                                        style="border: none;white-space: normal;" [ngStyle]="{'-webkit-line-clamp': 7}">
                                        <div class="ql-editor" style="padding: 0;"
                                            [innerHTML]="(block?.data | mNameTranslate:'content') | uploadUrlPrefix:'true'">
                                        </div>
                                    </div>
                                </div>
                                <div class="app-version" *ngIf="(block?.data?.contentType !== 'CUSTOMIZE')">
                                    <ng-container *ngIf="post?.summary">
                                        <div class="ql-container ql-snow item-desc pb-2 word-less-text"
                                            style="border: none;white-space: normal;"
                                            [ngStyle]="{'-webkit-line-clamp': ((getPostRenderOptions(renderOptions)?.enablePublishAuthor !== false)||(getPostRenderOptions(renderOptions)?.enablePublishDate !== false)) ? 5 : 7}">
                                            <div class="ql-editor" style="padding: 0;"
                                                [innerHTML]="(post | mNameTranslate:'summary') | uploadUrlPrefix:'true'">
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div class="item-desc mb-2" *ngIf="!post?.summary" [ngStyle]="{
                                            '-webkit-line-clamp': ((getPostRenderOptions(renderOptions)?.enablePublishAuthor !== false)||(getPostRenderOptions(renderOptions)?.enablePublishDate !== false)) ? 5 : 7,
                                            'word-less-text':(post | mNameTranslate:'content')?.length <= 106
                                        }">
                                        {{((post | mNameTranslate:'content') || ('GENERAL.NONE' | translate)) |
                                        textExtraction:(currentDeviceService.isDesktop()?'256':'128')}}</div>
                                </div>
                                <div *ngIf="(block?.data?.contentType !== 'CUSTOMIZE') && ((getPostRenderOptions(renderOptions)?.enablePublishAuthor !== false)||(getPostRenderOptions(renderOptions)?.enablePublishDate !== false))"
                                    class="justify-content-between pl-4 w-100 author-and-date-info-wrapper">
                                    <div class="item-master"
                                        *ngIf="getPostRenderOptions(renderOptions)?.enablePublishAuthor !== false">
                                        <img src="{{post.customer?.imageUrl | uploadUrlPrefix | imgResize: 'h_420'}}"
                                            alt="" class="item-logo" *ngIf="post.customer?.imageUrl">
                                        <img src="/assets/icon/c_logo.png" class="item-logo"
                                            *ngIf="!post.customer?.imageUrl" appPureImgErrorIdentification
                                            [setErrorImg]="true">
                                        <span class="author-name">{{post.authorName}}</span>
                                    </div>
                                    <div class="date-text"
                                        *ngIf="getPostRenderOptions(renderOptions)?.enablePublishDate !== false">
                                        {{(post?.publishDate || post?.createdDate) |
                                        date: ('SHARED.YEAR_MONTH_DAY_PIPE_FROMART_TEXT'|translate)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadFailed&&loadingPosts">
            <div class="br overflow-hidden px-4 pb-5 pt-1 bg-white b-r"
                style="min-height: 200px;position: relative;top: -1rem;margin-right: 1rem;">
                <app-loading></app-loading>
            </div>
        </ng-container>
        <ng-container *ngIf="!loadFailed&&!loadingPosts&&!posts?.length">
            <div class="br overflow-hidden px-4 pb-5 pt-1 bg-white b-r">
                <div class="emtpy-posts">
                    <div class="icon-empty">
                        <img src="/assets/icon/nothing/nothing-buy.png" style="width: 15rem;">
                    </div>
                    <div class="nothing-title">{{'GENERAL.NO_MORE_DATA' | translate}}~</div>
                </div>
            </div>
        </ng-container>
        <app-error-view *ngIf="loadFailed&&!loadingPosts" [noMarginTop]="true"
            [errorImg]="!isPosts && '/assets/icon/not-found.svg'"
            [loadedError]="{title:('GENERAL.LOAD_FAILED' | translate), message:loadFailedMsg}">
            <div class="d-flex justify-content-center pb-4">
                <button type="button" class="btn btn-sm bg-warning btn-reflush ellipsis"
                    [title]="'GENERAL.RELOAD_BTN_TEXT' | translate"
                    (click)="loadPosts(block)">{{'GENERAL.RELOAD_BTN_TEXT' | translate}}</button>
            </div>
        </app-error-view>
    </div>
</app-block-container>