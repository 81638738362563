export const contentManagementTranslate = {
    POST: {
        LIKED_CANCELLED: 'キャンセルが気に入りました！',
        CANNOT_REPEAT_LIKES: 'いいねを繰り返すことはできません！',
    },
    FORMS: {
        GET_FORM_FAILED: 'フォームの取得に失敗しました：',
        DISABLED_SUBMIT_FOR_NO_DATA: '現在、データは入力されておらず、送信できません。',
        GET_FORM_DATE_ERROR: 'フォームデータの取得に失敗しました：',
    },
    ERROR: {
    },
    GENERAL: {
    }
};
