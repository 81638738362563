import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../app.constants';

export interface IDeveloperInfo {
    "developer": {
        "name": string,
        "nameTranslation"?: any,
        "address"?: string,
        "addressTranslation"?: any,
        "postalCode"?: string,
        "website": string,
        "phone"?: string,
        "email"?: string
    },
    [key: string]: any,
}
const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({
    providedIn: 'root'
})
export class DeveloperInfoService {
    private resourceUrl = API_SERVICE_PREFIX + '/base/api/developer-info';
    constructor(
        private http: HttpClient,
    ) { }

    get(): Observable<IDeveloperInfo> {
        return this.http.get<IDeveloperInfo>(this.resourceUrl, { headers: headerOptions });
    }
}
