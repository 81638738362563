import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslationService } from '../../../i18n';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { Layout } from '../../shared/model/layouts.model';
import { NavigationLayout, NavigationPosition } from '../../shared/model/navigation-layout.enum';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { languageConfig, getSelectedLang, setLang } from '../../shared/pipes/selected-language-helper.pipe';
import { debounceTime, filter, fromEvent, Subscription, tap } from 'rxjs';
import { findPageMenu } from '../../core/utilities/page-route-util';
import { Principal } from '../../core/auth/principal.service';
import { AccountService } from '../../core/auth/account.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-header-block',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() logo = '';
    @Input() siteMenu = null;
    @Input() set layout(value: Layout) {
        if (value) {
            this.layoutRenderOptions = jsonParse(value?.renderOptions) || {
                desktopNavigation: NavigationLayout.TOP_BAR,
                mobileNavigation: NavigationLayout.BOTTOM_TAB_BAR,
                desktopNavigationPosition: NavigationPosition.STATIC
            };
            this._layout = value;
        }
    }
    isMobile = false;
    isLogin = false;
    userName = '';
    userAvatarUrl = '';
    fixedInBottom = false;
    NavigationLayout = NavigationLayout;
    layoutRenderOptions: any = null;
    _layout = new Layout();
    bannerClosed = false;
    showPersonMenu = false;
    sideBarMenuExpend = false;
    countries: {
        langKey: string;
        nameKey: string;
    }[] = [];
    _navigationBackgroundColor: string;
    selectedLang = getSelectedLang;
    NavigationPosition = NavigationPosition;
    navigationShadow = false;
    navigationShadowContainer = false;
    headerShadowContainer = false;
    subscriptions: Subscription[] = [];

    constructor(
        public translationService: TranslationService,
        private router: Router,
        private principal: Principal,
        private accountService: AccountService,
        private siteContextService: SiteContextService,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) private platformId: any,
        private changeDetectorRef: ChangeDetectorRef,
        public currentDeviceService: CurrentDeviceService,
    ) {}

    ngOnInit(): void {
        this.isLogin = this.principal.isAuthenticated();
        let sub = this.principal.getAuthenticationState().subscribe(isLogin => {
            this.isLogin = isLogin;
            if (isLogin) { 
                let userSub = this.accountService.getUserInfo().subscribe(res => {
                    this.userAvatarUrl = res.account?.imageUrl;
                    this.userName = res.customer?.nickName || res.customer?.name;
                });
                this.subscriptions.push(userSub);
            }
        });
        this.subscriptions.push(sub);

        const langs = this.translationService.getSiteLanguages();
        this.countries = languageConfig?.filter(lang => {
            return langs?.supportedLanguages?.find(languageItem => lang?.langKey?.toLowerCase() === languageItem?.toLowerCase());
        });

        if (this.getHeaderBlock()?.data || this.getHeaderBlock()?.renderOptions) {
            const tempValue = ((this.getHeaderBlock()?.data as any)?.navigation?.background || (this.getHeaderBlock()?.renderOptions as any)?.navigation?.background) || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._navigationBackgroundColor = backgroundColor;
        }
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.recalculateClientLayout();
            // 开启 监听设备宽度变化，200ms内防抖处理，实时检测当前设备宽度
            let sub = fromEvent(window, 'resize').pipe(debounceTime(200))
            .subscribe(() => {
                this.recalculateClientLayout();
            });
            this.subscriptions.push(sub);
            this.routerActiveWatch();
        }
    }

    private recalculateClientLayout() {
        this.isMobile = this.currentDeviceService.isMobile();
        this.navigationShadow = this.getNavigationShadow();
        this.navigationShadowContainer = this.getNavigationShadowContainer();
        this.headerShadowContainer = this.getHeaderShadowContainer();
        this.changeDetectorRef.detectChanges();
    }

    private getNavigationShadow() {
        return this._document.querySelector('html')?.scrollTop > 0;
    }

    private getHeaderShadowContainer() {
        return this.currentDeviceService?.isDesktop() && (this.layoutRenderOptions?.desktopNavigationPosition === NavigationPosition.FIXED) && this.getNavigationShadow();
    }

    private getNavigationShadowContainer() {
        return ['#fff', '#ffffff', '#FFF', '#FFFFFF', 'rgba(255,255,255)']?.includes(this._document.documentElement.style.getPropertyValue('--background-color'));
    }

    getContentExpansionHeight() {
        switch (((this.getHeaderBlock()?.data || this.getHeaderBlock()?.renderOptions) as any)?.navigation?.contentExpansionHeightSize) {
            case 'SMALL':
                return '0.25rem';
            case 'MIDDLE':
                return '0.5rem';
            case 'BIG':
                return '0.75rem';
            case 'LARGE':
                return '1rem';
            default:
                return '0.5rem';
        }
    }

    getHeaderBlock() {
        return this.layout?.blocks?.find(block => {
            return (block?.blockType === 'HEADER') && (block?.section === 'HEADER');
        });
    }

    bannerEnableForCurrentPage(pageBlock) {
        if (pageBlock?.data?.homePageOnly === true) {
            return ['', '/', '/home']?.includes(this.router.url?.split(/\?/)?.[0]);
        }
        return true;
    }

    headerBackgroundEnableForCurrentPage(pageBlock) {
        if (!['NONE', null, undefined]?.includes((pageBlock?.data || pageBlock?.renderOptions)?.headerType)) {
            if ((pageBlock?.data || pageBlock?.renderOptions)?.backgroundImgHomePageOnly !== false) {
                return ['', '/', '/home']?.includes(this.router.url?.split(/\?/)?.[0]);
            }
        }
        return false;
    }

    isAsideMenuForMobile() {
        return [NavigationLayout?.RIGHT_SIDE_BAR, NavigationLayout?.LEFT_SIDE_BAR]?.includes(this.layoutRenderOptions?.mobileNavigation);
    }

    closeBanner() {
        this.bannerClosed = true;
    }

    setLang(langKey: string) {
        setLang(langKey, this.translationService);
    };

    isTopOrBottomMenuForCurrentDevice() {
        return !this.isMobile ? (!this.layoutRenderOptions?.desktopNavigation || (this.layoutRenderOptions?.desktopNavigation === NavigationLayout?.TOP_BAR)) :
            (!this.layoutRenderOptions?.mobileNavigation || (this.layoutRenderOptions?.mobileNavigation === NavigationLayout?.BOTTOM_TAB_BAR))
    }

    checkIsLeftAsideForCurrentDevice() {
        return !this.isMobile ? (this.layoutRenderOptions?.desktopNavigation === NavigationLayout?.LEFT_SIDE_BAR) :
            (this.layoutRenderOptions?.mobileNavigation === NavigationLayout?.LEFT_SIDE_BAR)
    }

    checkIsRightAsideForCurrentDevice() {
        return !this.isMobile ? (this.layoutRenderOptions?.desktopNavigation === NavigationLayout?.RIGHT_SIDE_BAR) :
            (this.layoutRenderOptions?.mobileNavigation === NavigationLayout?.RIGHT_SIDE_BAR)
    }

    routerActiveWatch() {
        let sub = this.router.events
        .pipe(filter(event => {
            return event instanceof NavigationStart;
        }))
        .subscribe((event) => {
            if ((event instanceof (NavigationStart))) {
                this.fixedInBottom = !!findPageMenu(this.siteMenu?.items, event.url);
            }
        });
        this.subscriptions.push(sub);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }
}
