import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';

@Component({
    selector: 'app-block-container',
    templateUrl: './block-container.component.html',
    styleUrls: ['./block-container.component.scss']
})
export class BlockContainerComponent {
    @Input() blockId: string;
    @Input() title: string;
    @Input() titleClass: string;
    @Input() titleTranslation: string;
    @Input() set renderOptions(value: any) {
        this._renderOptions = jsonParse(value);
    };
    @Input() enabledTitle: boolean = true;
    @Input() enabledMoreBtn: boolean = false;
    @Input() disabledOverflowHidden: boolean = false;
    @Input() moreBtnText: string;
    @Input() section: string;
    @Input() containerClass: string;
    @Input() attachStyleToInnerContent: boolean = false;
    @Output() moreBtnClick = new EventEmitter();

    _renderOptions: any = {};
    constructor() { }
}
