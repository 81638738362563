import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../i18n';
import { PostService } from '../../builtin-pages/post-list/_service/post.service';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { CustomerService } from '../../core/services/customer.service';
import { PageBlockTypeEnum } from '../../shared/model/page-block-type.enum';
import { PageBlock } from '../../shared/model/page-block.model';
import { HttpErrorHandlerUtil } from '../../core/utilities/http-error-handler-util';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { SiteContextService } from '../../shared/services/site-context.service';
import { GetCurrentUrlAddress } from '../../../assets/js/tools/getUrl';
import { PostStyleType } from '../../shared/components/post/post.component';

@Component({
    selector: 'app-posts-block',
    templateUrl: './posts.component.html',
    styleUrls: ['./posts.component.scss']
})
export class PostsBlockComponent implements OnChanges {
    @Input() title: string = '';
    @Input() blockId: string = '';
    @Input() titleTranslation = '';
    @Input() renderOptions: any = {};
    @Input() block: PageBlock;
    @Input() section: string;
    @Input() data: any = {};
    isPosts = true;
    showPostsMore: boolean = true;
    loadFailedMsg = '';
    loadFailed = false;
    loadingPosts = true;
    posts = [];
    selectedTagIndex = 0;
    tags: any[] = [];
    PostStyleType = PostStyleType;
    constructor(
        private cdr: ChangeDetectorRef,
        private postService: PostService,
        public currentDeviceService: CurrentDeviceService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
        private router: Router,
        private customerService: CustomerService,
        private translationService: TranslationService,
        private siteContextService: SiteContextService,
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['block']['firstChange']) {
            if (this.block) {
                let tags = (this.block?.data as any)?.tags as string[];
                if (tags?.length) {
                    this.postService.getTags().toPromise().then(res => {
                        this.tags = res?.body;
                        this.loadPosts(this.block);
                    });
                } else {
                    this.loadPosts(this.block);
                }
            }
        }
    }

    getTagNameById(id: string) {
        return this.tags?.find(tag => tag?.id === id);
    }

    labelChecked(index: number) {
        this.selectedTagIndex = index;
        this.loadPosts();
    }

    loadPosts(block: PageBlock = this.block) {
        this.loadingPosts = true;
        this.loadFailed = false;
        this.loadFailedMsg = '';
        if (block.blockType === PageBlockTypeEnum.POSTS) {
            this.isPosts = false;
            this.getPostById((block?.data as any)?.targetEntityId || (block?.data as any)?.paramsId || undefined);
        } else {
            this.isPosts = true;
            this.getPosts((block?.data as any)?.targetEntityId || (block?.data as any)?.paramsId);
        }
        const posts = jsonParse(this.siteContextService.retrieveBlockStorageData(this.blockId));
        if (posts?.length) {
            this.posts = posts;
            this.loadingPosts = false;
        }
    }

    getPosts(categoryId?: string) {
        const postRequestParams = {
            sort: 'publishDate,desc',
            'status.equals': 'PUBLISH',
            'visibility.equals': 'PUBLIC',
            size: this.currentDeviceService.isDesktop() ? 3 : 2,
            page: 0,
        };
        if (this.tags?.length) {
            postRequestParams['tagsId.in'] = (this.block?.data as any)?.tags[this.selectedTagIndex];
        }
        if (categoryId) {
            postRequestParams['categoriesId.equals'] = categoryId;
        }
        this.postService.query(postRequestParams).toPromise().then(
            (res) => {
                this.loadingPosts = false;
                const total = parseInt(res.headers.get('x-total-count'), 10);
                this.showPostsMore = Boolean(total > (this.currentDeviceService.isDesktop() ? 3 : 2));
                if (!res.body?.length) {
                    this.getAuthorInfo(res.body.map(post => post.authorUserId));
                }
                this.posts = res.body;
                this.siteContextService.setBlockStorageData(this.blockId, this.posts);
                this.cdr.detectChanges();
            }).catch(err => {
                this.loadingPosts = false;
                this.loadFailed = true;
                this.httpErrorHandlerUtil.errHandle(err).catch(error => {
                    this.loadFailedMsg = error.msg;
                });
                this.posts = [];
                this.cdr.detectChanges();
            }).finally(() => {
                this.cdr.detectChanges();
            });
    }

    getAuthorInfo(ids: number[]) {
        this.customerService.query({
            'userId.in': ids.join(',')
        }).subscribe(res => {
            res.body.forEach(customer => {
                const findPost = this.posts.find(post => customer.userId === post.authorUserId) as any;
                if (findPost) {
                    findPost.customer = customer;
                }
            });
        });
    }

    getPostById(id: number) {
        this.postService.find(id).toPromise().then(
            (res) => {
                this.loadingPosts = false;
                this.posts = [res.body];
                this.siteContextService.setBlockStorageData(this.blockId, this.posts);
                this.showPostsMore = false;
                this.cdr.detectChanges();
            }).catch(err => {
                this.loadingPosts = false;
                this.loadFailed = true;
                this.loadFailedMsg = this.translationService.instant('GENERAL.LOAD_POST_FAILED', {}, '文章找不到或已删除');
                this.posts = [];
                this.showPostsMore = false;
                this.cdr.detectChanges();
            }).finally(() => {
                this.cdr.detectChanges();
            });
    }

    navigateMorePage(paths: string[], queryParams: any) {
        this.router.navigate(paths, {
            queryParams: {
                ...queryParams,
                isMorePostsPage: true
            }
        });
    }

    getQueryParams() {
        const result = {
            ...this.data,
            ...this.renderOptions,
            titleTranslation: JSON.stringify(this.titleTranslation || {}),
            title: this.title,
            fromPagePath: window.location.pathname,
            fromPageQueryParams: JSON.stringify(GetCurrentUrlAddress.parseQueryParams()),
        };
        if (this.data?.targetEntityId) {
            result.categoryId = this.data.targetEntityId;
            result.targetEntityId = undefined;
        } else if (this.data?.paramsId) {
            result.categoryId = this.data.paramsId;
            result.paramsId = undefined;
        }
        return result;
    }

    getPostRenderOptions(post) {
        if (post?.id) {
            return {
                "enablePublishAuthor": true,
                "enablePublishDate": true,
                "enableBanner": true,
                "enableLike": true,
                "enableComment": true,
                "enabledShare": true,
                ...jsonParse(post?.renderOptions)
            };
        } else {
            return {
                "enablePublishAuthor": true,
                "enablePublishDate": true,
                "enableBanner": true,
                "enableLike": true,
                "enableComment": true,
                "enabledShare": true,
            };
        }
    }
}
