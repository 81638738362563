<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="renderOptions" [enabledMoreBtn]="showProductsMore" [moreBtnText]="'GENERAL.SEE_MORE' | translate"
    (moreBtnClick)="navigateMoreProducts()" [section]="section">
    <div>
        <ul class="header-nav swiper-part p-0" *ngIf="productLabels?.length > 1">
            <li class="label-part b-labr" (click)="labelChecked(idx, label)"
                *ngFor="let label of productLabels;let idx = index"
                [class.label-part-select]="idx === selectedLabelIndex">
                {{ label }}
            </li>
        </ul>
        <ng-container *ngIf="!loadFailed">
            <ng-container *ngIf="!showLoading">
                <div style="padding-top: 20px;" *ngIf="products && products?.length">
                    <div id="js-loadProduct" class="product-list" [class.load-part]="false">
                        <app-product [enableAddShoppingCartBtn]="renderOptions?.enableShoppingCartBtn"
                            [enableDesktopMarginRightAuto]="renderOptions?.fullWidth" [productDatas]="products"
                            [oneRemOfMarginTop]="true" [salesVisible]="salesVisible">
                        </app-product>
                    </div>
                </div>
                <div class="page-padding emtpy-category b-r overflow-auto w-100" *ngIf="!products?.length">
                    <div class="icon-empty">
                        <img src="/assets/icon/nothing/nothing-buy.png" style="width: 15rem;">
                    </div>
                    <div class="nothing-title">{{'GENERAL.NO_MORE_DATA' | translate}}~</div>
                </div>
            </ng-container>
            <ng-container *ngIf="showLoading">
                <div class="br overflow-hidden px-4 pb-5 pt-1 mt-3 bg-white"
                    style="min-height: 200px;position: relative;top: -1rem;margin-right: 1rem;">
                    <app-loading></app-loading>
                </div>
            </ng-container>
        </ng-container>
        <app-error-view *ngIf="loadFailed" [errorImg]="'/assets/icon/not-found.svg'"
            [loadedError]="{title:'GENERAL.LOAD_FAILED', message:loadFailedMsg,isTitleLangKey:true}">
            <div class="d-flex justify-content-center pb-4">
                <button type="button" class="btn btn-sm bg-warning btn-reflush ellipsis"
                    [title]="'GENERAL.RELOAD_BTN_TEXT' | translate"
                    (click)="reloadProducts()">{{'GENERAL.RELOAD_BTN_TEXT' | translate}}</button>
            </div>
        </app-error-view>
    </div>
</app-block-container>