export const invoiceCenterTranslate = {
    GENERAL: {
        ENABLE_INVOICE_ORDERS: '可开票订单',
        INVOICED_ORDERS: '开票记录',
        NOT_INVOICE_ORDERS: '待开票订单',
        INVOICE_HEADERS: '抬头管理',
    },
    SHARED: {
        NOT_INVOICE: '待开票',
        TO_DETAIL: '查看详情',
        TO_APPLY: '申请开票',
        PENDING_TIPS: '已申请，待审核',
        PENDING: '待审核',
        DISABLED_INVOICE_TIPS: '该订单不支持开票',
    }
}