import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'numberTransform'
})
export class NumberTransformPipe implements PipeTransform {
    constructor(
        private translateService: TranslateService,
    ) { }
    transform(value: string): string {
        let formatStr: string;
        const originalStr = String(value).trim();
        let level = (Number(originalStr.length) > 5) ? ((Number(originalStr.length) > 6) ? ((Number(originalStr.length) > 12) ? 'BILLION' : 'TEN_THOUSAND') : 'THOUSAND') : undefined;
        switch (level) {
            case 'THOUSAND':
                formatStr = (Number(originalStr) / 1000).toFixed(1) + (this.translateService.instant('GENERAL.THOUSAND') || '千');
                break;
            case 'TEN_THOUSAND':
                formatStr = (Number(originalStr) / 10000).toFixed(1) + (this.translateService.instant('GENERAL.TEN_THOUSAND') || '万');
                break;
            case 'BILLION':
                formatStr = (Number(originalStr) / 10000000).toFixed(1) + (this.translateService.instant('GENERAL.BILLION') || '亿');
                break;
            default:
                formatStr = originalStr;
                break;
        }
        return formatStr;
    }
}
