<div class="navigation-menu" [ngClass]="{
    'b-r overflow-hidden background-height-light-hover':menuHoverStyle === MenuHoverStyleEnum.BACKGROUND_HIGHLIGHT
}">
    <ng-container
        *ngTemplateOutlet="menuItem;context:{menuItem:menu,enableIcon:enableIcon,expendChildrenMenuIcon:'chevron-down expend-icon'}">
    </ng-container>
    <div class="menu-child-panel-container b-r" *ngIf="menu?.children?.length">
        <ng-container *ngTemplateOutlet="childrenMenuPanel;context:{menuItem:menu}"></ng-container>
    </div>
</div>

<ng-template #menuItem let-menuItem="menuItem" let-enableIcon="enableIcon"
    let-expendChildrenMenuIcon="expendChildrenMenuIcon">
    <!-- (mouseenter)="hoverMenuItem(menuItem)" (mouseleave)="hoverMenuItem(menuItem)" -->
    <div class="d-flex justify-content-between align-items-center navigation-menu-item first-navigation-menu-item"
        (click)="navigateMenuPage(menuItem)" [ngClass]="{
            active:checkMenuIsActive(menuItem),
            'bottom-line-hover':!menuHoverStyle || (menuHoverStyle === MenuHoverStyleEnum.BOTTOM_LINE)
        }">
        <div>
            <!-- <i *ngIf="enableIcon"
                class="menu-item-icon {{menu?.iconClassKey | appIconClassKey:checkMenuIsActive(menuItem)}}"></i> -->
            <span [ngClass]="{'d-block w-100 text-center':enableIcon}"
                style="padding-left: 0.25rem;white-space: nowrap;" [style.fontSize]="enableIcon?'0.85rem':''"
                [style.paddingLeft]="enableIcon?'':'0.25rem'" [style.paddingTop]="enableIcon?'0.25rem':''">{{menuItem |
                mTitleTranslate}}</span>
        </div>
        <i class="bi bi-{{expendChildrenMenuIcon}}" *ngIf="expendChildrenMenuIcon && menuItem?.children?.length"></i>
    </div>
</ng-template>

<ng-template #childrenMenuPanel let-menuItem="menuItem">
    <div class="children-menus-panel" *ngIf="menuItem?.children?.length">
        <div class="child-menu-item" *ngFor="let childMenu of menuItem?.children">
            <ng-container *ngIf="childMenu?.enabled">
                <div class="d-flex justify-content-between align-items-center navigation-menu-item"
                    (click)="navigateMenuPage(childMenu)" [ngClass]="{active:checkMenuIsActive(childMenu)}">
                    <div>
                        <!-- <i class="{{menu?.iconClassKey | appIconClassKey:checkMenuIsActive(childMenu)}}"></i> -->
                        <span style="padding-left: 0.5rem;text-transform: capitalize;">{{childMenu |
                            mTitleTranslate}}</span>
                    </div>
                    <i class="bi bi-chevron-right" *ngIf="childMenu?.children?.length"></i>
                </div>
                <div class="menu-son-panel-container b-r" *ngIf="childMenu?.children?.length">
                    <div class="son-menus-panel">
                        <div class="child-menu-item" *ngFor="let sonMenu of childMenu?.children">
                            <div *ngIf="sonMenu?.enabled"
                                class="d-flex justify-content-between align-items-center navigation-menu-item"
                                (click)="navigateMenuPage(sonMenu)" [ngClass]="{active:checkMenuIsActive(sonMenu)}">
                                <div>
                                    <!-- <i class="{{menu?.iconClassKey | appIconClassKey:checkMenuIsActive(sonMenu)}}"></i> -->
                                    <span style="padding-left: 0.5rem;text-transform: capitalize;">{{sonMenu |
                                        mTitleTranslate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>