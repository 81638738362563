import { Component, Input, OnInit } from '@angular/core';
import { BackgroundSetting, FilterTypeEnum } from '../../shared/model/background-setting.model';
import { ImgResizePipe } from '../../shared/pipes/img-resize.pipe';
import { UrlHelperService } from '../../core/services/url-helper.service';
import { checkVideoCanAutoPlay } from '../../../assets/js/tools/utils';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { tranformGradientColorStyle } from '../../core/model/color-config.model';

@Component({
    selector: 'app-site-branding',
    templateUrl: './site-branding.component.html',
    styleUrls: ['./site-branding.component.scss'],
    providers: [ImgResizePipe],
})
export class SiteBrandingComponent {
    @Input() block: any = null;
    @Input() data: BackgroundSetting;
    @Input() renderOptions: any;
    @Input() isMobile = false;
    @Input() navigationHeight: number;
    FilterTypeEnum = FilterTypeEnum;
    constructor(
        private navigator: Navigator,
        private imgResizePipe: ImgResizePipe,
        public urlHelperService: UrlHelperService,
    ) { }
    getBackgroundImageUrl(url: string) {
        return 'url("' + this.imgResizePipe.transform(url, 'w_3840') + '")';
    }

    videoAutoPlayEnabled() {
        if (!this.navigator) {
            return false;
        }
        return checkVideoCanAutoPlay();
    }

    getElStyle(data?: any) {
        let options = jsonParse(data);
        let colorData = ((options?.color !== "CUSTOMIZE") && options?.color) ? options?.color : options?.customizeColorValue;
        try {
            colorData = JSON.parse(colorData);
        } catch (error) {
            colorData = colorData?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(colorData);
        return `letter-spacing: ${options?.['letter-spacing'] || 0}px;line-height: ${options?.['line-height'] || 1.5};
        ${gradientColor ? `background-image: ${colorData};color: ${jsonParse(colorData)?.data?.[0]?.color};background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;`
                : `color:${colorData};`}`;
    }

    getElFontSizeScale(data?: any) {
        return jsonParse(data)?.['font-size'];
    }
}
