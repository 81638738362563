import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';

export interface IErrorMessage {
    status: number;
    msg: string;
    instantKeyForLanguage: string;
    translateParams?: any;
}

@Injectable({ providedIn: 'root' })
export class HttpErrorHandlerUtil {
    constructor(private translateService: TranslateService,) { }

    // 根据不同的状态码，生成不同的提示信息
    showReadableErrorInfo = (err: HttpErrorResponse) => {
        let message = '', instantKeyForLanguage = '', translateParams = {};
        try {
            // 先判断是否连接宽带
            if (!window.navigator.onLine) {
                instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.OFF_LINE';
                message = this.translateService.instant(instantKeyForLanguage) || `请求出错，请检查您的网络是否连接……`;
            } else if (err instanceof HttpErrorResponse) {
                // 如果实参是HttpErrorResponse错误的话
                const status = err && err.status;
                // 是否展示detail错误
                if (err && err.error && err.error.message === 'error.http.500') {
                    message = err.error.detail ? `${err.error.detail}` : `${err.error.title}`;
                    // 常规提示
                } else if (err && err.error && err.error.title) {
                    message = `${err.error.title}`;
                    if (status === 401 && err.error.title === 'Unauthorized') {
                        if (err?.error?.detail?.includes('failed to lazily initialize')) {
                            instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.FAILEDTOLAZILYINITIALIZE';
                            message = this.translateService.instant(instantKeyForLanguage) || `当前请求服务未启动成功，请联系客服或者管理员处理`;
                        } else {
                            instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.UNAUTHORIZED';
                            message = this.translateService.instant(instantKeyForLanguage) || '您暂未登录或者登录状态已过期，请先登录后再进行操作……';
                        }
                    }
                } else {
                    if (err.error?.includes('"entityName\"')) {
                        message = `${jsonParse(err.error).title}`;
                    } else {
                        instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.ERROR_MESSAGE';
                        // 其他提示：
                        const messageInfo = err && err.statusText ? err.statusText : (this.translateService.instant('API_ALERT_MESSAGE.ERROR.UNKONWN') || '未知错误……');
                        message = `${this.translateService.instant(instantKeyForLanguage) || '请求出错，错误信息：'}${messageInfo}`;
                    }
                }
            } else {
                // 如果实参不是HttpErrorResponse类型
                instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.DEFAULT';
                message = err || this.translateService.instant(instantKeyForLanguage) || '请检查当前网络，或者当前接口出现未知错误……';
            }
            const errorKey = err?.error?.errorKey || jsonParse(err.error)?.errorKey;
            // 对于比较场景通用的特殊的几个报错再进行包装下
            switch (errorKey) {
                case 'CouponNotFeasibleForGiftCard':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.COUPONNOTFEASIBLEFORGIFTCARD';
                    message = this.translateService.instant(instantKeyForLanguage) || '礼品卡不能使用优惠券抵扣哦~';
                    break;
                case 'invalidCardTemplateId':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.INVALIDCARDTEMPLATEID';
                    message = this.translateService.instant(instantKeyForLanguage) || '订单中包含礼品卡商品，无法使用礼品卡支付哦~';
                    break;
                case 'otpExpired':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.OTPEXPIRED';
                    message = this.translateService.instant(instantKeyForLanguage) || '微信授权已过期，请重新授权哦~';
                    break;
                case 'send verification code error':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.SENDVERIFICATIONCODEERROR';
                    message = this.translateService.instant(instantKeyForLanguage) || '发送次数过多，请稍后或者更换号码再试吧';
                    break;
                case 'noCustomerRecord':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.NOCUSTOMERRECORD';
                    message = this.translateService.instant(instantKeyForLanguage) || '当前账号信息不完整，请换个手机号再试或者联系管理员吧';
                    break;
                case 'loginFailed':
                    let failResult = /([1-9]\d*)/gi.exec(message);
                    if (failResult?.length > 1) {
                        instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.' + errorKey?.toUpperCase();
                        translateParams = {
                            loginNumber: failResult?.[1],
                            maxNumber: failResult?.[2],
                            timeNumber: failResult?.[3]
                        };
                        message = this.translateService.instant(instantKeyForLanguage, translateParams) || `登录失败，您已经尝试登录 ${failResult?.[1]} 次，超过 ${failResult?.[2]} 次将会导致您 ${failResult?.[3]} 分钟内无法使用账号登录`;
                    } else {
                        instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.' + errorKey?.toUpperCase() + '_NO_DETAIL';
                        message = this.translateService.instant(instantKeyForLanguage) || `登录失败，请检查用户名或密码是否正确或更改登录方式，频繁登录将会导致您一段时间内无法使用账号登录`;
                    }
                    break;
                case 'failedTooManyTimes':
                    let result = /([1-9]\d*)/gi.exec(message);
                    if (result?.length > 1) {
                        instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.' + errorKey?.toUpperCase();
                        translateParams = {
                            value: result?.[1]
                        };
                        message = this.translateService.instant(instantKeyForLanguage, translateParams) || `您尝试账号登录次数过多，更换登录方式或 ${result?.[1]} 分钟后重新尝试`;
                    } else {
                        instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.' + errorKey?.toUpperCase() + '_NO_DETAIL';
                        message = this.translateService.instant(instantKeyForLanguage) || `您尝试账号登录次数过多，更换登录方式或 稍后再试吧`;
                    }
                    break;
                default:
                    break;
            }
            const entityName = err?.error?.entityName || jsonParse(err.error)?.entityName;
            // CMS部分判断
            if (entityName) {
                if (['formNotFound', 'missingRequiredResponse', 'invalidRowsFormat', 'responseNotMatchWithRowCount',
                    'missingRequiredResponseOfRow', 'multipleResponseNotAllowed', 'missingResponseValue',
                    'emptyResponseValue', 'invalidDateTimeFormat', 'invalidIntegerValue', 'invalidFloatValue',
                    'decimalPlaceNumberExceeds', 'fileNumberExceeds', 'invalidOptionsFormat', 'invalidValue',
                    'cannotFindValidator', 'emailAlreadyExists']?.includes(err?.error?.errorKey)) {
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.CMS.' + (err?.error?.errorKey).toUpperCase();
                    const msg = this.translateService.instant(instantKeyForLanguage);
                    if (msg) {
                        message = msg;
                    }
                }
            }
            // USER部分判断
            if (['userUserService', 'userWechatService', 'userWxMaUserService', 'userJWT', 'userMobileAuthenticationService', 'userVerifyCode']?.includes(entityName)) {
                if (['passwordFormatInvalid', 'loginAlreadyExists', 'alreadyBoundMobile', 'userNotBoundMobile',
                    'mobileNumberMismatch', 'mobileBoundByOtherUser', 'incorrectPassword', 'mobileNotRegistered', 'parameterNull',
                    'noUserCredential', 'wechatNotRegistered', 'userNotBoundWechat', 'wechatBoundByOtherUser', 'mobileAlreadyRegistered',
                    'registerRequired', 'wechatAlreadyRegistered', 'noUserByWechat', 'codeNull', 'loginFailed', 'wrongPassword', 'codeInvalid',
                    'passwordNotEnabled']?.includes(err?.error?.errorKey)) {
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.' + errorKey?.toUpperCase();
                    if (err?.error?.errorKey === 'loginFailed') {
                        let result = [];
                        message.replace(/([1-9]\d*)/gi, function (matchStr, value) {
                            result.push(value);
                            return value;
                        });
                        if (result?.length === 3) {
                            translateParams = {
                                loginNumber: result?.[0],
                                maxNumber: result?.[1],
                                timeNumber: result?.[2]
                            };
                        } else {
                            instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.' + errorKey?.toUpperCase() + '_NO_DETAIL';
                            message = this.translateService.instant(instantKeyForLanguage) || `登录失败，请检查用户名或密码是否正确或更改登录方式，频繁登录将会导致您一段时间内无法使用账号登录哦`;
                        }
                    }
                    const msg = this.translateService.instant(instantKeyForLanguage, translateParams);
                    if (msg) {
                        message = msg;
                    }
                }
            }
            switch (message?.replace('请求出错，错误信息：', '')) {
                case 'Not Found':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.NOTFOUND';
                    message = this.translateService.instant(instantKeyForLanguage) || '数据不存在或后端服务暂时不可用，此问题若继续出现，请联系管理员';
                    break;
                case 'Method Not Allowed':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.METHODNOTALLOWED';
                    message = this.translateService.instant(instantKeyForLanguage) || '请求出错，此问题若继续出现，请联系管理员';
                    break;
                case 'Internal Server Error':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.INTERNALSERVERERROR';
                    message = this.translateService.instant(instantKeyForLanguage) || '服务器出现异常，此问题若继续出现，请联系管理员';
                    break;
                case 'Gateway Timeout':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.GATEWAYTIMEOUT';
                    message = this.translateService.instant(instantKeyForLanguage) || '无法连接服务器，请检查网络再试吧';
                    break;
                case 'Unknown Error':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.UNKONWN';
                    message = this.translateService.instant(instantKeyForLanguage) || '未知错误，请检查网络再试吧；若继续出现，请联系管理员';
                    break;
                case 'ERR_NETWORK_CHANGED':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.ERRNETWORKCHANGED';
                    message = this.translateService.instant(instantKeyForLanguage) || '无法连接服务器，请检查网络再试吧';
                    break;
                case '未知错误……':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.UNKONWN';
                    message = this.translateService.instant(instantKeyForLanguage) || '未知错误，请检查网络再试吧；若继续出现，请联系管理员';
                    break;
                case 'Method argument not valid':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.METHODARGUMENTNOTVALID';
                    message = this.translateService.instant(instantKeyForLanguage) || '提交数据有误，请刷新再试吧；若继续出现，请重新访问本应用';
                    break;
                case 'Bad Gateway':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.BADGATEWAY';
                    message = this.translateService.instant(instantKeyForLanguage) || '服务器异常，请稍后访问本应用；若继续出现，请联系管理员';
                    break;
                case 'User could not be found':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USERCOULDNOTBEFOUND';
                    message = this.translateService.instant(instantKeyForLanguage) || '用户不存在，请重新登录或者稍后再试吧';
                    break;
                case '用户不存在':
                    instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.USER.USER_DOES_NOT_EXIST';
                    message = this.translateService.instant(instantKeyForLanguage) || '用户不存在';
                    break;
                default:
                    break;
            }
            if (message?.includes('could not extract ResultSet')) {
                instantKeyForLanguage = 'API_ALERT_MESSAGE.ERROR.COULDNOTEXTRACTRESULTSET';
                message = this.translateService.instant(instantKeyForLanguage) || '服务相关进程异常，请稍后访问本应用；若继续出现，请联系管理员';
            }
        } catch (error) {

        }
        return {
            message,
            instantKeyForLanguage,
            translateParams
        };
    }
    // 错误处理
    errHandle = (error: HttpErrorResponse) => {
        const errorInfo = this.showReadableErrorInfo(error);
        const err: IErrorMessage = {
            msg: errorInfo.message,
            status: error.status,
            instantKeyForLanguage: errorInfo.instantKeyForLanguage,
            translateParams: errorInfo.translateParams,
        };
        return Promise.reject(err);
    }
}
