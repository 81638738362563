import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Page } from './shared/model/page.model';
import { SiteContextService } from './shared/services/site-context.service';
import { filter, lastValueFrom, map, tap } from 'rxjs';
import { PageService } from './shared/services/page.service';

@Injectable({ providedIn: 'root' })
export class CurrentPageResolver implements Resolve<any> {
    constructor(
        private siteContextService: SiteContextService,
        private pageService: PageService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Page> {
        let pageUrl = state.url;
        let pagePath = pageUrl.split('?')[0]; // remove query string if any
        pagePath = pagePath.startsWith('/') ? pagePath.substring(1) : pagePath; // remove preceeding slash if any
        // This is to solve the root route issue
        if (pagePath === '') {
            pagePath = 'home';
            pageUrl = pagePath + (pageUrl.startsWith('/') ? pageUrl.substring(1) : pageUrl);
        }
        if (!this.siteContextService.siteGraph) {
            return lastValueFrom(this.siteContextService.loadSiteGraph(pageUrl)
            .pipe(filter(siteGraph => !!siteGraph))
            .pipe(map(siteGraph => siteGraph.currentPage)));
        }

        // Retrieve page from server if not found in current site graph
        let pageId = this.siteContextService.siteGraph.pages.find(p => p.slug === pagePath)?.id;
        if (pageId) {
            // check if page is already loaded in site graph
            if (this.siteContextService.siteGraph?.currentPage?.id === pageId) {
                return Promise.resolve(this.siteContextService.siteGraph.currentPage);
            }

            return lastValueFrom(this.pageService.find(pageId)
                .pipe(map(pageRes => pageRes.body)));
        } else {
            return lastValueFrom(this.siteContextService.loadSiteGraph(state.url)
            .pipe(filter(siteGraph => !!siteGraph))
            .pipe(map(siteGraph => siteGraph.currentPage)));
        }
    }
}
