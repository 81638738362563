import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { ICarousel } from '../model/carousel.model';
import { CurrentDeviceService } from './current-device.service';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { SiteContextService } from '../../shared/services/site-context.service';
import { ShopContextService } from '../../ecommerce/shared/service/shop-context.service';

@Injectable({ providedIn: 'root' })
export class CarouselsService {
    constructor(
        private http: HttpClient,
        private siteContextService: SiteContextService,
        public currentDeviceService: CurrentDeviceService,
        private shopContextService: ShopContextService,
    ) { }
    get(key: string): Observable<ICarousel> {
        const headerOptions = new HttpHeaders({
            'brics-api-error-notify': 'false',
            'brics-api-success-notify': 'false'
        });
        if (key) {
            const shopId = this.shopContextService.shopId;
            return this.http.get<any>(API_SERVICE_PREFIX + `/store/api/shop/${shopId}/carousels/key`, {
                params: {
                    key: this.currentDeviceService.isDesktop() ? (key + '_Desktop') : (key + '_MOBILE')
                },
                headers: headerOptions
            });
        }
        return of(null);
    }
}
