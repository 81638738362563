// 生成Image对象以供js使用
export function loadImgToPromise(url: string): Promise<{ result: boolean, image: any }> {
    if (!url) { url = ''; }
    const img = new Image();   // 创建一个<img>元素
    // canvas污染现象：canvas绘制图片，由于浏览器的安全考虑，如果在使用canvas绘图的过程中，使用到了外域的图片资源必须加下面这段话，不然canvas认为其是不安全的，
    // 会在toDataURL()时会抛出安全异常
    img.setAttribute('crossOrigin', 'anonymous');
    // 部分电脑在img上加入请求跨域标识后明明阿里云存储允许跨域了还是会提示跨域被拦截？
    // 原因：如果给image对象设置crossOrign属性的话，那么image对象请求的时候一定会按照跨域请求去请求图片。
    // 1. 请求远端服务器的图片，由于阿里云存储设置了cors，那么这个是没有问题的。
    // 2.由于浏览器还存在缓存机制，当第二次请求图片时，因为命中缓存不会返回符合cors要求的响应头，这时候浏览器会把请求丢弃，认为触发了跨域问题，从而触发错误。
    // 解决方式：通过增加时间来避免缓存问题可解决该问题
    const urlForNoCache = url.includes('data:image/') ? url : url + '?timeStamp=' + new Date();
    img.src = urlForNoCache; // 设置图片源地址
    return new Promise((resolve, reject) => {
        img.onload = function () {
            resolve({
                result: true,
                image: img
            });
        };
        img.onerror = () => {
            // 以防万一，增加兜底机制，最后用xhr兜底确保不是因缓存问题导致无法产生分享图
            getImageBlobForNoCacheByXhR(url).then(result => resolve(result)).catch(err => reject(err));
        };
    });
}
// 通过xhr请求来绕开资源缓存问题
export function getImageBlobForNoCacheByXhR(url: string): Promise<{ result: boolean, image: any }> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('get', url, true);
        xhr.responseType = 'blob';
        // 禁用浏览器缓存
        xhr.setRequestHeader('Cache-control', 'no-cache');
        //  兼容处理如果不识别上面的协议头
        xhr.setRequestHeader('Pragma', 'no-cache');
        xhr.onload = function () {
            if (this.status == 200) {
                const imgResponse = this.response;
                const img = new Image();
                img.src = URL.createObjectURL(this.response);
                img.onload = function () {
                    resolve({
                        result: true,
                        image: img
                    });
                };
                img.onerror = function () {
                    reject({
                        result: false,
                        image: null
                    });
                };
            } else {
                reject({
                    result: false,
                    image: null
                });
            }
        };
        xhr.onerror = function () {
            reject({
                result: false,
                image: null
            });
        };
        xhr.send();
    });
}

/**
* 在本地进行文件保存（微信内不支持a标签下载，如果图片文件请使用img展示，引导用户长按保存）
* @param  {String} data     要保存到本地的图片数据
* @param  {String} filename 文件名
*/
export function saveFile(data, filename) {
    const save_link: any = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    save_link.href = data;
    // 判断当前浏览器a标签是否支持download属性
    const canSupportDownload = 'download' in save_link;
    // 微信浏览器的a标签增加download会无法触发下载也得排除!this.currentDeviceService.isWX() &&
    if (canSupportDownload) { save_link.download = filename + '.jpeg'; }
    const event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    save_link.dispatchEvent(event);
}
// 传入一个js文件地址，该函数会将其插入到网页里以动态加载某些第三方插件
export function insertJsOfSrcType(url: string, callbackForSuccess: Function, callbackForFail: Function) {
    if (!callbackForSuccess) { return; }
    const script: any = window.document.createElement('script');
    script.type = 'text/javascript';
    if (script.readyState) {
        script.onreadystatechange = () => {
            if (script.readyState == 'loaded' || script.readyState == 'complete') {
                script.onreadystatechange = null;
                callbackForSuccess();
            }
        };
    } else {
        script.onload = () => {
            callbackForSuccess();
        };
    }
    script.onerror = callbackForFail;
    script.src = url;
    document.body.appendChild(script);
}
// 将某个字符串切割为一个字符串数组用于换行展示：默认最多显示2行 每行13个字(即 一个数组里有两行，两行加起来一共13个字)
export function stringSplitForWrap(content: string, maxLineNum: number = 2, lineSize: number = 13): string[] {
    const strArr = [];
    const lineNum =
        content.length % lineSize == 0
            ? content.length / lineSize
            : content.length / lineSize + 1;
    for (let i = 1; i <= Math.min(lineNum, maxLineNum); i++) {
        const value = content.substring(lineSize * (i - 1), lineSize * i);
        if (i == maxLineNum && value.length >= 13) {
            strArr.push(value.substring(0, 12) + '...');
            break;
        }
        strArr.push(content.substring(lineSize * (i - 1), lineSize * i));
    }
    return strArr;
}
// 将一个价格格式化展示
export function priceFormat(price: number): string {
    let formatPrice: string;
    const originalPrice = price.toFixed(2);
    switch (Number(originalPrice.length)) {
        case 7:
            formatPrice = (Number(originalPrice) / 1000).toFixed(2) + '千';
            break;
        case 8:
            formatPrice = (Number(originalPrice) / 10000).toFixed(2) + '万';
            break;
        case 11:
            formatPrice = (Number(originalPrice) / 10000).toFixed(1) + '万';
            break;
        case 12:
            formatPrice = (Number(originalPrice) / 10000000).toFixed(2) + '亿';
            break;
        default:
            formatPrice = originalPrice;
            break;
    }
    return formatPrice;
}
// 比较两个基本数据类型数组是否相等（比较元素，不管顺序）
export function compareArrayOfBasicTypeSort(a1: any[], a2: any[]): boolean {
    if ((!a1 && a2) || (a1 && !a2)) { return false; }
    if (a1.length !== a2.length) { return false; }
    a1 = [].concat(a1);
    a2 = [].concat(a2);
    a1 = a1.sort();
    a2 = a2.sort();
    for (let i = 0, n = a1.length; i < n; i++) {
        if (a1[i] !== a2[i]) { return false; }
    }
    return true;
}
export function getBrowserScrollBarWidth() {
    try {
        let noScroll, scroll;
        const oDiv = document.createElement('div');
        oDiv.style.cssText = 'position:absolute; top:-9999px; width:100px; height:100px; overflow:hidden;z-index:-1;';
        noScroll = document.body.appendChild(oDiv).clientWidth;
        oDiv.style.overflowY = 'scroll';
        scroll = oDiv.clientWidth;
        document.body.removeChild(oDiv);
        return noScroll - scroll;
    } catch (error) {
        return 17;
    }
}
export function setRootCssVariable(key: string, value: string) {
    try {
        return document.documentElement.style.setProperty(key, value);
    } catch (err) {

    }
}
export function getRootCssVariable(key: string) {
    const styles = getComputedStyle(document.documentElement);
}

let siteName: string;

export function changeSiteMeta(title: string, description?: string, options?: {
    previewImg?: string,
    siteName?: string,
}) {
    try {
        const siteSignStr = (options?.siteName || siteName) ? (' | ' + (options?.siteName || siteName)) : '';
        if (title) {
            document.querySelector('title').innerHTML = title + siteSignStr;
        }
        document.querySelectorAll('head>meta')?.forEach(metaNode => {
            if (title) {
                if ((metaNode?.attributes as any)?.property?.textContent === 'og:title') {
                    metaNode.setAttribute('content', title + siteSignStr);
                }
                if ((metaNode?.attributes as any)?.property?.textContent === 'twitter:title') {
                    metaNode.setAttribute('content', title + siteSignStr);
                }
            }
            if (description) {
                if ((metaNode?.attributes as any)?.property?.textContent === 'og:description') {
                    metaNode.setAttribute('content', description);
                }
                if ((metaNode?.attributes as any)?.property?.textContent === 'twitter:description') {
                    metaNode.setAttribute('content', description);
                }
            }
            if (options?.previewImg) {
                if ((metaNode?.attributes as any)?.property?.textContent === 'og:image') {
                    metaNode.setAttribute('content', options?.previewImg);
                }
                if ((metaNode?.attributes as any)?.property?.textContent === 'twitter:image') {
                    metaNode.setAttribute('content', options?.previewImg);
                }
            }
            if (options?.siteName) {
                siteName = options?.siteName;
                if ((metaNode?.attributes as any)?.property?.textContent === 'og:site_name') {
                    metaNode.setAttribute('content', options?.siteName);
                }
                if ((metaNode?.attributes as any)?.property?.textContent === 'twitter:site_name') {
                    metaNode.setAttribute('content', options?.siteName);
                }
            }
        });
    } catch (error) {

    }
}

export function loadStyleSuccessEvent(node, callback, timeout?: number) {

    let isLoaded = false,
        maxTimeout = timeout || 6 * 1000,
        time = new Date().getTime(),
        curTime;

    function checkStyleLoaded() {
        // 通过link对象的属性判断,但无法检测跨域的CSS文件，以及非HTTP环境。
        if (node['sheet'] || node['stylesheet']) {
            if (node['sheet'] && node['sheet']['cssRules'] || node['stylesheet'] && node['stylesheet']['rules']) {
                isLoaded = true;
            } else {
                curTime = new Date().getTime();
            }
        } else {
            curTime = new Date().getTime();
        }

        // 尝试是否可执行onload，对于Firefox可能不兼容
        node.addEventListener('load', function (e) {
            isLoaded = true;
        }, false);

        // 6s钟的超时时间
        if (curTime - time > maxTimeout) {
            isLoaded = true;
        }

        if (isLoaded) {
            callback && callback();
        } else {
            setTimeout(checkStyleLoaded, 100);
        }
    }

    if (node.attachEvent) { // IE 6 ~ 8 直接绑定onload事件
        node.attachEvent('onload', callback);
    } else {
        setTimeout(checkStyleLoaded, 0);
    }
}


export interface IColorObj {
    r: number;
    g: number;
    b: number;
    a?: number;
}

/**
 * 255颜色值转16进制颜色值
 * @param n 255颜色值
 * @returns hex 16进制颜色值
 */
export const toHex = (n: number) => {
    return `${n > 15 ? '' : 0}${n.toString(16)}`;
}

/**
 * 颜色对象转化为16进制颜色字符串
 * @param colorObj 颜色对象
 */
export const toHexString = (colorObj: IColorObj) => {
    const { r, g, b, a = 1 } = colorObj;
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${a === 1 ? '' : toHex(Math.floor(a * 255))}`;
};


/**
 * 颜色对象转化为rgb颜色字符串
 * @param colorObj 颜色对象
 */
export const toRgbString = (colorObj: IColorObj) => {
    const { r, g, b } = colorObj;
    return `rgb(${r},${g},${b})`;
};

/**
 * 颜色对象转化为rgba颜色字符串
 * @param colorObj 颜色对象
 */
export const toRgbaString = (colorObj: IColorObj, n = 10000) => {
    const { r, g, b, a = 1 } = colorObj;
    return `rgba(${r},${g},${b},${Math.floor(a * n) / n})`;
};

/**
 * 16进制颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const parseHexColor = (color: string) => {
    let hex = color.slice(1);
    let a = 1;
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    if (hex.length === 8) {
        a = parseInt(hex.slice(6), 16) / 255;
        hex = hex.slice(0, 6);
    }
    const bigint = parseInt(hex, 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
        a,
    } as IColorObj;
};

/**
 * rgba颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const parseRgbaColor = (color: string) => {
    const arr = color.match(/(\d(\.\d+)?)+/g) || [];
    if (arr?.length === 3) {
        arr.push('1');
    }
    const res = arr.map((s: string) => parseInt(s, 10));
    return {
        r: res[0],
        g: res[1],
        b: res[2],
        a: parseFloat(arr[3]),
    } as IColorObj;
};

/**
 * 颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const parseColorString = (color: string) => {
    const tempColor = String(color)?.replace(' ', '');
    if (tempColor.startsWith('#')) {
        return parseHexColor(tempColor);
    } else if (tempColor.startsWith('rgb')) {
        return parseRgbaColor(tempColor);
    } else if (tempColor === 'transparent') {
        return parseHexColor('#00000000');
    }
    throw new Error(`color string error: ${tempColor}`);
};

/**
 * 颜色字符串解析为各种颜色表达方式
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const getColorInfo = (color: string) => {
    const colorObj = parseColorString(color);
    const hex = toHexString(colorObj);
    const rgba = toRgbaString(colorObj);
    const rgb = toRgbString(colorObj);
    return {
        hex,
        rgba,
        rgb,
        rgbaObj: colorObj,
    };
};

/**
 * 16进制颜色字符串转化为rgba颜色字符串
 * @param hex 16进制颜色字符串
 * @returns rgba颜色字符串
 */
export const hexToRgba = (hex: string) => {
    const colorObj = parseColorString(hex);
    return toRgbaString(colorObj);
};

/**
 * rgba颜色字符串转化为16进制颜色字符串
 * @param rgba rgba颜色字符串
 * @returns 16进制颜色字符串
 */
export const rgbaToHex = (rgba: string) => {
    const colorObj = parseColorString(rgba);
    return toHexString(colorObj);
};

export const colorToHex = (value: string) => {
    if (value?.includes('rgb')) {
        return rgbaToHex(value);
    } else {
        return rgbaToHex(hexToRgba(value));
    }
}

// hexToRgba('#a53eda') // rgba(165,62,218,1)
// rgbaToHex('rgba(165,62,218,1)') // #a53eda

// parseColorString('#a53eda') // {r: 165, g: 62, b: 218, a: 1}
// parseColorString('rgba(165,62,218,0.5)') // {r: 165, g: 62, b: 218, a: 0.5}

// toHexString({r: 165, g: 62, b: 218, a: 0.5}) // #a53eda7f
// toRgbaString({r: 165, g: 62, b: 218, a: 0.5}) // rgba(165,62,218,0.5)

// 检测浏览器是否符合能自动播放视频作为背景视频
export function checkVideoCanAutoPlay() {
    if (!navigator) {
        return false;
    }
    // Not supported yet:
    // if (navigator.getAutoplayPolicy) {
    //     let policy = navigator.getAutoplayPolicy();
    //     if (policy === "allowed" || policy === "allowed-muted") {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    let userAgent = navigator.userAgent;
    let isDesktop = !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    let isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Android') > -1;
    let isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('iPhone') > -1;
    let chromeVersion = parseInt((userAgent.match(/Chrome\/(\d+)/) || [])[1]);
    let safariVersion = parseFloat((userAgent.match(/Version\/(\d+\.\d+)/) || [])[1]);

    if (isDesktop) {
        return true;
    } else if (isChrome && chromeVersion >= 112) {
        return true;
    } else if (isSafari && safariVersion >= 16.3) {
        return true;
    } else {
        return false;
    }
}
