import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Observable, of, BehaviorSubject, firstValueFrom } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../../app.constants';
import { IShopSetting, ShopSetting } from '../../../core/model/config.model';
import { MobileAppConfig } from '../../../core/model/mobile-config.model';
import { SiteContextService, SiteSetting } from '../../../shared/services/site-context.service';

const headers = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({ providedIn: 'root' })
export class ShopContextService {
    private _shopSetting$: BehaviorSubject<ShopSetting> = new BehaviorSubject<ShopSetting>(null);
    private _shopId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private siteContextService: SiteContextService,
    ) { }

    async initialize(): Promise<ShopSetting>{
        if (this.siteContextService.enabledEcommerce) {
            return  firstValueFrom(this.getShopIdBySiteId(this.siteContextService.siteId)
                .pipe(mergeMap(shopId => this.getShopSetting())));
        } else {
            return Promise.resolve(null);
        }
    }

    get shopId(): string {
        if (this._shopId$.getValue()) {
            return this._shopId$.getValue();
        } else {
            return null;
        }
    };

    get shopSetting(): ShopSetting {
        if (this._shopSetting$.value) {
            return this._shopSetting$.value;
        } else {
            return null;
        }
    }

    private getShopIdBySiteId(siteId: string): Observable<string>  {
        return this.http.get<SiteSetting[]>(API_SERVICE_PREFIX + `/store/api/site-settings`, { headers: headers, params: {'siteId.equals': siteId}})
        .pipe(map(res => {
            if (res?.length) {
                return res[0]?.shopId;
            }
            return null;
        }))
        .pipe(tap((shopId) => {
            if (shopId && (shopId !== this._shopId$.value)) {
                this._shopId$.next(shopId);
            }
        }));
    };

    private getShopSetting(): Observable<ShopSetting> {
        if (this.shopId) {
            return this.queryShopSetting(this.shopId).pipe(mergeMap(shopSettings => {
                if (!shopSettings?.length) {
                    return of(null);
                }
                const shopSetting = shopSettings[0];
                try {
                    if (!shopSetting?.mobileAppConfig) {
                        shopSetting.mobileAppConfig = new MobileAppConfig();
                    }
                    if (typeof shopSetting?.mobileAppConfig === 'string') {
                        shopSetting.mobileAppConfig = new MobileAppConfig(shopSetting?.mobileAppConfig);
                    }
                } catch (error) {

                }
                if (shopSetting && (JSON.stringify(shopSetting) !== JSON.stringify(this._shopSetting$.value))) {
                    this.localStorage.store('shopSetting', shopSetting);
                    this.sessionStorage.store('shopSetting', shopSetting);
                    this._shopSetting$.next(shopSetting);
                }
                return of(shopSetting);
            }),
            catchError(err => {
                const shopSettingConfigFromStorage = this.localStorage.retrieve('shopSetting') || this.sessionStorage.retrieve('shopSetting');
                if (shopSettingConfigFromStorage && !shopSettingConfigFromStorage?.mobileAppConfig) {
                    shopSettingConfigFromStorage.mobileAppConfig = new MobileAppConfig();
                }
                return of(new ShopSetting(shopSettingConfigFromStorage));
            }));
        }
        return of(null);
    }

    private queryShopSetting(shopId: string): Observable<IShopSetting[]> {
        return this.http.get<IShopSetting[]>(API_SERVICE_PREFIX +
            `/store/api/shop/${shopId}/shop-settings?timestamp=${new Date().getTime()}`
        );
    }
}
