export const sitesTranslate = {
    ERROR: {
        CREATE_SITE_ERROR: '創建網站應用出錯：',
        COMPANY_INFO_ERROR: '獲取公司資訊出錯：',
        GET_SITE_ERROR: '獲取網站應用出錯：',
        UPDATE_SITE_ERROR: '更新站点应用出错：',
        NOT_FOUND_SITE: '當前頁面找不到對應的網站，請檢查後重新進入',
        GET_APP_ERROR: '獲取應用出錯：',
        LOAD_PAGE_EMPTY: '該頁面還在裝修中，請稍後再試吧',
        LOAD_SITE_ERROR: '網站目前暫不可用，請聯繫系統管理員 或 稍後再試吧',
    },
    GENERAL: {
        CURRENT_APPLICATION: '當前應用',
        NOTYETOPEN_CANNOTBEUSED: '暫未開通，無法使用！'
    }
};