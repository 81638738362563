import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationStart, Router } from '@angular/router';
import crypt from '../../assets/js/tools/crypt';
import { Site } from './model/site.model';
import { DOCUMENT } from '@angular/common';
declare const wx: any;

export enum ActionType {
    // 导航动作，比如转发给好友后，人家通过该消息点击进入小程序进入某个页面
    NAVIGATE = 'NAVIGATE',
    // 授权动作（登陆后该值用于标识是一个授权结果）
    AUTHORIZATION = 'AUTHORIZATION',
    // 支付动作
    PAY = 'PAY',
    // 分享动作
    SHARE = 'SHARE',
}

export interface IMessage {
    type: ActionType;
    body?: IShareMessage | INavigateMessage;
}

export interface IShareMessage {
    // 分享信息
    wxShare?: Site;
    // 信息携带的链接
    shareLink?: string;
}

export interface INavigateMessage {
    // 前往到哪个地址
    to?: string;
    // 从哪个地址来
    from?: string;
}

export interface IShareMessage {
    wxShare?: Site;
    shareLink?: string;
}

@Injectable({ providedIn: 'root' })
export class WebviewAdaptorForMiniprogramService {
    private _window: Window | null = null;
    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private http: HttpClient,
        private router: Router,
    ) {
        this._window = this._document.defaultView;
    }
    // h5向小程序发送信息
    postMessage(message: IMessage) {
        try {
            wx?.miniProgram?.getEnv(res => {
                const isMiniProgram = res?.miniprogram;
                if (!isMiniProgram) { return; }
                wx?.miniProgram?.postMessage({ data: message });
            });
        } catch (error) {

        }
    }
    // h5向小程序发送分享信息
    postShareMessage(site: Site, shareLink?: string) {
        let shareUrl = '';
        shareUrl = shareLink ? shareLink : this._window?.location?.href;
        shareUrl = shareUrl?.replace(this._window?.location?.origin, '');
        this.postMessage({
            type: ActionType.SHARE,
            body: { wxShare: site, shareLink: shareUrl }
        });
    }
    // 解析当前的浏览器地址来获取小程序传递过来的信息
    parseHashQueryParams(url: string): string | undefined {
        if (!url) { return undefined; }
        try {
            const resultForMiniProgramParams = url.match(/#\/miniprogrameurl=(.*?)/);
            // alert('当前网址提取：' + JSON.stringify(resultForMiniProgramParams))
            if (resultForMiniProgramParams.length > 1) {
                const miniProgramParams = resultForMiniProgramParams[1];
                return miniProgramParams;
            }
            return undefined;
        } catch (error) {
            return undefined;
        }
    }
    // 订阅路由变更事件来使得小程序和h5能进行交互
    subscribeMiniprogramMessageByRouterEventChange(url: string) {
        this.router.events.subscribe(event => {
            if ((event as NavigationStart)?.url?.includes('#/miniprogrameurl=')) {
                const messageStr = this.parseHashQueryParams(url);
                if (!messageStr) { return; }
                const message: IMessage = JSON.parse(crypt.decryptByBase64(messageStr));
                if (message) {
                    switch (message.type) {
                        // 监听到小程序通知跳转到哪
                        case ActionType.NAVIGATE:
                            this.navigateToByMiniprogramMessage(message.body as INavigateMessage);
                            break;

                        default:
                            break;
                    }
                }
            }
        });
    }
    // 根据小程序传递过来的信息跳转到某个页面
    navigateToByMiniprogramMessage(message: INavigateMessage): void {
        // 会重新加载
        // this._window.location.href = targetUrl;
        // 只改变地址，不重新加载
        // this._window.history.pushState({}, '新页面', targetUrl)
        // 通过路由切换，不会加载
        this.router.navigate([message?.to], {
            skipLocationChange: false,
            replaceUrl: true,
            queryParams: {
                from: message?.from
            }
        });
    }
    // h5跳转到小程序某个页面，返回的调整结果不是特别可靠
    navigateToMiniprogramPage(pathForMiniprogramPage, successCb: Function = () => { }, failCb: Function = () => { }): boolean {
        if (!pathForMiniprogramPage) { return false; }
        wx.miniProgram.navigateTo({
            url: pathForMiniprogramPage,
            success: successCb,
            fail: failCb
        });
        return true;
    }
    // 跳转到小程序登录页
    navigateToLoginPageOfMP(): boolean {
        // 跳转到微信的授权页
        return this.navigateToMiniprogramPage({
            url: '/pages/login/index?appIcon=xxx&&themeColor=red',
            // events参数貌似在sdk跳转里无效
            success: function (res) {
                //   如果授权成功的话，可通过监听hash变化直接处理，也可以在这里接受数据处理
                // 如果失败的话，做某些操作，比如显示授权失败是否重新获取之类的
            }
        });
        // 情况： 从其他地方进来的，如个人中心进来：H5检测hash地址传递过来的token参数（加密过）直接登录后前往原目的页（无需刷新webview）
    }
    // 判断是否是微信内的web-view环境 - 同步的方法，最低版本：微信7.0.0
    isWebviewOfWechat(): boolean {
        try {
            return navigator.userAgent.toLowerCase().includes('miniProgram');
        } catch (error) {
            return false;
        }
    }
}
