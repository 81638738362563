import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-nothing',
    templateUrl: './nothing.component.html',
    styleUrls: ['./nothing.component.scss']
})
export class NothingComponent {
    @Input() nothingImageClass: string;
    @Input() nothingImage: string;
    @Input() nothingText: string;
    constructor() { }

}
