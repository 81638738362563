import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { CurrentDeviceService } from '../../../core/services/current-device.service';
import { GroupBuyingCampaignsService } from '../../shared/service/group-buying-campaigns.service';
import { QueryType } from '../../../core/model/query-type.enum';
import { IProduct } from '../../shared/model/product.model';
import { HttpErrorHandlerUtil } from '../../../core/utilities/http-error-handler-util';
import { jsonParse, NameTranslatePipe } from '../../../shared/pipes/name-translate.pipe';
import { SiteContextService } from '../../../shared/services/site-context.service';
import { ProductService } from '../../shared/service/product.service';
import { ShopContextService } from '../../shared/service/shop-context.service';
import groupBuyingTranform from '../../shared/utils/group-buying-tranform';

@Component({
    selector: 'app-products-block',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    providers: [NameTranslatePipe]
})
export class ProductsBlockComponent implements OnChanges, OnInit {
    @Input() blockId: string = '';
    @Input() productsType: string;
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() titleRenderOption = {};
    @Input() salesVisible = true;
    @Input() moreProductsQueryParams = {
        queryType: QueryType.labelProduct,
    }
    @Input() renderOptions: any = {};
    @Input() section: string;
    products = [];
    loadProductsFailed: boolean = false;
    loadProductsFailedMsg: string;
    loadingProducts: boolean = true;
    showMoreProducts: boolean;
    productName: string = '商品';
    constructor(
        private translateService: TranslateService,
        private productService: ProductService,
        public currentDeviceService: CurrentDeviceService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
        private groupBuyingCampaignsService: GroupBuyingCampaignsService,
        private siteContextService: SiteContextService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private shopContextService: ShopContextService,
        private nameTranslatePipe: NameTranslatePipe,
    ) { }

    ngOnInit(): void {
        this.productName = this.nameTranslatePipe.transform(this.shopContextService.shopSetting?.mobileAppConfig?.general, 'productName');
    }

    changeLoadFailedMsgByTranslate() {
        this.loadProductsFailedMsg = this.translateService.instant('GENERAL.LOAD_LABEL_PRODUCT_FAILED', { productName: this.productName });
    }

    getProducts() {
        const products = jsonParse(this.siteContextService.retrieveBlockStorageData(this.blockId));
        if (products?.length) {
            this.products = products;
            this.loadingProducts = false;
        }
        const shopId = this.shopContextService.shopId;
        if (!shopId) {
            this.loadProductsFailed = true;
            this.changeLoadFailedMsgByTranslate();
            this.loadingProducts = false;
            return;
        }
        let req = null;
        switch (this.productsType) {
            case 'PRESALE_PRODUCTS':
                req = this.getPreproduct(shopId);
                break;
            case 'GROUP_PURCHASING':
                req = this.getGroupBuyingProducts(shopId);
                break;
            case 'NEW_ARRIVAL':
                req = this.getLatestProducts(shopId);
                break;
            case 'BEST_SELLERS':
                req = this.getHotProducts(shopId);
                break;
            default:
                break;
        }
        return req.then((res: HttpResponse<IProduct[]>) => {
            this.loadProductsFailed = false;
            this.loadProductsFailedMsg = '';
            this.loadingProducts = false;
            this.products = res.body?.map((item: IProduct) => {
                item['img'] = item.imgUrl;
                return item;
            });
            if (this.products?.length) {
                this.groupBuyingCampaignsService.query({ 'productId.in': this.products?.map(product => product?.id) }).toPromise().then(result => {
                    this.products?.forEach(product => {
                        const findGroupBuyingCampaign = result.body?.find(groupBuyingCampaign => groupBuyingCampaign?.id === product?.id);
                        const groupBuyingCampaigns = groupBuyingTranform.initGroupBuyingCampaigns(findGroupBuyingCampaign, null);
                        product.requiredMembers = groupBuyingCampaigns.requiredMembers;
                        product.allowOnlyNewCustomer = groupBuyingCampaigns.allowOnlyNewCustomer;
                    });
                    this.siteContextService.setBlockStorageData(this.blockId, this.products);
                }).catch(() => { });
            }
            const total = parseInt(res.headers.get('x-total-count'), 10);
            this.showMoreProducts = Boolean(total > (this.currentDeviceService.isDesktop() && (this.renderOptions?.productImageSize !== 'BIG') ? 5 : 4));
            this.siteContextService.setBlockStorageData(this.blockId, this.products);
            this.changeDetectorRef.markForCheck();
        }).catch(err => {
            this.loadProductsFailed = true;
            this.changeLoadFailedMsgByTranslate();
            this.loadingProducts = false;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['productsType']?.firstChange) {
            this.getProducts();
        }
    }

    getLatestProducts(shopId: string) {
        return this.productService.queryAndStatistics({
            size: this.currentDeviceService.isDesktop() && (this.renderOptions?.productImageSize !== 'BIG') ? (this.renderOptions?.fullWidth ? 7 : 5) : 4,
            'onShelf.equals': true,
            sort: 'createdDate,desc',
            'hiddenInList.equals': false,
            'shopId.equals': shopId,
        }).pipe(take(1)).toPromise();
    }

    getHotProducts(shopId: string) {
        return this.productService.queryAndStatistics({
            'onShelf.equals': true,
            'hiddenInList.equals': false,
            'shopId.equals': shopId,
            size: this.currentDeviceService.isDesktop() && (this.renderOptions?.productImageSize !== 'BIG') ? (this.renderOptions?.fullWidth ? 7 : 5) : 4,
            page: 0,
            sort: 'productStatistics.saleQuantity,desc'
        }).pipe(take(1)).toPromise();
    }

    getPreproduct(shopId: string) {
        return this.productService
            .queryAndStatistics({
                'onShelf.equals': true,
                'hiddenInList.equals': false,
                'saleType.equals': 'PRESALE',
                page: 0,
                size: this.currentDeviceService.isDesktop() && (this.renderOptions?.productImageSize !== 'BIG') ? (this.renderOptions?.fullWidth ? 7 : 5) : 4,
                sort: 'createdDate,desc',
                'shopId.equals': shopId,
            }).pipe(take(1)).toPromise();
    }

    getGroupBuyingProducts(shopId: string) {
        return this.groupBuyingCampaignsService.query({
            'endTime.greaterThanOrEqual': new Date().toISOString(),
            size: this.currentDeviceService.isDesktop() && (this.renderOptions?.productImageSize !== 'BIG') ? (this.renderOptions?.fullWidth ? 7 : 5) : 4,
            page: 0,
            'suspended.equals': false
        }).pipe(take(1), mergeMap(res => {
            if (!res.body || !res.body.length) {
                return of(res);
            }
            return this.productService.queryAndStatistics({
                'id.in': res.body.map(groupInfo => groupInfo.productId).join(','),
                'onShelf.equals': true,
                'shopId.equals': shopId,
            }).pipe(take(1));
        })).toPromise();
    }

    navigateMorePage(path: string) {
        this.router.navigate([path], {
            queryParams: {
                ...this.moreProductsQueryParams,
                productImageSize: (this.renderOptions?.productImageSize === 'BIG') ? 'BIG' : 'SMALL',
                originalPriceVisible: this.renderOptions?.originalPriceVisible,
                taxIncludedVisible: this.renderOptions?.taxIncludedVisible,
                enableDesktopMarginRightAuto: this.renderOptions?.fullWidth,
                enableAddShoppingCartBtn: this.renderOptions?.enableShoppingCartBtn,
                salesVisible: this.salesVisible,
            }
        });
    }
}
