import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpErrorHandlerUtil } from '../utilities/http-error-handler-util';
import { MessageAlertService } from '../services/message-alert.service';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    constructor(
        private messageAlertService: MessageAlertService,
        public httpErrorHandlerUtil: HttpErrorHandlerUtil,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err instanceof HttpErrorResponse && JSON.parse(request.headers.get('brics-api-error-notify'))) {
                            this.httpErrorHandlerUtil.errHandle(err)
                                .catch(e => {
                                    this.messageAlertService.send('error', e.msg);
                                });
                        }
                    }
                }
            )
        );
    }
}
