import { accountManagementTranslate } from './_modules/account-management.translate';
import { affiliateTranslate } from './_modules/affiliate.translate';
import { authTranslate } from './_modules/auth.translate';
import { contentManagementTranslate } from './_modules/content-management.translate';
import { invoiceCenterTranslate } from './_modules/invoice-center.translate';
import { menuTranslate } from './_modules/menu.translate';
import { orderTranslate } from './_modules/order.translate';
import { sitesTranslate } from './_modules/sites.translate';
import { systemSettingTranslate } from './_modules/system-setting.translate';
import { userTranslate } from './_modules/user.translate';

// China
export const locale = {
    lang: 'zh-CN',
    data: {
        AUTH: authTranslate,
        MENU: menuTranslate,
        ORDER: orderTranslate,
        ACCOUNT_MANAGEMENT: accountManagementTranslate,
        SYSTEM_SETTING: systemSettingTranslate,
        SITES: sitesTranslate,
        CONTENT_MANAGEMENT: contentManagementTranslate,
        AFFILIATE: affiliateTranslate,
        INVOICE_CENTER: invoiceCenterTranslate,
        GENERAL: {
            OWNER: '所有者',
            UNPAID: '未支付',
            PAID: '已支付',
            EXPIRED: '失效',
            INVOICE_CENTER: '我的发票',
            SERIES_PAGE: '系列商品',
            CALLING_CODE_SELECTOR_TITLE: '国家/地区码选择',
            CALLING_CODE_SELECTOR_PLACEHOLDER: '搜索国家/地区',
            REFERRAL_HISTORY_TEXT: '推荐记录',
            RULE_TEXT: '活动规则',
            REFERRAL_ACTIVITY_TITLE_TEXT: '邀好友，赢奖励',
            SHARE_TO_NEW_PEOPLE_TEXT: '复制分享链接',
            COPY_YOUR_REFERRAL_CODE_TEXT: '复制推荐码：{{myReferralCode}}',
            YOUR_REFERRAL_PERSON_TEXT: '我的推荐人：{{referrerId}}',
            SAVE: '提交',
            LOAD_ORDER_INFO_TIPS: '计算订单数据中，请稍后……',
            LOAD_INFO_TIPS: '加载数据中，请稍后……',
            TAX_INCLUDED: '含税',
            CANT_CREATE_ORDER_FOR_ERROR_MESSAGE: '当前订单不存在或者数据不合法，请重新提交吧',
            GROUP_BUYING_CREATER_DISCOUNT: '团长优惠',
            GET_USER_INFO_FAILED: '获取身份信息失败，请稍后再试吧！',
            PICK_UP_PHONE_INVALID_TIPS: '提货人手机号格式不正确',
            PICK_UP_NAME_EMPTY_TIPS: '提货人姓名不能为空',
            PICK_UP_TIME_TIPS: '选择自提时间',
            ORDINARY_EXPRESS: '普通快递',
            GROUP_BUYING_FREE_SHIPPING: '团购包邮',
            FREE_SHIPPING: '包邮',
            PHONE: '手机号码',
            HOW_TO_GET_THE_CARD_PASSWORD: '卡密码获取方式',
            HOW_TO_GET_THE_CARD_PASSWORD_DESC: '支付成功后需凭此方式获取卡密码',
            GIFT_CARD_INFORMATION: '礼品卡信息',
            ALL_BOUND_TO_CURRENT_ACCOUNT: '全部绑定到当前账号',
            LOGISTICS_COMPANY_NUMBER_INVALID_ERROR: '物流单号格式不正确',
            LOGISTICS_COMPANY_NAME_EMPTY_ERROR: '物流公司名称不能为空',
            FILL_IN_THE_LOGISTICS_COMPANY: '填写物流公司',
            EVALUATE_SUCCESS: '评价成功',
            EVALUATE_INVALID_ERROR: '评价最少5个字！！！',
            QUERY_BTN_TEXT: '查询',
            ADD_BTN_TEXT: '添加',
            EDIT_BTN_TEXT: '编辑',
            FILL_IN_BTN_TEXT: '填写',
            DELETE_BTN_TEXT: '删除',
            SAVE_BTN_TEXT: '保存',
            CANCEL_BTN_TEXT: '取消',
            CHANGE_TEXT: '修改',
            LOGIN: '登录',
            REGISTER: '注册',
            REGISTER_BTN_TEXT: '创建新账号',
            SEARCH: '搜索',
            PRODUCT_DEFAULT_NAME: '商品',
            INDEX: '首页',
            CUSTOMERIZE: '定制',
            CATEGORY: '分类',
            FEATURE: '特色',
            SHOPPING_CART: '购物车',
            ABOUT: '关于',
            USER_CENTER: '个人中心',
            CUSTOMIZE: '自定义',
            LOAD: '加载',
            LOADING: '加载中',
            SIGN_OUT_SUCCESSFULLY: '退出登录成功',
            WECHAT_BINDING_IS_SUCCESSFUL: '微信绑定成功！',
            WECHAT_BINDING_FAILED: '微信绑定失败',
            HOT_SALE: '热卖',
            LABEL: '标签',
            NEW: '最新',
            PRE_SALE: '预售',
            SERIES: '系列',
            FILL_OUT: '去填写',
            SEE_MORE: '查看更多',
            CURRENTLY_NO_RECORDS: '抱歉，暂时没有对应的记录',
            FAILED: '失败',
            RELOAD_BTN_TEXT: '刷新重试',
            NONAME: '匿名',
            NONE: '无',
            SETTING: '设置',
            LOGIN_REGISTER: '登录/注册',
            ALL: '全部',
            LOADED_ALL: '已加载全部',
            LOAD_FAILED: '加载失败',
            LOAD_POST_FAILED: '文章找不到或已删除',
            LOAD_PRODUCT_FAILED: '{{productName}}找不到或已下架',
            LOAD_FORM_FAILED: '表单找不到或已删除',
            LOAD_LABEL_PRODUCT_FAILED: '相关{{productName}}找不到或已下架',
            RE_APPLY_BTN_TEXT: '再次申请',
            REVIEW_APPLIED_CONTENT_BTN_TEXT: '查看已填写',
            GO_TO_HOME_BTN_TEXT: '前往首页',
            SUBMIT: '提交',
            SOME_ERROR_OCCUR: '出现异常',
            TIPS: '提示',
            SUBMITTED_SUCCESSFULLY: '提交成功',
            SUBMITTED_FAILED: '提交失败',
            RETURNING_TO_HOME_PAGE_TIPS: '正在为您返回首页',
            RETURNING_TO_HOME_PAGE_SECONDS_TIPS: '{{secondNumber}}秒后，自动为您返回首页',
            PAGE_NOT_FOUND: '找不到页面',
            SELECT_ALL_BTN_TEXT: '全选',
            INFO: '信息',
            UNIT_PRICE: '单价',
            QUANTITY: '数量',
            PRICE: '金额',
            OPERATION: '操作',
            MODIFY_AMOUNT: '修改金额',
            OFF_SHELF: '已下架',
            GO_TO_SETTLEMENT: '去结算',
            TOTAL: '共计',
            SELECTED: '已选',
            PIECES: '件',
            OUT_OF_STOCK: '不能再增加了',
            OFF_STOCK: '无货',
            ONLY: '仅剩',
            UPDATE_FAILED: '加载失败',
            CALC_DELIVERY_FAILED: '计算配送费调用失败,请检查网络设置后重试',
            SUCCESS: '成功',
            DELETING_DATA_IN_PROGRESS: '删除中',
            LET_ME_THINK_AGAIN: '我再想想',
            YOU_DIDNT_SELECTED_SOMETHING: '你没有进行选择噢！',
            NO_PURCHASABLE_ITEMS_TO_CHOOSE_TIPS: '无可购买的商品来选择哦！',
            PLEASE_SELECTED: '请选择',
            WHETHER_TO_DELETE: '是否删除',
            INCORRECT_FORMAT_TIPS: '格式不正确',
            PRICE_OF_PRODUCT: '价格',
            ORIGINAL_PRICE_OF_PRODUCT: '原价',
            SALE_QUANTITY: '已售',
            PRODUCT_TOTAL_COMMENTS: '累计评论',
            COUPON: '优惠劵',
            THRESHOLD_FREE_COUPONS: '无门槛优惠券',
            INVITE_NEW_PARTICIPANTS: '邀新参团',
            INVITE_NEW_PARTICIPANTS_TIPS: '限新用户参团，人满发货，人不满退款',
            ENTER_THE_PURCHASE_AMOUNT_TIPS: '请输入购买量',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS: '阶梯拼团',
            SPECIAL_REQUIREMENT_PARAMS: '特殊要求',
            FAVORITES: '收藏',
            COLLECT: '收藏',
            COLLECTED: '已收藏',
            SHARE: '分享',
            COPY_LINK: '复制链接',
            WECHAT_SCAN: '微信扫一扫',
            RELATED_ARTICLES: '相关文章',
            WANT_CREATE_GROUP_BUYING: '我要开团',
            FULL_PAYMENT: '全款支付',
            EXPECTED: '预计',
            INDIVIDUAL_PURCHASE: '单独购买',
            ADD_TO_SHOPPINGCART: '加入购物车',
            ADD_TO_SHOPPINGCART_BTN_TEXT: '加入购物车',
            PRE_SALE_PRICE: '预售价',
            PARAMETER: '参数',
            COMMENTS: '评价',
            SEE_ALL_PRODUCT_COMMENTS: '查看全部评论',
            RECEIVED: '已领取',
            RECEIVE: '领取',
            START_TIME: '开始时间',
            END_TIME: '结束时间',
            PRODUCT_PROPERTIES: '产品属性',
            PURCHASE_QUANTITY: '购买数量',
            BUY_NOW: '立即购买',
            DISCOUNT_OF_COUPON: '满{{minAmountToUse}}减{{offValue}}',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER: '每单限购{{maxQuantityOfOrder}}件',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER_TIPS: '超出单次限购数量',
            PRODUCT_IS_SOLD_OUT: '{{productName}}已售罄',
            SOLD_OUT: '已售罄',
            INVENTORY: '库存',
            GROUP_BUYING_FOR_PEOPLE_NUMBER: '{{price}}/{{peopleNumber}}人团',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS_TEXT: '{{requiredMembers}}人拼团',
            GET_THE_COUPON: '领券',
            JOIN_THE_GOURP_BUYING_DESC: '这些人刚刚拼单成功，可参与拼团',
            SOMEONE_GROUP: '的团',
            ONLY_FOR_GROUP_BUYING: '只差',
            PERSON_LEFT_IN_THE_GROUP: '人成团',
            TO_INVITE: '去邀请',
            GO_TO_THE_GROUP: '去拼团',
            NO_DETAILS_FOR_PRODUCT: '暂无{{productName}}详情',
            SOME_DAYS_AFTER_PAYMENT: '付款后{{presaleDeliveryDaysAfterPayment}}天内',
            SHIP: '发货',
            COUPON_VALIDITY_PERIOD_TIPS: '领券当日开始{{validityDays}}天有效',
            PRESALE_DELIVERY_FIXED_DATE: '{{value}}前',
            DESCRIPTION: '介绍',
            PRODUCT_QUANTITY_EXCEEDS_AVAILABLE_STOCK: '数量超过可用库存数',
            ADD_TO_CART_SUCCESSFUL: '加入购物车成功',
            ADD_FAILED: '添加失败',
            LIMIT_MIN_NUMBER_OF_PRODUCT_TIPS: '{{productName}}数不能少于1',
            ANONYMOUS_USER: '匿名用户',
            LIMIT_MAX_STOCK_NUMBER_OF_PRODUCT_TIPS: '不能大于当前{{productName}}库存数！',
            NETWORK_CONNECTION_FAILED: '网络连接失败！',
            BUY_PRODUCT_ERROR_TIPS: '数据获取失败，该{{productName}}找不到或已下架',
            SHARE_INFO_FOR_PRESALE_TIPS: '我发现了个好{{productName}}，目前正在预售中，快来看看吧!!!',
            SHARE_INFO_FOR_GROUP_BUYING_TIPS: '我发现了个好{{productName}}，目前正在团购促销中，快来看看吧!!!',
            SHARE_INFO_FOR_PRODUCT_TIPS: '我发现了个好{{productName}}，快来看看吧!!!',
            UNFAVORITE_SUCCESSFUL_TIPS: '取消收藏成功',
            UNFAVORITE_FAILED_TIPS: '取消收藏失败',
            FAVORITE_SUCCESSFUL_TIPS: '收藏成功',
            FAVORITE_FAILED_TIPS: '收藏失败',
            LOGIN_CHECKING: '登录检测中',
            RECEIVING: '领取中',
            RECEIVED_SUCCESSFULLY: '领取成功',
            RECEIVED_FAILED: '领取失败,请联系客服!',
            FAILED_TO_LOAD: '加载失败',
            VIEW_ORDER_DETAILS: '查看订单详情',
            GO_TO_ORDER_LIST: '前往订单列表',
            GO_TO_HOME_TIPS: '去首页逛逛',
            GROUP_BUYING_CREATER: '团长',
            PARTICIPATE: '拼单',
            PEOPLE: '人',
            PLEASE_WAIT: '请稍候',
            ENTER_SEARCH_CONTENT: '请输入搜索内容',
            SEARCH_HISTORY: '搜索历史',
            NOT_SEARCHED_RELATED_PRODUCTS: '抱歉，没有搜索到相关的内容',
            INPUT_INVALID_ERROR: '请检查您的输入是否有误',
            SEARCH_FAILED: '搜索失败，请稍后再试吧',
            SURE: '确定',
            DELETE_SEARCH_WORD_FAILED: '清除失败，请稍后再试吧',
            CONFIRM_BEFORE_CLEARING_SEARCH_HISTORY: '你确定要将清除所有的搜索历史吗？',
            NO_SERIES: '无系列',
            FAILED_TO_LOAD_SERIES_DATA: '加载系列数据失败',
            NO_GIFT_CARD_CONTENT: '无礼品卡内容',
            SERIES_CARD: '主题卡片',
            NOT_FOUND_PRODUCTS: '无{{productName}}内容',
            NO_DATA: '暂无数据',
            NO_MORE_DATA: '暂无更多数据',
            ARTICLE_LIST: '文章列表',
            LIKE: '点赞',
            COMMENT_ARTICLE: '评论',
            ARTICLE_COMMENTS: '全部评论',
            LATEST: '最新',
            MOST_POPULAR: '最热',
            AUTHOER: '作者',
            CANCEL_REPLY: '取消回复',
            REPLY: '回复',
            UNLIKE: '取消点赞',
            LINK_COPIED_SUCCESSFULLY: '链接复制成功',
            THE_CURRENT_BROWSER_DOES_NOT_SUPPORT_COPYING: '当前浏览器不支持复制，请手动复制！',
            ARTICLE_DETAILS_TITLE: '文章详情',
            ONLY_TIME_LEFT_UNTIL_THE_EVENT_STARTS: '距离活动开始仅剩',
            ONLY_TIME_LEFT_UNTIL_THE_END_OF_THE_EVENT: '距离活动结束仅剩',
            MALE: '男',
            FEMALE: '女',
            OTHER: '其他',
            UNKNOWN: '未知',
            THOUSAND: '千',
            TEN_THOUSAND: '万',
            BILLION: '亿',
            BACK: '返回',
            HELLO: '你好',
            PERSONAL: '个人',
            ORGANIZE: '组织',
            PLEASE_SELECT_GENDER: '请选择性别',
            PLEASE_CHOOSE_THE_TYPE: '请选择类型',
            AVATAR_UPLOAD_FAILED: '头像上传失败',
            TRY_AGAIN_RECIVE: '请重新领取',
            SUCCESSFULLY_DELETED: '删除成功',
            FAILED_TO_DELETE: '删除失败',
            SET_AS_THE_DEFAULT_ADDRESS: '设为默认地址',
            SHIPPING_ADDRESS: '收货地址',
            SHIPPING_ADDRESS_PLACEHOLDER: '请输入收货地址',
            POSTAL_CODE: '邮政邮编',
            POSTAL_CODE_PLACEHOLDER: '请输入邮编',
            DEFAULT_ADDRESS: '默认地址',
            NO_ADDRESS_YET: '暂无地址',
            SET_SUCCESSFULLY: '设置成功',
            COMPLETE_ADDRESS_INFORMATION: '请完善地址信息',
            EMAIL_ADDRESS_TITLE: '填写接收发票的电子邮箱',
            EMAIL_ADDRESS: '电子邮箱',
            EMAIL_ADDRESS_PLACEHOLDER: '请输入电子邮箱',
            INVOICE_HEADER_TYPE_TITLE: '抬头类型',
            INVOICE_HEADER_TYPE_PLACEHOLDER: '选择抬头类型',
            COMPANY: '公司',
            INVOICE_HEADER_PLACEHOLDER: '请输入发票抬头',
            INVOICE_HEADER_CONTENT: '*发票抬头',
            INVOICE_HEADER_TITLE: '发票抬头',
            NSRSBH_PLACEHOLDER: '请输入纳税人识别号',
            NSRSBH_CONTENT: '*纳税人识别号',
            NSRSBH_TITLE: '纳税人识别号',
            PHONE_PLACEHOLDER: '请输入手机号码',
            PHONE_CONTENT: '*手机号',
            PHONE_TITLE: '手机号',
            INPUT_MORE_INFORMATION: '填写更多信息',
            BANK: '开户行',
            BANK_PLACEHOLDER: '请输入开户行',
            NICKNAME_TITLE: '个人姓名',
            NICKNAME_CONTENT: '*个人姓名',
            NICKNAME_PLACEHOLDER: '请输入姓名',
            FULL_DATE_FROMART_TEXT: '{{year}}年{{month}}月{{day}}日 {{hour}}:{{minute}}:{{second}}',
            SELECT_INVOICE_INFORMATION: '选择开票信息',
            FAILED_TO_APPLY_FOR_INVOICING: '申请开票失败，请联系管理员',
            FAILED_TO_APPLY_FOR_INVOICING_TIPS: '申请开票失败',
            INVOICING_WAS_SUCCESSFUL: '申请开票成功！',
            FAILED_TO_CREATE_INVOICE_HEADER: '创建发票抬头失败',
            REFUNDING: '退款中',
            PENDING_RETURN: '待退货',
            RETURNED_PENDING_REFUND: '已退货，待退款',
            REVIEW_FAILED: '审核未通过',
            IN_REVIEW: '审核中',
            REFUND_COMPLETE: '退款完成',
            REFUND: '退款',
            REFUND_APPLICATION_APPROVED: '退款申请审核通过',
            REFUND_INSTRUCTIONS: '退款须知',
            REFUND_BUTTON_TEXT: '我已阅读（去退款）',
        },
        SWEET_ALERT: {
            DEFAULT_TITLE: '你确认要进行这个操作吗？',
            DEFAULT_CONFIRM_BUTTON_TEXT: '确认',
            DEFAULT_CANCEL_BUTTON_TEXT: '取消',
        },
        PAGINATION: {
            TITLE: '显示第 {{page}} - {{lastIndex}} 条，共 {{total}} 条数据.',
            TEXT: '每页显示',
            Unit: '条'
        },
        USER: {
            ...userTranslate,
            LANGUAGE: '语言',
            LOGOUT: '退出',
        },
        FOOTER: {
            TEXT: '上海慧特信息技术有限公司',
            TERMS_OF_USE: '使用条款',
            TERMS_OF_REGISTRATION: '注册条款',
            TERMS_OF_PRIVACY_POLICY: '隐私条款',
            TERMS_OF_PURCHASE: '购买条款',
            CUSTOMER_SERVICE: '客户服务',
            CONTACT_CUSTOMER_SERVICE: '联系客服',
            FEEDBACK_BTN_TEXT: '我要反馈',
            AFTER_SALES_SERVICE: '售后服务',
            MERCHANT_SERVICES: '商家服务',
            AFFILIATE_SERVICES: '联盟网络',
            AFFILIATE_BTN_TEXT: '申请加入',
            MERCHANT_PORTAL: '管理后台',
            FOLLOW_US_FOR_MORE_INFORMATION: '关注我们获取更多信息',
            TECHNICAL_SUPPORT: '技术支持:',
        },
        API_ALERT_MESSAGE: {
            ERROR: {
                OFF_LINE: '请求出错，请检查您的网络是否连接……',
                UNAUTHORIZED: '您暂未登录或者登录状态已过期，请先登录后再进行操作……',
                FAILEDTOLAZILYINITIALIZE: '当前请求服务未启动成功，请联系客服或者管理员处理',
                UNKONWN: '未知错误，请检查网络再试吧；若继续出现，请联系管理员',
                ERROR_MESSAGE: '请求出错，错误信息：',
                DEFAULT: '请检查当前网络，或者当前接口出现未知错误……',
                COUPONNOTFEASIBLEFORGIFTCARD: '礼品卡不能使用优惠券抵扣哦~',
                INVALIDCARDTEMPLATEID: '订单中包含礼品卡商品，无法使用礼品卡支付哦~',
                OTPEXPIRED: '微信授权已过期，请重新授权哦~',
                SENDVERIFICATIONCODEERROR: '发送次数过多，请稍后或者更换号码再试吧',
                NOCUSTOMERRECORD: '当前账号信息不完整，请换个手机号再试或者联系管理员吧',
                NOTFOUND: '数据不存在或后端服务暂时不可用，此问题若继续出现，请联系管理员',
                METHODNOTALLOWED: '请求出错，此问题若继续出现，请联系管理员',
                INTERNALSERVERERROR: '服务器出现异常，此问题若继续出现，请联系管理员',
                GATEWAYTIMEOUT: '无法连接服务器，请检查网络再试吧',
                ERRNETWORKCHANGED: '无法连接服务器，请检查网络再试吧',
                METHODARGUMENTNOTVALID: '提交数据有误，请刷新再试吧；若继续出现，请重新访问本应用',
                BADGATEWAY: '服务器异常，请稍后访问本应用；若继续出现，请联系管理员',
                USERCOULDNOTBEFOUND: '用户不存在，请重新登录或者稍后再试吧',
                COULDNOTEXTRACTRESULTSET: '服务相关进程异常，请稍后访问本应用；若继续出现，请联系管理员',
                NOT_LOADED_SUCCESSFULLY_PLEASE_RE_ENTER: '未加载成功，请重新进入：',
                CMS: {
                    FORMNOTFOUND: '未找到该答卷对应的表单',
                    MISSINGREQUIREDRESPONSE: '部分必答题未提交答案',
                    INVALIDROWSFORMAT: '矩阵题型的行应该为 JSON 数组的字符串',
                    RESPONSENOTMATCHWITHROWCOUNT: '矩阵题的应答行数量与题目的行数量不一致',
                    MISSINGREQUIREDRESPONSEOFROW: '矩阵问题中有必答的行缺少答案',
                    MULTIPLERESPONSENOTALLOWED: '非矩阵问题不允许多条答案',
                    MISSINGRESPONSEVALUE: '应答中缺失答案',
                    EMPTYRESPONSEVALUE: '应答中答案为空字符串',
                    INVALIDDATETIMEFORMAT: '题目中不正确的日期/时间格式',
                    INVALIDINTEGERVALUE: '不正确的整数值',
                    INVALIDFLOATVALUE: '不正确的小数值',
                    DECIMALPLACENUMBEREXCEEDS: '小数位数超过了定义',
                    FILENUMBEREXCEEDS: '文件数量超过了限额',
                    INVALIDOPTIONSFORMAT: '不正确的选项格式，应该是 JSON 格式的 FormAttachment 数组',
                    INVALIDVALUE: '不符合规则的答案',
                    CANNOTFINDVALIDATOR: '找不到验证器的答案',
                    EMAILALREADYEXISTS: '已订阅成功！',
                },
                USER: {
                    PASSWORDFORMATINVALID: '密码格式未达到复杂度要求',
                    LOGINALREADYEXISTS: '登录名已经被他人占用了',
                    NOUSERCREDENTIAL: '获取不到用户记录，请用正确的账号登录',
                    ALREADYBOUNDMOBILE: '用户已经绑定了手机号',
                    USERNOTBOUNDMOBILE: '用户尚未绑定手机号',
                    MOBILENUMBERMISMATCH: '手机号与绑定手机号不一致',
                    MOBILEBOUNDBYOTHERUSER: '该手机号已被其他用户绑定',
                    INCORRECTPASSWORD: '密码不正确',
                    MOBILENOTREGISTERED: '此手机号码未注册',
                    PARAMETERNULL: '参数不能为空',
                    WECHATNOTREGISTERED: '此微信未注册',
                    USERNOTBOUNDWECHAT: '用户尚未绑定微信号',
                    WECHATBOUNDBYOTHERUSER: '该微信号已被其他用户绑定',
                    MOBILEALREADYREGISTERED: '此手机号已注册,请进行微信绑定操作',
                    REGISTERREQUIRED: '登录失败, 请在注册后进行登录',
                    WECHATALREADYREGISTERED: '此微信已注册,请登录或绑定新的微信号',
                    NOUSERBYWECHAT: '根据微信获取不到用户记录， 请联系技术支持',
                    CODENULL: 'code 不能为空',
                    WRONGPASSWORD: '密码错误，请检查您输入的密码',
                    FAILEDTOOMANYTIMES: '您尝试账号登录次数过多，更换登录方式或 {{value}} 分钟后重新尝试',
                    FAILEDTOOMANYTIMES_NO_DETAIL: '您尝试账号登录次数过多，更换登录方式或 稍后再试吧',
                    LOGINFAILED: '登录失败，请检查用户名或密码是否正确或更改登录方式，您已经尝试登录 {{loginNumber}} 次，超过 {{maxNumber}} 次将会导致您 {{timeNumber}} 分钟内无法使用账号登录',
                    LOGINFAILED_NO_DETAIL: '登录失败，请检查用户名或密码是否正确或更改登录方式，频繁登录将会导致您一段时间内无法使用账号登录哦',
                    CODEINVALID: '验证码已失效',
                    PASSWORDNOTENABLED: '未启用密码登录，请更换其他登录方法',
                    USER_DOES_NOT_EXIST: '用户不存在',
                }
            },
            SUCCESS: {
                TITLE: '成功！',
            }
        },
        LANGUAGE_SWITCH: {
            CHINESE: '简体中文',
            ENGLISH: '英语',
            JAPANESE: '日语',
            TRADITIONAL_CHINESE: '繁体中文'
        },
        INDEX: {
            USER_CENTER: '个人中心',
            MY_ORDERS: '我的订单',
            MY_GIFT_CARDS: '我的礼品卡',
            PERSONAL_INFORMATION: '个人资料',
            ACCOUNT_SECURITY: '账号安全',
            SIGN_OUT_BTN_TEXT: '退出登录',
            FAILED_TO_GET_TODAY_GROUP_PRODUCT: '获取今日拼团商品失败',
            HEADER: '头部',
            CAROUSEL: '轮播图',
            DIY_CONTENTS: '自定义',
            ANNOUNCEMENT: '公告',
            ANNOUNCEMENT_INFO: '公告信息',
            NEWS: '新闻资讯',
            GROUP_BUYING_PRODUCS: '今日必拼',
        },
        FLOATING_WINDOW: {
            GO_TO_SHOPCART: '购物车',
            USER_CENTER: '个人中心',
            CONTACT_CUSTOMER_SERVICE: '联系客服',
            BACK_TO_THE_TOP: '回到顶部',
            SHRINK_BTN_TEXT: '收缩',
            EXPAND_BTN_TEXT: '展开',
        },
        HOME: {
            AMOUNTS_PAYABLE: '应付金额',
            SELECT_SELF_PICK_UP_ADDRESS: '选择自提点',
            SELF_PICK_UP_ADDRESS: '自提点',
            SELLER_SENT_WRONG_PRODUCT: '卖家发错货',
            PRODUCT_STYLE_ERROR: '版本/批次/颜色等与{{productName}}描述不符',
            DAMAGED_PRODUCT_PACKAGING: '包装或{{productName}}破损',
            OTHER_REASONS: '其他原因',
            REASON_FOR_RETURN: '退款原因',
            REFUND_TYPE: '退款类型',
            FILL_IN_THE_LOGISTICS_ORDER_NUMBER: '填写物流单号',
            EVALUATE_THE_ORDER: '评价晒单',
            PRODUCT_NAME: '商品名称',
            PLEASE_SELECT_IMAGE_SOURCE: '请选择图片来源',
            PHONE_ALBUM: '手机相册',
            DELETE_INVOICE_TIPS: '你确定要删除这个开票信息吗？',
            INVOICE_CREATE_BTN_TEXT: '新建开票信息',
            NO_INVOICE_INFORMATION_YET: '暂无发票信息',
            TAX_ID: '税号',
            INVOICE_SETTING: '开票设置',
            ADDRESS_PLACEHOLDER: '请输入地址',
            BANK_ACCOUNT_PLACEHOLDER: '请输入银行账号',
            BANK_ACCOUNT: '账号',
            INVOICING_INFORMATION: '开票信息',
            VAT_ELECTRONIC_GENERAL_INVOICE: '增值税电子普通发票',
            VAT_SPECIAL_ELECTRONIC_INVOICE: '增值税专用电子发票',
            INVOICE_DOCUMENT: '发票文件',
            INVOICE_FILE_TYPE: '发票类型',
            INVOICE_HEADER_NAME: '抬头名称',
            CREATED_SUCCESSFULLY: '创建成功',
            VAT_ORDINARY_INVOICE: '增值税普通发票',
            VAT_SPECIAL_INVOICE: '增值税专用发票',
            INVOICE_NUMBER: '发票编号',
            INVOICE_TYPE: '开票类型',
            ELECTRONIC_INVOICE: '电子发票',
            MANUAL_INVOICE: '人工发票',
            INVOICE_AMOUNT: '开票金额',
            INVOICING_TIME: '开票时间',
            APPLICATION_TIME: '申请时间',
            FAILED_TO_CREATE: '创建失败',
            EMAIL_INVALID_ERROR: '邮箱未填写或格式输入有误',
            PHONE_INVALID_ERROR: '请填写正确的手机号码',
            ADDRESS_INVALID_ERROR: '收货地址不支持特殊符号',
            NSRSBH_INVALID_ERROR: '请输入正确的纳税人识别号',
            NAME_CANNOT_BE_EMPTY: '姓名不能为空',
            INVOICE_HEADER_CANNOT_BE_EMPTY: '发票抬头不能为空',
            FAILED_LOADED_ACCOUNT_INFORMATION_PLEASE_RETRY_AGAIN: '账号信息获取失败，请刷新重试',
            TO_BE_PAID: '待支付',
            TO_BE_JION: '待成团',
            PENDING_RECEIPT_OF_GOODS: '待收货',
            COMPLETED: '已完成',
            ALL_ORDERS: '全部订单',
            MY_SERVICES: '我的服务',
            ADDRESS_MANAGEMENT: '地址管理',
            MY_FAVORITES: '我的收藏',
            MY_COUPONS: '我的优惠券',
            MY_INVOICE: '发票抬头',
            ACCOUNT_SECURITY: '账户安全',
            INVITATION_WITH_PRIZE: '推荐有奖',
            FEEDBACK: '意见反馈',
            SUCCESSFUL_EXIT: '退出成功',
            FAILED_TO_GET_PERSONAL_INFORMATION: '获取个人信息失败！',
            SPARE_CHANGE_DETAILS: '零钱明细',
            MY_SPARE_CHANGE: '我的零钱',
            MY_WALLET: '我的钱包',
            BALANCE_DETAILS: '余额明细',
            CHOOSE: '选择',
            NO_PICK_UP_LOCATIONS_AVAILABLE: '暂无可选自提点',
            SUNDAY: '周日',
            MONDAY: '周一',
            TUESDAY: '周二',
            WEDNESDAY: '周三',
            THURSDAY: '周四',
            FRIDAY: '周五',
            SATURDAY: '周六',
            PICK_UP_TIPS: '请尽快到自提点提货',
            TODAY: '今天',
            TOMORROW: '明天',
            THE_DAY_AFTER_TOMORROW: '后天',
            ALL_DAY: '全天',
            MORNING: '上午',
            AFTERNOON: '下午',
            NIGHT: '晚上',
            FEEDBACK_CONTENT: '反馈内容',
            FEEDBACK_CONTENT_PLACEHOLDER: '反馈内容不少于五个字',
            FEEDBACK_CONTACT: '请填写手机号或者邮箱',
            CONTACT_DETAILS: '联系方式',
            FEEDBACK_POSTING: '提交中',
            FEEDBACK_FAILED: '请将联系方式与反馈内容补充完整',
            NO_CONSUMPTION_RECORD: '暂无消费记录',
            CONSUMPTION_RECORD: '消费记录',
            CONSUMPTION_RECORD_DETAILS: '消费记录明细',
            DESCRIBE: '描述',
            ORDER_ID: '订单ID',
            TRANSACTION_NUMBER: '交易流水号',
            TRANSACTION_TIME: '交易时间',
            TOTAL_GIFT_CARD_BALANCE: '礼品卡总余额',
            BUY_A_NEW_CARD: '购买新卡',
            BIND_NEW_CARD: '绑定新卡',
            USED: '可用',
            NOT_USED: '不可用',
            CHOOSE_IMG_FILE_TO_UPDATE_AVATAR: '更换头像需要选择一个图片文件！',
            CHANGE: '更换',
            NO_ACCOUNT_SECURITY_SETTINGS_INFORMATION: '暂无账户安全设置信息',
            LOGIN_SETUP: '登录名设置',
            CURRENT_LOGIN: '当前登录名：',
            HAS_BEEN_SET: '已设置',
            NOT_SET: '未设置',
            SET_LOGIN: '设置登录名',
            PASSWORD_SETUP: '密码设置',
            SET_PASSWORD: '设置密码',
            CHANGE_PASSWORD: '修改密码',
            SET_PHONE_BINDING: '手机绑定设置',
            SET_WECHAT_BINDING: '微信绑定设置',
            BIND_THE_PHONE: '绑定手机',
            CHANGE_PHONE: '更换手机',
            UNBOUND: '未绑定',
            BOUND: '已绑定',
            UNBOUND_WECHAT_ACCOUNT: '未绑定微信帐号',
            UNBIND_WECHAT: '解绑微信',
            BIND_WECHAT: '绑定微信',
            UNBOUND_AND_NOT_SET_PASSWORD: '当前账户未绑定手机号无法设置密码',
            GO_TO_BIND: '前往绑定',
            UNBIND_WECHAT_TIPS: '解绑后您将无法使用 微信 登录本网站，确定要解绑吗？',
            CONFIRM_UNBIND: '确认解绑',
            UNBIND_WECHAT_SUCCESSFULLY: '解绑当前账户绑定的微信成功！',
            WECHAT_SCAN_CODE_IS_NOT_ENABLED: '暂未开启扫码绑定，请在微信内打开本页面进行绑定',
            WECHAT_AUTHORIZATION_IS_NOT_ENABLED: '暂未开启授权绑定，请在浏览器内打开本页面进行绑定',
            THIS_FEATURE_IS_NOT_YET_AVAILABLE: '此功能暂未开放呀',
            LOGIN_NAME_EMPTY_ERROR: '未输入登录名，请检查',
            WRONG_FIELD_FORMAT: '{{name}}格式有误',
            CAPTCHA_VERIFY_CODE_PLACEHOLDER: '输入图片码',
            PLEASE_ENTER_A_NEW_PASSWORD: '请输入新密码',
            SUCCESSFULLY_MODIFIED: '修改成功',
            PHONE_VERIFICATION: '手机验证',
            PWD_VERIFICATION: '密码验证',
            WAYS_OF_IDENTIFYING: '验证方式',
            INPUT_OLD_PHONE_PLACEHOLDER: '请输入原手机号',
            OLD_PHONE: '原手机号',
            OLD_PHONE_VERIFICATION_CODE: '原手机验证码',
            NEW_PHONE__PLACEHOLDER: '请输入新手机号',
            NEW_PHONE_VERIFICATION_CODE: '新手机验证码',
            NEW_PHONE: '新手机号',
            MODIFY_PHONE_NUMBER: '修改手机号',
            CAPTCHA_LABEL: '图片验证码',
            NEW_PHONE_CAPTCHA_ERROR: '新手机号图片验证码输入错误',
            OLD_PHONE_CAPTCHA_ERROR: '原手机号图片验证码输入错误',
            CURRENT_AVATAR: '当前头像',
            USER_NICKNAME_PLACEHOLDER: '请输入用户昵称',
            USER_NICKNAME: '用户昵称',
            GENDER: '性别',
            TYPE: '类型',
            CONFIRM_THE_CHANGES: '确认修改',
            CONTACT_DETAILS_PLACEHOLDER: '请输入联系方式',
            MOBILE_PHONE: '手机号',
            MOBILE_PHONE_PLACEHOLDER: '请输入手机号',
            NAME: '姓名',
            NAME_PLACEHOLDER: '请输入姓名',
            ORGANIZATION_NAME_PLACEHOLDER: '请输入组织名称',
            ORGANIZATION_NAME: '组织',
            ORGANIZATION_NAME_TITLE: '组织名称',
            FAIL_TO_EDIT: '修改失败',
            LOGISTICS_STATUS: '物流状态',
            LOGISTICS_COMPANY: '物流公司',
            LOGISTIC_NUMBER: '物流单号',
            FILL_IN_THE_REFUND_LOGISTICS_INFORMATION: '填写退款物流信息',
            COPY_THE_TRACKING_NUMBER: '复制单号',
            LOGISTICS_INFORMATION: '物流信息',
            LOGISTICS_INFORMATION_TIPS: '暂无物流轨迹信息，请稍后再试！',
            TRACK_NUMBER_COPIED: '单号已复制',
            NO_DISCOUNT_COUPONS_AVAILABLE: '暂无优惠劵领取',
            GET_YOUR_COUPON_NOW: '立即领取',
            GET_YOUR_COUPON_TEXT: '领券当日开始{{validityDays}}天有效',
            GET_COUPON: '领取优惠券',
            TO_BUY: '去购买',
            NO_FAVORITES_YET: '暂无收藏',
            DELETE_PRODUCT_NAME_TIPS: '你确定要删除这个收藏的{{productName}}吗？',
            MOBILE_PLACEHOLDER: '手机或带区号的固话',
            YOUR_AREA: '所在地区',
            NAME_EMPTY_ERROR: '姓名不能为空或者全是空格',
            NAME_INVALID_ERROR: '收货人姓名不支持特殊符号',
            PHONE_EMPTY_ERROR: '你的联系方式未输入或输入错误',
            ADDRESS_EMPTY_ERROR: '收货地址不能为空或者全是空格',
            PROVINCE_NAME_EMPTY_ERROR: '请选择所在地区',
            CITY_NAME_EMPTY_ERROR: '请完善所在地区',
            ADDRESS_LENGTH_ERROR: '收货地址最多33个字',
            INVOICING_RESULT: '开票结果',
            CREATE_ADDRESS: '新建地址',
            UPDATE_ADDRESS: '更新地址',
            CHANGE_ADDRESS: '修改地址',
            DELETE_ADDRESS_TIPS: '你确定要删除这个地址吗？',
            ORDER_CREATE: '订单提交',
            ORDER_COMPLETED: '订单完成',
            INVOICING_IS_COMPLETE: '开票完成',
            TOTAL_AMOUNT: '总金额',
            FAILED_TO_LOAD_ORDER_INFORMATION: '加载订单信息失败',
            ORDER_ID_TEXT: '订单编号',
            CREATION_TIME: '创建时间',
            UPDATE_TIME: '更新时间',
            PAYMENT_METHOD: '支付方式',
            EMAIL_BINDING: '邮箱绑定',
            BIND_THE_EMAIL: '绑定邮箱',
            CHANGE_EMAIL: '更换邮箱',
            OLD_EMAIL_VERIFICATION_CODE: '原邮箱验证码',
            NEW_EMAIL_VERIFICATION_CODE: '新邮箱验证码',
            EMAIL_VERIFICATION: '邮箱验证'
        },
        PAYMENT_METHOD: {
            WECHAT: '微信',
            ALIPAY: '支付宝',
            CASH: '现金',
            GIFT_CARD: '礼品卡',
            BANK_TRANSFER: '银行转账',
            OTHER: '其他',
            PAYPAY: 'PAYPAY',
            CONSUMING_CARD: '消费卡',
            COUPON: '优惠券',
            POINTS: '积分',
            PRODUCT: '商品',
        },
        CATEGORY: {
            NO_PRODUCT: '暂无商品',
            NO_RELATED: '暂无相关',
            CONTENT: '内容',
            NO_CATEGORY: '暂无分类',
            SHRINK_BTN_TEXT: '收缩',
            EXPAND_BTN_TEXT: '展开',
        },
        FORM: {
            SELECT_THE_FORM_RESPONSE_YOU_WANT_TO_COPY: '选择您想复制的提交记录',
            UPDATE_TIME: '更新时间',
            COPY_BTN_TEXT: '复制',
            COPY_AND_EDIT: '复制编辑',
            REFILL: '重新填写',
            INPUT_VALIDATED_FAILED_TIPS_END: '位仅包含数字、字母字符！',
            NOT_FOUND_CURRENT_FORM_TIPS: '表单找不到或已删除，请重新进入或者看看别的吧',
            THE_FORM_RESPONSE_TO_BE_COPIED_IS_NOT_SELECTED_AND_CANNOT_BE_COPIED: '未选择要复制的表单记录，无法复制！',
            FOUND_SOME_FORM_RESPONSE_THAT_I_FILLED_OUT_EARLIER_DID_I_COPY_THE_CONTENTS_AND_EDIT_THEM_FOR_SUBMISSION: '发现之前填写的一些表单记录，是否复制内容并编辑提交？',
            FILL_FORM_BEFORE_PURCHASE: '购买前须填写表单',
            FILL_FORM_AFTER_PAID: '支付后可填写表单',
            PLEASE_FILL_OUT_THE_FORM_FIRST: '请先填写表单',
            NEED_APPLY_FORM_TIPS: '至少还需填写{{needApplyFormNumber}}个表单',
            WHETHER_TO_BIND_THE_FORM_RESPONSE: '是否将已填写的表单记录绑定到当前订单上',
            CONFIRM_AND_BIND: '确认并绑定',
            APPLY_FORM__AND_BIND_ORDER_TIPS: '填写表单并绑定订单成功！',
            GO_TO_FILL_OUT: '前往填写',
            FILLED_IN: '已填写',
        },
        SHOPPING_CART: {
            FAVORITE_IT: '移入收藏夹',
            GO_SHOPPING: '去购物',
            SHOPPING_CART_IS_EMPTY_TIPS: '购物车为空，去看看别的吧',
        },
        PRODUCT_PAGE: {
            GO_SHOPPING_CART: '去购物车',
            CURRENT_GROUP_CANNOT_JOIN_PLEASE_TRY_ANOTHER_ONE: '当前拼团无法参与，请换个试试吧',
            PRODUCT_ERROR_SEE_OTHER_ONE: '很抱歉本商品处于不可售状态， 麻烦您去看看其它商品！',
            CREATE_GROUP_BUYING_PRICE_ERROR: '团长价格设置有误，无法开团！',
            BUY_AT_LEAST_ONE_TIPS: '最少购买一件',
        },
        GROUP_BUYING_PAGE: {
            TITLE: '拼团详情',
            SUCCESSFULLY_JOINED_TOGETHER: '等人拼团成功',
            MY_GROUP_BUYING: '我的拼团',
            GROUP_BUYING_INFORMATION_TITLE: '拼团须知：',
            GROUP_BUYING_INFORMATION_TIPS: '人参团，人数不足自动退款',
            GROUP_BUYING_REMAINING_TIME: '拼团剩余时间',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_TIPS: '人，快来加入我的团吧！',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_TIPS: '人，即可拼团成功！',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_LEFT_LABEL: '还差',
            JOIN_NOW: '立即参团',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_FRIENDS_TIPS: '暂无拼团成员，快去邀请好友参与吧！',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_NEW_PEOPLE_TIPS: '暂无拼团成员，快去邀请新人参与吧！',
            INVITE_FRIENDS_TO_JOIN_THE_GROUP: '邀请好友参团',
            INVITE_NEW_GINSENG_GROUP: '邀请新人参团',
            FAILED_TO_GET_GROUP_INFORMATION_TIPS: '获取拼团信息失败，请检查网络并刷新再试吧',
            GROUP_HAS_BEEN_CANCELLED: '当前拼团已取消',
            HURRY_UP_TO_INVITE: '赶快邀请',
            COME_AND_JOIN_MY_GROUP: '赶快来参加我的拼团吧',
            GROUP_BUYING_STATUS_IS_UNKNOWN: '拼团状态未知',
            GROUP_BUYING_HAS_STOPPED: '拼团已停止',
            GROUP_BUYING_HAS_BEEN_CANCELLED: '拼团已取消',
            THE_GROUP_BUYING_IS_COMPLETE: '拼团已完成',
            THE_GROUP_BUYING_HAS_TIMED_OUT: '拼团已超时',
            THE_STATE_OF_THE_GROUP_BUYING_IS_ABNORMAL: '拼团状态异常',
            NEED_SOME_PERSON_TO_SUCCESSFUL_FOR_GROUP_BUYING: '还差{{memberGap}}人 拼团成功',
        },
        CUSTOMER_SERVICE: {
            SERVICE_HOTLINE: '服务热线',
            OFFICIAL_ADDRESS: '官方地址',
            COMPANY_ADDRESS: '公司地址',
            OFFICIAL_WECHAT: '官方微信',
        },
        DOCUMENT: {
            NO_TERMS_AND_CONDITIONS: '暂无条款信息',
            LOGIN_TERM: '登录条款',
            REGISTER_TERM: '注册条款',
            PURCHASE_TERM: '购买条款',
            RETURN_TERM: '退款条款'
        },
        GIFT_CARD: {
            EXPIRATION_DAYS_AFTER_ACTIVATION: '激活后到期时间 (天)',
            CAMPAIGN_CODE: '活动编号',
            CHANNEL_CODE: '渠道编号',
            CARD_MEDIA_TYPE: '卡片类型',
            CURRENCY: '币钟类型',
            ACTIVATION_EXPIRATION_DATE: '激活截止时间',
            CNY: '人民币',
            ELECTRONIC: '电子卡',
            ORDER_TYPE: '应用订单',
            REMARK: '备注',
            SERVICE_ORDER: '服务订单',
            SALES_ORDER: '销售订单',
            GIFT_CARD_TIPS: '选填，请先和商家协商一致'
        },
        FORM_CONTROL: {
            DOCUMENTS_MUST_BE_UPLOADED: '必须上传！',
            PENDING_UPLOAD: '待上传',
            CANCEL_UPLOAD: '取消上传',
            UPLOAD: '上传',
            DOWNLOAD: '下载',
            DRAG_OR_CLICK_TO_UPLOAD: '拖拽/点击上传',
            FILES: '个',
            APPLICATION: '应用',
            IMAGE: '图片',
            VIDEO: '视频',
            AUDIO: '音频',
            COMPRESS: '压缩包',
            FILES_UPLOAD: '附件上传',
            FILE_SIZE_NUMBER_MB_CANNOT_BE_UPLOADED: '大小为{{fileSize}}MB的文件无法上传',
            PLEASE_UPLOAD_THE_TYPE_FORMAT_FILE_WITHIN_FILESIZE_MB_IN_SIZE: '请上传大小在{{fileSize}}MB内的{{fileType}}文件！',
            FILE_TYPE: '格式',
            CONTROL_CANNOT_BE_EMPTY: '不能为空！',
            TEXTAREA: '多行文字',
            PLEASE_CHOOSE: '请选择',
            SELECT: '下拉框',
            RADIO_GRID: '矩阵单选',
            RADIO: '单选框',
            LINEAR_SCALE_GRID: '矩阵量表',
            LINEAR_SCALE: '线性量表',
            PLEASE_TYPE_IN: '请输入',
            PLEASE_ENTER_CONTENT: '请输入 内容',
            ADDRESS: '地址',
            INPUT: '单行文字',
            LENGTH_CANNOT_BE_LESS_THAN: '长度不能小于',
            LENGTH_CANNOT_BE_GREATER_THAN: '长度不能大于',
            CANNOT_BE_LESS_THAN: '不能小于',
            CANNOT_BE_GREATER_THAN: '不能大于',
            INVALID_ERROR: '格式不正确！',
            CHECKBOX_GRID: '矩阵多选',
            TITLE: '标题',
            SUBTITLE: '副标题',
            TEXT: '正文',
            RICHTEXT: '富文本',
            CHECKBOX: '多选框',
            SHOULD_CHOOSE: '应选择',
            OPTIONS: '个选项',
            PREV_STEP: '上一步',
            NEXT_STEP: '下一步',
            DATETIME: '时间',
            DATE: '日期',
            DATE_NOT_SELECTED: '日期未选择！',
            THE_TIME_IS_NOT_ENTERED_COMPLETELY: '时间未输入完整！',
            PLEASE_CHECK: '，请检查！',
        },
        EDITOR: {
            TITLE: '标题',
            SUB_TITLE: '副标题',
            BODY: '正文',
            DEFAULT: '默认',
            EXPORT_PDF: '导出PDF',
            PREVIEW_PDF: '预览PDF',
            PRINT: '打印',
            MARGIN_LEFT: '左边距',
            MARGIN_RIGHT: '右边距',
            LINE_HEIGHT: '行高',
            MARGIN_TOP: '上边距',
        },
        FILTER_PARAMS_SELECTOR: {
            SORT: '排序',
            FILTER: '筛选',
            CATEGORY: '分类',
            PUT_AWAY: '收起',
            EXPEND_MORE: '更多',
            KEYWORD: '关键字',
            PRICE: '价格',
            CANCEL: '取消',
            CONFIRM: '确认',
            KEYWORD_PLACEHOLDER: '请输入筛选关键字',
        },
        SHARED: {
            DISCOUNTED_COUPON_PRICE: '优惠券折扣',
            NOT_SUPPORT_ADDRESS: '暂不支持在该区域购买！',
            NOT_SUPPORT_ADDRESS_TIP: '暂不支持在该区域购买，请选择其他地址！',
            COMMENT_PLACEHOLDER: '请输入评论',
            DELIVERY_ADRESS_EMPTY: '请先选择地址',
            DELIVERY_INFORMATION_EMPTY: '未获取到收货信息，请刷新后重试！',
            DISCOUNTED_PRICE: '优惠金额',
            UNAVAILABLE_FOR_COUPON: '不可使用',
            EDIT_PICKER_INFO: '编辑提货人信息',
            PICKER_INFO_PLACEHOLDER: '请完善提货人信息',
            EDIT_PICKER_INFO_PLACEHOLDER: '请输入提货人姓名',
            EDIT_PICKER_PHONE_PLACEHOLDER: '请输入提货人手机号',
            NO_COUPONS_AVAILABLE: '无可用优惠券',
            SELF_PICKUP_TIME: '自提时间',
            TOGGLE: '切换',
            CHANGE_ADDRESS: '更换地址',
            SELF_PICKUP_PERPON: '提货人',
            THE_ORDER_HAS_BEEN_REVIEWED_AND_DOES_NOT_SUPPORT_REFUNDS: '订单已审核不支持退款',
            REFUND_ORDER_DETAILS: '退款订单详情',
            PAYMENT_METHOD: '收款方式',
            FILL_FROM_AFTER_PAID_TIPS: '请支付后再填写表单哦',
            REFUND_NUMBER: '退款编号',
            I_HAVE_SENT_IT_CLICK_TO_FILL_IN_THE_LOGISTICS_ORDER_NUMBER: '填写快递信息',
            ONLINE_PAY_REFUND_AMOUNT: '微信/支付宝退款金额',
            GIFT_CARD_REFUND_AMOUNT: '礼品卡退款金额',
            WAITING_FOR_SELLER_TO_RECEIVE: '已退货，等待卖家收货',
            PASSED_THE_REVIEW_TIPS: '商家已同意退货申请，请尽早退货。',
            ORDER_TYPE: '订单类型',
            THE_AMOUNT_PAID_FOR_THE_ORDER: '订单已支付金额',
            EXPRESS_LOGISTICS: '快递物流',
            SHIPPING_FEES: '配送费用',
            NO_DELIVERY_REQUIRED: '无需配送',
            DELIVERY_METHOD: '配送方式',
            APPLICATION_STATUS: '申请状态',
            OTHER_REASONS_INVALID_ERROR: '其他原因填写不正确',
            OTHER_REASONS_EMPTY_ERROR: '其他原因未填写',
            PHONE_INVALID_ERROR: '手机号格式不正确',
            REQUEST_A_REFUND_PLACEHOLDER: '注：非上门自提的已发运订单，运费不退！',
            PLEASE_FILL_IN_OTHER_REASONS: '请填写其他原因',
            ANONYMOUS_COMMENTS: '匿名评论',
            PLEASE_ENTER_REVIEW_CONTENT: '请输入评价内容',
            BIND_GIFT_CARD: '绑定礼品卡',
            TO_BE_PICKED_UP: '待自提',
            GIFT_CARD_SCRET: '卡密',
            GROUP_TO_BE_FORMED: '拼团待成团',
            PAID_TO_BE_PICKED: '已支付，待拣货',
            PAID_PENDING: '已支付，待处理',
            ORDER_NOT_PAID: '订单未支付',
            PLEASE_ENTER_GIFT_CARD_SCRET: '请输入卡密',
            COMMENTED: '已评论',
            BIND: '绑定',
            INPUT_GIFT_CARD_SCRET_TIPS: '请输入礼品卡密码（电子卡卡密可至订单详情查看）',
            GIFT_CARD_SCRET_EMPTY_ERROR: '卡密不能为空',
            BINDING_SUCCEEDED: '绑定成功',
            GIFT_CARD_SCRET_INVALID_ERROR: '请输入正确的卡密',
            MAX_QUANTITY_FOR_ONCE_TIPS: '一次最多只能买{{value}}件哦!',
            MAX_QUANTITY_FOR_ONCE_BY_STOCK_TIPS: '最多只能买{{value}}件哦！',
            NEWCOMER_GROUP: '新人拼团',
            BROWSER_UNSPPORT_VIDEO_PLAY: '您的浏览器太老了，不支持播放视频，请升级浏览器哦！',
            SOLD_DESC: '{{value}}件已售',
            THERE_IS_NO_INFORMATION: '没有任何信息',
            COMMENT: '发表评论',
            PURCHASE_NOTICE: '购买须知',
            COPY_SUCCESSFULLY: '复制成功！',
            BIND_FAILED: '绑定失败',
            USE_THIS_GIFT_CARD_TIPS: '你确定要将这张礼品卡绑定到当前账号吗？',
            USE_THIS_GIFT_CARD: '绑定到当前账号',
            VIEW_THIS_GIFT_CARD: '查看卡密',
            VIEW_GIFT_CARDS_IN_ORDER: '查看订单-礼品卡',
            LOAD_GIFT_CARDS_ERROR: '订单相关礼品卡获取失败',
            CARD_NUMBER: '卡号',
            PAR_VALUE: '面值',
            EXPIRATION_DATE: '有效期',
            CLICK_TO_SEE_LARGER_PICTURE: '点击查看大图',
            ENLARGE_IMAGE: '放大图',
            CLICK_TO_GO: '点击前往',
            COUPONS: '优惠券',
            DO_NOT_USE: '不使用',
            FAILED_TO_INITIALIZE_QR_CODE_FUNCTION: '初始化二维码功能失败，请检查网络是否正常或者是否被广告拦截插件拦截误杀！',
            DRAW_AND_SHARE: '极速绘制分享图中，请耐心等待',
            BROWSER_NOT_SUPPORT_CANVAS: '当前浏览器不支持绘制二维码，请升级或者更换浏览器哦',
            LOAD_LOGO_IMG_FAILED: '未能加载Logo图片或Logo图片不存在，请稍后再试~',
            HOW_TO_SHARE_ON_WECHAT: '① 长按保存图片或发送好友',
            SAVE_PICTURES: '① 保存图片到本地',
            SCAN_THE_CODE_TO_VIEW: '② 扫码二维码查看详情',
            GROUP_BUYING: '拼团',
            GROUP_BUYING_DESC: '人拼团 • 人满发货 • 人不满退款',
            LOAD_QRCODE_FAILED: '未能加载{{productName}}二维码，请稍后再试。',
            LOAD_IMG_FAILED: '未能加载{{productName}}图片，请稍后再试。',
            SHIP_BEFORE_DATE: '{{value}}前发货',
            SHIPS_DAYS_AFTER_PAYMENT: "付款后{{value}}天内发货",
            MORE_PRODUCT: '更多商品',
            DEFAULT: '默认',
            LATEST: '最新',
            SALES: '销量',
            PRICE: '价格',
            MORE: '更多',
            NOT_FOUND_TIPS: '相关{{value}}未找到哦，先看看别的吧',
            HOT_SALE: '热销',
            CANCEL_ORDER_TIPS: '确定要取消该订单吗',
            CANCELLED: '已取消',
            IN_PROGRESS_GROUPINGS_DO_NOT_ALLOW_REFUNDS: '进行中的拼团不允许退款！',
            OUT_OF_STOCK: '已出库',
            ORDER_REVIEWED: '订单已审核，不支持退款',
            INSUFFICIENT_AMOUNT_ACTUALLY_PAID: '实际支付金额为0，无法开票',
            ACTUAL_PAYMENT_AS_GIFT_CARD: '支付方式为礼品卡支付，无法开票',
            INVOICE_SUBMITTED_SUCCESSFULLY: '当前订单已提交开票，正在审核中，耐心等待哦……',
            REFUND_REQUESTED: '已申请退款',
            ORDER_ID: '订单号',
            ORDER_CANCELLED: '订单已取消',
            REMAINING_PAYMENT_TIME: '剩余支付时间',
            SECONDS: '秒',
            MINUTE: '分钟',
            REFUNDS: '退货退款',
            REQUEST_A_REFUND: '申请退款',
            REFUND_SUCCESSFULLY: '退款成功',
            REFUND_UNDER_REVIEW: '退款审核中',
            REFUND_FAILED: '退款未通过',
            REFUND_APPROVED: '退款审核通过',
            REFUND_PENDING_REVIEW: '退款待审核',
            GO_TO_COMMENT: '去评论',
            EVALUATED: '已评价',
            GO_TO_EVALUATE: '去评价',
            PRESALE_ORDER: '预售订单',
            GROUP_BUYING_ORDER: '拼团订单',
            ESTIMATED_DELIVERY_TIME: '预计发货时间',
            DELIVERY_TIME: '发货时间',
            NORMAL_ORDER: '普通订单',
            SELF_PICKUP: '上门自提',
            NUMBER_PIECES_IN_TOTAL: '共 {{totalQuantity}} 件',
            THE_TOTAL_AMOUNT_OF_ORDERS: '订单总金额',
            SHIPPING_VALUE: '含运费{{value}}',
            AMOUNT_PAID: '已支付金额',
            AMOUNT_TO_BE_PAID: '待支付金额',
            REFUND_AMOUNT: '退款金额',
            ORDER_TIME: '下单时间',
            INVOICED: '已开票',
            INVOICING_REVIEW: '开票审核中',
            APPLY_FOR_MAKING_AN_INVOICE: '申请开票',
            VIEW_LOGISTICS: '查看物流',
            BUY_AGAIN: '再次购买',
            HOURS: '小时',
            TO_PAY: '去支付',
            SITEWIDE: '全场',
            BE_USABLE: '可使用',
            UNAVAILABLE: '不可用',
            SUBMITTED: '已提交',
            REFUND_FAILED_ERROR: '退款失败-请联系管理员',
            ORDER_DETAILS: '订单详情',
            PASSED_THE_REVIEW: '审核通过',
            NOT_ADDRESS_DATA_TIPS: '当前无地址信息，点击创建地址',
            NOT_INVOICE_HEADER_DATA_TIPS: '无可用发票抬头，前往创建',
            TO_CREATE_TIPS: '前往创建',
            ORDER: '订单',
            STORE_WIDE_COUPONS: '店铺全场优惠券',
            DESIGNATED_PRODUCT_COUPONS: '指定{{value}}优惠券',
            YEAR_MONTH_DAY_FROMART_TEXT: '{{year}}年{{month}}月{{day}}日',
            YEAR_MONTH_DAY_PIPE_FROMART_TEXT: 'yyyy-MM-dd',
            ADDRESS_NOT_SELECTED_TIPS: '请选择收货地址',
            FREE_SHIPPING_ON_SITE: '全场包邮',
            FREE_SHIPPING_OVER_NUMBER_KG: '重量满{{value}}KG包邮',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT: '金额满{{value}}KG包邮',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT_AND_WEIGHT: '重量满{{value}}KG 且 金额满{{mount}}KG包邮'
        }
    },
};
