import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SiteContextService } from '../../shared/services/site-context.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
    constructor(
        private router: Router,
        private localStorage: LocalStorageService,
        private siteContextService: SiteContextService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => { },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this.siteContextService.setToken('', true);
                            // this.loginService.logout();
                            // this.router.navigateByUrl("login")
                        }
                    }
                }
            )
        );
    }
}
