import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontSizeScaleDirective } from './font-size-scale.directive';
import { InputToLowCaseDirective } from './input-low-case.directive';
import { InputTrimDirective } from './input-trim.directive';
import { LoadingAnimationAndErrorBackgroundDirective } from './loading-animation-and-error-background.directive';
import { appPreventDefaultAndStopPropagationDirective } from './prevent-default-and-stop-propagation.directive';
import { ImgErrorIdentificationDirective } from './pure-img-error-identification.directive';
import { ThrottleClickDirective } from './throttle-click.directive';
import { TranslateDirective } from './translate.directive';
import { BricsVideoAutoPlayDirective } from './video-auto-play.directive';
import { AnimateOnIntersectionDirective } from './animate-on-intersection.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ThrottleClickDirective,
        appPreventDefaultAndStopPropagationDirective,
        InputTrimDirective,
        LoadingAnimationAndErrorBackgroundDirective,
        ImgErrorIdentificationDirective,
        TranslateDirective,
        InputToLowCaseDirective,
        FontSizeScaleDirective,
        BricsVideoAutoPlayDirective,
        AnimateOnIntersectionDirective,
    ],
    exports: [
        ThrottleClickDirective,
        appPreventDefaultAndStopPropagationDirective,
        InputTrimDirective,
        LoadingAnimationAndErrorBackgroundDirective,
        ImgErrorIdentificationDirective,
        TranslateDirective,
        InputToLowCaseDirective,
        FontSizeScaleDirective,
        BricsVideoAutoPlayDirective,
        AnimateOnIntersectionDirective,
    ]
})
export class DirectivesModule { }
