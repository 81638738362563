import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { SiteAnnouncement } from '../model/site-announcement.model';
type EntityResponseType = HttpResponse<SiteAnnouncement>;
type EntityArrayResponseType = HttpResponse<SiteAnnouncement[]>;

const headers = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({ providedIn: 'root' })
export class SiteAnnouncementService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/site-announcements';


    getShopResourceUrl() {
        return `${API_SERVICE_PREFIX}/cms/api/site-announcements`;
    }

    constructor(
        private http: HttpClient,
    ) { }

    create(siteAnnouncement: SiteAnnouncement): Observable<EntityResponseType> {
        return this.http
            .post<SiteAnnouncement>(this.getShopResourceUrl(), siteAnnouncement, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(siteAnnouncement: SiteAnnouncement): Observable<EntityResponseType> {
        return this.http
            .put<SiteAnnouncement>(`${this.getShopResourceUrl()}/${siteAnnouncement.id}`, siteAnnouncement, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http
            .get<SiteAnnouncement>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = req;
        return this.http
            .get<SiteAnnouncement[]>(this.getShopResourceUrl(), { params: options, observe: 'response', headers })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
