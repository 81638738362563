import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../../i18n';

export function getCurrentControlName(control: any, property: string, translationStr: string, currentLangKey: string, defaultLanguageKey: string = null): string {
    let langKey = currentLangKey?.toLowerCase();
    let toLowerCasePropertyTranslation = objectKeyToLowerCase(jsonParse(control?.[property + translationStr]));
    if (toLowerCasePropertyTranslation) {
        if (Array.isArray(toLowerCasePropertyTranslation)) {
            const findPropertyTranslation = toLowerCasePropertyTranslation?.find(nameTranslationItem => nameTranslationItem?.[langKey]);
            return findPropertyTranslation?.[langKey] || findPropertyTranslation?.[defaultLanguageKey] || control?.[property];
        }
        if (toLowerCasePropertyTranslation && (typeof toLowerCasePropertyTranslation === 'object') && Object.keys(toLowerCasePropertyTranslation)?.includes(langKey)) {
            return toLowerCasePropertyTranslation[langKey] || toLowerCasePropertyTranslation?.[defaultLanguageKey] || control?.[property];
        } else {
            if (toLowerCasePropertyTranslation && (typeof toLowerCasePropertyTranslation === 'object')) {
                if (['zh-hk', 'zh-tw']?.includes(langKey)) {
                    langKey = (langKey === 'zh-tw') ? 'zh-hk' : 'zh-tw';
                    return toLowerCasePropertyTranslation[langKey] || toLowerCasePropertyTranslation[defaultLanguageKey] || control?.[property];
                }
            }
        }
        return jsonParse(control?.[property + translationStr])?.[currentLangKey] || jsonParse(control?.[property + translationStr])?.[defaultLanguageKey] || control?.[property];
    }
    return control?.[property];
}

export function objectKeyToLowerCase(value: string | Object) {
    if (!['', 'null', 'undefined', null, undefined]?.includes(value as string)) {
        let strTranslation = {};
        let tempValue = value;
        if (typeof value === 'string') {
            tempValue = jsonParse(value);
        }
        Object.keys(tempValue)?.map(key => {
            strTranslation[key.toLowerCase()] = tempValue[key];
        });
        return strTranslation;
    }
    return value;
}

export function jsonParse(str: string) {
    try {
        if (str && (typeof str === 'string')) {
            return JSON.parse(str);
        }
        return str;
    } catch (error) {
        return str;
    }
}

@Pipe({
    name: 'mNameTranslate',
    pure: false,
})
export class NameTranslatePipe implements PipeTransform {

    constructor(
        private translationService: TranslationService,
    ) { }

    transform(value: any, params: string = 'name', language: string = this.translationService.getSelectedLanguage()): any {
        let property: string = 'name', translationStr = 'Translation';
        if (params?.includes(',')) {
            const result = params?.split(',');
            property = result[0];
            translationStr = result[1];
        } else {
            property = params ? params : 'name';
        }
        return getCurrentControlName(jsonParse(value), property, translationStr, language);
    }
}
