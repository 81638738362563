import { isPlatformBrowser } from '@angular/common';
import { OnDestroy, Injectable, afterNextRender, Inject, PLATFORM_ID } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CurrentDeviceService implements OnDestroy {
    isSmallScreen: boolean;
    // 监听设备宽度的subscription对象
    private watchDeviceWidthSub: Subscription;
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        // Client only code.
        if (isPlatformBrowser(this.platformId)) {
            this.isWX = () => {
                return this.browserDetect().isWx;
            };

            this.isDesktop = () => {
                return this.browserDetect().isDesktop;
            }

            this.isMobile = () => {
                return this.browserDetect().isMobile;
            }

            this.isWxWebview = () => {
                return this.browserDetect().isWebViewOfMp;
            }

            this.isWxwork = () => {
                return this.browserDetect().isWxwork;
            }
            // 开启 监听设备宽度变化，200ms内防抖处理，实时检测当前设备宽度
            this.watchDeviceWidthSub = fromEvent(window, 'resize')
                .pipe(debounceTime(200))
                .subscribe(() => this.browserDetect());
        }
    }

    browserDetect() {
        const sUserAgent = ((navigator as any)?.userAgentForServer || navigator?.userAgent)?.toLowerCase();
        const bIsIpad = sUserAgent.indexOf('ipad') >= 0;
        const bIsIphoneOs = sUserAgent.indexOf('iphone os') >= 0;
        const bIsMidp = sUserAgent.indexOf('midp') >= 0;
        const bIsUc7 = sUserAgent.indexOf('rv:1.2.3.4') >= 0;
        const bIsUc = sUserAgent.indexOf('ucweb') >= 0;
        const bIsAndroid = sUserAgent.indexOf('android') >= 0;
        const bIsCE = sUserAgent.indexOf('windows ce') >= 0;
        const bIsWM = sUserAgent.indexOf('windows mobile') >= 0;
        const isWx = (sUserAgent.indexOf('micromessenger') >= 0) && !(sUserAgent.indexOf('wxwork') >= 0);
        const isWxwork = (sUserAgent.indexOf('micromessenger') >= 0) && (sUserAgent.indexOf('wxwork') >= 0);
        const isWebViewOfMp = sUserAgent.includes('miniProgram');
        const isMobileDevice = (window.innerWidth > 0) ? (window.innerWidth <= 568) : ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM || isWebViewOfMp || isWx));
        let isMobile = false;
        let isDesktop = false;
        if (isMobileDevice) {
            isMobile = true;
        } else {
            isDesktop = true;
        }
        return {
            isWebViewOfMp,
            isWx,
            isMobile,
            isDesktop: isDesktop,
            isWxwork,
        };
    }

    isWX() {
        return false;
    }


    isDesktop() {
        return true;
    }

    isNotMobile() {
        return window.innerWidth >= 769;
    }

    isMobile() {
        return false;
    }

    isWxWebview() {
        return false;
    }

    isWxwork() {
        return false;
    }

    ngOnDestroy(): void {
        this.watchDeviceWidthSub?.unsubscribe();
    }
}
