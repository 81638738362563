
// export const WXAPI = {
// 	isWX:false,
//   loginUrl:'http%3a%2f%2ftestmall.sptcc.com%2f%23%2flogin',
//   wxLoginUrl:'http%3a%2f%2ftestmall.sptcc.com%2f%23%2fwxlogin',
//   appid:'wx02e9aea53ec1a40f',   //正式
//   resType:'code',
//   scopeBase:'snsapi_base',
//   scopeBaseUser:'snsapi_userinfo',
//   wxopen:'https://auth.htaonet.cn/wxproxy/auth?appid=',
// };

// 正式
export const WXAPI = {
  isWX:false,
  mallUrl:'http%3a%2f%2femall.sptcc.com%2f%23%2fmall%2findex',
  loginUrl:'http%3a%2f%2femall.sptcc.com%2f%23%2flogin',
  wxLoginUrl:'http%3a%2f%2femall.sptcc.com%2f%23%2fwxlogin',
  appid:'wx02e9aea53ec1a40f',                           //正式
  resType:'code',
  scopeBase:'snsapi_base',
  scopeBaseUser:'snsapi_userinfo',
  wxopen:'https://auth.htaonet.cn/wxproxy/auth?appid=',
};

//http://duu.sctcd.com/resource/wxproxy/auth?appid=wx02e9aea53ec1a40f&redirect_uri=http%3a%2f%2femall.sptcc.com%2f%23%2fwxlogin&response_type=code&scope=snsapi_base&state=123&uin=MzM4MDk2NDAyNw%3D%3D&key=d9eb163415b67d2f4e2f8f93f066b21bf766deb0fe6463a8219f1210019c45972e90da7f7838be0735494bd6e846d7a1&pass_ticket=70dkZoDoErDb/JLr8OovxMZK0XXgvx04cIDKjYTH0wQerkVNCHXRMzn7yW+cUqz1EhkDMfDdGCPYdfJveLojbA==
