import { accountManagementTranslate } from './_modules/account-management.translate';
import { affiliateTranslate } from './_modules/affiliate.translate';
import { authTranslate } from './_modules/auth.translate';
import { contentManagementTranslate } from './_modules/content-management.translate';
import { invoiceCenterTranslate } from './_modules/invoice-center.translate';
import { menuTranslate } from './_modules/menu.translate';
import { orderTranslate } from './_modules/order.translate';
import { sitesTranslate } from './_modules/sites.translate';
import { systemSettingTranslate } from './_modules/system-setting.translate';
import { userTranslate } from './_modules/user.translate';

// USA
export const locale = {
    lang: 'en-US',
    data: {
        AUTH: authTranslate,
        MENU: menuTranslate,
        ORDER: orderTranslate,
        ACCOUNT_MANAGEMENT: accountManagementTranslate,
        SYSTEM_SETTING: systemSettingTranslate,
        SITES: sitesTranslate,
        CONTENT_MANAGEMENT: contentManagementTranslate,
        AFFILIATE: affiliateTranslate,
        INVOICE_CENTER: invoiceCenterTranslate,
        GENERAL: {
            OWNER: 'Owner',
            UNPAID: 'Unpaid',
            PAID: 'Paid',
            EXPIRED: 'Expired',
            INVOICE_CENTER: 'Invoice',
            SERIES_PAGE: 'Series products',
            CALLING_CODE_SELECTOR_TITLE: 'Select country/territory code',
            CALLING_CODE_SELECTOR_PLACEHOLDER: 'Search country/territory',
            REFERRAL_HISTORY_TEXT: 'Referral history',
            REFERRAL_ACTIVITY_TITLE_TEXT: 'Invite friends and win rewards',
            RULE_TEXT: 'Activity Rules',
            SHARE_TO_NEW_PEOPLE_TEXT: 'Copy referral url',
            COPY_YOUR_REFERRAL_CODE_TEXT: 'Copy referral code: {{myReferralCode}}',
            YOUR_REFERRAL_PERSON_TEXT: 'My referrer: {{referrerId}}',
            SAVE: 'Change',
            LOAD_ORDER_INFO_TIPS: 'Calculating order data in progress, please wait...',
            LOAD_INFO_TIPS: 'Loading data, please wait...',
            TAX_INCLUDED: 'Tax included',
            CANT_CREATE_ORDER_FOR_ERROR_MESSAGE: 'The current order does not exist or the data is not legal, please resubmit it',
            GROUP_BUYING_CREATER_DISCOUNT: 'Group Leader Discount',
            GET_USER_INFO_FAILED: 'Failed to get identity information, please try again later!',
            PICK_UP_PHONE_INVALID_TIPS: "The picker's mobile phone number is not in the correct format",
            PICK_UP_NAME_EMPTY_TIPS: 'The name of the person who picks up the goods cannot be empty',
            PICK_UP_TIME_TIPS: 'Select a pick-up time',
            ORDINARY_EXPRESS: 'Ordinary express',
            GROUP_BUYING_FREE_SHIPPING: 'Group purchase free shipping',
            FREE_SHIPPING: 'Free shipping',
            PHONE: 'Phone number',
            HOW_TO_GET_THE_CARD_PASSWORD: 'How to obtain the card password',
            HOW_TO_GET_THE_CARD_PASSWORD_DESC: 'After successful payment, you need to use this method to obtain the card password',
            GIFT_CARD_INFORMATION: 'Gift card information',
            ALL_BOUND_TO_CURRENT_ACCOUNT: 'All are bound to the current account',
            LOGISTICS_COMPANY_NUMBER_INVALID_ERROR: 'The document number is not in the correct format',
            LOGISTICS_COMPANY_NAME_EMPTY_ERROR: 'The logistics company name cannot be empty',
            FILL_IN_THE_LOGISTICS_COMPANY: 'Fill in the logistics company',
            EVALUATE_SUCCESS: 'Evaluate success',
            EVALUATE_INVALID_ERROR: 'Minimum 5 words for evaluation!!!',
            QUERY_BTN_TEXT: 'Query',
            ADD_BTN_TEXT: 'Add to',
            EDIT_BTN_TEXT: 'Edit',
            FILL_IN_BTN_TEXT: 'Fill out',
            DELETE_BTN_TEXT: 'Delete',
            SAVE_BTN_TEXT: 'Save',
            CANCEL_BTN_TEXT: 'Cancel',
            CHANGE_TEXT: 'Change',
            LOGIN: 'login',
            REGISTER: 'register',
            REGISTER_BTN_TEXT: 'Create new account',
            SEARCH: 'Search',
            PRODUCT_DEFAULT_NAME: 'product',
            INDEX: 'Home',
            CUSTOMERIZE: 'customize',
            CATEGORY: 'category',
            FEATURE: 'feature',
            SHOPPING_CART: 'Cart',
            ABOUT: 'about',
            USER_CENTER: 'User Center',
            CUSTOMIZE: 'Customization',
            LOAD: 'load',
            LOADING: 'Loading',
            SIGN_OUT_SUCCESSFULLY: 'Log out successfully',
            WECHAT_BINDING_IS_SUCCESSFUL: 'WeChat binding successful!',
            WECHAT_BINDING_FAILED: 'WeChat binding failed',
            HOT_SALE: 'Hot',
            LABEL: 'Label',
            NEW: 'Latest',
            PRE_SALE: 'Pre-sale',
            SERIES: 'Series',
            FILL_OUT: 'Fill out',
            SEE_MORE: 'See more',
            CURRENTLY_NO_RECORDS: 'There is no record at present',
            FAILED: 'failed',
            RELOAD_BTN_TEXT: 'Refresh retry',
            NONAME: 'Anonymous',
            NONE: 'none',
            SETTING: 'Setting',
            LOGIN_REGISTER: 'Login/Register',
            ALL: 'all',
            LOADED_ALL: 'Loaded all',
            LOAD_FAILED: 'Loading failed',
            LOAD_POST_FAILED: 'The post cannot be found or has been deleted',
            LOAD_PRODUCT_FAILED: 'The {{productName}} cannot be found or is taken off the shelves',
            LOAD_FORM_FAILED: 'The form cannot be found or has been deleted',
            LOAD_LABEL_PRODUCT_FAILED: 'The related {{productName}} cannot be found or has been removed from the shelves',
            RE_APPLY_BTN_TEXT: 'Apply again',
            REVIEW_APPLIED_CONTENT_BTN_TEXT: 'View filled',
            GO_TO_HOME_BTN_TEXT: 'Go to the home page',
            SUBMIT: 'submit',
            SOME_ERROR_OCCUR: 'An exception occurred',
            TIPS: 'Tips',
            SUBMITTED_SUCCESSFULLY: 'Submission successful',
            SUBMITTED_FAILED: 'Submission failed',
            RETURNING_TO_HOME_PAGE_TIPS: 'The home page is being returned for you',
            RETURNING_TO_HOME_PAGE_SECONDS_TIPS: '{{secondNumber}} after a second, it will automatically return you to the home page',
            PAGE_NOT_FOUND: 'Page not found',
            SELECT_ALL_BTN_TEXT: 'Select all',
            INFO: 'Information',
            UNIT_PRICE: 'unit price',
            QUANTITY: 'quantity',
            PRICE: 'amount',
            OPERATION: 'operation',
            MODIFY_AMOUNT: 'Modify the amount',
            OFF_SHELF: 'Off shelf',
            GO_TO_SETTLEMENT: 'Pay',
            TOTAL: 'Total',
            SELECTED: 'Selected',
            PIECES: ' pieces',
            OUT_OF_STOCK: 'Out of stock',
            ONLY: 'Only left',
            UPDATE_FAILED: 'Loading failed',
            CALC_DELIVERY_FAILED: 'Calculate shipping fee call failed, please check the network settings and try again',
            SUCCESS: 'succeed',
            DELETING_DATA_IN_PROGRESS: 'Delete in',
            LET_ME_THINK_AGAIN: "Cancel",
            YOU_DIDNT_SELECTED_SOMETHING: "You didn't make a choice!",
            NO_PURCHASABLE_ITEMS_TO_CHOOSE_TIPS: 'There is no way to choose from the goods to be purchased!',
            PLEASE_SELECTED: 'Please select',
            WHETHER_TO_DELETE: 'Whether to delete',
            INCORRECT_FORMAT_TIPS: 'The format is incorrect',
            PRICE_OF_PRODUCT: 'Price',
            ORIGINAL_PRICE_OF_PRODUCT: 'Price',
            SALE_QUANTITY: 'Sold',
            PRODUCT_TOTAL_COMMENTS: 'Total comments',
            COUPON: 'Coupons',
            THRESHOLD_FREE_COUPONS: 'No threshold coupons',
            INVITE_NEW_PARTICIPANTS: 'Invite new groups',
            INVITE_NEW_PARTICIPANTS_TIPS: 'Limited to new users to join the group, full delivery, people are not satisfied with the refund',
            ENTER_THE_PURCHASE_AMOUNT_TIPS: 'Please enter the purchase amount',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS: 'Ladder group',
            SPECIAL_REQUIREMENT_PARAMS: 'Special requirements',
            FAVORITES: 'Favorites',
            COLLECT: 'Add to Favorites',
            COLLECTED: 'Remove from favorites',
            SHARE: 'share',
            COPY_LINK: 'Copy the link',
            WECHAT_SCAN: 'WeChat sweep',
            RELATED_ARTICLES: 'Related posts',
            WANT_CREATE_GROUP_BUYING: 'Create',
            FULL_PAYMENT: 'Full payment',
            EXPECTED: 'expect',
            INDIVIDUAL_PURCHASE: 'Sold separately',
            ADD_TO_SHOPPINGCART: 'Add to cart',
            ADD_TO_SHOPPINGCART_BTN_TEXT: 'Add to Cart',
            PRE_SALE_PRICE: 'Pre-sale price',
            PARAMETER: 'parameter',
            COMMENTS: 'comments',
            SEE_ALL_PRODUCT_COMMENTS: 'See all comments',
            RECEIVED: 'Received',
            RECEIVE: 'Receive',
            START_TIME: 'Start time',
            END_TIME: 'End time',
            PRODUCT_PROPERTIES: 'Product properties',
            PURCHASE_QUANTITY: 'Quantity',
            BUY_NOW: 'Buy now',
            DISCOUNT_OF_COUPON: '{{minAmountToUse}} minus {{offValue}}',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER: 'Each order is limited to {{maxQuantityOfOrder}} pieces',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER_TIPS: 'Exceed the limit quantity per purchase',
            PRODUCT_IS_SOLD_OUT: '{{productName}} sold out',
            SOLD_OUT: 'Sold out',
            INVENTORY: 'Stock ',
            GROUP_BUYING_FOR_PEOPLE_NUMBER: '{{price}}/{peopleNumber}}',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS_TEXT: 'For {{requiredMembers}} p',
            GET_THE_COUPON: 'Get',
            JOIN_THE_GOURP_BUYING_DESC: 'These people have just successfully spelled a single and can participate in the group',
            SOMEONE_GROUP: "'s group",
            ONLY_FOR_GROUP_BUYING: 'Only bad',
            PERSON_LEFT_IN_THE_GROUP: 'People in groups',
            TO_INVITE: 'To Invite',
            GO_TO_THE_GROUP: 'To the group',
            NO_DETAILS_FOR_PRODUCT: 'No details of {{productName}}',
            SOME_DAYS_AFTER_PAYMENT: 'within {{presaleDeliveryDaysAfterPayment}} days after payment',
            SHIP: 'to ship',
            COUPON_VALIDITY_PERIOD_TIPS: 'The voucher is valid on the day of receipt {{validityDays}}',
            PRESALE_DELIVERY_FIXED_DATE: '{{value}}before',
            DESCRIPTION: 'introduction',
            PRODUCT_QUANTITY_EXCEEDS_AVAILABLE_STOCK: 'The quantity exceeds the number of available stocks',
            ADD_TO_CART_SUCCESSFUL: 'Add to cart successfully',
            ADD_FAILED: 'Add failed',
            LIMIT_MIN_NUMBER_OF_PRODUCT_TIPS: 'The number of {{productName}} cannot be less than 1',
            ANONYMOUS_USER: 'Anonymous users',
            LIMIT_MAX_STOCK_NUMBER_OF_PRODUCT_TIPS: 'Cannot be greater than the current {{productName}} inventory!',
            NETWORK_CONNECTION_FAILED: 'Network connection failed!',
            BUY_PRODUCT_ERROR_TIPS: 'Data acquisition failed, the {{productName}} may have been taken down or an unknown exception occurred in the service',
            SHARE_INFO_FOR_PRESALE_TIPS: 'I found a good {{productName}} that is currently on sale, so check it out!!!',
            SHARE_INFO_FOR_GROUP_BUYING_TIPS: 'I found a good {{productName}} that is currently in a group buy promotion, so check it out!!!',
            SHARE_INFO_FOR_PRODUCT_TIPS: 'I found a good {{productName}}, take a look!!!',
            UNFAVORITE_SUCCESSFUL_TIPS: 'Unsolicited successfully',
            UNFAVORITE_FAILED_TIPS: 'Unsolicited failed',
            FAVORITE_SUCCESSFUL_TIPS: 'Favorite success',
            FAVORITE_FAILED_TIPS: 'Favorite failed',
            LOGIN_CHECKING: 'Login detection in progress',
            RECEIVING: 'Picking up',
            RECEIVED_SUCCESSFULLY: 'Claim successful',
            RECEIVED_FAILED: 'Pick up failed, please contact customer service!',
            FAILED_TO_LOAD: 'Loading failed',
            VIEW_ORDER_DETAILS: 'View order details',
            GO_TO_ORDER_LIST: 'Go to the order list',
            GO_TO_HOME_TIPS: 'Go to the homepage',
            GROUP_BUYING_CREATER: 'Head',
            PARTICIPATE: 'Spelling',
            PEOPLE: 'person',
            PLEASE_WAIT: 'Please wait',
            ENTER_SEARCH_CONTENT: 'Please enter your search',
            SEARCH_HISTORY: 'Search history',
            NOT_SEARCHED_RELATED_PRODUCTS: 'Sorry, no relevant content was searched',
            INPUT_INVALID_ERROR: 'Please check your input for errors',
            SEARCH_FAILED: 'Search failed, please try again later',
            SURE: 'OK',
            DELETE_SEARCH_WORD_FAILED: 'The cleanup failed, please try again later',
            CONFIRM_BEFORE_CLEARING_SEARCH_HISTORY: 'Are you sure you want to clear all your search history?',
            NO_SERIES: 'No series',
            FAILED_TO_LOAD_SERIES_DATA: 'Failed to load series data',
            NO_GIFT_CARD_CONTENT: 'No gift card content',
            SERIES_CARD: 'Theme cards',
            NOT_FOUND_PRODUCTS: 'No {{productName}} content',
            NO_DATA: 'No data yet',
            NO_MORE_DATA: 'No more data',
            ARTICLE_LIST: 'Post list',
            LIKE: 'Like',
            COMMENT_ARTICLE: 'comment',
            ARTICLE_COMMENTS: 'All comments',
            LATEST: 'Latest',
            MOST_POPULAR: 'Hottest',
            AUTHOER: 'author',
            CANCEL_REPLY: 'Cancel the reply',
            REPLY: 'Reply',
            UNLIKE: 'Cancel likes',
            LINK_COPIED_SUCCESSFULLY: 'Link copy successful',
            THE_CURRENT_BROWSER_DOES_NOT_SUPPORT_COPYING: 'The current browser does not support copying, please copy it manually!',
            ARTICLE_DETAILS_TITLE: 'Post detail',
            ONLY_TIME_LEFT_UNTIL_THE_EVENT_STARTS: 'Activity start time',
            ONLY_TIME_LEFT_UNTIL_THE_END_OF_THE_EVENT: 'Activity end time',
            MALE: 'Man',
            FEMALE: 'Woman',
            OTHER: 'Other',
            UNKNOWN: 'Unknown',
            THOUSAND: 'thousand',
            TEN_THOUSAND: 'ten thousand',
            BILLION: 'hundred million',
            BACK: 'Back',
            HELLO: 'Hello',
            PERSONAL: 'Person',
            ORGANIZE: 'Organization',
            PLEASE_SELECT_GENDER: 'Please select a gender',
            PLEASE_CHOOSE_THE_TYPE: 'Please select a type',
            AVATAR_UPLOAD_FAILED: 'Avatar upload failed',
            TRY_AGAIN_RECIVE: 'Please pick it up again',
            SUCCESSFULLY_DELETED: 'Delete successful',
            FAILED_TO_DELETE: 'Delete failed',
            SET_AS_THE_DEFAULT_ADDRESS: 'Set to the default address',
            SHIPPING_ADDRESS: 'Shipping address',
            SHIPPING_ADDRESS_PLACEHOLDER: 'Please enter the shipping address',
            POSTAL_CODE: 'Postal post code',
            POSTAL_CODE_PLACEHOLDER: 'Please enter a postal code',
            DEFAULT_ADDRESS: 'The default address',
            NO_ADDRESS_YET: 'No address yet',
            SET_SUCCESSFULLY: 'Setup succeeded',
            COMPLETE_ADDRESS_INFORMATION: 'Please improve the address information',
            EMAIL_ADDRESS_TITLE: 'Fill in the email address where you will receive the invoice',
            EMAIL_ADDRESS: 'Email address',
            EMAIL_ADDRESS_PLACEHOLDER: 'Please enter your email address',
            INVOICE_HEADER_TYPE_TITLE: 'Header type',
            INVOICE_HEADER_TYPE_PLACEHOLDER: 'Select the header type',
            COMPANY: 'firm',
            INVOICE_HEADER_PLACEHOLDER: 'Please enter the invoice header',
            INVOICE_HEADER_CONTENT: '*Invoice header',
            INVOICE_HEADER_TITLE: 'Invoice header',
            NSRSBH_PLACEHOLDER: 'Please enter the taxpayer identification number',
            NSRSBH_CONTENT: '*Taxpayer identification number',
            NSRSBH_TITLE: 'Taxpayer identification number',
            PHONE_PLACEHOLDER: 'Please enter your mobile phone number',
            PHONE_CONTENT: '*Phone number',
            PHONE_TITLE: 'Phone number',
            INPUT_MORE_INFORMATION: 'Refill in more information',
            BANK: 'bank',
            BANK_PLACEHOLDER: 'Please enter the bank',
            NICKNAME_TITLE: 'Personal name',
            NICKNAME_CONTENT: '*Personal name',
            NICKNAME_PLACEHOLDER: 'Please enter a name',
            FULL_DATE_FROMART_TEXT: '{{year}} {{month}},{{day}}, {{hour}}:{{minute}}:{{second}}',
            SELECT_INVOICE_INFORMATION: 'Select Invoice Information',
            FAILED_TO_APPLY_FOR_INVOICING: 'Failed to request invoicing, please contact your administrator',
            FAILED_TO_APPLY_FOR_INVOICING_TIPS: 'The request for invoicing failed',
            INVOICING_WAS_SUCCESSFUL: 'Successful application for invoicing!',
            FAILED_TO_CREATE_INVOICE_HEADER: 'Failed to create invoice header',
            REFUNDING: 'Refunds are under way',
            PENDING_RETURN: 'Pending return',
            RETURNED_PENDING_REFUND: 'Returned and awaiting refund',
            REVIEW_FAILED: 'The audit failed',
            IN_REVIEW: 'Under review',
            REFUND_COMPLETE: 'Refund completed',
            REFUND: 'Refund',
            REFUND_APPLICATION_APPROVED: 'The refund request is approved',
            REFUND_INSTRUCTIONS: 'Refund Notice',
            REFUND_BUTTON_TEXT: 'I have read (go to refund)',
        },
        SWEET_ALERT: {
            DEFAULT_TITLE: 'Are you sure you want to do this?',
            DEFAULT_CONFIRM_BUTTON_TEXT: 'Confirm',
            DEFAULT_CANCEL_BUTTON_TEXT: 'Cancel',
        },
        PAGINATION: {
            TITLE: 'Display 第 {{page}} - {{lastIndex}} 條,Total {{total}} 条数据.',
            TEXT: 'Displayed per page',
            Unit: 'strip'
        },
        USER: {
            ...userTranslate,
            LANGUAGE: 'language',
            LOGOUT: 'quit',
        },
        FOOTER: {
            TEXT: '上海慧特信息技术有限公司',
            TERMS_OF_USE: 'Terms of Use',
            TERMS_OF_REGISTRATION: 'Terms of Registration',
            TERMS_OF_PRIVACY_POLICY: 'Terms of Privacy policy',
            TERMS_OF_PURCHASE: 'Terms of Purchase',
            CUSTOMER_SERVICE: 'Customer service',
            CONTACT_CUSTOMER_SERVICE: 'Customer Service',
            FEEDBACK_BTN_TEXT: 'I want to feedback',
            AFTER_SALES_SERVICE: 'After-sales service',
            MERCHANT_SERVICES: 'Merchant services',
            AFFILIATE_SERVICES: 'Affiliate networks',
            AFFILIATE_BTN_TEXT: 'Apply to join',
            MERCHANT_PORTAL: 'Merchant portal',
            FOLLOW_US_FOR_MORE_INFORMATION: 'Follow us for more information',
            TECHNICAL_SUPPORT: 'Support:',
        },
        API_ALERT_MESSAGE: {
            ERROR: {
                OFF_LINE: 'There was an error requesting, please check your internet connection...',
                UNAUTHORIZED: 'You are not logged in yet or your login status has expired, please log in before proceeding...',
                FAILEDTOLAZILYINITIALIZE: 'The current request service is not started successfully, please contact customer service or administrator for processing',
                UNKONWN: 'Unknown error, please check the network and try again; if it continues to appear, please contact the administrator',
                ERROR_MESSAGE: 'Error in request, error message:',
                DEFAULT: 'Please check the current network, or there is an unknown error on the current interface...',
                COUPONNOTFEASIBLEFORGIFTCARD: 'Gift cards cannot be deducted with coupons~',
                INVALIDCARDTEMPLATEID: 'The order contains a gift card item and cannot be paid with a gift card~',
                OTPEXPIRED: 'WeChat authorization has expired, please re-authorize~',
                SENDVERIFICATIONCODEERROR: 'Too many times sent, please try again later or change the number',
                NOCUSTOMERRECORD: 'The current account information is incomplete, please try another mobile number or contact the administrator',
                NOTFOUND: 'Data does not exist or the backend service is temporarily unavailable. If this problem continues, please contact the administrator',
                METHODNOTALLOWED: 'There was an error in the request, if this problem continues, please contact the administrator',
                INTERNALSERVERERROR: 'The server is abnormal, if this problem continues, please contact the administrator',
                GATEWAYTIMEOUT: 'Unable to connect to the server, please check the network and try again',
                ERRNETWORKCHANGED: 'Unable to connect to the server, please check the network and try again',
                METHODARGUMENTNOTVALID: 'The submitted data is incorrect, please refresh and try again; if it continues to appear, please re-visit the app',
                BADGATEWAY: 'The server is abnormal, please visit the application later; if it continues to appear, please contact the administrator',
                USERCOULDNOTBEFOUND: 'User does not exist, please log in again or try again later',
                COULDNOTEXTRACTRESULTSET: 'The service-related process is abnormal, please visit the application later; if it continues to appear, please contact the administrator',
                NOT_LOADED_SUCCESSFULLY_PLEASE_RE_ENTER: 'Not loaded successfully, please re-enter:',
                CMS: {
                    FORMNOTFOUND: 'Form not found for this response',
                    MISSINGREQUIREDRESPONSE: 'Missing required response of question',
                    INVALIDROWSFORMAT: 'Rows in grid type question must be a JSON array of strings',
                    RESPONSENOTMATCHWITHROWCOUNT: 'Response count does not match with row count of grid type question',
                    MISSINGREQUIREDRESPONSEOFROW: 'Missing required response of row in grid type question',
                    MULTIPLERESPONSENOTALLOWED: 'Multiple responses are not allowed for singular question',
                    MISSINGRESPONSEVALUE: 'Missing response of question',
                    EMPTYRESPONSEVALUE: 'Empty response of question',
                    INVALIDDATETIMEFORMAT: 'Invalid date/time format for question',
                    INVALIDINTEGERVALUE: 'Invalid integer value',
                    INVALIDFLOATVALUE: 'Invalid float value',
                    DECIMALPLACENUMBEREXCEEDS: 'Decimal place number exceeds',
                    FILENUMBEREXCEEDS: 'File number exceeds',
                    INVALIDOPTIONSFORMAT: 'Invalid options format, should be a JSON array of FormAttachment',
                    INVALIDVALUE: 'Invalid value for question',
                    CANNOTFINDVALIDATOR: 'Cannot find validator for question',
                    EMAILALREADYEXISTS: 'Subscribed successfully!',
                },
                USER: {
                    PASSWORDFORMATINVALID: 'Password does not meet the complexity requirements',
                    LOGINALREADYEXISTS: 'Login name already used',
                    NOUSERCREDENTIAL: 'Cannot find user credential, please log in first',
                    ALREADYBOUNDMOBILE: 'User has already bound with a mobile number',
                    USERNOTBOUNDMOBILE: 'User is not binding with any mobile',
                    MOBILENUMBERMISMATCH: 'Not the same number in record',
                    MOBILEBOUNDBYOTHERUSER: 'This number is already bound by others',
                    INCORRECTPASSWORD: 'Incorrect password',
                    MOBILENOTREGISTERED: 'This number is not registered',
                    PARAMETERNULL: 'Parameter cannot be null',
                    WECHATNOTREGISTERED: 'This wechat is not registered',
                    USERNOTBOUNDWECHAT: 'User is not binding with any wechat',
                    WECHATBOUNDBYOTHERUSER: 'This wecjat is already bound by others',
                    MOBILEALREADYREGISTERED: 'This mobile is already registered, please bind a wechat',
                    REGISTERREQUIRED: 'Log in failed, please register first',
                    WECHATALREADYREGISTERED: 'This wechat is already registered, please log in or bind a new wechat',
                    NOUSERBYWECHAT: 'Cannot find user by wechat, please contact technical support',
                    CODENULL: 'Code cannot be null',
                    WRONGPASSWORD: 'Password error, please check the password you entered',
                    FAILEDTOOMANYTIMES: 'You have tried to log in too many times, change your login method or try again in {{value}} minutes',
                    FAILEDTOOMANYTIMES_NO_DETAIL: 'You have tried to log in too many times, change your login method or try again later',
                    LOGINFAILED: 'Login failed, please check if your username or password is correct or change your login method, you have tried to log in {{loginNumber}} times, more than {{maxNumber}} times will prevent you from logging in for {{timeNumber}} minutes',
                    LOGINFAILED_NO_DETAIL: 'Login failed, please check if your username or password is correct or change your login method, frequent login will prevent you from logging in for a period of time',
                    CODEINVALID: 'The verification code is no longer valid',
                    PASSWORDNOTENABLED: 'Password login is not enabled, please change to another login method',
                    USER_DOES_NOT_EXIST: 'User does not exist',
                }
            },
            SUCCESS: {
                TITLE: 'Success!',
            }
        },
        LANGUAGE_SWITCH: {
            CHINESE: 'Chinese Simplified',
            ENGLISH: 'English',
            JAPANESE: 'Japanese',
            TRADITIONAL_CHINESE: 'Chinese Traditional'
        },
        INDEX: {
            USER_CENTER: 'User Center',
            MY_ORDERS: 'My Orders',
            MY_GIFT_CARDS: 'Giftcard Balance',
            PERSONAL_INFORMATION: 'My Profile',
            ACCOUNT_SECURITY: 'Account Security',
            SIGN_OUT_BTN_TEXT: 'Sign Out',
            FAILED_TO_GET_TODAY_GROUP_PRODUCT: "Failed to get today's group buy product!",
            HEADER: 'Header',
            CAROUSEL: 'Carousel',
            DIY_CONTENTS: 'Customization',
            ANNOUNCEMENT: 'Announcement',
            ANNOUNCEMENT_INFO: 'Announcement information',
            NEWS: 'News',
            GROUP_BUYING_PRODUCS: 'Group buying products',
        },
        FLOATING_WINDOW: {
            GO_TO_SHOPCART: 'Cart',
            USER_CENTER: 'User Center',
            CONTACT_CUSTOMER_SERVICE: 'Customer service',
            BACK_TO_THE_TOP: 'Back to top',
            SHRINK_BTN_TEXT: 'shrink',
            EXPAND_BTN_TEXT: 'unfold',
        },
        HOME: {
            AMOUNTS_PAYABLE: 'Amount',
            SELF_PICK_UP_ADDRESS: 'Pickup address',
            SELECT_SELF_PICK_UP_ADDRESS: 'Select pickup address',
            SELLER_SENT_WRONG_PRODUCT: 'The seller sent the wrong goods',
            PRODUCT_STYLE_ERROR: 'Version/batch/color, etc. do not match the {{productName}} description',
            DAMAGED_PRODUCT_PACKAGING: 'Damaged packaging or {{productName}}',
            OTHER_REASONS: 'Other reasons',
            REASON_FOR_RETURN: 'Reason for refund',
            REFUND_TYPE: 'Refund Type',
            FILL_IN_THE_LOGISTICS_ORDER_NUMBER: 'Fill in the logistics slip number',
            EVALUATE_THE_ORDER: 'Review the list',
            PRODUCT_NAME: 'Product Name',
            PLEASE_SELECT_IMAGE_SOURCE: 'Please select an image source',
            PHONE_ALBUM: 'Mobile phone album',
            DELETE_INVOICE_TIPS: 'Are you sure you want to delete this billing information?',
            INVOICE_CREATE_BTN_TEXT: 'Create a new invoicing information',
            NO_INVOICE_INFORMATION_YET: 'There is no invoice information',
            TAX_ID: 'Tax ID',
            INVOICE_SETTING: 'Invoicing settings',
            ADDRESS_PLACEHOLDER: 'Please enter an address',
            BANK_ACCOUNT_PLACEHOLDER: 'Please enter your bank account number',
            BANK_ACCOUNT: 'Account',
            INVOICING_INFORMATION: 'Invoicing information',
            VAT_ELECTRONIC_GENERAL_INVOICE: 'VAT electronic free text invoice',
            VAT_SPECIAL_ELECTRONIC_INVOICE: 'Special electronic invoices for VAT',
            INVOICE_DOCUMENT: 'Invoice file',
            INVOICE_FILE_TYPE: 'Invoice type',
            INVOICE_HEADER_NAME: 'Header name',
            CREATED_SUCCESSFULLY: 'Created successfully',
            VAT_ORDINARY_INVOICE: 'VAT free text invoice',
            VAT_SPECIAL_INVOICE: 'Special VAT invoices',
            INVOICE_NUMBER: 'Invoice number',
            INVOICE_TYPE: '开票类型',
            ELECTRONIC_INVOICE: 'Electronic invoicing',
            MANUAL_INVOICE: 'Manual invoices',
            INVOICE_AMOUNT: 'Invoice amount',
            INVOICING_TIME: 'Invoicing time',
            APPLICATION_TIME: 'Application time',
            FAILED_TO_CREATE: 'Created failed',
            EMAIL_INVALID_ERROR: 'The email address is not filled in or the format is incorrect',
            PHONE_INVALID_ERROR: 'Please fill in the correct mobile phone number',
            ADDRESS_INVALID_ERROR: 'Special symbols are not supported for the shipping address',
            NSRSBH_INVALID_ERROR: 'Please enter the correct taxpayer identification number',
            NAME_CANNOT_BE_EMPTY: 'The name cannot be empty',
            INVOICE_HEADER_CANNOT_BE_EMPTY: 'Invoice header cannot be empty',
            FAILED_LOADED_ACCOUNT_INFORMATION_PLEASE_RETRY_AGAIN: 'Failed to obtain account information, please refresh and try again',
            TO_BE_PAID: 'Pending payment',
            TO_BE_JION: 'Pending group',
            PENDING_RECEIPT_OF_GOODS: 'Waiting for delivery',
            COMPLETED: 'Completed order',
            ALL_ORDERS: 'All orders',
            MY_SERVICES: 'My Services',
            ADDRESS_MANAGEMENT: 'My Address',
            MY_FAVORITES: 'My Favorites',
            MY_COUPONS: 'My coupons',
            MY_INVOICE: 'Invoice header',
            ACCOUNT_SECURITY: 'Account Security',
            INVITATION_WITH_PRIZE: 'Referral with prize',
            FEEDBACK: 'Feedback',
            SUCCESSFUL_EXIT: 'exit successfully',
            FAILED_TO_GET_PERSONAL_INFORMATION: 'Failed to get personal information!',
            SPARE_CHANGE_DETAILS: 'Change breakdown',
            MY_SPARE_CHANGE: 'My change',
            MY_WALLET: 'My wallet',
            BALANCE_DETAILS: 'Balance Details',
            CHOOSE: 'choose',
            NO_PICK_UP_LOCATIONS_AVAILABLE: 'There are no optional pick-up points',
            SUNDAY: 'Sunday',
            MONDAY: 'Monday',
            TUESDAY: 'Tuesday',
            WEDNESDAY: 'Wednesday',
            THURSDAY: 'Thursday',
            FRIDAY: 'Friday',
            SATURDAY: 'Saturday',
            PICK_UP_TIPS: 'Please pick up your order at the pick-up point as soon as possible',
            TODAY: 'Today',
            TOMORROW: 'Tomorrow',
            THE_DAY_AFTER_TOMORROW: 'Day after tomorrow',
            ALL_DAY: 'all day',
            MORNING: 'morning',
            AFTERNOON: 'Afternoon',
            NIGHT: 'At night',
            FEEDBACK_CONTENT: 'Feedback content',
            FEEDBACK_CONTENT_PLACEHOLDER: 'The feedback content is not less than five words',
            FEEDBACK_CONTACT: 'Please fill in your mobile phone number or email address',
            CONTACT_DETAILS: 'Contact details',
            FEEDBACK_POSTING: 'Submitting',
            FEEDBACK_FAILED: 'Please complete the contact details and feedback content',
            NO_CONSUMPTION_RECORD: 'There are no records of consumption',
            CONSUMPTION_RECORD: 'Consumption history',
            CONSUMPTION_RECORD_DETAILS: 'Breakdown of consumption records',
            DESCRIBE: 'description',
            ORDER_ID: 'Order id',
            TRANSACTION_NUMBER: 'Transaction flow number',
            TRANSACTION_TIME: 'Trading hours',
            TOTAL_GIFT_CARD_BALANCE: 'Total gift card balance',
            BUY_A_NEW_CARD: 'Buy a new card',
            BIND_NEW_CARD: 'Bind the new card',
            USED: 'available',
            NOT_USED: 'not available',
            CHOOSE_IMG_FILE_TO_UPDATE_AVATAR: 'Changing your avatar requires selecting an image file!',
            CHANGE: 'change',
            NO_ACCOUNT_SECURITY_SETTINGS_INFORMATION: 'There is no account security setting information',
            LOGIN_SETUP: 'Login name setup',
            CURRENT_LOGIN: 'Current login:',
            HAS_BEEN_SET: 'Set',
            NOT_SET: 'Not set',
            SET_LOGIN: 'Setup login name',
            PASSWORD_SETUP: 'Password setup',
            SET_PASSWORD: 'Set password',
            CHANGE_PASSWORD: 'Change password',
            SET_PHONE_BINDING: 'Mobile binding',
            BIND_THE_PHONE: 'Bind mobile',
            CHANGE_PHONE: 'Change mobile',
            UNBOUND: 'Not bound',
            BOUND: 'Bound',
            SET_WECHAT_BINDING: 'WeChat binding',
            UNBOUND_WECHAT_ACCOUNT: 'WeChat account is not bound',
            UNBIND_WECHAT: 'Unbind WeChat',
            BIND_WECHAT: 'Bind WeChat',
            UNBOUND_AND_NOT_SET_PASSWORD: 'The password cannot be set without the mobile phone number bound to the current account',
            GO_TO_BIND: 'Go to Bindings',
            UNBIND_WECHAT_TIPS: 'After unbinding you will not be able to use WeChat Login to this website, are you sure you want to unbind?',
            CONFIRM_UNBIND: 'Confirm unbinding',
            UNBIND_WECHAT_SUCCESSFULLY: 'Unbind the WeChat binding of the current account successfully!',
            WECHAT_SCAN_CODE_IS_NOT_ENABLED: 'Scan code binding has not been enabled, please open this page in WeChat for binding',
            WECHAT_AUTHORIZATION_IS_NOT_ENABLED: 'Authorization binding has not been enabled yet, please open this page in your browser for binding',
            THIS_FEATURE_IS_NOT_YET_AVAILABLE: 'This feature is not yet available',
            LOGIN_NAME_EMPTY_ERROR: 'Login name is not entered, please check',
            WRONG_FIELD_FORMAT: '{{name}} malformed',
            CAPTCHA_VERIFY_CODE_PLACEHOLDER: 'Enter the image code',
            PLEASE_ENTER_A_NEW_PASSWORD: 'Please enter a new password',
            SUCCESSFULLY_MODIFIED: 'Modification successful',
            PHONE_VERIFICATION: 'Phone verification',
            PWD_VERIFICATION: 'Password verification',
            WAYS_OF_IDENTIFYING: 'Authentication mode',
            INPUT_OLD_PHONE_PLACEHOLDER: 'Please enter your original phone number',
            OLD_PHONE: 'Old phone number',
            OLD_PHONE_VERIFICATION_CODE: 'Old SMS code',
            NEW_PHONE__PLACEHOLDER: 'Please enter a new phone number',
            NEW_PHONE_VERIFICATION_CODE: 'New SMS code',
            NEW_PHONE: 'New phone number',
            MODIFY_PHONE_NUMBER: 'Change your phone number',
            CAPTCHA_LABEL: 'Image captcha',
            NEW_PHONE_CAPTCHA_ERROR: 'The new mobile phone number picture verification code was entered incorrectly',
            OLD_PHONE_CAPTCHA_ERROR: 'The original phone number picture verification code was entered incorrectly',
            CURRENT_AVATAR: 'Avatar',
            USER_NICKNAME_PLACEHOLDER: 'Please enter the nickname',
            USER_NICKNAME: 'Nickname',
            GENDER: 'Gender',
            TYPE: 'Type',
            CONFIRM_THE_CHANGES: 'Save Changes',
            CONTACT_DETAILS_PLACEHOLDER: 'Please enter your contact details',
            MOBILE_PHONE: 'Mobile phone',
            MOBILE_PHONE_PLACEHOLDER: 'Please enter your mobile number',
            NAME: 'Name',
            NAME_PLACEHOLDER: 'Please enter a name',
            ORGANIZATION_NAME_PLACEHOLDER: 'Please enter an organization name',
            ORGANIZATION_NAME: 'organization',
            ORGANIZATION_NAME_TITLE: 'Organization name',
            FAIL_TO_EDIT: 'Modification failed',
            LOGISTICS_STATUS: 'Logistics status',
            LOGISTICS_COMPANY: 'Logistics companies',
            LOGISTIC_NUMBER: 'The number of the logistics slip',
            FILL_IN_THE_REFUND_LOGISTICS_INFORMATION: 'Fill in the refund logistics information',
            COPY_THE_TRACKING_NUMBER: 'Copy',
            LOGISTICS_INFORMATION: 'Logistics information',
            LOGISTICS_INFORMATION_TIPS: 'No logistics trajectory information, please try again later!',
            TRACK_NUMBER_COPIED: 'The single number has been copied',
            NO_DISCOUNT_COUPONS_AVAILABLE: 'There is no coupon to collect',
            GET_YOUR_COUPON_NOW: 'Claim it now',
            GET_YOUR_COUPON_TEXT: 'The voucher is valid on the day of receipt {{validityDays}}',
            GET_COUPON: 'Collect your voucher',
            TO_BUY: 'Go buy',
            NO_FAVORITES_YET: 'There are no favorites',
            DELETE_PRODUCT_NAME_TIPS: "Are you sure you want to delete this collection's {{productName}}?",
            MOBILE_PLACEHOLDER: 'Enter contact information',
            YOUR_AREA: 'Region',
            NAME_EMPTY_ERROR: 'The name cannot be empty or all spaces',
            NAME_INVALID_ERROR: 'Special symbols are not supported for consignee names',
            PHONE_EMPTY_ERROR: 'Your contact details were not entered or entered incorrectly',
            ADDRESS_EMPTY_ERROR: 'The shipping address cannot be empty or all spaces',
            PROVINCE_NAME_EMPTY_ERROR: 'Please select a region',
            CITY_NAME_EMPTY_ERROR: 'Please improve your area',
            ADDRESS_LENGTH_ERROR: 'The delivery address is up to 33 characters',
            INVOICING_RESULT: 'Invoicing results',
            CREATE_ADDRESS: 'Create address',
            UPDATE_ADDRESS: 'Update the address',
            CHANGE_ADDRESS: 'UPdate address',
            DELETE_ADDRESS_TIPS: 'Are you sure you want to delete this address?',
            ORDER_CREATE: 'Order Submission',
            ORDER_COMPLETED: 'Order completion',
            INVOICING_IS_COMPLETE: 'Invoicing is complete',
            TOTAL_AMOUNT: 'Total',
            FAILED_TO_LOAD_ORDER_INFORMATION: 'Failed to load order information',
            ORDER_ID_TEXT: 'Order id',
            CREATION_TIME: 'Created time',
            UPDATE_TIME: 'Update time',
            PAYMENT_METHOD: 'Payment Methods',
            EMAIL_BINDING: 'Email binding',
            BIND_THE_EMAIL: 'Bind email',
            CHANGE_EMAIL: 'Change email',
            OLD_EMAIL_VERIFICATION_CODE: 'Old email code',
            NEW_EMAIL_VERIFICATION_CODE: 'New email code',
            EMAIL_VERIFICATION: 'Email verification'
        },
        PAYMENT_METHOD: {
            WECHAT: 'Wechat',
            ALIPAY: 'Alipay',
            CASH: 'Cash',
            GIFT_CARD: 'Gift cards',
            BANK_TRANSFER: 'Bank transfer',
            OTHER: 'Other',
            PAYPAY: 'PAYPAY',
            CONSUMING_CARD: 'Cousuming card',
            COUPON: 'Coupon',
            POINTS: 'Points',
            PRODUCT: 'Product',
        },
        CATEGORY: {
            NO_PRODUCT: 'There are no products ',
            NO_RELATED: 'Not relevant ',
            CONTENT: 'content',
            NO_CATEGORY: 'There is no categories ',
            SHRINK_BTN_TEXT: 'shrink',
            EXPAND_BTN_TEXT: 'expend',
        },
        FORM: {
            SELECT_THE_FORM_RESPONSE_YOU_WANT_TO_COPY: 'Select the commit record that you want to copy',
            UPDATE_TIME: 'Update time',
            COPY_BTN_TEXT: 'Copy',
            COPY_AND_EDIT: 'Copy and edit',
            REFILL: 'Refill in',
            INPUT_VALIDATED_FAILED_TIPS_END: 'Bits contain only numbers, alphabetic characters!',
            NOT_FOUND_CURRENT_FORM_TIPS: 'The form does not exist, please re-enter or see something else',
            THE_FORM_RESPONSE_TO_BE_COPIED_IS_NOT_SELECTED_AND_CANNOT_BE_COPIED: 'The form record you have not selected to copy cannot be copied!',
            FOUND_SOME_FORM_RESPONSE_THAT_I_FILLED_OUT_EARLIER_DID_I_COPY_THE_CONTENTS_AND_EDIT_THEM_FOR_SUBMISSION: 'Found some previous form records, copy the content and edit the submission?',
            FILL_FORM_BEFORE_PURCHASE: 'Form to be filled before purchase',
            FILL_FORM_AFTER_PAID: 'Form to be filled after payment',
            PLEASE_FILL_OUT_THE_FORM_FIRST: 'Please fill out the form first',
            NEED_APPLY_FORM_TIPS: 'At least {{needApplyFormNumber}} more forms to fill out',
            WHETHER_TO_BIND_THE_FORM_RESPONSE: 'Whether to bind a filled form record to the current order',
            CONFIRM_AND_BIND: 'Confirm and bind',
            APPLY_FORM__AND_BIND_ORDER_TIPS: 'Fill out the form and bind the order successfully!',
            GO_TO_FILL_OUT: 'To Fill',
            FILLED_IN: 'Filled in',
        },
        SHOPPING_CART: {
            FAVORITE_IT: 'Favorite',
            GO_SHOPPING: 'Go shopping',
            SHOPPING_CART_IS_EMPTY_TIPS: 'The shopping cart is empty, check out something else',
        },
        PRODUCT_PAGE: {
            GO_SHOPPING_CART: 'Go to Cart',
            CURRENT_GROUP_CANNOT_JOIN_PLEASE_TRY_ANOTHER_ONE: 'The current group can not participate, please try another one',
            PRODUCT_ERROR_SEE_OTHER_ONE: "We're sorry that this item is not available for sale, please check out other items!",
            CREATE_GROUP_BUYING_PRICE_ERROR: 'The price of the group leader is set incorrectly, and the group cannot be opened!',
            BUY_AT_LEAST_ONE_TIPS: 'Purchase at least one piece',
        },
        GROUP_BUYING_PAGE: {
            TITLE: 'Group details',
            SUCCESSFULLY_JOINED_TOGETHER: 'Wait for people to succeed in the group',
            MY_GROUP_BUYING: 'My group',
            GROUP_BUYING_INFORMATION_TITLE: 'Notice for the group:',
            GROUP_BUYING_INFORMATION_TIPS: 'Ginseng tours, insufficient number of people will be refunded automatically',
            GROUP_BUYING_REMAINING_TIME: 'Time remaining in the group',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_TIPS: 'Man, come and join my group!',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_TIPS: 'People, you can successfully assemble the group!',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_LEFT_LABEL: 'only',
            JOIN_NOW: 'Join the tour now',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_FRIENDS_TIPS: 'No group members, go and invite your friends to participate!',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_NEW_PEOPLE_TIPS: 'No group members, go and invite newcomers to participate!',
            INVITE_FRIENDS_TO_JOIN_THE_GROUP: 'Invite friends to join the group',
            INVITE_NEW_GINSENG_GROUP: 'Newcomers are invited to join the group',
            FAILED_TO_GET_GROUP_INFORMATION_TIPS: 'Failed to get the group information, please check the network and refresh to try again',
            GROUP_HAS_BEEN_CANCELLED: 'The current group has been cancelled',
            HURRY_UP_TO_INVITE: 'Hurry up and invite',
            COME_AND_JOIN_MY_GROUP: 'Hurry up and join my group',
            GROUP_BUYING_STATUS_IS_UNKNOWN: 'The status of the group is unknown',
            GROUP_BUYING_HAS_STOPPED: 'The group has stopped',
            GROUP_BUYING_HAS_BEEN_CANCELLED: 'The group has been cancelled',
            THE_GROUP_BUYING_IS_COMPLETE: 'The group is complete',
            THE_GROUP_BUYING_HAS_TIMED_OUT: 'The group has timed out',
            THE_STATE_OF_THE_GROUP_BUYING_IS_ABNORMAL: 'The status of the group is abnormal',
            NEED_SOME_PERSON_TO_SUCCESSFUL_FOR_GROUP_BUYING: 'It is still a matter of {{memberGap}} people successfully combining the group',
        },
        CUSTOMER_SERVICE: {
            SERVICE_HOTLINE: 'Service Hotline',
            OFFICIAL_ADDRESS: 'Official address',
            COMPANY_ADDRESS: 'Company address',
            OFFICIAL_WECHAT: 'Official WeChat',
        },
        DOCUMENT: {
            NO_TERMS_AND_CONDITIONS: 'No terms and conditions are available',
            LOGIN_TERM: 'Login Terms',
            REGISTER_TERM: 'Terms of Registration',
            PURCHASE_TERM: 'Terms of Purchase',
            RETURN_TERM: 'Refund Terms'
        },
        GIFT_CARD: {
            EXPIRATION_DAYS_AFTER_ACTIVATION: 'Expiration time after activation (days)',
            CAMPAIGN_CODE: 'Activity number',
            CHANNEL_CODE: 'Channel number',
            CARD_MEDIA_TYPE: 'Card type',
            CURRENCY: 'Coin clock type',
            ACTIVATION_EXPIRATION_DATE: 'Activation deadline',
            CNY: 'Renminbi',
            ELECTRONIC: 'Electronic cards',
            ORDER_TYPE: 'Apply the order',
            REMARK: 'remark',
            SERVICE_ORDER: 'Service Orders',
            SALES_ORDER: 'Sales orders',
            GIFT_CARD_TIPS: 'Optional, please negotiate with the merchant first'
        },
        FORM_CONTROL: {
            DOCUMENTS_MUST_BE_UPLOADED: 'Must upload!',
            PENDING_UPLOAD: 'Pending upload',
            CANCEL_UPLOAD: 'Cancel the upload',
            UPLOAD: 'Upload',
            DOWNLOAD: 'Download',
            DRAG_OR_CLICK_TO_UPLOAD: 'Drag/click upload',
            FILES: 'piece',
            APPLICATION: 'apply',
            IMAGE: 'Image',
            VIDEO: 'Video',
            AUDIO: 'audio',
            COMPRESS: 'Compressed package',
            FILES_UPLOAD: 'Attachment upload',
            FILE_SIZE_NUMBER_MB_CANNOT_BE_UPLOADED: 'Files with size {{fileSize}}mb cannot be uploaded',
            PLEASE_UPLOAD_THE_TYPE_FORMAT_FILE_WITHIN_FILESIZE_MB_IN_SIZE: 'Please upload {{fileType}} files with a size of {{fileSize}}}mb!',
            FILE_TYPE: 'format',
            CONTROL_CANNOT_BE_EMPTY: "Can't be empty!",
            TEXTAREA: 'textarea',
            PLEASE_CHOOSE: 'Please select',
            SELECT: 'Drop-down box',
            RADIO_GRID: 'Matrix radio',
            RADIO: 'radio',
            LINEAR_SCALE_GRID: 'Matrix scale',
            LINEAR_SCALE: 'Linear scale',
            PLEASE_TYPE_IN: 'Please enter',
            PLEASE_ENTER_CONTENT: 'Please enter content',
            ADDRESS: 'Address',
            INPUT: 'Single-line text',
            LENGTH_CANNOT_BE_LESS_THAN: 'The length cannot be less than',
            LENGTH_CANNOT_BE_GREATER_THAN: 'The length cannot be greater than',
            CANNOT_BE_LESS_THAN: 'Cannot be less than',
            CANNOT_BE_GREATER_THAN: 'Cannot be greater than',
            INVALID_ERROR: 'The format is incorrect!',
            CHECKBOX_GRID: 'Checkbox grid',
            TITLE: 'title',
            SUBTITLE: 'subheading',
            TEXT: 'body',
            RICHTEXT: 'Rich text',
            CHECKBOX: 'Multi-check box',
            SHOULD_CHOOSE: 'Should be selected',
            OPTIONS: 'options',
            PREV_STEP: 'Previous',
            NEXT_STEP: 'Next',
            DATETIME: 'Time',
            DATE: 'date',
            DATE_NOT_SELECTED: 'Date not selected!',
            THE_TIME_IS_NOT_ENTERED_COMPLETELY: 'Time is not entered completely!',
            PLEASE_CHECK: ', please check!',
        },
        EDITOR: {
            TITLE: 'title',
            SUB_TITLE: 'subheading',
            BODY: 'body',
            DEFAULT: 'default',
            EXPORT_PDF: 'Export the pdf',
            PREVIEW_PDF: 'Preview the pdf',
            PRINT: 'print',
            MARGIN_LEFT: 'Left margin',
            MARGIN_RIGHT: 'Right margin',
            LINE_HEIGHT: 'Row height',
            MARGIN_TOP: 'Top margin',
        },
        FILTER_PARAMS_SELECTOR: {
            SORT: 'Sorting',
            FILTER: 'Filtering',
            CATEGORY: 'Category',
            PUT_AWAY: 'Put away',
            EXPEND_MORE: 'See more',
            KEYWORD: 'Keyword',
            PRICE: 'Price',
            CANCEL: 'Cancel',
            CONFIRM: 'Confirm',
            KEYWORD_PLACEHOLDER: 'Please enter filter keywords',
        },
        SHARED: {
            DISCOUNTED_COUPON_PRICE: 'Coupon amount',
            NOT_SUPPORT_ADDRESS: 'Currently not supported for purchasing in this address!',
            NOT_SUPPORT_ADDRESS_TIP: 'Currently, purchasing in this address is not supported. Please choose a different address!',
            COMMENT_PLACEHOLDER: 'Please enter a comment',
            DELIVERY_ADRESS_EMPTY: 'Please select the address first',
            DELIVERY_INFORMATION_EMPTY: 'Did not get the receipt information, please refresh and try again!',
            DISCOUNTED_PRICE: 'Discounted price',
            UNAVAILABLE_FOR_COUPON: 'Not available',
            EDIT_PICKER_INFO: 'Edit picker information',
            PICKER_INFO_PLACEHOLDER: 'Please improve the pick-up person information',
            EDIT_PICKER_INFO_PLACEHOLDER: 'Please enter the name of the person who picked up the goods',
            EDIT_PICKER_PHONE_PLACEHOLDER: "Please enter the picker's mobile phone number",
            NO_COUPONS_AVAILABLE: 'No coupons available',
            SELF_PICKUP_TIME: 'Pickup time',
            TOGGLE: 'Switch',
            CHANGE_ADDRESS: 'Switch',
            SELF_PICKUP_PERPON: 'Pickup person',
            THE_ORDER_HAS_BEEN_REVIEWED_AND_DOES_NOT_SUPPORT_REFUNDS: 'Refunds are not supported when orders have been approved',
            REFUND_ORDER_DETAILS: 'Refund order details',
            PAYMENT_METHOD: 'payment method',
            FILL_FROM_AFTER_PAID_TIPS: 'Please fill in the form after payment',
            REFUND_NUMBER: 'Refund number',
            I_HAVE_SENT_IT_CLICK_TO_FILL_IN_THE_LOGISTICS_ORDER_NUMBER: 'Fill in express info',
            ONLINE_PAY_REFUND_AMOUNT: 'WeChat/Alipay refund amount',
            GIFT_CARD_REFUND_AMOUNT: 'Gift card refund amount',
            WAITING_FOR_SELLER_TO_RECEIVE: 'Returned, waiting for the seller to receive the goods',
            PASSED_THE_REVIEW_TIPS: 'The merchant has agreed to the return request, please return it as soon as possible.',
            ORDER_TYPE: 'The order type',
            THE_AMOUNT_PAID_FOR_THE_ORDER: 'The amount paid for the order',
            EXPRESS_LOGISTICS: 'Express logistics',
            SHIPPING_FEES: 'Shipping fees',
            NO_DELIVERY_REQUIRED: 'No delivery required',
            DELIVERY_METHOD: 'Delivery method',
            APPLICATION_STATUS: 'Application status',
            OTHER_REASONS_INVALID_ERROR: 'Other reasons are incorrectly filled in',
            OTHER_REASONS_EMPTY_ERROR: 'Other reasons are not filled in',
            PHONE_INVALID_ERROR: 'The phone number format is incorrect',
            REQUEST_A_REFUND_PLACEHOLDER: 'Tips: non-on-site pick-up of shipped orders, freight is not refundable!',
            PLEASE_FILL_IN_OTHER_REASONS: 'Please fill in other reasons',
            ANONYMOUS_COMMENTS: 'Anonymous comments',
            PLEASE_ENTER_REVIEW_CONTENT: 'Please enter the content of the review',
            BIND_GIFT_CARD: 'Bind gift cards',
            TO_BE_PICKED_UP: 'To be picked up',
            GIFT_CARD_SCRET: 'Card scret',
            GROUP_TO_BE_FORMED: 'The group is to be formed',
            PAID_TO_BE_PICKED: 'Paid, to be picked',
            PAID_PENDING: 'Paid, pending',
            ORDER_NOT_PAID: 'The order was not paid',
            PLEASE_ENTER_GIFT_CARD_SCRET: 'Please enter the card secret',
            COMMENTED: 'Commented',
            BIND: 'bind',
            INPUT_GIFT_CARD_SCRET_TIPS: 'Please enter the gift card pin (e-card card can be viewed for order details)',
            GIFT_CARD_SCRET_EMPTY_ERROR: 'Cardmi cannot be empty',
            BINDING_SUCCEEDED: 'Binding succeeded',
            GIFT_CARD_SCRET_INVALID_ERROR: 'Please enter the correct card password',
            MAX_QUANTITY_FOR_ONCE_TIPS: 'You can only buy {{value}} pieces at a time!',
            MAX_QUANTITY_FOR_ONCE_BY_STOCK_TIPS: 'You can only buy {{value}} pieces at most!',
            NEWCOMER_GROUP: 'Newcomers join the group',
            BROWSER_UNSPPORT_VIDEO_PLAY: 'Your browser is too old to play videos, please upgrade your browser!',
            SOLD_DESC: '{{value}} sold',
            THERE_IS_NO_INFORMATION: 'No information',
            COMMENT: 'Comment',
            PURCHASE_NOTICE: 'Purchase Instructions',
            COPY_SUCCESSFULLY: 'Copied successfully!',
            BIND_FAILED: 'Binding failed',
            USE_THIS_GIFT_CARD_TIPS: 'Are you sure you want to bind this gift card to your current account?',
            USE_THIS_GIFT_CARD: 'Bind to the current account',
            VIEW_THIS_GIFT_CARD: 'View card secrets',
            VIEW_GIFT_CARDS_IN_ORDER: 'View orders for gift cards',
            LOAD_GIFT_CARDS_ERROR: 'The order-related gift card failed',
            CARD_NUMBER: 'Card number',
            PAR_VALUE: 'Value',
            EXPIRATION_DATE: 'Expiration date',
            CLICK_TO_SEE_LARGER_PICTURE: 'Click to view a larger image',
            ENLARGE_IMAGE: 'Enlarged view',
            CLICK_TO_GO: 'Click Go',
            COUPONS: 'Coupons',
            DO_NOT_USE: 'Not used',
            FAILED_TO_INITIALIZE_QR_CODE_FUNCTION: 'Initialization of the QR code function failed, please check whether the network is normal or whether it is blocked by the ad blocking plug-in and killed by mistake!',
            DRAW_AND_SHARE: 'Speed Drawing Sharing Chart, please be patient',
            BROWSER_NOT_SUPPORT_CANVAS: 'The current browser does not support drawing two-dimensional codes, please upgrade or change the browser',
            LOAD_LOGO_IMG_FAILED: 'Failed to load logo image or logo image does not exist, please try again later~',
            HOW_TO_SHARE_ON_WECHAT: '(1) Press and hold to save a picture or send a friend',
            SAVE_PICTURES: '(1) Save the image',
            SCAN_THE_CODE_TO_VIEW: '(2) Scan to view details',
            GROUP_BUYING: 'Grouping',
            GROUP_BUYING_DESC: 'People group • full delivery • people are not satisfied with the refund',
            LOAD_QRCODE_FAILED: 'Failed to load {{productName}} QR code, please try again later.',
            LOAD_IMG_FAILED: 'Failed to load {{productName}} image, please try again later.',
            SHIP_BEFORE_DATE: '{{value}} before shipment',
            SHIPS_DAYS_AFTER_PAYMENT: "Shipped within {{value}} days after payment",
            MORE_PRODUCT: 'More products',
            DEFAULT: 'Default',
            LATEST: 'Latest',
            SALES: 'Sales',
            PRICE: 'Price',
            MORE: 'MORE',
            NOT_FOUND_TIPS: "The relevant {{value}} is not found, so let's take a look at something else",
            HOT_SALE: 'Hot',
            CANCEL_ORDER_TIPS: 'Are you sure you want to cancel this order',
            CANCELLED: 'Canceled',
            IN_PROGRESS_GROUPINGS_DO_NOT_ALLOW_REFUNDS: 'Refunds are not permitted for ongoing groups!',
            OUT_OF_STOCK: 'No more can be added',
            OFF_STOCK: 'Out of stock',
            ORDER_REVIEWED: 'Orders have been reviewed and no refunds are available',
            INSUFFICIENT_AMOUNT_ACTUALLY_PAID: 'The actual payment amount is 0 and cannot be invoiced',
            ACTUAL_PAYMENT_AS_GIFT_CARD: 'Payment is made by gift card and cannot be invoiced',
            INVOICE_SUBMITTED_SUCCESSFULLY: 'The current order has been submitted for invoicing, is under review, wait patiently Oh...',
            REFUND_REQUESTED: 'A refund has been requested',
            ORDER_ID: 'Order id',
            ORDER_CANCELLED: 'The order has been cancelled',
            REMAINING_PAYMENT_TIME: 'Remaining payment time',
            SECONDS: 'second',
            MINUTE: 'minute',
            REFUNDS: 'Return refunds',
            REQUEST_A_REFUND: 'Request a refund',
            REFUND_SUCCESSFULLY: 'Refund successful',
            REFUND_UNDER_REVIEW: 'Refunds are under review',
            REFUND_FAILED: 'The refund did not pass',
            REFUND_APPROVED: 'The refund is approved',
            REFUND_PENDING_REVIEW: 'Reviewing refunds',
            GO_TO_COMMENT: 'To comment',
            EVALUATED: 'Commented',
            GO_TO_EVALUATE: 'To comment',
            PRESALE_ORDER: 'Pre-sale orders',
            GROUP_BUYING_ORDER: 'Group buying orders',
            ESTIMATED_DELIVERY_TIME: 'Expected shipping time',
            DELIVERY_TIME: 'Shipping time',
            NORMAL_ORDER: 'Normal orders',
            SELF_PICKUP: 'Self pickup',
            NUMBER_PIECES_IN_TOTAL: '{{totalQuantity}} pieces in total',
            THE_TOTAL_AMOUNT_OF_ORDERS: 'Total amount',
            SHIPPING_VALUE: 'Ship fee: {{value}}',
            AMOUNT_PAID: 'Paid amount',
            AMOUNT_TO_BE_PAID: 'Amount to pay',
            REFUND_AMOUNT: 'Refund amount',
            ORDER_TIME: 'Order time',
            INVOICED: 'Invoiced',
            INVOICING_REVIEW: 'Invoicing is under review',
            APPLY_FOR_MAKING_AN_INVOICE: 'Request invoicing',
            VIEW_LOGISTICS: 'View logistics',
            BUY_AGAIN: 'Buy again',
            HOURS: 'hour',
            TO_PAY: 'Go to pay',
            SITEWIDE: 'Full venue',
            BE_USABLE: 'Available',
            UNAVAILABLE: 'not available',
            SUBMITTED: 'Submitted',
            REFUND_FAILED_ERROR: 'Refund failure Contact your administrator',
            ORDER_DETAILS: 'Order details',
            PASSED_THE_REVIEW: 'Approved',
            NOT_ADDRESS_DATA_TIPS: 'There is currently no address, click to create new address',
            NOT_INVOICE_HEADER_DATA_TIPS: 'No available invoice header, go to create',
            TO_CREATE_TIPS: 'Create',
            ORDER: 'orders',
            STORE_WIDE_COUPONS: 'Store-wide coupons',
            DESIGNATED_PRODUCT_COUPONS: 'Specify {{value}} coupon',
            YEAR_MONTH_DAY_FROMART_TEXT: '{{year}} {{month}},{{day}} ',
            YEAR_MONTH_DAY_PIPE_FROMART_TEXT: 'yyyy-MM-dd',
            ADDRESS_NOT_SELECTED_TIPS: 'Please select a delivery address',
            FREE_SHIPPING_ON_SITE: 'Free shipping on site',
            FREE_SHIPPING_OVER_NUMBER_KG: 'Free shipping for orders over {{value}}KG',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT: 'Free shipping for over {{value}}KG',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT_AND_WEIGHT: 'Free shipping for orders over {{value}}KG and over {{mount}}KG'
        }
    },
};
