import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../../app.constants';
import { IPaymentResult } from '../../shared/model/payment-result.model';
import { IPaymentRequest } from '../../shared/model/payment-request.model';
import { INativePaymentResult } from '../../shared/model/native-payment-result.model';
import { ICustomerGiftCard } from '../../shared/model/customer-gift-card.model';
import { IWxPayMpOrderResult } from './model/xx-pay-mp-order-result.model';
import { IWxJsapiSignature } from './model/wx-jsapi-signature.model';
import { IWxPayUnifiedOrderResult } from './model/wx-pay-unified-order-result.model';
import { SalesOrderService } from '../../../builtin-pages/user-center/pages/all-order-page/_service/sales-order.service';
import { CustomerGiftCardService } from '../../../builtin-pages/user-center/pages/gift-cards/_service/customer-gift-card.service';

type EntityResponseType = HttpResponse<IPaymentResult>;
type WxH5ResponseType = HttpResponse<IWxPayUnifiedOrderResult>;
type WxJsPayResultType = HttpResponse<IWxPayMpOrderResult>;
type NativeResponseType = HttpResponse<INativePaymentResult>;
type CustomerGiftCardsResponseType = HttpResponse<ICustomerGiftCard[]>;
type WxJsapiSignatureResponseType = HttpResponse<IWxJsapiSignature>;
type SalesOrderResponseType = HttpResponse<any>;

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

@Injectable({ providedIn: 'root' })
export class OrderPayService {
    public aliWapPayUrl = API_SERVICE_PREFIX + '/payment/api/pay/alipay/wappay';
    public aliPagePayUrl = API_SERVICE_PREFIX + '/payment/api/pay/alipay/pagepay';
    public wxJSPayUrl = API_SERVICE_PREFIX + '/payment/api/pay/wxpay/wxUnifiedOrders';
    public wxH5PayUrl = API_SERVICE_PREFIX + '/payment/api/pay/wxpay/wxUnifiedOrders/h5';
    public giftCardPayUrl = API_SERVICE_PREFIX + '/payment/api/pay/gift-card';
    public consumingCardPayUrl = API_SERVICE_PREFIX + '/payment/api/pay/consuming-card';
    public wxNativePayUrl = API_SERVICE_PREFIX + '/payment/api/pay/wxpay/wxUnifiedOrders/native';
    public paypayPayUrl = API_SERVICE_PREFIX + '/payment/api/pay/paypay/webpay';
    public stripePayUrl = API_SERVICE_PREFIX + '/payment/api/pay/stripe/payment-link';
    shopId: string = '';

    constructor(
        protected http: HttpClient,
        private salesOrderService: SalesOrderService,
        private customerGiftCardService: CustomerGiftCardService,
    ) { }

    aliWapPay(redirectUrl: string, req: IPaymentRequest): Observable<any> {
        return this.http
            .post(this.aliWapPayUrl + `?redirectUrl=${redirectUrl}`, req, { responseType: 'text', headers: headerOptions });
    }

    paypayPay(redirectUrl: string, req: IPaymentRequest): Observable<any> {
        return this.http
            .post(this.paypayPayUrl + `?redirectUrl=${redirectUrl}`, req, { observe: 'body', headers: headerOptions });
    }

    stripePay(redirectUrl: string, req: IPaymentRequest): Observable<any> {
        return this.http
            .post(this.stripePayUrl + `?redirectUrl=${redirectUrl}`, req, {
                responseType: 'text', headers: headerOptions
            });
    }

    aliPagePay(redirectUrl: string, req: IPaymentRequest): Observable<any> {
        return this.http
            .post(this.aliPagePayUrl + `?redirectUrl=${redirectUrl}`, req, { responseType: 'text', headers: headerOptions });
    }

    wxJSPay(openId: string, req: IPaymentRequest): Observable<WxJsPayResultType> {
        const wxJSPayUrl = this.wxJSPayUrl + `?openId=${openId}`;
        return this.http
            .post<IWxPayMpOrderResult>(wxJSPayUrl, req, { observe: 'response', headers: headerOptions });
    }

    wxH5Pay(redirectUrl: string, req: IPaymentRequest): Observable<WxH5ResponseType> {
        const wxH5PayUrl = this.wxH5PayUrl + `?redirectUrl=${redirectUrl}`;
        return this.http
            .post<IWxPayUnifiedOrderResult>(wxH5PayUrl, req, { observe: 'response', headers: headerOptions });
    }

    giftCardPay(req: IPaymentRequest): Observable<EntityResponseType> {
        return this.http
            .post<IPaymentResult>(this.giftCardPayUrl, req, { observe: 'response', headers: headerOptions });
    }

    consumingCardPay(req: IPaymentRequest): Observable<EntityResponseType> {
        return this.http
            .post<IPaymentResult>(this.consumingCardPayUrl, req, { observe: 'response', headers: headerOptions });
    }
    wxNativePay(redirectUrl: string, req: IPaymentRequest): Observable<NativeResponseType> {
        const wxNativePayUrl = this.wxNativePayUrl + `?redirectUrl=${redirectUrl}`;
        return this.http
            .post<INativePaymentResult>(wxNativePayUrl, req, { observe: 'response', headers: headerOptions });
    }

    queryCustomerGiftCards(option: any): Observable<CustomerGiftCardsResponseType> {
        return this.customerGiftCardService.query(option, headerOptions).pipe(map((res: CustomerGiftCardsResponseType) => res));
    }

    getJsSignature(option?: any): Observable<WxJsapiSignatureResponseType> {
        return this.http.get<IWxJsapiSignature>(`${API_SERVICE_PREFIX}/user/api/wx/js-signature`,
            { params: option, observe: 'response', headers: headerOptions });
    }

    findSalesOrder(salesOrderId?: number): Observable<SalesOrderResponseType> {
        return this.salesOrderService.find(salesOrderId);
    }
}
