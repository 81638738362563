export enum NavigationLayout {
    'LEFT_SIDE_BAR' = 'LEFT_SIDE_BAR',
    'RIGHT_SIDE_BAR' = 'RIGHT_SIDE_BAR',
    'BOTTOM_TAB_BAR' = 'BOTTOM_TAB_BAR',
    'TOP_BAR' = 'TOP_BAR',
}

export enum NavigationPosition {
    'FIXED' = 'FIXED',
    'STATIC' = 'STATIC',
}

