export const orderTranslate = {
    ERROR: {
        BINDING_FORM_FAILED: 'フォームのバインドに失敗しました:',
        FAILED_TO_SUBMIT_ORDER: '注文の送信に失敗しました:',
    },
    GENERAL: {
        DISCOUNT_TIP: 'アフィリエイト割引特典、クーポンクレジット特典は、送料には適用されません。',
        SUBMIT_ORDER: '注文を出す',
        ORDER_ID: '注文番号',
        TOTAL_AMOUNT_OF_ORDERS: '合計金額',
        AMOUNT_PAID: '支払済金額',
        AMOUNT_TO_BE_PAID: '未払金額',
        FOLLOW_THE_PAYMENT_INSTRUCTIONS_TO_PAID: 'オフライン支払を選択した場合は、支払い手順に従って支払いを行います。',
        CONTACT_THE_STORE_TO_PAID: 'オフライン決済を選択した場合は、店舗にお問い合わせください。',
        PAYMENT_BY_OTHER_METHODS: 'その他の支払い方法',
        OFFLINE_PAYMENTS: 'オフラインで支払う',
        STRIPE_PAYMENTS: 'Stripe',
        PAY_FOR_THE_ORDER: '注文を支払う',
        CONFIRM_PAYMENT: '支払いを確認',
        CONFIRM_THE_PAYMENT_INFORMATION: '以下の支払い情報をご確認ください',
        PAYMENT_AMOUNT: '支払金額',
        WECHAT_PAY: 'WeChat',
        PAY_BY_ALIPAY: 'Alipay',
        PAY_BY_PAYPAY: 'PayPay',
        PAYMENT_METHOD: '支払い方法',
        WECHAT_TO_SCAN_THE_CODE_TO_PAY: 'WeChat スキャンを使用して、コードを支払う',
        PAYMENT_RESULT_CONFIRMATION: '支払結果の確認',
        PLEASE_MAKE_PAYMENT_ON_THE_NEWLY_OPENED_PAGE: '新しく開いたページでお支払いください',
        AFTER_PAYMENT_CONFIRMATION: 'お支払いが完了したら、状況に応じて下のボタンをクリックします。',
        PAYMENT_COMPLETED: '支払いが完了しました',
        ORDER_PAYMENT_COMPLETED: '注文支払い完了',
        PAYMENT_COMPLETED_TIMEOUT_TIP: '{{value}}秒後、自動的に注文リストページにリダイレクトされます。',
        PAYMENT_NOT_COMPLETED: '支払いは完了していません',
        SELECT_PAYMENT_METHOD: '支払方法を選択',
        GIFT_CARD_PAYMENT: 'ギフトカードで支払い',
        CURRENTLY_UNAVAILABLE_BALANCE: '現在の残高',
        RECHARGE: 'リチャージ',
        CANNOT_BE_PAID_WITH_A_GIFT_CARD: '注文にはギフトカード商品が含まれていますが、ギフトカードで支払うことはできません',
        BALANCE: '残高',
        USE: '使用します',
        DEDUCT: '控除',
        PAY: '支払い',
        PAY_AMOUNT: '支払金額',
        NO_PAYMENT_METHOD_AVAILABLE: '利用可能な支払い方法はありません',
        NO_PAYMENT_METHOD_AVAILABLE_TIPS: '支払い方法はありませんので、更新して再試行するか、管理者にお問い合わせください!',
        BUY_AND_PAY: '購入支払い',
        PAYMENT_ORDER_FAILED: '支払いは注文に失敗しました',
        PAYMENT_TIMED_OUT: '支払いがタイムアウトしましたので、再度お支払いください',
        PAYMENT_CANCELLED: '支払いはキャンセルされました',
        Available_Balance: '利用可能残高',
        Unavailable_Balance: '利用できない残高',
        Gift_Card_Istructions: 'ヒント: 注文と同じ通貨のギフトカードの残高のみを使用できます',
    }
};
