import { isPlatformServer } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, OnDestroy, PLATFORM_ID, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appLoadingAnimationAndErrorBackground]'
})
export class LoadingAnimationAndErrorBackgroundDirective implements OnDestroy {
    @Input() loadingClassName = 'ng-img-loading';
    @Input() loadSuccessClassName = 'ng-img-loaded';
    @Input() loadFailedClassName = 'ng-img-failed';
    @Input() loadingImageWrapperClass = 'ng-img-wrapper';
    @Input() loadingDivWrapperClass = 'ng-img-loading-wrapper';
    // 考虑到ssr时目前使用的ng-lazyload-image插件不会将懒加载的数据直接赋值，我们插件来对其处理下
    @Input() set lazyLoad(value: string) {
        this.imageUrl = value;
        if (isPlatformServer(this.platformId)) {
            this.rd2.setAttribute(this.el?.nativeElement, 'src', this.imageUrl);
        }
    };
    loadingImageAnimationWrapper: any;
    loadingChildrenWrapper: any;
    imageUrl: string;
    constructor(private el: ElementRef,
        private rd2: Renderer2,
        @Inject(PLATFORM_ID) private platformId: any,) { }

    loadImageError() {
        this.el?.nativeElement?.classList.forEach(className => {
            this.rd2.addClass(this.loadingImageAnimationWrapper, className);
        });
        this.rd2.addClass(this.loadingImageAnimationWrapper, this.loadFailedClassName);
        this.rd2.removeClass(this.loadingImageAnimationWrapper, this.loadingClassName);
        this.rd2.removeClass(this.loadingImageAnimationWrapper, this.loadSuccessClassName);
        this.rd2.setStyle(this.loadingChildrenWrapper, 'visibility', 'visible');
        this.rd2.setStyle(this.loadingChildrenWrapper, 'position', 'static');
        this.rd2.setStyle(this.el?.nativeElement, 'visibility', 'hidden');
    }
    // 现代化浏览器
    @HostListener('load', ['$event']) loadForModernBrowser = () => {
        this.loadImageSuccess();
    }
    // IE6-IE10支持该事件，其它浏览器不支持。
    @HostListener('readystatechange', ['$event']) loadForIEBrowser = () => {
        this.loadImageSuccess();
    }
    loadImageSuccess() {
        if (this.loadingImageAnimationWrapper) {
            this.rd2.addClass(this.loadingImageAnimationWrapper, this.loadSuccessClassName);
            this.rd2.removeClass(this.loadingImageAnimationWrapper, this.loadingClassName);
            this.rd2.removeClass(this.loadingImageAnimationWrapper, this.loadFailedClassName);
            // 为了避免图片可能设置了背景图片导致加载完成后父级盒子还有背景故而手动清除
            this.rd2.setStyle(this.loadingImageAnimationWrapper, 'background', 'none');
            this.rd2.setStyle(this.loadingChildrenWrapper, 'visibility', 'hidden');
            this.rd2.setStyle(this.loadingChildrenWrapper, 'position', 'absolute');
            // 恢复图片的显示
            this.rd2.setStyle(this.el?.nativeElement, 'visibility', 'visible');
        }
    }
    createLoadingChildren() {
        // 初次加载的话
        // 这个属性好像有问题，会导致complete属性不准
        // this.rd2.setAttribute(this.el.nativeElement, 'loading', 'lazy');
        this.loadingImageAnimationWrapper = this.rd2.createElement('div');
        this.el.nativeElement?.classList.forEach(className => {
            this.rd2.addClass(this.loadingImageAnimationWrapper, className);
        });
        this.rd2.addClass(this.loadingImageAnimationWrapper, this.loadingClassName);
        this.rd2.addClass(this.loadingImageAnimationWrapper, this.loadingImageWrapperClass);
        const loadingChildrenWrapper = this.rd2.createElement('div');
        this.rd2.addClass(loadingChildrenWrapper, this.loadingDivWrapperClass);
        this.rd2.setStyle(loadingChildrenWrapper, 'display', 'flex');
        this.rd2.setStyle(loadingChildrenWrapper, 'align-items', 'center');
        this.rd2.setStyle(loadingChildrenWrapper, 'width', '100%');
        this.rd2.setStyle(loadingChildrenWrapper, 'height', '100%');
        for (let i = 0; i < 3; i++) {
            const loadingChildItem = this.rd2.createElement('div');
            this.rd2.addClass(loadingChildItem, 'ng-img-loading-animation-item');
            this.rd2.appendChild(loadingChildrenWrapper, loadingChildItem);
        }
        this.rd2.appendChild(this.loadingImageAnimationWrapper, loadingChildrenWrapper);
        this.rd2.appendChild(this.el?.nativeElement?.parentElement, this.loadingImageAnimationWrapper);
        this.rd2.appendChild(this.loadingImageAnimationWrapper, this.el.nativeElement);
        // 为了避免图片有绝对定位导致反而把加载效果遮挡了，于是先隐藏
        this.rd2.setStyle(this.el?.nativeElement, 'visibility', 'hidden');
        this.loadingChildrenWrapper = loadingChildrenWrapper;
        // 如果有缓存直接成功的话
        if (this.el.nativeElement?.complete) { this.loadImageSuccess(); }
        // 如果加载失败的话
        this.rd2.listen(this.el?.nativeElement, 'error', this.loadImageError.bind(this));
    }
    // 监听ng-lazyload-image插件的状态变更事件
    @HostListener('onStateChange', ['$event']) listenStateForModernBrowser = (result) => {
        switch (result.reason) {
            case 'start-loading':
                if (!this.loadingImageAnimationWrapper && !this.loadingChildrenWrapper) { this.createLoadingChildren(); }
                break;
            case 'loading-succeeded':
                this.loadImageSuccess();
                break;
            case 'loading-failed':
                this.loadImageError();
                break;
            case 'finally':
                // The last event before cleaning up
                break;
        }
    }
    ngOnDestroy() {
        this.loadingImageAnimationWrapper = null;
        this.loadingChildrenWrapper = null;
    }
}
