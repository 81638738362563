import { Routes } from '@angular/router';
import { builtinPageRoutes } from './builtin-pages/builtin-pages-routes';

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        // redirectTo: '/home',
        // pathMatch: 'full',

    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'page',
        loadChildren: () => import(`./custom-page-renderer/custom-page-renderer.module`).then(m => m.CustomPageRendererModule),
    },
    {
        path: '',
        loadChildren: () => import(`./ecommerce/ecommerce.module`).then(m => m.EcommerceModule),
    }
];


export const routes: Routes = [
    ...appRoutes,
    ...builtinPageRoutes,
];

