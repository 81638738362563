<app-block-container [blockId]="blockId" [enabledMoreBtn]="false" [renderOptions]="renderOptions" [enabledTitle]="false"
    [enabledMoreBtn]="false" [section]="section">
    <div class="announcement-container" style="width: 100%;" data-skeleton-bgcolor="#eeeeee"
        [ngClass]="{'full-width-mode':renderOptions?.fullWidth}">
        <div class="announcement-contents b-r">
            <i class="iconfont icon-gonggao1"
                style="font-size: 22px;line-height: 36px;margin-left: 10px;color: var(--btn2-bg-color,#ffd000);"></i>
            <Carousel class="carousels" [autoplay]="true" [infinite]="true" [vertical]="true" [dots]="false"
                [dragging]="true" *ngIf="announcements?.length">
                <CarouselSlide *ngFor="let item of announcements">
                    <div style="line-height: 36px;margin:0 8px;background-color: #fff;" (click)="toAnnouncementsPage()">
                        <div class="text-overfollow-ellipsis" style="color: #999;">
                            <span class="card-color text-overfollow-ellipsis" style="line-height: 8px;">{{item |
                                mNameTranslate:'title'}}</span>
                        </div>
                    </div>
                </CarouselSlide>
            </Carousel>
            <div class="px-3" *ngIf="!announcements?.length" [appTranslate]="'GENERAL.NO_DATA'">暂无数据</div>
        </div>
    </div>
</app-block-container>
