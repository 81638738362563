import { Params } from "@angular/router";

export interface MenuConfig {
    title?: string,
    page?: string,
    iconClass?: string,
    translate?: string,
    submenu?: MenuConfig[],
    redirectTo?: string;
    regExp?: RegExp;
    authorities?: any[];
    queryParams?: Params;
    hidden?: boolean;
}

export const SiteMenus: MenuConfig[] = [
    // 个人中心的相关路由配置
    {
        title: '个人中心',
        translate: 'FLOATING_WINDOW.USER_CENTER',
        page: '/user-center/',
        submenu: [
            {
                title: '订单列表',
                translate: 'HOME.ALL_ORDERS',
                page: '/user-center/orders',
                submenu: [
                    {
                        title: '订单详情',
                        translate: 'SHARED.ORDER_DETAILS',
                        page: '/user-center/orders/paid-order',
                    },
                ]
            },
            {
                title: '地址管理',
                translate: 'HOME.ADDRESS_MANAGEMENT',
                page: '/user-center/delivery-address',
                submenu: [
                    {
                        title: '新增地址',
                        translate: 'HOME.CREATE_ADDRESS',
                        page: '/user-center/delivery-address/new-address',
                    },
                    {
                        title: '修改地址',
                        translate: 'HOME.UPDATE_ADDRESS',
                        regExp: /\/user-center\/delivery-address\/(.*?)\/edit/,
                    },
                ]
            },
            {
                title: '物流信息',
                translate: 'HOME.LOGISTICS_INFORMATION',
                page: '/user-center/logistics-info'
            },
            {
                title: '申请退款',
                translate: 'SHARED.REQUEST_A_REFUND',
                page: '/user-center/refund'
            },
            {
                title: '评价',
                translate: 'HOME.EVALUATE_THE_ORDER',
                page: '/user-center/comment'
            },
            {
                title: '我的收藏',
                translate: 'HOME.MY_FAVORITES',
                page: '/user-center/customer-favorite'
            },
            {
                title: '我的优惠券',
                translate: 'HOME.MY_COUPONS',
                page: '/user-center/customer-coupon'
            },
            {
                title: '提交订单',
                translate: 'HOME.ORDER_CREATE',
                page: '/user-center/order-page'
            },
            {
                title: '选择自提点',
                translate: 'HOME.SELECT_SELF_PICK_UP_ADDRESS',
                page: '/user-center/self-pickup-addresses'
            },
            {
                title: '开票设置',
                translate: 'HOME.MY_INVOICE',
                page: '/user-center/customer-invoice',
                submenu: [
                    {
                        title: '修改抬头',
                        translate: 'HOME.INVOICING_INFORMATION',
                        regExp: /\/user-center\/customer-invoice\/(.*?)\/edit/,
                    },
                    {
                        title: '新增抬头',
                        translate: 'HOME.INVOICING_INFORMATION',
                        page: '/user-center/customer-invoice/new',
                    },
                ]
            },
            {
                title: '我的礼品卡',
                translate: 'INDEX.MY_GIFT_CARDS',
                page: '/user-center/gift-card',
                submenu: [
                    {
                        title: '消费记录',
                        translate: 'HOME.CONSUMPTION_RECORD',
                        regExp: /\/user-center\/gift-card\/(.*?)\/log/,
                    },
                    {
                        title: '消费记录明细',
                        translate: 'HOME.CONSUMPTION_RECORD_DETAILS',
                        regExp: /\/user-center\/gift-card\/(.*?)\/log\/(.*?)\/detail/,
                    },
                ]
            },
            {
                title: '意见反馈',
                translate: 'HOME.FEEDBACK',
                page: '/user-center/feedback'
            },
            {
                title: '个人资料',
                translate: 'INDEX.PERSONAL_INFORMATION',
                page: '/user-center/personal-info'
            },
            {
                title: '账户安全',
                translate: 'HOME.ACCOUNT_SECURITY',
                page: '/user-center/account-security',
                submenu: [
                    {
                        title: '更换邮箱',
                        translate: 'HOME.CHANGE_EMAIL',
                        page: '/user-center/account-security/change-email'
                    },
                    {
                        title: '修改密码',
                        translate: 'HOME.CHANGE_PASSWORD',
                        page: '/user-center/account-security/change-password'
                    },
                    {
                        title: '修改手机号',
                        translate: 'HOME.MODIFY_PHONE_NUMBER',
                        page: '/user-center/account-security/change-phone'
                    },
                    {
                        title: '绑定手机号',
                        translate: 'AUTH.BIND_MOBILE_NUMBER',
                        page: '/user-center/account-security/bind-mobile-phone'
                    },
                    {
                        title: '设置用户名',
                        translate: 'USER.ACCOUNT_SECURITY.SET_LOGIN_NAME.TITLE',
                        page: '/user-center/account-security/set-login-name'
                    },
                    {
                        title: '绑定邮箱',
                        translate: 'AUTH.BIND_EMAIL',
                        page: '/user-center/account-security/bind-email'
                    },
                ]
            },
            {
                title: '客户服务',
                translate: 'FOOTER.AFTER_SALES_SERVICE',
                page: '/user-center/sales-service'
            },
            {
                title: '推荐有奖',
                translate: 'HOME.INVITATION_WITH_PRIZE',
                page: '/user-center/referral',
                submenu: [
                    {
                        title: '推荐记录',
                        translate: 'GENERAL.REFERRAL_HISTORY_TEXT',
                        page: '/user-center/referral/history',
                    },
                ]
            },
            {
                title: '联盟网络',
                translate: 'FOOTER.AFFILIATE_SERVICES',
                page: '/user-center/affiliate',
                submenu: [
                    {
                        title: '加入联盟',
                        translate: 'FOOTER.AFFILIATE_BTN_TEXT',
                        regExp: /\/user-center\/affiliate\/(.*?)\/join/,
                    },
                    {
                        title: '档案',
                        translate: 'USER.AFFILIATE_PROFILE.MENU_TITLE',
                        page: '/user-center/affiliate/profile',
                    },
                ]
            },
            {
                title: '佣金记录',
                translate: 'USER.COMMISSION.COMMISSION_RECORDS',
                page: '/user-center/marketing-partner-reward',
                submenu: [
                    {
                        title: '结算记录',
                        translate: 'USER.COMMISSION.SETTLEMENT_RECORDS',
                        page: '/user-center/marketing-partner-reward/settlements',
                    },
                ]
            },
            {
                title: '我的发票',
                translate: 'GENERAL.INVOICE_CENTER',
                page: '/user-center/invoice-center',
                submenu: [
                    {
                        title: '订单详情',
                        translate: 'SHARED.ORDER_DETAILS',
                        page: '/user-center/orders/paid-order',
                    },
                ]
            },
        ]
    },
    // 文章菜单配置
    {
        title: '文章列表',
        translate: 'GENERAL.ARTICLE_LIST',
        page: '/post/list',
    },
    // 自定义页面配置
    {
        title: '页面',
        page: '/page',
        hidden: true,
        submenu: [
            {
                title: '分类',
                translate: 'GENERAL.CATEGORY',
                regExp: /\/page\/(.*?)\/product-category/,
            },
            {
                title: '商品分类',
                translate: 'GENERAL.CATEGORY',
                regExp: /\/page\/(.*?)\/product-categories/,
            },
            {
                title: '文章列表',
                translate: 'GENERAL.ARTICLE_LIST',
                regExp: /\/page\/(.*?)\/post\/list/,
            },
        ]
    },
    // 搜索页
    {
        title: '搜索',
        translate: 'GENERAL.SEARCH',
        page: '/search-page',
    },
    // 店铺公告页
    {
        title: '搜索',
        translate: 'INDEX.ANNOUNCEMENT',
        page: '/announcements',
    },
    {
        title: '客户服务',
        translate: 'FOOTER.CUSTOMER_SERVICE',
        page: '/customer-service',
    },
    {
        title: '线下支付',
        translate: 'ORDER.GENERAL.OFFLINE_PAYMENTS',
        page: '/offline-payment-redirect'
    },
    {
        title: '系列商品',
        translate: 'GENERAL.SERIES_PAGE',
        page: '/series-page'
    },
    {
        title: '拼团详情',
        translate: 'GROUP_BUYING_PAGE.TITLE',
        page: '/share-group-buying'
    },
];
