<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="renderOptions" [enabledMoreBtn]="false" [section]="section">
    <div class="diy-post-list" [ngClass]="{'diy-post-list-padding':currentDeviceService?.isDesktop()}">
        <div class="diy-content-wrapper no-background-img" *ngIf="diyContents?.length">
            <div class="diy-content b-r" [ngClass]="{'content-when-many-items-than-one-rows':diyContents?.length>4}">
                <div class="diy-content-item" *ngFor="let diyContentItem of diyContents"
                    (click)="navigateToSomePageByDiyContentItem(diyContentItem)">
                    <div class="diy-content-item-icon-wrapper b-r"
                        [ngClass]="{'diy-content-item-icon': !diyContentItem?.imgUrl}"
                        style="display: inline-block;position: relative;">
                        <img class="diy-content-item-icon b-r" *ngIf="diyContentItem?.imgUrl" [decode]="true"
                            offset="100" appLoadingAnimationAndErrorBackground
                            [lazyLoad]="(diyContentItem?.imgUrl | uploadUrlPrefix) | imgResize: 'h_120'"
                            appPureImgErrorIdentification [setErrorImg]="true"
                            loadFailedImage='/assets/icon/img-blank.svg' />
                    </div>
                    <div class="diy-content-item-title line-clamp" *ngIf="!checkBlockTitleIsEmpty(diyContents)">
                        {{diyContentItem | mTitleTranslate}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-block-container>