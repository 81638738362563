<div class="page-block-container {{containerClass}}" appBricsAnimateOnIntersection
    [bricsAnimationName]="_renderOptions?.animation?.name + ((_renderOptions?.animation?.direction ? ('-'+_renderOptions?.animation?.direction):''))"
    [bricsAnimationDuration]="_renderOptions?.animation?.duration"
    [bricsAnimationDelay]="_renderOptions?.animation?.delay" [emitOnce]="_renderOptions?.animation?.emitOnce"
    [attr.blockId]="blockId" [ngStyle]="!attachStyleToInnerContent && _renderOptions?.container" [ngClass]="{ 
        'full-width-mode':_renderOptions?.fullWidth,
         'content-width-mode':_renderOptions?.fullWidth!==true, 
         'block-in-content':section==='CONTENT', 
         'block-contain-title':enabledTitle && (_renderOptions?.title?.display !== 'none')
        }"
    [style.background]="_renderOptions?.fullWidth && _renderOptions?.container && _renderOptions?.container['background-color']">
    <div class="page-block-inner-content" [ngStyle]="attachStyleToInnerContent && _renderOptions?.container" [ngClass]="{
        'b-r':_renderOptions?.fullWidth!==true,
        'overflow-hidden':!disabledOverflowHidden && (_renderOptions?.fullWidth!==true),
        'content-width-limit':_renderOptions?.innerContentWidthLimit===true
    }">
        <div class="d-flex align-items-center" [ngClass]="{
                'justify-content-between': _renderOptions?.title?.['text-align'] !== 'center',
                'justify-content-center': _renderOptions?.title?.['text-align'] === 'center'
            }" [style.paddingTop]="_renderOptions?.title?.['padding-top']"
            [style.paddingBottom]="_renderOptions?.title?.['padding-bottom']"
            *ngIf="enabledTitle && (_renderOptions?.title?.display !== 'none' )">
            <span class="title {{titleClass}} py-0" [ngStyle]="_renderOptions?.title"
                [style.letterSpacing]="_renderOptions?.title?.['letter-spacing'] + 'px'"
                [appFontSizeScale]="_renderOptions?.title?.['font-size']">{{
                { title: title, titleTranslation: titleTranslation } | mTitleTranslate
                }}</span>
            <span class="select-all" *ngIf="enabledMoreBtn && (_renderOptions?.title?.['text-align'] !== 'center')"
                (click)="moreBtnClick?.emit()">
                <span [appTranslate]="moreBtnText || ('GENERAL.SEE_MORE' | translate)"> See more</span>
                &nbsp;>
            </span>
        </div>
        <div class="block-content">
            <ng-content></ng-content>
        </div>
        <div class="d-flex justify-content-center align-items-center block-footer"
            *ngIf="enabledMoreBtn && (_renderOptions?.title?.['text-align'] === 'center')"
            [style.paddingTop]="_renderOptions?.title?.['padding-bottom']"
            [style.paddingBottom]="_renderOptions?.title?.['padding-bottom']">
            <div class="to-more-page-btn" (click)="moreBtnClick?.emit()">
                <span [appTranslate]="moreBtnText || ('GENERAL.SEE_MORE' | translate)" style="letter-spacing: normal;">
                    See more</span>
            </div>
        </div>
    </div>
</div>