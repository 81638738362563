import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild, afterNextRender, afterRender } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, NavigationEnd, NavigationError, Router, Scroll } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { AccountService } from './core/auth/account.service';
import { Principal } from './core/auth/principal.service';
import { SocialShare } from '../assets/js/tools/social-share';
import { ToastService } from './shared/services/toast.service';
import { WebviewAdaptorForMiniprogramService } from './shared/webview-adaptor-for-miniprogram.service';
import { debounceTime, filter, take, tap } from 'rxjs/operators';
import { WeChatLoginService } from './core/services/wechat-login.service';
import { TermType } from './ecommerce/shared/model/shop-user-term.model';
import { AutoLoginForWechatService } from './core/services/auto-login-for-wechat.service';
import { CurrentDeviceService } from './core/services/current-device.service';
import { changeSiteMeta, getBrowserScrollBarWidth, setRootCssVariable } from '../assets/js/tools/utils';
import { TranslationService } from '../i18n';
import { PageService } from './shared/services/page.service';
import { SiteContextService } from './shared/services/site-context.service';
import { Layout } from './shared/model/layouts.model';
import { NavigationLayout, NavigationPosition } from './shared/model/navigation-layout.enum';
import { Site, defaultFeatureOptions } from './shared/model/site.model';
import { DescriptionTranslatePipe } from './shared/pipes/description-translate.pipe';
import { NameTranslatePipe, jsonParse } from './shared/pipes/name-translate.pipe';
import { getSelectedLang, setLang } from './shared/pipes/selected-language-helper.pipe';
import { UploadUrlPrefixPipe } from './shared/pipes/upload-url-prefix.pipe';
import { BreadcrumbService } from './shared/services/breadcrumb.service';
import { ShopContextService } from './ecommerce/shared/service/shop-context.service';
import { SiteGraph } from './shared/model/site-graph.model';
import { LayoutBlock } from './shared/model/layout-block.model';
import { ParseTargetEntityConfigService } from './core/services/parse-target-entity-config.service';
import { NewCouponComponent } from './ecommerce/shared/components/new-coupon/new-coupon.component';
import { locale as enLang } from '../i18n/vocabs/en-US';
import { locale as jaLang } from '../i18n/vocabs/ja';
import { locale as zhCNLang } from '../i18n/vocabs/zh-CN';
import { locale as zhTWLang } from '../i18n/vocabs/zh-TW';
import { SsrPolyfillService } from './core/services/ssr-polyfills.service';
import { CustomPageRendererComponent } from './custom-page-renderer/custom-page-renderer.component';
import { CurrentPageResolver } from './current-page-resolver';

export interface IMenu {
    title: string;
    path: string;
    icon: string;
    activeIcon: string;
    visible: boolean;
    sort?: number;
    rawConfig?: Object;
    isCustomize?: boolean;
    customizeIndex?: number;
    defaultSort?: number;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [NameTranslatePipe, DescriptionTranslatePipe, UploadUrlPrefixPipe]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('newCoupon', { static: false }) newCoupon: NewCouponComponent;
    userName: string;
    isLogin = false;
    subs: Subscription[] = [];
    asideMenuHeaderHeight: string = '';
    TermType = TermType;
    userNoticeInfoContentArray: any = [];
    clauseContent = '';
    showGoTop = false;
    subscribeScoll: any;
    hiderightbar = false;
    scrolTopTimer: number;
    footerBlock: LayoutBlock;
    siteMenu = null;
    layout = null;
    site = null;
    NavigationLayout = NavigationLayout;
    layoutRenderOptions: any = null;
    themeSettled = false;
    loadSiteError: boolean;
    supportEcommerceFunc: boolean = false;
    customerId: number;
    isEmitedNewCouponFaild: boolean = false;
    isMobile = false;
    constructor(
        private titleService: Title,
        private toastService: ToastService,
        private socialShare: SocialShare,
        public shopContextService: ShopContextService,
        @Inject(DOCUMENT) private _document: Document,
        public accountService: AccountService,
        private principal: Principal,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private weChatLoginService: WeChatLoginService,
        private webviewAdaptorForMiniprogramService: WebviewAdaptorForMiniprogramService,
        private autoLoginForWechatService: AutoLoginForWechatService,
        public currentDeviceService: CurrentDeviceService,
        public translationService: TranslationService,
        public siteContextService: SiteContextService,
        public pageService: PageService,
        private nameTranslatePipe: NameTranslatePipe,
        private descriptionTranslatePipe: DescriptionTranslatePipe,
        private uploadUrlPrefix: UploadUrlPrefixPipe,
        public breadcrumbService: BreadcrumbService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
        private renderer: Renderer2,
        private ssrPolyfillService: SsrPolyfillService,
        @Inject(PLATFORM_ID) private platformId: any,
        private el: ElementRef,
    ) {
        this.translationService.loadTranslations(enLang, zhTWLang, zhCNLang, jaLang);
        afterRender(() => {
            this.asideMenuHeaderHeight = this.getAsideMenuHeaderHeight();
            this.cdr.detectChanges();
        });
    }

    ngOnInit() {
        const siteGraph = this.siteContextService.siteGraph;
        this.loadSiteError = siteGraph.errorMock;
        // dynamically add the pages with non-null slug into the router:
        siteGraph.pages.forEach(page => {
            if (!page.builtIn && !!page.slug) {
                this.router.config.push({
                    path: page.slug,
                    component: CustomPageRendererComponent,
                    resolve: {
                        currentPage: CurrentPageResolver
                    },
                    canActivate: [AutoLoginForWechatService],
                });
            }
        });
        // console log output all the route entries in the router:
        // console.log('router config size: ', this.router.config.length);
        // this.router.config.forEach(route => {
        //     console.log('route', route.path);
        // });

        this.renderLayout(siteGraph);
        if (isPlatformServer(this.platformId)) {
            this.themeSettled = true;
        }
        this.autoLoginForWechatService.checkCodeInRouterPathAndLogin();
        this.routerActiveWatch();
    }

    // // 动态路由，但是不工作，且不能使用siteContextService，貌似不会请求
    // private dynamicMenuInit(menu: Menu, router: Router) {
    //     if (menu?.items?.length) {
    //         const dynamicMenus = [];
    //         menu?.items?.forEach(menuItem => {
    //             if (menuItem?.linkedPageSlug) {
    //                 if (!router?.config?.find(routerItem => routerItem === menuItem?.linkedPageSlug)) {
    //                     dynamicMenus.push({
    //                         path: menuItem?.linkedPageSlug,
    //                         component: CustomPageRendererComponent,
    //                         // loadChildren: () => import(`./custom-page-renderer/custom-page-renderer.module`).then(m => m.CustomPageRendererModule),
    //                     });
    //                 }
    //             }
    //         });
    //         console.log('dynamicMenus:', dynamicMenus);
    //         router.resetConfig([...router?.config, ...dynamicMenus]);
    //     }
    // }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.recalculateClientLayout();
            // 开启 监听设备宽度变化，200ms内防抖处理，实时检测当前设备宽度
            let sub = fromEvent(window, 'resize').pipe(debounceTime(200))
            .subscribe(() => {
                this.recalculateClientLayout();
            });
            this.subs.push(sub);

            this.loginOrBindAfterWechatAuthorization();
            this.watchLoginStatus();
            this.onWindowScroll();
            this.webviewAdaptorForMiniprogramService.subscribeMiniprogramMessageByRouterEventChange(this.router.url);
            this.socialShare.globalConfig(this.site);
        }
    }

    private recalculateClientLayout() {
        this.isMobile = this.currentDeviceService.isMobile();
        this.asideMenuHeaderHeight = this.getAsideMenuHeaderHeight();
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

    themeChange(layout: Layout) {
        try {
            setRootCssVariable('--browser-scroll-bar', getBrowserScrollBarWidth() + 'px');
            const renderOptions = jsonParse(layout.renderOptions);
            if (renderOptions.theme) {
                this.renderer.addClass(this.ssrPolyfillService.getDocument(), renderOptions.theme + '-active');
                if (renderOptions.theme === 'custom-theme') {
                    this._document.documentElement.style.setProperty('--background-color', renderOptions.customizeTheme.customizedThemeItems[0]);
                    this._document.documentElement.style.setProperty('--content-color', renderOptions.customizeTheme.customizedThemeItems[1]);
                    this._document.documentElement.style.setProperty('--theme-color', renderOptions.customizeTheme.customizedThemeItems[2]);
                    this._document.documentElement.style.setProperty('--sub-color', renderOptions.customizeTheme.customizedThemeItems[3]);
                    if (renderOptions.customizeTheme.enabledCustomizedBgImg) {
                        this._document.documentElement.style.setProperty('--index-bg-img', `url(${renderOptions.customizeTheme.customizedThemeBackgroundImgUrl})`);
                        this._document.documentElement.style.setProperty('--login-img-bg', `url(${renderOptions.customizeTheme.customizedThemeLoginBackgroundImgUrl})`);
                        this._document.documentElement.style.setProperty('--header-bg-img',
                            `url(${renderOptions.customizeTheme.customizedThemeHeaderBackgroundImgUrl})`);
                    }
                    if (renderOptions?.customizeTheme?.customizedFontFamily) {
                        try {
                            this._document.querySelector('body').style.cssText = this._document.querySelector('body').style.cssText + (';font-family:' + renderOptions.customizeTheme.customizedFontFamily + ',' + window.getComputedStyle(document.body).fontFamily + '!important')?.replace(',,', ',')?.replace(';;', ';');
                        } catch (error) {

                        }
                    }
                }
                this.themeSettled = true;
            }
        } catch (err) {

        }
    }

    renderLayout(siteGraph: SiteGraph) {
        this.layout = siteGraph?.layouts?.[0];
        this.layoutRenderOptions = jsonParse(this.layout?.renderOptions) || {
            desktopNavigation: NavigationLayout.TOP_BAR,
            mobileNavigation: NavigationLayout.BOTTOM_TAB_BAR,
            desktopNavigationPosition: NavigationPosition.STATIC
        };
        this.footerBlock = this.layout.blocks?.find(block => (block?.blockType === 'FOOTER') && (block?.section === 'FOOTER'));
        this.themeChange(this.layout);
        // update menu:
        this.siteMenu = siteGraph?.menus?.[0];
        // update branding:
        if (JSON.stringify(siteGraph?.site) !== JSON.stringify(this.site)) {
            this.site = siteGraph?.site;
            changeSiteMeta(this.nameTranslatePipe.transform(siteGraph?.site), this.descriptionTranslatePipe.transform(siteGraph?.site), {
                siteName: this.nameTranslatePipe.transform(siteGraph?.site),
                previewImg: this.uploadUrlPrefix.transform(siteGraph?.site?.iconUrl) as string
            });
            this.changeTabConfig(siteGraph?.site);
        }
    }

    get getSiteFeatureOptions() {
        return jsonParse(this.site?.featureOptions) || defaultFeatureOptions;
    }

    changeTabConfig(site: Site) {
        this.titleService.setTitle(site.name);
        this._document.getElementById('appFavicon').setAttribute('href', this.uploadUrlPrefix.transform(site?.iconUrl) as string);
    }

    public getAsideMenuHeaderHeight() {
        if (this.checkHeaderBackgroundEnabled() && this.checkIsAsideForMobileDevice()) {
            return '';
        } else {
            const mobileHeight = this._document.querySelector('#asideMenuHeader')?.clientHeight ? (this._document.querySelector('#asideMenuHeader')?.clientHeight + 1 + 'px') : '';
            const pcHeight = (this.layoutRenderOptions?.desktopNavigationPosition === NavigationPosition.FIXED && this._document.querySelector('#siteMenuHeaderContainer')?.clientHeight) ? (this._document.querySelector('#siteMenuHeaderContainer')?.clientHeight + 1 + 'px') : '';
            return this.currentDeviceService?.isMobile() ? mobileHeight : pcHeight;
        }
    }

    private checkIsAsideForMobileDevice() {
        return this.currentDeviceService.isMobile() && [NavigationLayout?.RIGHT_SIDE_BAR, NavigationLayout?.LEFT_SIDE_BAR]?.includes(this.layoutRenderOptions?.mobileNavigation)
    }

    private checkHeaderBackgroundEnabled() {
        const headerType = jsonParse(this.checkHeaderConfig()?.renderOptions)?.headerType;
        return !['NONE', null, undefined]?.includes(headerType);
    }

    addRemHeight(a, b) {
        if (a && b) {
            return Number(a?.replace('rem', '')) + Number(b?.replace('rem', '')) + 'rem';
        }
        return undefined;
    }

    checkHeaderConfig() {
        return this.layout.blocks?.find(block => {
            return (block?.blockType === 'HEADER') && (block?.section === 'HEADER');
        });
    }

    getFooterBlock() {
        return this.layout.blocks?.find(block => {
            return (block?.blockType === 'FOOTER') && (block?.section === 'FOOTER');
        });
    }

    scollPostion() {
        let t;
        if (this._document.documentElement && this._document.documentElement.scrollTop) {
            t = this._document.documentElement.scrollTop;
        } else if (this._document.body) {
            t = this._document.body.scrollTop;
        }
        return {
            top: t,
        };
    }

    onWindowScroll() {
        this.subscribeScoll = fromEvent(window, 'scroll').subscribe(() => {
            this.showGoTop = this.scollPostion().top > 20;
        });
    }

    watchLoginStatus() {
        const sub = this.principal.getAuthenticationState().subscribe(isLogin => {
            this.isLogin = isLogin;
            if (isLogin && !this.userName) { this.getUserInfo(); }
        });
        this.subs.push(sub);
    }

    getUserInfo() {
        this.accountService.getUserInfo().subscribe(res => {
            this.userName = res.customer?.nickName || res.customer?.name;
            this.siteContextService.setCustomer(res.customer);
            // 判断是否要弹出新人优惠券：如果customerId变了（从空值变为有值 或者 id变动） 或者 之前加载优惠券失败过
            if (((res?.customer?.id !== this.customerId) || this.isEmitedNewCouponFaild) && res?.customer?.id) {
                this.customerId = res.customer?.id;
                this.isEmitedNewCouponFaild = false;
                this.supportEcommerceFunc = this.siteContextService.enabledEcommerce;
                if (this.supportEcommerceFunc && this.newCoupon) {
                    if (res?.hasRequestedCustomer && !res?.requestedCustomerFailed) {
                        this.newCoupon.initialize();
                    }
                }
            }
            this.cdr.markForCheck();
        }, error => {
            console.log('Error on getting user info：', error);
        });
    }

    private routerActiveWatch() {
        // 过滤 取消导航事件，避免loading无法隐藏；增加节流避免来回闪烁
        this.router.events.pipe(filter(event => {
            return !(event instanceof Scroll);
        }))
            .pipe(filter(() => {
                try {
                    let state = this.router.getCurrentNavigation()?.extras?.state;
                    return !Object.keys(state)?.includes('no-loading') && !Object.values(state)?.includes('no-loading');
                } catch (error) {
                    return true;
                }
            })).pipe(tap((event) => {
                if ((event instanceof NavigationError) && event?.error?.message?.includes('Cannot match any routes')) {
                    this.router.navigate(['/404'], {
                        replaceUrl: true
                    });
                }
                if (!((event instanceof NavigationEnd) || (event instanceof NavigationCancel))) {
                    if (this.currentDeviceService.isMobile()) {
                        new Promise((resolve) => {
                            resolve('success');
                        }).then(() => this.toastService.load(this.translationService.instant('GENERAL.LOADING', {}, '加载中') + '...', 3000));
                    }
                }
            })).pipe(filter(event => {
                return (event instanceof NavigationEnd) ||
                    (event instanceof NavigationCancel);
            })).subscribe(() => {
                this.hiderightbar = false;
                if (this.currentDeviceService.isMobile()) {
                    new Promise((resolve) => {
                        resolve('success');
                    }).then(() => this.toastService.hide());
                }
                // this.cdr.detectChanges();
                this.cdr.markForCheck();
            });
    }

    checkCurrentRouterIsActive(routerUrl: string): boolean {
        return (!['', '/', '/home']?.includes(routerUrl)) ? this.router.isActive(routerUrl, false) : ['', '/', '/home']?.includes(this.router.url?.split(/\?/)?.[0]);
    }

    toggleRightbar() {
        this.hiderightbar = !this.hiderightbar;
    }

    loginOrBindAfterWechatAuthorization() {
        const returnUrl = this.weChatLoginService.handleWeChatActionReturn();
        const sub = this.weChatLoginService.actionMessage$.subscribe(result => {
            if (result.type === 'loginInSucess') {
            }
            if (result.type === 'bindWxSucess') {
                this.toastService.success(this.translationService.instant('GENERAL.WECHAT_BINDING_IS_SUCCESSFUL', {}, '微信绑定成功！'), 1500);
            }
            if (result.type === 'bindWxFailed') {
                this.toastService.fail(this.translationService.instant('GENERAL.WECHAT_BINDING_FAILED', {}, '微信绑定失败') + `：${result.msg}。`, 1500);
            }
            if (returnUrl) { this.router.navigate([returnUrl]); }
        });
        this.subs.push(sub);
    }

    scrollToTop(position: number) {
        // 使用requestAnimationFrame，如果没有则使用setTimeOut
        if (!window.requestAnimationFrame) {
            window.requestAnimationFrame = function (callback) {
                return setTimeout(callback, 20)
            } as any;
        }
        // 获取当前元素滚动的距离
        let scrollTopDistance = this._document.documentElement.scrollTop || this._document.body.scrollTop;
        function smoothScroll() {
            // 如果你要滚到顶部，那么position传过来的就是0，下面这个distance肯定就是负值。
            let distance = position - scrollTopDistance;
            // 每次滚动的距离要不一样，制造一个缓冲效果
            scrollTopDistance = scrollTopDistance + distance / 5;
            // 判断条件
            if (Math.abs(distance) < 1) {
                window.scrollTo(0, position);
            } else {
                window.scrollTo(0, scrollTopDistance);
                requestAnimationFrame(smoothScroll);
            }
        }
        smoothScroll();
    }

    selectedLang = getSelectedLang;

    setLang(langKey: string) {
        setLang(langKey, this.translationService);
    };

    navigateCustomerPage(data) {
        this.parseTargetEntityConfigService.navigateLinkedPage(data);
    }

    trackByCustomizeItem = (_: number, item: any) => JSON.stringify(item);

    ngOnDestroy(): void {
        this.cdr.detach();
        this.subs.forEach(sub => sub.unsubscribe());
    }
}
