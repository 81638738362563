import { Component, Input } from '@angular/core';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../core/services/parse-target-entity-config.service';
import { CardSpacingType, CardStyle, ContentStyleType, FeatureListItem, FeatureSetting, ImageSizeType } from './feature-list.model';
import { tranformGradientColorStyle } from '../../core/model/color-config.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';

@Component({
    selector: 'app-feature-list-block',
    templateUrl: './feature-list.component.html',
    styleUrls: ['./feature-list.component.scss']
})
export class FeatureListBlockComponent {
    @Input() blockId: string = '';
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() data: FeatureSetting = {};
    @Input() renderOptions: any = {};
    @Input() section: string;
    CardStyle = CardStyle;
    ImageSizeType = ImageSizeType;
    CardSpacingType = CardSpacingType;
    ContentStyleType = ContentStyleType;
    constructor(
        public currentDeviceService: CurrentDeviceService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
    ) { }

    navigateToSomePage(item: FeatureListItem) {
        this.parseTargetEntityConfigService.navigateLinkedPage(item);
    }

    getElStyle(item: any, propertyPath: string) {
        let options = item?.[propertyPath];
        let colorData = ((options?.color !== "CUSTOMIZE") && options?.color) ? options?.color : options?.customizeColorValue;
        try {
            colorData = JSON.parse(colorData);
        } catch (error) {
            colorData = colorData?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(colorData);
        return `letter-spacing: ${options?.['letter-spacing'] || 0}px;line-height: ${options?.['line-height'] || 1.5};
        ${gradientColor ? `background-image: ${colorData};color: ${jsonParse(colorData)?.data?.[0]?.color};background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;`
                : `color:${colorData};`}`;
    }

    getElFontSizeScale(item: any, propertyPath: string) {
        let options = item?.[propertyPath];
        return options?.['font-size'];
    }

    toNumber(value: string) {
        return Number(value);
    }
}
