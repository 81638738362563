import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, forkJoin, lastValueFrom } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { LayoutService } from './layouts.service';
import { SiteSecuritySettingService } from './site-security-settings.service';
import { ICustomerDTO } from '../../core/model/customer-dto.model';
import { defaultSiteLayout, Layout } from '../model/layouts.model';
import { Menu } from '../model/menu.model';
import { SiteSecuritySetting, defaultSiteSecuritySetting } from '../model/site-security-settings.model';
import { Site } from '../model/site.model';
import { SiteGraph } from '../model/site-graph.model';
import { SiteGraphService } from './site-graph.service';
import { normalizePageBlocks } from './page.service';
import { OwnerAppsService } from '../../core/services/owner-apps.service';

export class SiteId {
    siteId?: string;
    host?: string;
}
export class SiteSetting {
    siteId?: string;
    shopId?: string;
}

export const tokenStorageKey = 'bricsSiteToken';

@Injectable({
    providedIn: 'root',
})
export class SiteContextService {
    private _siteGraph$: BehaviorSubject<SiteGraph> = new BehaviorSubject<SiteGraph>(null);
    private _layout$: BehaviorSubject<Layout> = new BehaviorSubject<Layout>(null);
    private _siteSecuritySetting$: BehaviorSubject<SiteSecuritySetting> = new BehaviorSubject<SiteSecuritySetting>(null);
    private _enabledEcommerce$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _isLoadingSiteSecuritySetting: boolean = false;
    private _isLoadingLayout: boolean = false;

    constructor(
        private siteGraphService: SiteGraphService,
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private layoutService: LayoutService,
        private siteSecuritySettingService: SiteSecuritySettingService,
        private ownerAppsService: OwnerAppsService,
    ) { }

    async initialize(): Promise<[SiteGraph, boolean]> {
        return lastValueFrom(forkJoin([
            this.loadSiteGraph().pipe(tap(() => this.loadSiteSecuritySetting())),
            this.getIfEnabledEcommerce()
        ]))
    }

    get siteGraph(): SiteGraph {
        return this._siteGraph$?.getValue();
    }

    loadSiteGraph(url?: String): Observable<SiteGraph> {
        this._isLoadingLayout = true;
        return this.siteGraphService.getSiteGraph({url: url ? url : ''})
        .pipe(tap(() => {
            this._isLoadingLayout = false;
        }))
        .pipe(map(res => res?.body),
            catchError(err => {
                console.log('siteGraph 报错：', err);
                let siteGraph = new SiteGraph();
                siteGraph.site = new Site();
                siteGraph.menus = [new Menu()];
                siteGraph.layouts = [new Layout()]
                siteGraph.errorMock = true;
                return of(siteGraph);
            })
        )
        .pipe(tap(siteGraph => {
            if (siteGraph.menus?.length) {
                siteGraph.menus = siteGraph.menus.map(menu => this.getNormalizedMenu(menu));
            }
            if (siteGraph?.layouts?.length) {
                siteGraph.layouts.forEach(layout => {
                    if (!layout?.blocks?.length) {
                        layout.blocks = new Layout(defaultSiteLayout as unknown as Layout)?.blocks;
                    }
                    normalizePageBlocks(layout);
                });
            }
            if(siteGraph !== this._siteGraph$.value) {
                this._siteGraph$.next(siteGraph);
            }
            if (siteGraph.layouts?.length && siteGraph.layouts[0] !== this._layout$.value) {
                this._layout$.next(siteGraph?.layouts[0]);
            }
        }));
    }

    get site(): Site {
        if (this._siteGraph$.getValue()) {
            return this._siteGraph$.getValue()?.site;
        } else {
            return null;
        }
    }

    get siteId(): string {
        if (this._siteGraph$.getValue()) {
            return this._siteGraph$.getValue()?.site?.id;
        } else {
            return null;
        }
    }

    get siteSecuritySetting(): SiteSecuritySetting {
        if (this._siteSecuritySetting$.value) {
            return this._siteSecuritySetting$.value;
        } else {
            return null;
        }
    }

    /**
     * Get the first menu as the default menu of the site.
     * @returns the first menu of the site.
     */
    get defaultSiteMenu(): Menu {

        if (this._siteGraph$.getValue()) {
            return this._siteGraph$.getValue()?.menus[0];
        } else {
            return null;
        }
    }

    /**
     * Get the first layout as the default layout of the site.
     * @returns the first layout of the site.
     */
    get defaultLayout(): Layout {
        if (this._siteGraph$.getValue()) {
            return this._siteGraph$.getValue()?.layouts[0];
        } else {
            return null;
        }
    }

    get enabledEcommerce(): boolean {
        return this._enabledEcommerce$.value;
    }

    private getIfEnabledEcommerce() {
        return this.ownerAppsService.query({'name.equals': 'ecommerce', 'enabled.equals': true, 'visible.equals': true})
        .pipe(map(res => !!res?.length))
        .pipe(map((enabled) => {
            if (enabled !== this._enabledEcommerce$.value) {
                this._enabledEcommerce$.next(enabled);
            }
            return enabled;
        }));
    }

    private getNormalizedMenu(menu: Menu): Menu {
        if (menu.items?.length) {
            menu.items = menu.items.sort((menuItem, otherMenuItem) => (menuItem.seqNum + menuItem.seqNum) -
                (otherMenuItem.seqNum + otherMenuItem.seqNum));
        }
        menu = this.getMenuTree(menu);
        return menu;
    }

    loadLayout(): Observable<Layout> {
        if (!this._isLoadingLayout && !this._layout$.value?.id) {
            this.getLayout().subscribe(layout => {
                if (layout && (JSON.stringify(layout) !== JSON.stringify(this._layout$.value))) {
                    this._layout$.next(layout);
                }
            });
        }
        return this._layout$;
    };

    private getLayout(): Observable<Layout> {
        const siteId = this.siteId;
        if (siteId) {
            this._isLoadingLayout = true;
            return this.layoutService.query({ 'siteId.equals': siteId })
            .pipe(tap(() => this._isLoadingLayout = false))
            .pipe(mergeMap(layoutRes => {
                const matchLayouts = layoutRes?.body?.filter(layout => layout.siteId === siteId);
                if (!matchLayouts?.length) {
                    return of(null);
                }
                if (matchLayouts?.length === 1) {
                    const findLayout = matchLayouts[0];
                    if (findLayout?.blocks?.length) {
                        return of(findLayout);
                    } else {
                        return this.layoutService.find(findLayout?.id).pipe(map(res => res.body));
                    }
                } else {
                    return of(matchLayouts[0]);
                }
            }));
        } else {
            return of(new Layout());
        }
    }

    loadSiteSecuritySetting(): Observable<SiteSecuritySetting> {
        if (!this._siteSecuritySetting$.value && !this._isLoadingSiteSecuritySetting) {
            this._isLoadingSiteSecuritySetting = true;
            this.getSiteSecuritySetting().subscribe(siteSecuritySetting => {
                if ((siteSecuritySetting && (JSON.stringify(siteSecuritySetting) !== JSON.stringify(this._siteSecuritySetting$.value)))) {
                    this._siteSecuritySetting$.next(siteSecuritySetting);
                }
                this._isLoadingSiteSecuritySetting = false;
            }, err => {
                this._isLoadingSiteSecuritySetting = false;
                this._siteSecuritySetting$.next(null);
            });
        }
        return this._siteSecuritySetting$;
    };

    private getSiteSecuritySetting(): Observable<SiteSecuritySetting> {
        const siteId = this.siteId;
        if (siteId) {
            return this.siteSecuritySettingService.find(siteId).pipe(map(siteSecuritySettingRes => {
                return siteSecuritySettingRes?.body || new SiteSecuritySetting(defaultSiteSecuritySetting);
            }));
        }
        return of(new SiteSecuritySetting(defaultSiteSecuritySetting));
    }

    customer(): ICustomerDTO {
        return this.localStorage.retrieve('customer') || this.sessionStorage.retrieve('customer');
    }

    setCustomer(customer?: ICustomerDTO) {
        if (customer) {
            this.localStorage.store('customer', customer);
            this.sessionStorage.store('customer', customer);
        } else {
            this.localStorage.clear('customer');
            this.sessionStorage.clear('customer');
        }
    }

    token(): string {
        return this.localStorage.retrieve(tokenStorageKey) || this.sessionStorage.retrieve(tokenStorageKey);
    }

    loginIsRememberMe() {
        return !!this.localStorage.retrieve(tokenStorageKey);
    }

    setToken(token: string, rememberMe: boolean) {
        if (token) {
            if (rememberMe) {
                this.localStorage.store(tokenStorageKey, token);
                // this.localStorage.store('authenticationToken', token);
            } else {
                this.sessionStorage.store(tokenStorageKey, token);
                // this.sessionStorage.store('authenticationToken', token);
            }
        } else {
            this.localStorage.clear(tokenStorageKey);
            this.sessionStorage.clear(tokenStorageKey);
        }
    }

    loginOut() {
        // 清除token
        this.setToken('', true);
        // 查找到的相关用户信息需要清除，后续需要改造相关写法，缓存的东西有点多了
        this.localStorage.clear('userinfor');
        this.localStorage.clear('userid');
        this.localStorage.clear('customerid');
        this.localStorage.clear('name');
        this.localStorage.clear('nickname');
        this.localStorage.clear('mobilephone');
        this.sessionStorage.clear('previousUrl');
        this.localStorage.clear('productorder');
        this.sessionStorage.clear('productId');
        this.localStorage.clear('order');
        this.localStorage.clear('refundInfor');
        this.localStorage.clear('userAddress');
        this.localStorage.clear('selfPickupAddress');
        this.sessionStorage.clear('previousState');
        this.setCustomer();
    }

    retrieveBlockStorageData(blockId: string) {
        return this.localStorage.retrieve('site_' + this.siteId + '_block_' + blockId);
    }

    setBlockStorageData(blockId: string, data: any) {
        this.localStorage.store('site_' + this.siteId + '_block_' + blockId, JSON.stringify(data));
    }

    private getMenuTree(siteMenu: Menu) {
        let siteMenuItems = [];
        siteMenu.items?.forEach(menu => {
            if (menu?.parent) {
                let parentMenuItem = siteMenu.items?.find(menuItem => {
                    return menu?.parent?.id === menuItem?.id;
                });
                if (parentMenuItem?.children?.length) {
                    parentMenuItem?.children?.push(menu);
                } else {
                    parentMenuItem.children = [menu];
                }
            } else {
                siteMenuItems.push(menu);
            }
        });
        siteMenuItems?.forEach(siteMenuItem => {
            if (siteMenuItem?.children?.length) {
                siteMenuItem.children = siteMenuItem?.children?.sort((menuItem, otherMenuItem) => menuItem.seqNum - otherMenuItem.seqNum);
            }
        });
        siteMenu.items = siteMenuItems?.sort((menu, otherMenu) => menu.seqNum - otherMenu.seqNum)?.filter(menu => menu.enabled);
        return siteMenu;
    }
}

