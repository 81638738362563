// import CryptoJS from 'crypto-js';

// const key = CryptoJS.enc.Utf8.parse("rFu3eE0oGFr8uy2t");  //十六位十六进制数作为密钥
// const iv = CryptoJS.enc.Utf8.parse("0D34wYgJXZGypYzl");   //十六位十六进制数作为密钥偏移量

//AES解密方法
// function decryptByAes(word: string | Object) {
//     if (word instanceof Object) word = JSON.stringify(word);
//     let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
//     let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
//     let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
//     let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
//     return decryptedStr.toString();
// }

//AES加密方法
// function encryptByAes(word: string): string | Object {
//     let srcs = CryptoJS.enc.Utf8.parse(word);
//     let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).ciphertext.toString().toUpperCase();
//     try {
//         return JSON.parse(encrypted);
//     } catch (error) {
//         return encrypted;
//     }
// }

// MD5加密
// function encryptByMd5(word: string | Object): string {
//     if (word instanceof Object) word = JSON.stringify(word);
//     return CryptoJS.MD5(word).toString();
// }

// window.btoa() 不支持中文, window.atob() 转换含有中文的 base64编码 的时候中文部分会变为乱码。
// base64编码
function decryptByBase64(str: string): string {
    return window.btoa(unescape(encodeURIComponent(str)));
}

// base64解码
function ecryptByBase64(str: string): string {
    return decodeURIComponent(escape(window.atob(str)));
}

export default {
    // decryptByAes,
    // encryptByAes,
    decryptByBase64,
    ecryptByBase64,
    // encryptByMd5
}
