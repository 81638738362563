import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../core/services/parse-target-entity-config.service';
import { IDiyContentItem } from '../../core/model/mobile-config.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';

@Component({
    selector: 'app-text-links',
    templateUrl: './text-links.component.html',
    styleUrls: ['./text-links.component.scss']
})
export class TextLinksComponent {
    @Input() blockId: string = '';
    @Input() data = [];
    @Input() section: string;
    @Input() set renderOptions(value: any) {
        if (value) {
            const tempValue = value?.container?.background || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._renderOptions = {
                ...value,
                container: {
                    ...value?.container,
                    'background-color': backgroundColor
                }
            };
        }
    }
    _renderOptions: any = {};
    constructor(
        private router: Router,
        public currentDeviceService: CurrentDeviceService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
    ) { }
    navigateLink(linkItem) {
        this.navigateToSomePageByDiyContentItem(linkItem);
    }
    navigateToSomePageByDiyContentItem(diyContentItemChild: IDiyContentItem) {
        this.parseTargetEntityConfigService.navigateLinkedPage(diyContentItemChild);
    }
}
