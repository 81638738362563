import { Dayjs } from 'dayjs/esm';
import { SiteTypeEnum } from './site-type.enum';

export class Site {
    constructor(site?: Site) {
        if (site) {
            for (const key in site) {
                if (key) {
                    this[key] = site[key];
                }
            }
        }
    }
    id?: string;
    name: string;
    nameTranslation?: string;
    ownerName?: string;
    ownerNameTranslation?: string;
    desc?: string;
    descTranslation?: string;
    siteAppType: SiteTypeEnum;
    iconUrl?: string;
    screenShotUrl?: string;
    defaultLanguage?: string;
    supportedLanguages: string;
    renderOptions?: string;
    featureOptions?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    new?: boolean;
}

export const defaultFeatureOptions = {
    "productComment": {
        enable: false
    },
    "shoppingCart": {
        enable: false
    },
    "address": {
        enable: false
    },
    "myFavorite": {
        enable: false
    },
    "myInvoice": {
        enable: false
    }
}

export const defaultSite = {
    name: 'Brics site',
    siteAppType: SiteTypeEnum.BUILTIN_COMBO,
    defaultLanguage: 'zh-CN',
    supportedLanguages: '["zh-CN","zh-TW","ja","en-US"]',
    featureOptions: JSON.stringify(defaultFeatureOptions)
}
