export const sitesTranslate = {
    ERROR: {
        CREATE_SITE_ERROR: '创建站点应用出错：',
        COMPANY_INFO_ERROR: '获取公司信息出错：',
        GET_SITE_ERROR: '获取站点应用出错：',
        UPDATE_SITE_ERROR: '更新站点应用出错：',
        NOT_FOUND_SITE: '当前页面找不到对应的站点，请检查后重新进入',
        GET_APP_ERROR: '获取应用出错：',
        LOAD_PAGE_EMPTY: '该页面还在装修中，请稍后再试吧',
        LOAD_SITE_ERROR: '网站目前暂不可用，请联系系统管理员 或 稍后再试吧',
    },
    GENERAL: {
        CURRENT_APPLICATION: '当前应用',
        NOTYETOPEN_CANNOTBEUSED: '暂未开通，无法使用！'
    }
}