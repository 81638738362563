export enum DirectionType {
    // 垂直
    COLUMN = 'COLUMN',
    // 水平
    ROW = 'ROW',
}

export class MailSubscribeSetting {
    // 布局：垂直、水平
    direction?: DirectionType;
    items?: CustomizeContext[];
}

// 图片、富文本（富文本编辑）、输入控件（输入框 提示信息、按钮、按钮点击跳转至）
export enum CustomizeContextType {
    // 图片
    IMAGE = 'IMAGE',
    // 富文本
    RICHTEXT = 'RICHTEXT',
    // 输入控件
    INPUT_CONTROL = 'INPUT_CONTROL',
}

export enum HorizontalPositionType {
    // 左侧
    LEFT = 'LEFT',
    // 右侧
    RIGHT = 'RIGHT',
}

export class CustomizeContext {
    type?: CustomizeContextType;
    // 输入控件的话应该是JSON化的字符串，富文本的话应该也是
    data?: string;
    // 如果是水平方向的话，标识属于左侧还是右侧的
    horizontalPosition?: HorizontalPositionType;
    sort?: number;
}

export enum CustomizeFormControlType {
    EMAIL = 'EMAIL',
    TEL = 'TEL',
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    BUTTON = 'BUTTON',
}