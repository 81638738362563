import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { CarouselsService } from '../../core/services/carousels.service';
import { ICarouselItem } from '../../core/model/carousel-item.model';
import { ICarousel } from '../../core/model/carousel.model';
import { NavigationLayout } from '../../shared/model/navigation-layout.enum';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { SiteContextService } from '../../shared/services/site-context.service';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { firstValueFrom } from 'rxjs';

export class Carousel {
    img: string;
    target?: string;
    url?: string;
    id?: string;
    title?: string;
    targetEntityType?: string;
    targetEntityConfig?: string;
}
export class CarouselConfig {
    duration?: number;
    rounded?: boolean;
    fullWidth?: boolean;
    suggestedHeight: number;
    suggestedWidth: number;
}

@Component({
    selector: 'app-carousel-block',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselBlockComponent implements OnInit {
    @Input() renderOptions: any = {};
    @Input() set key(value: string) {
        this._key = value;
        const carousel = this.$localStorage.retrieve('carousel_block_' + value);
        if (carousel && carousel?.items) {
            this.parseCarousels(carousel, false);
        } else if (carousel && carousel?.carouselItemDTOS) {
            this.parseCarousels(carousel, false);
        }
    };
    @Input() defaultData: any[];
    @Input() set data(value: any) {
        if (value && jsonParse(value)?.[this.currentDeviceService?.isDesktop() ? 'DESKTOP' : 'MOBILE']) {
            this.parseCarousels(jsonParse(value)?.[this.currentDeviceService?.isDesktop() ? 'DESKTOP' : 'MOBILE'], false);
        } else {
            if (this._key) {
                // 获取轮播图
                this.getCarouselsInfo(this._key);
            }
        }
    };
    _key = '';
    carouselConfig: CarouselConfig = {
        duration: 5000,
        rounded: true,
        fullWidth: true,
        suggestedHeight: 640,
        suggestedWidth: 1920,
    };
    carousels: ICarouselItem[] = [];
    asideWidth = '0px';
    layoutRenderOptions = {
        desktopNavigation: NavigationLayout.TOP_BAR,
        mobileNavigation: NavigationLayout.BOTTOM_TAB_BAR
    };
    constructor(
        private siteContextService: SiteContextService,
        private carouselsService: CarouselsService,
        private $localStorage: LocalStorageService,
        private currentDeviceService: CurrentDeviceService,
    ) { }

    ngOnInit(): void {
        const layout = this.siteContextService.defaultLayout;
        this.layoutRenderOptions = jsonParse(layout.renderOptions) || {
            desktopNavigation: NavigationLayout.TOP_BAR,
            mobileNavigation: NavigationLayout.BOTTOM_TAB_BAR
        };
        if (![NavigationLayout.TOP_BAR, undefined, '', null]?.includes(this.layoutRenderOptions.desktopNavigation) && (window.innerWidth >= 1201)) {
            this.asideWidth = '200px';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.defaultData) {
            this.defaultData = this.defaultData?.map(defaultCarouselItem => {
                return { img: defaultCarouselItem?.itemUrl };
            });
            if (!this.carousels?.length) {
                this.carousels = this.defaultData || [];
            }
        }
    }

    // 轮播图
    getCarouselsInfo(key: string) {
        firstValueFrom(this.carouselsService.get(key))
            .then((carousel: ICarousel) => {
                if (carousel && carousel.id) {
                    this.parseCarousels(carousel, true);
                }
            },
            (error) => {
                console.log('Failed to retrieve carousel');
            });
    }

    parseCarousels(carousel: any, backToLocalStorage?: boolean) {
        const tempCarousel = [];
        if (carousel?.items?.length) {
            for (let k = 0; k < carousel.items['length']; k++) {
                tempCarousel.push(carousel.items[k]);
            }
        } else if (carousel.carouselItemDTOS?.length) {
            for (let k = 0; k < carousel.carouselItemDTOS['length']; k++) {
                tempCarousel.push(carousel.carouselItemDTOS[k]);
            }
        }
        tempCarousel?.sort((a, b) => a.sequence - b.sequence);
        this.carousels = tempCarousel?.length ? tempCarousel : (this.defaultData || []);
        this.carouselConfig = {
            duration: Number.isInteger(carousel.duration) ? carousel.duration * 1000 : 5000,
            rounded: [undefined, null]?.includes(carousel.rounded) ? true : carousel.rounded,
            fullWidth: [undefined, null]?.includes(carousel.fullWidth) ? true : carousel.fullWidth,
            suggestedHeight: carousel.suggestedHeight || 640,
            suggestedWidth: carousel.suggestedWidth || 1920,
        };
        if (backToLocalStorage) {
            this.$localStorage.store('carousel_block_' + this._key, carousel);
        }
    }
}
