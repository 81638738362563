import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { Dayjs } from 'dayjs/esm';

export class EmailSubscriber {
    /**
     * Id of the item, short random alphanumeric String
     */
    id?: string;
    email?: string = '';
    /**
     * The email list this subscriber is subscribed to
     */
    emailListId?: string = '';
    firstName?: string;
    lastName?: string;
    userId?: string;
    customerId?: string;
    /**
     * The language of the subscriber
     */
    language?: string;
    subscribedDate?: Dayjs;
    constructor(item?: EmailSubscriber) {
        if (item) {
            for (const key in item) {
                if (key) {
                    this[key] = item[key];
                }
            }
        }
    }
}

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
type EntityResponseType = HttpResponse<EmailSubscriber>;
type EntityArrayResponseType = HttpResponse<EmailSubscriber[]>;
@Injectable({ providedIn: 'root' })
export class EmailSubscriberService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/email-subscribers';

    constructor(
        private http: HttpClient,
    ) { }

    create(layouts: EmailSubscriber): Observable<EntityResponseType> {
        return this.http
            .post<EmailSubscriber>(this.resourceUrl, layouts, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(layouts: EmailSubscriber): Observable<EntityResponseType> {
        return this.http
            .put<EmailSubscriber>(`${this.resourceUrl}/${layouts.id}`, layouts,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http
            .get<EmailSubscriber>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityResponseType) => res));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        return this.http
            .get<EmailSubscriber[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
