import { Component, Input } from '@angular/core';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../core/services/parse-target-entity-config.service';
import { IDiyContentItem } from '../../core/model/mobile-config.model';

@Component({
    selector: 'app-icon-boxes-block',
    templateUrl: './icon-boxes.component.html',
    styleUrls: ['./icon-boxes.component.scss']
})
export class IconBoxesBlockComponent {
    @Input() blockId: string = '';
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() diyContents: any[] = [];
    @Input() renderOptions: any = {};
    @Input() section: string;
    constructor(
        public currentDeviceService: CurrentDeviceService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
    ) { }
    checkBlockTitleIsEmpty(blockLayoutListOfIndex: any[]) {
        return blockLayoutListOfIndex.every(blockLayoutItemOfIndex => !blockLayoutItemOfIndex?.title);
    }
    navigateToSomePageByDiyContentItem(diyContentItemChild: IDiyContentItem) {
        this.parseTargetEntityConfigService.navigateLinkedPage(diyContentItemChild);
    }
}
