import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appPureImgErrorIdentification]'
})
export class ImgErrorIdentificationDirective implements AfterViewInit {
    @Input() loadSuccessClassName = 'ng-img-loaded';
    @Input() loadFailedClassName = 'ng-img-failed';
    @Input() setErrorImg = false;
    @Input() loadFailedClassNameForErrorImage = 'ng-img-failed-and-set-error-img';
    @Input() loadFailedImage = '/assets/icon/img-blank.svg';
    @Output() loadImgEvent: EventEmitter<boolean> = new EventEmitter();
    constructor(private el: ElementRef, private rd2: Renderer2) {
    }
    ngAfterViewInit() {
        this.listenInit();
    }
    loadImageError() {
        this.loadImgEvent.emit(false);
        this.rd2.addClass(this.el?.nativeElement, this.loadFailedClassName);
        this.rd2.removeClass(this.el?.nativeElement, this.loadSuccessClassName);
        if (this.setErrorImg && (this.el?.nativeElement?.src?.replace(window.location.origin, '') !== this.loadFailedImage)) {
            this.rd2.setProperty(this.el?.nativeElement, 'src', this.loadFailedImage);
            this.rd2.addClass(this.el?.nativeElement, this.loadFailedClassNameForErrorImage);
        }
    }
    // 现代化浏览器
    @HostListener('load', ['$event']) loadForModernBrowser = () => {
        this.loadImageSuccess();
    }
    // IE6-IE10支持该事件，其它浏览器不支持。
    @HostListener('readystatechange', ['$event']) loadForIEBrowser = () => {
        this.loadImageSuccess();
    }
    // 监听ng-lazyload-image插件的状态变更事件
    @HostListener('onStateChange', ['$event']) listenStateForModernBrowser = (result) => {
        switch (result.reason) {
            case 'loading-succeeded':
                this.loadImageSuccess();
                break;
            case 'loading-failed':
                this.loadImageError();
                break;
            case 'finally':
                // The last event before cleaning up
                break;
        }
    }
    loadImageSuccess() {
        if (this.el?.nativeElement?.src.includes(this.loadFailedImage)) { return; }
        this.loadImgEvent.emit(true);
        this.rd2.addClass(this.el?.nativeElement, this.loadSuccessClassName);
        this.rd2.removeClass(this.el?.nativeElement, this.loadFailedClassName);
        if (this.setErrorImg) {
            this.rd2.removeClass(this.el?.nativeElement, this.loadFailedClassNameForErrorImage);
        }
    }
    listenInit() {
        // 如果有缓存直接成功的话
        if (this.el.nativeElement?.complete) { this.loadImageSuccess(); }
        // 如果加载失败的话
        this.rd2.listen(this.el?.nativeElement, 'error', this.loadImageError.bind(this));
    }
    @HostListener('error', ['$event']) loadErrorForModernBrowser = () => {
        this.loadImageError();
    }
}
