<app-block-container [blockId]="blockId" [enabledTitle]="false" [renderOptions]="_renderOptions"
    [enabledMoreBtn]="false" [section]="section">
    <div class="text-links-container">
        <div class="d-flex flex-wrap text-links-content">
            <div class="text-link" *ngFor="let link of data;" (click)="navigateLink(link)">
                <span>{{
                    {title:link?.title || link?.caption,titleTranslation: link?.titleTranslation ||
                    link?.titleTranslation }
                    | mTitleTranslate
                    }}</span>
                <i class="bi bi-caret-right-fill ml-2"></i>
            </div>
        </div>
    </div>
</app-block-container>