export const orderTranslate = {
    ERROR: {
        BINDING_FORM_FAILED: '绑定表单失败：',
        FAILED_TO_SUBMIT_ORDER: '提交订单失败：',
    },
    GENERAL: {
        DISCOUNT_TIP: '联盟折扣优惠、优惠券抵扣优惠不适用于运费',
        SUBMIT_ORDER: '提交订单',
        ORDER_ID: '订单编号',
        TOTAL_AMOUNT_OF_ORDERS: '订单总金额',
        AMOUNT_PAID: '已支付金额',
        AMOUNT_TO_BE_PAID: '待付金额',
        FOLLOW_THE_PAYMENT_INSTRUCTIONS_TO_PAID: '您已选择线下支付，请按照支付说明进行付款：',
        CONTACT_THE_STORE_TO_PAID: '您已选择线下支付，请联系店家进行付款。',
        PAYMENT_BY_OTHER_METHODS: '其他方式支付',
        OFFLINE_PAYMENTS: '线下支付',
        STRIPE_PAYMENTS: 'Stripe 支付',
        PAY_FOR_THE_ORDER: '订单支付',
        CONFIRM_PAYMENT: '确认支付',
        CONFIRM_THE_PAYMENT_INFORMATION: '请确认以下支付信息',
        PAYMENT_AMOUNT: '支付金额',
        WECHAT_PAY: '微信支付',
        PAY_BY_ALIPAY: '支付宝',
        PAY_BY_PAYPAY: 'PayPay',
        PAYMENT_METHOD: '支付方式',
        WECHAT_TO_SCAN_THE_CODE_TO_PAY: '请使用微信扫一扫进行扫码支付',
        PAYMENT_RESULT_CONFIRMATION: '支付结果确认',
        PLEASE_MAKE_PAYMENT_ON_THE_NEWLY_OPENED_PAGE: '请在新打开的页面进行支付',
        AFTER_PAYMENT_CONFIRMATION: '支付完成后，根据您的情况点击下面按钮。',
        PAYMENT_COMPLETED: '支付完成',
        ORDER_PAYMENT_COMPLETED: '订单支付完成',
        PAYMENT_COMPLETED_TIMEOUT_TIP: '{{value}} 秒后自动跳转到订单列表页。',
        PAYMENT_NOT_COMPLETED: '支付未完成',
        SELECT_PAYMENT_METHOD: '选择支付方式',
        GIFT_CARD_PAYMENT: '礼品卡支付',
        CURRENTLY_UNAVAILABLE_BALANCE: '暂无可使用的余额',
        RECHARGE: '充值',
        CANNOT_BE_PAID_WITH_A_GIFT_CARD: '订单中包含礼品卡商品，无法使用礼品卡支付哦',
        BALANCE: '余额',
        USE: '使用',
        DEDUCT: '抵扣',
        PAY: '支付',
        PAY_AMOUNT: '支付金额',
        NO_PAYMENT_METHOD_AVAILABLE: '没有可使用的支付方式',
        NO_PAYMENT_METHOD_AVAILABLE_TIPS: '无可用支付方式，请刷新再试或者联系管理员！',
        BUY_AND_PAY: '购买-支付',
        PAYMENT_ORDER_FAILED: '支付下单失败',
        PAYMENT_TIMED_OUT: '支付超时，请重新进行支付',
        PAYMENT_CANCELLED: '支付已取消',
        Available_Balance: '可用余额',
        Unavailable_Balance: '不可用余额',
        Gift_Card_Istructions: '注：仅能使用当前订单同种货币的礼品卡余额',
    }
};
