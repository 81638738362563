export const orderTranslate = {
    ERROR: {
        BINDING_FORM_FAILED: 'Bind form failed:',
        FAILED_TO_SUBMIT_ORDER: 'Failed to submit order:',
    },
    GENERAL: {
        DISCOUNT_TIP: 'Affiliate discount offers, coupon credit offers do not apply to shipping costs.',
        SUBMIT_ORDER: 'Submit order',
        ORDER_ID: 'Order id',
        TOTAL_AMOUNT_OF_ORDERS: 'Total amount',
        AMOUNT_PAID: 'Charged amount',
        AMOUNT_TO_BE_PAID: 'Uncharged amount',
        FOLLOW_THE_PAYMENT_INSTRUCTIONS_TO_PAID: "You've chosen to pay offline, please follow the payment instructions to make the payment:",
        CONTACT_THE_STORE_TO_PAID: 'You have selected offline payment, please contact your store to make the payment.',
        PAYMENT_BY_OTHER_METHODS: 'Other payment methods',
        OFFLINE_PAYMENTS: 'Offline payments',
        STRIPE_PAYMENTS: 'Stripe payments',
        PAY_FOR_THE_ORDER: 'Order payment',
        CONFIRM_PAYMENT: 'Confirm payment',
        CONFIRM_THE_PAYMENT_INFORMATION: 'Please confirm the following payment information',
        PAYMENT_AMOUNT: 'Payment amount',
        WECHAT_PAY: 'WeChat',
        PAY_BY_ALIPAY: 'Alipay',
        PAY_BY_PAYPAY: 'PayPay',
        PAYMENT_METHOD: 'Payment Methods',
        WECHAT_TO_SCAN_THE_CODE_TO_PAY: 'Please use WeChat scan to scan the code to pay',
        PAYMENT_RESULT_CONFIRMATION: 'Confirmation of payment result',
        PLEASE_MAKE_PAYMENT_ON_THE_NEWLY_OPENED_PAGE: 'Please make a payment on the newly opened page',
        AFTER_PAYMENT_CONFIRMATION: 'Once the payment is complete, click on the button below according to your situation.',
        PAYMENT_COMPLETED: 'Payment completed',
        ORDER_PAYMENT_COMPLETED: 'Order payment completed',
        PAYMENT_COMPLETED_TIMEOUT_TIP: 'After {{value}} seconds, you will be automatically redirected to the order list page..',
        PAYMENT_NOT_COMPLETED: 'Payment not completed',
        SELECT_PAYMENT_METHOD: 'Select a payment method',
        GIFT_CARD_PAYMENT: 'Gift card payments',
        CURRENTLY_UNAVAILABLE_BALANCE: 'No available balance',
        RECHARGE: 'Recharge',
        CANNOT_BE_PAID_WITH_A_GIFT_CARD: 'Gift card items are included in the order and cannot be paid for with a gift card',
        BALANCE: 'balance',
        USE: 'Use',
        DEDUCT: 'Deductions',
        PAY: 'Pay',
        PAY_AMOUNT: 'Amount',
        NO_PAYMENT_METHOD_AVAILABLE: 'There are no payment methods available',
        NO_PAYMENT_METHOD_AVAILABLE_TIPS: 'No payment method available, please refresh and try again or contact the administrator!',
        BUY_AND_PAY: 'Purchase pay',
        PAYMENT_ORDER_FAILED: 'Payment failed to place an order',
        PAYMENT_TIMED_OUT: 'The payment timed out, please make a new payment',
        PAYMENT_CANCELLED: 'The payment has been canceled',
        Available_Balance: 'Available balance',
        Unavailable_Balance: 'Unavailable balance',
        Gift_Card_Istructions: 'Note: Only gift card balances in the same currency as the current order can be used',
    }
};
