import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../i18n';
import { Menu, LinkType, MenuItem } from '../../shared/model/menu.model';
import { TargetEntityTypeEnum, TargetTypeEnum } from '../../core/model/mobile-config.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { languageConfig, getSelectedLang, setLang } from '../../shared/pipes/selected-language-helper.pipe';
import { PostConfig } from '../../builtin-pages/post-list/_model/post.model';
import { getMenuNativePath, getSpecialPagePath } from '../../core/utilities/page-route-util';

@Component({
    selector: 'app-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    @Input() siteLogo = '';
    @Input() menu = new Menu();
    @Input() maskEnable = false;
    @Output() closeAside = new EventEmitter<boolean>();
    @Input() isMobile = false;
    @Input() isRight = false;
    @Input() isLogin = false;
    @Input() userName = '';
    @Input() userAvatarUrl = '';
    @Input() accountLoginBtnType = '';
    @Input() renderOptions: any;
    expendedMenuPanel: any = {};
    countries: {
        langKey: string;
        nameKey: string;
    }[] = [];
    selectedLang = getSelectedLang;

    constructor(
        private router: Router,
        public translationService: TranslationService,
    ) { }

    ngOnInit(): void {
        const langs = this.translationService.getSiteLanguages();
        this.countries = languageConfig?.filter(lang => {
            return langs?.supportedLanguages?.find(languageItem => lang?.langKey?.toLowerCase() === languageItem?.toLowerCase());
        });
    }

    setLang(langKey: string) {
        setLang(langKey, this.translationService);
    };

    navigateMenuPage(item: MenuItem) {
        let path = this.getMenuNativePath(item);
        if (![undefined, null]?.includes(path)) {
            let queryParams: any = {};
            if (item.linkType === LinkType.URL) {
                if (jsonParse(item?.linkOptions)?.targetType === 'NEW_WINDOW') {
                    window.open(item.linkedUrl);
                } else {
                    location.href = item.linkedUrl;
                }
            }
            if (jsonParse(item?.linkedPageParams)?.special) {
                queryParams = this.getSpecialPageQueryParams(jsonParse(item?.linkedPageParams));
            }
            if (jsonParse(item?.linkedPageParams)?.targetEntityType === TargetEntityTypeEnum.POST_CATEGORY) {
                if (!queryParams) {
                    queryParams = {};
                }
                queryParams.categoryId = jsonParse(item?.linkedPageParams)?.targetEntityId || jsonParse(item?.linkedPageParams)?.paramsId;
            }
            this.router.navigate([path], {
                queryParams: {
                    ...queryParams,
                    enableHeaderBackBtn: false,
                }
            });
            return true;
        } else {
            if (item?.children?.length) {
                this.navigateMenuPage(item?.children?.[0]);
                return true;
            }
            return false;
        }
    }

    checkCurrentRouterIsActive(item: MenuItem, routerUrl: string): boolean {
        if (!item) {
            return false;
        }
        if (routerUrl === undefined) {
            if (item?.children?.length) {
                return item?.children?.some(menuItem => {
                    if (this.getMenuNativePath(menuItem)) {
                        let result = this.checkCurrentRouterIsActive(item, this.getMenuNativePath(menuItem));
                        if (result) {
                            return result;
                        }
                    }
                    return menuItem?.children?.some(menuChildItem => this.checkCurrentRouterIsActive(item, this.getMenuNativePath(menuChildItem)));
                });
            }
            return false;
        }
        return (!['', '/', '/home']?.includes(routerUrl)) ? this.router.isActive(routerUrl, false) : ['', '/', '/home']?.includes(this.router.url?.split(/\?/)?.[0]);
    }

    checkMenuIsActive(item: MenuItem): boolean {
        let isActive = this.checkCurrentRouterIsActive(item, this.getMenuNativePath(item));
        if (isActive) {
            return isActive;
        }
        if (item?.children?.length) {
            return item?.children?.some(menuItem => {
                let result = this.checkCurrentRouterIsActive(menuItem, this.getMenuNativePath(menuItem));
                if (result) {
                    return result;
                }
                return menuItem?.children?.some(menuChildItem => this.checkCurrentRouterIsActive(menuChildItem, this.getMenuNativePath(menuChildItem)));
            });
        }
        return false;
    }

    getMenuNativePath = getMenuNativePath;

    getSpecialPagePath = getSpecialPagePath;

    getSpecialPageQueryParams(linkParams: any) {
        if (linkParams.targetType === TargetTypeEnum.ENTITY) {
            if ([TargetEntityTypeEnum.POST_LIST, TargetEntityTypeEnum.POST_CATEGORY].includes(linkParams.targetEntityType)) {
                return {
                    ...(linkParams.postConfig || new PostConfig),
                    enableHeaderBackBtn: false,
                };
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.POST) {
                return {
                    enableHeaderBackBtn: false,
                    ...linkParams.postConfig
                };
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.POST_CATEGORY) {
                const params: any = linkParams.postConfig;
                params.categoryId = linkParams.targetEntityId;
                params.enableHeaderBackBtn = false;
                return params;
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.PRODUCT_CATEGORY) {
                return {
                    enableHeaderBackBtn: false,
                    categoryId: linkParams.targetEntityId
                };
            }
            if (linkParams.targetEntityType === TargetEntityTypeEnum.PRODUCT) {
                return {
                    enableHeaderBackBtn: false,
                    id: linkParams.targetEntityId
                };
            }
        }
    }

    clickMenuItem(item: MenuItem) {
        if (this.navigateMenuPage(item)) {
            if (this.isMobile) {
                this.closeAsideBar();
            }
        } else {
            if (item?.children) {
                this.changeMenuPanelExpendStatus(item?.id);
                return;
            }
        }
    }

    closeAsideBar() {
        this.closeAside.emit(false);
    }

    changeMenuPanelExpendStatus(id: string) {
        this.expendedMenuPanel[id] = (this.expendedMenuPanel[id] === undefined) ? true : !this.expendedMenuPanel[id];
    }
}
