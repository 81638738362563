export const invoiceCenterTranslate = {
    GENERAL: {
        ENABLE_INVOICE_ORDERS: 'Invoiced orders',
        INVOICED_ORDERS: 'Invoiced orders',
        NOT_INVOICE_ORDERS: 'Orders to be invoiced',
        INVOICE_HEADERS: 'Invoiced headers',
    },
    SHARED: {
        NOT_INVOICE: 'To be invoiced',
        TO_DETAIL: 'View details',
        TO_APPLY: 'Apply for invoicing',
        PENDING_TIPS: 'Applied for, pending review',
        PENDING: 'To be reviewed',
        DISABLED_INVOICE_TIPS: 'This order is not supported for invoicing',
    }
}