
export enum CardStyle {
    // 立体3D卡片
    '3D' = '3D',
    // 扁平卡片
    FLAT = 'FLAT',
    // 非卡片
    NONE = 'NONE',
    BORDER = 'BORDER',
}

export enum CardSpacingType {
    SMALL = 'SMALL',
    // MIDDLE = 'MIDDLE',
    BIG = 'BIG',
}

export enum MediaType {
    IMAGE = 'IMAGE',
    VECTOR_ICON = 'VECTOR_ICON',
    FONT_ICON = 'FONT_ICON',
}

export enum VerticalAlignType {
    HORIZONTAL = 'HORIZONTAL',
    STEP_UP = 'STEP_UP',
}

export class FeatureListItem {
    // eg: image、vector icons、font icon、video
    mediaType?: MediaType;
    mediaValue?: string;
    desc?: string;
    descTranslation?: string;
    button?: string;
    buttonTranslation?: string;
    sort?: number;
    title?: string;
    titleTranslation?: string;
    content?: string;
    contentTranslation?: string;
}

export enum ImageSizeType {
    HORIZONTAL = 'HORIZONTAL',
    SQUARE = 'SQUARE',
}

export enum ContentStyleType {
    DEFAULT = 'DEFAULT',
    CUSTOMIZE = 'CUSTOMIZE',
}

export class FeatureSetting {
    // card-style： 3D，flat，no border
    cardStyle?: CardStyle;
    enabledActionButton?: boolean;
    // card-spacing-style： SMALL、BIG
    cardSpacing?: CardSpacingType;
    verticalAlign?: VerticalAlignType;
    columns?: number;
    imageSize?: ImageSizeType;
    contentStyleType?: ContentStyleType;
    items?: FeatureListItem[];
}
