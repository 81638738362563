import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DirectivesModule } from '../../core/directives/directives.module';
import { AddBtnComponent } from './add-btn/add-btn.component';
import { AdressInputComponent } from './adress-input/adress-input.component';
import { AreaSelectComponent } from './area-select/area-select.component';
import { FilterParamsSelectorComponent } from './filter-params-selector/filter-params-selector.component';
import { CountriesSelectorComponent } from './countries-selector/countries-selector.component';
import { ErrorComponent } from './error-view/error-view.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { LoadingComponent } from './loading/loading.component';
import { MaskComponent } from './mask/mask.component';
import { NothingComponent } from './nothing/nothing.component';
import { SmsCountryCodeComponent } from './sms-country-code/sms-country-code.component';
import { BreadComponent } from './bread/bread.component';
import { ButtonComponent } from './button/button.component';
import { CardComponent } from './card/card.component';
import { CarouselComponent } from './carousel/carousel.component';
import { SharedModule } from '../shared.module';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CommentComponent } from './comment/comment.component';
import { UserProfileMenuComponent } from './user-profile-menu/user-profile-menu.component';
import { PopupComponent } from './popup/popup.component';
import { PostComponent } from './post/post.component';
import { CommonPostListComponent } from './post-list/post-list.component';
import { IconModule, ResultModule, TextareaItemModule, CarouselModule, DrawerModule, ListModule } from 'ng-zorro-antd-mobile';
import { WechatQrcodeComponent } from './wechat-qrcode/wechat-qrcode.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        AddBtnComponent,
        BreadComponent,
        ButtonComponent,
        CardComponent,
        CarouselComponent,
        PopupComponent,
        HeaderNavComponent,
        MaskComponent,
        NothingComponent,
        UserProfileMenuComponent,
        AdressInputComponent,
        AreaSelectComponent,
        LoadingComponent,
        ErrorComponent,
        FilterParamsSelectorComponent,
        SmsCountryCodeComponent,
        CountriesSelectorComponent,
        BreadcrumbsComponent,
        CommentComponent,
        PostComponent,
        CommonPostListComponent,
        WechatQrcodeComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        LazyLoadImageModule,
        DirectivesModule,
        TranslateModule,
        NgbDropdownModule,
        SharedModule,
        TextareaItemModule,
        ResultModule,
        RouterModule,
        IconModule,
        CarouselModule,
        MatCheckboxModule,
        DrawerModule,
        ListModule,
    ],
    exports: [
        AddBtnComponent,
        BreadComponent,
        ButtonComponent,
        CardComponent,
        CarouselComponent,
        PopupComponent,
        HeaderNavComponent,
        MaskComponent,
        NothingComponent,
        UserProfileMenuComponent,
        FormsModule,
        LazyLoadImageModule,
        AdressInputComponent,
        AreaSelectComponent,
        LoadingComponent,
        ErrorComponent,
        FilterParamsSelectorComponent,
        TranslateModule,
        SmsCountryCodeComponent,
        CountriesSelectorComponent,
        BreadcrumbsComponent,
        CommentComponent,
        PostComponent,
        CommonPostListComponent,
        RouterModule,
        WechatQrcodeComponent,
    ]
})
export class SharedComponentsModule { }
