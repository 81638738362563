export const accountManagementTranslate = {
    USER: {
        LIST_PAGE: {
            CUSTOMER_USER: '客户账号',
            TEACHER_USER: '教师账号',
            ADD_BTN_TEXT: '添加管理员账号',
            QUERY_FORM: {
                LOGIN: {
                    TITLE: '账号',
                    PLACEHOLDER: '请输入账号进行查询...',
                },
                FIRST_NAME: {
                    TITLE: '用户名',
                    PLACEHOLDER: '请输入用户名进行查询...',
                },
                MOBILE_PHONE: {
                    TITLE: '手机号',
                    PLACEHOLDER: '请输入手机号进行查询...',
                },
                EMAIL: {
                    TITLE: '邮箱',
                    PLACEHOLDER: '请输入邮箱进行查询...',
                },
            },
            TABLE: {
                TH: {
                    LOGIN: '登录账号',
                    FIRST_NAME: '用户名',
                    CONTACT: '联系方式',
                    ACTIVATED: '是否激活',
                    PASSWORD_LOGIN_ENABLED: '密码登录',
                    MOBILE_PHONE_LOGIN_ENABLED: '手机号登录',
                    WECHAT_LOGIN_ENABLED: '微信登录',
                },
                TD: {
                    ACTIVATED_TITLE: '点击更改账号激活状态',
                    ACTIVATED: '已激活',
                    NOT_ACTIVATED: '未激活',
                    RESET_BTN_TEXT: '重置密码'
                }
            },
            CHANGE_ACTIVE: {
                TITLE: '你确定要更改账号 "{{login}}" 的激活状态吗？'
            },
            DELETE_USER: {
                TITLE: '你确定要删除账号 "{{login}}" 吗？'
            }
        },
        UPDATE_PAGE: {
            TITLE: '{{action}}账号',
            LOGIN: {
                TITLE: '登录账号',
                PLACEHOLDER: '请输入登录账号...',
                REQUIRED_ERROR_MESSAGE: '账号不能为空！'
            },
            FIRST_NAME: {
                TITLE: '用户名',
                PLACEHOLDER: '请输入用户名...',
            },
            PASSWORD: {
                TITLE: '密码',
                PLACEHOLDER: '请输入密码...',
                REQUIRED_ERROR_MESSAGE: '密码不能为空！'
            },
            REPEAT_PASSWORD: {
                TITLE: '确认密码',
                PLACEHOLDER: '请输入确认密码...',
                REQUIRED_ERROR_MESSAGE: '确认密码不能为空！',
                REPEAT_ERROR_MESSAGE: '确认密码必须与密码保持一致！',
            },
            MOBILE_PHONE: {
                TITLE: '手机号',
                PLACEHOLDER: '请输入手机号...',
            },
            EMAIL: {
                TITLE: '邮箱',
                PLACEHOLDER: '请输入邮箱...',
                EMAIL_ERROR_MESSAGE: '邮箱格式不正确！'
            },
            ACTIVATED: {
                TITLE: '是否激活'
            },
        },
        RESET_PASSWORD_PAGE: {
            TITLE: '您正在为账号 "{{login}}" 重置登录密码',
            PASSWORD: {
                TITLE: '密码',
                PLACEHOLDER: '请输入密码...',
                REQUIRED_ERROR_MESSAGE: '密码不能为空！',
            },
            REPEAT_PASSWORD: {
                TITLE: '确认密码',
                PLACEHOLDER: '请输入确认密码...',
                REQUIRED_ERROR_MESSAGE: '确认密码不能为空！',
                REPEAT_ERROR_MESSAGE: '确认密码必须与密码保持一致！',
            }
        }
    },
}