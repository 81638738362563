import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentDeviceService } from '../../../core/services/current-device.service';
import { ISeries } from '../../shared/model/series.model';
import { ProductsService } from '../../builtin-pages/featured-products/_service/products.service';

@Component({
    selector: 'app-series-products-block',
    templateUrl: './series-products.component.html',
    styleUrls: ['./series-products.component.scss']
})
export class SeriesProductsBlockComponent implements OnInit {
    @Input() title: string = '';
    @Input() blockId: string;
    @Input() titleTranslation = '';
    @Input() renderOptions: any = {};
    @Input() section: string;
    series: ISeries[] = [];
    showSeriesMore: boolean;
    constructor(
        private storeService: ProductsService,
        private currentDeviceService: CurrentDeviceService,
        private router: Router,
    ) { }
    ngOnInit(): void {
        this.getSeries();
    }
    // 获取主题卡片
    getSeries() {
        this.storeService.querySeries({
            published: true,
            sort: 'createdDate,desc',
            size: (this.renderOptions?.fullWidth ? 8 : 5)
        }).subscribe(
            (res: HttpResponse<ISeries[]>) => {
                const total = parseInt(res.headers.get('x-total-count'), 10);
                this.showSeriesMore = Boolean(total > (this.renderOptions?.fullWidth ? 8 : 5));
                this.series = res.body;
            });
    }

    navigateMorePage(path: string) {
        this.router.navigate([path]);
    }
}
