export const systemSettingTranslate = {
    WECHAT_OPEN_CONFIG: {
        APP_ID: {
            PLACEHOLDER: '请输入AppId...',
            REQUIRED_ERROR_MESSAGE: 'AppId不能为空！',
        },
        APP_SECRET: {
            PLACEHOLDER: '请输入AppSecret...',
            REQUIRED_ERROR_MESSAGE: 'AppSecret不能为空！',
        },
        SUCCESS_MESSAGE: '保存成功！',
        ERROR_MESSAGE: '保存失败！',
    },
    WECHAT_MP_CONFIG: {
        APP_ID: {
            PLACEHOLDER: '请输入AppId...',
            REQUIRED_ERROR_MESSAGE: 'AppId不能为空！',
        },
        APP_SECRET: {
            PLACEHOLDER: '请输入AppSecret...',
            REQUIRED_ERROR_MESSAGE: 'AppSecret不能为空！',
        },
        SUCCESS_MESSAGE: '保存成功！',
        ERROR_MESSAGE: '保存失败！',
    }
}