export const contentManagementTranslate = {
    POST: {
        LIKED_CANCELLED: '已取消点赞！',
        CANNOT_REPEAT_LIKES: '不能重复点赞！',
    },
    FORMS: {
        GET_FORM_FAILED: '获取表单失败：',
        DISABLED_SUBMIT_FOR_NO_DATA: '当前未填写任何数据，无法提交！',
        GET_FORM_DATE_ERROR: '获取表单数据失败：',
    },
    ERROR: {
    },
    GENERAL: {
    }
};
