export const affiliateTranslate = {
    GENERAL: {
        CASH: '現金獎勵',
        AFFILIATE_CODE: '聯盟代碼',
        AFFILIATE_DISCOUNT: '聯盟優惠',
        REFERRAL_DISCOUNT: '推薦優惠',
        PENDING_STATUS: '待支付',
        CONFIRMED_STATUS: '已入帳',
        SETTLED_STATUS: '已結算',
        COMPLETED_STATUS: '已完成',
        CANCELLED_STATUS: '已取消',
        CASH_TEXT: '現金',
        COUPON: '優惠券',
        CONSUMING_CARD: '消費卡',
        GIFT_CARD: '禮品卡',
        POINTS: '積分',
        PRODUCT: '商品',
        APPROVED: '已批准',
        PENDING: '待審核',
        REJECTED: '已拒絕',
        CANCELLED: '已取消',
        SETTLED: '已結算',
    },
    SHARE_MODAL: {
        MARKET_REWARD: '推廣賺',
        INVITE_FRIEND_START_TIP: '邀請好友成功購買本{{productName}}，每單最高得',
        COMMISSION_START_TIP: '推廣收益及提現請到',
        OPERATION: '操作',
        COPY_BUTTON: '複製推廣連結',
        REWARD: '推廣賺',
    }
}