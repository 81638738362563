<div class="recommend" [ngClass]="{'big-size-product-list':productImageSize === 'BIG'}">
    <div class="b-r" [ngClass]="{'product-part': !isSliding,
        'slide-product-part': isSliding,
        'margin-top-1-rem':oneRemOfMarginTop,
        'big-size':productImageSize === 'BIG',
        'margin-right-auto-for-desktop':enableDesktopMarginRightAuto
    }" *ngFor="let data of productDatas; let idx = index">
        <div (click)="vote('son')" routerLink="/product" [queryParams]="{ id: data.id, share: true, shopId: shopId }"
            data-skeleton-bgcolor="#eee" style="height: 100%">
            <div class="triangle" *ngIf="data?.requiredMembers">
                <span class="triangle-text" data-skeleton-bgcolor="white"
                    [appTranslate]="'GENERAL.GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS_TEXT'"
                    [translateParams]="{requiredMembers:data?.requiredMembers}">
                    {{data?.requiredMembers}}人拼团</span>
            </div>
            <div class="img-box">
                <video src="{{ data.img || data.imgUrl }}" *ngIf="urlHelperService.isVideo(data.img || data.imgUrl)"
                    controls class="product-img" [appTranslate]="'SHARED.BROWSER_UNSPPORT_VIDEO_PLAY'">
                    您的浏览器太老了，不支持播放视频，请升级浏览器哦！
                </video>
                <img [decode]="true" offset="100"
                    [src]="((data.img || data.imgUrl) | uploadUrlPrefix) | imgResize: ((productImageSize !== 'BIG') ? 'h_400' : 'h_532')"
                    loading="lazy" onerror="this.onerror=null;this.src='/assets/icon/img-blank.svg';"
                    alt="" class="b-r product-img" *ngIf="urlHelperService.isImage(data.img || data.imgUrl)"
                    data-skeleton-bgcolor="white" />
            </div>
            <div class="product-word">
                <div class="btm" data-skeleton-bgcolor="white">
                    <div class="line-clamp tit-width" style="letter-spacing: 1.1px;" data-skeleton-bgcolor="white"
                        [ngClass]="{'show-original-price':originalPriceVisible}">{{
                        data | mNameTranslate }}</div>
                    <div class="d-flex align-items-center product-price flex-wrap">
                        <ng-container *ngIf="originalPriceVisible">
                            <div class="d-flex align-items-center" style="white-space: nowrap;display: inline-block;">
                                <span class="price-color" style="font-size: var(--fz-16);"
                                    data-skeleton-bgcolor="white">{{ getFormatPriceStr(data.price) |
                                    currencyHelper:data.currency}}</span>
                                <span style="padding-left: 0.25rem;color:var(--bs-body-color);"
                                    *ngIf="taxIncludedVisible" data-skeleton-bgcolor="white">(<span
                                        [appTranslate]="'GENERAL.TAX_INCLUDED'">含税</span>)</span>
                                <span class="delete-line" style="margin-left: 0.25rem;align-self: flex-end;">
                                    {{data.marketPrice ? (getFormatPriceStr(data.marketPrice) |
                                    currencyHelper:data.currency) :
                                    ''}}</span>
                            </div>
                            <div class="justify-content-between d-flex align-items-center w-100"
                                style="margin-top: 0.25rem;padding-top: 0.25rem;padding-bottom: 0.25rem;">
                                <span *ngIf="data.saleType === 'PRESALE'" class="pre-sale show-original-price"
                                    data-skeleton-bgcolor="white" [appTranslate]="'GENERAL.PRE_SALE'">预售</span>
                                <span *ngIf="data?.allowOnlyNewCustomer" class="pre-sale show-original-price"
                                    data-skeleton-bgcolor="white" [appTranslate]="'SHARED.NEWCOMER_GROUP'">新人拼团</span>
                                <span [ngClass]="{'expand': salesVisible,'no-expand': !salesVisible}"
                                    style="float: initial;" [appTranslate]="'SHARED.SOLD_DESC'"
                                    [translateParams]="{value:data?.productStatisticsDTO?.saleQuantity || 0}"></span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!originalPriceVisible">
                            <div class="d-inline-flex align-items-center"
                                style="white-space: nowrap;display: inline-block;">
                                <span class="price-color" style="font-size: var(--fz-16);"
                                    data-skeleton-bgcolor="white">{{ getFormatPriceStr(data.price) |
                                    currencyHelper:data.currency}}</span>
                                <span style="padding-left: 0.25rem;color:var(--bs-body-color);"
                                    *ngIf="taxIncludedVisible" data-skeleton-bgcolor="white">(<span
                                        [appTranslate]="'GENERAL.TAX_INCLUDED'">含税</span>)</span>
                                <span *ngIf="data.saleType === 'PRESALE'" class="pre-sale" data-skeleton-bgcolor="white"
                                    [appTranslate]="'GENERAL.PRE_SALE'">预售</span>
                                <span *ngIf="data?.allowOnlyNewCustomer" class="pre-sale" data-skeleton-bgcolor="white"
                                    [appTranslate]="'SHARED.NEWCOMER_GROUP'">新人拼团</span>
                            </div>
                            <span [ngClass]="{'expand': salesVisible,'no-expand': !salesVisible}"
                                [appTranslate]="'SHARED.SOLD_DESC'"
                                [translateParams]="{value:data?.productStatisticsDTO?.saleQuantity || 0}"></span>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="nothing-but-blue"
                *ngIf="data?.stockControl && (!data.stock || data.stock <= 0 || !data.onShelf)">
                <img src="/assets/icon/nothing-but-blue.png" alt="" style="width: 100%; height: 100%" />
                <div class="sale-out-tip-text" [appTranslate]="'GENERAL.SOLD_OUT'" style="position: absolute;
                top: 41%;
                left: 0;
                transform: rotate(-18.5deg);
                font-size: 12px;
                text-transform: uppercase;
                color: rgb(222, 0, 0);
                font-weight: bolder;
                width: 100%;
                text-align: center;" [style.letterSpacing]="isEnglishLanguage()?'0':'8px'">已售罄</div>
            </div>
        </div>
        <button type="button" class="btn btn-sm bg-warning btn-add-shopping-cart b-r overflow-hidden"
            *ngIf="enableShoppingCartFeatureOption && enableAddShoppingCartBtn"
            [disabled]="(data.saleType === 'PRESALE')" (click)="addToShoppingCart(data)"
            [style.marginTop]="!originalPriceVisible?'0.75rem':''">
            <i class="bi bi-cart-plus" style="padding-right: 0.5rem;position: relative;top: -0.075rem;"></i>
            <span [appTranslate]="'GENERAL.ADD_TO_SHOPPINGCART_BTN_TEXT'">加入购物车</span>
        </button>
    </div>
</div>