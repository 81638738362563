export const accountManagementTranslate = {
    USER: {
        LIST_PAGE: {
            CUSTOMER_USER: '顧客アカウント',
            TEACHER_USER: '教師アカウント',
            ADD_BTN_TEXT: '管理者アカウントの追加',
            QUERY_FORM: {
                LOGIN: {
                    TITLE: 'アカウント',
                    PLACEHOLDER: 'アカウントを入力して検索してください...',
                },
                FIRST_NAME: {
                    TITLE: 'ユーザー名',
                    PLACEHOLDER: 'クエリーにユーザー名を入力してください...',
                },
                MOBILE_PHONE: {
                    TITLE: '携帯番号',
                    PLACEHOLDER: '携帯番号を入力して調べてください...',
                },
                EMAIL: {
                    TITLE: 'メール',
                    PLACEHOLDER: '問い合わせにメールアド レスを入力してください...',
                },
            },
            TABLE: {
                TH: {
                    LOGIN: 'ログインアカウント',
                    FIRST_NAME: 'ユーザー名',
                    CONTACT: '連絡先',
                    ACTIVATED: 'アクティブ化するかどうか',
                    PASSWORD_LOGIN_ENABLED: 'パスワードログイン',
                    MOBILE_PHONE_LOGIN_ENABLED: '携帯番号登録',
                    WECHAT_LOGIN_ENABLED: 'ウィチャットログイン',
                },
                TD: {
                    ACTIVATED_TITLE: 'アカウントのアクティブ状態を変更するにはクリックしてください',
                    ACTIVATED: 'アクティブ化',
                    NOT_ACTIVATED: '非アクティブ',
                    RESET_BTN_TEXT: 'パスワードをリセット'
                }
            },
            CHANGE_ACTIVE: {
                TITLE: 'アカウント"{{login}}"のアクティブ状態を変更しますか？'
            },
            DELETE_USER: {
                TITLE: 'アカウント"{{login}}"を削除しますか？'
            }
        },
        UPDATE_PAGE: {
            TITLE: '{{action}} アカウント',
            LOGIN: {
                TITLE: 'ログインアカウント',
                PLACEHOLDER: 'ログインアカウントを入力してください...',
                REQUIRED_ERROR_MESSAGE: 'アカウントを空にすることはできません！'
            },
            FIRST_NAME: {
                TITLE: 'ユーザー名',
                PLACEHOLDER: 'ユーザー名を入力してください...',
            },
            PASSWORD: {
                TITLE: 'パスワード',
                PLACEHOLDER: 'パスワードを入力してください...',
                REQUIRED_ERROR_MESSAGE: 'パスワードは空白にできません！'
            },
            REPEAT_PASSWORD: {
                TITLE: 'パスワードの確認',
                PLACEHOLDER: '確認パスワードを入力してください...',
                REQUIRED_ERROR_MESSAGE: 'パスワードを空にすることはできません！',
                REPEAT_ERROR_MESSAGE: '確認用パスワードはパスワードと一致させる必要があります！',
            },
            MOBILE_PHONE: {
                TITLE: '携帯番号',
                PLACEHOLDER: '携帯番号を入力してください...',
            },
            EMAIL: {
                TITLE: 'メール',
                PLACEHOLDER: 'メールアド レスを入力してください...',
                EMAIL_ERROR_MESSAGE: 'メールアド レスのフォーマットが正しくありません！'
            },
            ACTIVATED: {
                TITLE: 'アクティブ化するかどうか'
            },
        },
        RESET_PASSWORD_PAGE: {
            TITLE: 'アカウント "{{login}}" のログインパスワードをリセットしています。',
            PASSWORD: {
                TITLE: 'パスワード',
                PLACEHOLDER: 'パスワードを入力してください...',
                REQUIRED_ERROR_MESSAGE: 'パスワードは空白にできません！',
            },
            REPEAT_PASSWORD: {
                TITLE: 'パスワードの確認',
                PLACEHOLDER: '確認パスワードを入力してください...',
                REQUIRED_ERROR_MESSAGE: 'パスワードを空にできないことを確認します！',
                REPEAT_ERROR_MESSAGE: 'パスワードがパスワードと一緻していることを確認します！',
            }
        }
    },
}
