<div class="side-bar-container aside-panel" [ngClass]="{right:isRight}">
    <div class="d-flex align-items-center justify-content-between side-bar-header">
        <div [title]="'GENERAL.GO_TO_HOME_BTN_TEXT' | translate" style="cursor: pointer;" [routerLink]="['/']"
            class="site-logo-container">
            <img [src]="siteLogo" alt="" class="site-logo"
                [style.visibility]="(renderOptions?.logoVisible !== false) ? 'visible' : 'hidden'">
        </div>
        <i class="close-side-bar-btn bi bi-x-lg" (click)="closeAsideBar()"></i>
    </div>
    <div class="side-bar-content">
        <div class="w-100" *ngFor="let menuItem of menu?.items;">
            <ng-container *ngIf="menuItem?.enabled">
                <div class="d-flex align-items-center justify-content-between side-bar-menu"
                    (click)="clickMenuItem(menuItem)" [ngClass]="{active:checkMenuIsActive(menuItem)}">
                    <div class="d-flex align-items-center">
                        <!-- <i class="{{(menuItem?.iconClassKey | appIconClassKey:checkMenuIsActive(menuItem))}}"></i> -->
                        <p data-skeleton-bgcolor="white" style="text-transform: capitalize;"
                            title="{{menuItem | mTitleTranslate}}">{{menuItem |
                            mTitleTranslate}}
                        </p>
                    </div>
                    <i class="bi expend-icon"
                        [ngClass]="{'bi-chevron-down':!expendedMenuPanel?.[menuItem?.id],'bi-chevron-up':expendedMenuPanel?.[menuItem?.id]}"
                        (click.stop)="changeMenuPanelExpendStatus(menuItem?.id)" *ngIf="menuItem?.children?.length"></i>
                </div>
                <div class="menu-children-container" [ngClass]="{expended:expendedMenuPanel[menuItem?.id]}"
                    style="padding-left: 1em;" *ngIf="menuItem?.children?.length">
                    <div class="w-100" *ngFor="let childMenuItem of menuItem?.children;">
                        <ng-container *ngIf="childMenuItem?.enabled">
                            <div class="d-flex align-items-center justify-content-between side-bar-menu"
                                (click)="clickMenuItem(childMenuItem)"
                                [ngClass]="{active:checkMenuIsActive(childMenuItem)}">
                                <div class="d-flex align-items-center">
                                    <!-- <i
                                        class="{{(childMenuItem?.iconClassKey | appIconClassKey:checkMenuIsActive(childMenuItem))}}"></i> -->
                                    <p data-skeleton-bgcolor="white" style="text-transform: capitalize;"
                                        title="{{childMenuItem | mTitleTranslate}}">{{childMenuItem
                                        | mTitleTranslate}}
                                    </p>
                                </div>
                                <i class="bi expend-icon"
                                    [ngClass]="{'bi-chevron-down':!expendedMenuPanel?.[childMenuItem?.id],'bi-chevron-up':expendedMenuPanel?.[childMenuItem?.id]}"
                                    (click.stop)="changeMenuPanelExpendStatus(childMenuItem?.id)"
                                    *ngIf="childMenuItem?.children?.length"></i>
                            </div>
                            <div class="menu-son-children-container" style="padding-left: 1em;"
                                [ngClass]="{expended:expendedMenuPanel[childMenuItem?.id]}"
                                *ngIf="childMenuItem?.children?.length">
                                <div class="w-100" *ngFor="let sonMenuItem of childMenuItem?.children;">
                                    <div class="d-flex align-items-center justify-content-between side-bar-menu"
                                        (click)="clickMenuItem(sonMenuItem)" *ngIf="sonMenuItem?.enabled"
                                        [ngClass]="{active:checkMenuIsActive(sonMenuItem)}">
                                        <div class="d-flex align-items-center">
                                            <!-- <i
                                                class="{{(sonMenuItem?.iconClassKey | appIconClassKey:checkMenuIsActive(sonMenuItem))}}"></i> -->
                                            <p data-skeleton-bgcolor="white" style="text-transform: capitalize;"
                                                title="{{sonMenuItem | mTitleTranslate}}">
                                                {{sonMenuItem | mTitleTranslate}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="d-flex align-items-center site-bar-footer" *ngIf="countries?.length">
        <div class="register" style="position: relative;cursor: pointer;margin-left: 2rem;">
            <app-user-profile-menu *ngIf="isLogin;" [username]="userName" [userAvatarUrl]="userAvatarUrl"
                [enableMoreIcon]="false" [containerClass]="'flex-row-reverse'" avatarImgSize="2rem" (click.stop)="closeAsideBar()">
            </app-user-profile-menu>
            <ng-container *ngIf="!isLogin">
                <ng-container *ngIf="(accountLoginBtnType !== 'ONLY_LOGIN_ICON')&&(accountLoginBtnType !== 'NONE')">
                    <ng-container *ngTemplateOutlet="loginButtonRef"></ng-container>
                </ng-container>
                <ng-container *ngIf="accountLoginBtnType === 'ONLY_LOGIN_ICON'">
                    <div class="d-flex align-items-center px-0 menu-item-btn" routerLink="/auth/default-login"
                        (click.stop)="closeAsideBar()">
                        <i class="iconfont icon-wode shopping-cart-menu-btn" style="font-size:1.3rem;"></i>
                        <span class="d-flex align-items-end menu-item-btn w-100" style="padding-left: 0.5rem;"
                            [appTranslate]="'GENERAL.LOGIN'" title="{{'GENERAL.LOGIN' | translate}}">
                            登录</span>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <ng-template #loginButtonRef>
            <div Button [inline]="true" [size]="'small'" style="padding: 0 15px;cursor: pointer;"
                routerLink="/auth/default-login" title="{{'GENERAL.LOGIN' | translate}}"
                [appTranslate]="'GENERAL.LOGIN'" (click)="closeAsideBar()">登录
            </div>
            <div Button [inline]="true" [size]="'small'" style="padding: 0 15px;margin-left: 10px; cursor: pointer;"
                routerLink="/auth/default-register" title="{{'GENERAL.REGISTER' | translate}}" (click)="closeAsideBar()"
                [appTranslate]="'GENERAL.REGISTER'">
                注册</div>
        </ng-template>
    </div>
</div>

<div class="position-fixed side-bar-drop-mask" (click)="closeAsideBar()" *ngIf="maskEnable"></div>
