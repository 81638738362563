<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="renderOptions" [enabledMoreBtn]="showMoreProducts" [moreBtnText]="'GENERAL.SEE_MORE' | translate"
    (moreBtnClick)="navigateMorePage('/more-products')" [section]="section">
    <div class="product-list-block-layout">
        <div id="js-loadProduct" class="product-list" [class.load-part]="false">
            <ng-container *ngIf="!loadProductsFailed">
                <ng-container *ngIf="!loadingProducts">
                    <app-product [productDatas]="products" [originalPriceVisible]="renderOptions?.originalPriceVisible"
                        [taxIncludedVisible]="renderOptions?.taxIncludedVisible" [oneRemOfMarginTop]="true"
                        [enableDesktopMarginRightAuto]="renderOptions?.fullWidth"
                        [enableAddShoppingCartBtn]="renderOptions?.enableShoppingCartBtn" [salesVisible]="salesVisible"
                        [productImageSize]="renderOptions?.productImageSize">
                    </app-product>
                    <div class="page-padding emtpy-category b-r overflow-auto w-100" *ngIf="!products?.length">
                        <div class="icon-empty">
                            <img src="/assets/icon/nothing/nothing-buy.png" style="width: 15rem;">
                        </div>
                        <div class="nothing-title">{{'GENERAL.NO_MORE_DATA' | translate}}~</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="loadingProducts">
                    <div class="br overflow-hidden px-4 pb-5 pt-1 mt-3 bg-white b-r">
                        <app-loading></app-loading>
                    </div>
                </ng-container>
            </ng-container>
            <app-error-view *ngIf="loadProductsFailed" [errorImg]="'/assets/icon/not-found.svg'"
                [loadedError]="{title:'GENERAL.LOAD_FAILED', message:loadProductsFailedMsg,isTitleLangKey:true}">
                <div class="d-flex justify-content-center pb-4">
                    <button type="button" class="btn btn-sm bg-warning btn-reflush ellipsis"
                        [title]="'GENERAL.RELOAD_BTN_TEXT' | translate"
                        (click)="getProducts()">{{'GENERAL.RELOAD_BTN_TEXT' | translate}}</button>
                </div>
            </app-error-view>
        </div>
    </div>
</app-block-container>