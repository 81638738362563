<div class="page-padding error-category b-r overflow-hidden {{errorWrapperClass}} {{noMarginTop&&'no-margin-top'}}">
    <Result class="border-0" [img]="loadedError?.templateRef || img3"
        [title]="loadedError?.isTitleLangKey ? (loadedError?.title | translate) : loadedError?.title"
        [message]="loadedError?.message">
        <ng-template #img3>
            <div class="icon-loaded-error">
                <svg *ngIf="!errorImg" t="1637812724340" class="icon" viewBox="0 0 1427 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4541" width="200" height="200">
                    <path
                        d="M908.47863 733.898197a42.608601 42.608601 0 0 1-33.817637-16.608758 266.680859 266.680859 0 0 0-422.501502 0 42.657259 42.657259 0 1 1-67.554176-52.129441 351.962938 351.962938 0 0 1 557.609855 0 42.657259 42.657259 0 0 1-33.752759 68.72198z"
                        fill="#B9B9B9" p-id="4542"></path>
                    <path
                        d="M1097.841179 544.50321a42.559942 42.559942 0 0 1-32.179469-14.597542 533.313059 533.313059 0 0 0-804.486716 0 42.657259 42.657259 0 0 1-64.342718-56.054558 618.643797 618.643797 0 0 1 933.155933 0 42.657259 42.657259 0 0 1-32.14703 70.684539z"
                        fill="#B9B9B9" p-id="4543"></path>
                    <path
                        d="M1284.138244 358.189925a42.527503 42.527503 0 0 1-31.433372-13.802786 799.961479 799.961479 0 0 0-1178.605479 0 42.673479 42.673479 0 0 1-62.850525-57.725166 885.275997 885.275997 0 0 1 1304.27409 0 42.657259 42.657259 0 0 1-31.417153 71.511733z"
                        fill="#B9B9B9" p-id="4544"></path>
                    <path
                        d="M666.078348 842.682318m-85.330738 0a85.330738 85.330738 0 1 0 170.661477 0 85.330738 85.330738 0 1 0-170.661477 0Z"
                        fill="#B9B9B9" p-id="4545"></path>
                    <path
                        d="M1134.043081 730.686738m-239.983578 0a239.983578 239.983578 0 1 0 479.967156 0 239.983578 239.983578 0 1 0-479.967156 0Z"
                        fill="#FF5F2E" p-id="4546"></path>
                    <path
                        d="M1134.043081 490.703161a240.048456 240.048456 0 1 1-240.048456 240.048455 240.048456 240.048456 0 0 1 240.048456-240.048455m0-53.329684a293.313262 293.313262 0 1 0 293.313262 293.313261A293.572774 293.572774 0 0 0 1134.043081 437.373477z"
                        fill="#FFFFFF" p-id="4547"></path>
                    <path
                        d="M1224.77491 861.334732a39.883727 39.883727 0 0 1-28.286791-11.710472l-181.398779-181.317681a39.997263 39.997263 0 1 1 56.573582-56.557363l181.317681 181.285243a39.997263 39.997263 0 0 1-28.286791 68.284054z"
                        fill="#FFFFFF" p-id="4548"></path>
                    <path
                        d="M1043.376131 861.334732a39.997263 39.997263 0 0 1-28.286791-68.284054l181.317681-181.317681a39.997263 39.997263 0 0 1 56.573582 56.557362l-181.317681 181.333901a39.883727 39.883727 0 0 1-28.286791 11.710472z"
                        fill="#FFFFFF" p-id="4549"></path>
                </svg>
                <ng-container *ngIf="errorImg">
                    <img [src]="errorImg" alt="">
                </ng-container>
            </div>
        </ng-template>
    </Result>
    <ng-content></ng-content>
</div>