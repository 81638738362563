export const userTranslate = {
    INFOMATION: {
        AVATAR: {
            TITLE: '头像'
        },
        NICK_NAME: {
            TITLE: '昵称',
            PLACEHOLDER: '请输入昵称...'
        },
        GENDER: {
            TITLE: '性别',
            MALE: '男',
            FEMALE: '女',
            OTHER: '其他',
            UNKNOWN: '未知'
        },
        NAME: {
            TITLE: '姓名',
            PLACEHOLDER: '请输入姓名...'
        },
        MOBILE_PHONE: {
            TITLE: '联系方式',
            PLACEHOLDER: '请输入联系方式...'
        },
        TYPE: {
            TITLE: '类型',
            INDIVIDUAL: '个人',
            ORGANIZATION: '组织'
        },
        ENTITY_SCALE: {
            TITLE: '主体规模',
            OPTIONS: ['有限责任公司', '股份有限公司', '个人独资企业', '合伙企业', '全名所有制企业', '集体所有制企业', '其他']
        },
        ANNUAL_REVENUE: {
            TITLE: '年营收入',
            PLACEHOLDER: '请输入年营收入...'
        },
        EMPLOYEE_NUMBER: {
            TITLE: '用工人数',
            PLACEHOLDER: '请输入用工人数...'
        },
        SAVE_BTN_TEXT: '更新资料',
        SAVE_BTN_SUCCESS_TEXT: '资料更新成功！',
        SAVE_BTN_ERROR_TEXT: '资料更新失败！',
        UPDATE_AVATAR_ERROR: '上传头像失败，',
    },
    ACCOUNT_SECURITY: {
        SET_PASSWORD: {
            TITLE: '设置密码',
            ENABLE: '已设置',
            NOT_ENABLE: '未设置',
            SET_BTN_TEXT: '设置',
            CHANGE_BTN_TEXT: '更改',
        },
        BIND_MOBILE_PHONE: {
            TITLE: '绑定手机',
            ENABLE: '已绑定',
            NOT_ENABLE: '未绑定',
            SET_BTN_TEXT: '绑定',
            CHANGE_BTN_TEXT: '更改',
        },
        BIND_WECHAT: {
            TITLE: '绑定微信',
            ENABLE: '已绑定',
            NOT_ENABLE: '未绑定',
            SET_BTN_TEXT: '绑定',
            CHANGE_BTN_TEXT: '更改',
            CHANGE_TITLE: '你确定要解绑微信吗？'
        },
        BIND_PHONE_PAGE: {
            TITLE: '为了您的账户安全，需要{{action}}你的手机号',
            BIND: '绑定',
            VERIFY: '验证',
            CHANGE_BIND: '换绑',
            SUCCESS_MESSAGE: '{{action}}手机号：{{phoneNumber}} 成功',
            ERROR_MESSAGE: '手机号更新失败！',
            MOBILE_PHONE: {
                TITLE: '手机号',
                PLACEHOLDER: '请输入手机号...'
            },
            OLD_MOBILE_PHONE: {
                TITLE: '原手机号',
                PLACEHOLDER: '请输入原手机号...'
            },
            VERTIFY_CODE: {
                TITLE: '验证码',
                PLACEHOLDER: '请输入验证码...',
                GET_BTN_TEXT: '获取验证码',
                SEND_TEXT: '发送',
                PHONE_CODE_ERROR_MESSAGE: '验证码获取失败！',
                IMAGE_CODE_ERROR_MESSAGE: '验证码获取失败！',
            },
            NEW_MOBILE_PHONE: {
                TITLE: '新手机号',
                PLACEHOLDER: '请输入新手机号...'
            },
            CHANGE_MOBILE_PHONE_BTN_TEXT: '修改手机号',
            BIND_MOBILE_PHONE_BTN_TEXT: '绑定手机号',
        },
        CHANGE_PASSWORD_PAGE: {
            TITLE: '为了您的账户安全，请选择验证方式完成身份验证：',
            PHONE_VERTIFY: {
                TITLE: '手机验证',
                MOBILE_PHONE: {
                    TITLE: '手机号',
                    PLACEHOLDER: '请输入当前绑定的手机号...'
                },
                PASSWORD: {
                    TITLE: '新密码',
                    PLACEHOLDER: '请输入新密码...'
                },
                VERTIFY_CODE: {
                    TITLE: '验证码',
                    PLACEHOLDER: '请输入验证码...',
                    GET_BTN_TEXT: '获取验证码',
                    SEND_TEXT: '发送',
                    PHONE_CODE_ERROR_MESSAGE: '验证码获取失败！',
                    IMAGE_CODE_ERROR_MESSAGE: '验证码获取失败！',
                },
                NOT_BIND_PHONE_TEXT: '暂未绑定手机，无法通过手机进行验证，请先',
                BIND_PHONE_LINK_TEXT: '绑定手机'
            },
            PASSWORD_VERTIFY: {
                TITLE: '密码验证',
                OLD_PASSWORD: {
                    TITLE: '原密码',
                    PLACEHOLDER: '请输入原密码...'
                },
                NEW_PASSWORD: {
                    TITLE: '新密码',
                    PLACEHOLDER: '请输入新密码...'
                },
            },
            SET_PASSWORD_BTN_TEXT: '设置密码',
            CHANGE_PASSWORD_BTN_TEXT: '修改密码',
            SUCCESS_MESSAGE: '修改密码成功！',
            ERROR_MESSAGE: '修改失败！',
            GET_VERTIFY_CODE_ERROR_MESSAGE: '验证码获取失败！',
            SEND_VERTIFY_CODE_ERROR_MESSAGE: '验证码发送失败！',
        },
        SET_LOGIN_NAME: {
            TITLE: '设置登录名',
            ENABLE: '已设置',
            NOT_ENABLE: '未设置',
            SET_BTN_TEXT: '设置',
            CURRENT_NAME_TIPS: '当前登录名：',
            INIT_NAME_TIPS: '初始登录名：',
            OLD_LOGIN_NAME_TIPS: '原登录名：',
            NEW_LOGIN_NAME_TIPS: '新登录名：',
            CHANGE_LOGIN_NAME_BTN_TEXT: '修改登录名',
            NEW_LOGIN_NAME_PLACEHOLDER: '修改后不能二次修改',
            LOGIN_CUSTOMIZED_TIP: '登录名已经设置了，不能再次修改',
            CHANGE_LOGIN_NAME_ERROR_TIPS: '设置登录名失败：',
            LOGIN_NAME_INVALID_ERROR_MESSAGE: '登录名格式不正确，请检查，目前仅支持大小写字母、数字和 指定特殊符号（- 、+ 、 _）！',
        }
    },
    REFERRAL: {
        REFERREEID_LABEL: '被推荐人：',
        REFERRAL_DATE_LABEL: '推荐时间：',
        REFERRAL_SHARE_URL_DESC: '注册有奖，快来看看吧：',
        REFERRAL_SHARE_CODE_DESC: '填写我的推荐码有更多福利哦：',
    },
    AFFILIATE: {
        TITLE: '申请加入联盟',
        SUCCESS_TEXT: '恭喜您，已经成功加入联盟！',
        EMAIL_LABEL: '联系邮箱',
        EMAIL_PLACEHOLDER: '用于接收通知与联系',
        EMAIL_ERROR_REQUIRED: '请填写联系邮箱',
        EMAIL_ERROR_INVALID: '联系邮箱 格式有误！',
        PHONE_LABEL: '联系电话',
        PHONE_PLACEHOLDER: '请输入您的常用电话号',
        PHONE_ERROR_REQUIRED: '请填写联系电话',
        COPY_CODE_TEXT: '复制专属代码：',
        PHONE_ERROR_INVALID: '联系电话 格式有误！',
        FIRST_NAME_TEXT: '名字',
        FIRST_NAME_ERROR_REQUIRED: '请填写名字',
        FIRST_NAME_PLACEHOLDER: '请输入您的名字',
        LAST_NAME_TEXT: '姓氏',
        LAST_NAME_PLACEHOLDER: '请输入姓氏',
        LAST_NAME_ERROR_REQUIRED: '请输入您的姓氏',
        BIRTHDAY_TEXT: '生日',
        BIRTHDAY_PLACEHOLDER: '输入生日',
        BIRTHDAY_ERROR_REQUIRED: '请填写您的生日！',
        PAYPAL_EMAIL_PLACEHOLDER: '输入您的 Paypal Email 提现账号',
        PAYPAL_EMAIL_ERROR_REQUIRED: '请填写 Paypal Email',
        PAYPAL_EMAIL_ERROR_INVALID: 'Paypal Email 格式有误！',
        FULL_ADDRESS_TEXT: '详细地址',
        FULL_ADDRESS_PLACEHOLDER: '如：街道地址',
        FULL_ADDRESS_ERROR_REQUIRED: '请填写您的详细地址！',
        COUNTRY_TEXT: '国家/地区',
        COUNTRY_ERROR_REQUIRED: '请填写国家/地区',
        STATE_TEXT: '省/州',
        STATE_PLACEHOLDER: '输入 省/州名',
        STATE_ERROR_REQUIRED: '请输入 省/州',
        SELECT_TEXT: '请选择',
        CITY_TEXT: '市名',
        CITY_PLACEHOLDER: '输入 市名',
        CITY_ERROR_REQUIRED: '请填写市名',
        ZIP_CODE_TEXT: '邮政编号',
        ZIP_CODE_PLACEHOLDER: '输入 邮政编号',
        ZIP_CODE_ERROR_REQUIRED: '请填写邮政编号',
        APPLICATION_STATEMENT_TEXT: '个人介绍及申请理由',
        APPLICATION_STATEMENT_PLACEHOLDER: '请介绍个人情况及说明申请理由',
        REMARKS_TEXT: '额外备注',
        REMARKS_PLACEHOLDER: '如果有额外备注请再此说明',
        FORM_ERROR_MSG: '当前信息填写不完整！',
    },
    COMMISSION: {
        COMMISSION_SETTLEMENT: '佣金结算',
        SETTLEMENT_AMOUNT_LABEL: '待结算金额：',
        SETTLEMENT_BTN: '结算',
        COMMISSION_RECORDS: '佣金记录',
        SETTLEMENT_RECORDS: '结算记录',
        COMMISSION_SETTLEMENT_CONFIRM_TITLE: '结算信息确认',
        RECEIPT_INFO: '收款信息',
        ENABLE_SETTLEMENT_TEXT: '可结算',
        SEE_ALL_SETTLEMENTS: '查看结算记录',
        STATUS: '状态',
        AFFILIATE: '联盟网络',
        REFERRAL: '推荐网络',
        REWARD_DETAIL: '奖励明细',
        REWARD_STATUS: '结算状态',
        PARTNER_TYPE: '奖励来源',
        PARTNER_CODE: '营销代码',
        REWARD: '营销奖励',
        SETTLEMENT_DATE: '结算日期',
        PAYMENT_DATE: '付款日期',
        PAYMENT_METHOD: '付款方式',
        PARTNER_NETWORD: '营销网络',
        CUSTOMER_ID: '客户ID',
        REWARD_TYPE: '奖励类型',
        ORDER_TYPE: '订单类型',
        ORDER_ID: '订单ID',
        ORDER_TOTAL_AMOUNT: '订单总金额',
        REWARD_RULE: '奖励规则',
        ORDER_DATE: '下单日期',
        CONFIRMED_DATE: '确认日期',
        PAYMENT_DETAIL: '付款详情',
        PAYMENT_REFERRENCE: '付款来源',
        PAYMENT_REMARK: '付款备注',
        ORDER_COMMISSION: '订单转换',
        TIER_UPGRADE: '等级奖励',
        RECEIPT_ACCOUNT: '收款账号',
        RECEIPT_TYPE: '收款方式',
        CASH_AMOUNT: '佣金收入',
        PERIOD_START_DATE: '结算开始日期',
        PERIOD_END_DATE: '结算借宿日期',
        REWARD_SOURCE_TYPE: '奖励来源',
    },
    MARKING: {
        NETWORK: '营销网络',
        NETWORK_ERROR_REQUIRED: '请选择一个想加入的营销网络',
    },
    AFFILIATE_PROFILE: {
        MENU_TITLE: '资料',
        TITLE: '修改联盟资料',
        NETWORK_INFO_LABEL: '网络信息',
        RECEIPT_INFO_LABEL: '收款信息',
        BASIC_INFO_LABEL: '基本信息',
        ADDRESS_INFO_LABEL: '地址信息',
    }
}
