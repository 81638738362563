import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ShopContextService } from '../../ecommerce/shared/service/shop-context.service';
import { CurrencyCodeEnum } from '../smart-form/_model/config.model';

@Pipe({
    name: 'currencyHelper',
    pure: false,
})

export class CurrencyHelperPipe implements PipeTransform {

    constructor(
        public shopContextService: ShopContextService,
        private currencyPipe: CurrencyPipe,
    ) { }

    transform(amount: number, currencyCode?: CurrencyCodeEnum, display?: string, digitsInfo?: string): string {
        if (!currencyCode) {
            currencyCode = this.shopContextService.shopSetting?.defaultCurrencyCode || CurrencyCodeEnum.CNY;
        }
        if (!Number.isInteger(amount)) {
            if (amount && Number.isNaN(Number(amount))) {
                return amount as unknown as string;
            }
        }
        if (!display || display == '') {
            display = currencyCode == 'JPY' ? '' : 'symbol-narrow';
        }
        if (!digitsInfo) {
            digitsInfo = '1.0-2';
        }
        const locale = 'en-US';
        let result: string = this.currencyPipe.transform(amount, currencyCode, display, digitsInfo, locale) || '';
        // 部分地方用了传入-1来得到当前货币符号，额外处理下去除掉-和1
        if (amount === -1) {
            result = result?.replace('-', '')?.replace('1', '');
        }
        if (currencyCode == 'JPY' && result != '' && !result?.includes('¥')) {
            result = result.concat('円');
        }
        return result;
    }
}
