
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { WXAPI } from './app/wxApiConfig';

if (environment.production) {
    enableProdMode();
    window.console.log = () => { };
    window.console.debug = () => { };
}

try {
    var ua = window.navigator.userAgent.toLowerCase();
    // if (ua.match(/micromessenger/i)[0] == 'micromessenger') {
    if (ua.match(/micromessenger/i) && !ua.match(/wxwork/i)) {
        console.log('微信环境')
        WXAPI.isWX = true;
    }
} catch (error) {

}
function bootstrap() {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
};


if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}

