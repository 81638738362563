import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import dayjs, { Dayjs } from 'dayjs/esm';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../../app.constants';
import { createRequestOption } from '../../../core/utilities/request-util';
import { PartnerType } from '../../../builtin-pages/user-center/pages/cash-settlement/services/cash-settlement.service';

export class OrderDiscountRule {
    constructor(value?: OrderDiscountRule) {
        if (value) {
            Object.keys(value)?.forEach(key => this[key] = value[key]);
        }
    }
    id?: string;
    // Minimum order amount to apply the discount, null means no minimum amount
    minOrderAmount?: number;
    minOrderAmountCurrency?: string;
    // If allow coupon usage, default to false
    allowCouponOnOrder?: boolean;
    // Discount amount type: FIXED, PERCENTAGE
    discountAmountType?: DiscountAmountType;
    // Discount amount value, the percentage in Double or the fixed amount
    discountAmountValue?: number;
    // Discount currency, required for fixed amount commission
    discountCurrency?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    // 后续订单是否允许折扣
    followupOrderAllowCoupon?: boolean;
    // 后续订单折扣类型
    followupOrderDiscountType?: DiscountAmountType;
    // 后续订单折扣值（比例或金额）
    followupOrderDiscountValue?: number;
    followupOrderDiscountCurrency?: string;
    network?: MarketingPartnerNetwork[];
    // 前台专属
    // 用于返回给前台判断是否叠加referral优惠
    ruleExclusiveType?: RuleExclusiveType;
    // 对于当前用户来说该规则是否有效
    eligibleForCurrentUser?: boolean;
}


export enum MarketingPartnerAchievedCriteriaType {
    /** 达成标准：订单金额 */
    ORDER_AMOUNT = 'ORDER_AMOUNT',
    /** 达成标准：订单数量 */
    ORDER_COUNT = 'ORDER_COUNT',
    /** 达成标准：注册新客户数量 */
    REGISTERED_CUSTOMER_COUNT = 'REGISTERED_CUSTOMER_COUNT',
    /** 达成标准：付款新客户数量 */
    PAID_CUSTOMER_COUNT = 'PAID_CUSTOMER_COUNT',
}

export enum DiscountAmountType {
    PERCENTAGE = 'PERCENTAGE',
    FIXED = 'FIXED',
}

/**
 * Discount/Commission Rule Exclusive type to other network/promotion of Marketing Partner Network
 */
export enum RuleExclusiveType {
    /** 排他性 */
    EXCLUSIVE = 'EXCLUSIVE',
    /** 非排他性 */
    NON_EXCLUSIVE = 'NON_EXCLUSIVE',
}

/**
 * Discount/Commission Rule validity type of Marketing Partner Network
 */
export enum RuleValidityType {
    /** 规则有效期类型：永久有效 */
    PERMANENT = 'PERMANENT',
    /** 规则有效期类型：固定有效期 */
    FIXED_DATE = 'FIXED_DATE',
    /** 规则有效期类型：相对有效期，REFERRAL 相对于被推荐人注册日期；Affiliate 相对于 Affiliate 注册日期 */
    PERIOD = 'PERIOD',
    /** 规则有效期类型：订单数量，REFERRAL 相对于被推荐人的累计订单；Affiliate 相对于 Affiliate 的总累计订单 */
    ORDER_COUNT = 'ORDER_COUNT',
    // 只有首单优惠，无后续订单
    NONE = 'NONE',
}

export enum RewardPaymentType {
    /** 佣金支付类型：现金 */
    CASH = 'CASH',
    /** 佣金支付类型：折扣券 */
    COUPON = 'COUPON',
    /** 佣金支付类型：消费卡 */
    CONSUMING_CARD = 'CONSUMING_CARD',
    /** 佣金支付类型：礼品卡 */
    GIFT_CARD = 'GIFT_CARD',
    /** 佣金支付类型：积分 */
    POINTS = 'POINTS',
    PRODUCT = 'PRODUCT',
}

// 订单佣金规则
export class OrderCommissionRule {
    constructor(value?: OrderCommissionRule) {
        if (value) {
            Object.keys(value)?.forEach(key => this[key] = value[key]);
        }
    }
    id?: string;
    /**
     * Commission Payment Type: CASH, POINTS etc.
     */
    commissionPaymentType?: RewardPaymentType;
    /**
     * Commission amount type: FIXED, PERCENTAGE
     */
    commissionAmountType?: DiscountAmountType;
    /**
     * Commission amount value, the percentage in Double or the fixed amount
     */
    commissionAmountValue?: number;
    /**
     * Commission currency, required for fixed amount commission
     */
    commissionCurrency?: string;
    downlineRules?: OrderCommissionDownlineRule[];
    network?: MarketingPartnerNetwork;
    followupOrderCommissionType?: RewardPaymentType;
    followupOrderCommissionAmountType?: DiscountAmountType;
    followupOrderCommissionAmountValue?: number;
    followupOrderCommissionCurrency?: string;
    // 佣金确认后至结算的最小天数：    0：确认后当天直接结算    1：确认后第二天结算    2：确认后第三天结算
    minDaysToSettlement?: number;
}

export class OrderCommissionDownlineRule {

}

export class MarketingPartnerNetwork {
    constructor(value?: MarketingPartnerNetwork) {
        if (value) {
            Object.keys(value)?.forEach(key => this[key] = value[key]);
        }
    }
    id?: string;
    partnerType?: PartnerType;
    name?: string;
    nameTranslation?: string;
    description?: string;
    descriptionTranslation?: string;
    siteId?: string;
    // 是否开放允许新 partner 加入
    openToJoin?: boolean;
    multiLevel?: boolean;
    /**
     * Max level on multi-level mode
     */
    maxLevel?: number;
    // 一级内的最大下线数
    maxDirectDownline?: number;
    // 该网络有多少个等级
    tiers?: MarketingPartnerTier[];
    // 网络对应的规则
    commissionRule?: OrderCommissionRule;
    // 折扣规则
    discountRule?: OrderDiscountRule;
    // 多少人可以参加这个活动（加入网络） 的 类型
    followupOrderEligibilityType?: RuleValidityType;
    followupOrderEligibilityValue?: string;
    ruleExclusiveType?: RuleExclusiveType;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
}

export class MarketingPartnerTier {
    id?: number;
    name?: string;
    nameTranslation?: string;
    description?: string;
    descriptionTranslation?: string;
    rewardPaymentType?: RewardPaymentType;
    rewardAmountType?: DiscountAmountType
    // Reward amount value, the percentage in Double or the fixed amount
    rewardAmountValue?: number;
    // Reward currency, required for fixed amount commission
    rewardCurrency?: string;
    network?: MarketingPartnerNetwork;
    /**
     * The criteria to reach this tier
     */
    achievedCriteriaType?: MarketingPartnerAchievedCriteriaType;
    /**
     * The criteria value to reach this tier
     */
    achievedCriteriaValue?: number;
    /**
     * The criteria currency to reach this tier
     */
    achievedCriteriaCurrency?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
}

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

type EntityResponseType = HttpResponse<MarketingPartnerNetwork>;
type EntityArrayResponseType = HttpResponse<MarketingPartnerNetwork[]>;

@Injectable({ providedIn: 'root' })
export class MarketingPartnerNetworkService {
    public resourceUrl = API_SERVICE_PREFIX + '/payment/api/marketing-partner-networks';
    constructor(
        protected http: HttpClient,
    ) { }

    create(partnerNetwork: MarketingPartnerNetwork): Observable<EntityResponseType> {
        return this.http.post<MarketingPartnerNetwork>(this.resourceUrl, partnerNetwork, { observe: 'response' });
    }

    update(partnerNetwork: MarketingPartnerNetwork): Observable<EntityResponseType> {
        return this.http.put<MarketingPartnerNetwork>(this.resourceUrl + '/' + partnerNetwork?.id, partnerNetwork, { observe: 'response' });
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http.get<MarketingPartnerNetwork>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headerOptions });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http.get<MarketingPartnerNetwork[]>(this.resourceUrl, { params: options, observe: 'response', headers: headerOptions });
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    count(req: any) {
        return this.http.get<any[]>(`${this.resourceUrl}/count`, { observe: 'response', headers: headerOptions });
    }

    getDicountRule(siteId: string, type: PartnerType, id: string): Observable<HttpResponse<OrderDiscountRule>> {
        return this.http.get<OrderDiscountRule>(API_SERVICE_PREFIX + '/payment/api/marketing-partners/' + siteId + '/' + type + '/' + id + '/discount-rule', { observe: 'response', headers: headerOptions });
    }

    countDiscountRule() {
        return this.http.get<any[]>(API_SERVICE_PREFIX + `/payment/api/order-discount-rules/count`, { observe: 'response', headers: headerOptions });
    }
}
