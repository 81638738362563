export const menuTranslate = {
    HOME: 'Home',
    ASIDE_NAV_MENUS: {
        APPS: {
            TITLE: 'Application'
        }
    },
    USER_PROFILE_MENUS: {
        INFOMATION: {
            TITLE: 'Infomation'
        },
        ACCOUNT_SECURITY: {
            TITLE: 'Account security',
            CHANGE_PASSWORD: {
                TITLE: 'Set password'
            },
            BIND_PHONE: {
                TITLE: 'Binding mobile phone'
            },
        },
    },
    ASIDE_FOOTER_MENUS: {
        ACCOUNT_MANAGEMENT: {
            TITLE: 'Account management',
            ADMIN_USER: {
                TITLE: 'Administrator account'
            },
            OTHER_USER: {
                TITLE: 'Other account numbers'
            },
        },
        ORGANIZATIONAL_STRUCTURE: {
            TITLE: 'Organizational structure',
            EMPLOYEES_POSTS: {
                TITLE: 'Personnel and position',
                EMPLOYEES: {
                    TITLE: 'Personnel management'
                },
                POSTS: {
                    TITLE: 'Job management'
                }
            },
            ORGANIZATIONS_DEPARTMENTS: {
                TITLE: 'Organization and department',
                DEPARTMENTS: {
                    TITLE: 'Department management'
                },
                ORGANIZATIONS: {
                    TITLE: 'Organization management'
                }
            }
        },
        SYSTEM_SETTING: {
            TITLE: 'System settings',
            WECHAT_MP_CONFIG: {
                TITLE: 'Wechat official account configuration'
            },
            WECHAT_OPEN_CONFIG: {
                TITLE: 'Wechat open platform configuration'
            }
        },
    },
}