import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RechargeDialogComponent } from './components/recharge-dialog/recharge-dialog.component';
import { NgScrollbarModule, NgScrollbarOptions, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { CategoriesForMobileComponent } from './components/categories-for-mobile/categories.component';
import { GenerateShareImageComponent } from './components/generate-share-image/generate-share-image.component';
import { NewCouponComponent } from './components/new-coupon/new-coupon.component';
import { OrderComponent } from './components/order/order.component';
import { ProductInfoComponent } from './components/product-info/product-info.component';
import { ProductComponent } from './components/product/product.component';
import { SharedModule } from '../../shared/shared.module';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { CouponComponent } from './components/coupon/coupon.component';
import { PopoverItemModule, PopoverModule } from 'ng-zorro-antd-mobile';
import { InvoiceOrderComponent } from './components/invoice-order/invoice-order.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: NgScrollbarOptions = {
    autoHeightDisabled: true,
};
@NgModule({
    declarations: [
        RechargeDialogComponent,
        CouponComponent,
        NewCouponComponent,
        OrderComponent,
        ProductComponent,
        ProductInfoComponent,
        CategoriesForMobileComponent,
        GenerateShareImageComponent,
        InvoiceOrderComponent,
    ],
    imports: [
        CommonModule,
        NgScrollbarModule,
        SharedModule,
        SharedComponentsModule,
        PopoverModule,
        PopoverItemModule,
    ],
    exports: [
        RechargeDialogComponent,
        NgScrollbarModule,
        CouponComponent,
        NewCouponComponent,
        OrderComponent,
        ProductComponent,
        ProductInfoComponent,
        CategoriesForMobileComponent,
        GenerateShareImageComponent,
        InvoiceOrderComponent,
    ],
    providers: [
        {
            provide: NG_SCROLLBAR_OPTIONS,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }
    ]
})
export class EcommerceSharedModule { }
