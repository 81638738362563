import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import dayjs, { Dayjs } from 'dayjs/esm';
import { TranslationService } from '../../../i18n';
import { AccountService } from '../../core/auth/account.service';
import { CustomerDTO } from '../../core/model/customer-dto.model';
import { HttpErrorHandlerUtil } from '../../core/utilities/http-error-handler-util';
import { Account } from '../../core/user/account.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { ToastService } from '../../shared/services/toast.service';
import { CustomizeContextType, DirectionType, HorizontalPositionType, MailSubscribeSetting } from './mail-subscribe.model';
import { EmailSubscriber, EmailSubscriberService } from './mail-subscriber.service';

@Component({
    selector: 'app-mail-subscribe-block',
    templateUrl: './mail-subscribe.component.html',
    styleUrls: ['./mail-subscribe.component.scss']
})
export class MailSubscriptionComponent {
    @Input() blockId: string = '';
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() data: MailSubscribeSetting = {};
    @Input() section: string;
    @Input() set renderOptions(value: any) {
        if (value) {
            const tempValue = value?.container?.background || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._renderOptions = {
                ...value,
                container: {
                    ...value?.container,
                    'background-color': backgroundColor
                }
            };
        }
    }
    _renderOptions: any = {};
    CustomizeContextType = CustomizeContextType;
    HorizontalPositionType = HorizontalPositionType;
    DirectionType = DirectionType;
    subscribeEmail: string;
    userInfo: Account;
    customerInfo: CustomerDTO;
    constructor(
        private router: Router,
        private emailSubscriberService: EmailSubscriberService,
        private toast: ToastService,
        private translationService: TranslationService,
        private accountService: AccountService,
        private httpErrorHandlerUtil: HttpErrorHandlerUtil,
    ) { }

    ngOnInit(): void {
        this.accountService.getUserInfo().subscribe(res => {
            this.userInfo = res?.account;
            this.customerInfo = res?.customer;
        });
    }

    getHorizontalPositionList(horizontalPosition?: HorizontalPositionType) {
        return this.data?.items?.filter(item => item.horizontalPosition === horizontalPosition);
    }

    subscribeAction(data) {
        this.emailSubscriberService.create(new EmailSubscriber({
            email: this.subscribeEmail,
            emailListId: jsonParse(data).emailListId,
            language: this.translationService.getSelectedLanguage(),
            subscribedDate: dayjs(new Date()),
            userId: this.userInfo?.id,
            customerId: this.customerInfo?.id as unknown as string,
        })).toPromise().then(res => {
            this.toast.success(this.translationService.instant('API_ALERT_MESSAGE.ERROR.CMS.EMAILALREADYEXISTS', {}, '订阅成功！'), 2000);
        }).catch(err => {
            this.httpErrorHandlerUtil.errHandle(err).catch(error => {
                try {
                    this.toast.info(this.translationService.instant(error?.instantKeyForLanguage, error?.translateParams, error?.msg), 2000);
                } catch (error) {
                    this.toast.info(error?.msg, 2000);
                }
            });
        });
    }
}
