import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ToastService as NgToastService } from 'ng-zorro-antd-mobile';
@Injectable({
    providedIn: 'root'
})
export class ToastService {
    private _lastestToastIsLoad = false;
    private platformId: any;
    constructor(
        private _toast: NgToastService,
    ) {
        this.platformId = inject(PLATFORM_ID);
     }

    load(name, time, callback = () => { }, mask?: boolean, forceUpdateToast?: boolean) {
        if (isPlatformBrowser(this.platformId)) {
            if (time === 0) {
                // 最多10秒，不应该出现无限加载的情况
                time = 10000;
            }
            try {
                // 如果没指定必须强制更新提示时，当前界面存在提示时则不往下进行提示
                if ((!forceUpdateToast) && this.checkThereIsSomeToast()) { return; }
                this._toast.loading(name, time, callback, mask);
                this._lastestToastIsLoad = true;
            } catch (error) {
            }
        }
    }

    success(name, time, callback = () => { }, mask?: boolean) {
        if (isPlatformBrowser(this.platformId)) {
            this.hide();
            try {
                this._toast.success(name, time, callback, mask);
                this._lastestToastIsLoad = false;
            } catch (error) {
            }
        }
    }
    
    fail(name, time, callback = () => { }, mask?: boolean) {
        if (isPlatformBrowser(this.platformId)) {
            this.hide();
            try {
                this._toast.fail(name, time, callback, mask);
                this._lastestToastIsLoad = false;
            } catch (error) {
            }
        }
    }

    hide() {
        if (isPlatformBrowser(this.platformId)) {
            if (this._lastestToastIsLoad) {
                try {
                    this._toast.hide();
                } catch (error) {
                }
            }
        }
    }

    info(name, time, mask: boolean = false) {
        if (isPlatformBrowser(this.platformId)) {
            this.hide();
            try {
                this._toast.info(name, time, () => { }, mask);
                this._lastestToastIsLoad = false;
            } catch (error) {
            }
        }
    }

    checkThereIsSomeToast(): boolean {
        // 判断当前界面有几个提示
        return this._toast.appRef.viewCount > 1;
    }
}
