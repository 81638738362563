export enum PageBlockTypeEnum {
    'HEADER' = 'HEADER',
    'ICON_BOXES' = 'ICON_BOXES',
    'SHOP_CAROUSEL' = 'SHOP_CAROUSEL',
    'FORMS' = 'FORMS',
    'LABEL_PRODUCTS' = 'LABEL_PRODUCTS',
    'NAVBAR' = 'NAVBAR',
    'POSTS' = 'POSTS',
    'NEW_ARRIVAL' = 'NEW_ARRIVAL',
    'SERIES_PRODUCTS' = 'SERIES_PRODUCTS',
    'BEST_SELLERS' = 'BEST_SELLERS',
    'GROUP_PURCHASING' = 'GROUP_PURCHASING',
    'PRESALE_PRODUCTS' = 'PRESALE_PRODUCTS',
    'ANNOUNCEMENT' = 'ANNOUNCEMENT',
    'BANNER' = 'BANNER',
    'PAGED_PRODUCT_CATEGORY' = 'PAGED_PRODUCT_CATEGORY',
    'PAGED_SHOPPING_CART' = 'PAGED_SHOPPING_CART',
    'PAGED_USER_CENTER' = 'PAGED_USER_CENTER',
    'FEATURE' = 'FEATURE',
    FOOTER = "FOOTER",
    'POST_LIST' = 'POST_LIST',
    PAGED_POSTS = "PAGED_POSTS",
    PAGED_POST_LIST = "PAGED_POST_LIST",
    PAGED_CATEGORY_PRODUCT = "PAGED_CATEGORY_PRODUCT",
    PAGED_PRODUCTS = "PAGED_PRODUCTS",
    PAGED_FORMS = "PAGED_FORMS",
    TEXT_LINKS = "TEXT_LINKS",
    IMAGE_GRID = "IMAGE_GRID",
    PULL_QUOTE = "PULL_QUOTE",
    MAIL_SUBSCRIPTION = "MAIL_SUBSCRIPTION",
}

