import { Directive, HostListener, Input, } from '@angular/core';

@Directive({
    selector: '[appPreventDefaultAndStopPropagation]',
})
export class appPreventDefaultAndStopPropagationDirective {
    @Input() preventDefault = true;
    @Input() stopPropagation = true;
    @Input() effectiveForClick = true;
    @Input() effectiveForMousedown = true;
    constructor() { }
    @HostListener('click', ['$event']) clickHandler(event) {
        if (this.effectiveForClick) {
            if (this.preventDefault) event.preventDefault();
            if (this.stopPropagation) event.stopPropagation();
        }
    }
    @HostListener('mousedown', ['$event']) onmousedown(event) {
        if (this.effectiveForMousedown) {
            if (this.preventDefault) event.preventDefault();
            if (this.stopPropagation) event.stopPropagation();
        }
    }
}
