import { Dayjs } from 'dayjs/esm';
import { jsonParse } from '../pipes/name-translate.pipe';

export enum LinkType {
    'PAGE' = 'PAGE',
    'URL' = 'URL',
    'MENU' = 'MENU',
}

export class Menu {
    id?: string;
    siteId?: string;
    name?: string;
    nameTranslation?: string;
    renderOptions?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    items?: MenuItem[];
    new?: boolean;
    constructor(menu?: Menu) {
        let menuData = menu;
        if (!menuData) {
            try {
                menuData = jsonParse(window?.localStorage?.getItem('ngx-webstorage|site-menus'));
            } catch (error) {

            }
        }
        if (!menuData) { return }
        for (const key in menuData) {
            if (key) {
                this[key] = menuData[key];
            }
        }
    }
}

export class MenuItem {
    id?: string;
    iconClassKey?: string;
    title?: string;
    titleTranslation?: string;
    // What type of link, e.g. PAGE or URL.
    linkType?: LinkType;
    // The id of the page, if linkType is PAGE.
    linkedPageId?: string;
    // Params of the linked page if any, in JSON format.
    linkedPageParams?: string;
    // The external url if linkType is URL.
    linkedUrl?: string;
    // Options of the link if any, in JSON format, e.g. if open in a new window etc.
    linkOptions?: string;
    enabled?: boolean;
    seqNum?: number;
    new?: boolean;
    renderOptions?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    menu?: MenuItem;
    parent?: MenuItem;
    children?: MenuItem[];
    linkedPageSlug?: string;
    constructor(menuItem?: MenuItem) {
        if (menuItem) {
            for (const key in menuItem) {
                if (key) {
                    this[key] = menuItem[key];
                }
            }
        }
    }
}

export const defaultMenus = {
    items: [{
        iconClassKey: 'Home',
        title: '首页',
        titleTranslation: JSON.stringify({ 'en-US': 'Home', ja: 'ホーム', 'zh-TW': '首頁', 'zh-CN': '首页' }),
        linkType: LinkType.PAGE,
        linkedPageId: undefined,
        seqNum: 0,
        linkedPageParams: JSON.stringify({ path: '/', builtIn: true, name: 'Home' }),
        enabled: true,
    }, {
        iconClassKey: 'Category',
        title: '分类',
        titleTranslation: JSON.stringify({ 'en-US': 'Category', ja: 'カテゴリー', 'zh-TW': '類別', 'zh-CN': '分类' }),
        linkType: LinkType.PAGE,
        linkedPageId: undefined,
        seqNum: 1,
        linkedPageParams: JSON.stringify({ path: 'product-categories', builtIn: true, name: 'Category' }),
        enabled: true,
    }, {
        iconClassKey: 'ShoppingCart',
        title: '购物车',
        titleTranslation: JSON.stringify({ 'en-US': 'Cart', ja: 'カート', 'zh-TW': '購物車', 'zh-CN': '购物车' }),
        linkType: LinkType.PAGE,
        linkedPageId: undefined,
        seqNum: 2,
        linkedPageParams: JSON.stringify({ path: 'shopping-cart', builtIn: true, name: 'ShoppingCart' }),
        enabled: true,
    }, {
        iconClassKey: 'User',
        title: '个人中心',
        titleTranslation: JSON.stringify({ 'en-US': 'User Center', ja: 'アカウントセンター', 'zh-TW': '個人中心', 'zh-CN': '个人中心' }),
        linkType: LinkType.PAGE,
        linkedPageId: undefined,
        seqNum: 4,
        linkedPageParams: JSON.stringify({ path: 'user-center', builtIn: true, name: 'UserCenter' }),
        enabled: true,
    },
    // {
    //     iconClassKey: 'Customerize',
    //     title: '定制',
    //     titleTranslation: JSON.stringify({ 'en-US': 'Customerize', ja: 'カスタマイズ', 'zh-TW': '定制', 'zh-CN': '定制' }),
    //     linkType: LinkType.PAGE,
    //     linkedPageId: undefined,
    //     seqNum: 4,
    //     linkedPageParams: JSON.stringify({ path: 'customized-card', builtIn: true }),
    //     enabled: false,
    // },
    {
        iconClassKey: 'News',
        title: '资讯',
        titleTranslation: JSON.stringify({ 'en-US': 'Posts', ja: 'ニュース', 'zh-TW': '資訊', 'zh-CN': '资讯' }),
        linkType: LinkType.PAGE,
        linkedPageId: undefined,
        seqNum: 3,
        linkedPageParams: JSON.stringify({ path: 'post/list', builtIn: true, name: 'PostList' }),
        enabled: false,
    },
    {
        iconClassKey: 'Feature',
        title: '特色',
        titleTranslation: JSON.stringify({ 'en-US': 'Featured Products', ja: '注目の商品', 'zh-TW': '特色商品', 'zh-CN': '特色商品' }),
        linkType: LinkType.PAGE,
        linkedPageId: undefined,
        seqNum: 5,
        linkedPageParams: JSON.stringify({ path: 'featured-products', builtIn: false, name: 'FeaturedProducts' }),
        enabled: false,
    }]
}
