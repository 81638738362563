import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
    selector: 'app-iframe-block',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class IframeComponent {
    @Input() blockId: string = '';
    @Input() set url(value: string) {
        if (value) {
            this._url = this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }
    };
    @Input() renderOptions: any = {};
    @Input() section: string;
    _url: SafeUrl;
    constructor(
        private router: Router,
        private sanitizer: DomSanitizer,
    ) { }
}
