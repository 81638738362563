export const accountManagementTranslate = {
    USER: {
        LIST_PAGE: {
            CUSTOMER_USER: 'Customer account',
            TEACHER_USER: 'Teacher account',
            ADD_BTN_TEXT: 'Add administrator account',
            QUERY_FORM: {
                LOGIN: {
                    TITLE: 'Account number',
                    PLACEHOLDER: 'Please enter the account number to query...',
                },
                FIRST_NAME: {
                    TITLE: 'User name',
                    PLACEHOLDER: 'Please enter the user name to query...',
                },
                MOBILE_PHONE: {
                    TITLE: 'Cell-phone number',
                    PLACEHOLDER: 'Please enter your mobile number to query...',
                },
                EMAIL: {
                    TITLE: 'Email',
                    PLACEHOLDER: 'Please enter the email for query...',
                },
            },
            TABLE: {
                TH: {
                    LOGIN: 'Login account',
                    FIRST_NAME: 'User name',
                    CONTACT: 'Contact information',
                    ACTIVATED: 'Activate or not',
                    PASSWORD_LOGIN_ENABLED: 'Password login',
                    MOBILE_PHONE_LOGIN_ENABLED: 'Mobile number login',
                    WECHAT_LOGIN_ENABLED: 'Wechat login',
                },
                TD: {
                    ACTIVATED_TITLE: 'Click to change the account activation status',
                    ACTIVATED: 'Activated',
                    NOT_ACTIVATED: 'Not active',
                    RESET_BTN_TEXT: 'Reset password'
                }
            },
            CHANGE_ACTIVE: {
                TITLE: 'Are you sure you want to change the activation status of the account "{{login}}" ?'
            },
            DELETE_USER: {
                TITLE: 'Are you sure you want to delete the account "{{login}}" ?'
            }
        },
        UPDATE_PAGE: {
            TITLE: '{{action}} account number',
            LOGIN: {
                TITLE: 'Login account',
                PLACEHOLDER: 'Please enter the login account...',
                REQUIRED_ERROR_MESSAGE: 'Account number cannot be empty!'
            },
            FIRST_NAME: {
                TITLE: 'User name',
                PLACEHOLDER: 'Enter one user name...',
            },
            PASSWORD: {
                TITLE: 'Password',
                PLACEHOLDER: 'Please input a password...',
                REQUIRED_ERROR_MESSAGE: 'Password cannot be empty!'
            },
            REPEAT_PASSWORD: {
                TITLE: 'Confirm password',
                PLACEHOLDER: 'Please enter the confirmation password...',
                REQUIRED_ERROR_MESSAGE: 'Confirm password cannot be empty!',
                REPEAT_ERROR_MESSAGE: 'The confirmation password must be consistent with the password!',
            },
            MOBILE_PHONE: {
                TITLE: 'Cell-phone number',
                PLACEHOLDER: 'Please enter your mobile number...',
            },
            EMAIL: {
                TITLE: 'Email',
                PLACEHOLDER: 'Please enter email...',
                EMAIL_ERROR_MESSAGE: 'The email format is incorrect!'
            },
            ACTIVATED: {
                TITLE: 'Activate or not'
            },
        },
        RESET_PASSWORD_PAGE: {
            TITLE: 'You are resetting the login password for the account "{{login}}"',
            PASSWORD: {
                TITLE: 'Password',
                PLACEHOLDER: 'Please input a password...',
                REQUIRED_ERROR_MESSAGE: 'Password cannot be empty!',
            },
            REPEAT_PASSWORD: {
                TITLE: 'Confirm password',
                PLACEHOLDER: 'Please enter the confirmation password...',
                REQUIRED_ERROR_MESSAGE: 'Confirm password cannot be empty!',
                REPEAT_ERROR_MESSAGE: 'The confirmation password must be consistent with the password!',
            }
        }
    },
}