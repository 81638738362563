<div class="d-inline-flex align-items-center user-profile-wrapper {{containerClass}}" style="height: 100%;"
    (mouseenter)="switchDropdownMenu(true);" (mouseleave)="switchDropdownMenu(false);"
    [ngClass]="{'flex-wrap flex-column-reverse':enableWrap}" [style.paddingTop]="enableWrap?'0':''">
    <span class="greetings" [ngClass]="{'text-center w-100':enableWrap}" [style.fontSize]="enableWrap?'12px':''"
        [style.paddingRight]="enableWrap?'9px':''" [style.paddingTop]="enableWrap?'1px':''"
        [style.marginLeft]="!enableMoreIcon?'.4rem':''">
        {{username || ('GENERAL.NONAME'|translate)}}
    </span>
    <div class="avatar-button" [style]="enableWrap?'position: relative; top: -2px;':''" (click)="consoleLog('clicked');switchDropdownMenu(!showDropdownMenu);">
        <img class="avatar-img" style="width: 2rem;height: 2rem;" [style.width]="avatarImgSize"
            [style.margin]="enableWrap?'0':''" [style.height]="avatarImgSize" appPureImgErrorIdentification
            [setErrorImg]="true" [loadFailedImage]="'/assets/icon/me-icon/my-icon.png'"
            [src]="(userAvatarUrl | uploadUrlPrefix) || '/assets/icon/me-icon/my-icon.png'" />
        <i class="iconfont icon-iconfront-" *ngIf="enableMoreIcon"></i>
    </div>
    <div class="account-menu b-r" [hidden]="!showDropdownMenu">
      <ul>
          <li [routerLink]="[ '/user-center' ]" (click)="consoleLog('click');switchDropdownMenu(false);"
              title="{{'INDEX.USER_CENTER' | translate}}" [appTranslate]="'INDEX.USER_CENTER'">
              个人中心</li>
          <li class="divider"></li>
          <li [routerLink]="[ '/user-center/orders' ]" (click)="switchDropdownMenu(false);" class="indented-menu-item"
              title="{{'INDEX.MY_ORDERS' | translate}}" [appTranslate]="'INDEX.MY_ORDERS'">
              我的订单</li>
          <li class="divider"></li>
          <li [routerLink]="[ '/user-center/gift-card' ]" (click)="switchDropdownMenu(false);" class="indented-menu-item"
              title="{{'INDEX.MY_GIFT_CARDS' | translate}}" [appTranslate]="'INDEX.MY_GIFT_CARDS'">
              我的礼品卡</li>
          <li class="divider"></li>
          <li [routerLink]="['/user-center/personal-info']" (click)="switchDropdownMenu(false);" class="indented-menu-item"
              title="{{'INDEX.PERSONAL_INFORMATION' | translate}}" [appTranslate]="'INDEX.PERSONAL_INFORMATION'">
              个人信息</li>
          <li class="divider"></li>
          <li [routerLink]="[ '/user-center/account-security' ]" (click)="switchDropdownMenu(false);" class="indented-menu-item"
              [appTranslate]="'INDEX.ACCOUNT_SECURITY'" title="{{'INDEX.ACCOUNT_SECURITY' | translate}}">
              账号安全</li>
          <li class="divider"></li>
          <li (click)="signOut()" (click)="switchDropdownMenu(false);" class="indented-menu-item"
              title="{{'INDEX.SIGN_OUT_BTN_TEXT' | translate}}" [appTranslate]="'INDEX.SIGN_OUT_BTN_TEXT'">
              退出登录</li>
      </ul>
    </div>
</div>
