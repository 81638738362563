import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { PullQuoteSetting } from './pull-quote.model';
declare const Swiper: any;

@Component({
    selector: 'app-pull-quote-block',
    templateUrl: './pull-quote.component.html',
    styleUrls: ['./pull-quote.component.scss']
})
export class PullQuoteBlockComponent {
    @Input() blockId: string = '';
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() data: PullQuoteSetting = {};
    @Input() section: string;
    @Input() set renderOptions(value: any) {
        if (value) {
            const tempValue = value?.container?.background || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._renderOptions = {
                ...value,
                container: {
                    ...value?.container,
                    'background-color': backgroundColor
                }
            };
        }
    }
    pullQuoteThumbs: any;
    _renderOptions: any = {};
    constructor(
        private router: Router,
    ) { }

    ngAfterViewInit(): void {
        setTimeout(() => {
            try {
                if (Swiper) {
                    this.pullQuoteThumbs = new Swiper('.pull-quote-thumbs-' + this.blockId, {
                        autoplay: true,//等同于以下设置
                        slidesPerView: 1,
                        loop: true,
                        resizeObserver: true,
                        cssMode: true,
                        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                        centeredSlides: true,
                        spaceBetween: 30,
                    });
                }
            } catch (error) {

            }
        }, 300);
    }

    slideToTargetIndex(index: number) {
        this.pullQuoteThumbs.slideToLoop(index, 300);
    }
}
