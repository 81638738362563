import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../core/services/parse-target-entity-config.service';
import { IDiyContentItem } from '../../core/model/mobile-config.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { tranformGradientColorStyle } from '../../core/model/color-config.model';

@Component({
    selector: 'app-image-grid-block',
    templateUrl: './image-grid.component.html',
    styleUrls: ['./image-grid.component.scss']
})
export class ImageGridComponent {
    @Input() blockId: string = '';
    @Input() title: string = '';
    @Input() titleTranslation = '';
    @Input() data = [];
    @Input() section: string;
    @Input() renderOptions: any = {};
    constructor(
        private router: Router,
        public currentDeviceService: CurrentDeviceService,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
    ) { }

    navigateLink(portfolioItem) {
        this.navigateToSomePageByDiyContentItem(portfolioItem);
    }

    navigateToSomePageByDiyContentItem(diyContentItemChild: IDiyContentItem) {
        this.parseTargetEntityConfigService.navigateLinkedPage(diyContentItemChild);
    }

    getImageResizeParams(portfolio: any) {
        if (this.currentDeviceService?.isDesktop()) {
            switch (Number(portfolio?.columns)) {
                case 1:
                    return this.renderOptions?.fullWidth ? 'h_926,w_926' : 'h_587,w_587';
                case 2:
                    return this.renderOptions?.fullWidth ? 'h_926,w_1852' : 'h_1175,w_587';
                case 3:
                    return this.renderOptions?.fullWidth ? 'h_926,w_2778' : 'h_1762.5,w_587';
                case 4:
                    return this.renderOptions?.fullWidth ? 'h_926,w_3704' : 'h_2350,w_587';
                default:
                    return this.renderOptions?.fullWidth ? 'h_926,w_926' : 'h_587,w_587';
            }
        } else {
            switch (Number(portfolio?.columns)) {
                case 1:
                    return 'h_694,w_694';
                case 2:
                    return 'h_1388,w_694';
                case 3:
                    return 'h_2082,w_694';
                case 4:
                    return 'h_2776,w_694';
                default:
                    return 'h_694,w_694';
            }
        }
    }

    getCurrentDeviceData(isMobile: boolean) {
        let key = isMobile ? 'itemsOfMobile' : 'itemsOfDesktop';
        return Array.isArray(this.data) ? this.data : this.data?.[key];
    }

    isTreeEqualsPart() {
        return Number(this.renderOptions?.columns) === 3;
    }

    getElStyle(portfolio?: any) {
        if (jsonParse(this.renderOptions)?.['item-title']?.enabled === false) {
            return 'display:none!important;';
        }
        let tempData = jsonParse(portfolio);
        let options = (tempData?.titleRenderType === 'CUSTOMIZE_SETTING') ? tempData?.titleRenderOptions : jsonParse(this.renderOptions)?.['item-title'];
        let colorData = ((options?.color !== "CUSTOMIZE") && options?.color) ? options?.color : options?.customizeColorValue;
        try {
            colorData = JSON.parse(colorData);
        } catch (error) {
            colorData = colorData?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(colorData);
        return `letter-spacing: ${options?.['letter-spacing'] || 0}px;
        ${gradientColor ? `background-image: ${colorData};color: ${jsonParse(colorData)?.data?.[0]?.color};background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;`
                : `color:${colorData};`}`;
    }

    getElFontSizeScale(portfolio?: any) {
        if (jsonParse(this.renderOptions)?.['item-title']?.enabled === false) {
            return 100;
        }
        let tempData = jsonParse(portfolio);
        let options = (tempData?.titleRenderType === 'CUSTOMIZE_SETTING') ? tempData?.titleRenderOptions : jsonParse(this.renderOptions)?.['item-title'];
        return options?.['font-size'];
    }

    calcItemWidth(portfolio?: any) {
        return (100 / (
            (!this.currentDeviceService?.isDesktop() && (Number(this.renderOptions?.columns) === 4))
                ? 2 : ((Number(this.renderOptions?.columns) || 3) / (Number(portfolio?.columns) || 1))
        )
        ) + '%';
    }
}
