export const systemSettingTranslate = {
    WECHAT_OPEN_CONFIG: {
        APP_ID: {
            PLACEHOLDER: '請輸入app id…',
            REQUIRED_ERROR_MESSAGE: 'App id不能為空！',
        },
        APP_SECRET: {
            PLACEHOLDER: '請輸入app secret…',
            REQUIRED_ERROR_MESSAGE: 'AppSecret不能为空！',
        },
        SUCCESS_MESSAGE: '保存成功！',
        ERROR_MESSAGE: '保存失敗！',
    },
    WECHAT_MP_CONFIG: {
        APP_ID: {
            PLACEHOLDER: '請輸入app id…',
            REQUIRED_ERROR_MESSAGE: 'App id不能為空！',
        },
        APP_SECRET: {
            PLACEHOLDER: '請輸入app secret…',
            REQUIRED_ERROR_MESSAGE: 'AppSecret不能为空！',
        },
        SUCCESS_MESSAGE: '保存成功！',
        ERROR_MESSAGE: '保存失敗！',
    }
};