<ng-container *ngTemplateOutlet="siteRrandingRef;context:{block:block}"></ng-container>

<ng-template #siteRrandingRef let-block="block">
    <div class="site-branding" *ngIf="(data?.headerType || renderOptions?.headerType) !== 'NONE'" [ngClass]="{
        'banner-header':((data?.headerType || renderOptions?.headerType)==='BANNER'),
        'cover-header':((data?.headerType || renderOptions?.headerType)==='COVER'),
        'large-banner-header':((data?.headerType || renderOptions?.headerType)==='LARGE_BANNER'),
        'title-only-header':((data?.headerType || renderOptions?.headerType)==='TITLE_ONLY')}"
        style="box-sizing: border-box;">
        <ng-container
            *ngIf="!(((data?.headerType || renderOptions?.headerType)==='NONE')
            ||!(data?.headerType || renderOptions?.headerType) || ((data?.headerType || renderOptions?.headerType) ==='TITLE_ONLY'))">
            <div class="header-background-drop" [ngClass]="{
            'none-filter-mode':!(data || renderOptions)?.background?.filterType||((data || renderOptions)?.background?.filterType === FilterTypeEnum?.NONE),
            'blur-mode':(data || renderOptions)?.background?.filterType === FilterTypeEnum?.BLUR,
            'darken-mode':(data || renderOptions)?.background?.filterType === FilterTypeEnum?.DARKEN
        }" [style.backgroundImage]="getBackgroundImageUrl(
            ((urlHelperService?.isVideo(data?.background?.data || renderOptions?.background?.data)&&videoAutoPlayEnabled())?
            (data?.background?.data || renderOptions?.background?.data) : (data?.background?.videoBanner || renderOptions?.background?.videoBanner || data?.background?.data || renderOptions?.background?.data)
            ) | uploadUrlPrefix)">
            </div>
            <div class="header-background-drop" [ngClass]="{
                'none-filter-mode':!(data || renderOptions)?.background?.filterType||((data || renderOptions)?.background?.filterType === FilterTypeEnum?.NONE),
                'blur-mode':(data || renderOptions)?.background?.filterType === FilterTypeEnum?.BLUR,
                'darken-mode':(data || renderOptions)?.background?.filterType === FilterTypeEnum?.DARKEN
            }">
                <video appBricsVideoAutoPlay name="media" x-webkit-airplay="allow" muted loop autoplay="autoplay"
                    preload="auto" playsinline="true" webkit-playsinline="true" mtt-playsinline="true"
                    x5-video-player-type="h5-page" class="w-100 h-100 video-bg" [poster]="data?.background?.videoBanner"
                    *ngIf="urlHelperService?.isVideo(data?.background?.data || renderOptions?.background?.data)&&videoAutoPlayEnabled()"
                    [playSrc]="data?.background?.data || renderOptions?.background?.data" playsinline="true"
                    x5-playsinline="true">
                    <source type="video/mp4; codecs=&quot;avc1.4D401E, mp4a.40.2&quot;"
                        [src]="(data?.background?.data || renderOptions?.background?.data) | uploadUrlPrefix"
                        group="banner">
                </video>
            </div>
        </ng-container>
        <div class="w-100 px-3 text-center header-title d-flex flex-wrap align-items-center justify-content-center flex-column transform-y-center"
            style="box-sizing: border-box;" [ngClass]="{
            'has-sub-title':(data?.subHeader || renderOptions?.subHeader) | mTitleTranslate,
            'position-bottom':((data || renderOptions)?.navigation?.position?.bottom === 0)
        }"
            [style]="(((data || renderOptions)?.navigation?.position?.bottom === 0) ? 'margin-bottom' : 'margin-top') + navigationHeight + 'px'">
            <h1 class="header-main-title fw-bolder text-capitalize mb-0 w-100"
                [style]="getElStyle(data?.header?.renderOptions)"
                [appFontSizeScale]="getElFontSizeScale(data?.header?.renderOptions)">
                {{(data || renderOptions)?.header |
                mTitleTranslate}}
            </h1>
            <h3 class="header-sub-title pt-3 mb-0" [style]="getElStyle(data?.subHeader?.renderOptions)"
                [appFontSizeScale]="getElFontSizeScale(data?.subHeader?.renderOptions)"
                *ngIf="(data?.subHeader || renderOptions?.subHeader) | mTitleTranslate">{{
                (data?.subHeader || renderOptions?.subHeader) | mTitleTranslate}}</h3>
        </div>
    </div>
</ng-template>