import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerBlockComponent } from './banner/banner.component';
import { BlockContainerComponent } from './block-container/block-container.component';
import { CarouselBlockComponent } from './carousel/carousel.component';
import { FormsBlockComponent } from './forms/forms.component';
import { HeaderComponent } from './header/header.component';
import { HomeAnnouncementBlockComponent } from './home-announcement/home-announcement.component';
import { IconBoxesBlockComponent } from './icon-boxes/icon-boxes.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PostsBlockComponent } from './posts/posts.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SiteBrandingComponent } from './site-branding/site-branding.component';
import { TextLinksComponent } from './text-links/text-links.component';
import { TranslationModule } from '../../i18n/translation.module';
import { DirectivesModule } from '../core/directives/directives.module';
import { SharedModule } from '../shared/shared.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { ImageGridComponent } from './image-grid/image-grid.component';
import { IframeComponent } from './iframe/iframe.component';
import { FooterBlockComponent } from './footer/footer.component';
import { PullQuoteBlockComponent } from './pull-quote/pull-quote.component';
import { FeatureListBlockComponent } from './feature-list/feature-list.component';
import { MailSubscriptionComponent } from './mail-subscribe/mail-subscribe.component';
import { GraphicPortfolioBlockComponent } from './graphic-portfolio/graphic-portfolio.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ng-zorro-antd-mobile';

@NgModule({
    declarations: [
        BannerBlockComponent,
        BlockContainerComponent,
        CarouselBlockComponent,
        FormsBlockComponent,
        HeaderComponent,
        HomeAnnouncementBlockComponent,
        IconBoxesBlockComponent,
        MenuComponent,
        NavigationComponent,
        PostsBlockComponent,
        SiteBrandingComponent,
        SideBarComponent,
        TextLinksComponent,
        ImageGridComponent,
        IframeComponent,
        FooterBlockComponent,
        PullQuoteBlockComponent,
        FeatureListBlockComponent,
        MailSubscriptionComponent,
        GraphicPortfolioBlockComponent,
    ],
    imports: [
        CommonModule,
        TranslationModule,
        DirectivesModule,
        SharedModule,
        SharedComponentsModule,
        RouterModule,
        NgbDropdownModule,
        CarouselModule,
    ],
    exports: [
        BannerBlockComponent,
        BlockContainerComponent,
        CarouselBlockComponent,
        FormsBlockComponent,
        HeaderComponent,
        HomeAnnouncementBlockComponent,
        IconBoxesBlockComponent,
        MenuComponent,
        NavigationComponent,
        PostsBlockComponent,
        SiteBrandingComponent,
        SideBarComponent,
        TextLinksComponent,
        CommonModule,
        ImageGridComponent,
        IframeComponent,
        FooterBlockComponent,
        PullQuoteBlockComponent,
        FeatureListBlockComponent,
        MailSubscriptionComponent,
        GraphicPortfolioBlockComponent,
    ]
})
export class CommonPageBlocksModule { }
