import { BrowserModule, Title, provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { routes } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';

// 拦截器
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { InterceptorService } from './core/interceptor/InterceptorService';  // token失效拦截
import { APP_BASE_HREF } from '@angular/common';
import { ErrorHandlerInterceptor } from './core/interceptor/errorhandler.interceptor';

// CDK试用拖动
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationModule } from '../i18n';
import { DirectivesModule } from './core/directives/directives.module';
import { SharedModule } from './shared/shared.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { CommonPageBlocksModule } from './common-page-blocks/common-page-blocks.module';
import { EcommerceSharedModule } from './ecommerce/shared/ecommerce-shared.module';
import { RouterModule, provideRouter } from '@angular/router';
import { ToastModule } from 'ng-zorro-antd-mobile';
import { SiteContextService } from './shared/services/site-context.service';
import { ShopContextService } from './ecommerce/shared/service/shop-context.service';
import { firstValueFrom, forkJoin, lastValueFrom } from 'rxjs';

@NgModule({
    imports: [
        BrowserModule,
        DragDropModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NgxWebstorageModule.forRoot(),
        TranslateModule.forRoot(),
        SharedModule,
        SharedComponentsModule,
        TranslationModule,
        DirectivesModule,
        CommonPageBlocksModule,
        EcommerceSharedModule,
        RouterModule.forRoot(routes,{initialNavigation: 'enabledNonBlocking'}),
    ],
    declarations: [
        AppComponent,
            ],
    providers: [
        provideClientHydration(withHttpTransferCacheOptions({ includeHeaders: [
            'brics-api-success-notify',
            'brics-api-error-notify',
            'X-Site-Id',
            'X-Total-Count',
            'x-total-count'
        ]})),
        provideRouter(routes),
        provideHttpClient(withFetch()),
        ToastModule,
        Title,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        {
            provide: APP_BASE_HREF,
            useValue: ''
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [SiteContextService, ShopContextService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function initializeApp(siteContextService: SiteContextService, shopContextService: ShopContextService) {
    return () => siteContextService.initialize()
    .then(() => shopContextService.initialize().then()
    );
}
