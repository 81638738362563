import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { IProduct } from '../model/product.model';
import { API_SERVICE_PREFIX } from '../../../app.constants';
import { SiteContextService } from '../../../shared/services/site-context.service';
import { ShopContextService } from './shop-context.service';

type EntityResponseType = HttpResponse<IProduct>;
type EntityArrayResponseType = HttpResponse<IProduct[]>;

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
@Injectable({ providedIn: 'root' })
export class ProductService {
    shopId: number;
    private resourceUrl = API_SERVICE_PREFIX + '/store/api/products';

    getShopResourceUrl() {
        return API_SERVICE_PREFIX + '/store/api/shop/' + this.shopContextService.shopId + '/products';
    }

    getProductsBySaleResourceUrl() {
        return API_SERVICE_PREFIX + '/store/api/products/by-statistics';
    }

    constructor(
        private http: HttpClient,
        public shopContextService: ShopContextService,
        private siteContextService: SiteContextService,
    ) {
    }

    create(product: IProduct): Observable<EntityResponseType> {
        return this.http
            .post<IProduct>(this.getShopResourceUrl(), product, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(product: IProduct): Observable<EntityResponseType> {
        return this.http
            .put<IProduct>(this.getShopResourceUrl(), product, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: number): Observable<IProduct> {
        return this.http
            .get<IProduct>(`${this.resourceUrl}/${id}`);
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        if (this.shopContextService.shopSetting?.hideSoldOutProduct) {
            req = {
                'stock.greaterThanOrEqual': 0,
                ...req
            };
        }
        return this.http
            .get<IProduct[]>(this.getShopResourceUrl(), {
                params: req,
                observe: 'response',
                headers: headerOptions
            })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    queryProducts(req?: any): Observable<EntityArrayResponseType> {
        if (this.shopContextService.shopSetting?.hideSoldOutProduct) {
            req = {
                'stock.greaterThanOrEqual': 0,
                ...req
            };
        }
        return this.http
            .get<IProduct[]>(this.resourceUrl, {
                params: req,
                observe: 'response',
                headers: headerOptions
            })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    queryAndStatistics(req?: any): Observable<EntityArrayResponseType> {
        if (this.shopContextService.shopSetting?.hideSoldOutProduct) {
            req = {
                'stock.greaterThanOrEqual': 0,
                ...req
            };
        }
        return this.http
            .get<IProduct[]>(`${this.getShopResourceUrl()}/with-statistics`, {
                params: req,
                observe: 'response',
                headers: headerOptions
            })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    getProducts(req?: any): Observable<HttpResponse<IProduct[]>> {
        if (this.shopContextService.shopSetting?.hideSoldOutProduct) {
            req = {
                'stock.greaterThanOrEqual': 0,
                ...req
            };
        }
        return this.http
            .get<IProduct[]>(this.getShopResourceUrl(), {
                params: req
                , observe: 'response',
                headers: headerOptions
            });
    }

    getProductsBySales(req?: any): Observable<HttpResponse<IProduct[]>> {
        return this.http
            .get<IProduct[]>(this.getProductsBySaleResourceUrl(), {
                params: req,
                observe: 'response',
                headers: headerOptions
            });
    }
    getProductsByCategoryIds(req?: any): Observable<HttpResponse<any>> {
        if (this.shopContextService.shopSetting?.hideSoldOutProduct) {
            req = {
                'stock.greaterThanOrEqual': 0,
                ...req
            };
        }
        return this.http
            .get<any>(API_SERVICE_PREFIX + `/store/api/shop/${this.shopContextService.shopId}/products/by-CategoryIds`, {
                params: req,
                observe: 'response',
                headers: headerOptions
            });
    }
}
