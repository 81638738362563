import { ArticleStyle } from "../../../core/model/mobile-config.model";

export enum PostMimeTypeEnum {
    STANDARD = 'STANDARD'
}

export enum PostStatusEnum {
    AUTO_DRAFT = 'AUTO_DRAFT',
    DRAFT = 'DRAFT',
    PUBLISH = 'PUBLISH'
}

export enum PostVisibilityEnum {
    PASSWORD_PROTECTED = 'PASSWORD_PROTECTED',
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC'
}

export class PostConfig {
    enableRelatedPostEntry: boolean = true;
    enableTitleForDetail: boolean = true;
    enableCategoryForDetail: boolean = true;
    enableTagForDetail: boolean = true;
    type: ArticleStyle = ArticleStyle.CARD;
    recursion(container, content) {
        for (const key in content) {
            if (typeof (container[key]) === 'object' && !container[key].hasOwnProperty('length')) {
                this.recursion(container[key], content[key]);
            } else {
                if (key === 'version') {
                    return;
                }
                container[key] = content[key];
            }
        }
    }
    constructor(postConfig?: PostConfig) {
        if (postConfig) {
            this.recursion(this, postConfig);
        }
    }
}

export interface IPost {
    authorName?: string;
    authorUserId?: number;
    commentCount?: number;
    content?: string;
    createdBy?: string;
    createdDate?: string;
    featuredImage?: string;
    guid?: string;
    id?: number;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    mimeType?: PostMimeTypeEnum;
    publishDate?: string;
    status?: PostStatusEnum;
    summary?: string;
    title?: string;
    visibility?: PostVisibilityEnum;
    categories?: any[];
    renderOptions?: string;
}

export class Post implements IPost {
    authorName?: string;
    authorUserId?: number;
    commentCount?: number;
    content?: string;
    createdBy?: string;
    createdDate?: string;
    featuredImage?: string;
    guid?: string;
    id?: number;
    lastModifiedBy?: string;
    lastModifiedDate?: string;
    mimeType?: PostMimeTypeEnum;
    publishDate?: string;
    status?: PostStatusEnum;
    summary?: string;
    title?: string;
    visibility?: PostVisibilityEnum;
    categories?: any[];
    renderOptions?: string;
    constructor(wxOpenPlatformConfig?: IPost) {
        if (wxOpenPlatformConfig) {
            for (const key in wxOpenPlatformConfig) {
                if (key) {
                    this[key] = wxOpenPlatformConfig[key];
                }
            }
        }
    }
}
