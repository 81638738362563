import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../../../../app.constants';
import { ICustomerCoupon } from '../_model/customer-coupon.model';
import { ICoupon } from '../../../../../ecommerce/shared/model/coupon.model';
import { map, mergeMap, take } from 'rxjs/operators';
import { ShopContextService } from '../../../../../ecommerce/shared/service/shop-context.service';

type CustomerCouponArrayResponseType = HttpResponse<ICustomerCoupon[]>;

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
@Injectable({ providedIn: 'root' })
export class CustomerCouponService {
    public customerCouponUrl = API_SERVICE_PREFIX + '/customer/api/customer-coupons';

    constructor(
        private http: HttpClient,
        private shopContextService: ShopContextService,
    ) { }

    createCustomerCoupon(couponIds: number[]) {
        const shopId = this.shopContextService.shopId;
        return this.http.post(`${API_SERVICE_PREFIX}/customer/api/shop/${shopId}/customer-coupons`, couponIds, { responseType: 'text' });
    }

    customerCouponQuery(req?: any): Observable<CustomerCouponArrayResponseType> {
        return this.http
            .get<ICustomerCoupon[]>(`${this.customerCouponUrl}/customer`, { params: req, observe: 'response' });
    }

    customerRegisterNotReceivedCouponQuery(req?: any): Observable<HttpResponse<ICoupon[]>> {
        const shopId = this.shopContextService.shopId;
        return this.http
            .get<ICoupon[]>(`${API_SERVICE_PREFIX}/store/api/shop/${shopId}/coupons/register-not-received`,
                {
                    params: req,
                    observe: 'response',
                    headers: headerOptions
                }
            );
    }
}
