import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../../app.constants';

type EntityResponseType = HttpResponse<any>;
type EntityArrayResponseType = HttpResponse<any[]>;
@Injectable({ providedIn: 'root' })
export class GroupBuyingCampaignsService {
    private resourceUrl = API_SERVICE_PREFIX + '/store/api/group-buying-campaigns/public';

    constructor(
        private http: HttpClient,
    ) {
    }
    query(req?: any, notifyEnable?: boolean): Observable<EntityArrayResponseType> {
        const headerOptions = new HttpHeaders({
            'brics-api-error-notify': notifyEnable ? 'true' : 'false',
            'brics-api-success-notify': notifyEnable ? 'true' : 'false'
        });
        return this.http
            .get<any[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseType) => res));
    }
    find(id: number): Observable<EntityResponseType> {
        return this.http
            .get<any>(`${this.resourceUrl}/${id}`, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }
}
