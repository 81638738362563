<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation" *ngIf="forms?.length"
    [titleClass]="'share-group-buying-tit title-bottom forms-title'" [renderOptions]="renderOptions"
    [enabledMoreBtn]="false" [enabledTitle]="titleRenderOption?.display !== 'none'" [section]="section">
    <div class="forms-container">
        <div class="forms-content" [ngClass]="{'single-form-content':forms?.length === 1}">
            <!-- 多个表单 -->
            <ng-container *ngIf="forms?.length > 1">
                <div class="d-inline-flex p-3 bg-white b-r overflow-hidden align-items-center pr-4 form-item"
                    *ngFor="let formItem of forms" [routerLink]="['/forms',formItem?.formId]"
                    style="box-sizing: border-box;margin-right: 1rem;">
                    <div class="d-flex align-items-center w-100 item-card" title="{{formItem?.title}}">
                        <div class="forms-icon-box">
                            <i [class]="'iconfont forms-icon ' + formItem?.icon"
                                [style]="'color:' + formItem?.color + ';'"></i>
                        </div>
                        <h4 class="mb-0 px-2 item-tit">{{formItem?.title}}</h4>
                    </div>
                    <i class="icon-xiangyou iconfont"></i>
                </div>
            </ng-container>
            <!-- 单个表单 -->
            <ng-container *ngIf="forms?.length === 1">
                <div class="d-flex justify-content-between p-3 bg-white b-r overflow-hidden align-items-center pr-4 w-100 mr-0 mb-0 form-item"
                    *ngIf="forms[0] as formItem;" [routerLink]="['/forms',formItem?.formId]"
                    style="box-sizing: border-box;">
                    <div class="d-flex align-items-center overflow-hidden w-75 item-card" title="{{formItem?.title}}">
                        <div class="forms-icon-box">
                            <i [class]="'iconfont forms-icon ' + formItem?.icon"></i>
                        </div>
                        <h4 class="mb-0 px-2 item-tit">{{formItem?.title}}</h4>
                    </div>
                    <div data-skeleton-bgcolor="#eee" class="apply-form-btn"> {{'GENERAL.FILL_OUT' | translate}} </div>
                </div>
            </ng-container>
        </div>
    </div>
</app-block-container>