import { Dayjs } from 'dayjs/esm';
import { PageBlock } from "./page-block.model";

export class Page {
    constructor(page?: Page) {
        if (page) {
            for (const key in page) {
                if (key) {
                    this[key] = page[key];
                }
            }
        }
    }
    id?: string;
    siteId: string;
    name: string;
    nameTranslation?: string;
    title?: string;
    /**
     * The i18n version titles, in JSON format: { "en": "Carousel", "zh-CN": "轮播图" }
     */
    titleTranslation?: string;
    // 排序数
    seqNum: number;
    // The URL-friendly name, for route composition
    slug?: string;
    layoutId: string;
    // 是否是内置的页面
    builtIn: boolean;
    renderOptions?: string;
    createdBy?: string;
    createdDate?: Dayjs;
    lastModifiedBy?: string;
    lastModifiedDate?: Dayjs;
    blocks?: PageBlock[];
    new?: boolean;
}

export const defaultHomePage = {
    "name": "Home",
    "nameTranslation": null,
    "title": "首页",
    "titleTranslation": null,
    "slug": "home",
    "seqNum": 0,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "ANNOUNCEMENT",
        "enabled": true,
        "title": "店铺公告",
        "titleTranslation": "{\"zh-TW\":\"店鋪公告\",\"en-US\":\"Shop announcement\",\"ja\":\"ショプお知らせ\", \"zh-CN\": \"店铺公告\"}",
        "data": "{}",
        "seqNum": 2,
        "builtIn": false,
        "renderOptions": "{\"container\":{\"padding-top\":\"1.5rem\",\"padding-bottom\":\"0\"},\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "GROUP_PURCHASING",
        "enabled": true,
        "title": "拼团商品",
        "titleTranslation": "{\"zh-TW\":\"拼團商品\",\"en-US\":\"Group-buy products\",\"ja\":\"グループ購入商品\", \"zh-CN\": \"拼团商品\"}",
        "data": "null",
        "seqNum": 5,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "POST_LIST",
        "enabled": true,
        "title": "文章列表",
        "titleTranslation": "{\"zh-TW\":\"文章清單\",\"en-US\":\"Post List\",\"ja\":\"記事のリスト\", \"zh-CN\": \"文章列表\"}",
        "data": "{\"type\":\"post_category\",\"describe\":\"\"}",
        "seqNum": 4,
        "builtIn": false,
        "renderOptions": "{\"enableLike\":true,\"enableComment\":true,\"enableRelatedPostEntry\":true,\"enablePublishAuthorAndDate\":false,\"enableTitleForDetail\":true,\"enableBannerForDetail\":true,\"enableCategoryForDetail\":true,\"enableTagForDetail\":true,\"type\":\"CARD\",\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "SHOP_CAROUSEL",
        "enabled": true,
        "title": "店铺轮播图",
        "titleTranslation": "{\"zh-TW\":\"店鋪輪播圖\",\"en-US\":\"Shop carousel\",\"ja\":\"店舗カルーセル\", \"zh-CN\": \"店铺轮播图\"}",
        "data": JSON.stringify({
            defaultData: [
                {
                    "itemUrl": "/assets/img/default-carousels/blue.jpeg",
                }
            ]
        }),
        "seqNum": 1,
        "builtIn": false,
        "renderOptions": "{\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "SERIES_PRODUCTS",
        "enabled": true,
        "title": "系列商品",
        "titleTranslation": "{\"zh-TW\":\"系列商品\",\"en-US\":\"Series products\",\"ja\":\"シリーズ商品\", \"zh-CN\": \"系列商品\"}",
        "data": "null",
        "seqNum": 7,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "LABEL_PRODUCTS",
        "enabled": true,
        "title": "标签商品",
        "titleTranslation": "{\"zh-TW\":\"標籤商品\",\"en-US\":\"Label products\",\"ja\":\"ラベル商品\", \"zh-CN\": \"标签商品\"}",
        "data": "null",
        "seqNum": 8,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "HEADER",
        "blockType": "HEADER",
        "enabled": true,
        "title": "站点导航",
        "titleTranslation": "{\"zh-TW\":\"站點導航\",\"en-US\":\"Navigation\",\"ja\":\"ナビゲーション\", \"zh-CN\": \"站点导航\"}",
        "data": "null",
        "seqNum": 0,
        "builtIn": false,
        "renderOptions": "{\"logoVisible\":true,\"searchVisible\":true,\"container\":{\"padding-top\":\"1rem\",\"padding-bottom\":\"1rem\"},\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"0\",\"padding-bottom\":\"0\"},\"navigation\": {\"position\":{ \"top\": \"0\"}}}",
    }, {
        "section": "CONTENT",
        "blockType": "NEW_ARRIVAL",
        "enabled": true,
        "title": "最新商品",
        "titleTranslation": "{\"zh-TW\":\"最新商品\",\"en-US\":\"New arrivals\",\"ja\":\"新着商品\", \"zh-CN\": \"最新商品\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "BEST_SELLERS",
        "enabled": true,
        "title": "热卖商品",
        "titleTranslation": "{\"zh-TW\":\"熱賣商品\",\"en-US\":\"Best sellers\",\"ja\":\"人気商品\", \"zh-CN\": \"热卖商品\"}",
        "data": "null",
        "seqNum": 6,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultCategoryPage = {
    "name": "Category",
    "nameTranslation": null,
    "title": "分类",
    "titleTranslation": "{\"zh-TW\":\"分類\",\"en-US\": \"Category\",\"ja\": \"分類\", \"zh-CN\": \"分类\"}",
    "slug": 'product-categories',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_PRODUCT_CATEGORY",
        "enabled": true,
        "title": "分类",
        "titleTranslation": "{\"zh-TW\":\"分類\",\"en-US\": \"Category\",\"ja\": \"分類\", \"zh-CN\": \"分类\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultUserCenterPage = {
    "name": "UserCenter",
    "nameTranslation": null,
    "title": "个人中心",
    "titleTranslation": "{\"zh-TW\":\"個人中心\",\"en-US\": \"User Center\",\"ja\": \"アカウントセンター\", \"zh-CN\": \"个人中心\"}",
    "slug": 'user-center',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_USER_CENTER",
        "enabled": true,
        "title": "个人中心",
        "titleTranslation": "{\"zh-TW\":\"個人中心\",\"en-US\": \"User Center\",\"ja\": \"アカウントセンター\", \"zh-CN\": \"中心\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultShoppingCartPage = {
    "name": "ShoppingCart",
    "nameTranslation": null,
    "title": "购物车",
    "titleTranslation": "{\"zh-TW\":\"購物車\",\"en-US\": \"ShoppingCart\",\"ja\": \"カート\", \"zh-CN\": \"购物车\"}",
    "slug": 'shopping-cart',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_SHOPPING_CART",
        "enabled": true,
        "title": "购物车",
        "titleTranslation": "{\"zh-TW\":\"購物車\",\"en-US\": \"ShoppingCart\",\"ja\": \"カート\", \"zh-CN\": \"购物车\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultPostListPage = {
    "name": "PostList",
    "nameTranslation": null,
    "title": "文章列表",
    "titleTranslation": "{\"zh-TW\":\"文章清單\",\"en-US\": \"Post List\",\"ja\": \"記事のリスト\", \"zh-CN\": \"文章列表\"}",
    "slug": "post/list",
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_POST_LIST",
        "enabled": true,
        "title": "文章列表",
        "titleTranslation": "{\"zh-TW\":\"文章清單\",\"en-US\": \"Post List\",\"ja\": \"記事のリスト\", \"zh-CN\": \"文章列表\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"enableLike\":true,\"enableComment\":true,\"enableRelatedPostEntry\":true,\"enablePublishAuthorAndDate\":false,\"enableTitleForDetail\":true,\"enableBannerForDetail\":true,\"enableCategoryForDetail\":true,\"enableTagForDetail\":true,\"type\":\"CARD\",\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultFeaturePage = {
    "name": "FeaturedProducts",
    "nameTranslation": null,
    "title": "特色",
    "titleTranslation": "{\"zh-TW\":\"特色商品\",\"en-US\": \"Featured Products\",\"ja\": \"注目の商品\", \"zh-CN\": \"特色商品\"}",
    "slug": 'featured-products',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": false,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "SERIES_PRODUCTS",
        "enabled": true,
        "title": "系列商品",
        "titleTranslation": "{\"zh-TW\":\"系列商品\",\"en-US\":\"Series products\",\"ja\":\"シリーズ商品\", \"zh-CN\": \"系列商品\"}",
        "data": "null",
        "seqNum": 7,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }, {
        "section": "CONTENT",
        "blockType": "LABEL_PRODUCTS",
        "enabled": true,
        "title": "标签商品",
        "titleTranslation": "{\"zh-TW\":\"標籤商品\",\"en-US\":\"Label products\",\"ja\":\"ラベル商品\", \"zh-CN\": \"标签商品\"}",
        "data": "null",
        "seqNum": 8,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultPostsPage = {
    "name": "PostCategory",
    "nameTranslation": null,
    "title": "文章列表",
    "titleTranslation": "{\"zh-TW\":\"文章清單\",\"en-US\": \"Post List\",\"ja\": \"記事のリスト\", \"zh-CN\": \"文章列表\"}",
    "slug": "",
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_POST_LIST",
        "enabled": true,
        "title": "文章列表",
        "titleTranslation": "{\"zh-TW\":\"文章清單\",\"en-US\": \"Post List\",\"ja\": \"記事のリスト\", \"zh-CN\": \"文章列表\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"enableLike\":true,\"enableComment\":true,\"enableRelatedPostEntry\":true,\"enablePublishAuthorAndDate\":false,\"enableTitleForDetail\":true,\"enableBannerForDetail\":true,\"enableCategoryForDetail\":true,\"enableTagForDetail\":true,\"type\":\"CARD\",\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultPostPage = {
    "name": "Post",
    "nameTranslation": null,
    "title": "文章详情",
    "titleTranslation": "{\"zh-TW\":\"文章詳情\",\"en-US\": \"Post Detail\",\"ja\": \"記事の詳細\", \"zh-CN\": \"文章详情\"}",
    "slug": "",
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_POSTS",
        "enabled": true,
        "title": "资讯详情",
        "titleTranslation": "{\"zh-TW\":\"資訊詳情\",\"en-US\": \"Post Detail\",\"ja\": \"記事の詳細\", \"zh-CN\": \"文章详情\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"enableLike\":true,\"enableComment\":true,\"enableRelatedPostEntry\":true,\"enablePublishAuthorAndDate\":false,\"enableTitleForDetail\":true,\"enableBannerForDetail\":true,\"enableCategoryForDetail\":true,\"enableTagForDetail\":true,\"type\":\"CARD\",\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultProductCategoryPage = {
    "name": "ProductCategory",
    "nameTranslation": null,
    "title": "商品分类",
    "titleTranslation": "{\"zh-TW\":\"商品分類\",\"en-US\": \"Product Category\",\"ja\": \"カテゴリー\", \"zh-CN\": \"商品分类\"}",
    "slug": '',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_CATEGORY_PRODUCT",
        "enabled": true,
        "title": "商品分类",
        "titleTranslation": "{\"zh-TW\":\"商品分類\",\"en-US\": \"Product Category\",\"ja\": \"カテゴリー\", \"zh-CN\": \"商品分类\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultProductPage = {
    "name": "Product",
    "nameTranslation": null,
    "title": "商品详情",
    "titleTranslation": "{\"zh-TW\":\"商品詳情\",\"en-US\": \"Product Detail\",\"ja\": \"商品詳細\", \"zh-CN\": \"商品详情\"}",
    "slug": '',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_PRODUCTS",
        "enabled": true,
        "title": "商品详情",
        "titleTranslation": "{\"zh-TW\":\"商品詳情\",\"en-US\": \"Product Detail\",\"ja\": \"商品詳細\", \"zh-CN\": \"商品详情\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}

export const defaultFormPage = {
    "name": "Form",
    "nameTranslation": null,
    "title": "智能表单",
    "titleTranslation": "{\"zh-TW\":\"智能表單\",\"en-US\": \"Smart Forms\",\"ja\": \"スマートフォーム\", \"zh-CN\": \"智能表单\"}",
    "slug": '',
    "seqNum": 1,
    "layoutId": "DEFAULT",
    "builtIn": true,
    "renderOptions": null,
    blocks: [{
        "section": "CONTENT",
        "blockType": "PAGED_FORMS",
        "enabled": true,
        "title": "智能表单",
        "titleTranslation": "{\"zh-TW\":\"智能表單\",\"en-US\": \"Smart Forms\",\"ja\": \"スマートフォーム\", \"zh-CN\": \"智能表单\"}",
        "data": "null",
        "seqNum": 3,
        "builtIn": false,
        "renderOptions": "{\"title\":{\"display\":\"block\",\"text-align\":\"left\",\"padding-top\":\"1.5rem\",\"padding-bottom\":\"1rem\"},\"container\":{\"padding-top\":\"0\",\"padding-bottom\":\"0\"}}",
    }]
}
