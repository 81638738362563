<div class="position-relative banner-block-container" *ngIf="data && isShow"
    style="width: 100%;text-align: center;{{getContainerStyle()}}" [ngClass]="{
        'b-r overflow-hidden':!currentDeviceService?.isDesktop() || !renderOptions?.fullWidth,
        'full-view-mode':_renderOptions?.fullWidth,
        'disabled-background-image':!data?.enabledBackgroundImage
    }">
    <img [decode]="true" offset="100" appLoadingAnimationAndErrorBackground *ngIf="data?.enabledBackgroundImage"
        [lazyLoad]="(currentDeviceService?.isDesktop() ? data?.desktop?.data : data?.mobile?.data) | uploadUrlPrefix"
        [style.height]="_renderOptions?.fullWidth ? (100 / (currentDeviceService?.isDesktop() ? data?.desktop : data?.mobile)?.aspectRatio + 'vw')
         : ('calc(min(var(--content-width), 100%) / ' + (currentDeviceService?.isDesktop() ? data?.desktop : data?.mobile)?.aspectRatio + ')')"
        alt="" class="banner-img" [ngClass]="{'full-view-mode':_renderOptions?.fullWidth}"
        (click)="navigateToSomePage(currentDeviceService?.isDesktop() ? data?.desktop : data?.mobile)">
    <ng-container *ngIf="!data?.enabledBackgroundImage">
        <ng-container *ngTemplateOutlet="mainContentRef;"></ng-container>
    </ng-container>
    <button *ngIf="data?.enabledActionButton&&data?.enabledBackgroundImage&&(data |
    mNameTranslate:(currentDeviceService?.isDesktop() ? 'desktopButton':'mobileButton'))"
        [style.marginRight]="data?.enableClose?'calc(1.5rem + 15px)':''"
        class="btn bg-warning btn-reflush ellipsis d-inline-flex align-items-center justify-content-center position-absolute close-for-banner-block"
        (click.stop)="navigateToSomePage(currentDeviceService?.isDesktop() ? data?.desktop : data?.mobile)" [title]="data |
        mNameTranslate:(currentDeviceService?.isDesktop() ? 'desktopButton':'mobileButton')">{{data |
        mNameTranslate:(currentDeviceService?.isDesktop() ? 'desktopButton':'mobileButton')}}</button>
    <i class="iconfont icon-cha text-muted position-absolute close-for-banner-block" *ngIf="data?.enableClose"
        (click)="closeBanner()"></i>
</div>

<ng-template #mainContentRef>
    <div class="py-4 text-center" style="width: min(500px,calc(100% - 2rem));margin: auto;">
        <div class="main-content-title title text-center" [style]="getElStyle('item-title')"
            [appFontSizeScale]="getElFontSizeScale('item-title')">
            {{data|mNameTranslate:'title'}}</div>
        <div class="main-content-desc sub-title" [ngClass]="{
            'py-3':!currentDeviceService?.isMobile(),
            'py-2':currentDeviceService?.isMobile(),
            'on-top-content':data?.layoutType === 'IMAGE_BOTTOM',
            'pb-0':!(data?.enabledActionButton&&!data?.enabledBackgroundImage&&(data |
            mNameTranslate:'button'))
        }" [style]="getElStyle('item-desc')" [appFontSizeScale]="getElFontSizeScale('item-desc')">
            {{data|mNameTranslate:'desc'}}</div>
        <div class="d-flex align-items-center justify-content-center" *ngIf="data?.enabledActionButton&&!data?.enabledBackgroundImage&&(data |
            mNameTranslate:'button')">
            <button class="btn bg-warning btn-reflush ellipsis" [title]="data |
            mNameTranslate:'button'" style="cursor: pointer;" (click)="navigateToSomePage(data)" type="submit">{{data |
                mNameTranslate:'button'}}</button>
        </div>
    </div>
</ng-template>