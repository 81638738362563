<app-block-container [blockId]="blockId" [renderOptions]="_renderOptions" [enabledMoreBtn]="false"
    [enabledTitle]="false" style="background-color: transparent;" [section]="section"
    [ngStyle]="_renderOptions?.container" [containerClass]="'border-top'">
    <div class='bottom-footer copy-footer'>
        <div
            [style.padding]="((data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false))?'2rem 1rem 1rem':'0'">
            <div #footerBody style="display: flex;"
                *ngIf="(data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false)">
                <div class="mod_logo"
                    *ngIf="site?.iconUrl && (data?.enabledSiteLogo !== false) &&
                ((data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false))">
                    <img class="b-r"
                        style="width: 85px;max-height: 85px;object-fit: contain;position: relative;top: -10px;"
                        loading="lazy" onerror="this.onerror=null;this.src='/assets/icon/img-blank.svg';"
                        [src]="(site?.iconUrl) | uploadUrlPrefix | imgResize: 'h_170'" />
                </div>
                <div class="mod_help_list">
                    <div class="mod_help_nav" *ngIf="data?.enabledSiteTerm !== false">
                        <h5 class="mod_help_nav_tit" [appTranslate]="'FOOTER.TERMS_OF_USE'">使用条款</h5>
                        <ul class="mod_help_nav_con">
                            <li>
                                <a style="color:var(--footer-tit-color);"
                                    [appTranslate]="'FOOTER.TERMS_OF_REGISTRATION'"
                                    [routerLink]="[ '/document','REGISTER_TERM' ]">注册条款</a>
                            </li>
                            <li>
                                <a style="color:var(--footer-tit-color);" [appTranslate]="'FOOTER.TERMS_OF_PURCHASE'"
                                    [routerLink]="[ '/document','PURCHASE_TERM' ]">购买条款</a>
                            </li>
                        </ul>
                    </div>
                    <div class="mod_help_nav" *ngIf="data?.enabledCustomerService !== false">
                        <h5 class="mod_help_nav_tit" [appTranslate]="'FOOTER.CUSTOMER_SERVICE'">
                            客户服务</h5>
                        <ul class="mod_help_nav_con">
                            <!-- <li *ngIf="_config?.homeModule?.homeService?.customerService?.visible">
                            <a style="color: var(--footer-tit-color);"
                                [appTranslate]="'FOOTER.CONTACT_CUSTOMER_SERVICE'"
                                [routerLink]="[ '/customer-service' ]">联系客服</a>
                        </li> -->
                            <!-- *ngIf="_config?.homeModule?.homeService?.feedbackvisible" -->
                            <li>
                                <a style="color: var(--footer-tit-color);" [appTranslate]="'FOOTER.FEEDBACK_BTN_TEXT'"
                                    [routerLink]="[ '/user-center/feedback' ]">我要反馈</a>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="mod_help_nav" *ngIf="_config?.homeModule?.homeService?.afterSaleService?.visible">
                    <h5 class="mod_help_nav_tit" [appTranslate]="'FOOTER.AFTER_SALES_SERVICE'">售后服务</h5>
                    <ul class="mod_help_nav_con">
                        <li>
                            <a style="color: var(--footer-tit-color);" [appTranslate]="'FOOTER.AFTER_SALES_SERVICE'"
                                [routerLink]="[ '/user-center/sales-service' ]">售后服务</a>
                        </li>
                    </ul>
                </div> -->
                    <div class="mod_help_nav" *ngIf="data?.enabledMerchantService !== false">
                        <h5 class="mod_help_nav_tit" [appTranslate]="'FOOTER.MERCHANT_SERVICES'">商家服务</h5>
                        <ul class="mod_help_nav_con">
                            <li>
                                <a style="color: var(--footer-tit-color);"
                                    href="/workspace?authenticationToken={{siteContextService?.token()}}"
                                    target="_blank" [appTranslate]="'FOOTER.MERCHANT_PORTAL'">管理后台</a>
                            </li>
                        </ul>
                    </div>
                    <div class="mod_help_nav"
                        *ngIf="affiliateNetworks?.length && (data?.enabledAffiliateService !== false)">
                        <h5 class="mod_help_nav_tit" [appTranslate]="'FOOTER.AFFILIATE_SERVICES'">联盟网络</h5>
                        <ul class="mod_help_nav_con">
                            <li *ngFor="let affiliateNetwork of affiliateNetworks">
                                <a style="color: var(--footer-tit-color);"
                                    [routerLink]="[ '/user-center/affiliate',affiliateNetwork?.id,'join' ]">{{affiliateNetwork
                                    |
                                    mNameTranslate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div style="width:200px;">
                <div style="text-align: right;"
                    *ngIf="_config?.homeModule?.homeService?.customerService?.officialWxQRCodeImageUrl">
                    <img style="width:85px;"
                        src="{{_config?.homeModule?.homeService?.customerService?.officialWxQRCodeImageUrl | uploadUrlPrefix}}"
                        alt="">
                    <h5 style="display: inline-block;
                        margin-left: 10px;
                        height: 78px;
                        letter-spacing: 3px;
                        font-weight: 500;
                        writing-mode: vertical-lr;
                        font-size: inherit;
                        vertical-align: bottom;
                        writing-mode: tb-lr;" [appTranslate]="'FOOTER.FOLLOW_US_FOR_MORE_INFORMATION'">
                        关注我们获取更多信息</h5>
                </div>
            </div> -->
            </div>
            <div id="footer-bottom" #footerBottom class="d-flex justify-content-between align-content-center"
                [ngClass]="{'mt-3 border-top py-3':(data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false)}">
                <div class="footer-widget align-items-center">
                    <!-- <a *ngIf="_config?.homeModule?.homeService?.customerService?.title"
                    style="color: var(--footer-tit-color);font-size: var(--fz-14);">{{_config?.homeModule?.homeService?.customerService?.title}}</a>
                <span style="padding: 0 10px;" *ngIf="_config?.homeModule?.homeService?.customerService?.title">|</span> -->
                    <div class="bottom-text-container">
                        <a class="bottom-text domainIcpNumber"
                            *ngIf="data?.customerLinkText" [href]="data?.customerLink || 'https://beian.miit.gov.cn/'"
                            target="_blank">{{data | mNameTranslate:'customerLinkText'}}</a>
                        <!-- <span style="padding: 0 10px;" *ngIf="data?.customerLinkText">|</span> -->
                        <span class="bottom-text domainIcpNumber"
                            *ngIf="site?.ownerName || (ownerInfo?.fullName || ownerInfo?.name)">{{(site |
                            mNameTranslate:'ownerName')
                            || ownerInfo?.fullName || ownerInfo?.name}}</span>
                        <!-- <span style="padding: 0 10px;"
                            *ngIf="site?.ownerName || (ownerInfo?.fullName || ownerInfo?.name)">|</span> -->
                        <span class="bottom-text">
                            <span [appTranslate]="'FOOTER.TECHNICAL_SUPPORT'">技术支持:</span>
                            <a [href]="developerInfo?.developer?.website" target="_blank">
                                <span *ngIf="developerInfo?.developer">
                                    {{developerInfo?.developer | mNameTranslate}}
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
                <div class="footer-widget align-items-center custom-html-container" *ngIf="data?.customizedHtml">
                    <div [innerHTML]="data?.customizedHtml"></div>
                </div>
                <div class="footer-widget menu-container">
                    <div>
                        <div class="menu-item"
                            [ngClass]="{'align-items-center d-inline-flex h-auto position-relative':!(site?.iconUrl && (data?.enabledSiteLogo !== false) &&
                        ((data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false))),
                    'd-inline-block':site?.iconUrl && (data?.enabledSiteLogo !== false) &&
                    ((data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false))}"
                            style="float: right;" ngbDropdown container="body">
                            <a class="menu-link px-2" ngbDropdownToggle>
                                <span class="menu-title position-relative">
                                    &nbsp;
                                    <span class="fs-8 rounded bg-light px-3 py-2 top-50 end-0"
                                        [ngClass]="{'position-absolute translate-middle-y':(site?.iconUrl && (data?.enabledSiteLogo !== false) &&
                                        ((data?.enabledSiteTerm !== false)||(data?.enabledCustomerService !== false)||(data?.enabledMerchantService !== false)||(affiliateNetworks?.length && data?.enabledAffiliateService !== false)))}"
                                        style="cursor: pointer;">
                                        <span [appTranslate]="(selectedLang() | selectedLanguageHelper)">-</span>
                                        <img class="w-15px h-15px rounded-1 ms-2" style="width: 15px;height: 15px;"
                                            [src]="'assets/icon/flags/globe.svg'" />
                                    </span>
                                </span>
                            </a>
                            <div class="menu-sub menu-sub-dropdown w-175px py-3 translate-option-item border-0" ngbDropdownMenu>
                                <div class="menu-item px-3" *ngFor="let country of countries" ngbDropdownItem>
                                    <a (click)="setLang(country.langKey)" class="menu-link d-flex px-3"
                                        [ngClass]="{ active: country.langKey === selectedLang() }">
                                        {{country.langKey | findLanguageFromKey}}
                                    </a>
                                </div>
                            </div>
                        </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</app-block-container>
