export const authTranslate = {
    LOGIN_BY_WECHAT_QRCODE: {
        TITLE: '微信扫码登录',
        RELOAD_BTN_TEXT: '刷新二维码',
        ERROR_MESSAGE: {
            RELOAD_ERROR: '初始化微信二维码失败，请重试！',
            WECHAT_NOT_REGISTER: '当前微信暂未注册或绑定手机号，您是否需要前往 微信注册页面 或 使用账户登录 ?',
            CODE_USED: '当前身份已过期，请重新扫码登录……',
            DEFAULT_TEXT: '后台服务未启动或者未配置微信平台信息，请联系管理员！',
            WECHAT_NOT_REGISTER_ERROR: '当前微信号暂未注册，请问是否用该微信号注册'
        }
    },
    LOGIN_BY_ACCOUNT_AND_PASSWORD: {
        TITLE: '账户密码登录'
    },
    PASSWORD: {
        TITLE: '8-60位仅包含数字、字母字符',
        PLACEHOLDER: '请输入密码...',
        REQUIRED_ERROR_MESSAGE: '密码不能为空',
        PATTERN_ERROR_MESSAGE: '密码格式不正确，请检查',
        PATTERN_TOOLTIPS: {
            LENGTH_VALIDATE_MSG: '长度为{{passwordMinimumLength}}~80个字符',
            REQUIRE_START_TEXT: '至少包含{{value}}',
            NUMBER_TEXT: '数字',
            LOWER_CASE_TEXT: '小写字母',
            SYMBOLS_TEXT: '特殊符号',
            UPPER_CASE_TEXT: '大写字母',
            REQUIRE_ALL_RULES_TEXT: '必须包含大写字母/小写字母/数字以及特殊符号',
            SUPPORTED_CHARACTERS_TEXT: '仅支持输入大写字母、小写字母、数字以及特殊符号',
        }
    },
    VERIFY_CODE: {
        TITLE: '验证码',
        PLACEHOLDER: '请输入验证码...',
        SEND_BTN_TEXT: '发送',
        SEND_BTN_FULL_TEXT: '发送验证码',
        GET_CODE_ERROR_MESSAGE: '获取图片验证码失败',
        REQUIRED_ERROR_MESSAGE: '验证码不能为空',
        PATTERN_ERROR_MESSAGE: '验证码格式不正确',
        SEND_ERROR: '发送验证码失败：',
        SENDING_TEXT_MESSAGES_TOO_FREQUENTLY_PLEASE_TRY_AGAIN_LATER: '发送验证码过于频繁，请稍后再试吧~',
    },
    MOBILE_PHONE: {
        TITLE: '手机号',
        PLACEHOLDER: '请输入手机号...',
        REQUIRED_ERROR_MESSAGE: '手机号不能为空！',
        PATTERN_ERROR_MESSAGE: '手机号格式不正确！',
        EXISISTED_ERROR_MESSAGE: '手机号已注册，请更换手机号或者使用该手机号登录吧！',
        NOT_EXISIST_ERROR_MESSAGE: '手机号未注册，请更换手机号或者先去注册吧！',
    },
    ACCOUNT: {
        TITLE: '账号',
        PLACEHOLDER: '请输入账号...',
        REQUIRED_ERROR_MESSAGE: '账号不能为空',
        PATTERN_ERROR_MESSAGE: '账号格式不正确',
    },
    RESET_PASSWORD: {
        TITLE: '重置密码',
        RESET_BTN_TEXT: '确认重置',
        SUCCESS_MESSAGE: '重置成功',
        ERROR_MESSAGE: '重置失败：'
    },
    LOGIN_BY_ACCOUNT: {
        TITLE: '账号登录',
    },
    LOGIN_BY_MOBILE_AND_VERIFY: {
        TITLE: '验证码登录',
    },
    LOGIN_BY_MOBILE_AND_PASSWORD: {
        TITLE: '密码登录',
    },
    LOGIN_BY_WECHAT_MP: {
        TITLE: '微信授权登录',
        LOGIN_BTN_TEXT: '授权登录',
        ERROR_MESSAGE: {
            NOT_CONFIG: '未配置微信公众平台信息，请联系客服或者管理员！',
            CONFIG_ERROR: '微信配置数据有误，请联系客服或者管理员！',
            WECHAT_NOT_REGISTER: '该微信暂未注册，请使用手机号或者其他微信登录吧！',
            DEFAULT_TEXT: '微信登录失败，请刷新后重新授权登录'
        }
    },
    LOGIN_BY_WECHAT: {
        TITLE: '微信登录'
    },
    LOGIN_BY_PHONE: {
        TITLE: '手机号登录'
    },
    RESPONSE_ERROR_MESSAGE: '登录失败：',
    REGISTER_ERROR_MESSAGE: '注册失败：',
    CHANGE_ERROR_MESSAGE: '修改失败：',
    AUTO_LOGIN_TEXT: '自动登录',
    FORGOT_PASSWORD_TEXT: '忘记密码',
    LOGIN_TEXT: '登录',
    LOGOUT_TEXT: '退出登录',
    RELOAD_TEXT: '刷新重试',
    METHOD_ERROR_MESSAGE: '获取可用登录方式失败~',
    OTHER_LOGIN_METHOD_TEXT: '其他登录方式~',
    USER_NOT_EXIST_EROOR_TEXT: '当前账号不存在哦~',
    INFOMATION: {
        ERROR_MESSAGE: '账号信息获取失败，请刷新重试',
        WELECOME_TEXT: '欢迎回来~',
        HOME_BTN_TEXT: '个人中心',
    },
    WECHAT_AUTHORIZATION_SUCCESS: '微信授权成功！',
    AUTHORIZATION: '授权',
    QRCODE: '扫码',
    FAILED_TO_BIND_WECHAT: '绑定微信失败：',
    FAILED_TO_UNBIND_WECHAT: '解绑微信失败：',
    FAILED_TO_BIND: '绑定失败：',
    WECHAT_CODE_VERIFICATION_FAILED_PLEASE_TRY_AGAIN: '微信码校验失败，请重新',
    CURRENT_ACCOUNT_IDENTITY: '当前身份',
    FAILED_TO_GET_LOGIN_REGISTRATION_METHOD: '获取登录/注册方式失败~',
    FAILED_TO_OBTAIN_PERSONAL_INFORMATION: '获取个人信息失败！',
    SIGN_OUT_AND_SIGN_IN_SUCCESSFULLY: '退出登录成功',
    OTHER_REGISTRATION_OR_LOGIN_METHODS: '其他{{value}}方式',
    REGISTRATION_TEXT: '注册',
    REGISTER_BTN_TEXT: '创建新账号',
    SIGN_UP_NOW: '立即注册',
    LOG_IN_IMMEDIATELY: '立即登录',
    ALREADY_HAVE_AN_ACCOUNT: '已有账号？',
    DONT_HAVE_AN_ACCOUNT_YET: '还没有账号？',
    MOBILE_NUMBER_REGISTRATION: {
        TITLE: '手机注册'
    },
    WECHAT_REGISTRATION: {
        TITLE: '微信注册'
    },
    CAPTCHA_VERIFY_CODE: '图片验证码',
    CAPTCHA_VERIFY_CODE_PLACEHOLDER: '请输入图片验证码',
    CAPTCHA_VERIFY_CODE_NULL_ERROR: '图片验证码不能为空！',
    CAPTCHA_VERIFY_CODE_INVALID_ERROR: '图片验证码最少得有4位！',
    MESSAGE_VERIFY_CODE: '手机验证码',
    REFERRAL_CODE: '推荐码',
    REFERRAL_CODE_PLACEHOLDER: '可输入邀请人的推荐码',
    SECOND: '秒',
    PHONE_NULL_TIPS: '请先输入手机号!',
    PHONE_NOT_REGISTER_TIPS: '当前手机号未注册!',
    PHONE_HAS_REGISTERED_TIPS: '当前手机号已注册!',
    CAPTCHA_VERIFY_CODE_ERROR_TIPS: '图片验证码输入错误，请重新填写',
    CAPTCHA_VERIFY_CODE_NULL_TIPS: '请输入图片验证码!',
    AGREEMENT_NOT_CONFIRM_TIPS: '协议未勾选！',
    LOGGING_IN_TIPS: '登录中',
    SIGN_IN_SUCCESSFULLY: '登录成功',
    PASSWORD_TEXT: '密码',
    ACCOUNT_LOGIN: '账户登录',
    LOGIN_BY_OTHER_WECHAT_ACCOUNT: '其他微信登录',
    ACCOUNT_REGISTER: {
        TITLE: '手机号注册',
        EXISISTED_ERROR_MESSAGE: '手机号已注册！',
        NOT_EXISIST_ERROR_MESSAGE: '手机号未注册！',
    },
    REGISTERING: '注册中',
    REGISTRATION_SUCCESS: '注册成功',
    WECHAT_AUTHORIZED_REGISTRATION: '微信授权注册',
    BIND_AND_REGISTER: '绑定并注册',
    WECHAT_SCAN_CODE_REGISTRATION: '微信扫码注册',
    BIND_MOBILE_NUMBER: '绑定手机号',
    WECHAT_REGISTERED_AND_AUTO_LOGGING_TIPS: '该微信已注册，为您自动登录中……',
    IDENTITY_EXPIRED_AND_REAUTHORIZE_TIPS: '当前身份已过期，请重新授权……',
    AGREE_BY_ACTION_TYPE: '{{action}}即代表同意',
    I_HAVE_READ_AND_AGREE_TIPS: '我已阅读并同意',
    WECHAT_SCAN_CODE_AUTHORIZATION: '微信扫码授权',
    WECHAT_AUTHORIZATION: '微信授权',
    WECHA_PLATFORM_NOT_CONFIRGURED_ERROR_TIPS: '后台未配置微信公众平台信息，请联系管理员！',
    CURRENT_WECHAT_AUTHORIZATION_FAILED_TIPS: '当前微信状态已过期，是否重新授权',
    REAUTHORIZE: '重新授权',
    GET_CURRENT_WECHAT_ID_FAILED_TIPS: '未获取到微信身份，请重试！',
    SUCCESSFULLY_BIND_WECHAT: '绑定微信成功！',
    AUTHORIZE_SUCCESSFULLY_AND_AUTO_LOGGING_TIPS: '授权成功，为您登录中……',
    WECHAT_PLATFORM_SCRET_ERROR_TIPS: '当前后台未配置正确的微信密钥，无法进行微信授权登录！',
    WECHAT_NOTREGISTERED_ERROR_TIPS: '当前微信暂未注册，您是否需要前往 微信注册页面 或 刷新重试 ?',
    WECHAT_NOTREGISTERED_OR_NOT_FOUND_PHONE_ERROR_TIPS: '当前微信暂未注册或绑定手机号，您是否需要前往 微信注册页面 或 使用账户登录 ?',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS_FOR_PHONE_LOGIN_ENABLE: '当前微信未授权，您是否需要 微信授权登录 或 使用账户登录 ?',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS: '当前微信未授权，您是否需要 微信授权登录 或 刷新重试 ?',
    NEW_PASSWORD: '新密码',
    PLEASE_READ_AND_CHECKED_THE_TERMS: '请阅读并勾选条款！',
    PHONE_OR_CAPTCHA_CODE_NULL: '请先输入手机号或者图片验证码！',
    OLD_PASSWORD: '原密码',
    OLD_PASSWORD_PLACEHOLDER: '请输入原密码',
    RESETING: '重置中',
    RESET_SUCCESSFULLY: '重置成功',
    MESSAGE_CODE_SEND_FAILED: '短信验证码发送失败',
    EMAIL_CODE_SEND_FAILED: '邮箱验证码发送失败',
    EMAIL_VERIFY_CODE: '邮箱验证码',
    EMAIL_NULL_TIPS: '请先输入邮箱!',
    EMAIL_NOT_REGISTER_TIPS: '当前邮箱未注册!',
    EMAIL_HAS_REGISTERED_TIPS: '当前邮箱已注册!',
    EMAIL: {
        TITLE: '邮箱',
        PLACEHOLDER: '请输入邮箱...',
        REQUIRED_ERROR_MESSAGE: '邮箱不能为空！',
        PATTERN_ERROR_MESSAGE: '邮箱格式不正确！',
        EXISISTED_ERROR_MESSAGE: '邮箱已注册，请更换邮箱或者使用该邮箱去登录吧！',
        NOT_EXISIST_ERROR_MESSAGE: '邮箱未注册，请更换邮箱或者先注册吧！',
    },
    REGISTRATION_BY_PHONE: {
        TITLE: '手机号注册'
    },
    LOGIN_BY_EMAIL: {
        TITLE: '邮箱登录'
    },
    REGISTRATION_BY_EMAIL: {
        TITLE: '邮箱注册'
    },
    BIND_EMAIL: '绑定邮箱',
    EMAIL_VERIFY_CODE_PLACEHOLDER: '请输入邮箱验证码',
    SEND_EMAIL_VERIFY_CODE_BTN_TEXT: '发送邮箱验证码',
    OLD_EMAIL_CAPTCHA_ERROR: '原邮箱图片验证码输入错误',
    NEW_EMAIL: '新邮箱',
    OLD_EMAIL: '原邮箱',
    LOGIN_BY_NAME: {
        TITLE: '账户名登录',
    },
    LOGIN_NAME: {
        TITLE: '账户名',
        PLACEHOLDER: '请输入账户名...',
        REQUIRED_ERROR_MESSAGE: '账户名不能为空！',
        PATTERN_ERROR_MESSAGE: '账户名格式不正确！',
        NOT_EXISIST_ERROR_MESSAGE: '当前账户不存在，请检查账户名或者先去注册吧！',
    },
};
