import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[click.stop]'
})
export class StopPropagationDirective {
    @Output('click.stop') stopPropEvent = new EventEmitter();
    unsubscribe: () => void;

    constructor() {
    }

    @HostListener('click', ['$event'])
    clickEvent(event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        this.stopPropEvent.emit(event);
    }

}
