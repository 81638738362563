export enum BackgroundTypeEnum {
    'NONE' = 'NONE',
    'IMAGE' = 'IMAGE',
    'COLOR' = 'COLOR',
    'CAROUSEL' = 'CAROUSEL',
    'VIDEO' = 'VIDEO',
}

export enum FilterTypeEnum {
    'NONE' = 'NONE',
    'BLUR' = 'BLUR',
    'DARKEN' = 'DARKEN',
    'CUSTOMIZE' = 'CUSTOMIZE',
}

export class BackgroundSetting {
    type?: BackgroundTypeEnum;
    // 如果是轮播图就是个json化的字符串
    data?: string;
    // 宽高比
    aspectRatio?: number;
    // 如果是轮播图可能需要额外的参数
    options?: string;
    // 滤镜
    filterType?: FilterTypeEnum;
    // 滤镜额外的参数
    filterOptions?: string;
}
