import { jsonParse } from "../../shared/pipes/name-translate.pipe";


export enum TargetTypeEnum {
    URL = 'URL',
    ENTITY = 'ENTITY',
    NONE = 'NONE'
}

export enum TargetEntityTypeEnum {
    PRODUCT = 'PRODUCT',
    PRODUCT_CATEGORY = 'PRODUCT_CATEGORY',
    POST_LIST = 'POST_LIST',
    POST = 'POST',
    PAGE = 'PAGE',
    FORM = 'FORM',
    POST_CATEGORY = 'POST_CATEGORY',
}
export interface IPostConfig {
    enableRelatedPostEntry: boolean;
    enableTitleForDetail: boolean;
    enableCategoryForDetail: boolean;
    enableTagForDetail: boolean;
    type: ArticleStyle;
}
export enum ArticleStyle {
    CARD = 'CARD',
    LIST = 'LIST'
}
export enum DefaultLoginWay {
    phoneNumber = 'phoneNumber',
    Password = 'password',
    wechatJsAuth = 'wechatJsAuth',
    wechatNativeAuth = 'wechatNativeAuth'
}

export interface IDiyContentItem {
    title?: string;
    imgUrl?: string;
    targetType?: TargetTypeEnum;
    targetUrl?: string;
    targetEntityId?: number;
    targetEntityType?: TargetEntityTypeEnum;
    postConfig?: IPostConfig;
    targetEntityValue?: MenuItem | number;
}
// targetEntityValue是选中的菜单
// targetEntityValue如果是数字那么就是自定义菜单，对应自定义菜单的下标。MenuItem的话就是对应就是那几个菜单
export enum MenuItem {
    home = 'home',
    personal = 'personal',
    category = 'category',
    featured = 'featured',
    shoppingCard = 'shoppingCard',
}

export class DiyContentItem implements IDiyContentItem {
    title = '';
    imgUrl = '';
    targetType = TargetTypeEnum.NONE;
    targetUrl = '';
    targetEntityId;
    targetEntityType = TargetEntityTypeEnum.PRODUCT;
    constructor(diyContentItem?: DiyContentItem) {
        for (const key in diyContentItem) {
            if (key) {
                this[key] = diyContentItem[key];
            }
        }
    }
}

export interface IDiyContent {
    visible?: boolean;
    title?: string;
    bgUrl?: string;
    items?: IDiyContentItem[];
}

export class DiyContent implements IDiyContent {
    visible = true;
    title = '';
    bgUrl = '';
    items?: IDiyContentItem[] = [];
    constructor(diyContent?: DiyContent) {
        for (const key in diyContent) {
            if (key) {
                this[key] = diyContent[key];
            }
        }
    }
}

export interface IPageModuleItem {
    visible: boolean;
    title?: string;
    icon?: string;
    activeIcon?: string;
    targetType?: TargetTypeEnum;
    targetUrl?: string;
    targetEntityId?: number;
    targetEntityType?: TargetEntityTypeEnum;
    postConfig?: IPostConfig;
    sort?: number;
}
export class PageModuleItem implements IPageModuleItem {
    visible: boolean;
    title?: string;
    icon?: string;
    activeIcon?: string;
    targetType?: TargetTypeEnum;
    targetUrl?: string;
    targetEntityId?: number;
    targetEntityType?: TargetEntityTypeEnum;
    postConfig?: IPostConfig;
    sort?: number;
    constructor(pageModuleItem?: PageModuleItem) {
        for (const key in pageModuleItem) {
            if (key) {
                this[key] = pageModuleItem[key];
            }
        }
    }
}
export interface IMobileAppConfig {
    general?: {
        title?: string,
        icon?: string,
        purchaseButtonText?: string,
        purchaseTitleText?: string,
        productName?: string,
        purchaseButtonTextTranslation?: string,
        purchaseTitleTextTranslation?: string,
        productNameTranslation?: string,
        productLabels?: {
            title?: string,
            value?: string
        }[],
        enabledShoppingCat?: boolean,
        shoppingCatSort?: number,
        personalSort?: number,
        enabledProductComment?: boolean,
        enabledAddress?: boolean,
        enabledAliPay?: boolean,
        enableOfflinePay?: boolean,
        enableStripePay?: boolean,
        enabledWxPay?: boolean,
        enablePaypayPay?: boolean,
        enabledCollection?: boolean,
        // enabledCustomizedTheme?: boolean,
        enabledCustomizedBgImg?: boolean,
        enabledCustomizedHeaderBgImg?: boolean,
        enabledCustomizedLoginBgImg?: boolean,
        theme?: string,
        offlinePayDesc?: string,
        customizedThemeItems?: string[],
        customizedThemeBackgroundImgUrl?: string,
        customizedThemeHeaderBackgroundImgUrl?: string,
        customizedThemeLoginBackgroundImgUrl?: string,
        domainIcpNumber?: string,
    };
    indexModule?: {
        title?: string,
        icon?: string,
        activeIcon?: string,
        sort?: number,
        logo?: {
            visible?: boolean,
            logoUrl?: string
        },
        search?: {
            visible?: boolean
        },
        carousel?: {
            visible?: boolean
        },
        hotProduct?: {
            visible?: boolean,
            title?: string,
            salesVisible?: boolean
        },
        groupBuyingProduct?: {
            visible?: boolean,
            title?: string,
            salesVisible?: boolean
        },
        newProduct?: {
            visible?: boolean,
            title?: string,
            salesVisible?: boolean
        },
        homeAnnouncement?: {
            visible?: boolean
        },
        series?: {
            visible?: boolean,
            title?: string,
            salesVisible?: boolean
        },
        labeledProducts?: {
            visible?: boolean,
            title?: string,
            salesVisible?: boolean
        },
        preSale?: {
            visible?: boolean,
            title?: string,
            salesVisible?: boolean
        },
        diyContents?: IDiyContent[]
    };
    customizedModule?: {
        visible?: boolean
    };
    categoryModule?: {
        visible?: boolean,
        title?: string,
        icon?: string,
        activeIcon?: string,
        sort?: number,
    };
    featuredModule?: {
        title?: string,
        icon?: string,
        activeIcon?: string,
        visible?: boolean,
        sort?: number,
    };
    shoppingCatModule?: {
        title?: string,
        icon?: string,
        activeIcon?: string,
        visible?: boolean,
        sort?: number,
    };
    pageModule?: {
        visible?: boolean,
        items?: IPageModuleItem[]
    };
    aboutModule?: {
        visible?: boolean
    };
    homeModule?: {
        title?: string,
        icon?: string,
        activeIcon?: string,
        sort?: number,
        homeService?: {
            feedbackvisible?: boolean,
            afterSaleService?: {
                visible?: boolean,
                text?: string,
                imageUrl?: string
            },
            customerService?: {
                visible?: boolean,
                title?: string,
                tel?: string,
                officialAddress?: string,
                companyAddress?: string,
                officialWxQRCodeImageUrl?: string
            }
        }
    };
    version?: number;
    manuSortArr?: any[];
}

export class MobileAppConfig implements IMobileAppConfig {
    general = {
        title: '网上商城',
        icon: '',
        purchaseButtonText: '立即购买',
        purchaseButtonTextTranslation: JSON.stringify({
            'zh-CN': '立即购买',
            'zh-TW': '立即購買',
            'en-US': 'Buy Now',
            'ja': '購入する'
        }),
        purchaseTitleText: '商品详情',
        productName: '商品',
        productNameTranslation: JSON.stringify({
            'zh-CN': '商品',
            'zh-TW': '商品',
            'en-US': 'product',
            'ja': '商品'
        }),
        productLabels: [
            {
                title: '推荐',
                value: 'RECOMMEND'
            }
        ],
        enabledShoppingCat: true,
        enabledProductComment: true,
        enabledAddress: true,
        enabledAliPay: false,
        enabledWxPay: true,
        enabledCollection: true,
        theme: 'simple-white',
        // enabledCustomizedTheme: false,
        enabledCustomizedBgImg: false,
        enabledCustomizedHeaderBgImg: false,
        enabledCustomizedLoginBgImg: false,
        customizedThemeItems: ['#f5f5f5', '#555', '#ff643a', '#ff643a'],
        customizedThemeBackgroundImgUrl: '',
        customizedThemeHeaderBackgroundImgUrl: '',
        customizedThemeLoginBackgroundImgUrl: '',
        domainIcpNumber: ''
    };
    indexModule = {
        sort: 0,
        logo: {
            visible: false,
            logoUrl: ''
        },
        search: {
            visible: true
        },
        carousel: {
            visible: true
        },
        homeAnnouncement: {
            visible: true
        },
        groupBuyingProduct: {
            visible: true,
            title: '今日必拼',
            salesVisible: false
        },
        hotProduct: {
            visible: true,
            title: '热卖商品',
            salesVisible: true
        },
        newProduct: {
            visible: true,
            title: '最新商品',
            salesVisible: false
        },
        series: {
            visible: true,
            title: '系列商品',
            salesVisible: false
        },
        labeledProducts: {
            visible: true,
            title: '标签商品',
            salesVisible: false
        },
        preSale: {
            visible: true,
            title: '预售商品',
            salesVisible: false
        },
        diyContents: [
            new DiyContent({
                visible: false,
                title: '',
                bgUrl: '',
                items: []
            }),
            new DiyContent({
                visible: false,
                title: '',
                bgUrl: '',
                items: []
            }),
        ]
    };
    customizedModule = {
        visible: false
    };
    categoryModule = {
        visible: true,
        title: '分类',
        icon: 'iconfont icon-fenleishouye'
    };
    featuredModule = {
        visible: true
    };
    pageModule = {
        items: [new PageModuleItem({
            visible: true,
            sort: undefined,
            title: '文章',
            icon: 'iconfont icon-16',
            targetType: TargetTypeEnum.ENTITY,
            targetUrl: '',
            targetEntityId: 0,
            targetEntityType: TargetEntityTypeEnum.POST_LIST
        })]
    };
    aboutModule = {
        visible: false
    };
    homeModule = {
        homeService: {
            feedbackvisible: true,
            afterSaleService: {
                visible: false,
                text: '',
                imageUrl: ''
            },
            customerService: {
                visible: false,
                title: '',
                tel: '',
                officialAddress: '',
                companyAddress: '',
                officialWxQRCodeImageUrl: ''
            }
        }
    };
    version = 2.1;
    recursion(container, content) {
        let data = jsonParse(content);
        for (const key in data) {
            if (typeof (container[key]) === 'object' && !container[key].hasOwnProperty('length')) {
                this.recursion(container[key], data[key]);
            } else {
                if (key === 'version') {
                    return;
                }
                container[key] = data[key];
            }
        }
    }
    constructor(mobileAppConfig: IMobileAppConfig = {}) {
        if (!mobileAppConfig) {
            mobileAppConfig = {};
        }
        if (mobileAppConfig) {
            this.recursion(this, mobileAppConfig);
        }
    }
}
