export const authTranslate = {
    LOGIN_BY_WECHAT_QRCODE: {
        TITLE: 'WeChat スキャン コードログイン',
        RELOAD_BTN_TEXT: 'QR コードを更新します',
        ERROR_MESSAGE: {
            RELOAD_ERROR: 'WeChat QR コードの初期化に失敗しました。',
            WECHAT_NOT_REGISTER: '現在の WeChat は携帯番号を登録またはバインドしていませんが、WeChat 登録ページに移動するか、アカウントでサインインする必要がありますか?',
            CODE_USED: '現在のIDの有効期限が切れています。QRコードをスキャンして再度ログインしてください...',
            DEFAULT_TEXT: 'バックグラウンドサービスが開始されていないか、WeChatプラットフォーム情報が構成されていない場合は、管理者に連絡してください。',
            WECHAT_NOT_REGISTER_ERROR: '現在のマイクロシグナルは登録されていませんので、そのマイクロシグナルで登録されているかどうか尋ねてください'
        }
    },
    LOGIN_BY_ACCOUNT_AND_PASSWORD: {
        TITLE: 'アカウントパスワードログイン'
    },
    PASSWORD: {
        TITLE: '8 60 ビットには、数字と英字のみが含まれます',
        PLACEHOLDER: 'パスワードを入力してください...',
        REQUIRED_ERROR_MESSAGE: 'パスワードを空にすることはできません。',
        PATTERN_ERROR_MESSAGE: 'パスワードが正しい形式ではありません、確認してください!',
        PATTERN_TOOLTIPS: {
            LENGTH_VALIDATE_MSG: '{{passwordMinimumLength}} の長さが60文字です',
            REQUIRE_START_TEXT: '少なくとも{{value}}を含む',
            NUMBER_TEXT: '数字',
            LOWER_CASE_TEXT: '小文字',
            SYMBOLS_TEXT: '特殊記号',
            UPPER_CASE_TEXT: '大文字の文字',
            REQUIRE_ALL_RULES_TEXT: '大文字/小文字の英数字と特殊記号を含むこと',
            SUPPORTED_CHARACTERS_TEXT: '大文字、小文字、数字、特殊記号のみ対応しています',
        }
    },
    VERIFY_CODE: {
        TITLE: '認証コード',
        PLACEHOLDER: '認証コードを入力してください...',
        SEND_BTN_TEXT: '送信',
        SEND_BTN_FULL_TEXT: 'コードを送信',
        GET_CODE_ERROR_MESSAGE: '画像認証コードの取得に失敗しました',
        REQUIRED_ERROR_MESSAGE: '認証コードを入力する必要があります',
        PATTERN_ERROR_MESSAGE: '認証コードの形式が正しくありません',
        SEND_ERROR: '認証コードの送信に失敗しました：',
        SENDING_TEXT_MESSAGES_TOO_FREQUENTLY_PLEASE_TRY_AGAIN_LATER: 'テキストメッセージの送信頻度が高すぎます。しばらくしてからもう一度お試しください',
    },
    MOBILE_PHONE: {
        TITLE: '携帯番号',
        PLACEHOLDER: '電話番号を入力してください...',
        REQUIRED_ERROR_MESSAGE: '携帯番号を空にすることはできません!',
        PATTERN_ERROR_MESSAGE: '携帯番号の形式が正しくありません。',
        EXISISTED_ERROR_MESSAGE: '電話番号が登録されています、電話番号を変更するかログインしてください！',
        NOT_EXISIST_ERROR_MESSAGE: '電話番号が登録されていません、電話番号を変更するか、先に登録をしてください! ',
    },
    ACCOUNT: {
        TITLE: 'アカウント番号',
        PLACEHOLDER: 'アカウントを入力してください...',
        REQUIRED_ERROR_MESSAGE: 'アカウントを空にすることはできません',
        PATTERN_ERROR_MESSAGE: 'アカウントの形式が正しくありません',
    },
    RESET_PASSWORD: {
        TITLE: 'パスワードをリセット',
        RESET_BTN_TEXT: 'リセットを確認',
        SUCCESS_MESSAGE: 'リセットに成功しました',
        ERROR_MESSAGE: 'リセットに失敗しました：'
    },
    LOGIN_BY_ACCOUNT: {
        TITLE: 'アカウントログイン',
    },
    LOGIN_BY_MOBILE_AND_VERIFY: {
        TITLE: '認証コードでログイン',
    },
    LOGIN_BY_MOBILE_AND_PASSWORD: {
        TITLE: 'パスワードでログイン',
    },
    LOGIN_BY_WECHAT_MP: {
        TITLE: 'WeChat承認済みログイン',
        LOGIN_BTN_TEXT: '許可されたログイン',
        ERROR_MESSAGE: {
            NOT_CONFIG: 'WeChatパブリックプラットフォーム情報が構成されていません。カスタマーサービスまたは管理者に連絡してください。',
            CONFIG_ERROR: 'WeChatの構成データが正しくありません。カスタマーサービスまたは管理者に連絡してください。',
            WECHAT_NOT_REGISTER: 'このWeChatはまだ登録されていませんので、携帯番号または他のWeChatでログインしてください!',
            DEFAULT_TEXT: 'Wechatのログインに失敗しました。更新して、ログインを再度承認してください'
        }
    },
    LOGIN_BY_WECHAT: {
        TITLE: 'WeChat ログイン'
    },
    LOGIN_BY_PHONE: {
        TITLE: '携帯番号でログイン'
    },
    RESPONSE_ERROR_MESSAGE: 'ログインに失敗しました：',
    REGISTER_ERROR_MESSAGE: '登録に失敗しました：',
    CHANGE_ERROR_MESSAGE: '編集に失敗しました：',
    AUTO_LOGIN_TEXT: 'ログインしたままにする',
    FORGOT_PASSWORD_TEXT: 'パスワードを忘れた場合',
    LOGIN_TEXT: 'ログイン',
    LOGOUT_TEXT: 'ログアウト',
    RELOAD_TEXT: '再試行',
    METHOD_ERROR_MESSAGE: '利用可能なログイン方法を取得できませんでした',
    OTHER_LOGIN_METHOD_TEXT: 'その他のログイン方法~',
    USER_NOT_EXIST_EROOR_TEXT: '現在のアカウントは存在しません~',
    INFOMATION: {
        ERROR_MESSAGE: 'アカウント情報の取得に失敗しました',
        WELECOME_TEXT: 'お帰りなさい~',
        HOME_BTN_TEXT: 'アカウントセンター',
    },
    WECHAT_AUTHORIZATION_SUCCESS: 'WeChat認証が成功しました！',
    AUTHORIZATION: '承認する',
    QRCODE: 'スキャンコード',
    FAILED_TO_BIND_WECHAT: 'WeChatのバインドに失敗しました：',
    FAILED_TO_UNBIND_WECHAT: 'WeChatのバインド解除に失敗しました：',
    FAILED_TO_BIND: 'バインドに失敗しました：',
    WECHAT_CODE_VERIFICATION_FAILED_PLEASE_TRY_AGAIN: 'WeChat コード チェックに失敗しました',
    CURRENT_ACCOUNT_IDENTITY: '現在の ID',
    FAILED_TO_GET_LOGIN_REGISTRATION_METHOD: 'ログイン/登録方法の取得に失敗しました',
    FAILED_TO_OBTAIN_PERSONAL_INFORMATION: '個人情報の取得に失敗しました!',
    SIGN_OUT_AND_SIGN_IN_SUCCESSFULLY: 'ログインを終了しました',
    OTHER_REGISTRATION_OR_LOGIN_METHODS: 'その他の{{value}}メソッド',
    REGISTRATION_TEXT: '登録',
    REGISTER_BTN_TEXT: 'アカウントを作成',
    SIGN_UP_NOW: '今すぐ登録する',
    LOG_IN_IMMEDIATELY: 'すぐにログインする',
    ALREADY_HAVE_AN_ACCOUNT: 'アカウントをお持ちですか?',
    DONT_HAVE_AN_ACCOUNT_YET: 'アカウントはまだお持ちませんか?',
    MOBILE_NUMBER_REGISTRATION: {
        TITLE: '携帯電話で登録'
    },
    WECHAT_REGISTRATION: {
        TITLE: 'WeChatで登録'
    },
    CAPTCHA_VERIFY_CODE: '画像認証コード',
    CAPTCHA_VERIFY_CODE_PLACEHOLDER: '画像認証コードを入力してください',
    CAPTCHA_VERIFY_CODE_NULL_ERROR: '画像認証コードを空にすることはできません',
    CAPTCHA_VERIFY_CODE_INVALID_ERROR: '画像認証コードは、少なくとも4桁です!',
    MESSAGE_VERIFY_CODE: '携帯電話の認証コード',
    REFERRAL_CODE: '紹介コード',
    REFERRAL_CODE_PLACEHOLDER: '紹介コードを入力する',
    SECOND: '秒',
    PHONE_NULL_TIPS: '最初に携帯番号を入力してください!',
    PHONE_NOT_REGISTER_TIPS: '現在の電話番号は登録されていません!',
    PHONE_HAS_REGISTERED_TIPS: '現在の電話番号が登録されています!',
    CAPTCHA_VERIFY_CODE_ERROR_TIPS: '画像認証コードが正しく入力されていません!',
    CAPTCHA_VERIFY_CODE_NULL_TIPS: '画像認証コードを入力してください',
    AGREEMENT_NOT_CONFIRM_TIPS: '契約はチェックされません!',
    LOGGING_IN_TIPS: 'サインイン中',
    SIGN_IN_SUCCESSFULLY: 'ログインに成功しました',
    PASSWORD_TEXT: 'パスワード',
    ACCOUNT_LOGIN: 'アカウントにログインします',
    LOGIN_BY_OTHER_WECHAT_ACCOUNT: 'その他のWeChatログイン',
    ACCOUNT_REGISTER: {
        TITLE: 'アカウントを登録します',
        EXISISTED_ERROR_MESSAGE: '電話番号は登録されています！',
        NOT_EXISIST_ERROR_MESSAGE: '電話番号が登録されていません！',

    },
    REGISTERING: '登録',
    REGISTRATION_SUCCESS: '登録は成功しました',
    WECHAT_AUTHORIZED_REGISTRATION: 'WeChat は登録を承認します',
    BIND_AND_REGISTER: 'バインドして登録します',
    WECHAT_SCAN_CODE_REGISTRATION: 'WeChatスキャンコード登録',
    BIND_MOBILE_NUMBER: '携帯番号をバインドします',
    WECHAT_REGISTERED_AND_AUTO_LOGGING_TIPS: 'WeChatは、自動的にログインするために登録されています...',
    IDENTITY_EXPIRED_AND_REAUTHORIZE_TIPS: '現在の ID の有効期限が切れています',
    AGREE_BY_ACTION_TYPE: '{{action}}は同意を意味します',
    I_HAVE_READ_AND_AGREE_TIPS: '同意してログイン',
    WECHAT_SCAN_CODE_AUTHORIZATION: 'WeChat スキャン コード認証',
    WECHAT_AUTHORIZATION: 'WeChat ライセンス',
    WECHA_PLATFORM_NOT_CONFIRGURED_ERROR_TIPS: 'バックグラウンドで WeChat パブリック プラットフォーム情報が構成されていない場合は、管理者にお問い合わせください。',
    CURRENT_WECHAT_AUTHORIZATION_FAILED_TIPS: '現在の WeChat ステータスの有効期限が切れているかどうか、および再承認するかどうか',
    REAUTHORIZE: '再承認します',
    GET_CURRENT_WECHAT_ID_FAILED_TIPS: 'WeChat ID を取得できませんでした。',
    SUCCESSFULLY_BIND_WECHAT: 'バインドされたWeChatは成功しました!',
    AUTHORIZE_SUCCESSFULLY_AND_AUTO_LOGGING_TIPS: '認証は成功し、ログイン中...',
    WECHAT_PLATFORM_SCRET_ERROR_TIPS: '現在のバックグラウンドでは正しい WeChat キーが構成されていないため、WeChat の承認ログインを実行できません。',
    WECHAT_NOTREGISTERED_ERROR_TIPS: 'Wechatは現在登録されていません。wechat登録ページに移動する必要がありますか、それとも更新して再試行する必要がありますか？',
    WECHAT_NOTREGISTERED_OR_NOT_FOUND_PHONE_ERROR_TIPS: '現在の WeChat は携帯番号を登録またはバインドしていませんが、WeChat 登録ページに移動するか、アカウントでサインインする必要がありますか?',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS_FOR_PHONE_LOGIN_ENABLE: '現在、WeChatは認証されていませんが、WeChat認証でログインする必要がありますか、それともアカウントでログインする必要がありますか？',
    WECHAT_NOT_AUTHORIZATION_FAILED_ERROR_TIPS: '現在、WeChatは認証されていませんが、WeChat認証でログインする必要がありますか、それとも更新して再試行する必要がありますか？',
    NEW_PASSWORD: '新しいパスワード',
    PLEASE_READ_AND_CHECKED_THE_TERMS: '利用規約を読み、チェックしてください',
    PHONE_OR_CAPTCHA_CODE_NULL: '最初に電話番号または画像認証コードを入力してください',
    OLD_PASSWORD: '旧パスワード',
    OLD_PASSWORD_PLACEHOLDER: '旧パスワードを入力してください',
    RESETING: 'リセット中',
    RESET_SUCCESSFULLY: 'リセットに成功しました',
    MESSAGE_CODE_SEND_FAILED: 'SMS認証コードの送信に失敗しました',
    EMAIL_CODE_SEND_FAILED: '電子メール認証コードの送信に失敗',
    EMAIL_VERIFY_CODE: '認証コード',
    EMAIL_NULL_TIPS: '最初にメールアドレスを入力してください',
    EMAIL_NOT_REGISTER_TIPS: '現在のメールアドレスが登録されていない',
    EMAIL_HAS_REGISTERED_TIPS: '現在のEメールが登録されている!',
    EMAIL: {
        TITLE: '電子メール',
        PLACEHOLDER: 'メールアドレスを入力してください...',
        REQUIRED_ERROR_MESSAGE: 'メールアドレスを空にすることはできません!',
        PATTERN_ERROR_MESSAGE: 'メールアド レスのフォーマットが正しくありません！',
        EXISISTED_ERROR_MESSAGE: 'メールアドレスは登録されています、メールボックスを変更するかログインしてください！',
        NOT_EXISIST_ERROR_MESSAGE: 'メールアドレスが登録されていません、メールアドレスを変更するか、先に登録してください！',
    },
    REGISTRATION_BY_PHONE: {
        TITLE: '携帯電話で登録'
    },
    LOGIN_BY_EMAIL: {
        TITLE: 'メールでログイン'
    },
    REGISTRATION_BY_EMAIL: {
        TITLE: 'メールで登録'
    },
    BIND_EMAIL: 'メールをバインドする',
    EMAIL_VERIFY_CODE_PLACEHOLDER: 'メール認証コードを入力してください',
    SEND_EMAIL_VERIFY_CODE_BTN_TEXT: 'メール認証コードを送信',
    OLD_EMAIL_CAPTCHA_ERROR: 'オリジナルの電子メール画像認証コードの入力に誤りがありました',
    NEW_EMAIL: '新しいメールアド レス',
    OLD_EMAIL: '旧メールアドレス',
    LOGIN_BY_NAME: {
        TITLE: 'アカウント名ログイン',
    },
    LOGIN_NAME: {
        TITLE: 'アカウント名',
        PLACEHOLDER: 'アカウント名を入力してください...',
        REQUIRED_ERROR_MESSAGE: 'アカウント名を空にすることはできません!',
        PATTERN_ERROR_MESSAGE: 'アカウント名が正しい形式ではありません!',
        NOT_EXISIST_ERROR_MESSAGE: '現在のアカウントは存在しません、アカウント名を確認するか、最初に登録してください!',
    },
};
