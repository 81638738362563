import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { Layout } from '../../shared/model/layouts.model';

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
type EntityResponseType = HttpResponse<Layout>;
type EntityArrayResponseType = HttpResponse<Layout[]>;
@Injectable({ providedIn: 'root' })
export class LayoutService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/layouts';

    constructor(
        private http: HttpClient,
    ) { }

    create(layouts: Layout): Observable<EntityResponseType> {
        return this.http
            .post<Layout>(this.resourceUrl, layouts, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(layouts: Layout): Observable<EntityResponseType> {
        return this.http
            .put<Layout>(`${this.resourceUrl}/${layouts.id}`, layouts,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http
            .get<Layout>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityResponseType) => res));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        return this.http
            .get<Layout[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
}
