import { Site } from './site.model';
import { Menu } from './menu.model';
import { Page } from './page.model';
import { Layout } from './layouts.model';

export class SiteGraph {
    site: Site;
    menus: Menu[];
    pages: Page[];
    layouts: Layout[];
    currentPage: Page;
    errorMock?: boolean;
}
