import { Input, Directive, ElementRef, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * A wrapper directive on top of the translate pipe as the inbuilt translate directive from ngx-translate is too verbose and buggy
 */
@Directive({
    selector: '[appTranslate]',
})
export class TranslateDirective implements OnChanges, OnInit, OnDestroy {
    @Input() appTranslate!: string;
    @Input() translateParams?: { [key: string]: unknown };
    // 增加了个属性，可以通过这个传递进来想设置的别的属性，如title，placehold之类的
    @Input() translateProperty = 'innerHTML';

    private readonly directiveDestroyed = new Subject();

    constructor(private el: ElementRef, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.translateService.onLangChange.pipe(takeUntil(this.directiveDestroyed)).subscribe(() => {
            this.getTranslation();
        });
        this.translateService.onTranslationChange.pipe(takeUntil(this.directiveDestroyed)).subscribe(() => {
            this.getTranslation();
        });
    }

    ngOnChanges(): void {
        this.getTranslation();
    }

    ngOnDestroy(): void {
        this.directiveDestroyed.next(null);
        this.directiveDestroyed.complete();
    }

    private getTranslation(): void {
        try {
            this.translateService
                .get(this.appTranslate || '', this.translateParams)
                .pipe(takeUntil(this.directiveDestroyed))
                .subscribe({
                    next: value => {
                        // 对于未获取到当前语言时，不进行替换
                        if (this.translateService.currentLang && ![undefined, null]?.includes(value)) {
                            this.el.nativeElement[this.translateProperty || 'innerHTML'] = value;
                        }
                    },
                    error: () => { },
                });
        } catch (error) {

        }
    }
}
