import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '../../../../../i18n';
import { CurrentDeviceService } from '../../../../core/services/current-device.service';
import { ProductService } from '../../service/product.service';
import { SiteContextService } from '../../../../shared/services/site-context.service';
import { UrlHelperService } from '../../../../core/services/url-helper.service';
import { UserAccountService } from '../../../../core/services/user-account.service';
import { ShoppingCartService } from '../../../builtin-pages/shopping-cart/_service/shopping-cart.service';
import { jsonParse, NameTranslatePipe } from '../../../../shared/pipes/name-translate.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ShopContextService } from '../../service/shop-context.service';
import { NumberTransformPipe } from '../../../../shared/pipes/number-transform.pipe';
import { ToastService } from '../../../../shared/services/toast.service';
import { defaultSite } from '../../../../shared/model/site.model';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss'],
    providers: [
        NameTranslatePipe,
        NumberTransformPipe,
    ]
})
export class ProductComponent implements OnInit {
    id: any;
    saleQuantity: any;
    @Input() productDatas: any
    @Input() participant: string;
    @Output() voted = new EventEmitter<string>();
    @Output() clickBtn = new EventEmitter<boolean>();
    @Input() isSliding: boolean;
    @Input() salesVisible: boolean;
    @Input() oneRemOfMarginTop: boolean;
    @Input() originalPriceVisible: boolean;
    @Input() taxIncludedVisible: boolean;
    @Input() productImageSize: string;
    @Input() enableAddShoppingCartBtn = false;
    @Input() enableDesktopMarginRightAuto = false;
    selectProductData: any = {};
    requestedGroupBuyingCampaigns = false;
    loadingGroupBuyingCampaigns = false;
    count = 1;
    shopId: any;
    enableShoppingCartFeatureOption = true;
    supportLanguages: string[] = [];

    constructor(
        private numberTransformPipe: NumberTransformPipe,
        private siteContextService: SiteContextService,
        public productService: ProductService,
        public urlHelperService: UrlHelperService,
        public currentDeviceService: CurrentDeviceService,
        public toast: ToastService,
        public shopContextService: ShopContextService,
        public translateService: TranslationService,
        public shoppingCartService: ShoppingCartService,
        public userAccountService: UserAccountService,
        public router: Router,
        private nameTranslatePipe: NameTranslatePipe,
        private translationService: TranslateService,
    ) { }

    isEnglishLanguage() {
        return this.translationService.currentLang === 'en-US';
    }

    vote(str: string) {
        this.voted.emit(str);
        this.clickBtn.emit(true);
    }

    ngOnInit() {
        this.shopId = this.shopContextService.shopId;
        let site = this.siteContextService.site;
        this.enableShoppingCartFeatureOption = jsonParse(site?.featureOptions)?.shoppingCart?.enable;
        this.supportLanguages = jsonParse(site?.supportedLanguages || defaultSite.supportedLanguages);
    }

    // 加入购物车
    addToShoppingCart(product) {
        let orderProduct = jsonParse(JSON.stringify(product));
        this.selectProductData = orderProduct;
        if (product.stock <= 0 ||
            !product.stock || !Boolean(product.onShelf)) {
            this.toast.info(this.nameTranslatePipe.transform(this.shopContextService.shopSetting?.mobileAppConfig?.general, 'productName') + this.translateService.instant('GENERAL.OUT_OF_STOCK'), 1500);
            return;
        }
        if (this.selectProductData.quantity > product.stock) {
            this.toast.info(this.nameTranslatePipe.transform(this.shopContextService.shopSetting?.mobileAppConfig?.general, 'productName') + this.translateService.instant('GENERAL.PRODUCT_QUANTITY_EXCEEDS_AVAILABLE_STOCK'), 1500);
            return;
        }
        const customerId = this.siteContextService.customer()?.id;
        const name = this.siteContextService.customer()?.name;
        const mobilePhone = this.siteContextService.customer()?.mobilePhone;
        // 拼团商品在列表页加入商品统一按不参与活动的方式处理
        // 参考京东逻辑，多规格商品只取第一条sku
        const enableAddShoppingCardSkus = product?.variants?.filter(variant => variant?.sku?.stock);
        if (enableAddShoppingCardSkus?.length) {
            // 排除掉无货的sku，取有货的第一条
            try {
                let variantId = enableAddShoppingCardSkus?.[0]?.id;
                let sku = enableAddShoppingCardSkus?.[0]?.sku;
                let price = sku?.price || product?.price;
                orderProduct = {
                    customerId: customerId,
                    customerName: name ? name : mobilePhone,
                    price,
                    quantity: 1,
                    skuSpecificationValues: sku?.name,
                    productName: orderProduct.productName,
                    productNameTranslation: product.nameTranslation,
                    imgUrl: sku.imgUrl || orderProduct.imgUrl,
                    productId: orderProduct.productId,
                    combined: this.selectProductData.combined,
                    volume: sku.volume || this.selectProductData.volume,
                    weight: sku.weight || this.selectProductData.weight,
                    stock: sku.stock || this.selectProductData.stock, // 库存
                    saleType: this.selectProductData.saleType,
                    presaleType: this.selectProductData.presaleType,
                    logisticsNeeded: this.selectProductData.logisticsNeeded,
                    presaleDeliveryType: this.selectProductData.presaleDeliveryType,
                    presaleDeliveryFixedDate: this.selectProductData.presaleDeliveryFixedDate,
                    extendedType: this.selectProductData.extendedType,
                    extendedId: this.selectProductData.extendedId,
                    presaleDeliveryDaysAfterPayment: this.selectProductData.presaleDeliveryDaysAfterPayment,
                    skuId: sku.id,
                    productVariantId: variantId,
                    stockControl: this.selectProductData.stockControl,
                    specialRequirement: product.specialRequirement,
                    specialRequirementType: product.specialRequirementType,
                    specialRequirementParam1: product.specialRequirementParam1,
                    specialRequirementParam2: product.specialRequirementParam2,
                    specialRequirementParam3: product.specialRequirementParam3,
                    currency: this.selectProductData.currency,
                };
            } catch (error) {
                console.log('error:', error);
            }
        }
        if (this.userAccountService.userLogin(this.router)) {
            this.shoppingCartService
                .create({
                    customerId: customerId, // 用户id
                    customerName: name ? name : mobilePhone, // 用户名
                    ...orderProduct,
                    productId: product?.id,
                    id: undefined,
                    quantity: 1,
                })
                .subscribe(
                    (res) => {
                        this.toast.success(this.translateService.instant('GENERAL.ADD_TO_CART_SUCCESSFUL'), 1200);
                    },
                    (error) => {
                        this.toast.info(this.translateService.instant('GENERAL.ADD_FAILED'), 1500);
                    }
                );
        }
    }

    getFormatPriceStr(price: string) {
        if (String(price)?.length < (!(this.taxIncludedVisible && this.salesVisible) ? 9 : 6)) {
            return price;
        }
        return this.numberTransformPipe.transform(price);
    }
}
