<div class="carousel-wrapper" appBricsAnimateOnIntersection
    [bricsAnimationName]="renderOptions?.animation?.name + ((renderOptions?.animation?.direction ? ('-'+renderOptions?.animation?.direction):''))"
    [bricsAnimationDuration]="renderOptions?.animation?.duration"
    [bricsAnimationDelay]="renderOptions?.animation?.delay" [emitOnce]="renderOptions?.animation?.emitOnce"
    [ngStyle]="renderOptions?.container"
    [ngClass]="{'banner-top':!carouselConfig?.fullWidth,'full-width-carousel':carouselConfig?.fullWidth,'full-width-carousel-for-pc-aside':carouselConfig?.fullWidth&&(asideWidth!=='0px')}"
    [style]="carouselConfig?.fullWidth?
    'height: calc(calc(100vw - ' + asideWidth + ') * ' + carouselConfig?.suggestedHeight + ' / ' + carouselConfig?.suggestedWidth + ');'
    :''">
    <div class="index-carousel"
        [style]="carouselConfig?.fullWidth?
        'width: 100%;height: calc(calc(100vw - ' + asideWidth + ') * ' + carouselConfig?.suggestedHeight + ' / ' + carouselConfig?.suggestedWidth + ');':
        ('height: calc(min(var(--content-width),100%) * ' + carouselConfig?.suggestedHeight + ' / ' + carouselConfig?.suggestedWidth + ';')">
        <app-carousel [carouselItems]="carousels" [enableViewBigPicture]="false"
            [contentRenderOptions]="renderOptions?.content"
            [rounded]="!carouselConfig?.fullWidth && carouselConfig?.rounded" [duration]="carouselConfig?.duration"
            [fullWidth]="carouselConfig?.fullWidth">
        </app-carousel>
    </div>
</div>