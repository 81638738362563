<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="_renderOptions" [enabledMoreBtn]="false" [section]="section" [attachStyleToInnerContent]="true">
    <div class="mail-subscribe-container">
        <div class="d-flex flex-wrap mail-subscribe-content"
            [ngClass]="{'padding-x-for-mobile':_renderOptions?.fullWidth}">
            <div class="mail-subscribe-real-content w-100"
                [style.paddingTop]="(_renderOptions?.title?.display === 'none') ? '1rem' : ''">
                <div style="width: 60%;margin: auto;"
                    [style.minWidth]="_renderOptions?.fullWidth!==true ? 'min(80vw,390px)' : 'min(calc(100% - 2rem),var(--content-width))'">
                    <div class="row">
                        <ng-container *ngIf="data.direction === DirectionType.COLUMN">
                            <ng-container *ngFor="let item of getHorizontalPositionList()">
                                <div class="col-12">
                                    <ng-container *ngTemplateOutlet="customizeContentRef;context:{item:item}">
                                    </ng-container>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="data.direction !== DirectionType.COLUMN">
                            <div class="col-lg-6 col-sm-12">
                                <ng-container
                                    *ngFor="let item of getHorizontalPositionList(HorizontalPositionType.LEFT)">
                                    <ng-container *ngTemplateOutlet="customizeContentRef;context:{item:item}">
                                    </ng-container>
                                </ng-container>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <ng-container
                                    *ngFor="let item of getHorizontalPositionList(HorizontalPositionType.RIGHT)">
                                    <ng-container *ngTemplateOutlet="customizeContentRef;context:{item:item}">
                                    </ng-container>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-block-container>

<ng-template #customizeContentRef let-item="item">
    <div [ngSwitch]="item?.type">
        <div *ngSwitchCase="CustomizeContextType.IMAGE">
            <img class="customize-image" [src]="item?.data | uploadUrlPrefix" alt="">
        </div>
        <div *ngSwitchCase="CustomizeContextType.RICHTEXT">
            <div class="ql-container ql-snow w-100" style="border: none;">
                <div class="ql-editor b-r" [innerHTML]="(item.data | mNameTranslate:'value') | uploadUrlPrefix:'true'">
                </div>
            </div>
        </div>
        <div *ngSwitchCase="CustomizeContextType.INPUT_CONTROL"
            [style]="data.direction === DirectionType.COLUMN?'text-align: center;':'padding: 0 15px;'">
            <!-- (ngSubmit)="search()" -->
            <form class="search-form d-flex align-items-center">
                <div class="search-input"
                    style="flex: 1;display: inline-flex;align-items: center;position: relative;background-color: #fff;border-radius: 20px;height: 2.4rem;vertical-align: middle;border: 1px solid #eee;max-width: 18rem;">
                    <input type="email" class="form-control-sm form-control border-0" autocomplete="off"
                        name="subscribeEmail" [(ngModel)]="subscribeEmail" style="box-sizing: border-box;
                        margin-right: 1rem;
                        margin-left: 1rem;
                        flex: 1;
                        outline: none;
                        box-shadow: none;
                        min-height: auto;" [placeholder]="item.data | mNameTranslate:'inputTip'">
                </div>
                <button class="btn bg-warning btn-reflush ellipsis" style="cursor: pointer;margin-left: 1rem;"
                    (click.stop)="subscribeAction(item.data)" type="submit" [title]="item.data |
                    mNameTranslate:'button'">{{item.data |
                    mNameTranslate:'button'}}</button>
            </form>
        </div>
    </div>
</ng-template>