import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../app.constants';

export class OwnerApp {
    'id': string;
    'name': string;
    'enabled': boolean;
    'edition': string;
    'new': boolean;
    'visible': boolean;
}

@Injectable({
    providedIn: 'root'
})
export class OwnerAppsService {
    private resourceUrl = API_SERVICE_PREFIX + '/base/api/apps';
    constructor(
        private http: HttpClient,
    ) { }

    query(req: any = {
        'visible.equals': true,
        size: 100
    }): Observable<OwnerApp[]> {
        return this.http.get<OwnerApp[]>(this.resourceUrl, {
            params: req
        });
    }
}
