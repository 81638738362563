export const affiliateTranslate = {
    GENERAL: {
        CASH: 'cash rewards',
        AFFILIATE_CODE: 'Affiliate code',
        AFFILIATE_DISCOUNT: 'Affiliate offers',
        REFERRAL_DISCOUNT: 'Referral offers',
        PENDING_STATUS: 'Pending payment',
        CONFIRMED_STATUS: 'Confirmed',
        SETTLED_STATUS: 'Settled',
        COMPLETED_STATUS: 'Completed',
        CANCELLED_STATUS: 'Cancelled',
        CASH_TEXT: 'Cash',
        COUPON: 'Coupon',
        CONSUMING_CARD: 'Consuming card',
        GIFT_CARD: 'Gift card',
        POINTS: 'Points',
        PRODUCT: 'Product',
        APPROVED: 'Approved',
        PENDING: 'Pending',
        REJECTED: 'Rejected',
        CANCELLED: 'Cancelled',
        SETTLED: 'Settled',
    },
    SHARE_MODAL: {
        MARKET_REWARD: 'Promote to earn ',
        INVITE_FRIEND_START_TIP: 'Invite your friends to buy this {{productName}} successfully and get up to',
        COMMISSION_START_TIP: 'To view earnings and withdrawals, please go to ',
        OPERATION: 'operation',
        COPY_BUTTON: 'Copy share link',
        REWARD: 'Reward',
    }
}