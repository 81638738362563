import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { API_SERVICE_PREFIX } from 'src/app/app.constants';
import { UrlHelperService } from '../../core/services/url-helper.service';

@Pipe({
    name: 'uploadUrlPrefix'
})
export class UploadUrlPrefixPipe implements PipeTransform {
    constructor(
        private urlHelperService: UrlHelperService,
        private sanitizer: DomSanitizer,) {
    }
    transform(content: string, isRichText?: boolean): string | SafeHtml {
        if (isRichText) {
            return this.uploadFileUrlInRichTextPrefix(content);
        } else {
            return this.uploadFileUrlPrefix(content);
        }
    }
    uploadFileUrlPrefix(url: string) {
        // 如果是阿里云路径
        if (this.urlHelperService.isOssUrl(url)) {
            return url;
            //  如果是相对路径
        } else if (!this.urlHelperService.isAbsoluteUrl(url) && url?.includes('/api/') && !url?.startsWith(API_SERVICE_PREFIX)) {
            if (url?.startsWith('/')) {
                return `${API_SERVICE_PREFIX}${url}`?.replace(/\/\//g, '/');
            }
            return `${API_SERVICE_PREFIX}/${url}`?.replace(/\/\//g, '/');
        }
        return url;
    }
    uploadFileUrlInRichTextPrefix(richText: string) {
        if (typeof richText === 'string') {
            return this.sanitizer.bypassSecurityTrustHtml(richText?.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/g, (matchImg, fileUrl) => {
                return matchImg?.replace(fileUrl, this.uploadFileUrlPrefix(fileUrl));
            }));
        }
        return richText;
    }
}
