<app-block-container [blockId]="blockId" [title]="title" [titleTranslation]="titleTranslation"
    [renderOptions]="_renderOptions" [enabledMoreBtn]="false" [section]="section" [attachStyleToInnerContent]="true">
    <div class="pull-quotes-container">
        <div class="d-flex flex-wrap pull-quotes-content"
            [ngClass]="{'padding-x-for-mobile':_renderOptions?.fullWidth}">
            <div class="pull-quote-content w-100">
                <div style="max-width: 100%;margin: auto;box-sizing: border-box;"
                    [style.width]="_renderOptions?.fullWidth!==true ? 'min(80vw,390px)' : 'min(calc(100% - 2rem),var(--content-width))'">
                    <!-- Swiper -->
                    <div class="swiper-container pull-quote-thumbs-{{blockId}} w-100">
                        <div class="swiper-wrapper w-100">
                            <div class="swiper-slide" *ngFor="let item of data?.items;">
                                <div class="d-flex justify-content-center align-items-center" style="height: 120px;">
                                    <div style="max-height: 100%;overflow: hidden;display: -webkit-box;
                                -webkit-box-orient: vertical;-webkit-line-clamp: 5;white-space: break-spaces;">
                                        {{item | mNameTranslate:'comment'}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="pullQuoteThumbs?.realIndex !== undefined">
                        <ng-container *ngIf="data?.items?.[pullQuoteThumbs?.realIndex] as item">
                            <div class="w-100 text-end" style="padding: 1rem 0 0;box-sizing: border-box;"
                                [style.paddingBottom]="(data?.items?.length && data?.enableQuoteList) ? '' : '1rem'"
                                *ngIf="(item?.iconUrl||!data?.enableQuoteList)&&(item | mNameTranslate)">
                                —— {{item | mNameTranslate}}
                                <img *ngIf="item?.iconUrl && !(data?.items?.length && data?.enableQuoteList)"
                                    [src]="item?.iconUrl | uploadUrlPrefix"
                                    style="object-fit: contain;max-width: 5rem;max-height: 3rem;margin-left: 1rem;"
                                    [alt]="item | mNameTranslate" [title]="item | mNameTranslate">
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <!-- Add Pagination -->
                <ng-container *ngIf="data?.items?.length && data?.enableQuoteList">
                    <div class="logo-thumbs d-flex justify-content-evenly align-items-center">
                        <ng-container *ngFor="let item of data?.items;let i = index;">
                            <div class="logo-item cursor-pointer"
                                [ngClass]="{'logo-item-active':pullQuoteThumbs?.realIndex===i}"
                                (click.stop)="slideToTargetIndex(i)">
                                <img *ngIf="item?.iconUrl" [src]="item?.iconUrl | uploadUrlPrefix"
                                    style="object-fit: contain;max-width: 6rem;max-height: 6rem;"
                                    [alt]="item | mNameTranslate" [title]="item | mNameTranslate">
                                <ng-container *ngIf="!item?.iconUrl">{{item | mNameTranslate}}</ng-container>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
            <div class="footer-content w-100">
                <div class="ql-container ql-snow" style="border: none;">
                    <div class="main-content ql-editor b-r"
                        [innerHTML]="(data | mNameTranslate:'additionalText') | uploadUrlPrefix:'true'">
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-block-container>