export const contentManagementTranslate = {
    POST: {
        LIKED_CANCELLED: '已取消點贊！',
        CANNOT_REPEAT_LIKES: '不能重複點贊！',
    },
    FORMS: {
        GET_FORM_FAILED: '获取表单失败：',
        DISABLED_SUBMIT_FOR_NO_DATA: '當前未填寫任何數據，無法提交！',
        GET_FORM_DATE_ERROR: '獲取表單數據失敗：',
    },
    ERROR: {
    },
    GENERAL: {
    }
};
