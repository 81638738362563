import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { ICustomerDTO } from '../model/customer-dto.model';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs/esm';

const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});
@Injectable({ providedIn: 'root' })
export class CustomerService {
    private resourceUrl = API_SERVICE_PREFIX + '/customer/api/customers';

    constructor(
        private http: HttpClient,
    ) { }

    create(customer: ICustomerDTO): Observable<HttpResponse<ICustomerDTO>> {
        return this.http.post<ICustomerDTO>(this.resourceUrl, customer, { observe: 'response' });
    }

    update(customer: ICustomerDTO): Observable<HttpResponse<ICustomerDTO>> {
        return this.http.put<ICustomerDTO>(`${this.resourceUrl}/${customer.id}`, customer, { observe: 'response' })
            .pipe(map(this.convertDateFromServer));
    }

    query(query?: any): Observable<HttpResponse<ICustomerDTO[]>> {
        return this.http.get<any>(this.resourceUrl, {
            params: query,
            observe: 'response', headers: headerOptions
        });
    }

    getCurrent(headerOptions?: HttpHeaders): Observable<HttpResponse<ICustomerDTO>> {
        return this.http
            .get<ICustomerDTO>(`${this.resourceUrl}/current`, { headers: headerOptions, observe: 'response' })
            .pipe(map((res: HttpResponse<ICustomerDTO>) => this.convertDateFromServer(res)));
    }

    protected convertDateFromServer(res: HttpResponse<ICustomerDTO>): HttpResponse<ICustomerDTO> {
        if (res.body) {
            if (res.body.customerAndMemberDTOList) {
                res.body.customerAndMemberDTOList.forEach(item => {
                    item.createdDate = item.createdDate ? dayjs(item.createdDate) : undefined;
                    item.lastModifiedDate = item.lastModifiedDate ? dayjs(item.lastModifiedDate) : undefined;
                    item.lastLoginTime = item.lastLoginTime ? dayjs(item.lastLoginTime) : undefined;
                    item.dueTime = item.dueTime ? dayjs(item.dueTime) : undefined;
                    item.effectiveTime = item.effectiveTime ? dayjs(item.effectiveTime) : undefined;
                });
            }
            // this.authServerProvider.storageWxOpenId(res.body.wxOpenId);
            res.body.lastLoginTime = res.body.lastLoginTime ? dayjs(res.body.lastLoginTime) : undefined;
            res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
            res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
        }
        return res;
    }
}
