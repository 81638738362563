import { Component, Input, OnInit } from '@angular/core';
import { TranslationService } from '../../../i18n';
import { PartnerType } from '../../builtin-pages/user-center/pages/cash-settlement/services/cash-settlement.service';
import { CurrentDeviceService } from '../../core/services/current-device.service';
import { MarketingPartnerNetwork, MarketingPartnerNetworkService } from '../../ecommerce/shared/service/marketing-partner-networks.service';
import { Site } from '../../shared/model/site.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { getSelectedLang, languageConfig, setLang } from '../../shared/pipes/selected-language-helper.pipe';
import { DeveloperInfoService, IDeveloperInfo } from '../../shared/services/developer-info.service';
import { SiteContextService } from '../../shared/services/site-context.service';
import { OwnerInfo, OwnerInfoService } from '../../core/services/owner-info.service';
import { ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-footer-block',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterBlockComponent implements OnInit {
    @Input() blockId: string = '';
    @Input() site: Site;
    @Input() set renderOptions(value: any) {
        if (value) {
            const tempValue = value?.container?.background || {};
            const backgroundValue = jsonParse(tempValue?.data);
            const backgroundColor = String(backgroundValue?.customizeColorValue || backgroundValue?.color)?.toLowerCase();
            this._renderOptions = {
                ...value,
                container: {
                    color: 'var(--footer-tit-color)',
                    'background': 'var(--footer-bg-color)',
                    ...value?.container,
                    'background-color': backgroundColor
                },
                fullWidth: true,
                innerContentWidthLimit: true,
            };
        }
    }
    @Input()
    set data(value: any) {
        this._data = {
            ... value,
            customizedHtml: value?.customizedHtml ? this.sanitizer.bypassSecurityTrustHtml(value?.customizedHtml) : null,
        }
    };
    get data() {
        return this._data;
    }
    private _data: any = {};
    @Input() section: string;
    _renderOptions: any = {
        fullWidth: true,
        innerContentWidthLimit: true,
    };
    developerInfo: IDeveloperInfo;
    countries: {
        langKey: string;
        nameKey: string;
    }[] = [];
    affiliateNetworks: MarketingPartnerNetwork[] = [];
    ownerInfo: OwnerInfo = {};
    supportEcommerceFunc: boolean = false;

    constructor(
        private ownerInfoService: OwnerInfoService,
        public currentDeviceService: CurrentDeviceService,
        private developerInfoService: DeveloperInfoService,
        public siteContextService: SiteContextService,
        private translationService: TranslationService,
        private marketingPartnerNetworkService: MarketingPartnerNetworkService,
        private cdr: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.developerInfoService.get().toPromise().then(res => {
            this.developerInfo = res;
        });
        this.ownerInfoService.get().toPromise().then(res => {
            this.ownerInfo = res;
            this.cdr.markForCheck();
        });
        const langs = this.translationService.getSiteLanguages();
        this.countries = languageConfig?.filter(lang => {
            return langs?.supportedLanguages?.find(languageItem => lang?.langKey?.toLowerCase() === languageItem?.toLowerCase());
        });
        const siteId = this.siteContextService.siteId;
        if (siteId && this.siteContextService.enabledEcommerce) {
            firstValueFrom(this.marketingPartnerNetworkService.query({
                'siteId.equals': siteId,
                'partnerType.equals': PartnerType.AFFILIATE,
            })).then(res => {
                this.affiliateNetworks = res?.body;
            }).catch(err => {

            });
        }
        this.site = this.siteContextService.site;
        this.cdr.markForCheck();
    }

    selectedLang = getSelectedLang;

    setLang(langKey: string) {
        setLang(langKey, this.translationService);
    };
}
