
export enum TermType {
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    REGISTER_TERM = 'REGISTER_TERM',
    PURCHASE_TERM = 'PURCHASE_TERM',
    RETURN_TERM = 'RETURN_TERM',
}

export interface IShopUserTerm {
    id?: number;
    shopId?: number;
    key?: TermType;
    content?: string;
    name?: string;
    language?: string;
    builtIn?: boolean;
}

export class ShopUserTerm implements IShopUserTerm {
    public id?: number;
    public shopId?: number;
    public key?: TermType;
    public content?: string;
    public name?: string;
    public language?: string;
    public builtIn?: boolean;
    constructor(private shopUserTerm?: IShopUserTerm
    ) {
        if (shopUserTerm) {
            for (const key in shopUserTerm) {
                if (key) {
                    this[key] = shopUserTerm[key];
                }
            }
        }
    }
}
