import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_SERVICE_PREFIX } from '../../app.constants';
import { Layout } from '../../shared/model/layouts.model';
import { Page } from '../../shared/model/page.model';

type EntityResponseType = HttpResponse<Page>;
type EntityArrayResponseType = HttpResponse<Page[]>;
const headerOptions = new HttpHeaders({
    'brics-api-error-notify': 'false',
    'brics-api-success-notify': 'false'
});

// page 的 POST 和 PUT 保存都会连带 blocks 一起保存。 如果某些场合想只保存 page，不涉及 blocks，那么可以用 patch 方法
// GET 方面，get 列表的时候， page 里面不带 blocks， GET 单个 page 的时候会带
@Injectable({ providedIn: 'root' })
export class PageService {
    private resourceUrl = API_SERVICE_PREFIX + '/cms/api/pages';

    constructor(
        private http: HttpClient,
    ) { }

    create(page: Page): Observable<EntityResponseType> {
        return this.http
            .post<Page>(this.resourceUrl, page, { observe: 'response' })
            .pipe(map((res: EntityResponseType) => res));
    }

    update(page: Page): Observable<EntityResponseType> {
        return this.http
            .put<Page>(`${this.resourceUrl}/${page.id}`, page,
                {
                    observe: 'response',
                })
            .pipe(map((res: EntityResponseType) => res));
    }

    find(id: string): Observable<EntityResponseType> {
        return this.http
            .get<Page>(`${this.resourceUrl}/${id}`, { observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityResponseType) => res));
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        return this.http
            .get<Page[]>(this.resourceUrl, { params: req, observe: 'response', headers: headerOptions })
            .pipe(map((res: EntityArrayResponseType) => res));
    }

    delete(id: string): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

}

export const jsonParseStr = (str: string) => {
    if (str) {
        if (typeof str === "string") {
            return JSON.parse(str);
        }
    }
    return str;
}

export const normalizePageBlocks = (page: Layout | Page) => {
    page.blocks = page?.blocks?.filter(block => block.enabled)?.sort((block, otherBlock) => block.seqNum - otherBlock.seqNum)?.
        map(block => {
            block.renderOptions = jsonParseStr(block.renderOptions);
            block.titleTranslation = jsonParseStr(block.titleTranslation);
            block.data = jsonParseStr(block.data);
            block.dataTranslation = jsonParseStr(block.dataTranslation);
            return block;
        });
    return page;
}
