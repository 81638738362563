import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UrlHelperService } from 'src/app/core/services/url-helper.service';
import { CurrentDeviceService } from '../../../core/services/current-device.service';
import { ParseTargetEntityConfigService } from '../../../core/services/parse-target-entity-config.service';
import { jsonParse } from '../../pipes/name-translate.pipe';
import { checkVideoCanAutoPlay } from '../../../../assets/js/tools/utils';
import { FilterTypeEnum } from '../../model/background-setting.model';
import { tranformGradientColorStyle } from '../../../core/model/color-config.model';
import { isPlatformServer } from '@angular/common';
import { Subscription } from 'rxjs';
declare const Swiper: any;

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnChanges {
    @Input() set carouselItems(value: any) {
        this._carouselItems = value;
    }
    get carouselItems() {
        // 过滤掉没有图片或者有视频但不能自动播放的数据
        return this._carouselItems.filter(item => (item?.img || item?.itemUrl) && (this.videoAutoPlayEnabled() || !this.urlHelperService.isVideo(item?.img || item?.itemUrl)));
    }
    private _carouselItems: any;
    @Input() imageMode = 'default';
    @Input() enableViewBigPicture = true;
    @Input() duration = 5000;
    @Input() rounded = true;
    @Input() fullWidth = false;
    // 是否是带缩略图的轮播
    @Input() hasThumbsSwiprer = false;
    @Input() contentRenderOptions: any;
    swiperSlide: any;
    pagination = null;
    particularsStatus = false;
    imgUrl = null;
    // 当前所在的大图索引
    currentBigImgIndex = 0;
    // 展示的当前大图序号
    showBigImgNumber = 1;
    // pc上带有缩略图的图片索引
    currentSelectedThumbsImageIndex = 0;
    currentImageUrl: string;
    imagezoomAreaNativeEleX = 0;
    imagezoomAreaNativeEleY = 0;
    bigImagezoomAreaNativeEleX = 0;
    bigImagezoomAreaNativeEleY = 0;
    thumbsShowStatus: boolean;
    FilterTypeEnum = FilterTypeEnum;
    subscriptions: Subscription[] = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public parseTargetEntityConfigService: ParseTargetEntityConfigService,
        public currentDeviceService: CurrentDeviceService,
        public urlHelperService: UrlHelperService,
        public translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    ngOnInit() {
        const sub = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.particularsStatus = this.router.url.includes('bigImgOpend=true');
            }
        });
        this.subscriptions.push(sub);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.carouselItems?.forEach(item => {
            item.targetEntityConfig = jsonParse(item.targetEntityConfig);
        });
        if (!this.hasThumbsSwiprer) {
            if (Boolean(this.carouselItems) && this.carouselItems.length > 1) {
                this.pagination = true;
                this.initializeSwiper(true);
            } else {
                this.pagination = false;
            }
        } else {
            if (this.carouselItems?.length > 1) {
                this.pagination = true;
                this.initializeSwiper(undefined);
            } else {
                this.pagination = false;
            }
            this.currentImageUrl = this.carouselItems[0]?.img || this.carouselItems[0]?.itemUrl;
        }
        this.cdr.detectChanges();
    }

    close(e) {
        this.particularsStatus = false;
        this.navigateForBackEvent();
        e.stopPropagation();
    }

    jump(index) {
        this.currentBigImgIndex = index;
        this.showBigImgNumber = index + 1;
        if (this.enableViewBigPicture) {
            this.particularsStatus = true;
            this.navigateForBackEvent(true);
        }
        let carouselItem = this.carouselItems;
        this.parseTargetEntityConfigService.navigateLinkedPage(carouselItem[index]?.linkData);
    }

    navigateForBackEvent(open: boolean = false) {
        if (!open) {
            window.history.back();
            return;
        }
        this.router.navigate(['./'], {
            queryParams: {
                bigImgOpend: true
            },
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            state: ['no-loading']
        });
    }

    // 初始化轮播图
    initializeSwiper(type?: boolean) {
        switch (type) {
            case true:
                setTimeout(() => {
                    if (!this.swiperSlide) {
                        this.initBasicCarousel();
                        const sub = this.translateService.onLangChange.subscribe(e => {
                            setTimeout(() => {
                                try {
                                    this.swiperSlide?.destroy();
                                    this.swiperSlide = null;
                                    this.initBasicCarousel();
                                    // this.swiperSlide?.reLoop();
                                } catch (error) {

                                }
                            }, 20);
                        });
                        this.subscriptions.push(sub);
                    }
                });
                break;
            default:
                setTimeout(() => {
                    if (!this.swiperSlide) {
                        this.initHasThumbsCarousel();
                    }
                });
                break;
        }
    }

    initBasicCarousel() {
        try {
            if (Swiper) {
                this.swiperSlide = new Swiper('.swiper-container', {
                    grabCursor: true,
                    setWrapperSize: true,
                    spaceBetween: 30,
                    centeredSlides: true,
                    loop: true, // 设置为true 则开启loop模式。loop模式：会在原本slide前后复制若干个slide(默认一个)并在合适的时候切换，让Swiper看起来是循环的
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true, // 点击分页器是否跳转到对应的页面
                        hideOnClick: false,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    autoplay: {
                        autoplay: true,
                        speed: this.duration || 5000,
                        delay: this.duration || 5000,
                        //   stopOnLastSlide: false, // 播放到最后一张是否停止
                        disableOnInteraction: true // 手动滑动是否停止自动播放
                    },
                    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
                    // observeParents: false,
                    fadeEffect: {
                        crossFade: true
                    },
                    effect: "slide",
                    on: {
                        observerUpdate: () => {
                            this.waitingVideoPlay();
                        },
                    },
                });
                this.waitingVideoPlay();
            }
        } catch (error) {

        }
    }

    initHasThumbsCarousel() {
        try {
            if (Swiper) {
                this.swiperSlide = new Swiper('#thumbs-for-pc-version', {
                    setWrapperSize: true,
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    direction: 'horizontal', // 水平滚动
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                });
            }

        } catch (error) {

        }
    }

    waitingVideoPlay() {
        const checkVideoPlayStatusFunc = function () {
            let currentSlide = this.swiperSlide.slides[this.swiperSlide.activeIndex];
            if (currentSlide?.isEnd) {
                return;
            }
            let video = currentSlide?.querySelector('video');
            // console.log('checkVideoPlayStatusFunc:', currentSlide);
            if (video) {
                this.swiperSlide?.autoplay?.stop();
                this.swiperSlide?.autoplay?.pause();
                try {
                    video.muted = true;
                    video?.play()?.catch((error) => {
                        console.log('Failed to auto play video:', this.playSrc, error);
                    });
                } catch (error) {

                }
                // onended在设置了loop的情况下不会生效
                video.ontimeupdate = () => {
                    // console.log('播放着“”,,', video.currentTime, video.duration);
                    if (video.currentTime >= video.duration - 0.5) {
                        // console.log('切换下一个')
                        video.currentTime = 0; // 清空播放进度
                        video.ontimeupdate = null; // 移除 timeupdate 事件监听器
                        video.onerror = null; // 移除 error 事件监听器
                        // 视频即将结束，执行相应操作
                        this.swiperSlide.slideNext();
                        this.swiperSlide.autoplay.start();
                        this.swiperSlide.autoplay.run();
                    }
                };
                video.addEventListener.onerror = () => {
                    video.currentTime = 0; // 清空播放进度
                    video.ontimeupdate = null; // 移除 timeupdate 事件监听器
                    video.onerror = null; // 移除 error 事件监听器
                    this.swiperSlide.slideNext();
                    this.swiperSlide.autoplay.start();
                    this.swiperSlide.autoplay.run();
                };
            }
        };
        if (this.swiperSlide?.on) {
            this.swiperSlide?.on('slideChange', checkVideoPlayStatusFunc?.bind(this));
            checkVideoPlayStatusFunc?.bind(this)();
        }
    }

    selectedAndShowThisThumbsImage(img: string, idx: number) {
        this.currentImageUrl = img;
        this.currentSelectedThumbsImageIndex = idx;
        this.currentBigImgIndex = idx;
        this.showBigImgNumber = idx + 1;
    }

    delegation(index: number, indexOfImg?: number) {
        if (Number.isInteger(indexOfImg)) {
            this.jump(indexOfImg);
            return;
        }
        if (Number.isInteger(index)) {
            this.jump(index);
        }
    }

    beforeChange(event) {
        this.showBigImgNumber = event.to + 1;
    }

    mousemoveHandlerForBigImg(e: MouseEvent) {
        try {
            // 左侧大图区域
            const bigImgNativeEleClientRect = document.querySelector('#img-detail').getBoundingClientRect();
            // 距离底部：bottom，距离顶部：top，距离左侧：left，距离右侧：right
            // 距离父盒子左侧，距离父盒子顶部；offsetX和Y属性好像有点问题，会突然跳到一个莫名其妙的数字
            const mouseOffSetX = e.clientX - bigImgNativeEleClientRect.left, mouseOffSetY = e.clientY - bigImgNativeEleClientRect.top;
            // 左侧放大镜区域
            const imagezoomAreaNativeEleClientRect = document.querySelector('#img-imagezoom-area').getBoundingClientRect();
            const imagezoomAreaWidth = imagezoomAreaNativeEleClientRect.width, imagezoomAreaHeight =
                imagezoomAreaNativeEleClientRect.height;
            // 如果鼠标坐标放大镜水平或者垂直居中的地方：先判断鼠标坐标是否大于当前放大区域的某个方向中心
            // 然后判断放大镜区域是否到了图片区域的边界
            const leftTopPointX = mouseOffSetX - imagezoomAreaWidth / 2, leftTopPointY = mouseOffSetY - imagezoomAreaHeight / 2;
            if (leftTopPointX <= 0) {
                this.imagezoomAreaNativeEleX = 0;
            } else if (leftTopPointX >= (bigImgNativeEleClientRect.width - imagezoomAreaWidth)) {
                this.imagezoomAreaNativeEleX = bigImgNativeEleClientRect.width - imagezoomAreaWidth;
            } else {
                this.imagezoomAreaNativeEleX = leftTopPointX;
            }
            if (leftTopPointY <= 0) {
                this.imagezoomAreaNativeEleY = 0;
            } else if (leftTopPointY >= (bigImgNativeEleClientRect.height - imagezoomAreaHeight)) {
                this.imagezoomAreaNativeEleY = bigImgNativeEleClientRect.height - imagezoomAreaHeight;
            } else {
                this.imagezoomAreaNativeEleY = leftTopPointY;
            }
            this.bigImagezoomAreaNativeEleX = -this.imagezoomAreaNativeEleX * 2;
            this.bigImagezoomAreaNativeEleY = -this.imagezoomAreaNativeEleY * 2;
            this.cdr.detectChanges();
        } catch (error) {

        }
    }

    changeThumbsShowStatus(status: boolean) {
        this.thumbsShowStatus = status;
    }

    getContentAlignClass(options: any) {
        switch (jsonParse(options)?.align) {
            case 'center':
                return 'center';
            case 'right':
                return 'flex-end';
            default:
                return 'flex-start';
        }
    }

    getElStyle(elName: string = 'main-title', options?: any) {
        const data = jsonParse(options);
        const carouselData = jsonParse(this.contentRenderOptions?.[this.currentDeviceService.isMobile() ? 'mobile' : 'desktop']);
        if (elName === 'button') {
            let textColorStyle = this.getColorStr(data?.[elName]?.color ? data?.[elName] : carouselData?.[elName]);
            let buttonBackgroundColorStyle = this.getColorStr(data?.[elName]?.background?.color ? data?.[elName]?.background : carouselData?.[elName]?.background, true);
            return `${textColorStyle}${buttonBackgroundColorStyle}`
        }
        return `color: var(--color-tit);letter-spacing: ${data?.[elName]?.['letter-spacing'] || 1}px;line-height: ${data?.[elName]?.['line-height'] || (((elName === 'main-title') ? 1 : 1.5))};${this.getColorStr(data?.[elName]?.color ? data?.[elName] : carouselData?.[elName])}`;
    }

    getElFontSizeScale(elName: string = 'main-title', options: any) {
        return jsonParse(options)?.[elName]?.['font-size'];
    }

    getColorStr(data: any, isBackgroundColor = false) {
        const options = jsonParse(data);
        let colorData = ((options?.color !== "CUSTOMIZE") && options?.color) ? options?.color : options?.customizeColorValue;
        try {
            colorData = JSON.parse(colorData);
        } catch (error) {
            colorData = colorData?.toLowerCase();
        }
        let gradientColor = tranformGradientColorStyle(colorData);
        return `${gradientColor ? `background-image: ${gradientColor}!important;${isBackgroundColor ? '' : `color: ${jsonParse(colorData)?.data?.[0]?.color}!important;background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;`}`
            : `color:${colorData}!important;`}`;
    }

    videoAutoPlayEnabled() {
        if (isPlatformServer(this.platformId)) {
            return false;
        }
        return checkVideoCanAutoPlay();
    }

    getVideoBanner(item) {
        return jsonParse(item)?.videoBanner;
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
