export const GetCurrentUrlAddress = {
    urlEncode: function (url, page) {
        return encodeURIComponent((url + page).replace('//', '/').replace(':/', '://'))
    },
    parseQueryParams: (queryParams?: string) => {
        const urlSearchParams = new URLSearchParams(queryParams || window.location.search);
        const result = {};
        // @ts-ignore
        for (let value of urlSearchParams.entries()) {
            result[value[0]] = value[1];
        }
        return result;
    },
}