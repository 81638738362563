import { accountManagementTranslate } from './_modules/account-management.translate';
import { affiliateTranslate } from './_modules/affiliate.translate';
import { authTranslate } from './_modules/auth.translate';
import { contentManagementTranslate } from './_modules/content-management.translate';
import { invoiceCenterTranslate } from './_modules/invoice-center.translate';
import { menuTranslate } from './_modules/menu.translate';
import { orderTranslate } from './_modules/order.translate';
import { sitesTranslate } from './_modules/sites.translate';
import { systemSettingTranslate } from './_modules/system-setting.translate';
import { userTranslate } from './_modules/user.translate';

// China-Taiwan
export const locale = {
    lang: 'zh-TW',
    data: {
        AUTH: authTranslate,
        MENU: menuTranslate,
        ORDER: orderTranslate,
        ACCOUNT_MANAGEMENT: accountManagementTranslate,
        SYSTEM_SETTING: systemSettingTranslate,
        SITES: sitesTranslate,
        CONTENT_MANAGEMENT: contentManagementTranslate,
        AFFILIATE: affiliateTranslate,
        INVOICE_CENTER: invoiceCenterTranslate,
        GENERAL: {
            OWNER: '所有者',
            UNPAID: '未支付',
            PAID: '已支付',
            EXPIRED: '失效',
            INVOICE_CENTER: '我的發票',
            SERIES_PAGE: '系列商品',
            CALLING_CODE_SELECTOR_TITLE: '國家/地區碼選擇',
            CALLING_CODE_SELECTOR_PLACEHOLDER: '搜索國家/地區',
            REFERRAL_HISTORY_TEXT: '推薦記錄',
            RULE_TEXT: '活動規則',
            SHARE_TO_NEW_PEOPLE_TEXT: '複製分享連結',
            REFERRAL_ACTIVITY_TITLE_TEXT: '邀好友，贏獎勵',
            COPY_YOUR_REFERRAL_CODE_TEXT: '複製推薦碼：{{myReferralCode}}',
            YOUR_REFERRAL_PERSON_TEXT: '我的推薦人：{{referrerId}}',
            SAVE: '提交',
            LOAD_INFO_TIPS: '載入數據中，請稍後......',
            LOAD_ORDER_INFO_TIPS: '計算訂單數據中，請稍後......',
            TAX_INCLUDED: '含稅',
            CANT_CREATE_ORDER_FOR_ERROR_MESSAGE: '當前訂單不存在或者數據不合法，請重新提交吧',
            GROUP_BUYING_CREATER_DISCOUNT: '團長優惠',
            GET_USER_INFO_FAILED: '獲取身份信息失敗，請稍後再試吧！',
            PICK_UP_PHONE_INVALID_TIPS: '提貨人手機號格式不正確',
            PICK_UP_NAME_EMPTY_TIPS: '提貨人姓名不能為空',
            PICK_UP_TIME_TIPS: '選擇自提時間',
            ORDINARY_EXPRESS: '普通快遞',
            GROUP_BUYING_FREE_SHIPPING: '團購包郵',
            FREE_SHIPPING: '包郵',
            PHONE: '手機號碼',
            HOW_TO_GET_THE_CARD_PASSWORD: '卡密碼獲取方式',
            HOW_TO_GET_THE_CARD_PASSWORD_DESC: '支付成功后需憑此方式獲取卡密碼',
            GIFT_CARD_INFORMATION: '禮品卡資訊',
            ALL_BOUND_TO_CURRENT_ACCOUNT: '全部綁定到當前帳號',
            LOGISTICS_COMPANY_NUMBER_INVALID_ERROR: '物流單號格式不正確',
            LOGISTICS_COMPANY_NAME_EMPTY_ERROR: '物流公司名稱不能為空',
            FILL_IN_THE_LOGISTICS_COMPANY: '填寫物流公司',
            EVALUATE_SUCCESS: '評價成功',
            EVALUATE_INVALID_ERROR: '評價最少5個字!!!',
            QUERY_BTN_TEXT: '查詢',
            ADD_BTN_TEXT: '添加',
            EDIT_BTN_TEXT: '編輯',
            FILL_IN_BTN_TEXT: '填寫',
            DELETE_BTN_TEXT: '刪除',
            SAVE_BTN_TEXT: '保存',
            CANCEL_BTN_TEXT: '取消',
            CHANGE_TEXT: '修改',
            LOGIN: '登錄',
            REGISTER: '註冊',
            REGISTER_BTN_TEXT: '創建新帳戶',
            SEARCH: '搜索',
            PRODUCT_DEFAULT_NAME: '商品',
            INDEX: '首頁',
            CUSTOMERIZE: '定製',
            CATEGORY: '分類',
            FEATURE: '特色',
            SHOPPING_CART: '購物車',
            ABOUT: '關於',
            USER_CENTER: '個人中心',
            CUSTOMIZE: '自訂',
            LOAD: '載入',
            LOADING: '載入中',
            SIGN_OUT_SUCCESSFULLY: '退出登錄成功',
            WECHAT_BINDING_IS_SUCCESSFUL: '微信綁定成功！',
            WECHAT_BINDING_FAILED: '微信綁定失敗',
            HOT_SALE: '熱賣',
            LABEL: '標籤',
            NEW: '最新',
            PRE_SALE: '預售',
            SERIES: '系列',
            FILL_OUT: '去填寫',
            SEE_MORE: '查看更多',
            CURRENTLY_NO_RECORDS: '抱歉，暫時沒有對應的記錄',
            FAILED: '失敗',
            RELOAD_BTN_TEXT: '刷新重試',
            NONAME: '匿名',
            NONE: '無',
            SETTING: '設置',
            LOGIN_REGISTER: '登錄/註冊',
            ALL: '全部',
            LOADED_ALL: '已載入全部',
            LOAD_FAILED: '載入失敗',
            LOAD_POST_FAILED: '文章找不到或已刪除',
            LOAD_PRODUCT_FAILED: '{{productName}}找不到或已下架',
            LOAD_FORM_FAILED: '表單找不到或已刪除',
            LOAD_LABEL_PRODUCT_FAILED: '相关{{productName}}找不到或已下架',
            RE_APPLY_BTN_TEXT: '再次申請',
            REVIEW_APPLIED_CONTENT_BTN_TEXT: '查看已填寫',
            GO_TO_HOME_BTN_TEXT: '前往首頁',
            SUBMIT: '提交',
            SOME_ERROR_OCCUR: '出現異常',
            TIPS: '提示',
            SUBMITTED_SUCCESSFULLY: '提交成功',
            SUBMITTED_FAILED: '提交失敗',
            RETURNING_TO_HOME_PAGE_TIPS: '正在為您返回首頁',
            RETURNING_TO_HOME_PAGE_SECONDS_TIPS: '{{secondNumber}}秒後，自動為您返回首頁',
            PAGE_NOT_FOUND: '找不到頁面',
            SELECT_ALL_BTN_TEXT: '全選',
            INFO: '資訊',
            UNIT_PRICE: '單價',
            QUANTITY: '數量',
            PRICE: '金額',
            OPERATION: '操作',
            MODIFY_AMOUNT: '修改金額',
            OFF_SHELF: '已下架',
            GO_TO_SETTLEMENT: '去結算',
            TOTAL: '共計',
            SELECTED: '已選',
            PIECES: '件',
            OUT_OF_STOCK: '不能再增加了',
            OFF_STOCK: '無貨',
            ONLY: '僅剩',
            UPDATE_FAILED: '載入失敗',
            CALC_DELIVERY_FAILED: '計算配送費調用失敗，請檢查網路設置后重試',
            SUCCESS: '成功',
            DELETING_DATA_IN_PROGRESS: '刪除中',
            LET_ME_THINK_AGAIN: '我再想想',
            YOU_DIDNT_SELECTED_SOMETHING: '你沒有進行選擇噢！',
            NO_PURCHASABLE_ITEMS_TO_CHOOSE_TIPS: '無可購買的商品來選擇哦！',
            PLEASE_SELECTED: '請選擇',
            WHETHER_TO_DELETE: '是否刪除',
            INCORRECT_FORMAT_TIPS: '格式不正確',
            PRICE_OF_PRODUCT: '價格',
            ORIGINAL_PRICE_OF_PRODUCT: '原價',
            SALE_QUANTITY: '已售',
            PRODUCT_TOTAL_COMMENTS: '累計評論',
            COUPON: '優惠劵',
            THRESHOLD_FREE_COUPONS: '無門檻優惠券',
            INVITE_NEW_PARTICIPANTS: '邀新參團',
            INVITE_NEW_PARTICIPANTS_TIPS: '限新用戶參團，人滿發貨，人不滿退款',
            ENTER_THE_PURCHASE_AMOUNT_TIPS: '請輸入購買量',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS: '階梯拼團',
            SPECIAL_REQUIREMENT_PARAMS: '特殊要求',
            FAVORITES: '收藏',
            COLLECT: '收藏',
            COLLECTED: '已收藏',
            SHARE: '分享',
            COPY_LINK: '複製連結',
            WECHAT_SCAN: '微信掃一掃',
            RELATED_ARTICLES: '相關文章',
            WANT_CREATE_GROUP_BUYING: '我要開團',
            FULL_PAYMENT: '全款支付',
            EXPECTED: '預計',
            INDIVIDUAL_PURCHASE: '單獨購買',
            ADD_TO_SHOPPINGCART: '加入購物車',
            ADD_TO_SHOPPINGCART_BTN_TEXT: '加入購物車',
            PRE_SALE_PRICE: '預售價',
            PARAMETER: '參數',
            COMMENTS: '評價',
            SEE_ALL_PRODUCT_COMMENTS: '查看全部評論',
            RECEIVED: '已領取',
            RECEIVE: '領取',
            START_TIME: '開始時間',
            END_TIME: '結束時間',
            PRODUCT_PROPERTIES: '產品屬性',
            PURCHASE_QUANTITY: '購買數量',
            BUY_NOW: '立即購買',
            DISCOUNT_OF_COUPON: '滿{{minAmountToUse}}減{{offValue}}',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER: '每單限購{{maxQuantityOfOrder}}件',
            MAX_QUANTITY_OF_GROUP_BUYING_ORDER_TIPS: '超出單次限購數量',
            PRODUCT_IS_SOLD_OUT: '{{productName}}已售罄',
            SOLD_OUT: '已售罄',
            INVENTORY: '庫存',
            GROUP_BUYING_FOR_PEOPLE_NUMBER: '{{price}}/{{peopleNumber}}人團',
            GROUP_BUYING_REQUIRE_MEMBER_LEVEL_TIPS_TEXT: '{{requiredMembers}}人拼團',
            GET_THE_COUPON: '領券',
            JOIN_THE_GOURP_BUYING_DESC: '這些人剛剛拼單成功，可參與拼團',
            SOMEONE_GROUP: '的團',
            ONLY_FOR_GROUP_BUYING: '只差',
            PERSON_LEFT_IN_THE_GROUP: '人成團',
            TO_INVITE: '去邀請',
            GO_TO_THE_GROUP: '去拼團',
            NO_DETAILS_FOR_PRODUCT: '暫無{{productName}}詳情',
            SOME_DAYS_AFTER_PAYMENT: '付款后{{presaleDeliveryDaysAfterPayment}}天內',
            SHIP: '發貨',
            COUPON_VALIDITY_PERIOD_TIPS: '領券當日開始{{validityDays}}天有效',
            PRESALE_DELIVERY_FIXED_DATE: '{{value}}前',
            DESCRIPTION: '介紹',
            PRODUCT_QUANTITY_EXCEEDS_AVAILABLE_STOCK: '數量超過可用庫存數',
            ADD_TO_CART_SUCCESSFUL: '加入購物車成功',
            ADD_FAILED: '添加失敗',
            LIMIT_MIN_NUMBER_OF_PRODUCT_TIPS: '{{productName}}数不能少於1',
            ANONYMOUS_USER: '匿名使用者',
            LIMIT_MAX_STOCK_NUMBER_OF_PRODUCT_TIPS: '不能大於當前{{productName}}庫存數！',
            NETWORK_CONNECTION_FAILED: '網路連接失敗！',
            BUY_PRODUCT_ERROR_TIPS: '數據獲取失敗，該{{productName}}可能已下架 或者 服務出現未知異常',
            SHARE_INFO_FOR_PRESALE_TIPS: '我發現了個好{{productName}}，目前正在預售中，快來看看吧!!!',
            SHARE_INFO_FOR_GROUP_BUYING_TIPS: '我發現了個好{{productName}}，目前正在團購促銷中，快來看看吧!!!',
            SHARE_INFO_FOR_PRODUCT_TIPS: '我發現了個好{{productName}}，快來看看吧!!!',
            UNFAVORITE_SUCCESSFUL_TIPS: '取消收藏成功',
            UNFAVORITE_FAILED_TIPS: '取消收藏失敗',
            FAVORITE_SUCCESSFUL_TIPS: '收藏成功',
            FAVORITE_FAILED_TIPS: '收藏失敗',
            LOGIN_CHECKING: '登錄檢測中',
            RECEIVING: '領取中',
            RECEIVED_SUCCESSFULLY: '領取成功',
            RECEIVED_FAILED: '領取失敗，請聯繫客服！',
            FAILED_TO_LOAD: '載入失敗',
            VIEW_ORDER_DETAILS: '查看訂單詳情',
            GO_TO_ORDER_LIST: '前往訂單清單',
            GO_TO_HOME_TIPS: '去首頁逛逛',
            GROUP_BUYING_CREATER: '團長',
            PARTICIPATE: '拼單',
            PEOPLE: '人',
            PLEASE_WAIT: '請稍候',
            ENTER_SEARCH_CONTENT: '請輸入搜尋內容',
            SEARCH_HISTORY: '搜索歷史',
            NOT_SEARCHED_RELATED_PRODUCTS: '抱歉，沒有蒐索到相關的內容',
            INPUT_INVALID_ERROR: '請檢查您的輸入是否有誤',
            SEARCH_FAILED: '搜索失敗，請稍後再試吧',
            SURE: '確定',
            DELETE_SEARCH_WORD_FAILED: '清除失敗，請稍後再試吧',
            CONFIRM_BEFORE_CLEARING_SEARCH_HISTORY: '你確定要將清除所有的搜索歷史嗎？',
            NO_SERIES: '無系列',
            FAILED_TO_LOAD_SERIES_DATA: '載入系列數據失敗',
            NO_GIFT_CARD_CONTENT: '無禮品卡內容',
            SERIES_CARD: '主題卡片',
            NOT_FOUND_PRODUCTS: '無{{productName}}內容',
            NO_DATA: '暫無數據',
            NO_MORE_DATA: '暫無更多數據',
            ARTICLE_LIST: '文章列表',
            LIKE: '點讚',
            COMMENT_ARTICLE: '評論',
            ARTICLE_COMMENTS: '全部評論',
            LATEST: '最新',
            MOST_POPULAR: '最熱',
            AUTHOER: '作者',
            CANCEL_REPLY: '取消回復',
            REPLY: '回復',
            UNLIKE: '取消點讚',
            LINK_COPIED_SUCCESSFULLY: '連結複製成功',
            THE_CURRENT_BROWSER_DOES_NOT_SUPPORT_COPYING: '當前瀏覽器不支援複製，請手動複製！',
            ARTICLE_DETAILS_TITLE: '文章詳情',
            ONLY_TIME_LEFT_UNTIL_THE_EVENT_STARTS: '距離活動開始僅剩',
            ONLY_TIME_LEFT_UNTIL_THE_END_OF_THE_EVENT: '距離活動結束僅剩',
            MALE: '男',
            FEMALE: '女',
            OTHER: '其他',
            UNKNOWN: '未知',
            THOUSAND: '千',
            TEN_THOUSAND: '萬',
            BILLION: '億',
            BACK: '返回',
            HELLO: '你好',
            PERSONAL: '個人',
            ORGANIZE: '組織',
            PLEASE_SELECT_GENDER: '請選擇性別',
            PLEASE_CHOOSE_THE_TYPE: '請選擇類型',
            AVATAR_UPLOAD_FAILED: '頭像上傳失敗',
            TRY_AGAIN_RECIVE: '請重新領取',
            SUCCESSFULLY_DELETED: '刪除成功',
            FAILED_TO_DELETE: '刪除失敗',
            SET_AS_THE_DEFAULT_ADDRESS: '設為預設位址',
            SHIPPING_ADDRESS: '收貨位址',
            SHIPPING_ADDRESS_PLACEHOLDER: '請輸入收貨位址',
            POSTAL_CODE: '郵遞郵編',
            POSTAL_CODE_PLACEHOLDER: '請輸入郵編',
            DEFAULT_ADDRESS: '默認位址',
            NO_ADDRESS_YET: '暫無位址',
            SET_SUCCESSFULLY: '設置成功',
            COMPLETE_ADDRESS_INFORMATION: '請完善地址資訊',
            EMAIL_ADDRESS_TITLE: '填寫接收發票的電子郵箱',
            EMAIL_ADDRESS: '電子郵箱',
            EMAIL_ADDRESS_PLACEHOLDER: '請輸入電子郵箱',
            INVOICE_HEADER_TYPE_TITLE: '抬頭類型',
            INVOICE_HEADER_TYPE_PLACEHOLDER: '選擇抬頭類型',
            COMPANY: '公司',
            INVOICE_HEADER_PLACEHOLDER: '請輸入發票抬頭',
            INVOICE_HEADER_CONTENT: '*發票抬頭',
            INVOICE_HEADER_TITLE: '發票抬頭',
            NSRSBH_PLACEHOLDER: '請輸入納稅人識別號',
            NSRSBH_CONTENT: '*納稅人識別號',
            NSRSBH_TITLE: '納稅人識別號',
            PHONE_PLACEHOLDER: '請輸入手機號碼',
            PHONE_CONTENT: '*手機號',
            PHONE_TITLE: '手機號',
            INPUT_MORE_INFORMATION: '填寫更多資訊',
            BANK: '開戶行',
            BANK_PLACEHOLDER: '請輸入開戶行',
            NICKNAME_TITLE: '個人姓名',
            NICKNAME_CONTENT: '*個人姓名',
            NICKNAME_PLACEHOLDER: '請輸入姓名',
            FULL_DATE_FROMART_TEXT: '{{year}}年{{month}}月{{day}}日 {{hour}}：{{minute}}：{{second}}',
            SELECT_INVOICE_INFORMATION: '選擇開票資訊',
            FAILED_TO_APPLY_FOR_INVOICING: '申請開票失敗，請聯繫管理員',
            FAILED_TO_APPLY_FOR_INVOICING_TIPS: '申請開票失敗',
            INVOICING_WAS_SUCCESSFUL: '申請開票成功！',
            FAILED_TO_CREATE_INVOICE_HEADER: '創建發票抬頭失敗',
            REFUNDING: '退款中',
            PENDING_RETURN: '待退貨',
            RETURNED_PENDING_REFUND: '已退貨，待退款',
            REVIEW_FAILED: '審核未通過',
            IN_REVIEW: '審核中',
            REFUND_COMPLETE: '退款完成',
            REFUND: '退款',
            REFUND_APPLICATION_APPROVED: '退款申請審核通過',
            REFUND_INSTRUCTIONS: '退款須知',
            REFUND_BUTTON_TEXT: '我已閱讀（去退款）',
        },
        SWEET_ALERT: {
            DEFAULT_TITLE: '你確認要進行這個操作嗎？',
            DEFAULT_CONFIRM_BUTTON_TEXT: '確認',
            DEFAULT_CANCEL_BUTTON_TEXT: '取消',
        },
        PAGINATION: {
            TITLE: '顯示第 {{page}} - {{lastIndex}} 條，共 {{total}} 條數據.',
            TEXT: '每頁顯示',
            Unit: '條'
        },
        USER: {
            ...userTranslate,
            LANGUAGE: '語言',
            LOGOUT: '退出',
        },
        FOOTER: {
            TEXT: '上海慧特資訊技術有限公司',
            TERMS_OF_USE: '使用條款',
            TERMS_OF_REGISTRATION: '註冊條款',
            TERMS_OF_PRIVACY_POLICY: '隱私條款',
            TERMS_OF_PURCHASE: '購買條款',
            CUSTOMER_SERVICE: '客戶服務',
            CONTACT_CUSTOMER_SERVICE: '聯繫客服',
            FEEDBACK_BTN_TEXT: '我要反饋',
            AFTER_SALES_SERVICE: '售後服務',
            MERCHANT_SERVICES: '商家服務',
            AFFILIATE_SERVICES: '聯盟網絡',
            AFFILIATE_BTN_TEXT: '申請加入',
            MERCHANT_PORTAL: '管理後台',
            FOLLOW_US_FOR_MORE_INFORMATION: '關注我們獲取更多資訊',
            TECHNICAL_SUPPORT: '技術支援:',
        },
        API_ALERT_MESSAGE: {
            ERROR: {
                OFF_LINE: '請求出錯，請檢查您的網络是否連接......',
                UNAUTHORIZED: '您暫未登錄或者登錄狀態已過期，請先登錄后再進行操作......',
                FAILEDTOLAZILYINITIALIZE: '當前請求服務未啟動成功，請聯繫客服或者管理員處理',
                UNKONWN: '未知錯誤，請檢查網路再試吧; 若繼續出現，請聯繫管理員',
                ERROR_MESSAGE: '要求出錯，錯誤資訊：',
                DEFAULT: '請檢查當前網路，或者當前介面出現未知錯誤......',
                COUPONNOTFEASIBLEFORGIFTCARD: '禮品卡不能使用優惠券抵扣哦~',
                INVALIDCARDTEMPLATEID: '訂單中包含禮品卡商品，無法使用禮品卡支付哦~',
                OTPEXPIRED: '微信授權已過期，請重新授權哦~',
                SENDVERIFICATIONCODEERROR: '發送次數過多，請稍後或者更換號碼再試吧',
                NOCUSTOMERRECORD: '當前帳號資訊不完整，請換個手機號再試或者聯繫管理員吧',
                NOTFOUND: '數據不存在或後端服務暫時不可用，此問題若繼續出現，請聯繫管理員',
                METHODNOTALLOWED: '請求出錯，此問題若繼續出現，請聯繫管理員',
                INTERNALSERVERERROR: '伺服器出現異常，此問題若繼續出現，請聯繫管理員',
                GATEWAYTIMEOUT: '無法連接伺服器，請檢查網路再試吧',
                ERRNETWORKCHANGED: '無法連接伺服器，請檢查網路再試吧',
                METHODARGUMENTNOTVALID: '提交數據有誤，請刷新再試吧; 若繼續出現，請重新訪問本應用',
                BADGATEWAY: '伺服器異常，請稍後訪問本應用; 若繼續出現，請聯繫管理員',
                USERCOULDNOTBEFOUND: '使用者不存在，請重新登錄或者稍後再試吧',
                COULDNOTEXTRACTRESULTSET: '服務相關進程異常，請稍後訪問本應用; 若繼續出現，請聯繫管理員',
                NOT_LOADED_SUCCESSFULLY_PLEASE_RE_ENTER: '未載入成功，請重新進入：',
                CMS: {
                    FORMNOTFOUND: '未找到該答卷對應的表單',
                    MISSINGREQUIREDRESPONSE: '部分必答題未提交答案',
                    INVALIDROWSFORMAT: '矩陣題型的行應該為 JSON 陣組的字串',
                    RESPONSENOTMATCHWITHROWCOUNT: '矩陣題的應答行數量與題目的行數量不一致',
                    MISSINGREQUIREDRESPONSEOFROW: '矩陣問題中有必答的行缺少答案',
                    MULTIPLERESPONSENOTALLOWED: '非矩陣問題不允許多條答案',
                    MISSINGRESPONSEVALUE: '應答中缺失答案',
                    EMPTYRESPONSEVALUE: '應答中答案為空字串',
                    INVALIDDATETIMEFORMAT: '題目中不正確的日期/時間格式',
                    INVALIDINTEGERVALUE: '不正確的整數值',
                    INVALIDFLOATVALUE: '不正確的小數值',
                    DECIMALPLACENUMBEREXCEEDS: '小數位數超過了定義',
                    FILENUMBEREXCEEDS: '檔數量超過了限額',
                    INVALIDOPTIONSFORMAT: '不正確的選項格式，應該是 JSON 格式的 FormAttachment 陣列',
                    INVALIDVALUE: '不符合規則的答案',
                    CANNOTFINDVALIDATOR: '找不到驗證器的答案',
                    EMAILALREADYEXISTS: '已訂閱成功！',
                },
                USER: {
                    PASSWORDFORMATINVALID: '密碼格式未達到複雜度要求',
                    LOGINALREADYEXISTS: '登錄名已經被他人佔用了',
                    NOUSERCREDENTIAL: '獲取不到用戶記錄，請用正確的賬號登錄',
                    ALREADYBOUNDMOBILE: '用戶已經綁定了手機號',
                    USERNOTBOUNDMOBILE: '使用者尚未綁定手機號',
                    MOBILENUMBERMISMATCH: '手機號與綁定手機號不一致',
                    MOBILEBOUNDBYOTHERUSER: '該手機號已被其他用戶綁定',
                    INCORRECTPASSWORD: '密碼不正確',
                    MOBILENOTREGISTERED: '此手機號碼未註冊',
                    PARAMETERNULL: '參數不能為空',
                    WECHATNOTREGISTERED: '此微信未註冊',
                    USERNOTBOUNDWECHAT: '使用者尚未綁定微信號',
                    WECHATBOUNDBYOTHERUSER: '該微信號已被其他用戶綁定',
                    MOBILEALREADYREGISTERED: '此手機號已註冊，請進行微信綁定操作',
                    REGISTERREQUIRED: '登錄失敗， 請在註冊後進行登錄',
                    WECHATALREADYREGISTERED: '此微信已註冊，請登錄或綁定新的微信號',
                    NOUSERBYWECHAT: '根據微信獲取不到用戶記錄， 請聯繫技術支援',
                    CODENULL: 'code 不能為空',
                    WRONGPASSWORD: '密碼錯誤，請檢查您輸入的密碼',
                    FAILEDTOOMANYTIMES: '您嘗試賬號登錄次數過多，更換登錄方式或 {{value}} 分鐘後重新嘗試',
                    FAILEDTOOMANYTIMES_NO_DETAIL: '您嘗試賬號登錄次數過多，更換登錄方式或 稍後再試吧',
                    LOGINFAILED: '登錄失敗，請檢查使用者名或密碼是否正確或更改登錄方式，您已經嘗試登錄 {{loginNumber}} 次，超過 {{maxNumber}} 次將會導致您 {{timeNumber}} 分鐘內無法使用賬號登錄',
                    LOGINFAILED_NO_DETAIL: '登錄失敗，請檢查使用者名或密碼是否正確或更改登錄方式，頻繁登錄將會導致您一段時間內無法使用賬號登錄哦',
                    CODEINVALID: '驗證碼已失效',
                    PASSWORDNOTENABLED: '未啟用密碼登錄，請更換其他登錄方法',
                    USER_DOES_NOT_EXIST: '使用者不存在',
                }
            },
            SUCCESS: {
                TITLE: '成功！',
            }
        },
        LANGUAGE_SWITCH: {
            CHINESE: '簡體中文',
            ENGLISH: '英語',
            JAPANESE: '日語',
            TRADITIONAL_CHINESE: '繁體中文'
        },
        INDEX: {
            USER_CENTER: '個人中心',
            MY_ORDERS: '我的訂單',
            MY_GIFT_CARDS: '我的禮品卡',
            PERSONAL_INFORMATION: '個人資料',
            ACCOUNT_SECURITY: '帳號安全',
            SIGN_OUT_BTN_TEXT: '退出登錄',
            FAILED_TO_GET_TODAY_GROUP_PRODUCT: '獲取今日拼團商品失敗',
            HEADER: '頭部',
            CAROUSEL: '輪播圖',
            DIY_CONTENTS: '自訂',
            ANNOUNCEMENT: '公告',
            ANNOUNCEMENT_INFO: '公告資訊',
            NEWS: '新聞資訊',
            GROUP_BUYING_PRODUCS: '今日必拼',
        },
        FLOATING_WINDOW: {
            GO_TO_SHOPCART: '購物車',
            USER_CENTER: '個人中心',
            CONTACT_CUSTOMER_SERVICE: '聯繫客服',
            BACK_TO_THE_TOP: '回到頂部',
            SHRINK_BTN_TEXT: '收縮',
            EXPAND_BTN_TEXT: '展開',
        },
        HOME: {
            AMOUNTS_PAYABLE: '應付金額',
            SELECT_SELF_PICK_UP_ADDRESS: '選擇自提點',
            SELF_PICK_UP_ADDRESS: '自提點',
            SELLER_SENT_WRONG_PRODUCT: '賣家發錯貨',
            PRODUCT_STYLE_ERROR: '版本/批次/顏色等與{{productName}}描述不符',
            DAMAGED_PRODUCT_PACKAGING: '包裝或{{productName}}破損',
            OTHER_REASONS: '其他原因',
            REASON_FOR_RETURN: '退款原因',
            REFUND_TYPE: '退款類型',
            FILL_IN_THE_LOGISTICS_ORDER_NUMBER: '填寫物流單號',
            EVALUATE_THE_ORDER: '評價曬單',
            PRODUCT_NAME: '產品名稱',
            PLEASE_SELECT_IMAGE_SOURCE: '請選擇圖片來源',
            PHONE_ALBUM: '手機相冊',
            DELETE_INVOICE_TIPS: '你確定要刪除這個開票資訊嗎？',
            INVOICE_CREATE_BTN_TEXT: '新建開票資訊',
            NO_INVOICE_INFORMATION_YET: '暫無發票資訊',
            TAX_ID: '稅號',
            INVOICE_SETTING: '開票設置',
            ADDRESS_PLACEHOLDER: '請輸入位址',
            BANK_ACCOUNT_PLACEHOLDER: '請輸入銀行帳號',
            BANK_ACCOUNT: '帳號',
            INVOICING_INFORMATION: '開票資訊',
            VAT_ELECTRONIC_GENERAL_INVOICE: '增值稅電子普通發票',
            VAT_SPECIAL_ELECTRONIC_INVOICE: '加值稅專用電子發票',
            INVOICE_DOCUMENT: '發票檔',
            INVOICE_FILE_TYPE: '發票類型',
            INVOICE_HEADER_NAME: '抬頭名稱',
            CREATED_SUCCESSFULLY: '創建成功',
            VAT_ORDINARY_INVOICE: '加值稅普通發票',
            VAT_SPECIAL_INVOICE: '加值稅專用發票',
            INVOICE_NUMBER: '發票編號',
            INVOICE_TYPE: '開票類型',
            ELECTRONIC_INVOICE: '電子發票',
            MANUAL_INVOICE: '人工發票',
            INVOICE_AMOUNT: '開票金額',
            INVOICING_TIME: '開票時間',
            APPLICATION_TIME: '申請時間',
            FAILED_TO_CREATE: '創建失敗',
            EMAIL_INVALID_ERROR: '郵箱未填寫或格式輸入有誤',
            PHONE_INVALID_ERROR: '請填寫正確的手機號碼',
            ADDRESS_INVALID_ERROR: '收貨位址不支援特殊符號',
            NSRSBH_INVALID_ERROR: '請輸入正確的納稅人識別號',
            NAME_CANNOT_BE_EMPTY: '姓名不能為空',
            INVOICE_HEADER_CANNOT_BE_EMPTY: '發票抬頭不能為空',
            FAILED_LOADED_ACCOUNT_INFORMATION_PLEASE_RETRY_AGAIN: '帳號信息獲取失敗，請刷新重試',
            TO_BE_PAID: '待支付',
            TO_BE_JION: '待成團',
            PENDING_RECEIPT_OF_GOODS: '待收貨',
            COMPLETED: '已完成',
            ALL_ORDERS: '全部訂單',
            MY_SERVICES: '我的服務',
            ADDRESS_MANAGEMENT: '位址管理',
            MY_FAVORITES: '我的收藏',
            MY_COUPONS: '我的優惠券',
            MY_INVOICE: '發票抬頭',
            ACCOUNT_SECURITY: '帳戶安全',
            INVITATION_WITH_PRIZE: '推薦有獎',
            FEEDBACK: '意見反饋',
            SUCCESSFUL_EXIT: '退出成功',
            FAILED_TO_GET_PERSONAL_INFORMATION: '獲取個人信息失敗！',
            SPARE_CHANGE_DETAILS: '零錢明細',
            MY_SPARE_CHANGE: '我的零錢',
            MY_WALLET: '我的錢包',
            BALANCE_DETAILS: '餘額明細',
            CHOOSE: '選擇',
            NO_PICK_UP_LOCATIONS_AVAILABLE: '暫無可選自提點',
            SUNDAY: '周日',
            MONDAY: '週一',
            TUESDAY: '週二',
            WEDNESDAY: '週三',
            THURSDAY: '週四',
            FRIDAY: '週五',
            SATURDAY: '週六',
            PICK_UP_TIPS: '請儘快到自提點提貨',
            TODAY: '今天',
            TOMORROW: '明天',
            THE_DAY_AFTER_TOMORROW: '後天',
            ALL_DAY: '全天',
            MORNING: '上午',
            AFTERNOON: '下午',
            NIGHT: '晚上',
            FEEDBACK_CONTENT: '反饋內容',
            FEEDBACK_CONTENT_PLACEHOLDER: '反饋內容不少於五個字',
            FEEDBACK_CONTACT: '請填寫手機號或者郵箱',
            CONTACT_DETAILS: '聯繫方式',
            FEEDBACK_POSTING: '提交中',
            FEEDBACK_FAILED: '請將聯繫方式與反饋內容補充完整',
            NO_CONSUMPTION_RECORD: '暫無消費記錄',
            CONSUMPTION_RECORD: '消費記錄',
            CONSUMPTION_RECORD_DETAILS: '消費記錄明細',
            DESCRIBE: '描述',
            ORDER_ID: '訂單id',
            TRANSACTION_NUMBER: '交易流水號',
            TRANSACTION_TIME: '交易時間',
            TOTAL_GIFT_CARD_BALANCE: '禮品卡總餘額',
            BUY_A_NEW_CARD: '購買新卡',
            BIND_NEW_CARD: '綁定新卡',
            USED: '可用',
            NOT_USED: '不可用',
            CHOOSE_IMG_FILE_TO_UPDATE_AVATAR: '更換頭像需要選擇一個圖片檔！',
            CHANGE: '更換',
            NO_ACCOUNT_SECURITY_SETTINGS_INFORMATION: '暫無帳戶安全設置資訊',
            LOGIN_SETUP: '登錄名設置',
            CURRENT_LOGIN: '目前登入名稱：',
            HAS_BEEN_SET: '已設置',
            NOT_SET: '未設置',
            SET_LOGIN: '設置登錄名',
            PASSWORD_SETUP: '密碼設置',
            SET_PASSWORD: '設置密碼',
            CHANGE_PASSWORD: '修改密碼',
            SET_PHONE_BINDING: '手機綁定設置',
            BIND_THE_PHONE: '綁定手機',
            CHANGE_PHONE: '更換手機',
            UNBOUND: '未綁定',
            BOUND: '已綁定',
            SET_WECHAT_BINDING: '微信綁定設置',
            UNBOUND_WECHAT_ACCOUNT: '未綁定微信帳號',
            UNBIND_WECHAT: '解綁微信',
            BIND_WECHAT: '綁定微信',
            UNBOUND_AND_NOT_SET_PASSWORD: '當前帳戶未綁定手機號無法設置密碼',
            GO_TO_BIND: '前往綁定',
            UNBIND_WECHAT_TIPS: '解綁后您將無法使用 微信 登錄本網站，確定要解綁嗎？',
            CONFIRM_UNBIND: '確認解綁',
            UNBIND_WECHAT_SUCCESSFULLY: '解綁當前賬戶綁定的微信成功！',
            WECHAT_SCAN_CODE_IS_NOT_ENABLED: '暫未開啟掃碼綁定，請在微信內打開本頁面進行綁定',
            WECHAT_AUTHORIZATION_IS_NOT_ENABLED: '暫未開啟授權綁定，請在瀏覽器內打開本頁面進行綁定',
            THIS_FEATURE_IS_NOT_YET_AVAILABLE: '此功能暫未開放呀',
            LOGIN_NAME_EMPTY_ERROR: '未輸入登錄名，請檢查',
            WRONG_FIELD_FORMAT: '{{name}}格式有誤',
            CAPTCHA_VERIFY_CODE_PLACEHOLDER: '輸入圖片碼',
            PLEASE_ENTER_A_NEW_PASSWORD: '請輸入新密碼',
            SUCCESSFULLY_MODIFIED: '修改成功',
            PHONE_VERIFICATION: '手機驗證',
            PWD_VERIFICATION: '密碼驗證',
            WAYS_OF_IDENTIFYING: '驗證方式',
            INPUT_OLD_PHONE_PLACEHOLDER: '請輸入原手機號',
            OLD_PHONE: '原手機號',
            OLD_PHONE_VERIFICATION_CODE: '原手機驗證碼',
            NEW_PHONE__PLACEHOLDER: '請輸入新手機號',
            NEW_PHONE_VERIFICATION_CODE: '新手機驗證碼',
            NEW_PHONE: '新手機號',
            MODIFY_PHONE_NUMBER: '修改手機號',
            CAPTCHA_LABEL: '圖片驗證碼',
            NEW_PHONE_CAPTCHA_ERROR: '新手機號圖片驗證碼輸入錯誤',
            OLD_PHONE_CAPTCHA_ERROR: '原手機號圖片驗證碼輸入錯誤',
            CURRENT_AVATAR: '當前頭像',
            USER_NICKNAME_PLACEHOLDER: '請輸入用戶暱稱',
            USER_NICKNAME: '用戶暱稱',
            GENDER: '性別',
            TYPE: '類型',
            CONFIRM_THE_CHANGES: '確認修改',
            CONTACT_DETAILS_PLACEHOLDER: '請輸入聯繫方式',
            MOBILE_PHONE: '手機號',
            MOBILE_PHONE_PLACEHOLDER: '請輸入手機號',
            NAME: '姓名',
            NAME_PLACEHOLDER: '請輸入姓名',
            ORGANIZATION_NAME_PLACEHOLDER: '請輸入組織名稱',
            ORGANIZATION_NAME: '組織',
            ORGANIZATION_NAME_TITLE: '組織名稱',
            FAIL_TO_EDIT: '修改失敗',
            LOGISTICS_STATUS: '物流狀態',
            LOGISTICS_COMPANY: '物流公司',
            LOGISTIC_NUMBER: '物流單號',
            FILL_IN_THE_REFUND_LOGISTICS_INFORMATION: '填寫退款物流資訊',
            COPY_THE_TRACKING_NUMBER: '複製單號',
            LOGISTICS_INFORMATION: '物流資訊',
            LOGISTICS_INFORMATION_TIPS: '暫無物流軌跡資訊，請稍後再試！',
            TRACK_NUMBER_COPIED: '單號已複製',
            NO_DISCOUNT_COUPONS_AVAILABLE: '暫無優惠劵領取',
            GET_YOUR_COUPON_NOW: '立即領取',
            GET_YOUR_COUPON_TEXT: '領券當日開始{{validityDays}}天有效',
            GET_COUPON: '領取優惠券',
            TO_BUY: '去購買',
            NO_FAVORITES_YET: '暫無收藏',
            DELETE_PRODUCT_NAME_TIPS: '你確定要刪除這個收藏的{{productName}}嗎？',
            MOBILE_PLACEHOLDER: '手機或帶區號的固話',
            YOUR_AREA: '所在地區',
            NAME_EMPTY_ERROR: '姓名不能為空或者全是空格',
            NAME_INVALID_ERROR: '收貨人姓名不支援特殊符號',
            PHONE_EMPTY_ERROR: '你的聯繫方式未輸入或輸入錯誤',
            ADDRESS_EMPTY_ERROR: '收貨位址不能為空或者全是空格',
            PROVINCE_NAME_EMPTY_ERROR: '請選擇所在地區',
            CITY_NAME_EMPTY_ERROR: '請完善所在地區',
            ADDRESS_LENGTH_ERROR: '收貨位址最多33個字',
            INVOICING_RESULT: '開票結果',
            CREATE_ADDRESS: '新建位址',
            UPDATE_ADDRESS: '更新位址',
            CHANGE_ADDRESS: '修改位址',
            DELETE_ADDRESS_TIPS: '你確定要刪除這個位址嗎？',
            ORDER_CREATE: '訂單提交',
            ORDER_COMPLETED: '訂單完成',
            INVOICING_IS_COMPLETE: '開票完成',
            TOTAL_AMOUNT: '總金額',
            FAILED_TO_LOAD_ORDER_INFORMATION: '載入訂單信息失敗',
            ORDER_ID_TEXT: '訂單編號',
            CREATION_TIME: '創建時間',
            UPDATE_TIME: '更新時間',
            PAYMENT_METHOD: '支付方式',
            EMAIL_BINDING: '郵箱綁定',
            BIND_THE_EMAIL: '綁定郵箱',
            CHANGE_EMAIL: '更換郵箱',
            OLD_EMAIL_VERIFICATION_CODE: '原郵箱驗證碼',
            NEW_EMAIL_VERIFICATION_CODE: '新郵箱驗證碼',
            EMAIL_VERIFICATION: '郵箱驗證'
        },
        PAYMENT_METHOD: {
            WECHAT: '微信',
            ALIPAY: '支付寶',
            CASH: '現金',
            GIFT_CARD: '禮品卡',
            BANK_TRANSFER: '銀行轉帳',
            OTHER: '其他',
            PAYPAY: 'PAYPAY',
            CONSUMING_CARD: '消費卡',
            COUPON: '優惠券',
            POINTS: '積分',
            PRODUCT: '商品',
        },
        CATEGORY: {
            NO_PRODUCT: '暫無商品',
            NO_RELATED: '暫無相關',
            CONTENT: '內容',
            NO_CATEGORY: '暫無分類',
            SHRINK_BTN_TEXT: '收縮',
            EXPAND_BTN_TEXT: '展開',
        },
        FORM: {
            SELECT_THE_FORM_RESPONSE_YOU_WANT_TO_COPY: '選擇您想複製的提交記錄',
            UPDATE_TIME: '更新時間',
            COPY_BTN_TEXT: '複製',
            COPY_AND_EDIT: '複製編輯',
            REFILL: '重新填寫',
            INPUT_VALIDATED_FAILED_TIPS_END: '位僅包含數位、字母字元！',
            NOT_FOUND_CURRENT_FORM_TIPS: '表單不存在，請重新進入或者看看別的吧',
            THE_FORM_RESPONSE_TO_BE_COPIED_IS_NOT_SELECTED_AND_CANNOT_BE_COPIED: '未選擇要複製的表單記錄，無法複製！',
            FOUND_SOME_FORM_RESPONSE_THAT_I_FILLED_OUT_EARLIER_DID_I_COPY_THE_CONTENTS_AND_EDIT_THEM_FOR_SUBMISSION: '發現之前填寫的一些表單記錄，是否複製內容並編輯提交？',
            FILL_FORM_BEFORE_PURCHASE: '購買前須填寫表單',
            FILL_FORM_AFTER_PAID: '支付後可填寫表單',
            PLEASE_FILL_OUT_THE_FORM_FIRST: '請先填寫表單',
            NEED_APPLY_FORM_TIPS: '至少還需填寫{{needApplyFormNumber}}個表',
            WHETHER_TO_BIND_THE_FORM_RESPONSE: '是否將已填寫的表單記錄綁定到當前訂單上',
            CONFIRM_AND_BIND: '確認並綁定',
            APPLY_FORM__AND_BIND_ORDER_TIPS: '填寫表單並綁定訂單成功！',
            GO_TO_FILL_OUT: '前往填寫',
            FILLED_IN: '已填寫',
        },
        SHOPPING_CART: {
            FAVORITE_IT: '移入我的最愛',
            GO_SHOPPING: '去購物',
            SHOPPING_CART_IS_EMPTY_TIPS: '購物車為空，去看看別的吧',
        },
        PRODUCT_PAGE: {
            GO_SHOPPING_CART: '去購物車',
            CURRENT_GROUP_CANNOT_JOIN_PLEASE_TRY_ANOTHER_ONE: '當前拼團無法參與，請換個試試吧',
            PRODUCT_ERROR_SEE_OTHER_ONE: '很抱歉本商品處於不可售狀態， 麻煩您去看看其它商品！',
            CREATE_GROUP_BUYING_PRICE_ERROR: '團長價格設置有誤，無法開團！',
            BUY_AT_LEAST_ONE_TIPS: '最少購買一件',
        },
        GROUP_BUYING_PAGE: {
            TITLE: '拼團詳情',
            SUCCESSFULLY_JOINED_TOGETHER: '等人拼團成功',
            MY_GROUP_BUYING: '我的拼團',
            GROUP_BUYING_INFORMATION_TITLE: '拼團須知：',
            GROUP_BUYING_INFORMATION_TIPS: '人參團，人數不足自動退款',
            GROUP_BUYING_REMAINING_TIME: '拼團剩餘時間',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_TIPS: '人，快來加入我的團吧！',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_TIPS: '人，即可拼團成功！',
            GROUP_BUYING_NEED_PEOPLE_NUMBER_SUCCESSFULLY_LEFT_LABEL: '還差',
            JOIN_NOW: '立即參團',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_FRIENDS_TIPS: '暫無拼團成員，快去邀請好友參與吧！',
            NO_GROUP_BUYING_MEMBERS_FOR_INVITE_NEW_PEOPLE_TIPS: '暫無拼團成員，快去邀請新人參與吧！',
            INVITE_FRIENDS_TO_JOIN_THE_GROUP: '邀請好友參團',
            INVITE_NEW_GINSENG_GROUP: '邀請新人參團',
            FAILED_TO_GET_GROUP_INFORMATION_TIPS: '獲取拼團信息失敗，請檢查網路並刷新再試吧',
            GROUP_HAS_BEEN_CANCELLED: '當前拼團已取消',
            HURRY_UP_TO_INVITE: '趕快邀請',
            COME_AND_JOIN_MY_GROUP: '趕快來參加我的拼團吧',
            GROUP_BUYING_STATUS_IS_UNKNOWN: '拼團狀態未知',
            GROUP_BUYING_HAS_STOPPED: '拼團已停止',
            GROUP_BUYING_HAS_BEEN_CANCELLED: '拼團已取消',
            THE_GROUP_BUYING_IS_COMPLETE: '拼團已完成',
            THE_GROUP_BUYING_HAS_TIMED_OUT: '拼團已超時',
            THE_STATE_OF_THE_GROUP_BUYING_IS_ABNORMAL: '拼團狀態異常',
            NEED_SOME_PERSON_TO_SUCCESSFUL_FOR_GROUP_BUYING: '還差{{memberGap}}人 拼團成功',
        },
        CUSTOMER_SERVICE: {
            SERVICE_HOTLINE: '服務熱線',
            OFFICIAL_ADDRESS: '官方位址',
            COMPANY_ADDRESS: '公司位址',
            OFFICIAL_WECHAT: '官方微信',
        },
        DOCUMENT: {
            NO_TERMS_AND_CONDITIONS: '暫無條款資訊',
            LOGIN_TERM: '登錄條款',
            REGISTER_TERM: '註冊條款',
            PURCHASE_TERM: '購買條款',
            RETURN_TERM: '退款條款'
        },
        GIFT_CARD: {
            EXPIRATION_DAYS_AFTER_ACTIVATION: '啟動後到期時間 （天）',
            CAMPAIGN_CODE: '活動編號',
            CHANNEL_CODE: '管道編號',
            CARD_MEDIA_TYPE: '卡片類型',
            CURRENCY: '幣鐘類型',
            ACTIVATION_EXPIRATION_DATE: '啟動截止時間',
            CNY: '人民幣',
            ELECTRONIC: '電子卡',
            ORDER_TYPE: '應用訂單',
            REMARK: '備註',
            SERVICE_ORDER: '服務訂單',
            SALES_ORDER: '銷售訂單',
            GIFT_CARD_TIPS: '選填，請先和商家協商一致'
        },
        FORM_CONTROL: {
            DOCUMENTS_MUST_BE_UPLOADED: '必須上傳！',
            PENDING_UPLOAD: '待上傳',
            CANCEL_UPLOAD: '取消上傳',
            UPLOAD: '上傳',
            DOWNLOAD: '下載',
            DRAG_OR_CLICK_TO_UPLOAD: '拖拽/點擊上傳',
            FILES: '個',
            APPLICATION: '應用',
            IMAGE: '圖片',
            VIDEO: '視頻',
            AUDIO: '音訊',
            COMPRESS: '壓縮包',
            FILES_UPLOAD: '附件上傳',
            FILE_SIZE_NUMBER_MB_CANNOT_BE_UPLOADED: '大小為{{fileSize}}mb的文件無法上傳',
            PLEASE_UPLOAD_THE_TYPE_FORMAT_FILE_WITHIN_FILESIZE_MB_IN_SIZE: '請上傳大小在{{fileSize}}mb內的{{fileType}}檔！',
            FILE_TYPE: '格式',
            CONTROL_CANNOT_BE_EMPTY: '不能為空！',
            TEXTAREA: '多行文字',
            PLEASE_CHOOSE: '請選擇',
            SELECT: '下拉框',
            RADIO_GRID: '矩陣單選',
            RADIO: '單選框',
            LINEAR_SCALE_GRID: '矩陣量表',
            LINEAR_SCALE: '線性量表',
            PLEASE_TYPE_IN: '請輸入',
            PLEASE_ENTER_CONTENT: '請輸入內容',
            ADDRESS: '位址',
            INPUT: '單行文字',
            LENGTH_CANNOT_BE_LESS_THAN: '長度不能小於',
            LENGTH_CANNOT_BE_GREATER_THAN: '長度不能大於',
            CANNOT_BE_LESS_THAN: '不能小於',
            CANNOT_BE_GREATER_THAN: '不能大於',
            INVALID_ERROR: '格式不正確！',
            CHECKBOX_GRID: '矩陣多選',
            TITLE: '標題',
            SUBTITLE: '副標題',
            TEXT: '正文',
            RICHTEXT: '富文本',
            CHECKBOX: '多選框',
            SHOULD_CHOOSE: '應選擇',
            OPTIONS: '個選項',
            PREV_STEP: '上一步',
            NEXT_STEP: '下一步',
            DATETIME: '時間',
            DATE: '日期',
            DATE_NOT_SELECTED: '日期未選擇！',
            THE_TIME_IS_NOT_ENTERED_COMPLETELY: '時間未輸入完整！',
            PLEASE_CHECK: ',請檢查！',
        },
        EDITOR: {
            TITLE: '標題',
            SUB_TITLE: '副標題',
            BODY: '正文',
            DEFAULT: '預設',
            EXPORT_PDF: '匯出pdf',
            PREVIEW_PDF: '預覽pdf',
            PRINT: '列印',
            MARGIN_LEFT: '左邊距',
            MARGIN_RIGHT: '右邊距',
            LINE_HEIGHT: '行高',
            MARGIN_TOP: '上邊距',
        },
        FILTER_PARAMS_SELECTOR: {
            SORT: '排序',
            FILTER: '篩選',
            CATEGORY: '分類',
            PUT_AWAY: '收起',
            EXPEND_MORE: '更多',
            KEYWORD: '關鍵字',
            PRICE: '價格',
            CANCEL: '取消',
            CONFIRM: '確認',
            KEYWORD_PLACEHOLDER: '請輸入篩選關鍵字',
        },
        SHARED: {
            DISCOUNTED_COUPON_PRICE: '優惠券金額',
            NOT_SUPPORT_ADDRESS: '暫不支持在該區域購買！',
            NOT_SUPPORT_ADDRESS_TIP: '暫不支持在該區域購買，請選擇其他地址！',
            COMMENT_PLACEHOLDER: '請輸入評論',
            DELIVERY_ADRESS_EMPTY: '請先選擇位址',
            DELIVERY_INFORMATION_EMPTY: '未獲取到收貨資訊，請刷新後重試！',
            DISCOUNTED_PRICE: '優惠金額',
            UNAVAILABLE_FOR_COUPON: '不可使用',
            EDIT_PICKER_INFO: '編輯提貨人資訊',
            PICKER_INFO_PLACEHOLDER: '請完善提貨人資訊',
            EDIT_PICKER_INFO_PLACEHOLDER: '請輸入提貨人姓名',
            EDIT_PICKER_PHONE_PLACEHOLDER: '請輸入提貨人手機號',
            NO_COUPONS_AVAILABLE: '無可用優惠券',
            SELF_PICKUP_TIME: '自提時間',
            TOGGLE: '切換',
            CHANGE_ADDRESS: '更換位址',
            SELF_PICKUP_PERPON: '提貨人',
            THE_ORDER_HAS_BEEN_REVIEWED_AND_DOES_NOT_SUPPORT_REFUNDS: '訂單已審核不支援退款',
            REFUND_ORDER_DETAILS: '退款訂單詳情',
            PAYMENT_METHOD: '收款方式',
            FILL_FROM_AFTER_PAID_TIPS: '請支付後再填寫表單哦',
            REFUND_NUMBER: '退款編號',
            I_HAVE_SENT_IT_CLICK_TO_FILL_IN_THE_LOGISTICS_ORDER_NUMBER: '填寫快遞資訊',
            ONLINE_PAY_REFUND_AMOUNT: '微信/支付寶退款金額',
            GIFT_CARD_REFUND_AMOUNT: '禮品卡退款金額',
            WAITING_FOR_SELLER_TO_RECEIVE: '已退貨，等待賣家收貨',
            PASSED_THE_REVIEW_TIPS: '商家已同意退貨申請，請儘早退貨。',
            ORDER_TYPE: '訂單類型',
            THE_AMOUNT_PAID_FOR_THE_ORDER: '訂單已支付金額',
            EXPRESS_LOGISTICS: '快遞物流',
            SHIPPING_FEES: '配送費用',
            NO_DELIVERY_REQUIRED: '無需配送',
            DELIVERY_METHOD: '交付方法',
            APPLICATION_STATUS: '申請狀態',
            OTHER_REASONS_INVALID_ERROR: '其他原因填寫不正確',
            OTHER_REASONS_EMPTY_ERROR: '其他原因未填寫',
            PHONE_INVALID_ERROR: '手機號格式不正確',
            REQUEST_A_REFUND_PLACEHOLDER: '注：非上門自提的已發運訂單，運費不退！',
            PLEASE_FILL_IN_OTHER_REASONS: '請填寫其他原因',
            ANONYMOUS_COMMENTS: '匿名評論',
            PLEASE_ENTER_REVIEW_CONTENT: '請輸入評價內容',
            BIND_GIFT_CARD: '綁定禮品卡',
            TO_BE_PICKED_UP: '待自提',
            GIFT_CARD_SCRET: '卡密',
            GROUP_TO_BE_FORMED: '拼團待成團',
            PAID_TO_BE_PICKED: '已支付，待揀貨',
            PAID_PENDING: '已支付，待處理',
            ORDER_NOT_PAID: '訂單未支付',
            PLEASE_ENTER_GIFT_CARD_SCRET: '請輸入卡密',
            COMMENTED: '已評論',
            BIND: '綁定',
            INPUT_GIFT_CARD_SCRET_TIPS: '請輸入禮品卡密碼（電子卡卡密可至訂單詳情查看）',
            GIFT_CARD_SCRET_EMPTY_ERROR: '卡密不能為空',
            BINDING_SUCCEEDED: '綁定成功',
            GIFT_CARD_SCRET_INVALID_ERROR: '請輸入正確的卡密',
            MAX_QUANTITY_FOR_ONCE_TIPS: '一次最多只能買{{value}}件哦！',
            MAX_QUANTITY_FOR_ONCE_BY_STOCK_TIPS: '最多只能買{{value}}件哦！',
            NEWCOMER_GROUP: '新人拼團',
            BROWSER_UNSPPORT_VIDEO_PLAY: '您的瀏覽器太老了，不支援播放視頻，請升級瀏覽器哦！',
            SOLD_DESC: '{{value}}件已售',
            THERE_IS_NO_INFORMATION: '沒有任何資訊',
            COMMENT: '發表評論',
            PURCHASE_NOTICE: '購買須知',
            COPY_SUCCESSFULLY: '複製成功！',
            BIND_FAILED: '綁定失敗',
            USE_THIS_GIFT_CARD_TIPS: '你確定要將這張禮品卡綁定到當前帳號嗎？',
            USE_THIS_GIFT_CARD: '綁定到當前帳號',
            VIEW_THIS_GIFT_CARD: '查看卡密',
            VIEW_GIFT_CARDS_IN_ORDER: '查看訂單 禮品卡',
            LOAD_GIFT_CARDS_ERROR: '訂單相關禮品卡獲取失敗',
            CARD_NUMBER: '卡號',
            PAR_VALUE: '面值',
            EXPIRATION_DATE: '有效期',
            CLICK_TO_SEE_LARGER_PICTURE: '點擊查看大圖',
            ENLARGE_IMAGE: '放大圖',
            CLICK_TO_GO: '點擊前往',
            COUPONS: '優惠券',
            DO_NOT_USE: '不使用',
            FAILED_TO_INITIALIZE_QR_CODE_FUNCTION: '初始化二維碼功能失敗，請檢查網路是否正常或者是否被廣告攔截外掛程式攔截誤殺！',
            DRAW_AND_SHARE: '極速繪製分享圖中，請耐心等待',
            BROWSER_NOT_SUPPORT_CANVAS: '當前瀏覽器不支援繪製二維碼，請升級或者更換瀏覽器哦',
            LOAD_LOGO_IMG_FAILED: '未能載入logo圖片或logo圖片不存在，請稍後再試~',
            HOW_TO_SHARE_ON_WECHAT: '（1） 長按保存圖片或發送好友',
            SAVE_PICTURES: '（1） 儲存圖片到本地',
            SCAN_THE_CODE_TO_VIEW: '（2） 掃碼二維碼查看詳情',
            GROUP_BUYING: '拼團',
            GROUP_BUYING_DESC: '人拼團 • 人滿發貨 • 人不滿退款',
            LOAD_QRCODE_FAILED: '未能載入{{productName}}二維碼，請稍後再試。',
            LOAD_IMG_FAILED: '未能載入{{productName}}圖片，請稍後再試。',
            SHIP_BEFORE_DATE: '{{value}}前發貨',
            SHIPS_DAYS_AFTER_PAYMENT: "付款后{{value}}天內發貨",
            MORE_PRODUCT: '更多商品',
            DEFAULT: '預設',
            LATEST: '最新',
            SALES: '銷量',
            PRICE: '價格',
            MORE: '更多',
            NOT_FOUND_TIPS: '相關{{value}}未找到哦，先看看別的吧',
            HOT_SALE: '熱銷',
            CANCEL_ORDER_TIPS: '確定要取消該訂單嗎',
            CANCELLED: '已取消',
            IN_PROGRESS_GROUPINGS_DO_NOT_ALLOW_REFUNDS: '進行中的拼團不允許退款！',
            OUT_OF_STOCK: '已出庫',
            ORDER_REVIEWED: '訂單已審核，不支援退款',
            INSUFFICIENT_AMOUNT_ACTUALLY_PAID: '實際支付金額為0，無法開票',
            ACTUAL_PAYMENT_AS_GIFT_CARD: '支付方式為禮品卡支付，無法開票',
            INVOICE_SUBMITTED_SUCCESSFULLY: '當前訂單已提交開票，正在審核中，耐心等待哦......',
            REFUND_REQUESTED: '已申請退款',
            ORDER_ID: '訂單號',
            ORDER_CANCELLED: '訂單已取消',
            REMAINING_PAYMENT_TIME: '剩餘支付時間',
            SECONDS: '秒',
            MINUTE: '分鐘',
            REFUNDS: '退貨退款',
            REQUEST_A_REFUND: '申請退款',
            REFUND_SUCCESSFULLY: '退款成功',
            REFUND_UNDER_REVIEW: '退款審核中',
            REFUND_FAILED: '退款未通過',
            REFUND_APPROVED: '退款審核通過',
            REFUND_PENDING_REVIEW: '退款待審核',
            GO_TO_COMMENT: '去評論',
            EVALUATED: '已評價',
            GO_TO_EVALUATE: '去評價',
            PRESALE_ORDER: '預售訂單',
            GROUP_BUYING_ORDER: '拼團訂單',
            ESTIMATED_DELIVERY_TIME: '預計發貨時間',
            DELIVERY_TIME: '發貨時間',
            NORMAL_ORDER: '普通訂單',
            SELF_PICKUP: '上門自提',
            NUMBER_PIECES_IN_TOTAL: '共 {{totalQuantity}} 件',
            THE_TOTAL_AMOUNT_OF_ORDERS: '訂單總金額',
            SHIPPING_VALUE: '含運費{{value}}',
            AMOUNT_PAID: '已支付金額',
            AMOUNT_TO_BE_PAID: '待支付金額',
            REFUND_AMOUNT: '退款金額',
            ORDER_TIME: '下單時間',
            INVOICED: '已開票',
            INVOICING_REVIEW: '開票審核中',
            APPLY_FOR_MAKING_AN_INVOICE: '申請開票',
            VIEW_LOGISTICS: '查看物流',
            BUY_AGAIN: '再次購買',
            HOURS: '小時',
            TO_PAY: '去支付',
            SITEWIDE: '全場',
            BE_USABLE: '可使用',
            UNAVAILABLE: '不可用',
            SUBMITTED: '已提交',
            REFUND_FAILED_ERROR: '退款失敗 請聯繫管理員',
            ORDER_DETAILS: '訂單詳情',
            PASSED_THE_REVIEW: '審核通過',
            NOT_ADDRESS_DATA_TIPS: '當前無地址資訊，點擊創建位址',
            NOT_INVOICE_HEADER_DATA_TIPS: '無可用發票抬頭，前往創建',
            TO_CREATE_TIPS: '前往創建',
            ORDER: '訂單',
            STORE_WIDE_COUPONS: '店鋪全場優惠券',
            DESIGNATED_PRODUCT_COUPONS: '指定{{value}}優惠券',
            YEAR_MONTH_DAY_FROMART_TEXT: '{{year}}年{{month}}月{{day}}日',
            YEAR_MONTH_DAY_PIPE_FROMART_TEXT: 'yyyy-MM-dd',
            ADDRESS_NOT_SELECTED_TIPS: '請選擇收貨地址',
            FREE_SHIPPING_ON_SITE: '全場包郵',
            FREE_SHIPPING_OVER_NUMBER_KG: '重量滿{{value}}KG包郵',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT: '金額滿{{value}}KG包郵',
            FREE_SHIPPING_ON_ORDERS_OVER_AMOUNT_AND_WEIGHT: '重量滿{{value}}KG 且 金額滿{{mount}}KG包郵'
        }
    },
};
