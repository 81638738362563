import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[appFontSizeScale]'
})
export class FontSizeScaleDirective {
    @Input() set appFontSizeScale(value: number) {
        if (Number(value) > 0) {
            this._fontSizeScale = Number(value);
            if (Number(value) !== 100) {
                this.setFontSizeScale();
            }
        }
    };
    _fontSizeScale = 100;
    constructor(private el: ElementRef, private rd2: Renderer2) {
    }
    setFontSizeScale() {
        this.rd2.removeStyle(this.el?.nativeElement, 'font-size');
        const originalFontSize = getComputedStyle(this.el?.nativeElement)?.fontSize;
        this.rd2.setProperty(this.el?.nativeElement, 'originalFontSize', originalFontSize);
        this.rd2.setStyle(this.el?.nativeElement, 'font-size', this.getFontSizeNumber(originalFontSize) * (this._fontSizeScale / 100) + this.getFontSizeUnit(originalFontSize));
    }
    getFontSizeNumber(value: string) {
        return Number(value?.replace('px', '')?.replace('rem', '')?.replace('%', ''))
    }
    getFontSizeUnit(value: string) {
        return value?.replace(value?.replace('px', '')?.replace('rem', '')?.replace('%', ''), '');
    }
    @HostListener('window:resize', ['$event']) windowResizeHandler(event) {
        this.setFontSizeScale();
    }
}
