export const userTranslate = {
    INFOMATION: {
        AVATAR: {
            TITLE: 'Head portrait'
        },
        NICK_NAME: {
            TITLE: 'Nickname',
            PLACEHOLDER: 'Please enter a nickname...'
        },
        GENDER: {
            TITLE: 'Gender',
            MALE: 'Male',
            FEMALE: 'Female',
            OTHER: 'Other',
            UNKNOWN: 'Unknown'
        },
        NAME: {
            TITLE: 'Name',
            PLACEHOLDER: 'Please enter your name...'
        },
        MOBILE_PHONE: {
            TITLE: 'Contact information',
            PLACEHOLDER: 'Please enter the contact information...'
        },
        TYPE: {
            TITLE: 'Type',
            INDIVIDUAL: 'Personal',
            ORGANIZATION: 'Organization'
        },
        ENTITY_SCALE: {
            TITLE: 'Subject size',
            OPTIONS: ['Company with limited liability', 'Limited company', 'Sole proprietorship', 'Partnership', 'Full name ownership enterprise', 'Collectively owned enterprise', 'Other']
        },
        ANNUAL_REVENUE: {
            TITLE: 'Annual revenue',
            PLACEHOLDER: 'Please enter annual revenue...'
        },
        EMPLOYEE_NUMBER: {
            TITLE: 'Number of workers',
            PLACEHOLDER: 'Please enter the number of employees...'
        },
        SAVE_BTN_TEXT: 'Update information',
        SAVE_BTN_SUCCESS_TEXT: 'Data updated successfully!',
        SAVE_BTN_ERROR_TEXT: 'Data update failed!',
        UPDATE_AVATAR_ERROR: 'Update Avatar Error，',
    },
    ACCOUNT_SECURITY: {
        SET_PASSWORD: {
            TITLE: 'Set password',
            ENABLE: 'Set',
            NOT_ENABLE: 'Not set',
            SET_BTN_TEXT: 'Set up',
            CHANGE_BTN_TEXT: 'Change',
        },
        BIND_MOBILE_PHONE: {
            TITLE: 'Binding mobile phone',
            ENABLE: 'Bound',
            NOT_ENABLE: 'Unbound',
            SET_BTN_TEXT: 'Binding',
            CHANGE_BTN_TEXT: 'Change',
        },
        BIND_WECHAT: {
            TITLE: 'Binding wechat',
            ENABLE: 'Bound',
            NOT_ENABLE: 'Unbound',
            SET_BTN_TEXT: 'Binding',
            CHANGE_BTN_TEXT: 'Change',
            CHANGE_TITLE: 'Are you sure you want to unbind wechat?'
        },
        BIND_PHONE_PAGE: {
            TITLE: 'For your account security, you need to {{action}} your mobile number is required',
            BIND: 'Binding',
            VERIFY: 'Verification',
            CHANGE_BIND: 'Change binding',
            SUCCESS_MESSAGE: '{{action}} mobile number: {{phonenumber}} succeeded',
            ERROR_MESSAGE: 'Mobile number update failed!',
            MOBILE_PHONE: {
                TITLE: 'Cell-phone number',
                PLACEHOLDER: 'Please enter your mobile number...'
            },
            OLD_MOBILE_PHONE: {
                TITLE: 'Original mobile number',
                PLACEHOLDER: 'Please enter the original mobile number...'
            },
            VERTIFY_CODE: {
                TITLE: 'Verification Code',
                PLACEHOLDER: 'Please enter the verification code...',
                GET_BTN_TEXT: 'Get verification code',
                SEND_TEXT: 'Send out',
                PHONE_CODE_ERROR_MESSAGE: 'Verification code acquisition failed!',
                IMAGE_CODE_ERROR_MESSAGE: 'Verification code acquisition failed!',
            },
            NEW_MOBILE_PHONE: {
                TITLE: 'New mobile number',
                PLACEHOLDER: 'Please enter a new phone number...'
            },
            CHANGE_MOBILE_PHONE_BTN_TEXT: 'Modify mobile number',
            BIND_MOBILE_PHONE_BTN_TEXT: 'Bind mobile number',
        },
        CHANGE_PASSWORD_PAGE: {
            TITLE: 'For the security of your account, please select the authentication method to complete authentication:',
            PHONE_VERTIFY: {
                TITLE: 'Mobile phone verification',
                MOBILE_PHONE: {
                    TITLE: 'Cell-phone number',
                    PLACEHOLDER: 'Please enter the currently bound mobile number...'
                },
                PASSWORD: {
                    TITLE: 'New password',
                    PLACEHOLDER: 'Please enter a new password...'
                },
                VERTIFY_CODE: {
                    TITLE: 'Verification Code',
                    PLACEHOLDER: 'Please enter the verification code...',
                    GET_BTN_TEXT: 'Get verification code',
                    SEND_TEXT: 'Send out',
                    PHONE_CODE_ERROR_MESSAGE: 'Verification code acquisition failed!',
                    IMAGE_CODE_ERROR_MESSAGE: 'Verification code acquisition failed!',
                },
                NOT_BIND_PHONE_TEXT: 'The mobile phone is not bound yet, and cannot be verified through the mobile phone. Please first',
                BIND_PHONE_LINK_TEXT: 'Binding mobile phone'
            },
            PASSWORD_VERTIFY: {
                TITLE: 'Password verification',
                OLD_PASSWORD: {
                    TITLE: 'Original password',
                    PLACEHOLDER: 'Please enter the original password...'
                },
                NEW_PASSWORD: {
                    TITLE: 'New password',
                    PLACEHOLDER: 'Please enter a new password...'
                },
            },
            SET_PASSWORD_BTN_TEXT: 'Set password',
            CHANGE_PASSWORD_BTN_TEXT: 'Change Password',
            SUCCESS_MESSAGE: 'Password changed successfully!',
            ERROR_MESSAGE: 'Modification failed!',
            GET_VERTIFY_CODE_ERROR_MESSAGE: 'Verification code acquisition failed!',
            SEND_VERTIFY_CODE_ERROR_MESSAGE: 'Verification code sending failed!',
        },
        SET_LOGIN_NAME: {
            TITLE: 'Set the login name',
            ENABLE: 'enable',
            NOT_ENABLE: 'Not enable',
            SET_BTN_TEXT: 'Set up',
            CURRENT_NAME_TIPS: 'Current Login:',
            INIT_NAME_TIPS: 'Initial Login:',
            OLD_LOGIN_NAME_TIPS: 'Original Login Name:',
            NEW_LOGIN_NAME_TIPS: 'New Login:',
            CHANGE_LOGIN_NAME_BTN_TEXT: 'Modify the login name',
            NEW_LOGIN_NAME_PLACEHOLDER: 'It cannot be modified twice after modification',
            LOGIN_CUSTOMIZED_TIP: 'The login name is already set and cannot be modified again',
            CHANGE_LOGIN_NAME_ERROR_TIPS: 'Failed to set login:',
            LOGIN_NAME_INVALID_ERROR_MESSAGE: 'The login name is not in the correct format, please check, currently only upper and lower case letters, numbers and specified special symbols (-, +, _) are supported!',
        }
    },
    REFERRAL: {
        REFERREEID_LABEL: 'Referree name: ',
        REFERRAL_DATE_LABEL: 'Register date: ',
        REFERRAL_SHARE_URL_DESC: 'There are rewards for registering: ',
        REFERRAL_SHARE_CODE_DESC: 'Fill in my referral code for more benefits: ',
    },
    AFFILIATE: {
        TITLE: 'Apply to join the affiliate',
        SUCCESS_TEXT: 'Congratulations, you have successfully joined the affiliate!',
        EMAIL_LABEL: 'Contact email',
        EMAIL_PLACEHOLDER: 'For receiving notifications and contacts',
        EMAIL_ERROR_REQUIRED: 'Please fill in the contact email',
        EMAIL_ERROR_INVALID: 'There is an error in the contact email format!',
        PHONE_LABEL: 'Contact phone',
        PHONE_PLACEHOLDER: 'Please enter your usual phone number',
        PHONE_ERROR_REQUIRED: 'Please fill in the contact phone number',
        COPY_CODE_TEXT: 'Copy affiliate code:',
        PHONE_ERROR_INVALID: 'There is an error in the contact number format!',
        FIRST_NAME_TEXT: 'Name',
        FIRST_NAME_ERROR_REQUIRED: 'Please fill in the name',
        FIRST_NAME_PLACEHOLDER: 'Please enter your name',
        LAST_NAME_TEXT: 'Last name',
        LAST_NAME_PLACEHOLDER: 'Please enter your last name',
        LAST_NAME_ERROR_REQUIRED: 'Please enter your last name',
        BIRTHDAY_TEXT: 'Birthday',
        BIRTHDAY_PLACEHOLDER: 'Enter your birthday',
        BIRTHDAY_ERROR_REQUIRED: 'Please fill in your birthday!',
        PAYPAL_EMAIL_PLACEHOLDER: 'Enter your Paypal Email withdrawal account',
        PAYPAL_EMAIL_ERROR_REQUIRED: 'Please fill in Paypal Email',
        PAYPAL_EMAIL_ERROR_INVALID: 'There is an error in the Paypal Email format!',
        FULL_ADDRESS_TEXT: 'Address details',
        FULL_ADDRESS_PLACEHOLDER: 'e.g. street address',
        FULL_ADDRESS_ERROR_REQUIRED: 'Please fill in your full address!',
        COUNTRY_TEXT: 'Country/Region',
        COUNTRY_ERROR_REQUIRED: 'Please fill in the country/region',
        STATE_TEXT: 'Province/State',
        STATE_PLACEHOLDER: 'Enter the name of the province/state',
        STATE_ERROR_REQUIRED: 'Please enter the province/state',
        SELECT_TEXT: 'Please select',
        CITY_TEXT: 'City name',
        CITY_PLACEHOLDER: 'Enter city name',
        CITY_ERROR_REQUIRED: 'Please fill in the city name',
        ZIP_CODE_TEXT: 'Zip code',
        ZIP_CODE_PLACEHOLDER: 'Enter the zip code',
        ZIP_CODE_ERROR_REQUIRED: 'Please fill in the zip code',
        APPLICATION_STATEMENT_TEXT: 'Personal introduction and reasons for application',
        APPLICATION_STATEMENT_PLACEHOLDER: 'Please describe your personal situation and state the reason for your application',
        REMARKS_TEXT: 'Additional remarks',
        REMARKS_PLACEHOLDER: 'If you have additional remarks, please fill in here',
        FORM_ERROR_MSG: 'Current required field is invalid!',
    },
    COMMISSION: {
        COMMISSION_SETTLEMENT: 'Commission settlement',
        SETTLEMENT_AMOUNT_LABEL: 'Settlement amount: ',
        SETTLEMENT_BTN: 'Settlement',
        COMMISSION_RECORDS: 'Commission records',
        SETTLEMENT_RECORDS: 'Settlement records',
        COMMISSION_SETTLEMENT_CONFIRM_TITLE: 'Settlement information confirmation',
        RECEIPT_INFO: 'Receipt Information',
        ENABLE_SETTLEMENT_TEXT: 'Settleable',
        SEE_ALL_SETTLEMENTS: 'View settlement records',
        STATUS: 'Status',
        AFFILIATE: 'Affiliate network',
        REFERRAL: 'Referral network',
        REWARD_DETAIL: 'Reward details',
        REWARD_STATUS: 'Settlement status',
        PARTNER_TYPE: 'Reward source',
        PARTNER_CODE: 'Partner code',
        REWARD: 'Marketing reward',
        SETTLEMENT_DATE: 'Settlement date',
        PAYMENT_DATE: 'Payment date',
        PAYMENT_METHOD: 'Payment method',
        PARTNER_NETWORD: 'Marketing network',
        CUSTOMER_ID: 'Customer ID',
        REWARD_TYPE: 'Award type',
        ORDER_TYPE: 'Order type',
        ORDER_ID: 'Order ID',
        ORDER_TOTAL_AMOUNT: 'Total amount of order',
        REWARD_RULE: 'Reward Rule',
        ORDER_DATE: 'Date of order',
        CONFIRMED_DATE: 'Confirmation date',
        PAYMENT_DETAIL: 'Payment details',
        PAYMENT_REFERRENCE: 'Payment source',
        PAYMENT_REMARK: 'Payment remark',
        ORDER_COMMISSION: 'Order commission',
        TIER_UPGRADE: 'Level reward',
        RECEIPT_ACCOUNT: 'Receipt account',
        RECEIPT_TYPE: 'Receipt type',
        CASH_AMOUNT: 'Commission income',
        PERIOD_START_DATE: 'Settlement start date',
        PERIOD_END_DATE: 'Settlement end date',
        REWARD_SOURCE_TYPE: 'Reward source',
    },
    MARKING: {
        NETWORK: 'Marketing networks',
        NETWORK_ERROR_REQUIRED: 'Please select a marketing network you would like to join',
    },
    AFFILIATE_PROFILE: {
        MENU_TITLE: 'Profile',
        TITLE: 'Update affiliate profile',
        NETWORK_INFO_LABEL: 'Network info',
        RECEIPT_INFO_LABEL: 'Receipt info',
        BASIC_INFO_LABEL: 'Basic info',
        ADDRESS_INFO_LABEL: 'Address info',
    }
}
