import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeAnnouncementsService } from '../../core/services/home-announcements.service';
import { SiteAnnouncementService } from '../../shared/services/site-announcement.service';
import { SiteAnnouncement, siteAnnouncementStatus, siteAnnouncementVisibility } from '../../shared/model/site-announcement.model';
import { jsonParse } from '../../shared/pipes/name-translate.pipe';
import { ShopContextService } from '../../ecommerce/shared/service/shop-context.service';

@Component({
    selector: 'app-home-announcement-block',
    templateUrl: './home-announcement.component.html',
    styleUrls: ['./home-announcement.component.scss']
})
export class HomeAnnouncementBlockComponent {
    @Input() blockId: string;
    @Input() renderOptions: any = {};
    @Input() section: string;
    @Input() set data(value: any) {
        if (value) {
            if (jsonParse(value)?.contentType === 'SITE') {
                this.type = 'SITE';
                this.getSiteAnnouncements();
            } else {
                this.type = 'SHOP';
                this.getShopAnnouncements();
            }
        }
    };
    announcements: any[] = [];
    type: string;
    constructor(
        private shopContextService: ShopContextService,
        private router: Router,
        private shopAnnouncementsService: HomeAnnouncementsService,
        private siteAnnouncementService: SiteAnnouncementService,
    ) { }

    toAnnouncementsPage() {
        this.router.navigate(['/announcements'], {
            queryParams: {
                type: this.type
            }
        });
    }

    // 获取通知公告
    getShopAnnouncements() {
        this.shopAnnouncementsService
            .query(this.shopContextService.shopId, { 'sort': 'id,desc', 'isShow': 'true' })
            .subscribe(
                (resp: HttpResponse<[]>) => {
                    this.announcements = resp.body;
                },
                (error) => {
                    console.log('访问失败', error);
                }
            );
    }

    getSiteAnnouncements() {
        this.siteAnnouncementService
            .query({
                'sort': 'createdDate,desc',
                'visibility.equals': siteAnnouncementVisibility.PUBLIC,
                'status.equals': siteAnnouncementStatus.DRAFT
            })
            .subscribe(
                (resp: HttpResponse<[]>) => {
                    this.announcements = resp.body;
                },
                (error) => {
                    console.log('访问失败', error);
                }
            );
    }
}
